<template>
  <div>
    
    <div
      class="frame66persona-formulario-lneadesgravamen-usosdebotones"
      style="border-color: transparent"
    >
      <v-btn
        color="rgba(255, 255, 255, 1)"
        class="frame66persona-formulario-lneadesgravamen-usosdebotones"
        rounded
        x-large
        style="left: 0px; top: 0px; text-transform: unset !important; border: thin solid rgb(44, 49, 49);"
        @click="volver"
      >
        <span class="frame66persona-formulario-lneadesgravamen-text02">
          <span>Volver</span>
        </span>
      </v-btn>
    </div>
  
    <!-- <div
     
      style="border-color: transparent; "
    >
      <v-btn
        color="juanito"
        class="frame66persona-formulario-lneadesgravamen-usosdebotones1"
        rounded
        outlined
        x-large
        style="left: 0px; top: -21px; text-transform: unset !important;"
        @click="validate"
        :disabled="bdisabled"
      >
        <span class="frame66persona-formulario-lneadesgravamen-text04">
          <span>Continuar</span>
        </span>
      </v-btn>
    </div> -->
    <v-btn color="juanito" class="rounded-pill " x-large
                    style="margin-left: 634px  ;top:844px;width:250px;text-transform: unset !important;" @click="validate"
                    :disabled="!valid">
                    <span class="frame66persona-formulario-lneadesgravamen-text04">
          <span>Continuar</span>
        </span>
                    <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
      </v-btn>
    <div class="frame664personaformulariolneadesgravamenalertbanner" v-show="info && id!=contDps">
      <div class="frame664persona-formulario-lneadesgravamen-group506">
        <span class="frame664persona-formulario-lneadesgravamen-text06">
          <span>
            Si tienes alguno de estos afecciones no puedes acceder a nuestro seguro
          </span>
        </span>
        <div class="frame664persona-formulario-lneadesgravamen-akariconscirclealert">
                 <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
        </div>
      </div>
    </div>

   
    <div class="frame67persona-formulario-lneadesgravamen-group288940" v-show="(id==contDps)">
        <div class="frame67persona-formulario-lneadesgravamen-alertbanner">
          <div class="frame67persona-formulario-lneadesgravamen-group506">
            <span class="frame67persona-formulario-lneadesgravamen-text02">
              <span>Acepto que toda la información que estoy otorgando es real y fidedigna </span>
            </span>
            <div
              class="frame67persona-formulario-lneadesgravamen-akariconscirclealert"
            >
              <div class="frame67persona-formulario-lneadesgravamen-group7">
                
                <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
                
              </div>
            </div>
          </div>
        </div>
        <div
          class="frame67persona-formulario-lneadesgravamen-icon-switch-o-n-o-f-f"
        >
          <div class="frame67persona-formulario-lneadesgravamen-group8">
           
            
                         
           
              <v-form ref="form" v-model="valid" lazy-validation>
                        <v-switch color="azulsitio"  style="top: -22px;" 
                        class="frame67persona-formulario-lneadesgravamen-oval1"  ></v-switch>
             </v-form>
            
          </div>
        </div>
      </div>
    
    <div class="frame66persona-formulario-lneadesgravamen-group288991">
      <img
        src="/playground_assets/seguros/linea/rectanguloarribarellenalasiguienteencuesta1593-s4ja.svg"
        alt="RectanguloarribaRellenalasiguienteencuesta1593"
        class="frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta"
      />
      <img
        src="/playground_assets/seguros/linea/rectanguloarribarellenalasiguienteencuesta1593-dx26.svg"
        alt="RectanguloarribaRellenalasiguienteencuesta1593"
        class="frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta1"
      />
      <div class="frame66persona-formulario-lneadesgravamen-group288878">
        <span class="frame66persona-formulario-lneadesgravamen-text06">
          <span>ENCUESTA</span>
        </span>
        <span class="frame66persona-formulario-lneadesgravamen-text08">
          <span>Breve</span>
        </span>
      </div>
      <span class="frame66persona-formulario-lneadesgravamen-text10">
        <span class="frame66persona-formulario-lneadesgravamen-text11">
          <span>Hola!</span>
          <br />
          <span></span>
        </span>
        <span class="frame66persona-formulario-lneadesgravamen-text15">
          <span>
            Rellena todos los campos de nuestra encusta para lograr tu Cotización de
            nuestro.
          </span>
          <br />
          <span></span>
        </span>
        <span>Seguro de Desgravamen On line.</span>
      </span>
    </div>
    
    <span class="frame66persona-formulario-lneadesgravamen-text24">
      <span>Declaración Personal de salud</span>
    </span>
   
    <div class="frame66personaformulariolneadesgravamenradiobtnsi" :class="{ frame66personaformulariolneadesgravamenradiobtnsi: id!=contDps, frame66personaformulariolneadesgravamenradiobtnsi2: id==contDps }">
      
      
        <v-form ref="form" v-model="valid" >
          <v-radio-group
            v-model="bps.respuesta"
            :rules="respRules"
            required
            @update:error="vresp"
          >
            <v-layout row wrap>
              <v-flex column>
                <p style="position: relative; left: 4px; margin-bottom: 0px">SI</p>
                <v-radio key="1" value="si" @click="acepta" color="juanito"> </v-radio>
              </v-flex>
              <v-flex column>
                <span style="color: transparent">___________</span>
              </v-flex>
              <v-flex column>
                <p style="position: relative; margin-bottom: 0px">NO</p>
                <v-radio key="2" value="no" @click="rechaza"  color="juanito"> </v-radio>
              </v-flex>
              <v-flex column>
              </v-flex>
            </v-layout>
          </v-radio-group>
        </v-form>
      
    </div>
    <vue-recaptcha ref="recaptcha" v-show="(id==contDps)" @verify="onVerify"  class="sizecaptcha"
    sitekey="6Lf95eAjAAAAAEfbjyFShlUNjq5DqdowUYBirAih" 
    style="     top: 670px;
    position: relative;
    transform: scale(0.77);
    transform-origin: 0px 0px;
    margin-left: 280px;"
    
    >
    </vue-recaptcha>
    <span class="frame66persona-formulario-lneadesgravamen-text30">
      <span>
        {{ bps.prg_titulo }}
      </span>
    </span>
    <!-- <span class="frame664persona-formulario-lneadesgravamen-text35">
        <span>
          {{ bps.prg_texto }}
        </span>
    </span> -->
    <!-- <span class="frame66persona-formulario-lneadesgravamen-text32" v-if="(id!=3)">
      <span>
        <span>
          {{ bps.texto_c1f1 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c1f2 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c1f3 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c1f4 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c1f5 }}
        </span>
        <br />
      </span>
    </span>
    <span class="frame66persona-formulario-lneadesgravamen-text45" v-if="(id!=3)">
      <span>
        <span>
          {{ bps.texto_c2f1 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c2f2 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c2f3 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c2f4 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c2f5 }}
        </span>
        <br />
      </span>
    </span>
    <span class="frame66persona-formulario-lneadesgravamen-text58" v-if="(id!=3)">
      <span>
        <span>
          {{ bps.texto_c3f1 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c3f2 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c3f3 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c3f4 }}
        </span>
        <br />
        <span>
          {{ bps.texto_c3f5 }}
        </span>
      </span>
    </span> -->
  </div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Frame66PersonaFormularioLneadesgravamen",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      info: false,
      normalClass : "frame66persona-formulario-lneadesgravamen-radiobtn-si",
      captchaClass : "frame66persona-formulario-lneadesgravamen-radiobtn-si2",
      respRules: [(v) => !!v || ""],
      validresp: true,
      valid: true,
      bdisabled: true,
      contDps: 0,
    };
  },
  props: {
    robot: false,
    id: 0,
    bps: {
      prg_id: "",
      pdp_numero: "",
      prg_numero: "",
      prg_titulo: "",
      prg_tipo_respuesta: "",
      prg_ultima_pregunta: "",
      prg_con_opcion: "",
      pdp_ldp_numero: ""
    },
  },
  methods: {
   acepta(){
    this.info=true;
    this.contDps=this.$store.state.contdps;
   },
   rechaza(){
    this.info=false;
    this.contDps=this.$store.state.contdps;
   },
    submit: function () {
      if (this.form.robot) {
      }
    },
    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        console.log("id:"+ this.id );
        console.log("dps:"+ this.contDps );
        var Cdps=this.$store.state.contdps;
        if(this.id>=Cdps){
          this.$store.commit("setestadoFormLinea",6);
          this.$root.$refs.FormularioLinea.estadoFormLinea = 6;     
        }else{
          this.$store.commit("setestadoBps", this.id + 1);
          this.$root.$refs.FormularioLinea.estadoBps = this.id + 1;
        }
        
        console.log(this.bps);
      }
    },
    vresp(v) {
      this.bdisabled=false;
      this.validresp = v;
    },
    volver() {
      if (this.id == 1) {
        this.$root.$refs.FormularioLinea.estadoFormLinea = 3;
      } else {
        this.$store.commit("setestadoBps", this.id - 1);
        this.$root.$refs.FormularioLinea.estadoBps = this.id - 1;
      }
    },
  },
  mounted() {
    

  },
  created() {
    this.$root.$refs.BpsLinea = this;
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>
<style scoped>
.fb-btn.v-btn--outlined {
    border: solid rgb(44, 49, 49);
  }
.frame67persona-formulario-lneadesgravamen-oval1 {
  top: 0px;
  /* left: 0px; */
  width: 20px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group8 {
  top: 0px;
  /* left: 0px; */
  width: 34px;
  height: 20px;
  display: flex;
  opacity: 0.38;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group288940 {
  top: 600px;
  /* left: 465px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-alertbanner {
  top: 0px;
  /* left: 0px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 100px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(246, 242, 255, 1);
}
.frame67persona-formulario-lneadesgravamen-group506 {
  top: 15px;
  left: 77px;
  width: 665.4117431640625px;
  height: 27px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-text02 {
  left: 35.37058639526367px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 630px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  /* left: 0px; */
  width: 26.528043746948242px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.frame67persona-formulario-lneadesgravamen-group7 {
  top: 2px;
  /* left: 2.210670232772827px; */
  width: 22.10670280456543px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  /* left: 0px; */
  width: 22px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector21 {
  top: 2px;
  /* left: 12px; */
  width: 0px;
  height: 10px;
  background-color: var(--dl-color-gray-black);
}
.frame67persona-formulario-lneadesgravamen-icon-switch-o-n-o-f-f {
  top: 19px;
  left: 742px;
  width: 34px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.sizecaptcha iframe {
     width: 430px !important;
     height: 110px !important;
  }
.ctrans {
  background-color: transparent !important;
  height: 0px !important;
  flex: none !important;
  position: absolute;
}

.v-messages__message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  top: 14px;
  position: relative;
  font-size: large;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones {
  top: 840px;
  /* left: 148px; */
  width: 220px;
  height: 48px !important;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 4px;
  border-radius: 100px;
  margin-left: -130px;
}
.frame66persona-formulario-lneadesgravamen-text02 {
  top: 14px;
  /* left: 14px; */
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame664personaformulariolneadesgravamenalertbanner {
  top: 695px;
  /* left: 484px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 163, 163, 1);
  margin-left: 48px;
}

.frame664personaformulariolneadesgravamenalertbanner2 {
  top: 596px;
  /* left: 484px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 163, 163, 1);
  margin-left: 48px;
}
.frame664personaformulariolneadesgravamenalertbanner2 {
  top: 600px;
  /* left: 484px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 163, 163, 1);
  margin-left: 48px;
}
.frame664persona-formulario-lneadesgravamen-group506 {
  top: 15px;
  left: 77px;
  width: 665.4117431640625px;
  height: 27px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame664persona-formulario-lneadesgravamen-text06 {
  left: 35.37058639526367px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 630px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.frame664persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  /* left: 0px; */
  width: 26.528043746948242px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.frame664persona-formulario-lneadesgravamen-group7 {
  top: 2px;
  /* left: 2.210670232772827px; */
  width: 22.10670280456543px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame664persona-formulario-lneadesgravamen-text35 {
  top: 445px;
  /* left: 495px; */
  color: var(--dl-color-colores_neutrales-90);
  width: 796px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  /* line-height: 94.9999988079071%; */
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame664persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  /* left: 0px; */
  width: 22px;
  height: 20px;
  position: absolute;
}
.frame664persona-formulario-lneadesgravamen-vector21 {
  top: 5px;
  /* left: 11.053351402282715px; */
  width: 0px;
  height: 10px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones1 {
  top: 863px;
  /* left: 1072px; */
  width: 255px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-left: 21em;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones2 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}
.frame66persona-formulario-lneadesgravamen-text04 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  position: relative;
  top: 0px;
}
.frame66persona-formulario-lneadesgravamen-group288991 {
  top: 368px;
  /* left: 148px; */
  width: 268px;
  height: 388px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -385px;
}
.frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta {
  top: 0px;
  /* left: 0px; */
  width: 268px;
  height: 141px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta1 {
  /* left: 0px; */
  width: 268px;
  bottom: 2px;
  height: 247px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-group288878 {
  top: 52.210693359375px;
  left: 47px;
  width: 173.2490234375px;
  height: 65px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-text06 {
  top: 28.17718505859375px;
  color: var(--dl-color-gray-white);
  width: 173px;
  height: auto;
  position: absolute;
  font-size: 34px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text08 {
  top: -4px;
  /* left: 51px; */
  color: var(--dl-color-gray-white);
  width: 73px;
  height: auto;
  position: absolute;
  font-size: 28px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  margin-bottom: 5px;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text10 {
  top: 174px;
  /* left: 9px; */
  color: var(--dl-color-gray-white);
  width: 250px;
  height: auto;
  position: absolute;
  font-size: medium;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text11 {
  font-weight: 700;
}
.frame66persona-formulario-lneadesgravamen-text15 {
  font-weight: 400;
}
.frame66persona-formulario-lneadesgravamen-group288914 {
  top: 241px;
  /* left: 474px; */
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-rectangle382 {
  top: 0px;
  /* left: 215px; */
  width: 278px;
  height: 40px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-rectangle383 {
  top: 0px;
  /* left: 0px; */
  width: 259px;
  height: 40px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-text20 {
  top: 13px;
  /* left: 93px; */
  color: var(--dl-color-gray-white);
  width: 69px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame66persona-formulario-lneadesgravamen-text22 {
  top: 13px;
  /* left: 347.095703125px; */
  color: var(--dl-color-colores_neutrales-50);
  width: 71px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame66persona-formulario-lneadesgravamen-radiobtnaceptar {
  top: 13px;
  /* left: 319px; */
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse288 {
  top: 0px;
  /* left: 0px; */
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-radiobtnaceptar1 {
  top: 13px;
  /* left: 64px; */
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse289 {
  top: 0px;
  /* left: 0px; */
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text24 {
  top: 368px;
  /* left: 474px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 65px;
}
.frame66persona-formulario-lneadesgravamen-rectangle31 {
  top: 444.84814453125px;
  /* left: 1063.728515625px; */
  width: 50px;
  height: 3px;
  position: absolute;
  border-color: transparent;
}
.frame66personaformulariolneadesgravamenradiobtnsi {
  top: 600px;
  /* left: 772px; */
  width: 150px;
  height: 10px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-left: 377px;
}
.frame66personaformulariolneadesgravamenradiobtnsi2 {
  top: 525px;
  /* left: 772px; */
  width: 150px;
  height: 10px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-left: 377px;
}
.frame66persona-formulario-lneadesgravamen-ellipse2881 {
  top: 22px;
  /* left: 0px; */
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text26 {
  /* left: 1px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-radiobtn-no {
  top: 706px;
  /* left: 872px; */
  width: 26px;
  height: 39px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse2882 {
  top: 22px;
  /* left: 4px; */
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text28 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text30 {
  top: 405px;
  /* left: 474px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 64px;
}
.frame66persona-formulario-lneadesgravamen-text32 {
  top: 445px;
  /* left: 500px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text45 {
  top: 445px;
  /* left: 765px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text58 {
  top: 445px;
  /* left: 1029px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
