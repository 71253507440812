<template>
            <div class="frame52missegurosactivos-cards-seguro-total">
          <img
            src="/playground_assets/seguros/privado/seguros/rectangle2387i1567-0xbb-400h.png"
            alt="Rectangle2387I1567"
            class="frame52missegurosactivos-rectangle2387"
          />
          <div class="frame52missegurosactivos-group288920">
            <span class="frame52missegurosactivos-text096">
              <span>
                Póliza:
              </span>
            </span>
            <span class="frame52missegurosactivos-text098">
              <span>{{ Seguro.glosaCompania }}</span>
            </span>
            <span class="frame52missegurosactivos-text100">
              <span>{{ Seguro.formaPago }}</span>
            </span>
            <span class="frame52missegurosactivos-text102">
              <span>Forma de pago</span>
            </span>
          </div>
          <div class="frame52missegurosactivos-group2889181">
            <span class="frame52missegurosactivos-text104">
              <span>Plan</span>
            </span>
            <span class="frame52missegurosactivos-text106">
              <span>{{ Seguro.plan }}</span>
            </span>
            <span class="frame52missegurosactivos-text108">
              <span>{{ Seguro.poliza }}</span>
            </span>
            <span class="frame52missegurosactivos-text110">
              <span>{{ Seguro.total }}</span>
            </span>
            <span class="frame52missegurosactivos-text112">
              <span>Valor Total</span>
            </span>
            <span class="frame52missegurosactivos-text114">
              <span>Nº Póliza</span>
            </span>
          </div>
          <span class="frame52missegurosactivos-text116">
            <span>Compañía</span>
          </span>
          <span class="frame52missegurosactivos-text118">
            <span>Nº Seguro</span>
          </span>
          <span class="frame52missegurosactivos-text120">
            <span>{{ Seguro.numero }}</span>
          </span>
          <span class="frame52missegurosactivos-text122">
            <span>{{ Seguro.glosaPoliza  }}</span>
          </span>
          <span class="frame52missegurosactivos-text124">
            <span>
              Inicio:
            </span>
          </span>
          <span class="frame52missegurosactivos-text126">
            <span>{{Seguro.fechaInicio}}</span>
          </span>
          <span class="frame52missegurosactivos-text128">
            <span>{{Seguro.fechaTermino}}</span>
          </span>
          <span class="frame52missegurosactivos-text130">
            <span>
              Termino:
            </span>
          </span>
          <span class="frame52missegurosactivos-text132">
            <span>Vigencia</span>
          </span>
          <div v-show="Seguro.estado==3" class="frame52missegurosactivos-group288912">
            <span  class="frame52missegurosactivos-text134">
              <span>INACTIVO</span>
            </span>
          </div>
          <div v-show="Seguro.estado==1" class="frame52missegurosactivos-group2889121">
            <span class="frame52missegurosactivos-text186">
              <span>VIGENTE</span>
            </span>
          </div>
          <div v-show="Seguro.estado==2" class="frame52missegurosactivos-group2889122">
            <span class="frame52missegurosactivos-text228">
              <span>NO VIGENTE</span>
            </span>
          </div>
          <img
            src="/playground_assets/seguros/privado/seguros/line31i1567-z22.svg"
            alt="Line31I1567"
            class="frame52missegurosactivos-line31"
          />
          <img
            src="/playground_assets/seguros/privado/seguros/line32i1567-gn86.svg"
            alt="Line32I1567"
            class="frame52missegurosactivos-line32"
          />
          <div class="frame52missegurosactivos-group288921">
            <span class="frame52missegurosactivos-text136">
              <span>Seg Desgravamen</span>
            </span>
            <img
              src="/playground_assets/seguros/privado/seguros/vectori1567-vjn.svg"
              alt="VectorI1567"
              class="frame52missegurosactivos-vector91"
            />
          </div>
          <span class="frame52missegurosactivos-text138">
            <span>Ver Bitácora Seguro</span>
          </span>
          <DetalleSeguro :ident="Seguro.numero"></DetalleSeguro>
          <!-- <img v-show="Seguro.estado==3"
            src="/playground_assets/seguros/privado/seguros/rectangle2394i1567-79w9-200h.png"
            alt="Rectangle2394I1567"
            class="frame52missegurosactivos-rectangle2394"
          /> -->
          <!-- <span v-show="Seguro.estado==3" class="frame52missegurosactivos-text142">
            <span>Activar seguro</span>
          </span> -->
          <div  class="frame52missegurosactivos-icon-switch-o-n-o-f-f">
            <div class="frame52missegurosactivos-group16">
              <!-- <img
                src="/playground_assets/seguros/privado/seguros/rectanglei1567-a27i-200h.png"
                alt="RectangleI1567"
                class="frame52missegurosactivos-rectangle"
              /> -->
              <!-- <v-switch class="frame52missegurosactivos-oval" v-model="Seguro.activar" ></v-switch> 
              -->
              <DetalleSeguroActivar :ident="Seguro.numero"></DetalleSeguroActivar>
            </div>
          </div>
        </div>
</template>
<script>

import DetalleSeguro from "./DetalleSeguro.vue";
import DetalleSeguroActivar from "./DetalleSeguroActivar.vue";
export default {
  name: "BotonContratar",
  components: {
    DetalleSeguro: DetalleSeguro,
    DetalleSeguroActivar: DetalleSeguroActivar
  },
  data() {
    return {
     
    }
  },
  methods: {
  
  },
  props:{
    Seguro: {}
  }
}

</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
.shadowsInfo {
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
}
.v-skeleton-loader__bone {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 325px;
  top: 34px;
}
.v-skeleton-loader__bone {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  top: 24px;
  width: 100%;
  height: 293px;
}
.container {
  width: 100%;
  max-width: 1920px;
  min-width: 1440px;
}
.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.boton-hover-txt {
  color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame52missegurosactivos-container {
  min-height: 100vh;
}
.frame52missegurosactivos-frame52missegurosactivos {
  width: 100%;
  height: 3100px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame52missegurosactivos-rectangle378 {
  top: -24px;
  left: -89px;
  width: 1641px;
  height: 1057px;
  position: absolute;
}
.frame52missegurosactivos-unsplashh-se-gx-ljozs {
  top: -23px;
  left: -159px;
  width: 1788px;
  height: 1009px;
  position: absolute;
}
.frame52missegurosactivos-logo-v1 {
  top: 34px;
  left: 148px;
  width: 161px;
  height: 169.9999237060547px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-txt {
  top: 118.38489532470703px;
  left: 0px;
  width: 161.1430206298828px;
  height: 51.61572265625px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-group {
  top: 0px;
  left: 47.55321502685547px;
  width: 59.151954650878906px;
  height: 21.457965850830078px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector {
  top: 3.4794921875px;
  left: 0px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame52missegurosactivos-vector01 {
  top: 0px;
  left: 12.178791046142578px;
  width: 6px;
  height: 21px;
  position: absolute;
}
.frame52missegurosactivos-vector02 {
  top: 6.9599609375px;
  left: 19.13720703125px;
  width: 12px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector03 {
  top: 6.9599609375px;
  left: 33.0556640625px;
  width: 13px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector04 {
  top: 6.9599609375px;
  left: 46.9736328125px;
  width: 12px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-group01 {
  top: 27.8388671875px;
  left: 0px;
  width: 154.25872802734375px;
  height: 23.77685546875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector05 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 19px;
  position: absolute;
}
.frame52missegurosactivos-vector06 {
  top: 4.0595703125px;
  left: 15.65673828125px;
  width: 13px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector07 {
  top: 4.0595703125px;
  left: 29.57568359375px;
  width: 11px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector08 {
  top: 4.0595703125px;
  left: 41.75341796875px;
  width: 12px;
  height: 20px;
  position: absolute;
}
.frame52missegurosactivos-vector09 {
  top: 4.0595703125px;
  left: 56.251953125px;
  width: 9px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector10 {
  top: 4.0595703125px;
  left: 65.53125px;
  width: 12px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector11 {
  top: 4.0595703125px;
  left: 78.869140625px;
  width: 13px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector12 {
  top: 4.0595703125px;
  left: 92.20703125px;
  width: 12px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector13 {
  top: 4.0595703125px;
  left: 106.70556640625px;
  width: 19px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector14 {
  top: 4.0595703125px;
  left: 127.58251953125px;
  width: 13px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector15 {
  top: 4.0595703125px;
  left: 141.50048828125px;
  width: 13px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-group02 {
  top: 26.615234375px;
  left: 155px;
  width: 6.143017768859863px;
  height: 6.143017768859863px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-group03 {
  top: 1.537109375px;
  left: 2px;
  width: 2.5595905780792236px;
  height: 3.0715088844299316px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector16 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame52missegurosactivos-group04 {
  top: 0px;
  left: 0px;
  width: 6.143017768859863px;
  height: 6.143017768859863px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame52missegurosactivos-img {
  top: 0px;
  left: 27.237537384033203px;
  width: 105.92477416992188px;
  height: 105.92427062988281px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector18 {
  top: 1.8651069402694702px;
  left: 0px;
  width: 38px;
  height: 84px;
  position: absolute;
}
.frame52missegurosactivos-vector19 {
  top: 0px;
  left: 33.568058013916016px;
  width: 72px;
  height: 106px;
  position: absolute;
}
.frame52missegurosactivos-group288957 {
  top: 1202px;
  left: 199px;
  width: 349px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text002 {
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text004 {
  top: 109px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text006 {
  top: 57px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text008 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text010 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group288918 {
  top: 1574px;
  left: 199px;
  width: 349px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text012 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text014 {
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text016 {
  top: 109px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text018 {
  top: 57px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text020 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text022 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group288956 {
  top: 1951px;
  left: 199px;
  width: 349px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text024 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text026 {
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text028 {
  top: 109px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text030 {
  top: 57px;
  left: 185px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text032 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text034 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-footer {
  top: 2738px;
  left: 0px;
  width: 1440px;
  height: 362px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(247, 247, 247, 1);
}
.frame52missegurosactivos-line23 {
  top: 185.181640625px;
  left: 240px;
  width: 1081px;
  height: 0px;
  position: absolute;
}
.frame52missegurosactivos-cajamenfooter {
  top: 42px;
  left: 417px;
  width: 140px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame52missegurosactivos-text036 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text038 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text040 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text042 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-caja-quienessomosfooter {
  top: 42px;
  left: 807px;
  width: 113px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame52missegurosactivos-text044 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text046 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text048 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text050 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text052 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-caja-segurofooter {
  top: 42px;
  left: 643px;
  width: 78px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame52missegurosactivos-text054 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text056 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text058 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text060 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-cajacontactofooter {
  top: 42px;
  left: 1006px;
  width: 285px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame52missegurosactivos-text062 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text064 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text066 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text068 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame52missegurosactivos-text070 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-rectangle2374 {
  top: 322px;
  left: 0px;
  width: 1440px;
  height: 40px;
  position: absolute;
  border-color: transparent;
}
.frame52missegurosactivos-group05 {
  top: 38px;
  left: 0px;
  width: 87.0000991821289px;
  height: 283.55206298828125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-group06 {
  top: 183.783203125px;
  left: 8.86767578125px;
  width: 54.09845733642578px;
  height: 99.76887512207031px;
  display: flex;
  opacity: 0.50;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector20 {
  top: 4.328125px;
  left: 1.89794921875px;
  width: 52px;
  height: 95px;
  opacity: 0.05;
  position: absolute;
}
.frame52missegurosactivos-vector21 {
  top: 8.615234375px;
  left: 3.81298828125px;
  width: 50px;
  height: 91px;
  opacity: 0.10;
  position: absolute;
}
.frame52missegurosactivos-vector22 {
  top: 13.005859375px;
  left: 5.68603515625px;
  width: 48px;
  height: 86px;
  opacity: 0.14;
  position: absolute;
}
.frame52missegurosactivos-vector23 {
  top: 17.314453125px;
  left: 7.58251953125px;
  width: 46px;
  height: 82px;
  opacity: 0.19;
  position: absolute;
}
.frame52missegurosactivos-vector24 {
  top: 21.64453125px;
  left: 9.47216796875px;
  width: 44px;
  height: 77px;
  opacity: 0.24;
  position: absolute;
}
.frame52missegurosactivos-vector25 {
  top: 25.9940128326416px;
  left: 11.377463340759277px;
  width: 42px;
  height: 73px;
  opacity: 0.29;
  position: absolute;
}
.frame52missegurosactivos-vector26 {
  top: 30.341796875px;
  left: 13.2802734375px;
  width: 40px;
  height: 68px;
  opacity: 0.33;
  position: absolute;
}
.frame52missegurosactivos-vector27 {
  top: 34.63066864013672px;
  left: 15.089670181274414px;
  width: 38px;
  height: 64px;
  opacity: 0.38;
  position: absolute;
}
.frame52missegurosactivos-vector28 {
  top: 39px;
  left: 16.95556640625px;
  width: 36px;
  height: 59px;
  opacity: 0.43;
  position: absolute;
}
.frame52missegurosactivos-vector29 {
  top: 43.34980010986328px;
  left: 18.815692901611328px;
  width: 34px;
  height: 55px;
  opacity: 0.48;
  position: absolute;
}
.frame52missegurosactivos-vector30 {
  top: 47.740440368652344px;
  left: 20.674196243286133px;
  width: 32px;
  height: 50px;
  opacity: 0.52;
  position: absolute;
}
.frame52missegurosactivos-vector31 {
  top: 51.986328125px;
  left: 22.498046875px;
  width: 30px;
  height: 46px;
  opacity: 0.57;
  position: absolute;
}
.frame52missegurosactivos-vector32 {
  top: 56.3359375px;
  left: 24.338134765625px;
  width: 28px;
  height: 41px;
  opacity: 0.62;
  position: absolute;
}
.frame52missegurosactivos-vector33 {
  top: 60.705078125px;
  left: 26.178955078125px;
  width: 26px;
  height: 37px;
  opacity: 0.67;
  position: absolute;
}
.frame52missegurosactivos-vector34 {
  top: 65.0546875px;
  left: 28.001220703125px;
  width: 24px;
  height: 32px;
  opacity: 0.71;
  position: absolute;
}
.frame52missegurosactivos-vector35 {
  top: 69.40608215332031px;
  left: 29.80400848388672px;
  width: 22px;
  height: 28px;
  opacity: 0.76;
  position: absolute;
}
.frame52missegurosactivos-vector36 {
  top: 73.671875px;
  left: 31.6181640625px;
  width: 20px;
  height: 23px;
  opacity: 0.81;
  position: absolute;
}
.frame52missegurosactivos-vector37 {
  top: 78.03515625px;
  left: 33.421142578125px;
  width: 19px;
  height: 19px;
  opacity: 0.86;
  position: absolute;
}
.frame52missegurosactivos-vector38 {
  top: 82.328125px;
  left: 35.244140625px;
  width: 17px;
  height: 15px;
  opacity: 0.90;
  position: absolute;
}
.frame52missegurosactivos-vector39 {
  top: 86.38858795166016px;
  left: 37.01726531982422px;
  width: 15px;
  height: 10px;
  opacity: 0.95;
  position: absolute;
}
.frame52missegurosactivos-vector40 {
  top: 89.765625px;
  left: 38.813720703125px;
  width: 13px;
  height: 7px;
  position: absolute;
}
.frame52missegurosactivos-vector41 {
  top: 260.162109375px;
  left: 51.68408203125px;
  width: 28px;
  height: 16px;
  position: absolute;
}
.frame52missegurosactivos-vector42 {
  top: 268.484375px;
  left: 51.746337890625px;
  width: 28px;
  height: 7px;
  position: absolute;
}
.frame52missegurosactivos-vector43 {
  top: 105.66619110107422px;
  left: 10.786811828613281px;
  width: 53px;
  height: 159px;
  position: absolute;
}
.frame52missegurosactivos-vector44 {
  top: 122.2109375px;
  left: 10.76611328125px;
  width: 49px;
  height: 143px;
  position: absolute;
}
.frame52missegurosactivos-vector45 {
  top: 254.7088165283203px;
  left: 36.814327239990234px;
  width: 24px;
  height: 27px;
  position: absolute;
}
.frame52missegurosactivos-group07 {
  top: 257.2265625px;
  left: 36.81396484375px;
  width: 23.727771759033203px;
  height: 24.127182006835938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector46 {
  top: 0px;
  left: 17.33056640625px;
  width: 0px;
  height: 0px;
  position: absolute;
}
.frame52missegurosactivos-vector47 {
  top: 2.14453125px;
  left: 0px;
  width: 24px;
  height: 22px;
  position: absolute;
}
.frame52missegurosactivos-vector48 {
  top: 111.1396484375px;
  left: 41.006103515625px;
  width: 44px;
  height: 149px;
  position: absolute;
}
.frame52missegurosactivos-vector49 {
  top: 31.6494140625px;
  left: 9.4072265625px;
  width: 56px;
  height: 97px;
  position: absolute;
}
.frame52missegurosactivos-vector50 {
  top: 47.066612243652344px;
  left: 9.428058624267578px;
  width: 20px;
  height: 81px;
  position: absolute;
}
.frame52missegurosactivos-vector51 {
  top: 34.3076171875px;
  left: 24.13427734375px;
  width: 25px;
  height: 21px;
  position: absolute;
}
.frame52missegurosactivos-vector52 {
  top: 17.49609375px;
  left: 26.92041015625px;
  width: 21px;
  height: 30px;
  position: absolute;
}
.frame52missegurosactivos-vector53 {
  top: 17.49609375px;
  left: 32.65478515625px;
  width: 15px;
  height: 27px;
  position: absolute;
}
.frame52missegurosactivos-vector54 {
  top: 2.9853515625px;
  left: 30.229736328125px;
  width: 26px;
  height: 38px;
  position: absolute;
}
.frame52missegurosactivos-vector55 {
  top: 0px;
  left: 27.21062660217285px;
  width: 34px;
  height: 29px;
  position: absolute;
}
.frame52missegurosactivos-vector56 {
  top: 89.7470703125px;
  left: 50.8291015625px;
  width: 12px;
  height: 6px;
  position: absolute;
}
.frame52missegurosactivos-group08 {
  top: 82.1923828125px;
  left: 43.31591796875px;
  width: 15.177910804748535px;
  height: 27.98879623413086px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector57 {
  top: 0.30078125px;
  left: 0.451904296875px;
  width: 15px;
  height: 28px;
  position: absolute;
}
.frame52missegurosactivos-vector58 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 28px;
  position: absolute;
}
.frame52missegurosactivos-vector59 {
  top: 91.7861557006836px;
  left: 43.08709716796875px;
  width: 23px;
  height: 14px;
  position: absolute;
}
.frame52missegurosactivos-vector60 {
  top: 37.0341796875px;
  left: 58.5244140625px;
  width: 28px;
  height: 63px;
  position: absolute;
}
.frame52missegurosactivos-vector61 {
  top: 130.951171875px;
  left: 14.9365234375px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame52missegurosactivos-vector62 {
  top: 41.822181701660156px;
  left: 0px;
  width: 25px;
  height: 97px;
  position: absolute;
}
.frame52missegurosactivos-vector63 {
  top: 145.267578125px;
  left: 40.985107421875px;
  width: 32px;
  height: 114px;
  position: absolute;
}
.frame52missegurosactivos-vector64 {
  top: 61.007633209228516px;
  left: 58.9627799987793px;
  width: 18px;
  height: 32px;
  position: absolute;
}
.frame52missegurosactivos-group288892 {
  top: 212px;
  left: 262px;
  width: 657px;
  height: 81px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text072 {
  top: 16px;
  left: 162px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 495px;
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.frame52missegurosactivos-text073 {
  font-weight: 500;
}
.frame52missegurosactivos-g-r-u-p-o-i-n-s-u-r-e-x-s-v-g1 {
  top: 0px;
  left: 0px;
  width: 97px;
  height: 81px;
  position: absolute;
  border-color: transparent;
}
.frame52missegurosactivos-logo-lineadesgravamen-footer {
  top: 48.4041633605957px;
  left: 267.99993896484375px;
  width: 85.00013732910156px;
  height: 90.7261734008789px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-txt1 {
  top: 63.2845458984375px;
  left: 0px;
  width: 85.00013732910156px;
  height: 27.44162368774414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-group09 {
  top: 0.0002319185296073556px;
  left: 25.083524703979492px;
  width: 31.201702117919922px;
  height: 11.408534049987793px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector65 {
  top: 1.8503669500350952px;
  left: 0.000029019884095760062px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame52missegurosactivos-vector66 {
  top: 0.0002319185296073556px;
  left: 6.424156665802002px;
  width: 3px;
  height: 11px;
  position: absolute;
}
.frame52missegurosactivos-vector67 {
  top: 3.7005021572113037px;
  left: 10.094902992248535px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector68 {
  top: 3.7005021572113037px;
  left: 17.436397552490234px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector69 {
  top: 3.7005021572113037px;
  left: 24.777891159057617px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-group10 {
  top: 14.800132751464844px;
  left: -0.0001068115234375px;
  width: 81.3687515258789px;
  height: 12.6417236328125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector70 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}
.frame52missegurosactivos-vector71 {
  top: 2.15820574760437px;
  left: 8.258431434631348px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector72 {
  top: 2.15820574760437px;
  left: 15.600566864013672px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector73 {
  top: 2.15820574760437px;
  left: 22.024267196655273px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame52missegurosactivos-vector74 {
  top: 2.15820574760437px;
  left: 29.671905517578125px;
  width: 5px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector75 {
  top: 2.15820574760437px;
  left: 34.566802978515625px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector76 {
  top: 2.15820574760437px;
  left: 41.6019401550293px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector77 {
  top: 2.15820574760437px;
  left: 48.63771438598633px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector78 {
  top: 2.15820574760437px;
  left: 56.28514099121094px;
  width: 10px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector79 {
  top: 2.15820574760437px;
  left: 67.29759216308594px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector80 {
  top: 2.15820574760437px;
  left: 74.63908386230469px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-group11 {
  top: 14.14947509765625px;
  left: 81.75969696044922px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-group12 {
  top: 0.8171027898788452px;
  left: 1.0551077127456665px;
  width: 1.3501358032226562px;
  height: 1.633067011833191px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector81 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 2px;
  position: absolute;
}
.frame52missegurosactivos-group13 {
  top: 0px;
  left: 0px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector82 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame52missegurosactivos-img1 {
  top: 0px;
  left: 14.36742877960205px;
  width: 55.87344741821289px;
  height: 56.31800079345703px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector83 {
  top: 0.9916815161705017px;
  left: -0.00005660363603965379px;
  width: 20px;
  height: 45px;
  position: absolute;
}
.frame52missegurosactivos-vector84 {
  top: -0.0001346481585642323px;
  left: 17.706514358520508px;
  width: 38px;
  height: 56px;
  position: absolute;
}
.frame52missegurosactivos-socialwrapper {
  top: 212px;
  left: 1010px;
  width: 144px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-facebook-negative {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame52missegurosactivos-twitter-negative {
  top: 0px;
  left: 42.666015625px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame52missegurosactivos-instagram-negative {
  top: 0px;
  left: 85.333984375px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-group14 {
  top: 0px;
  left: 0px;
  width: 15.990626335144043px;
  height: 15.996874809265137px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector85 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame52missegurosactivos-vector86 {
  top: 3.890625px;
  left: 3.890625px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.frame52missegurosactivos-vector87 {
  top: 2.76953125px;
  left: 11.3125px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.frame52missegurosactivos-linked-in-negative {
  top: 0px;
  left: 128px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-group15 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector88 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame52missegurosactivos-minimi {
  top: 2676px;
  left: 1284px;
  width: 73px;
  height: 125px;
  position: absolute;
}
.frame52missegurosactivos-group288955 {
  top: 2623px;
  left: 626px;
  width: 188.49998474121094px;
  height: 29px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text075 {
  top: 2.0712890625px;
  color: var(--dl-color-colores_neutrales-50);
  width: 54px;
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-ellipse314 {
  top: 0px;
  left: 66.25px;
  width: 29px;
  height: 29px;
  position: absolute;
  border-color: transparent;
}
.frame52missegurosactivos-text077 {
  top: 2.0712890625px;
  left: 74.572265625px;
  color: var(--dl-color-gray-white);
  width: 11px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text078 {
  top: 2.0712890625px;
  left: 105.6435546875px;
  color: var(--dl-color-colores_neutrales-90);
  width: 11px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text079 {
  top: 2.0712890625px;
  left: 155.357421875px;
  color: var(--dl-color-colores_neutrales-90);
  width: 11px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text080 {
  top: 2.0712890625px;
  left: 136.71484375px;
  color: var(--dl-color-colores_neutrales-90);
  width: 17px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group288952 {
  top: 19.67822265625px;
  left: 183.3212890625px;
  width: 5.1785712242126465px;
  height: 10.357142448425293px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-vector89 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 10px;
  position: absolute;
}
.frame52missegurosactivos-frame492 {
  top: 34px;
  left: 908.142578125px;
  width: 385px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame52missegurosactivos-textsbtns-siper-header {
  width: 116px;
  height: 25px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 30px;
}
.frame52missegurosactivos-signo-accesibilidad {
  top: 2px;
  left: 0px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame52missegurosactivos-text082 {
  left: 30px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-textsbtns-siper-header1 {
  width: 104px;
  height: 25px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 30px;
}
.frame52missegurosactivos-text084 {
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-textsbtns-siper-header2 {
  width: 105px;
  height: 25px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-text086 {
  left: 34px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-polygon2 {
  top: 17px;
  left: 101px;
  width: 6px;
  height: 5px;
  position: absolute;
}
.frame52missegurosactivos-subwayworld1 {
  top: 2px;
  left: 6px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame52missegurosactivos-nombreultimoaccesovaloruf {
  top: 95px;
  left: 658px;
  width: 535px;
  height: 61px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-rectangle2393 {
  top: 0px;
  left: 0px;
  width: 424px;
  height: 61px;
  position: absolute;
}
.frame52missegurosactivos-text088 {
  top: 12px;
  left: 89px;
  color: var(--dl-color-gray-white);
  width: 258px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text090 {
  top: 33.16015625px;
  left: 80px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-rectangle2392 {
  top: 0px;
  left: 411px;
  width: 124px;
  height: 61px;
  position: absolute;
}
.frame52missegurosactivos-text092 {
  top: 12px;
  left: 438px;
  color: var(--dl-color-gray-white);
  width: 69px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text094 {
  top: 34.80078125px;
  left: 440px;
  color: var(--dl-color-gray-white);
  width: 65px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-vector90 {
  top: 26px;
  left: 375px;
  width: 8px;
  height: 17px;
  position: absolute;
}
.frame52missegurosactivos-group288947 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-ellipse313 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  position: absolute;
  border-color: transparent;
}
.frame52missegurosactivos-icon-btns {
  top: 7.988186836242676px;
  left: 7.988186836242676px;
  width: 45px;
  height: 45px;
  position: absolute;
}
.frame52missegurosactivos-frame288931 {
  /* top: 724px;
  left: 148px; */
  width: 1158px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame52missegurosactivos-cards-seguro-total {
  width: 1158px;
  height: 334px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 35px;
  margin-left: -35px;
}
.frame52missegurosactivos-rectangle2387 {
  top: 0px;
  left: 0px;
  width: 1158px;
  height: 333px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame52missegurosactivos-group288920 {
  top: 45px;
  left: 815.3367919921875px;
  width: 256px;
  height: 115px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text096 {
  color: rgba(255, 255, 255, 1);
  width: 61px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text098 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text100 {
  top: 96px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text102 {
  top: 67px;
  color: rgba(255, 255, 255, 1);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889181 {
  top: 128px;
  left: 46.360313415527344px;
  width: 350.4490966796875px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text104 {
  color: rgba(255, 255, 255, 1);
  width: 48px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text106 {
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 221px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text108 {
  top: 109px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text110 {
  top: 57px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 200px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text112 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text114 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  width: 101px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text116 {
  top: 45px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  width: 93px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text118 {
  top: 115px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text120 {
  top: 144px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text122 {
  top: 74px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text124 {
  top: 254px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text126 {
  top: 252px;
  left: 600px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text128 {
  top: 281px;
  left: 600px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text130 {
  top: 281px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-text132 {
  top: 211px;
  left: 494.8459777832031px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-group288912 {
  top: 1px;
  left: 1120px;
  width: 36.281982421875px;
  height: 325px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 184, 78, 1);
}
.frame52missegurosactivos-text134 {
  top: 155px;
  left: -28px;
  color: rgba(255, 255, 255, 1);
  width: 91px;
  height: auto;
  position: absolute;
  font-size: 20px;
  transform: rotate(-90deg);
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-line31 {
  top: 161px;
  left: 300px;
  width: 324px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-line32 {
  top: 150px;
  left: 690px;
  width: 188px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-group288921 {
  top: 20px;
  left: 51.399478912353516px;
  width: 373.58746337890625px;
  height: 60px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text136 {
  top: 21px;
  left: 75.58746337890625px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-vector91 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 60px;
  position: absolute;
}
.frame52missegurosactivos-text138 {
  top: 218px;
  left: 815.3367919921875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}

.frame52missegurosactivos-rectangle2394 {
  top: 267px;
  left: 797.19580078125px;
  width: 272px;
  height: 40px;
  position: absolute;
  border-color: transparent;
  border-radius: 100px;
}
.frame52missegurosactivos-text142 {
  top: 276px;
  left: 825.4151611328125px;
  color: var(--dl-color-colores_paleta-tonos_azul-50);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-icon-switch-o-n-o-f-f {
  top: 277px;
  left: 1008.8407592773438px;
  width: 34px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-group16 {
  top: 0px;
  left: 0px;
  width: 34.26629638671875px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-rectangle {
  top: 3px;
  left: 0px;
  width: 34px;
  height: 14px;
  position: absolute;
  border-color: rgba(150, 150, 150, 1);
  border-radius: 7px;
}
.frame52missegurosactivos-oval {
  top: -22px;
    margin-left: -4px;
    /* left: 0px; */
    width: 20px;
    height: 20px;
    position: absolute;
    -webkit-box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%);
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%);
}
.frame52missegurosactivos-oval1 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.frame52missegurosactivos-cards-seguro-total1 {
  width: 1158px;
  height: 334px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 35px;
}
.frame52missegurosactivos-rectangle23871 {
  top: 1px;
  left: 0px;
  width: 1158px;
  height: 333px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame52missegurosactivos-group2889201 {
  top: 45px;
  left: 815.3367919921875px;
  width: 256px;
  height: 131px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text144 {
  color: rgba(255, 255, 255, 1);
  width: 61px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text146 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text148 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text150 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889182 {
  top: 128px;
  left: 46.360313415527344px;
  width: 350.4490966796875px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text152 {
  color: rgba(255, 255, 255, 1);
  width: 48px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text154 {
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text156 {
  top: 109px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text158 {
  top: 57px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 105px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text160 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text162 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  width: 101px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group288919 {
  top: 41px;
  left: 494.8459777832031px;
  width: 256px;
  height: 259px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text164 {
  color: rgba(255, 255, 255, 1);
  width: 93px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text166 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text168 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text170 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text172 {
  top: 213px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text174 {
  top: 211px;
  left: 105.82241821289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text176 {
  top: 240px;
  left: 103.80679321289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text178 {
  top: 240px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text180 {
  top: 170px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text182 {
  top: 279px;
  left: 815.3367919921875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-text184 {
  top: 248px;
  left: 813.3211059570312px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-group2889121 {
  top: 1px;
  left: 1120px;
  width: 36.281982421875px;
  height: 325px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(12, 176, 38, 1);
}
.frame52missegurosactivos-text186 {
  top: 155px;
  left: -23px;
  color: rgba(255, 255, 255, 1);
  width: 82px;
  height: auto;
  position: absolute;
  font-size: 20px;
  transform: rotate(-90deg);
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-line311 {
  top: 161px;
  left: 300px;
  width: 324px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-line321 {
  top: 150px;
  left: 690px;
  width: 188px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-group2889211 {
  top: 20px;
  left: 51.399478912353516px;
  width: 373.58746337890625px;
  height: 60px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text188 {
  top: 21px;
  left: 75.58746337890625px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-vector92 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 60px;
  position: absolute;
}
.frame52missegurosactivos-cards-seguro-total2 {
  width: 1158px;
  height: 334px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 35px;
}
.frame52missegurosactivos-rectangle23872 {
  top: 1px;
  left: 0px;
  width: 1158px;
  height: 333px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame52missegurosactivos-group2889202 {
  top: 45px;
  left: 815.3367919921875px;
  width: 256px;
  height: 131px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text190 {
  color: rgba(255, 255, 255, 1);
  width: 61px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text192 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text194 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text196 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889183 {
  top: 128px;
  left: 46.360313415527344px;
  width: 350.4490966796875px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text198 {
  color: rgba(255, 255, 255, 1);
  width: 48px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text200 {
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text202 {
  top: 109px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text204 {
  top: 57px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 105px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text206 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text208 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  width: 101px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889191 {
  top: 41px;
  left: 494.8459777832031px;
  width: 256px;
  height: 259px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text210 {
  color: rgba(255, 255, 255, 1);
  width: 93px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text212 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text214 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text216 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text218 {
  top: 213px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text220 {
  top: 211px;
  left: 105.82241821289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text222 {
  top: 240px;
  left: 103.80679321289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text224 {
  top: 240px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text226 {
  top: 170px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889122 {
  top: 1px;
  left: 1120px;
  width: 36.281982421875px;
  height: 325px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(163, 169, 181, 1);
}
.frame52missegurosactivos-text228 {
  top: 155px;
  left: -38px;
  color: rgba(255, 255, 255, 1);
  width: 115px;
  height: auto;
  position: absolute;
  font-size: 20px;
  transform: rotate(-90deg);
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-line312 {
  top: 161px;
  left: 300px;
  width: 333px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-line322 {
  top: 150px;
  left: 690px;
  width: 188px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-group2889212 {
  top: 20px;
  left: 51.399478912353516px;
  width: 373.58746337890625px;
  height: 60px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text230 {
  top: 21px;
  left: 75.58746337890625px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-vector93 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 60px;
  position: absolute;
}
.frame52missegurosactivos-text232 {
  top: 279px;
  left: 815.3367919921875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-text234 {
  top: 248px;
  left: 813.3211059570312px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-cards-seguro-total3 {
  width: 1158px;
  height: 334px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 35px;
}
.frame52missegurosactivos-rectangle23873 {
  top: 1px;
  left: 0px;
  width: 1158px;
  height: 333px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame52missegurosactivos-group2889203 {
  top: 45px;
  left: 815.3367919921875px;
  width: 256px;
  height: 131px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text236 {
  color: rgba(255, 255, 255, 1);
  width: 61px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text238 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text240 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text242 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889184 {
  top: 128px;
  left: 46.360313415527344px;
  width: 350.4490966796875px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text244 {
  color: rgba(255, 255, 255, 1);
  width: 48px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text246 {
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text248 {
  top: 109px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text250 {
  top: 57px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 105px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text252 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text254 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  width: 101px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889192 {
  top: 41px;
  left: 494.8459777832031px;
  width: 256px;
  height: 259px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text256 {
  color: rgba(255, 255, 255, 1);
  width: 93px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text258 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text260 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text262 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text264 {
  top: 213px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text266 {
  top: 211px;
  left: 105.82241821289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text268 {
  top: 240px;
  left: 103.80679321289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text270 {
  top: 240px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text272 {
  top: 170px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889123 {
  top: 1px;
  left: 1121.718017578125px;
  width: 36.281982421875px;
  height: 325px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(163, 169, 181, 1);
}
.frame52missegurosactivos-text274 {
  top: 155px;
  left: -24px;
  color: rgba(255, 255, 255, 1);
  width: 85px;
  height: auto;
  position: absolute;
  font-size: 20px;
  transform: rotate(-90deg);
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-line313 {
  top: 161px;
  left: 300px;
  width: 333px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-line323 {
  top: 150px;
  left: 690px;
  width: 188px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-group2889213 {
  top: 20px;
  left: 51.399478912353516px;
  width: 375.9216613769531px;
  height: 60px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text276 {
  top: 21px;
  left: 75.58746337890625px;
  color: rgba(255, 255, 255, 1);
  width: 300px;
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-vector94 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 60px;
  position: absolute;
}
.frame52missegurosactivos-text278 {
  top: 279px;
  left: 815.3367919921875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-text280 {
  top: 248px;
  left: 813.3211059570312px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-cards-seguro-total4 {
  width: 1158px;
  height: 334px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-rectangle23874 {
  top: 1px;
  left: 0px;
  width: 1158px;
  height: 333px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame52missegurosactivos-group2889204 {
  top: 45px;
  left: 815.3367919921875px;
  width: 256px;
  height: 131px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text282 {
  color: rgba(255, 255, 255, 1);
  width: 61px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text284 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text286 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text288 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889185 {
  top: 128px;
  left: 46.360313415527344px;
  width: 350.4490966796875px;
  height: 135px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text290 {
  color: rgba(255, 255, 255, 1);
  width: 48px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text292 {
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text294 {
  top: 109px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text296 {
  top: 57px;
  left: 186.4490966796875px;
  color: rgba(255, 255, 255, 1);
  width: 105px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text298 {
  top: 57px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text300 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  width: 101px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889193 {
  top: 41px;
  left: 494.8459777832031px;
  width: 256px;
  height: 259px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text302 {
  color: rgba(255, 255, 255, 1);
  width: 93px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text304 {
  top: 83px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text306 {
  top: 112px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text308 {
  top: 29px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text310 {
  top: 213px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text312 {
  top: 211px;
  left: 105.82241821289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text314 {
  top: 240px;
  left: 103.80679321289062px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text316 {
  top: 240px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-text318 {
  top: 170px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-group2889124 {
  top: 1px;
  left: 1121.718017578125px;
  width: 36.281982421875px;
  height: 325px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(163, 169, 181, 1);
}
.frame52missegurosactivos-text320 {
  top: 155px;
  left: -24px;
  color: rgba(255, 255, 255, 1);
  width: 85px;
  height: auto;
  position: absolute;
  font-size: 20px;
  transform: rotate(-90deg);
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-line314 {
  top: 161px;
  left: 300px;
  width: 333px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-line324 {
  top: 150px;
  left: 690px;
  width: 188px;
  border: 1px solid #FFFFFF;
  height: 0px;
  position: absolute;
  transform: rotate(-90deg);
}
.frame52missegurosactivos-group2889214 {
  top: 20px;
  left: 51.399478912353516px;
  width: 375.9216613769531px;
  height: 60px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-text322 {
  top: 21px;
  left: 75.58746337890625px;
  color: rgba(255, 255, 255, 1);
  width: 300px;
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame52missegurosactivos-vector95 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 60px;
  position: absolute;
}
.frame52missegurosactivos-text324 {
  top: 279px;
  left: 815.3367919921875px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-text326 {
  top: 248px;
  left: 813.3211059570312px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame52missegurosactivos-menuinterno {
  top: 224px;
  left: 148px;
  width: 1146px;
  height: 60px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.frame52missegurosactivos-rectangle2385 {
  top: 0px;
  left: 0px;
  width: 1146px;
  height: 60px;
  position: absolute;
  border-color: transparent;
  border-radius: 100px;
}
.frame52missegurosactivos-frame288925 {
  top: 13px;
  left: 65.9991683959961px;
  width: 1013px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
}
.frame52missegurosactivos-text328 {
  color: var(--dl-color-colores_paleta-tonos_morado-50);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-line {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text330 {
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: ExtraBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 800;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-line1 {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text332 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-line2 {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text334 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-line3 {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text336 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-vector96 {
  width: 5px;
  height: 10px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-line4 {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text338 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-vector97 {
  width: 5px;
  height: 10px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-line5 {
  width: 35px;
  height: 0px;
  position: relative;
  margin-right: 16px;
}
.frame52missegurosactivos-text340 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-frame517 {
  top: 316px;
  left: 148px;
  width: 1144px;
  height: 256px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
}
.frame52missegurosactivos-header {
  top: 85px;
  left: 51px;
  width: 420px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}
.frame52missegurosactivos-header-subheadand-avatar-autolayout {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  margin-right: 0px;
}
.frame52missegurosactivos-header-subhead-autolayout {
  width: 388px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame52missegurosactivos-text342 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 40px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}
.frame52missegurosactivos-text344 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 338px;
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-frame496 {
  top: 33px;
  left: 67px;
  width: 255px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.frame52missegurosactivos-textorecuperadinerodetucredito {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame52missegurosactivos-text346 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 50px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 69px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame52missegurosactivos-maskgroup {
  top: 316px;
  left: 515.8291015625px;
  width: 778.1710205078125px;
  height: 356px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame52missegurosactivos-rectangle2381 {
  top: 0px;
  left: 103.1708984375px;
  width: 675px;
  height: 256px;
  position: absolute;
  border-color: transparent;
}
.frame52missegurosactivos-icon-btns1 {
  top: 112px;
  left: 1254px;
  width: 38px;
  height: 27px;
  position: absolute;
}

</style>
