<template>
    <div>
        <v-dialog v-model="dialogSeguro" class="modalSize" max-width="100%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">    
                                    MI SIMULACIÓN</div>
                               
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="">
                                    <v-form ref="form" >

                                        <v-row dense class="colcenter">
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    Devolución aproximada de Prima no Consumida:
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="colcenter">
                                                <div class="valores">{{ Seguro.seg_sim_devolucion_calculada }}</div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    Valor nuevo Seguro y gasto operacional:
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="colcenter">
                                                <div class="valores">{{ Seguro.seg_valor_prima_costo_operativo }}</div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    Saldo a Favor Cliente Aproximado:
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="colcenter">
                                                <div class="valores">{{ Seguro.seg_sim_saldo_final_cliente }}</div>
                                            </v-col>
                                         
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="!Seguro.seg_usu_credito_nuevo">
                                                <div
                                                    class="el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada">
                                                    EL Retiro desde su Billetera Electrónica será transferido a su cuenta bancaria personal registrada, una vez instruido por Usted.
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="line-1"></div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <v-btn rounded x-large @click="cierra" width="180"
                                                    color="#1d2eff"><span><span style="color:white;width: 180px;"
                                                            class="submit">ACEPTAR</span></span></v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>

export default {
    name: "ModalDetalleSeguro",
    data() {
        return {
            overlay : false,
            dialogSeguro: false,           
        }
    },
    props: {
        Seguro: {},

    },
    methods: {
        cierra() {
            this.dialogSeguro = false;
        },
    },
    created() {
        this.$root.$refs.ModalDetalleSeguro = this;
    }
}
</script>
<style scoped>
.colcenter {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 70%;
}

.marcoDialog {
    background: var(--blanco, #f0f2f2);

}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 33px;
    height: 33px;
    position: relative;
    overflow: visible;
}

.el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem,5vw,1.7rem);
    line-height: clamp(1rem,5vw,1.7rem);
    position: relative;
    width: 100%;
}

.devoluci-n-aproximada-de-prima-no-consumida {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem,5vw,1.7rem);
    line-height: clamp(1rem,5vw,1.7rem);
    position: relative;
}

.valores {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 36px;
    line-height: 36px; */
    font-size: clamp(0.8rem,5vw,1.7rem);
    line-height: clamp(1rem,5vw,1.6rem);
    font-weight: 900;
    position: relative;

}
.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem,5vw,2.2rem);
    line-height: clamp(1.3rem,5vw,2.6rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .dialogStyle {
        width: 100% !important;
    }
    .vector {
        width: 20px !important;
        height: 20px !important;
    }
}
</style>