import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { mdiGenderTransgender } from "@mdi/js";
Vue.use(Vuex);

const ENDPOINT_PATH = "/api/";

const state = {

  // HOME
  islogin: true,
  estadoFormLinea: 1,
  estadoFormCotizacion: 1,
  clienteEncontrado: false,
  representanteEncontrado: false,
  tipoPersonaEmpresa: "persona",
  tipoCotizacion: "vigente",
  inicioDisabled: false,
  estadoFormCotizacionPrivado: 1,
  seleccionGenero: "",
  seleccionNacionalidad: "CHILE",
  seleccionOcupacion: "",
  restricciones: [],
  estadoPrivado : false,
  seguroSeleccion : false,
  planSeleccion : false,
  activaRenuncia : false,
  estadoSeguro: 0,
  estadoFea:0,

  estadoIdentidad : 0,
  estadoDocumentos : 0,
  estadoFirmas : 0,
  firma : false,
  estadoMandato : 0,
  estadoRenuncia : 0,
  estadoPrepago : 0,
  estadoCreditoCarga : 0,
  estadoCotizacion : 0,

  estadoTransaccionPago : false,

  tipoDocFirma : 1,
  firmaMandato : {
    urlValidacion : "",
    oid : "",
    exeCode : "",
    seguro : ""
  },
  firmaRenuncia : {
    urlValidacion : "",
    oid : "",
    exeCode : "",
    seguro : ""
  },

  seleccionMenuWallet: 0,
  clienteWallet: {
    numero : 0,
    saldo : "$ 0"
  },

  bancos : [],
  tipoCuentas : [],
  
  //PRIVADO
  cotizacionesCarro : [],
  formCuenta: {
    nombre1 : "",
    nombre2 : "",
    apellido1 : "",
    apellido2 : "",
    mail: "",
    celular: "",
    direccion: "",
    depto: 0,
    numero: "",
    region: "",
    ciudad: "",
    comuna: "",
    clave: "",
    nacionalidad: "",
    genero: "",
    ocuacion: ""
  },
  nacionalidad : ["Chile","Argentina","Brasil"],
  genero : ["Femenino","Masculino","Mirandao"],
  ocupacion : ["Ingeniero","Técnico","Programador"],
  regiones : ["Metropolitana","Ñuble"],
  comunasRegiones :[
    {region : "Metropolitana" ,
      nombre :["Maipú","San Joaquín"]
    },
    {region : "Ñuble" ,
      nombre :["Coelemu","Bulnes"]
    },
  ],
  selectMenu: 1,
  misSeguros: [],
  montoSaldos:{
    csa_ctt_numero:0,
    csa_monto_total_origen:0,
    csa_monto_utilizado_origen:0,
    csa_monto_disponible_origen:0,
    csa_fecha_actualizacion:"",
    csa_monto_total_lc:0,
    csa_monto_utilizado_lc:0,
    csa_monto_disponible_lc:0,
    csa_fecha_cambio:"",
    csa_tipo_moneda:"",
    csa_valor_cambio:0
    },
  //
  loginEstado: {
    acc_con_acceso: 0,
    acc_lista_negra: 0,
    acc_cuenta_bloqueda: 0,
    acc_clave_expirada: 0,
    acc_clave_invalida: 0,
    acc_clave_nueva: 0
},
loginUsuario: {
  acc_rut: "",
  acc_clave: "",
  acc_haswing: "",
  acc_fecha_expira: "",
  acc_estado: 0,
  acc_glosa_estado: "",
  acc_tipo_cliente: 0,
  acc_nombre_principal: "",
  acc_nombre_segundo: "",
  acc_ape_paterno: "",
  acc_ape_materno: "",
  acc_dv: null,
  rut_rep: ""
},
contratoPlan:[],
planContratado:{
  cse_numero:0,
  cse_ctt_numero:0,
  cse_pln_numero:0,
  cse_pln_glosa:"",
  cse_sim_monto_prima_cl:0,
  cse_sim_monto_prima_uf:0,
  cse_sim_monto_credito:0,
  cse_pln_fecha_inicio:"",
  cse_pln_fecha_termino:"",
  cse_compania:"",
  cse_poliza:0,
  cse_nombre:"",
  cse_rut:"",
  cse_celular:"",
  cse_email:"",
  cse_calle:"",
  cse_nro:"",
  cse_depto:"",
  cse_region:"",
  cse_ciudad:"",
  cse_comuna:"",
  cse_transaccion:0,
  cse_fecha_transaccion:""
},
datosContratoSeleccionado:[],
datosContrato:
  {
    ctt_numero: 0,
    ctt_simulacion: 0,
    ctt_rut_contrato: 0,
    ctt_dv_contrato: 0,
    ctt_rut_representante: 0,
    ctt_dv_representante: 0,
    ctt_edad_representante: 0,
    ctt_tipo_cliente: 0,
    ctt_producto: 0,
    ctt_plan: 0,
    ctt_tipo_moneda: 0,
    ctt_monto: 0,
    ctt_fecha_suscripcion: "",
    ctt_fecha_inicio: "",
    ctt_fecha_termino: "",
    ctt_estado_login: 0,
    ctt_estado: 0,
    per_rut: "",
    per_nombre: "",
    per_fec_nac: "",
    per_celular: "",
    per_fono: "",
    per_email: "L",
    per_sexo: 0,
    per_estado: 0
    }
,
estadoConfirmacion : {
  Vci: "",
  Amount: "",
  Status: "",
  BuyOrder: "",
  SessionId: "",
  CardNumber: "",
  AccountingDate: "",
  TransactionDate: "",
  AuthorizationCode: "",
  PaymentTypeCode: "",
  ResponseCode: 0,
  InstallmentsAmount: 0.0,
  InstallmentsNumber: 0.0,
  Balance: 0.0
},
  estadoCliente: {
    seguro_estado: 0,
    tiene_contrato: 0,
    tiene_login: 0,
    tiene_linea_desgravamen: 0,
    tiene_seguro_contratado: 0,
    esta_lista_negra: 0,
    texto_principal: "",
    texto_secundario: ""
},

  cliente: {
    nombre : ""
  },
  formHome: {
    nombre : "",
    correo : "",
    telefono : ""
  },

  infoLegal: {
    info1 : {
      titulo : "Circular 2114 CMF",
      detalle : "Aquí podrás ver + info. de tu línea de desgravamen",
      imagen : "imgHome1",
      archivo : ""
    },
    info2 : {
      titulo : "Circular 114 CMF",
      detalle : "Aquí podrás ver + info. de tu línea de desgravamen",
      imagen : "imgHome2",
      archivo : ""
    },
    info3 : {
      titulo : "Ley 24.302",
      detalle : "Aquí podrás ver + info. de tu línea de desgravamen",
      imagen : "imgHome3",
      archivo : ""
    }
  },
  pfrecuente:
      [
        {
          titulo: "Intem",
          detalle: "",
        },
        {
          titulo: "Intem",
          detalle: ""
        },
        {
          titulo: "Intem",
          detalle: ""
        },
        {
          titulo: "Intem",
          detalle: ""
        },
        {
          titulo: "Intem",
          detalle: ""
        },
        {
          titulo: "Intem",
          detalle: ""
        },
      ]
  ,
   //LINEA

  form1: {
    rut: "",
    nombre1: "",
    nombre2: "",
    apaterno: "",
    amaterno: "",
    nacionalidad: "9",
    genero: ""
  },
  
  form2: {
    email: "",
    celular: "",
    date: "",
    ocupacion: ""
  },
  forme1: {
    rut: "",
    nombre1: "",
    telefono: "",
    email: "",
  },
  forme2: {
    rut: "",
    nombre1: "",
    nombre2: "",
    apaterno: "",
    amaterno: "",
    date: "",
    nacionalidad: "9",
    genero: "",
    ocupacion: ""
  },
  plan: "",
  form3: {
    c1: "",
    c2: "",
    c3: "",
    c4: "",
  },

  tipoPersonaEmpresaDisabled: true,
  simulacion: {
    sim_numero: "",
    sim_rut_contrato: "",
    sim_rut_representante: "",
    sim_tipo_cliente: "",
    sim_fecha: "",
    sim_lcr_numero: "",
    sim_estado: "",
    sim_producto: "",
    sim_glosa_producto: "",
    sim_edad_desde: "",
    sim_edad_hasta: "",
    sim_monto: "",
    sim_tipo_moneda: "",
    sim_con_dps: ""
  },
  seguro: "2.000 UF",
  dps: [
    {
      prg_titulo : "PRUEBa PRUEBa PRUEBa PRUEBa PRUEBa",
      respuesta: "",
      cres: false
    },
    {
      prg_titulo : "PRUEBa 11111 11111 11111 PRUEBa",
      respuesta: "",
      cres: true
    },
    {
      prg_titulo : "PRUEBa 222222 222222 222222 PRUEBa",
      respuesta: "",
      cres: false
    },
    {
      prg_titulo : "PRUEBa 33333 33333 33333 PRUEBa",
      respuesta: "",
      cres: false
    },
    {
      prg_titulo : "PRUEBa 44444 44444 44444 PRUEBa",
      respuesta: "",
      cres: false
    },
  ],
  contdps: 0,
  estadodps: false,
  terminos: {
    tco_numero: "",
    tco_titulo: "",
    tco_sub_titulo: "",
    tco_texto: "",
    ptc_ldp_numero: ""
  },

    contrato: {
        ctt_numero: "",
        ctt_simulacion: "",
        ctt_tipo_cliente: "",
        ctt_rut: "",
        ctt_fecha_suscripcion: "",
        ctt_fecha_inicio: "",
        ctt_fecha_termino: "",
        ctt_glosa: "",
        ctt_estado: ""
    },
  //COTIZACION

 
  
  instituciones : ["Banco","Cooperativa"],
  nombreIntituciones :[
    {tipo : "Cooperativa" ,
      nombre :["Los Andes","Los Herores"]
    },
    {tipo : "Banco" ,
      nombre :["Banco de inversiones","Banco internacional"]
    },
  ],
  nombreinst : [],
  form4 :{
    tipoInstitucion : "",
    nombreInstitucion : "",
    saldoCredito : 0,
    mesesPago : 0,
  },
  codInst: 0,
  codNInst: 0,
  mPago : ["6 meses","12 meses","18 meses","24 meses","48 meses","60 meses"],
  mPendientePago : ["6 meses","12 meses","18 meses","24 meses","48 meses","60 meses"],
  mGracia : ["1 meses","2 meses","3 meses","4 meses","5 meses","6 meses"],
  form5 :{
    saldoInsolutoCredito : 0,
    mesesPendientePago : 0,
    numeroCredito : "",
    valorCuota : 0,
    fechaCuota : 0,
  },
  estadoBps: 1,
  bps : [
    {
      respuesta: "",
        titulo: "¿Esta es la pregunta 1?",

        texto_c1f1: "-Lorem Ipsum is simply",
        texto_c1f2: "-Lorem Ipsum is simply",
        texto_c1f3: "-Lorem Ipsum is simply",
        texto_c1f4: "-Lorem Ipsum is simply",
        texto_c1f5: "-Lorem Ipsum is simply",

        texto_c2f1: "-Lorem Ipsum is simply",
        texto_c2f2: "-Lorem Ipsum is simply",
        texto_c2f3: "-Lorem Ipsum is simply",
        texto_c2f4: "-Lorem Ipsum is simply",
        texto_c2f5: "-Lorem Ipsum is simply",

        texto_c3f1: "-Lorem Ipsum is simply",
        texto_c3f2: "-Lorem Ipsum is simply",
        texto_c3f3: "-Lorem Ipsum is simply",
        texto_c3f4: "-Lorem Ipsum is simply",
        texto_c3f5: "-Lorem Ipsum is simply",
    },
    {
      respuesta: "",
        titulo: "¿Esta es la pregunta 2?",

        texto_c1f1: "-Lorem Ipsum is simply",
        texto_c1f2: "-Lorem Ipsum is simply",
        texto_c1f3: "-Lorem Ipsum is simply",
        texto_c1f4: "-Lorem Ipsum is simply",
        texto_c1f5: "-Lorem Ipsum is simply",

        texto_c2f1: "-Lorem Ipsum is simply",
        texto_c2f2: "-Lorem Ipsum is simply",
        texto_c2f3: "-Lorem Ipsum is simply",
        texto_c2f4: "-Lorem Ipsum is simply",
        texto_c2f5: "-Lorem Ipsum is simply",

        texto_c3f1: "-Lorem Ipsum is simply",
        texto_c3f2: "-Lorem Ipsum is simply",
        texto_c3f3: "-Lorem Ipsum is simply",
        texto_c3f4: "-Lorem Ipsum is simply",
        texto_c3f5: "-Lorem Ipsum is simply",
    },
    {
      respuesta: "",
        titulo: "¿Esta es la pregunta 3?",

        texto_c1f1: "-Lorem Ipsum is simply",
        texto_c1f2: "-Lorem Ipsum is simply",
        texto_c1f3: "-Lorem Ipsum is simply",
        texto_c1f4: "-Lorem Ipsum is simply",
        texto_c1f5: "-Lorem Ipsum is simply",

        texto_c2f1: "-Lorem Ipsum is simply",
        texto_c2f2: "-Lorem Ipsum is simply",
        texto_c2f3: "-Lorem Ipsum is simply",
        texto_c2f4: "-Lorem Ipsum is simply",
        texto_c2f5: "-Lorem Ipsum is simply",

        texto_c3f1: "-Lorem Ipsum is simply",
        texto_c3f2: "-Lorem Ipsum is simply",
        texto_c3f3: "-Lorem Ipsum is simply",
        texto_c3f4: "-Lorem Ipsum is simply",
        texto_c3f5: "-Lorem Ipsum is simply",
    }
  ],
  propuestaseguro : {
    monto : "550.000",
    uf : "14,68"
  },
  seguros : [
    {
      ahorro: "200.000",
      monto : "350.000",
      uf : "1,68",
      glosa1 : "-Lorem Ipsum is simply",
      glosa2 : "-Lorem Ipsum is simply",
      glosa3 : "-Lorem Ipsum is simply",
      seleccion : false,
      comparar : false,
      terminos : ""
    },
    {
      ahorro: "500.000",
      monto : "450.000",
      uf : "2,68",
      glosa1 : "-Lorem Ipsum is simply",
      glosa2 : "-Lorem Ipsum is simply",
      glosa3 : "-Lorem Ipsum is simply",
      seleccion : false,
      comparar : false,
      terminos : ""
    },
    {
      ahorro: "500.000",
      monto : "450.000",
      uf : "2,68",
      glosa1 : "-Lorem Ipsum is simply",
      glosa2 : "-Lorem Ipsum is simply",
      glosa3 : "-Lorem Ipsum is simply",
      seleccion : false,
      comparar : false,
      terminos : ""
    }
  ],
  seleccionSeguros: false,
  parrillaSimulacion : {},
  seg: {
    simulacionSeguro: {
      seg_numero: 20,
      seg_simulacion_numero: 21,
      seg_rut_contrato: 67373738,
      seg_rut_representante: 18939395,
      seg_tipo_cliente: 2,
      seg_sim_institucion_tasa_seguro: 0.99,
      seg_sim_institucion_tasa_credito: 2.77,
      seg_sim_plazo: 14,
      seg_sim_plazo_meses: 12,
      seg_sim_monto_prima: 24.948,
      seg_sim_valor_seguro: 9.006,
      seg_estado: 1,
    },
    parrillaSimulacion: [
      {
        spl_numero: 22,
        spl_seguro_numero: 20,
        spl_pln_numero: 1,
        spl_pln_glosa: "Plan 1",
        spl_pln_nemo: "AA",
        spl_pln_tipo_moneda: 1,
        spl_pln_glosa_tipo_moneda: "UF",
        spl_pln_poliza: 2,
        spl_pln_numero_poliza: "797999",
        spl_pln_compania: 58,
        spl_pln_nombre_compania:
          "ASSURANT CHILE COMPAÑIA DE SEGUROS GENERALES S.A.",
        spl_pln__orden: 1,
        spl_pln_destacado: true,
        spl_tar_numero: 5,
        spl_tar_con_fecha_termino: true,
        spl_tar_fecha_inicio: "2023-01-18T00:00:00",
        spl_tar_fecha_termino: "2023-01-18T00:00:00",
        spl_tar_nemo: "QQ",
        spl_tar_monto_credito_minimo: 1201,
        spl_tar_monto_credito_maximo: 3000,
        spl_tar_tasas: 0.56,
        spl_sim_monto_prima_cl: 0,
        spl_sim_monto_prima_uf: 1008.000,
        spl_sim_compara_ahorro: -998.993,
        spl_sim_credito_nuevo: true,
        spl_sim_monto_credito: 1800,
        spl_sim_saldo_insoluto: 1800,
        spl_sim_valor_seguro: 9.006,
        spl_estado: 1,
        seleccion : false,
        comparar : false,
        terminos : "",
        beneficios: [
          {
            pbe_glosa: "Benficio 1 del pLan 1",
          },
          {
            pbe_glosa: "Beneficio 2 del Plan 1",
          },
          {
            pbe_glosa: "Beneficio 3 del Plan 1",
          },
        ],
      },
      {
        spl_numero: 23,
        spl_seguro_numero: 20,
        spl_pln_numero: 2,
        spl_pln_glosa: "PLan 2",
        spl_pln_nemo: "BB",
        spl_pln_tipo_moneda: 1,
        spl_pln_glosa_tipo_moneda: "UF",
        spl_pln_poliza: 1,
        spl_pln_numero_poliza: "POL-0001",
        spl_pln_compania: 3,
        spl_pln_nombre_compania: "BANCHILE SEGUROS DE VIDA S.A.",
        spl_pln__orden: 2,
        spl_pln_destacado: true,
        spl_tar_numero: 6,
        spl_tar_con_fecha_termino: true,
        spl_tar_fecha_inicio: "2023-01-18T00:00:00",
        spl_tar_fecha_termino: "2023-01-18T00:00:00",
        spl_tar_nemo: "BB2",
        spl_tar_monto_credito_minimo: 1501,
        spl_tar_monto_credito_maximo: 2500,
        spl_tar_tasas: 0.68,
        spl_sim_monto_prima_cl: 0,
        spl_sim_monto_prima_uf: 1224,
        spl_sim_compara_ahorro: -1214.9935018050542,
        spl_sim_credito_nuevo: true,
        spl_sim_monto_credito: 1800,
        spl_sim_saldo_insoluto: 1800,
        spl_sim_valor_seguro: 9.006498194945848,
        spl_estado: 1,
        seleccion : false,
        comparar : false,
        terminos : "",
        beneficios: [
          {
            pbe_glosa: "Benficio 20 del Plan 2",
          },
          {
            pbe_glosa: "Benficio 25 del Plan 2",
          },
          {
            pbe_glosa: "Beneficio 30",
          },
        ],
      },
      {
        spl_numero: 24,
        spl_seguro_numero: 20,
        spl_pln_numero: 3,
        spl_pln_glosa: "PLan 3",
        spl_pln_nemo: "CC",
        spl_pln_tipo_moneda: 1,
        spl_pln_glosa_tipo_moneda: "UF",
        spl_pln_poliza: 3,
        spl_pln_numero_poliza: "DEPTO-888",
        spl_pln_compania: 52,
        spl_pln_nombre_compania: "BBVA SEGUROS GENERALES S.A.",
        spl_pln__orden: 3,
        spl_pln_destacado: true,
        spl_tar_numero: 10,
        spl_tar_con_fecha_termino: true,
        spl_tar_fecha_inicio: "2023-01-25T00:00:00",
        spl_tar_fecha_termino: "2023-01-25T00:00:00",
        spl_tar_nemo: "OO",
        spl_tar_monto_credito_minimo: 1501,
        spl_tar_monto_credito_maximo: 2500,
        spl_tar_tasas: 0.47,
        spl_sim_monto_prima_cl: 0,
        spl_sim_monto_prima_uf: 846,
        spl_sim_compara_ahorro: -836.9935018050542,
        spl_sim_credito_nuevo: true,
        spl_sim_monto_credito: 1800,
        spl_sim_saldo_insoluto: 1800,
        spl_sim_valor_seguro: 9.006498194945848,
        spl_estado: 1,
        seleccion : false,
        comparar : false,
        terminos : "",
        beneficios: [
          {
            pbe_glosa: "Beneficio 50 del plan 3",
          },
          {
            pbe_glosa: "Beneficio 1000 del Plan 3",
          },
          {
            pbe_glosa: "Beneficio 900 del plan 3",
          },
        ],
      },
    ],
  },
  bitacora : {
    bitacoraPlan:{
      spl_numero:1,
      spl_seguro_numero:1,
      spl_pln_numero:1,
      cse_numero:0,
      cse_ctt_numero:1
    },
    bitacoraPago:{
      estado_pago:0,
      cce_transaccion:0
    }
  },
    
  estadoFormPrivado: 1,
  contratarLinea: false,


  propuestaCreditoNuevo :
  {
      spl_numero: 0,
      // spl_seg_numero: 190,
      // spl_pln_numero: 1,
      // spl_pln_glosa: "Plan Seguro",
      // spl_sim_monto_prima_cl: "$ 459.360",
      // spl_sim_monto_prima_uf: "UF 14,86",
      // spl_sim_monto_credito: "$ 30.000.000",
      // spl_sim_saldo_insoluto: "$ 0",
      // spl_pln_fecha_inicio: "15/12/2023",
      // spl_pln_fecha_termino: "15/12/2023",
      // spl_compania: "ASSURANT CHILE COMPAÑIA DE SEGUROS GENERALES S.A.",
      // spl_poliza: "797999",
      // spl_nombre: "kjkjhkjh jhkjhkjh jhkjhkjh jkhkjhkjh",
      // spl_rut: "76.177.116-7",
      // spl_celular: "+56976876876",
      // spl_email: "projas@apolo.cl",
      // spl_calle: "sdds",
      // spl_nro: "123",
      // spl_depto: null,
      // spl_region: "Región de Magallanes y de la Antartica Chilena",
      // spl_ciudad: "Primavera",
      // spl_comuna: "Primavera",
      // spl_domicilio: "23",
      // spl_estado: 1,
      // spl_glosa_estado: "Pendiente",
      // spl_valor_cuota: "$ 25.141"
  },
  propuestaCreditoVigente: {
     seg_numero: 0,
    // spl_numero: 1,
    // p1_glosa_costo_seguro: "El costo original del seguro actual fue de:",
    // p1_costo_seguro: "$ 2.785.440",
    // p1_glosa_costo_final_credito: "Por estar incluido en el crédito, terminarás pagando:",
    // p1_costo_final_credito: "$ 4.158.057",
    // p1_glosa_devolucion: "Si renuncias hoy a este seguro, tendrás una devolución de:",
    // p1_devolucion: "$ 242.384",
    // p1_glosa_valor_nuevo_seguro: "Por el Saldo de Tu crédito te ofrecemos un seguro",
    // p1_valor_nuevo_seguro: "$ 242.384",
    // p1_glosa_seguro_se_paga: "Este Seguro se paga con la Devolución que se obtiene al renunciar a tu seguro actual.",
    // p1_glosa_saldo_favor: "Nosotros tramitamos la Renuncia y Devolución de tu Seguro actual y quedas con un saldo a favor de",
    // p1_valor_saldo_favor: "$ 605.960",
    // p2_glosa_devolucion_aproximada: "Devolución aproximada",
    // p2_devolucion_aproximada: "$ 795.840",
    // p2_glosa_valor_nuevo_seguro: "Por el Saldo de Tu crédito te ofrecemos un seguro",
    // p2_valor_nuevo_seguro: "$ 182.380",
    // p2_glosa_devolucion: "Devolución",
    // p2_devolucion: "$ 242.384",
    // p2_glosa_abono_inmediato: "Recibe 40% de abono inmediato",
    // p2_abono_inmediato: "$ 0",
    // p2_glosa_abono_terminar_renovacion: "Abono al terminar la renovación",
    // p2_abono_terminar_renovacion: "$ 0"
  },
  documentos : {
    seg_numero: 0,
    seg_solicita_mandato: 0,
    seg_solicita_renuncia: 0,
    seg_solicita_imagen_rut: 0,
    seg_solicita_scraping: 0,
    seg_solicta_documentos: 0,
    seg_solicta_resumen_credito: 0,
    seg_solicta_certificado_prepago: 0
  },
  mandato : {
    cdo_numero: 0,
    cdo_seguro: 0,
    cdo_tipo_docto: 0,
    cdo_nombre_docto: "Planillas/PDF1.pdf",
    cdo_extension: "PDF",
    cdo_mime_type: "",
    cdo_docto: ""
  },
  renuncia : {
    cdo_numero: 0,
    cdo_seguro: 0,
    cdo_tipo_docto: 0,
    cdo_nombre_docto: "Planillas/PDF1.pdf",
    cdo_extension: "PDF",
    cdo_mime_type: "",
    cdo_docto: ""
  },
  preguntasDocumentos : [
    {
      pfr_numero: 1,
      pfr_pregunta: "¿ Que es un resumen de credito ?",
      pfr_respuesta: "Crea etiquetas y úsalas para categorizar las respuestas de texto.",
      pfr_estado: 3
  },
  {
      pfr_numero: 2,
      pfr_pregunta: "¿ Como lo obtengo ?",
      pfr_respuesta: "Crea una representación visual de las palabras y frases más frecuentes en tus respuestas.",
      pfr_estado: 3
  },
  {
      pfr_numero: 3,
      pfr_pregunta: "¿ En que formato debo adjuntarlo ?",
      pfr_respuesta: "Categoriza automáticamente las respuestas de texto para revelar la emoción detrás de lo que dicen las personas.",
      pfr_estado: 3
  },
  {
      pfr_numero: 4,
      pfr_pregunta: "¿ Que es un certificado prepago ?",
      pfr_respuesta: "Entra en el sistema como usuario administrador y, una vez dentro, sigue la ruta:",
      pfr_estado: 3
  },
  {
      pfr_numero: 5,
      pfr_pregunta: "¿ Como lo obtengo ?",
      pfr_respuesta: "Llegarás a una pantalla en la que puedes elegir el idioma entre los que tengas instalados. Elige español.",
      pfr_estado: 3
  }
],
  
};

const mutations = {

  //LOgin
  
  setloginestado(state,data){
    state.loginEstado = data;
  },
  setloginusuario(state,data){
    state.loginUsuario = data;
  },
  
  // HOME
  setformhome(state, data) {
    state.formHome = data;
  },
  setinfoLegal(state, data) {
    state.infoLegal = data;
  },
  setpFrecuente(state, data) {
    state.pfrecuente = data;
  },

  //LINEA

  setform1(state, data) {
    state.form1 = data;
  },
  setform2(state, data) {
    state.form2 = data;
  },
  setforme1(state, data) {
    state.forme1 = data;
  },
  setforme2(state, data) {
    state.forme2 = data;
  },
  setform3(state, data) {
    state.form3 = data;
  },
  setplan(state, data) {
    state.plan = data;
  },
  
  setemptyform1(state, data) {
    state.form1 = {
      rut: "",
      nombre1: "",
      nombre2: "",
      apaterno: "",
      amaterno: "",
      nacionalidad: "",
      genero: ""
    };
  },
  
  setclienteencontrado(state, data) {
    state.clienteEncontrado = data;
  },
  setrepresentanteencontrado(state, data) {
    state.representanteEncontrado = data;
  },

  setemptyform2(state, data) {
    state.form2 = {
      email: "",
      celular: "",
      date: "",
      ocupacion: ""
    };
  },
  setemptyform3(state, data) {
    state.form3 = {
      c1: "",
      c2: "",
      c3: "",
      c4: "",
    };
  },
  setemptyforme1(state, data) {
    state.forme2 = {
      rut: "",
      nombre1: "",
      telefono: "",
      email: "",
    };
  },
  setemptyforme2(state, data) {
    state.forme1 = {
      rut: "",
      nombre1: "",
      nombre2: "",
      apaterno: "",
      amaterno: "",
      date: "",
      genero: "",
      nacionalidad: "",
      ocupacion: ""
    };
  },
  
    
  
  setestadoFormLinea(state, data) {
    state.estadoFormLinea = data;
  },
  settipopersonaempresa(state, data) {
    state.tipoPersonaEmpresa = data;
  },
  setestadoBps(state, data) {
    state.estadoBps = data;
  },
  setsimulacion(state, data) {
    state.simulacion = data;
  },
  setseguro(state, data) {
    state.seguro = data;
  },
  setdps(state, data) {
    state.dps = data;
  },
  setestadodps(state, data) {
    state.estadodps = data;
  },
  setterminos(state, data) {
    state.terminos = data;
  },
  setcontrato(state, data) {
    state.contrato = data;
  },
  setcontdps(state, data) {
    state.contdps = data;
  },
  setselectmenu(state, data) {
    state.selectMenu = data;
  },
  setestadoseguro(state, data) {
    state.estadoSeguro = data;
  },

  //COTIZACION
  
  setemptyform4(state, data) {
    state.form4 = {
      tipoInstitucion : "",
      nombreInstitucion : "",
      saldoCredito : 0,
      saldoInsolutoCredito : 0,
    };
  },
  setemptyform5(state, data) {
    state.form5 = {
      mesesPago : "",
      mesesPendientePago : "",
      periodoGracia : "",
      valorCuota : 0,
      fechaCuota : "",
    };
  },
  setestadoFormCotizacion(state, data) {
    state.estadoFormCotizacion = data;
  },
  setestadoFormCotizacionPrivado(state, data) {
    state.estadoFormCotizacionPrivado= data;
  },
  settipocotizacion(state, data) {
    state.tipoCotizacion = data;
  },
  setform4(state, data) {
    state.form4 = data;
  },
  setform5(state, data) {
    state.form5 = data;
  },
  setinstituciones(state,data){
    state.tipoInstitucion = data;
  },
  setnombreinst(state,data){
    state.nombreinst = data;
  },
  setseguros(state,data){
    state.seguros = data;
  },
  setpropuestaseguro(state,data){
    state.propuestaseguro = data;
  },
  setcodinst(state,data){
    state.codInst = data;
  },
  setcodninst(state,data){
    state.codNInst = data;
  },
  setseg(state,data){
    state.seg = data;
  },
  setislogin(state,data){
    state.islogin=data;
  },
  setcliente(state,data){
    state.cliente=data;
  },
  setestadoformprivado(state,data){
    state.estadoFormPrivado=data;
  },
  setiniciodisabled(state,data){
    state.inicioDisabled=data;
  },
  setestadocliente(state,data){
    state.estadoCliente=data;
  },

  //
  setmontosaldos(state,data){
    state.montoSaldos=data;
  },
  setbitacora(state,data){
    state.bitacora=data;
  },
  setdatoscontrato(state,data){
    state.datosContrato=data;
  },
  setparrillasimulacion(state,data){
    state.parrillaSimulacion=data;
  },
  setdatoscontratoseleccionado(state,data){
    state.datosContratoSeleccionado=data;
  },
  setcontratoplan(state,data){
    state.contratoPlan=data;
  },
  setplancontratado(state,data){
    state.planContratado=data;
  },
  setmisseguros(state,data){
    state.misSeguros=data;
  },
  setcontratarlinea(state,data){
    state.contratarLinea=data;
  },
  setformcuenta(state,data){
    state.formCuenta=data;
  },
  setregiones(state,data){
    state.regiones=data;
  },
  setcomunasregiones(state,data){
    state.comunasRegiones=data;
  },
  setcotizacionescarro(state,data){
    state.cotizacionesCarro=data;
  },
  setnacionalidad(state,data){
    state.nacionalidad=data;
  },
  setgenero(state,data){
    state.genero=data;
  },
  setocupacion(state,data){
    state.ocupacion=data;
  },
  setselecciongenero(state,data){
    state.seleccionGenero=data;
  },
  setseleccionnacionalidad(state,data){
    state.seleccionNacionalidad=data;
  },
  setseleccionocupacion(state,data){
    state.seleccionOcupacion=data;
  },
  setrestricciones(state,data){
    state.restricciones=data;
  },
  setpropuestacreditovigente(state,data){
    state.propuestaCreditoVigente=data;
  },
  setpropuestacreditonuevo(state,data){
    state.propuestaCreditoNuevo=data;
  },
  setdocumentos(state,data){
    state.documentos=data;
  },
  setmandato(state,data){
    state.mandato=data;
  },
  setrenuncia(state,data){
    state.renuncia=data;
  },
  setpreguntasdocumentos(state,data){
    state.preguntasDocumentos=data;
  },
  setestadoprivado(state,data){
    state.estadoPrivado=data;
  },
  setseguroseleccion(state,data){
    state.seguroSeleccion=data;
  },
  setplanseleccion(state,data){
    state.planSeleccion=data;
  },
  setestadoidentidad(state,data){
    state.estadoIdentidad=data;
  },
  setestadodocumentos(state,data){
    state.estadoDocumentos=data;
  },
  setestadofirmas(state,data){
    state.estadoFirmas=data;
  },
  setestadomandato(state,data){
    state.estadoMandato=data;
  },
  setestadorenuncia(state,data){
    state.estadoRenuncia=data;
  },
  setestadoprepago(state,data){
    state.estadoPrepago=data;
  },
  setestadocreditocarga(state,data){
    state.estadoCreditoCarga=data;
  },
  setseleccionmenuwallet(state,data){
    state.seleccionMenuWallet=data;
  },
  setclientewallet(state,data){
    state.clienteWallet=data
  },
  setbancos(state,data){
    state.bancos=data
  },
  settipocuentas(state,data){
    state.tipoCuentas=data
  },

  setfirmamandato(state,data){
    state.firmaMandato=data
  },
  setfirmarenuncia(state,data){
    state.firmaRenuncia=data
  },
  settipodocfirma(state,data){
    state.tipoDocFirma=data
  },
  setactivarenuncia(state,data){
    state.activaRenuncia=data
  },
  setestadoconfirmacion(state,data){
    state.estadoConfirmacion=data
  },
  setestadotransaccionpago(state,data){
    state.estadoTransaccionPago=data
  },
  setestadocotizacion(state,data){
    state.estadoConfirmacion=data
  },
  setestadofea(state,data){
    state.estadoFea=data
  },
  setfirma(state,data){
    state.firma=data
  }


};

const actions = {
  // async  traemenus() {
  //     await axios.get(ENDPOINT_PATH + "menu/listar-menu").then((result) => {
  //         state.menuslistado=resultado;
  //     }).catch((err) => {
  //         return false;
  //     });
  //     return state.menuslistado;
  //   },
  //   async startCounter({ state }) {
  //     state.counter = true;
  //     while (state.count > 0 && state.counter) {
  //       await new Promise(resolve => setTimeout(resolve, 60*1000));
  //       if (state.counter)
  //         state.count--;
  //     }
  //     state.counter = false;
  //   },
};
const getters = {

  // HOME
  
  getformhome(state) {
    return state.formHome;
  },
  getinfolegal(state) {
    return state.infoLegal;
  },
  getpfrecuente(state) {
    return state.pfrecuente;
  },
   //LINEA

  getform1(state) {
    return state.form1;
  },
  getform2(state) {
    return state.form2;
  },
  getforme1(state) {
    return state.forme1;
  },
  getforme2(state) {
    return state.forme2;
  },
  getform3(state) {
    return state.form3;
  },
  getestadoFormLinea(state){
    return state.estadoFormLinea;
  },
  getclienteencontrado(state){
    return state.clienteEncontrado;
  },
  getsimulacion(state){
    return state.simulacion;
  },
  getplan(state) {
    return state.plan;
  },
  getdps(state) {
    return state.dps;
  },
  getestadodps(state) {
    return state.estadodps;
  },
  getterminos(state) {
    return state.terminos;
  },
  getcontrato(state) {
    return state.contrato;
  },
  getcontdps(state) {
    return state.contdps;
  },
  //COTIZACION

  getestadoFormCotizacion(state){
    return state.estadoFormCotizacion;
  },
  getestadoFormCotizacionPrivado(state){
    return state.estadoFormCotizacionPrivado;
  },
  getform4(state) {
    return state.form4;
  },
  getcodinst(state){
    return state.codInst;
  },
  getcodninst(state){
    return state.codNInst;
  },
  getseg(state){
    return state.seg;
  },
  getseleccionseguros(state){
    return state.seleccionSeguros;
  },
  getislogin(state){
    return state.islogin;
  },
  getcliente(state){
    return state.cliente;
  },
  getestadoformprivado(state){
    return state.estadoFormPrivado;
  },
  getiniciodisabled(state){
    return state.inicioDisabled;
  },
  getestadocliente(state){
    return state.estadoCliente;
  },
  getloginestado(state){
    return state.loginEstado;
  },
  getloginusuario(state){
    return state.loginUsuario;
  },
  getmontosaldos(state){
    return state.montoSaldos;
  },
  getbitacora(state){
    return state.bitacora;
  },
  getdatoscontrato(state){
    return state.datosContrato;
  },
  getparrillasimulacion(state){
    return state.parrillaSimulacion;
  },
  getdatoscontratoseleccionado(state){
    return state.datosContratoSeleccionado;
  },
  getcontratoplan(state){
    return state.contratoPlan;
  },
  getplancontratado(state){
    return state.planContratado;
  },
  getrepresentanteencontrado(state){
    return state.representanteEncontrado;
  },
  getmisseguros(state){
    return state.misSeguros;
  },
  getselectmenu(state){
    return state.selectMenu;
  },
  getcontratarlinea(state){
    return state.contratarLinea;
  },
  getformcuenta(state){
    return state.formCuenta;
  },
  getregiones(state){
    return state.regiones
  },
  getcotizacionescarro(state){
    return state.cotizacionesCarro
  },
  getnacionalidad(state){
    return state.nacionalidad
  },
  getgenero(state){
    return state.genero
  },
  getocupacion(state){
    return state.ocupacion
  },
  getselecciongenero(state){
    return state.seleccionGenero
  },
  getseleccionnacionalidad(state){
    return state.seleccionNacionalidad
  },
  getseleccionocupacion(state){
    return state.seleccionOcupacion
  },
  getrestricciones(state){
    return state.restricciones
  },
  getpropuestacreditovigente(state){
    return state.propuestaCreditoVigente
  },
  getpropuestacreditonuevo(state){
    return state.propuestaCreditoNuevo
  },
  getdocumentos(state){
    return state.documentos
  },
  getmandato(state){
    return state.mandato
  },
  getrenuncia(state){
    return state.renuncia
  },
  getpreguntasdocumentos(state){
    return state.preguntasDocumentos
  },
  getestadoprivado(state){
    return state.estadoPrivado
  },
  getseguroseleccion(state){
    return state.seguroSeleccion;
  },
  getestadoidentidad(state){
    return state.estadoIdentidad;
  },
  getestadodocumentos(state){
    return state.estadoDocumentos;
  },
  getestadofirmas(state){
    return state.estadoFirmas;
  },
  getestadomandato(state){
    return state.estadoMandato;
  },
  getestadorenuncia(state){
    return state.estadoRenuncia;
  },
  getestadoprepago(state){
    return state.estadoPrepago;
  },
  getestadocreditocarga(state){
    return state.estadoCreditoCarga;
  },
  getseleccionmenuwallet(state){
    return state.seleccionMenuWallet;
  },
  getfirmamandato(state){
    return state.firmaMandato;
  },
  getfirmarenuncia(state){
    return state.firmaRenuncia;
  },
  gettipodocfirma(state){
    return state.tipoDocFirma;
  },
  getactivarenuncia(state){
    return state.activaRenuncia;
  },
  getestadoseguro(state){
    return state.activaRenuncia;
  },
  getestadoconfirmacion(state){
    return state.estadoConfirmacion;
  },
  getestadotransaccionpago(state){
    return state.estadoTransaccionPago;
  },
  getestadocotizacion(state){
    return state.estadoCotizacion;
  },
  getestadofea(state){
    return state.estadoFea;
  },
  getfirma(state){
    return state.firma;
  },

  // cdo_prepago_estado_carga: elemnt.cdo_prepago_estado_carga,
  //           cdo_resumen_credito_estado_carga : element.cdo_resumen_credito_estado_carga

};



export default new Vuex.Store({
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    // lineaform: lineaform,
  },
});
