<template>
  <v-app class="tmpbase">

    <Nav></Nav>
    <v-row class="bannerlogo" style="display: flex; justify-content: center; ">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="bannerIz">

        <div class="text-somos">
          <div class="somos-una-empresa-liderada-por-profesionales">
            Somos una empresa liderada por profesionales
          </div>
          <div class="con-amplia-experiencia-en-el-mercado-asegurador-chileno-enfocados-en-la-asesor-a-de-seguros">
            con amplia experiencia en el mercado asegurador chileno, enfocados en la
            Asesoría de Seguros.
          </div>
        </div>

      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="bannerCn text-center mt-12">
        <v-img class="he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-2"
          src="/assets/somos/he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-20.png"> </v-img>
        <!-- <img class="he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-2" src="/assets/somos/he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-20.png"> -->
      </v-col>

    </v-row>
    <v-row class="colcenter">



      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="bajobaner">
          <div
            class="buscamos-alternativas-integrales-e-innovadoras-en-seguros-reaseguros-y-asistencias-cuestionamos-el-status-quo-y-ofrecemos-soluciones-accesibles-tecnol-gicas-y-justas-somos-pioneros-en-una-experiencia-de-seguros-avanzada-y-equitativa-para-chile-y-latam">
            Buscamos alternativas integrales e innovadoras en seguros, reaseguros y
            asistencias. Cuestionamos el status quo y ofrecemos soluciones accesibles,
            tecnológicas y justas. Somos pioneros en una experiencia de seguros
            avanzada y equitativa para Chile y Latam.
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="valores">Valores</div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>



      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">

        <v-row class="text-center colcenter">
          <v-col cols="12" sm="9" md="6" lg="4" xl="4" class="colcenter">
            <div class="frame-8">
              <div class="frame-11">
                <v-img cover class="group" src="/assets/somos/group0.svg" />
                <div class="valientes">Valientes</div>
              </div>
              <div
                class="tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio">
                Tenemos el coraje y la determinación de abrazar los desafíos en nombre de nuestros clientes. Estamos
                dispuestos a asumir riesgos calculados para encontrar soluciones innovadoras y brindar el mejor
                servicio.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="9" md="6" lg="4" xl="4" class="colcenter">
            <div class="frame-8 ">
              <div class="frame-11">
                <v-img cover class="group" src="/assets/somos/group0.svg" />
                <div class="valientes">Comprometidos</div>
              </div>
              <div
                class="tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio">
                Nuestro compromiso es mantener una relación sólida y duradera con nuestros clientes, demostrando
                constantemente nuestro propósito de proteger lo que más valoran.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="9" md="6" lg="4" xl="4" class="colcenter">
            <div class="frame-8">
              <div class="frame-11">
                <v-img cover class="group" src="/assets/somos/group0.svg" />
                <div class="valientes">Transparentes</div>
              </div>
              <div
                class="tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio">
                Valoramos la confianza de nuestros clientes y trabajamos constantemente para garantizar que cada aspecto
                de nuestra relación con ellos esté marcado por la honestidad y la apertura.
              </div>
            </div>
          </v-col>


        </v-row>
        <v-row class="text-center colcenter">
          <v-col cols="12" sm="9" md="6" lg="4" xl="4" class="colcenter">
            <div class="frame-8">
              <div class="frame-11">
                <v-img cover class="group" src="/assets/somos/group0.svg" />
                <div class="valientes">Liderazgo</div>
              </div>
              <div
                class="tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio">
                Estamos comprometidos a ser líderes en la industria de seguros y, más importante aún, inspirar a las
                personas a construir su futuro.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="9" md="6" lg="4" xl="4" class="colcenter">
            <div class="frame-8">
              <div class="frame-11">
                <v-img cover class="group" src="/assets/somos/group0.svg" />
                <div class="valientes">Innovadores</div>
              </div>
              <div
                class="tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio">
                Promovemos la creatividad y la capacidad de adaptación para desarrollar soluciones de seguros novedosas
                y efectivas, donde la tecnología es nuestra palanca para entregar un servicio de fácil acceso.

              </div>
            </div>
          </v-col>


        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="valores">Misión</div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="colcenter">
        <div class="bajobaner">
          <div class="mision">
            Ofrecer una alternativa integral e innovadora en el ámbito de los Seguros, Reaseguros y Asistencias, de
            fácil acceso, con herramientas tecnológicas de vanguardia y a un precio justo.
            Para lograrlo, contamos con un equipo de expertos en la industria, comprometidos en buscar y crear
            soluciones adaptadas a las necesidades de nuestros clientes.
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="valores">Visión</div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="colcenter">
        <div class="bajobaner">
          <div class="mision">
            Ser un referente en Chile y América Latina en el desarrollo de productos innovadores en el Mercado
            Asegurador y acompañar a las personas en la toma de decisiones para proteger y garantizar hoy su futuro.
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <v-row class="colcenter">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="display: flex; justify-content: center; ">
            <div class="boton-trabaja">
              <div class="frame">
                <v-img cover class="group6" src="/assets/somos/circulo-btngris.svg" />
                <!-- <img class="vector33" src="/assets/somos/vector0.svg"> -->
              </div>
              <div class="trabaja-con-nosotros">Trabaja con nosotros</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>


    </v-row>
    <v-row class="franja-ayuda">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="franja-ayuda-iz">
        <div class="imagen-iz">
          <div class="frame-46">
            <div class="necesitas-ayuda">¿Necesitas ayuda?</div>
            <div class="comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl">
              Comunícate con nosotros en
              <br>
              servicioalcliente@mueveseguro.cl
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
        <div class="colcenter">
          <div class="imagen-dr colcenter">
            <v-img class="rectangle-23801185-jj-79-400-h-1" src="/assets/somos/rectangle-23801185-jj-79-400-h-10.png" />
          </div>
        </div>
      </v-col>
    </v-row>


    <v-row class="">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">

      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">

      </v-col>


    </v-row>
    <v-row class="" style="display: flex; justify-content: center;">
      <v-col cols="12" sm="12" md="10" lg="9" xl="9 " class="marcas" style="display: flex; justify-content: center; ">
        <v-row class="" style="display: flex; justify-content: center;">

          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
         <!--  <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
            <div class="frame-24">
              <div class="compa-as-que-nos-respaldan">
                Compañías que nos respaldan&nbsp;
              </div>
            </div>
          </v-col>-->
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>

          <v-col cols="10" sm="10" md="10" lg="10" xl="10 " style="display: flex; justify-content: center; ">
            <v-row class="" style="display: flex; justify-content: center; ">
              <!-- <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
                  <img class="_59-b-53143-d-9-d-72" src="/assets/_59-b-53143-d-9-d-720.png">
                </v-col>-->
              <!--<v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
                <img class="group-14" src="/assets/group-140.svg">
              </v-col>-->
              <!--  <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
                  <img class="trayectoria-2018" src="/assets/trayectoria-20180.png">
                </v-col>-->
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="10" md="6" lg="3" xl="3 ">
              <div class="">
              <div class="frame-22 colcenter">
                <div class="colcenter regulados" >Regulados por&nbsp;</div>
              </div>
              <v-row class="colcenter">
                <v-col cols="12" sm="10" md="6" lg="6" xl="6" class="colcenter">
                  <v-img cover class="sernac" src="/assets/somos/dise-o-sin-t-tulo-27-10.png"/></v-col>
                <v-col cols="12" sm="10" md="6" lg="6" xl="6" class="cmfdr" >
                  <v-img cover class="cmf" src="/assets/somos/dise-o-sin-t-tulo-29-10.png"/>
                </v-col>
              </v-row>
              
              
            </div>
            </v-col>
            -->
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>


        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
    </v-row>
    <v-row class="row3">
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 " style="display: flex; justify-content: center; ">
        <v-row class="row3" style="display: flex; justify-content: center; ">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
            <div class="frame-41">
              <div class="frame-292">
                <div class="group-40">
                  <div class="group-39">
                    <div class="una-empresa-de">Una empresa de</div>
                    <img class="logotipo-grupo-insurex-blanco-1" src="/assets/logotipo-grupo-insurex-blanco-10.png">
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
              <div class="frame-39">
                <div class="group-392">
                  <div class="regulados-por">Regulados por&nbsp;</div>
                  <img class="dise-o-sin-t-tulo-27-1" src="/assets/dise-o-sin-t-tulo-27-10.png">
                  <img class="dise-o-sin-t-tulo-29-1" src="/assets/dise-o-sin-t-tulo-29-10.png">
                </div>
              </div>
            </v-col> -->

        </v-row>

      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2 " style="display: flex; justify-content: center; ">
        <div class="mueve-seguro-ingresa-registrate">
          <span>
            <span class="mueve-seguro-ingresa-registrate-span">
              Mueve Seguro
              <br>
            </span>
            <span class="mueve-seguro-ingresa-registrate-span2"><br></span>
            <span class="mueve-seguro-ingresa-registrate-span3">
              Ingresa
              <br>
              Registrate
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2 " style="display: flex; justify-content: center; ">
        <div class="seguro-cotiza-beneficios-qu-es">
          <span>
            <span class="seguro-cotiza-beneficios-qu-es-span">
              Seguro
              <br>
            </span>
            <span class="seguro-cotiza-beneficios-qu-es-span2"><br></span>
            <span class="seguro-cotiza-beneficios-qu-es-span3">
              Cotiza
              <br>
              ¿Qué es?
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 " style="display: flex; justify-content: center;   ">
        <div class="group-34">
          <div class="group-32">
            <div class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl">
              <span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span">
                  Contacto
                  <br>
                </span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2">
                  Consultas de la portabilidad: Servicioalcliente@mueveseguro.cl
                  Consulta de tu nuevo Seguro: contacto@grupoinsurex.cl
                 
                </span>
              </span>
            </div>
          </div>
          <!-- <img class="group-33" src="/assets/group-330.svg">-->
          <v-row class="row3 colcenter">
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="face" id="btn-social-facebook">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="insta" id="btn-social-instagram">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="link" id="btn-social-linkedin">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="you" id="btn-social-youtube">
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="2" xl="2 " style="display: flex; justify-content: center; ">
        <div class="sobre-nosotros-somos-misi-n-visi-n-valores">
          <span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span">
              Sobre nosotros
              <br>
            </span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span2">
              <br>
              Somos
              <br>
              Misión
              <br>
              Visión
              <br>
              Valores
            </span>
          </span>
        </div>
      </v-col>
    </v-row>

  </v-app>

</template>

<script>
import Nav from "../layout/Nav.vue";
export default {
  components: {
    Nav: Nav,
  },
  data() {
    return {
      isMouseRot1: false,
      isMouseRot2: false,
      isMouseRot3: false,
      isMouseRot4: false,
      toggleMenu: false,
      drawer: false,
      group: null,
      items: [
        {
          src: '/assets/person-working-on-a-laptop-in-a-cozy-home-office-2024-03-01-16-35-10-utc-10.png',
        },
        {
          src: '/assets/couple-using-tablet-computer-2023-11-27-05-32-54-utc-10.png',
        },
        {
          src: '/assets/he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-10.png',
        },
        {
          src: '/assets/young-boss-hiring-new-colleague-shaking-hands-afte-2023-11-27-05-25-00-utc-10.png',
        },
      ],
    }
  },
  methods: {
    face() {
      window.open("https://www.facebook.com/profile.php?id=61555367385086", "");
    },
    insta() {
      window.open("https://www.instagram.com/mueveseguro/", "");
    },
    link() {
      window.open("https://www.linkedin.com/company/grupo-insurex/?viewAsMember=true", "");
    },
    you() {
      window.open("https://www.youtube.com/@mueveseguro", "");
    },
    home() {
      this.$router.push("/");
    },
    login() {
      location.href = "http://52.204.241.21:8080/login";
    },
    contratar() {
      // location.href = "/formulario-cotizacion";
      this.$router.push("/formulario-cotizacion");
    },
    rotation(opt) {
      if (opt == 1) {
        this.isMouseRot1 = !this.isMouseRot1
      }
      if (opt == 2) {
        this.isMouseRot2 = !this.isMouseRot2
      }
      if (opt == 3) {
        this.isMouseRot3 = !this.isMouseRot3
      }
      if (opt == 4) {
        this.isMouseRot4 = !this.isMouseRot4
      }


    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  computed: {
    showMenu() {
      return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp
    },

  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {

    // this.traeInfoLegal();
    // this.traepFrcuente();
    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit('setemptyforme1', {});
    this.$store.commit('setemptyforme2', {});
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit("setislogin", false);
    localStorage.removeItem("loginEstado");
    localStorage.removeItem("loginCliente");

    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setemptyform3", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyform5", {});
    this.$store.commit("setestadoFormLinea", 1);
    this.$store.commit("setestadoFormCotizacion", 1);
    this.$store.commit("setestadoBps", 1);
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit('setrepresentanteencontrado', false);
    this.$store.commit("settipocotizacion", "inicio");
    this.$store.commit("settipopersonaempresa", "inicio");
    this.$store.commit("setiniciodisabled", true);
    this.isLogin = false;
    this.$store.commit("setislogin", false);
    localStorage.removeItem('loginEstado');
    localStorage.removeItem('loginCliente');

    this.$store.commit('setclienteencontrado', false);
    this.$store.commit('setrepresentanteencontrado', false);
    if (this.$root.$refs.LineaForm1) {
      this.$root.$refs.LineaForm1.tipodisabled = true;
    }
    if (this.$root.$refs.CotizaForm1) {
      this.$root.$refs.CotizaForm1.tipodisabled = true;
    }

  },
}
</script>
<style scoped>
.imagetra {
  transition: transform 0.5s ease-in-out;
}

.onmouserot {
  transform: rotate(360deg);
}

/* .btntop{
    background: transparent !important;
      border: 0px !important;
      box-shadow: none !important;
  } */
.HeadingsH6 {
  font-size: 15px;
  font-style: normal;
  font-family: Circular Pro;
  font-weight: 300px;
  font-stretch: normal;
  letter-spacing: 0em;
  margin-top: 21px !important;
}

.HeadingsH1 {
  width: 100px;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

#toolbar {
  padding: 27px !important;
  height: 118px !important;
}

.menutop {
  min-width: 100% !important;
  ;
  top: 38px !important;
  ;
  left: 0px !important;
  ;
  transform-origin: left top !important;
  ;
  z-index: 8 !important;
  ;
  height: auto !important;
  ;
}

.txtbnt {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.txtbnt2 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgba(4, 19, 127, 1) !important;
}

.txtbnt3 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgba(4, 19, 127, 1) !important; */
  color: white !important;
}

.txtbnt5 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgb(29 46 255) !important;
  color: white !important;
}

.txtbnt4 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgb(3 75 178) !important;  */
  color: white !important;
}

.txtbnt6 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: white !important;
  /* color: white !important; */
  text-transform: unset !important;

}

.txtbnt7 {
  font-size: 26px !important;
  font-weight: 500;
  padding: 28px;
  /* padding-top: 28px !important;
    padding-bottom: 28px !important; */
  /* background-color: rgb(3 75 178) !important;  */
  color: rgba(4, 19, 127, 1) !important;

}

.btntop {
  margin-top: 3px;
}

.bannerlogo {
  /* background-image: url('/assets/teaser-ms-02-10.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  margin-left: 0px !important;
}


.la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: "CircularPro-Black", sans-serif;
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  position: relative;
  /* width: 800px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.recibe-dinero-directamente-en-tu-cuenta-bancaria {
  color: var(--celeste, #00e5ff);
  text-align: left;
  font-family: "CircularPro-Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  position: relative;
  /* width: 867px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.boton-saber-mas {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.component-53 {
  background: var(--azul-primario, #1d2eff);
  border-radius: 30px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.group-29 {
  flex-shrink: 0;
  width: 621.45px;
  height: 130.31px;
  position: relative;
  overflow: visible;
}

.piggy-1 {
  flex-shrink: 0;
  width: 388px;
  height: 388px;
  /* position: relative; */
  object-fit: cover;
  align-content: center;
  margin-top: 80px;

}

.quieres-portar-tu-seguro-de-desgravamen {
  color: #ffffff;
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 28px);
  line-height: var(--headings-h2-line-height, 40px);
  font-weight: var(--headings-h2-font-weight, 400);
  position: relative;
  /* width: 560px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: #ffffff;
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 22px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  /* width: 560px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.boton-calcula {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 5px 5px 6.3px 0px rgba(0, 0, 0, 0.5);
}

.component-54 {
  background: var(--blanco, #f0f2f2);
  border-radius: 30px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.submit3 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  display: flex;
  justify-content: center;
}

.excluye-cr-ditos-hipotecarios {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  margin-top: 30px;
  /* width: 400px; */
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  /* width: 1920px; */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -72px;
}

.bienvenidos-a-la-portabilidad-de-seguros-de-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 35px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  /* width: 1469px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-8 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.frame-8-2 {
  background: #04137f !important;
  ;
}

.frame-11 {
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}


.group {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  position: relative;
  overflow: visible;
}

.nosotros-gestionamos-todo {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
}

.nosotros-gestionamos-todo-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.nosotros-gestionamos-todo-span-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span2-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}

.al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti-2 {
  color: white !important;
}

.al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 300px;
  height: 80px;
}

.frame-9 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.frame-10 {
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.group2 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.paga-un-precio-justo {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 229px;
}

.paga-un-precio-justo-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.paga-un-precio-justo-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}

.ten-la-misma-cobertura-de-siempre-pero-a-un-precio-conveniente-y-sin-pagar-de-m-s {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 304px;
  height: 80px;
}

.frame-102 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.group3 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.ten-tu-cashback {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 229px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ten-tu-cashback-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.ten-tu-cashback-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.ten-tu-cashback-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}

.al-portarte-te-devolveremos-lo-que-pagaste-de-m-s-directo-a-tu-cuenta-bancaria {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 300px;
  height: 80px;
}

.frame-112 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.group4 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.siempre-estar-s-protegido {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
}

.siempre-estar-s-protegido-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.siempre-estar-s-protegido-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 30px;
  line-height: 28px;
  font-weight: 900;
}

.nos-aseguramos-de-que-al-momento-de-realizar-tu-cambio-jam-s-te-quedes-sin-cobertura {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 300px;
  height: 80px;
}

.c-mo-funciona4 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  /* width: 1582px;
    height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-12 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 300px;
  height: 101px;
}

.calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.calcula-tu-devolucion-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e8f3ff;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.ingresa-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-11 {
  flex-shrink: 0;
  width: 20px;
  height: 18px;
  position: relative;
  overflow: visible;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud {
  text-align: left;
  position: relative;
  width: 300px;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span2 {
  color: var(--azul-primario, #1d2eff);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.adjunta-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e8f3ff;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-30 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 300px;
  height: 144px;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.firma-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-9 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 300px;
  height: 144px;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.beneficios-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 170px;
  position: relative;
  overflow: hidden;
}

.group-8 {
  flex-shrink: 0;
  width: 20px;
  height: 21.43px;
  position: relative;
  overflow: visible;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 300px;
  height: 144px;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.vector {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  /* position: absolute; */
  /* left: 1620px;
    top: 1946px; */
  margin-left: -302px;
  margin-top: 486px;
  overflow: visible;
}

.carrusel2 {
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 681px;
  /* position: absolute; */
  left: 864px;
  top: calc(50% - 290.5px);
  overflow: hidden;
  margin-top: 100px;

}

.frame-6 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 681px;
  position: relative;
}

.person-working-on-a-laptop-in-a-cozy-home-office-2024-03-01-16-35-10-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 681px;
  position: relative;
  object-fit: cover;
}

.vector2 {
  flex-shrink: 0;
  width: 1395.63px;
  height: 1395.63px;
  position: absolute;
  left: -643.18px;
  top: 1261.88px;
  transform: translate(643.18px, -1261.88px);
  overflow: visible;
}

.couple-using-tablet-computer-2023-11-27-05-32-54-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.young-boss-hiring-new-colleague-shaking-hands-afte-2023-11-27-05-25-00-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.rectangle-27 {
  background: var(--blanco, #f0f2f2);
  border-radius: 93px;
  flex-shrink: 0;
  width: 262px;
  height: 31px;
  position: absolute;
  left: 41px;
  top: 16px;
}

.ingresa {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: absolute;
  left: -8px;
  top: 16px;
  width: 361px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Bold", sans-serif;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
}

.es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Book", sans-serif;
  font-size: 31px;
  line-height: 36px;
  font-weight: 400;
  margin-top: -15px;
}

.ley-1 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.crop-ethnic-businessman-signing-papers-2023-11-27-05-18-25-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-23 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.circular-2114-26-07-2013-cmf {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 10%;
  left: 4.75%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ley-2 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.asian-woman-designer-working-with-laptop-in-creati-2023-11-27-05-04-40-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-24 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.ley-19-496-art-17-d {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 7.25%;
  left: 7.5%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ley-3 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.female-advisor-attorney-lawyer-consulting-client-i-2024-02-02-18-20-30-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-25 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.ley-20-448-art-8 {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 3.5%;
  left: 11.25%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.vector3 {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  /* position: absolute;
    left: 1860px;
    top: 322px; */
  /* overflow: visible; */
  margin-top: 296px;
  margin-right: -130px;
  border-radius: 104px;
}



.vector4 {
  margin-left: -140px;
  margin-top: 100px;
  flex-shrink: 0;
  width: 198px;
  height: 198px;
  /* position: absolute;
    left: -67px;
    top: 70px; */
  /* overflow: visible; */
  border-radius: 104px;
}


.frame-23 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.requisitos-de-portabilidad {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-16 {
  flex-shrink: 0;
  width: 270px;
  height: 270px;
  position: static;
}

.rectangle-30 {

  background: #e8f3ff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  right: 72.04%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
}

.vector5 {
  width: 7.2%;
  height: 33.5%;
  position: absolute;
  right: 82.47%;
  left: 10.32%;
  bottom: 57%;
  top: 9.5%;
  overflow: visible;
}

._1 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: absolute;
  right: 85.16%;
  left: 13.01%;
  width: 1.83%;
  bottom: 62%;
  top: 14.5%;
  height: 23.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tener-un-cr-dito-vigente-con-seguro-de-degravamen-en-bancos-cooperativas-o-automotoras {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  /* position: absolute; */
  right: 72.9%;
  left: 0.86%;
  width: 244px;
  bottom: 11.5%;
  top: 48.5%;
  height: 80px;
}

.excluye-cr-ditos-hipotecarios2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 1200px;
}

.frame-22 {}

.historias-de-xito {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle-28 {
  background: var(--azul-complementario, #04137f);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}

.texto_historia {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 0px 20px;

}

.nombre_historia {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.compa-as-que-nos-respaldan {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-41 {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  /* left: 48px;
    top: 3px; */
}

.frame-292 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.group-40 {
  flex-shrink: 0;
  width: 360px;
  height: 75.25px;
  position: static;
}

.group-39 {
  width: 360px;
  height: 75.25px;
  position: static;
}

.una-empresa-de {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: absolute;
  left: 0px;
  top: 11.12px;
  width: 160px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logotipo-grupo-insurex-blanco-1 {
  width: 200px;
  height: 75.25px;
  position: absolute;
  left: 160px;
  top: 0px;
  object-fit: cover;
}

.frame-39 {
  display: flex;
  /* flex-direction: row; */
  gap: 11px;
  /* align-items: flex-end; */
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;
  position: relative;
  /* left: 48px;
    top: 97px; */
}

.group-392 {
  flex-shrink: 0;
  width: 100%;
  height: 48px;
  /* position: static; */
  display: flex;
  justify-content: center;
}

.regulados-por {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  /* left: 0px; */
  top: -11px;
  width: 149px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dise-o-sin-t-tulo-27-1 {
  width: 69px;
  height: 48px;
  position: relative;
  /* left: 156px; */
  top: -6px;
  object-fit: cover;
}

.dise-o-sin-t-tulo-29-1 {
  width: 116px;
  height: 24px;
  position: relative;
  /* left: 233px; */
  top: 6px;
  object-fit: cover;
}

.mueve-seguro-ingresa-registrate {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.mueve-seguro-ingresa-registrate-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.mueve-seguro-ingresa-registrate-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.mueve-seguro-ingresa-registrate-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.seguro-cotiza-beneficios-qu-es-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.seguro-cotiza-beneficios-qu-es-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: absolute;
  /* left: 1084px;
    top: 26px; */
  width: 308px;
  height: 180px;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.group-34 {
  flex-shrink: 0;
  width: 308px;
  height: 180px;
  position: static;
  text-align: center;
}

.group-32 {
  width: 308px;
  height: 120px;
  position: static;
  margin-bottom: 20px;
}

.group-33 {
  width: 211.8px;
  height: 43.8px;
  position: relative;
  /* left: 1133px;
    top: 142px; */
  overflow: visible;
}

.sobre-nosotros-somos-misi-n-visi-n-valores {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 180px;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.btn-contrata {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.navapp {
  box-shadow: none !important;
}

.home-logotipo-mueve-seguroconbajada3 {
  width: 325px;
  cursor: pointer;
}

.avrow3 {
  margin-top: 30px;
}

.bannerIz {
  padding: 50px;
}

.bannerCn {
  /* padding: 80px; */
  display: flex;
  justify-content: center;
  display: flex;
  padding: 30px;
  justify-content: center;
  /* padding-top: 60px; */
  /* padding-top: 0px !important;
    padding: 30px;
    padding-right: 100px; */
}

.bannerDr {
  padding: 60px;
}

.row3 {
  background: var(--blanco, #f0f2f2);
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 959px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 30px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bannerIz {
    /* padding: 50px; */
  }

  .trabaja-con-nosotros {
    font-size: var(--headings-h2-font-size, 15px) !important;
  }


  .piggy-1 {
    flex-shrink: 0;
    width: 388px;
    height: 388px;
    /* position: relative; */
    object-fit: cover;
    align-content: center;
    margin-top: 0px !important;

  }



  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;

  }

  .comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
    font-size: var(--headings-h4-font-size, 20px) !important;
  }

  .valientes {
    font-size: var(--headings-h2-font-size, 20px) !important;
  }

  .cmfdr {
    align-content: center;
    justify-content: center;
    display: flex;
  }

  .rectangle-23801185-jj-79-400-h-1 {
    max-width: 50% !important;
  }
}

.menu-t {
  display: unset !important;
}

.menu-m {
  display: none !important;
}

.mdi-menu {
  margin-top: 45px !important;
  font-size: 53px !important;
  color: rgba(4, 19, 127, 1) !important;
}

@media only screen and (max-width: 1380px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 35px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  .bannerIz {
    padding: 20px;
    padding-top: 50px;
  }

  .menu-t {
    display: none !important;
  }

  .menu-m {
    display: unset !important;
  }

  .rotateimage {
    display: none !important;
  }

  .circuloiz {
    display: none !important;
  }

  .circulodr {
    display: none !important;
  }

}

.colcenter {
  display: flex;
  justify-content: center;
}

.rowpage2 {
  background-color: #f0f2f2;
}






.tmpbase {
  background-color: rgba(232, 243, 255, 1) !important;
}

.text-somos {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 50px 10px 30px;
}

.somos-una-empresa-liderada-por-profesionales {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 55px);
  line-height: var(--headings-h1-line-height, 60px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.con-amplia-experiencia-en-el-mercado-asegurador-chileno-enfocados-en-la-asesor-a-de-seguros {
  color: var(--azul-primario, #1d2eff);
  text-align: left;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 40px);
  line-height: var(--headings-h2-line-height, 50px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-2 {
  border-radius: 20px;
  flex-shrink: 0;
  width: 664px;
  height: 428px;
  position: relative;
  object-fit: cover;
}

.bajobaner {
  height: 100%;
  width: 90%;
  background-color: #f0f2f2;
  border-radius: 40px;
  padding: 20px;
}

.buscamos-alternativas-integrales-e-innovadoras-en-seguros-reaseguros-y-asistencias-cuestionamos-el-status-quo-y-ofrecemos-soluciones-accesibles-tecnol-gicas-y-justas-somos-pioneros-en-una-experiencia-de-seguros-avanzada-y-equitativa-para-chile-y-latam {
  color: var(--azul-complementario, #04137f);
  text-align: justify !important;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 21px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;

  width: 100%;

  display: flex;
  align-items: center;
}

.valores {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 50px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-8 {
  background: var(--azul-complementario, #04137f);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 85%;
  height: 100%;
  position: relative;
}

.frame-11 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.group {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  position: relative;
  overflow: visible;
}

.valientes {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 30px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}

.tenemos-el-coraje-y-la-determinaci-n-de-abrazar-los-desaf-os-en-nombre-de-nuestros-clientes-estamos-dispuestos-a-asumir-riesgos-calculados-para-encontrar-soluciones-innovadoras-y-brindar-el-mejor-servicio {
  color: var(--blanco, #f0f2f2);
  text-align: justify !important;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  min-height: 100px;
}

.mision {
  color: var(--azul-complementario, #04137f);
  text-align: justify !important;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 28px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;

  width: 100%;

  display: flex;
  align-items: center;
}

.rectangle-23801185-jj-79-400-h-1 {
  border-radius: 0px 40px 40px 0px;
  max-width: 520px;
  /* height: 494px; */
  position: relative;
  /* object-fit: cover; */
}

.imagen-dr {
  /* background-color: #ffffff; */
}

.franja-ayuda-iz {
  align-content: center;
}

.franja-ayuda {
  background-color: #ffffff !important;
  padding: 20px;
}

.frame-46 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

}

.necesitas-ayuda {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 40px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;

}

.comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 28px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  width: 100%;
  /* height: 81px; */
}

.banner-principal2 {
  background: #ffffff;
  padding: 27px 360px 27px 360px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  position: relative;

  overflow: hidden;
}

.frame-22 {
  flex-shrink: 0;
  width: 100%;
  height: 72px;
  position: relative;
}

.regulados {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 30px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sernac {
  max-width: 200px;
}

.cmf {
  max-width: 200px;
}

.cmfdr {
  align-content: center;

}

.marcas {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: white;
}

.boton-trabaja {
  background: var(--degradado,
      linear-gradient(35deg,
        rgba(29, 46, 255, 1) 0%,
        rgba(83, 180, 220, 1) 52.99999713897705%,
        rgba(0, 229, 255, 1) 100%));
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* flex-shrink: 0; */
  position: relative;
}

.frame {
  /* flex-shrink: 0; */
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}

.group6 {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  overflow: visible;
}

.vector33 {
  width: 22.43%;
  height: 22.43%;
  position: absolute;
  right: 10.66%;
  left: 66.91%;
  bottom: 65.44%;
  top: 12.13%;
  overflow: visible;
}

.trabaja-con-nosotros {
  color: var(--blanco, #f0f2f2);
  text-align: justified;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 28px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  display: flex;
  align-items: center;
}
</style>