<template>
  <div class="o-v-e-r-l-a-y-notevayas-container">
    <div class="o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas">
      <div class="o-v-e-r-l-a-y-notevayas-group288922">
        <span class="o-v-e-r-l-a-y-notevayas-text Roboto_H4_3242">
          <span>Ingrese su Rut</span>
        </span>
        <v-form ref="form" v-model="valid" >
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="12">
                            <div style="position: absolute; align-content: center;top: 116px;margin-left: 158px;width: 394px;text-align: right;">
                                <!-- <v-text-field v-model="numeroIngreso" :rules="numeroRules" label="Numero" required class="nn"
                                type="number"    @update:error="vnumero" color="rgb(56,0,151)" 
                                    >
                                    <template v-slot:append>                                       
                                        <v-icon v-if="!validnumero" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnumero" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field> -->
                                <v-text-field v-model="rut" :rules="rules" label="Rut" required 
                                    @update:error="vrut" color="rgba(0, 23, 135, 1)" 
                                    outlined
                                    style="width: 680px;
                                    border-radius: 10px;"
                                    >
                                    <template v-slot:append>
                                        <div v-if="validrut==`no`"></div>
                                        <v-icon v-else-if="!validrut" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validrut" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                              </div>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <div style="position: absolute;
                              align-content: center;
                              top: 192px;
                              margin-left: 162px;
                              width: 394px;
                              font-size: 20px;
                              text-align: center;
                              font-weight: 500;">
                              <span>
                              <span>Envíe su rut y enseguida le enviaremos un correo con las intrucciones para recuperar su contraseña</span>
                            </span>
                            </div>
                          </v-col>
                    </v-row>
                  </v-container>
          </v-form>
        <img
          src="/playground_assets/line331251-btmo.svg"
          alt="Line331251"
          class="o-v-e-r-l-a-y-notevayas-line33"
        />
        <div class="o-v-e-r-l-a-y-notevayas-group288921">
          <!-- <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
              <span class="o-v-e-r-l-a-y-notevayas-text4">
                <span>Salir</span>
              </span>
            </div>
          </div> -->

          <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
             
                <v-btn
                  color="neutral"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  @click="continuaOrigen"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text4">
                    <span>Salir</span>
                  </span>
                </v-btn>
            
            </div>
          </div>

          <div class="o-v-e-r-l-a-y-notevayas-usosdebotones2">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones3">
            
                <v-btn
                  color="juanito"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  @click="validate"
                  :disabled="!valid"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text6">
                    <span>Enviar</span>
                  </span>
                </v-btn>
            
            </div>
          </div>
         
          <!-- <span class="o-v-e-r-l-a-y-notevayas-text6">
                <span>Continuar</span>
              </span> -->
        </div>
      </div>
      
                <v-btn
                  color="transparent"
                  class="rounded-pill o-v-e-r-l-a-y-notevayas-icon-btns"
                  x-large
                  width="10px"
                  text
                  @click="continuaOrigen"

                >
                <img
            src="/playground_assets/iconbtns1251-loup.svg"
            alt="IconBtns1251"
            
          />
                </v-btn>
    
      
    </div>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </div>
</template>

<script>
import privado from "../../../services/privado";
import logContratar from "../../../services/logContratar";
export default {
  name: "LoginRecuperacion",
  data() {
    return {
      rut: "",
      validrut: "no",
      overlay: false,
      numeroIngreso: "",
      valid: false,
      SeguroDetalle : {},
      numeroRules: [
                (v) =>  !!v  || "número requerido",
                (v) => (v >= 999) || "número inválido",
                (v) => (v <= 999999) || "número inválido",
            ],
      validnumero : false,
      bitacora: {
        bitacoraPlan: {
          spl_numero: 0,
          spl_seguro_numero: 0,
          spl_pln_numero: 0,
          cse_numero: 0,
          cse_ctt_numero: 0,
        },
        bitacoraPago: {
          estado_pago: 0,
          cce_transaccion: 0,
        },
      },
    }
  },
  props: {
    Seguro : {},
    cierraModal : "",
    ident: 0,
  },
  methods: {
    validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
            if(rutCompleto=="44444444-4"){
                return true;
            }
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        
      formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.rut=rutPuntos;
        },
        largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.rut=rut.replace(/[^kK0-9.-]/gi,"");
      
      return true;
    },
    vnumero(v) {        
            this.validnumero = v;
        },
    vrut(v) {
        this.validrut = v;
        },
    validate() {
            if (this.$refs.form.validate()) {
                this.Recuperar();               
            }
    },
  async  Recuperar(){   
          let res="";      
          this.overlay=true;
          var rut=this.rut.split(".").join("");
          var cuenta={
                    acc_rut: rut,
                    };
          console.log(cuenta);
          res = await privado.recuperarPass(cuenta);          
          if (res.status == 200 && res.data.estadoTransaccion.success) {
              
                          this.$alertas(res.data.estadoTransaccion.errors,"success");
                          this.overlay = false;
                          this.$emit('cierraModal');

                      }else{
                          this.$alertas(res.data.estadoTransaccion.errors,"error");
                          this.overlay = false;
                          this.$emit('cierraModal');
                      }
                   
          
    },
    continuaOrigen(){
      this.$emit('cierraModal');
    }

  },
  computed : {
    rules() {
            const rules = [];
            let rule="";
            rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.rut) || `rut inválido`;
            if(this.rut!=""){
            this.formateaRut(this.rut);
            }
            rules.push(rule);
            
            return rules;
        },
  },
  metaInfo: {
    title: 'Mueve Seguro',
  },
  created() {
        
  },
};
</script>

<style scoped>
.o-v-e-r-l-a-y-notevayas-container {
  min-height: 315px;
}
.o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas {
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.o-v-e-r-l-a-y-notevayas-group288922 {
  top: 0px;
  left: 0px;
  width: 744px;
  height: 400px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(241, 241, 243, 1);
}
.o-v-e-r-l-a-y-notevayas-text {
  width: 662px;
  top: 49px;
  left: 251px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.o-v-e-r-l-a-y-notevayas-text2 {
  top: 184px;
  left: 92px;
  color: var(--dl-color-colores_neutrales-90);
  width: 560px;
  height: auto;
  position: absolute;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 146.00000381469727%;
  font-stretch: normal;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-line33 {
  top: 135px;
  left: 101px;
  width: 542px;
  height: 0px;
  position: absolute;
}
.o-v-e-r-l-a-y-notevayas-group288921 {
  top: 295px;
  left: 83px;
  width: 525px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones {
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones1 {
  top: -15px;
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  /* background-color: var(--dl-color-colores_neutrales-10); */
}
.o-v-e-r-l-a-y-notevayas-text4 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones2 {
  top: 0px;
  left: 305px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones3 {
  top: -15px;
  width: 400px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.o-v-e-r-l-a-y-notevayas-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-icon-btns {
  top: 20px;
  left: 650px;
  width: 52px;
  height: 52px;
  position: absolute;
}
</style>
