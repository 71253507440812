<template>
    <div>
        <v-dialog v-model="dialogSeguroBorrar" class="modalSize" max-width="70%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">
                                    ELIMINAR SIMULACIÓN
                                </div>

                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="">
                                    <v-form ref="form" >

                                        <v-row dense class="colcenter">
                                            <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    ¿Esta seguro de eliminar la Simulación?
                                                </div>
                                            </v-col>


                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="line-1"></div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="frame-32-2">
                                                    <v-row class="colcenter">
                                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                                            <v-btn class="sm-size" outlined rounded x-large
                                                                @click="continuaOrigen"><span><span>REGRESAR</span></span></v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                                            <v-btn class="sm-size" rounded x-large @click="Eliminar"
                                                                color="#1d2eff" ><span><span
                                                                        style="color:white">Eliminar</span></span></v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import privado from "../../../../../services/privado";
export default {
    name: "EliminaSeguro",
    data() {
        return {
            overlay: false,
            dialogSeguroBorrar: false
        }
    },
    props: {
        numeroSeguro: false,
        Seguro: {},
        cierraModal: "",
        ident: "",
    },
    methods: {


        async Eliminar() {

            var res = false;
            this.overlay = true;
            this.dialogSeguroBorrar=false;
            var simulacion = { seg_numero: this.numeroSeguro }
            console.log(simulacion);
            res = await privado.eliminarPlanSimulacion(simulacion);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                this.$alertas(res.data.estadoTransaccion.errors, "success");
                this.overlay = false;
                this.$root.$refs.HomePrivado.cambiaMenu(3);
                this.dialogSeguroBorrar=false;
            } else {
                this.$alertas(res.data.estadoTransaccion.errors, "info");
                this.overlay = false;
                this.$root.$refs.HomePrivado.cambiaMenu(3);
                this.dialogSeguroBorrar=false;
            }

        },
        continuaOrigen() {
            this.dialogSeguroBorrar=false;
        }

    },
   
    created() {
        this.$root.$refs.ModalBorrarSeguro = this;
    },
};
</script>
<style scoped>
.sm-size{
  width: 90%;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 70%;
}

.marcoDialog {
    background: var(--blanco, #f0f2f2);

}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 33px;
    height: 33px;
    position: relative;
    overflow: visible;
}

.el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.7rem);
    position: relative;
    width: 100%;
}

.devoluci-n-aproximada-de-prima-no-consumida {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.7rem);
    position: relative;
}

.valores {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 36px;
    line-height: 36px; */
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.6rem);
    font-weight: 900;
    position: relative;

}

.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem, 5vw, 2.2rem);
    line-height: clamp(1.3rem, 5vw, 2.6rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .dialogStyle {
        width: 100% !important;
    }
    .sm-size{
        width: 100%;
    }
    .vector {
        width: 20px !important;
        height: 20px !important;
    }
}
</style>