<template>
    <div>

        <v-row class="colcenter">
           
            <v-col cols="12" sm="12" md="8" lg="6" xl="6" class="colcenter">
                <div class="base-image-input" :style="{ 'background-image': `url(${imageData})` }" @click="chooseImage"
                    @mouseover="hoverimg('frontal')" @mouseout="hoveroutimg('frontal')">
                    <span class="placeholder">
                        <span v-show="botonhover || (imageDatadef == imageData)" class="sbtn sbtnnew" >
                            Subir imagen frontal
                        </span>
                    </span>
                    <input class="file-input" ref="fileInput" type="file" accept="application/pdf, .gif,.jpeg"
                        @input="onSelectFile">
                </div>
              
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="6" xl="6" class="colcenter">
                <div class="base-image-input" :style="{ 'background-image': `url(${imageData2})` }"
                    @click="chooseImage2" @mouseover="hoverimg('posterior')" @mouseout="hoveroutimg('posterior')">
                    <span class="placeholder">
                        <span v-show="botonhover2 || (imageDatadef2 == imageData2)" class="sbtn2 sbtnnew" >
                            Subir imagen posterior
                        </span>
                    </span>
                    <input class="file-input" ref="fileInput2" type="file" accept="application/pdf, .gif,.jpeg"
                        @input="onSelectFile2">
                </div>
            </v-col>

            
            <v-col cols="12" sm="12" class="colcenter">
                <v-row class="colcenter">
                    
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                       
                        <v-btn class="btn1class" color="#04137F" rounded x-large
                             :disabled="!vbh || !vbh2" @click="guardarDocumento">
                            <v-icon v-if="!guardado" left color="white" size="30px" class="icon1class">
                                mdi-arrow-up-circle
                            </v-icon>
                            <v-icon v-if="guardado" left color="white" size="30px" class="icon2class">
                                mdi-check-circle
                            </v-icon>
                            <span class="textoCargar">Cargar</span>
                        </v-btn>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="line-1"></div>
                  </v-col>  
                  <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                        <div class="frame-32-2">
                            <v-row class="colcenter">
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                <v-btn outlined rounded x-large @click="volver"><span
                                    ><span>REGRESAR</span></span></v-btn>
                                </v-col>      
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                <v-btn rounded x-large @click="continuar" color="#1d2eff" :disabled="!vbh || !vbh2 || !guardado"><span
                                    ><span style="color:white">CONTINUAR</span></span></v-btn>
                                </v-col>                 
                            </v-row>          
                        </div>
                    </v-col>        
                    
                </v-row>
            </v-col>

        </v-row>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
import fileservice from "../../../../../services/fileservice.js";
export default {
name: "DevolucionSeguro",
components: {

},
data() {
return {
    imageDatadef: "/playground_assets/seguros/cotizacion/validacionidentidad1.svg",
    imageData: "/playground_assets/seguros/cotizacion/validacionidentidad1.svg",
    botonhover: false,
    imageDatadef2: "/playground_assets/seguros/cotizacion/validacionidentidad2.svg",
    imageData2: "/playground_assets/seguros/cotizacion/validacionidentidad2.svg",
    botonhover2: false,
    vbh: false,
    vbh2: false,
    seguro: this.$store.state.seguroSeleccion,
    estadoPrivado: this.$store.state.estadoPrivado,
    guardado: false,
    overlay: false,
    file1 : "",
    file2 : "",
}
},
props : {
renuncia: 0,
},
methods:{
    volver() {
       if(this.$root.$refs.CotizaForm.isLogin){
        //this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;   
        this.$router.push("/mi-cuenta");     
       }else{
        this.$router.push("/home");
       }       
       
    },
    continuar() {
        this.$root.$refs.CotizaForm.traeDocumentos(1);
        this.$root.$refs.CotizaForm.traeDocumentos(2);
       this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
    },
    chooseImage() {
        this.$refs.fileInput.click()
    },
    chooseImage2() {
        this.$refs.fileInput2.click()
    },
    
    onSelectFile2 () {
        const input = this.$refs.fileInput2
        const files = input.files
        this.file2=files[0]
        if (files && files[0]) {
            const reader = new FileReader
            reader.onload = e => {
            this.imageData2 = e.target.result
            this.vbh2=true
            }
            console.log(files[0]);
            reader.readAsDataURL(files[0])
            
            this.$emit('input', files[0])
        }
    },
        
    onSelectFile () {
        const input = this.$refs.fileInput
        const files = input.files
        this.file1=files[0]
        if (files && files[0]) {
            const reader = new FileReader
            reader.onload = e => {
            this.imageData = e.target.result
            this.vbh=true
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
        }
    },
    hoverimg(tipo){
        if(tipo=="frontal"){
            this.botonhover=true;
        }
        if(tipo=="posterior"){
            this.botonhover2=true;
        }
    },
    hoveroutimg(tipo){
        if(tipo=="frontal"){
            this.botonhover=false;
        }
        if(tipo=="posterior"){
            this.botonhover2=false;
        }
    },
    async guardarDocumento() {
      this.overlay = true;
      var res = false;

      this.$root.$refs.CargaDocumento.seguro = this.$store.state.seguroSeleccion;
      this.$root.$refs.CargaDocumento.preguntasDocumentos = this.$store.state.preguntasDocumentos;
     
      var documento = {
        file : this.file1,
        seg_numero: this.seguro,
        cdo_tipo_docto : 1
      };
      res = await fileservice.GuardaIdentidad(documento);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success"); 
        this.guardado=true;       
        this.overlay = false;
        this.guardarDocumento2();
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
        this.overlay = false;
        this.guardado=false;  
      }
    },
    async guardarDocumento2() {
      this.overlay = true;
      var res = false;

      var documento = {
        file : this.file2,
        seg_numero: this.seguro,
        cdo_tipo_docto : 2
      };
      res = await fileservice.GuardaIdentidad(documento);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success"); 
        this.guardado=true;       
        this.overlay = false;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
        this.overlay = false;
        this.guardado=false;  
      }
   }
  },
  mounted() {
    var identidad=this.$store.state.estadoIdentidad;
    console.log(identidad);
    if(identidad){
      this.guardado=true;
      this.vbh=true;
      this.vbh2=true;
    }else{
      this.guardado=false;
      this.vbh=false;
      this.vbh2=false;
    }
  },
  created() {
    this.$root.$refs.Identidad = this;
  },
}
</script>
<style scoped>
.colcenter {
  display: flex;
  justify-content: center;
}
.h1class{
    width: 100%;
    display: block;
    margin-top: -40px;
    text-align: center;
}
.sbtnnew{
    border: solid;
    padding: 5px;    
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 25px;
    background-color: white;
}
.icon1class{
    margin-right: 30px;
    color : white;
}
.icon2class{
    margin-right: 30px;
    color : white;
}
.btn1class{
    color:white;
    text-transform: unset !important;
}
.btn2class{
    color:white;
    text-transform: unset !important;
    /* margin-left:-350px; */
}
.base-image-input {
  display: block;
  cursor: pointer;
  border-radius: 10px;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 200px;
}
.placeholder {
  background: #f0f0f02c;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.sbtn:hover {
  background: black !important;
  color:white !important;
}
.sbtn2:hover {
  background: black !important;
  color: white !important;
}
.file-input {
  display: none;
}
.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}
.textoCargar{
    color: white;
}
.frame-32-2 {
  border-radius: 80px;
  padding: 9px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
    .sm-size{
    font-size: small !important;
    }
    .base-image-input {
        height: 150px !important ;
    }
}
@media only screen and (max-width: 1000px) {
    .base-image-input {
        height: 180px ;
    }
}
</style>
