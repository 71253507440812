<template>
  <div style="
    top: 200px;
    width: 1525px;
    display: flex;
    position: absolute;
    flex-direction: column;   
   
">
    <div style="
    top: 200px;
    width: 1525px;
    height: 450px;
    flex-direction: column;
    margin-left: -450px;
    background-color: white;
    border-radius: 25px;
    padding: 45px;
    padding-left: 20%;
    padding-right: 20%;
    -webkit-box-shadow: -3px 9px 35px 0px rgba(0,0,0,0.1);
-moz-box-shadow: -3px 9px 35px 0px rgba(0,0,0,0.1);
box-shadow: -3px 9px 35px 0px rgba(0,0,0,0.1);
">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row style="    display: flex;
justify-content: center;">
            <v-col cols="10" sm="10" style=" ">
              <h1 style="width: 100%;
              display: block;
              margin-top: -40px;
              text-align: center">
                Términos y condiciones
              </h1>
              <h3 style="text-align: center"></h3>

            </v-col>

            <v-col cols="12" sm="12">
              <div class="cuadro-terminos">
                <v-row>
                  <v-col cols="12" sm="12"></v-col>
                 
                  <v-col cols="3" sm="3">

                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-checkbox v-model="acepta" :rules="respRules" required @update:error="vresp">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a class="color-termininos" target="_blank" @click="leerTerminos" v-on="on">
                                términos y condiciones
                              </a>
                            </template>
                            abrir en nueva ventana
                          </v-tooltip>
                          de MueveSeguro
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3" sm="3">

                  </v-col>
                  <v-col cols="3" sm="3">

                  </v-col>
                 
                  <v-col cols="6" sm="6" style="margin-top: -45px">
                    <v-checkbox v-model="aceptaPrivacidad" :rules="respRulesPrivacidad" required
                      @update:error="vrespPrivacidad">
                      <template v-slot:label>
                        <div>
                          Acepto
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a class="color-termininos" target="_blank" @click="leerPrivacidad" v-on="on">
                                políticas y privacidad
                              </a>
                            </template>
                            abrir en nueva ventana
                          </v-tooltip>
                          de MueveSeguro
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3" sm="3">

                  </v-col>


                </v-row>

              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-container class="px-0" fluid>

                  </v-container>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12"></v-col>
            <v-col cols="3" sm="3">

            </v-col>
            <v-col cols="1" sm="1"></v-col>
            <v-col cols="3" sm="3">


            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div style="

  width: 1525px;
  margin-left: -450px;">
      <v-row>
        <v-col cols="12" sm="12"></v-col>
        <v-col cols="12" sm="12"></v-col>


        <v-col cols="3" sm="3"></v-col>
        <v-col cols="2" sm="2">
          <v-btn style="color:white;text-transform: unset !important ;margin-left:-200px" color="secondary" rounded
            outlined x-large block @click="volver()">
            Volver
          </v-btn>
        </v-col>
        <v-col cols="3" sm="3"></v-col>
        <v-col cols="2" sm="2">
          <v-btn style="color:white;text-transform: unset !important;right: -130px;" color="juanito" rounded x-large
            block @click="validate()" :disabled="!valid">
            Continuar
          </v-btn>
        </v-col>
        <v-col cols="3" sm="3">

        </v-col>
      </v-row>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import privado from "../../../../../services/privado";
import datausuario from "../../../../../services/usuario-datos";
import plan from "../../../../../services/plan";

export default {
  name: "TerminosCondiciones",
  components: {

  },
  data() {
    return {

      preguntas: [],
      respuestas: [],
      acepta: false,
      aceptaPrivacidad: false,
      overlay: false,
      valid: false,
      validPrivacidad: false,
      respRules: [(v) => !!v || ""],
      respRulesPrivacidad: [(v) => !!v || ""],
      validrespPrivacidad: true,
      dialogConfirma: false
    }
  },
  props: {
    renuncia: 0,
    tipo: "",
  },
  methods: {
    guardarFormulario() {
      // this.dialogConfirma = false
      // this.guardarDps()
    },
    vrespPrivacidad(v) {
      this.validrespPrivacidad = v;
    },
    async validate() {

      if (this.$refs.form.validate()) {

        this.aprobar();

      }
    },

    async aprobar() {
      this.overlay = true;
      let res = false;
      var simulacion = this.$store.state.simulacion;

      var preparaSimulacion = {
        ctt_simulacion: simulacion.sim_numero,
        dpsRespuestas: []
      }

      res = await plan.aprobar(preparaSimulacion);
      console.log(res);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
        if (res.data.estadoTransaccion.success) {
          this.$store.commit('setcontrato', res.data.contrato);
        }
        this.$root.$refs.FormularioLinea.estadoFormLinea = 7;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }

      this.overlay = false;
    },
    async leerTerminos() {
      this.overlay = true;
      var res = false;
      var documento = false;
      var ext = "";
      var nombreDoc = "";
      var simulacion = this.$store.state.simulacion;
      var sim = {
        "sim_numero":  simulacion.sim_numero
      }
      res = await datausuario.leerTerminosLinea(sim);

      if (res.status == 200 && res.data.estadoTransaccion.success) {
        // this.$alertas(res.data.estadoTransaccion.errors, "success");
        documento = res.data.cdo_docto;
        nombreDoc = res.data.cdo_nombre_docto;
        ext = res.data.cdo_extension.toLowerCase();

        const binaryString = atob(documento);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });

        console.log(blob);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = nombreDoc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        this.overlay = false;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
        this.overlay = false;
      }
    },
    async leerPrivacidad() {
      this.overlay = true;
      var res = false;
      var documento = false;
      var ext = "";
      var nombreDoc = "";
      var simulacion = this.$store.state.simulacion;
      var sim = {
        "sim_numero":  simulacion.sim_numero
      }
      res = await datausuario.leerPoliticaLinea(sim);

      if (res.status == 200 && res.data.estadoTransaccion.success) {
        // this.$alertas(res.data.estadoTransaccion.errors, "success");
        documento = res.data.cdo_docto;
        nombreDoc = res.data.cdo_nombre_docto;
        ext = res.data.cdo_extension.toLowerCase();

        const binaryString = atob(documento);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });

        console.log(blob);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = nombreDoc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        this.overlay = false;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
        this.overlay = false;
      }
    },
    revisa(value) {
      console.log(this.respuestas);
    },

    volver() {
      this.$root.$refs.FormularioLinea.estadoFormLinea = 3;
    },
    continuar() {
      this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
    },
  },
  computed: {

  },
  mounted() {
    // this.traeDps()
  }
}
</script>

<style scoped>
.colordialog {
  background-color: white !important;
}

.v-application {
  border-radius: 20px !important;
}

.cuadro-terminos {
  border: 1px solid #a9a9a926;
  height: 200px;
  border-radius: 20px;
  background-color: #dbe1db24;
}

.color-termininos {
  color: black;
  font-weight: 900;
}
</style>