<template>
  <v-app>
    <v-container fluid style="background-color: white">
      <v-layout row wrap>
        <v-flex color="primary" sm1 border style="border: 1px">
          <h1 style="display: none !important">1</h1>
          <div class="frame2loginnormal-frame2loginnormal">
            <img
              src="/playground_assets/login/rectangulofondogradiente21186-mk8.svg"
              alt="RectanguloFondoGradiente21186"
              class="frame2loginnormal-rectangulo-fondo-gradiente2"
            />
            <img
              src="/playground_assets/login/rectangulofondogradiente21186-dh4d.svg"
              alt="RectanguloFondoGradiente21186"
              class="frame2loginnormal-rectangulo-fondo-gradiente21"
            />
            <!-- <img
                  src="/playground_assets/login/line161186-64vo.svg"
                  alt="Line161186"
                  class="frame2loginnormal-line16"
                /> -->
          </div>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
          <div class="frame2loginnormal-group495">
            <div class="frame2loginnormal-group498">
              <div class="frame2loginnormal-group">
                <div class="frame2loginnormal-group1">
                  <img
                    src="/playground_assets/login/vector1865-ex43.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector"
                  />
                  <img
                    src="/playground_assets/login/vector1865-7if.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector01"
                  />
                  <img
                    src="/playground_assets/login/vector1865-36j9.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector02"
                  />
                  <img
                    src="/playground_assets/login/vector1865-t058.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector03"
                  />
                  <img
                    src="/playground_assets/login/vector1865-8lgd.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector04"
                  />
                </div>
                <div class="frame2loginnormal-group2">
                  <img
                    src="/playground_assets/login/vector1865-cy17.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector05"
                  />
                  <img
                    src="/playground_assets/login/vector1865-sw1h.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector06"
                  />
                  <img
                    src="/playground_assets/login/vector1865-6oij.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector07"
                  />
                  <img
                    src="/playground_assets/login/vector1865-pind.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector08"
                  />
                  <img
                    src="/playground_assets/login/vector1865-qus8.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector09"
                  />
                  <img
                    src="/playground_assets/login/vector1865-bo1ox.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector10"
                  />
                  <img
                    src="/playground_assets/login/vector1865-h1xb.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector11"
                  />
                  <img
                    src="/playground_assets/login/vector1865-w12l.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector12"
                  />
                  <img
                    src="/playground_assets/login/vector1865-z268.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector13"
                  />
                  <img
                    src="/playground_assets/login/vector1865-qb5.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector14"
                  />
                  <img
                    src="/playground_assets/login/vector1865-2tes.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector15"
                  />
                </div>
                <div class="frame2loginnormal-group3">
                  <div class="frame2loginnormal-group4">
                    <img
                      src="/playground_assets/login/vector1865-e1nn.svg"
                      alt="Vector1865"
                      class="frame2loginnormal-vector16"
                    />
                  </div>
                  <div class="frame2loginnormal-group5">
                    <img
                      src="/playground_assets/login/vector1865-mvu.svg"
                      alt="Vector1865"
                      class="frame2loginnormal-vector17"
                    />
                  </div>
                </div>
              </div>
              <div class="frame2loginnormal-group6">
                <img
                  src="/playground_assets/login/vector1865-ivwo.svg"
                  alt="Vector1865"
                  class="frame2loginnormal-vector18"
                />
                <img
                  src="/playground_assets/login/vector1865-cx9v.svg"
                  alt="Vector1865"
                  class="frame2loginnormal-vector19"
                />
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <img
            src="/playground_assets/login/rectangle401186-wat5.svg"
            alt="Rectangle401186"
            class="frame2loginnormal-rectangle40"
          /> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm10>
          <h1 style="display: none !important">2</h1>
          <div class="frame2loginnormal-rectangle40"></div>
          <span class="frame2loginnormal-text12">
            <span>Recuperación de contraseña</span>
          </span>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <img
                        src="/playground_assets/login/rectangle391186-j99t-700h.png"
                        alt="Rectangle391186"
                        class="frame2loginnormal-rectangle39" -->
          <!-- <div class="frame2loginnormal-rectangle39"></div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm10>
          <h1 style="display: none !important">2</h1>

          <div class="frame2loginnormal-rectangle39">
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                <v-col cols="1" sm="1"></v-col>
                <v-col cols="6" sm="6">
                  <!-- <v-col cols="12" sm="12">
                    <span class="frame-activacindecuenta-text26">
                    <span class="frame-activacindecuenta-text27">
                        <span>1.- Te damos la bienvenida,</span>
                        <br />
                        <span></span>
                    </span>
                    <span>por favor rellena con tus datos personales:</span>
                    </span>
                  </v-col> -->
                  <v-col cols="12" sm="12">
                   
                  </v-col>                  
                  <!-- <v-col cols="12" sm="12">
                    <v-row dense>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.nombre1" :rules="nombre1Rules" label="Primer nombre:" required class="nombre1 campotexto"
                                single-line outlined  @update:error="vnombre1" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>                 
                                        <div v-if="validnombre1==`no`"></div>                      
                                        <v-icon v-else-if="!validnombre1" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre1" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.nombre2" :rules="nombre2Rules" label="Segundo nombre:" required class="nombre2 campotexto"
                                single-line outlined  @update:error="vnombre2" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>                 
                                        <div v-if="validnombre2==`no`"></div>                      
                                        <v-icon v-else-if="!validnombre2" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre2" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.apellido1" :rules="apellido1Rules" label="Apellido Paterno:" required class="apellido1 campotexto"
                                single-line outlined  @update:error="vapellido1" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>                 
                                        <div v-if="validapellido1==`no`"></div>                      
                                        <v-icon v-else-if="!validapellido1" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validapellido1" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.apellido2" :rules="apellido2Rules" label="Apellido Materno:" required class="apellido2 campotexto"
                                single-line outlined  @update:error="vapellido2" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>                 
                                        <div v-if="validapellido2==`no`"></div>                      
                                        <v-icon v-else-if="!validapellido2" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validapellido2" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="formCuenta.mail" :rules="mailRules" label="Mail:" required class="mail campotexto"
                                     single-line outlined  @update:error="vmail" color="rgb(56,0,151)" readonly
                                    >
                                    <template v-slot:append>  
                                        <div v-if="validmail==`no`"></div>                                  
                                        <v-icon v-else-if="!validmail" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validmail" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="formCuenta.celular" :rules="celularRules" label="Celular:" required class="celular campotexto"
                                     single-line outlined  @update:error="vcelular" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>
                                        <div v-if="validcelular==`no`"></div>                                       
                                        <v-icon v-else-if="!validcelular" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validcelular" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="formCuenta.direccion" :rules="direccionRules" label="Dirección" required class="direccion campotexto"
                                     single-line outlined  @update:error="vdireccion" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>        
                                        <div v-if="validdireccion==`no`"></div>                               
                                        <v-icon v-else-if="!validdireccion" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validdireccion" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.depto"  :rules="deptoRules" label="Depto.:" required class="depto campotexto"
                                     single-line outlined  @update:error="vdepto" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>     
                                        <div v-if="validdepto==`no`"></div>                                  
                                        <v-icon v-else-if="!validdepto" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validdepto" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="formCuenta.numero"  :rules="numeroRules" label="Número" required class="numero campotexto"
                                     single-line outlined  @update:error="vnumero" color="rgb(56,0,151)" readonly 
                                    >
                                    <template v-slot:append>         
                                        <div v-if="validnumero==`no`"></div>                                 
                                        <v-icon v-else-if="!validnumero" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnumero" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-select
                                v-model="seleccionRegion"
                                :items="regiones"
                                required                                
                                single-line outlined
                                label="Región:"
                                color="rgb(56,0,151)"
                                :rules="regionRules"
                                v-on:change="selectregion"
                                style="cursor: pointer"
                                class="campotexto"
                                >
                                <template v-slot:append>
                                    <div v-if="validregion==`no`"></div>    
                                    <v-icon
                                    color="rgba(0, 23, 135, 1)"
                                    v-else-if="validregion"
                                    style="margin-bottom: 10px !important"
                                    >
                                    mdi-checkbox-marked-circle
                                    </v-icon>
                                </template>
                                </v-select>
                            </v-col>
                          
                            <v-col cols="6" sm="6">
                                <v-select
                                v-model="seleccionCiudad"
                                :items="ciudades"
                                required
                                single-line outlined
                                label="Comuna:"
                                color="rgb(56,0,151)"
                                :rules="ciudadRules"
                                v-on:change="selectciudad"
                                style="cursor: pointer"
                                class="campotexto"
                                >
                                <template v-slot:append>
                                    <div v-if="validciudad==`no`"></div> 
                                    <v-icon
                                    color="rgba(0, 23, 135, 1)"
                                    v-else-if="validciudad"
                                    style="margin-bottom: 10px !important"
                                    >
                                    mdi-checkbox-marked-circle
                                    </v-icon>
                                </template>
                                </v-select>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-select
                                v-model="seleccionComuna"
                                :items="comunas"
                                required
                                single-line outlined
                                label="Comuna:"
                                color="rgb(56,0,151)"
                                :rules="comunaRules"
                                v-on:change="selectcomuna"
                                style="cursor: pointer"
                                class="campotexto"
                                >
                                <template v-slot:append>
                                    <div v-if="validcomuna==`no`"></div> 
                                    <v-icon
                                    color="rgba(0, 23, 135, 1)"
                                    v-else-if="validcomuna"
                                    style="margin-bottom: 10px !important"
                                    >
                                    mdi-checkbox-marked-circle
                                    </v-icon>
                                </template>
                                </v-select>
                            </v-col>
                            
                    </v-row>
                           
                    
                  </v-col> -->
                  <v-col cols="12" sm="12">
                    <span class="frame-activacindecuenta-text26">
                    <span class="frame-activacindecuenta-text27">
                        <span>Ingresa tu nueva clave y no</span>
                        <br />
                        <span></span>
                    </span>
                    <span>la compartas con nadie</span>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12">
                   <div class="cuadroPass">
                    <span class="frame-activacindecuenta-text47">
                        <span class="frame-activacindecuenta-text49">
                        <span>La contraseña debe ser <b>ALFANUMERICA</b></span>
                    </span>
                    </span>
                </div>
                  </v-col> 
                  <v-col cols="12" sm="12">
                  <v-row dense>
                    <v-col cols="6" sm="6">
                        <v-text-field
                        single-line outlined
                        required
                        color="rgba(0, 23, 135, 1)"     
                        class="campotexto"               
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="claveRules"
                        @update:error="vclave"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Ingresar clave"
                        @click:append="show1 = !show1"
                        ></v-text-field>     
                    </v-col>
                        <v-col cols="6" sm="6">
                            <v-text-field
                            single-line outlined
                            required
                            class="campotexto"
                            color="rgba(0, 23, 135, 1)"                    
                            v-model="password2"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="clave2Rules"
                            @update:error="vclave2"
                            :type="show2 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Repite tu nueva clave"
                            @click:append="show2 = !show2"
                            ></v-text-field>     
                        </v-col>
                        </v-row>
                    </v-col>
                    </v-col>
                    <v-col cols="1" sm="1"></v-col> 
                    <v-col cols="4" sm="4"> 
                        <v-col cols="12" sm="12">
                        <v-row dense>
                            <v-col cols="12" sm="12"></v-col>
                            <br>
                            <br>
                            <br>
                            <br>
                            <v-col cols="12" sm="12">                            
                                <span class="frame-activacindecuenta-text35">
                                <span>¿Necesitas ayuda?</span>
                                </span>
                            </v-col>
                            <br>
                            <br>
                            
                            <v-col cols="12" sm="12"></v-col>
                            <v-col cols="2" sm="2">
                                <img
                                src="/playground_assets/login/micuenta/ellipse2911186-pqgq-200h.png"
                                alt="Ellipse2911186"
                                class="frame-activacindecuenta-ellipse291"
                                />
                            </v-col>
                            <v-col cols="10" sm="10">
                                <span class="frame-activacindecuenta-text37">
                                <span>Lorem Ipsum is simply</span>
                                </span>
                            </v-col>
                            <v-col cols="2" sm="2">
                                <img
                                src="/playground_assets/login/micuenta/ellipse2911186-pqgq-200h.png"
                                alt="Ellipse2911186"
                                class="frame-activacindecuenta-ellipse291"
                                />
                            </v-col>
                            <v-col cols="10" sm="10">
                                <span class="frame-activacindecuenta-text39">
                                <span>Lorem Ipsum is simply</span>
                                </span>
                            </v-col>
                            <v-col cols="2" sm="2">
                                <img
                                src="/playground_assets/login/micuenta/ellipse2911186-pqgq-200h.png"
                                alt="Ellipse2911186"
                                class="frame-activacindecuenta-ellipse291"
                                />
                            </v-col>
                            <v-col cols="10" sm="10">
                                <span class="frame-activacindecuenta-text41">
                                <span>Lorem Ipsum is simply</span>
                                </span>
                            </v-col>
                                
                                <!-- <img
                                src="/playground_assets/login/micuenta/ellipse2921186-umrdaj-200h.png"
                                alt="Ellipse2921186"
                                class="frame-activacindecuenta-ellipse292"
                                />
                                <img
                                src="/playground_assets/login/micuenta/ellipse2931186-s94-200h.png"
                                alt="Ellipse2931186"
                                class="frame-activacindecuenta-ellipse293"
                                />
                                <img
                                src="/playground_assets/login/micuenta/navigationarrowdropdown24px1186-8nz8.svg"
                                alt="navigationarrowdropdown24px1186"
                                class="frame-activacindecuenta-navigationarrowdropdown24px"
                                />
                                <img
                                src="/playground_assets/login/micuenta/navigationarrowdropdown24px1186-6bnx.svg"
                                alt="navigationarrowdropdown24px1186"
                                class="frame-activacindecuenta-navigationarrowdropdown24px1"
                                /> -->
                       
                    </v-row>
                </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>

          <div style="position: relative; margin-top: 30px">
            <v-btn
              color="rgba(255, 184, 78)"
              @click="validate"
              :disabled="!valid"
              class="frame75persona-cotizarsegurode-desgravamen-usosdebotones1"
              rounded
              x-large
              style="margin-left: 76%; top: 0px; text-transform: unset !important"
            >
              <span class="frame75persona-cotizarsegurode-desgravamen-text02">
                <span>Guardar contraseña</span>
              </span>
            </v-btn>
            <v-btn color="transparent"  text
                    style=" position: relative; margin-left: 76%;top: 51px;width: 250px;text-transform: unset !important;" 
                    :disabled="!valid">
                    <span class="frame644persona-formulario-lneadesgravamen-text08">
                      <span>¿Necesitas ayuda?</span>
                    </span>
                    <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                </v-btn>
            <v-btn
              color="grey darken-1 "
              class="frame75persona-cotizarsegurode-desgravamen-usosdebotones"
              rounded
              x-large
              style="margin-left: 60px; top: 0px; text-transform: unset !important"
              @click="volver"
            >
              <span class="frame75persona-cotizarsegurode-desgravamen-text">
                <span>Volver</span>
              </span>
            </v-btn>
          </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div class="frame2loginnormal-group508"> -->

          <!-- </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
          <!-- <span class="frame2loginnormal-text"><span>¿Necesitas ayuda?</span></span>
              <div class="frame2loginnormal-usosdebotones2">
              <span class="frame2loginnormal-text04"><span>Volver</span></span>
            </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">13</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">14</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">15</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <!-- <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">16</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">2</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">3</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">4</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">5</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">6</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">7</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">8</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">9</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">10</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">11</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">12</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">17</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">2</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">3</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">4</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">5</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">6</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">7</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">8</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">9</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">10</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">11</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">12</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">18</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">2</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">3</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">4</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">5</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">6</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">7</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">8</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">9</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">10</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">11</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">12</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">19</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">2</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">3</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">4</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">5</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">6</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">7</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">8</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">9</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">10</h1>
                    </v-flex>
                    <v-flex color="primary" sm1>
                        <h1 style="display:none !important ;">11</h1>
                    </v-flex>
                    <v-flex secondary sm1>
                        <h1 style="display:none !important ;">12</h1>
                    </v-flex> -->
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">20</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">21</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">1</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame498centro" id="cfunciona"><span data-v-fae5bece=""
                                class="frame1home-text026centro"><span data-v-fae5bece="">¿Cómo funciona?</span></span><span
                                data-v-fae5bece="" class="frame1home-text028centro"><span data-v-fae5bece=""> Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit, sed do.. </span></span></div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group459"><img data-v-fae5bece="" alt="Ellipse2791185"
                                src="/playground_assets/login/ellipse2791185-vgf3-200h.png" class="frame1home-ellipse279"><img
                                data-v-fae5bece="" alt="Vector1185" src="/playground_assets/login/vector1185-88a3.svg"
                                class="frame1home-vector"></div>
                        <div data-v-fae5bece="" class="frame1home-frame52">
                            <div data-v-fae5bece="" class="frame1home-frame51"><span data-v-fae5bece=""
                                    class="frame1home-text010"><span data-v-fae5bece="">Regístrate</span></span>
                            </div><span data-v-fae5bece="" class="frame1home-text012"><span data-v-fae5bece="">Completa el
                                    registro para que podamos aprobar tu
                                    línea.</span></span>
                        </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
          <!-- <img data-v-fae5bece="" alt="ESCUDO11185" src="/playground_assets/login/escudo11185-i5n.svg"
                            class="frame1home-e-s-c-u-d-o1"> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group461"><img data-v-fae5bece="" alt="Ellipse2811185"
                                src="/playground_assets/login/ellipse2811185-0s4sp-200h.png" class="frame1home-ellipse281"><img
                                data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-t93r.svg"
                                class="frame1home-vector001"></div>
                        <div data-v-fae5bece="" class="frame1home-frame58">
                            <div data-v-fae5bece="" class="frame1home-frame511"><span data-v-fae5bece=""
                                    class="frame1home-text014"><span data-v-fae5bece="">Contrátalo</span></span>
                            </div><span data-v-fae5bece="" class="frame1home-text016"><span data-v-fae5bece=""> El
                                    comparador de prima te dará los resultados y podrás contratar el servicio.
                                </span></span>
                        </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group462"><img data-v-fae5bece="" alt="Ellipse2801185"
                                src="/playground_assets/ellipse2801185-mtsd-200h.png" class="frame1home-ellipse2801"><img
                                data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-7v4i.svg"
                                class="frame1home-vector003"></div>
                        <div data-v-fae5bece="" class="frame1home-frame59">
                            <div data-v-fae5bece="" class="frame1home-frame513"><span data-v-fae5bece=""
                                    class="frame1home-text022"><span data-v-fae5bece="">Cotiza</span></span>
                            </div><span data-v-fae5bece="" class="frame1home-text024"><span data-v-fae5bece="">
                                    Ingresa tus datos, el monto del crédito, cantidad de cuotas a pagar, periodos de
                                    gracia y la institución donde se contrata el crédito. </span></span>
                        </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group460"><img data-v-fae5bece="" alt="Ellipse2801185"
                                src="/playground_assets/ellipse2801185-61ag-200h.png" class="frame1home-ellipse280"><img
                                data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-02p8.svg"
                                class="frame1home-vector002"></div>
                        <div data-v-fae5bece="" class="frame1home-frame59abajo">
                            <div data-v-fae5bece="" class="frame1home-frame498"><span data-v-fae5bece=""
                                    class="frame1home-text026"><span data-v-fae5bece="">Informate</span></span><span
                                    data-v-fae5bece="" class="frame1home-text028"><span data-v-fae5bece="">
                                        Ingresa tus datos, el monto del crédito, cantidad de cuotas a pagar, periodos de
                                        gracia y la institución donde se contrata el crédito. </span></span>
                            </div>
    
                        </div> -->
          <!-- <div data-v-fae5bece="" class="frame1home-frame58">
                                    <div data-v-fae5bece="" class="frame1home-frame511"><span data-v-fae5bece=""
                                            class="frame1home-text014"><span data-v-fae5bece="">Contrátalo</span></span>
                                    </div><span data-v-fae5bece="" class="frame1home-text016"><span data-v-fae5bece=""> El
                                            comparador de prima te dará los resultados y podrás contratar el servicio.
                                        </span></span>
                                </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame499"><span data-v-fae5bece=""
                                class="frame1home-text103"><span data-v-fae5bece="">Información legal</span></span><span
                                data-v-fae5bece="" class="frame1home-text105"><span data-v-fae5bece=""><span
                                        data-v-fae5bece=""> Si quieres conocer más acerca de las leyes en las que se soporta
                                        nuestro seguro <span data-v-fae5bece=""> </span></span><br data-v-fae5bece=""><span
                                        data-v-fae5bece="">Desgravamen, haz clic en los links a
                                        continuación.</span></span></span></div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-cards1">
                            <div data-v-fae5bece="" class="frame1home-header">
                                <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout">
                                    <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout"><span
                                            data-v-fae5bece="" class="frame1home-text085"><span data-v-fae5bece="">Circular
                                                2114 CMF</span></span><span data-v-fae5bece=""
                                            class="frame1home-text087"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                                tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                    </div>
                                </div>
                            </div>
                            <div data-v-fae5bece="" class="frame1home-frame-image"><img data-v-fae5bece=""
                                    alt="IMAGEImage1185" src="/playground_assets/imageimage1185-glt-400w.png"
                                    class="frame1home-i-m-a-g-e-image"></div>
                            <div data-v-fae5bece="" class="frame1home-frame26"><span data-v-fae5bece=""
                                    class="frame1home-text089"><span data-v-fae5bece="">Descargar</span></span></div>
                        </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame1imagetop-b-outlineda-resting">
                            <div data-v-fae5bece="" class="frame1home-header1">
                                <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout1">
                                    <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout1"><span
                                            data-v-fae5bece="" class="frame1home-text091"><span data-v-fae5bece="">Circular
                                                114 CMF</span></span><span data-v-fae5bece=""
                                            class="frame1home-text093"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                                tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                    </div>
                                </div>
                            </div>
                            <div data-v-fae5bece="" class="frame1home-frame-image1"><img data-v-fae5bece=""
                                    alt="IMAGEImage1185" src="/playground_assets/tim-gouw-bwki71ap-y8-unsplash.png"
                                    class="frame1home-i-m-a-g-e-image1"></div>
                            <div data-v-fae5bece="" class="frame1home-frame261"><span data-v-fae5bece=""
                                    class="frame1home-text095"><span data-v-fae5bece="">Descargar</span></span></div>
                        </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame1imagetop-b-outlineda-resting1">
                            <div data-v-fae5bece="" class="frame1home-header2">
                                <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout2">
                                    <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout2"><span
                                            data-v-fae5bece="" class="frame1home-text097"><span data-v-fae5bece="">Ley
                                                24.302</span></span><span data-v-fae5bece=""
                                            class="frame1home-text099"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                                tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                    </div>
                                </div>
                            </div>
                            <div data-v-fae5bece="" class="frame1home-frame-image2"><img data-v-fae5bece=""
                                    alt="IMAGEImage1185" src="/playground_assets/scott-graham-5fNmWej4tAA-unsplash.png"
                                    class="frame1home-i-m-a-g-e-image2"></div>
                            <div data-v-fae5bece="" class="frame1home-frame262"><span data-v-fae5bece=""
                                    class="frame1home-text101"><span data-v-fae5bece="">Descargar</span></span></div>
                        </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm12>
          <h1 style="display: none !important">9</h1>
          <!-- <img data-v-fae5bece="" alt="unsplashhSeGxLjozs1185"  id="pfrecuente"
                            src="/playground_assets/unsplashhsegxljozs1185-t2sj.svg"
                            class="frame1home-unsplashh-se-gx-ljozs1" /> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
          <!-- <div class="frame1home-dropdownfinal">
                            <span class="frame1home-text156">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                    vivamus enim, ipsum iaculis ut aliquet tellus.
                                </span>
                            </span>
                            <img src="/playground_assets/vectori2771-hweo.svg" alt="VectorI2771"
                                class="frame1home-vector244" />
                        </div>
                        <div class="frame1home-dropdownfinal1">
                            <span class="frame1home-text158">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                    vivamus enim, ipsum iaculis ut aliquet tellus.
                                </span>
                            </span>
                            <img src="/playground_assets/vectori2771-buzu.svg" alt="VectorI2771"
                                class="frame1home-vector245" />
                        </div>
                        <div class="frame1home-dropdownfinal2">
                            <span class="frame1home-text160">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                    vivamus enim, ipsum iaculis ut aliquet tellus.
                                </span>
                            </span>
                            <img src="/playground_assets/vectori2771-nk7x.svg" alt="VectorI2771"
                                class="frame1home-vector246" />
                        </div>
                        <div class="frame1home-dropdownfinal3">
                            <span class="frame1home-text162">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                    vivamus enim, ipsum iaculis ut aliquet tellus.
                                </span>
                            </span>
                            <img src="/playground_assets/vectori2771-hhg8.svg" alt="VectorI2771"
                                class="frame1home-vector247" />
                        </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
          <!-- <span class="frame1home-text030 FontProductH4">
            <span>Preguntas frecuentes</span>
          </span>
          <span class="frame1home-text032">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vivamus
              enim, ipsum
            </span>
          </span> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-logo-lineadesgravamen-footer">
                            <div data-v-fae5bece="" class="frame1home-txt1">
                                <div data-v-fae5bece="" class="frame1home-group018"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-k5i.svg" class="frame1home-vector046"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-3azb.svg"
                                        class="frame1home-vector047"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-ortd.svg" class="frame1home-vector048"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-nxwk.svg"
                                        class="frame1home-vector049"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-m7i.svg" class="frame1home-vector050"></div>
                                <div data-v-fae5bece="" class="frame1home-group019"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-tnsg.svg" class="frame1home-vector051"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-ro2h.svg"
                                        class="frame1home-vector052"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-uo0u.svg" class="frame1home-vector053"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-idob.svg"
                                        class="frame1home-vector054"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-kac.svg" class="frame1home-vector055"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-nm4.svg"
                                        class="frame1home-vector056"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-6lbk.svg" class="frame1home-vector057"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-8b3.svg"
                                        class="frame1home-vector058"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-j9x7.svg" class="frame1home-vector059"><img
                                        data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-9lvz8.svg"
                                        class="frame1home-vector060"><img data-v-fae5bece="" alt="Vector1185"
                                        src="/playground_assets/vector1185-4af7.svg" class="frame1home-vector061"></div>
                                <div data-v-fae5bece="" class="frame1home-group020">
                                    <div data-v-fae5bece="" class="frame1home-group021"><img data-v-fae5bece=""
                                            alt="Vector1185" src="/playground_assets/vector1185-xtz.svg"
                                            class="frame1home-vector062"></div>
                                    <div data-v-fae5bece="" class="frame1home-group022"><img data-v-fae5bece=""
                                            alt="Vector1185" src="/playground_assets/vector1185-3u1d.svg"
                                            class="frame1home-vector063"></div>
                                </div>
                            </div>
                            <div data-v-fae5bece="" class="frame1home-img1"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-7hs.svg" class="frame1home-vector064"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-21uq.svg"
                                    class="frame1home-vector065"></div>
                        </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">13</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">14</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">15</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">16</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">17</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">18</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">19</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">20</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">21</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
      </v-layout>
    </v-container>
    <div>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>
<script>
import datausuario from "../../../services/usuario-datos";
import privado from "../../../services/privado";
import listadoRegion from "../../../services/region";

export default {
  name: "MiCuentaActiva",

  data() {
    return {
      valid: false,
      formCuenta: this.$store.state.formCuenta,
      regiones: [],
      comunasRegiones: this.$store.state.comunasRegiones,
      comunas: [],
      ciudades: [],
      seleccionRegion: "",
      seleccionComuna: "",
      seleccionCiudad: "",
      validnombre1: "no",
      validnombre2: "no",
      validapellido1: "no",
      validapellido2: "no",
      validmail: "no",
      validcelular: "no",
      validdireccion: "no",
      validdepto: "no",
      validnumero: "no",
      validregion: "no",
      validciudad: "no",
      validcomuna: "no",
      show1: false,
      show2: false,
      overlay: false,
      prefijoCliente: "9",
      cargaRegiones: [],
      cargaCiudades: [],
      cargaComunas: [],
      nombre1Rules: [
        (v) => !!v || "el campo Primer nombre es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarN1(v)) || "este campo solo acepta letras",
      ],
      nombre2Rules: [
        // (v) => !!v || "el campo Segundo nombre es requerido",
        // (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        // (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (this.validarN2(v)) || "este campo solo acepta letras",
      ],
      apellido1Rules: [
        (v) => !!v || "el campo Apellido Paterno es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarA1(v)) || "este campo solo acepta letras",
      ],
      apellido2Rules: [
        (v) => !!v || "el campo Apellido Materno es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarA2(v)) || "este campo solo acepta letras",
      ],
      mailRules: [
        v => !!v || 'el campo Mail es requerido',
        (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
        v => /.+@.+\..+/.test(v) || 'Ingrese un Mail válido',
        (v) => (this.validaMail(v)) || "largo mínimo de dominio 3 caracteres",
         
      ],
      celularRules: [
        (v) => (this.formatPhoneNumber(v)) || "formato inválido",
        (v) => !!v || "el campo Celular es requerido",
        (v) => (v && v.length == 16) || "número de celular máximo 9 caracteres",       
      ],
      direccionRules: [
        (v) => !!v || "el campo Dirección es requerido",
      ],
      deptoRules: [
        // (v) => !!v || "el campo Departamento es requerido",
      ],
      numeroRules: [
        (v) => !!v || "el campo Número es requerido",
      ],
      regionRules: [
        (v) => !!v || "el campo Región es requerido",
      ],
      ciudadRules: [
        (v) => !!v || "el campo Ciudad es requerido",
      ],
      comunaRules: [
        (v) => !!v || "el campo Comuna es requerido",
      ],
      validclave: "no",
      validclave2: "no",
      claveRules: [
        v => !!v || 'el campo Clave es requerido',
        (v) => (v && v.length >= 6) || "largo mínimo 6 caracteres",
        (v) => (v && v.length <= 10) || "largo máximo 10 caracteres",
        (v) => (v && v.match(/[a-zA-Z]/)) || "debe contener a lo menos una letra",
        (v) => (v && v.match(/[1-9]/)) || "debe contener a lo menos un número",
        // (v) => (v.includes(".") || v.includes("-") || v.includes("_") || v.includes("*") || v.includes(",") ) || "debe contener a lo menos un caracter especial",
        
      ],
      clave2Rules: [
        v => v && this.passIgual(v) || "La segunda clave ingresada no es idéntica a la primera, corrígela y vuelve a intentar.",        
      ],
      password: '',
      password2: '',
      contrato: {},
    };
  },

  methods: {
    passIgual(val){
       let resp=false;
       if(this.password==val){
        resp=true;
       }
       console.log(val);
       return resp;
    },
    validarN1(){
        this.formCuenta.nombre1=this.formCuenta.nombre1.replace(/[^a-zA-Z\s]/gi,"");
        this.formCuenta.nombre1=this.formCuenta.nombre1.replace("  "," ");
        return true;
    },
    validarN2(){
        if(this.formCuenta.nombre2!=""){
        this.formCuenta.nombre2=this.formCuenta.nombre2.replace(/[^a-zA-Z\s]/gi,"");
        this.formCuenta.nombre2=this.formCuenta.nombre2.replace("  "," ");
        }
        return true;
    },
    validarA1(){
        this.formCuenta.apellido1=this.formCuenta.apellido1.replace(/[^a-zA-Z\s]/gi,"");
        this.formCuenta.apellido1=this.formCuenta.apellido1.replace("  "," ");
        return true;
    },
    validarA2(){
        this.formCuenta.apellido2=this.formCuenta.apellido2.replace(/[^a-zA-Z\s]/gi,"");
        this.formCuenta.apellido2=this.formCuenta.apellido2.replace("  "," ");
        return true;
    },
    formatPhoneNumber(phone)  {
      if(phone && phone!=""){
      if(phone && phone.length>16){
        phone=phone.substring(0, phone.length - 1);
      } 
      let celular="(+56) "+this.prefijoCliente+" ";
      if(phone.length<=7){
        this.formCuenta.celular=celular;
        return;
      }
      phone=phone.replace("(+56) "+this.prefijoCliente+" ","");
      let letra="";
      for (let index = 0; index < phone.length; index++) {
        letra=phone.charAt(index).replace(/[^0-9]/gi,"");
          celular=celular + letra;
      }
      this.formCuenta.celular=celular;
      
    }
    return true;
    },
    
    validaMail(email){
      if(!email || email==""){
        return;
      }
      var revmail=email.split("@");
      if(revmail.length==2){
          if(revmail[1].length>=5){
            return true;
          }
          return false;
      }
  
    },
    async grabarCuenta() {
      this.overlay=true;
      let res = false;
      let res2 = false;
      var cel = this.formCuenta.celular;
      cel = cel.replace("(", "");
      cel = cel.replace(")", "");
      cel = cel.replace(" ", "");
      cel = cel.replace(" ", "");
      if(this.formCuenta.depto==""){
        this.formCuenta.depto="0";
      }
      
      var cuenta= {
            per_tipo_cliente: this.contrato.per_tipo_cliente,     
                perfilPersona : { 
                    per_rut: this.contrato.per_rut,
                    per_dv: this.contrato.per_dv,
                    per_nombre_principal: this.formCuenta.nombre1,
                    per_nombre_segundo: this.formCuenta.nombre2,
                    per_ape_paterno: this.formCuenta.apellido1,
                    per_ape_materno :this.formCuenta.apellido2,
                    per_celular: cel,
                    per_fono: cel,    
                    per_email: this.formCuenta.mail,
                    acc_clave: this.password
                },
                perfilDireccion : {
                    dom_tipo_domicilio: this.contrato.per_tipo_cliente,
                    dom_origen: this.contrato.ctt_numero,
                    dom_calle: this.formCuenta.direccion,
                    dom_nro: this.formCuenta.numero,
                    dom_depto: this.formCuenta.depto,
                    dom_codigo_comuna : this.formCuenta.cocod,
                    dom_codigo_ciudad: this.formCuenta.cicod,
                    dom_codigo_region: this.formCuenta.rcod
                }
            };
           
      res = await privado.editarPerfil(cuenta);
      if(res.status==200 && res.data.estadoTransaccion.success){
            this.$alertas(res.data.estadoTransaccion.errors,"success");
            var contrato={
                            ctt_numero:  this.contrato.ctt_numero
                        };
            res2 = await privado.activarContrato(contrato);
            if(res2.status==200 && res2.data.estadoTransaccion.success){
                this.$alertas(res2.data.estadoTransaccion.errors,"success");
                this.$router.push('/login');
                this.overlay=false;
            }else{
                this.$alertas(res2.data.estadoTransaccion.errors,"error");
                this.overlay=false;
                
            }
         }else{
            this.$alertas(res.data.estadoTransaccion.errors,"error");
            this.overlay=false;
         }
         this.overlay=false;
        
    },
    async traeCuenta(token) {
    //   this.overlay=true;
      let res = false;
      res = await privado.activaCuenta(token);
      if(res.status==200 && res.data.estadoTransaccion.success){
        var cel=res.data.tokenContrato.per_celular;
        if(res.data.tokenContrato.per_tipo_cliente==2){
            this.prefijoCliente="2";
        }else{
            this.prefijoCliente="9";
        }
    
        cel=cel.substring(4, 12);
    
        this.contrato=res.data.tokenContrato;
        this.formCuenta.nombre1=res.data.tokenContrato.per_nombre_principal;
        this.formCuenta.nombre2=res.data.tokenContrato.per_nombre_segundo;
        this.formCuenta.apellido1=res.data.tokenContrato.per_ape_paterno;
        this.formCuenta.apellido2=res.data.tokenContrato.per_ape_materno;
        this.formCuenta.mail=res.data.tokenContrato.per_email;
        this.formCuenta.celular=cel;
        this.formCuenta.direccion=res.data.tokenContrato.dom_calle;
        this.formCuenta.depto=res.data.tokenContrato.dom_depto;
        this.formCuenta.numero=res.data.tokenContrato.dom_nro;
        this.formCuenta.nacionalidad=res.data.tokenContrato.per_pais;
        this.formCuenta.genero=res.data.tokenContrato.per_genero;
        this.formCuenta.ocupacion=res.data.tokenContrato.per_ocupacion;
        this.seleccionRegion= res.data.tokenContrato.dom_glosa_region;
        this.seleccionComuna= res.data.tokenContrato.dom_glosa_comuna;
        this.seleccionCiudad= res.data.tokenContrato.dom_glosa_ciudad;
        this.selectregion(res.data.tokenContrato.dom_glosa_region);
        this.selectciudad(res.data.tokenContrato.dom_glosa_ciudad);
        this.selectcomuna(res.data.tokenContrato.dom_glosa_comuna);
        
        // "tokenContrato": {
        // "ctt_numero": 29,
        // "per_rut": 10210776,
        // "per_dv": "4",
        // "per_rut_formato": "10.210.776-4",
        // "per_nombre": "ALBERTO PEREZ HERRERA",
        // "per_nombre_principal": "xime",
        // "per_nombre_segundo": "ANDREA",
        // "per_ape_paterno": "LILO",
        // "per_ape_materno": "LULU",
        // "per_email": "PEPE@APOLO.CL",
        // "per_celular": "+56971814644",
        // "per_tipo_cliente": 1,
        // "per_rut_representante": 10210776,
        // "dom_calle": null,
        // "dom_nro": null,
        // "dom_depto": null,
        // "dom_comuna": 0,
        // "dom_ciudad": 0,
        // "dom_region": 0,
        // "dom_glosa_comuna": null,
        // "dom_glosa_ciudad": null,
        // "dom_glosa_region": null
        // }
        // this.overlay=false;
     } else{
    //   this.$store.commit('setclienteencontrado', false);
        // this.overlay=false;
        this.$router.push('/home');
    //   this.$alertas("En este momento no podemos atender su solicitud","warning");
     }
    
    },
    async traeRegiones() {
      this.overlay=true;
      let res = false;
      res = await listadoRegion.traeregiones();
      if(res.status==200 && res.data.estadoTransaccion.success){
        this.cargaRegiones=res.data.direccionCiudadComuna;
        res.data.direccionCiudadComuna.forEach(element => {
            this.regiones.push(element.reg_descripcion);
        });
        this.traeCuenta(this.$route.params.token);
        this.overlay=false;
     } else{
    //   this.$store.commit('setclienteencontrado', false);
        this.overlay=false;
      this.$alertas("En este momento no podemos atender su solicitud","warning");
     }
    
    },
    vclave(v) {
      this.validclave = v;
    },
    vclave2(v) {
      this.validclave2 = v;
    },
    vnombre1(v) {
      this.validnombre1 = v;
    },
    vnombre2(v) {
      this.validnombre2 = v;
    },
    vapellido1(v) {
      this.validapellido1 = v;
    },
    vapellido2(v) {
      this.validapellido2 = v;
    },
    vmail(v) {
      this.validmail = v;
    },
    vcelular(v) {
      this.validcelular = v;
    },
    vdireccion(v) {
      this.validdireccion = v;
    },
    vdepto(v) {
      this.validdepto = v;
    },
    vnumero(v) {
      this.validnumero = v;
    },
    vregion(v) {
      this.validcelular = v;
    },
    vciudad(v) {
      this.validciudad = v;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.grabarCuenta();
      }
    },
    selectregion(e) {
     this.overlay=true;
     this.validregion = true;
     this.formCuenta.region = e;
     this.cargaCiudades=[];
     this.cargaComunas=[];
     this.ciudades=[];
     this.comunas=[];
     this.validciudad= "no",
     this.validcomuna= "no",
     // console.log(this.$store.state.nombreIntituciones);
     this.cargaRegiones.forEach((element) => {
       if (element.reg_descripcion == e) {
            this.formCuenta.rcod=element.reg_codigo;
            this.cargaCiudades=element.ciudadUbicacion;
            element.ciudadUbicacion.forEach(ciudad => {
                this.ciudades.push(ciudad.ciu_descripcion);
            });
      }
     });
     this.overlay=false;
    //  this.comunas = comunas;
    //  this.ncod=ncod;
    //  this.$store.commit("setcomunas", comunas);
   },
   selectciudad(e) {
     this.overlay=true;
     this.validciudad = true;
     this.formCuenta.ciudad = e;
     this.cargaComunas=[];
     this.comunas=[];
     // console.log(this.$store.state.nombreIntituciones);
     this.cargaCiudades.forEach((element) => {
       if (element.ciu_descripcion == e) {
        this.cargaComunas=element.comunasUbicacion;
        
            this.formCuenta.cicod=element.ciu_numero;
            element.comunasUbicacion.forEach(comuna => {
                this.comunas.push(comuna.com_descripcion);
            });
      }
     });
     this.overlay=false;
    //  this.comunas = comunas;
    //  this.ncod=ncod;
    //  this.$store.commit("setcomunas", comunas);
   },
   selectcomuna(e) {
     
     this.overlay=true;
     this.formCuenta.comuna = e;     
     this.validcomuna = true;
     
     this.cargaComunas.forEach((element) => {
        if (element.com_descripcion == e) {
            this.formCuenta.cocod=element.com_numero;
        }
     });
     this.overlay=false;
    
   },
    vrut(v) {
      this.validrut = v;
    },
    volver() {
        this.$router.push('/home');
    },
  },
  computed: {
    
  },
  mounted() {
    console.log(this.$route.params);  
    
  },
  created(){    
     this.traeRegiones();
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>

<style scoped>
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

html,
body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.container {
  width: 100%;
  max-width: 1923px;
  min-width: 1440px;
}

.frame2loginnormal-container {
  min-height: 100vh;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.01);
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.frame2loginnormal-frame2loginnormall {
  width: 100%;
  height: 1025px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}

.frame2loginnormal-rectangulo-fondo-gradiente2 {
  top: -117px;
  /* left: 0px; */
  width: 1930px;
  height: 435px;
  position: absolute;
}

.frame2loginnormal-rectangulo-fondo-gradiente21 {
  top: -117px;
  /* left: 0px; */
  width: 1930px;
  height: 435px;
  position: absolute;
}

.frame2loginnormal-line16 {
  top: 998px;
  left: 0px;
  width: 1440px;
  height: 0px;
  position: absolute;
}

.frame2loginnormal-text {
  top: 899px;
  /* left: 1093px; */
  margin-left: 60px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;

  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-group508 {
  top: 868px;
  /* left: 162px; */
  width: 1116px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-usosdebotones {
  top: 836px;
  /* left: 875px; */
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}

.frame2loginnormal-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}

.frame2loginnormal-text02 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-usosdebotones2 {
  top: 836px;
  /* left: 0px; */
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
}

.frame2loginnormal-text04 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-line20 {
  top: 998px;
  left: 0px;
  width: 1440px;
  height: 0px;
  position: absolute;
}

.frame2loginnormal-rectangle39 {
  margin-top: 195px;
  /* left: 150px; */
  width: 100%;
  max-width: 1574px;
  background-color: white;
  height: auto;
  position: relative;
  border-color: rgba(143, 150, 165, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 20px 20px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.frame2loginnormal-text06 {
  top: 571px;
  /* left: 570px; */
  margin-left: 430px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}

.frame2loginnormal-googlelogo {
  top: 566px;
  left: 367px;
  width: 22px;
  height: 22px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame2loginnormal-rectangle40 {
  top: 197px;
  /* left: 150px; */
  width: 100%;
  max-width: 1574px;
  height: 122px;
  position: relative;
  border-color: rgba(143, 150, 165, 1);
  border-radius: 20px 20px 0 0;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
}

.frame2loginnormal-formtophome {
  top: 432px;
  /* left: 198px; */
  margin-left: 80px;
  width: 680px;
  height: 123px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-rectangle7 {
  top: 0px;
  left: 0px;
  width: 680px;
  height: 51px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.frame2loginnormal-rectangle8 {
  top: 73px;
  left: 0px;
  width: 680px;
  height: 50px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.frame2loginnormal-text08 {
  top: 16px;
  left: 29.9571533203125px;
  color: var(--dl-color-colores_neutrales-70);
  width: 232px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame2loginnormal-text10 {
  top: 91px;
  left: 29.9571533203125px;
  color: var(--dl-color-colores_neutrales-70);
  width: 165px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame2loginnormal-text12 {
  top: 248px;
  /* left: 198px; */
  margin-left: 80px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-text14 {
  top: 372px;
  /* left: 198px; */
  margin-left: 80px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-text15 {
  font-weight: 700;
}

.frame2loginnormal-text17 {
  top: 571px;
  /* left: 344px; */
  margin-left: 250px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}

.frame2loginnormal-text19 {
  top: 394px;
  /* left: 1007px; */
  margin-left: 55px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-text21 {
  top: 440px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-text23 {
  top: 485px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-text25 {
  top: 530px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame2loginnormal-ellipse291 {
  top: 434px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-ellipse292 {
  top: 477px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-ellipse293 {
  top: 520px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-group289010 {
  top: 630.5px;
  /* left: 415px; */
  width: 610px;
  height: 155px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-rectangle398 {
  top: 0px;
  left: 177px;
  width: 433px;
  height: 155px;
  position: absolute;
}

.frame2loginnormal-rectangle399 {
  top: 0px;
  left: 0px;
  width: 177px;
  height: 155px;
  position: absolute;
}

.frame2loginnormal-rectangle400 {
  top: 0px;
  left: 0px;
  width: 177px;
  height: 155px;
  position: absolute;
}

.frame2loginnormal-group288893 {
  top: 124px;
  left: 327px;
  width: 133px;
  height: 13px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-ellipse294 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-ellipse295 {
  top: 0px;
  left: 40px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-ellipse296 {
  top: 0px;
  left: 80px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-ellipse297 {
  top: 0px;
  left: 120px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame2loginnormal-text27 {
  top: 35px;
  left: 244px;
  color: var(--dl-color-colores_paleta-tonos_azul-0);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}

.frame2loginnormal-group495 {
  top: 68px;
  /* left: 148px; */
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-group498 {
  top: 0px;
  left: 0px;
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-group {
  top: 12.904296875px;
  left: 90.333984375px;
  width: 128.66651916503906px;
  height: 41.209571838378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-group1 {
  top: 0.00005661231989506632px;
  left: 37.96873474121094px;
  width: 47.22975158691406px;
  height: 17.132549285888672px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-vector {
  top: 2.778263807296753px;
  left: -0.00001415307997376658px;
  width: 9px;
  height: 14px;
  position: absolute;
}

.frame2loginnormal-vector01 {
  top: 0.00005661231989506632px;
  left: 9.724743843078613px;
  width: 5px;
  height: 17px;
  position: absolute;
}

.frame2loginnormal-vector02 {
  top: 5.555908203125px;
  left: 15.279296875px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector03 {
  top: 5.5559539794921875px;
  left: 26.393468856811523px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector04 {
  top: 5.555908203125px;
  left: 37.505859375px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-group2 {
  top: 22.2257137298584px;
  left: 0.00007784193439874798px;
  width: 123.17034912109375px;
  height: 18.98410415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-vector05 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 15px;
  position: absolute;
}

.frame2loginnormal-vector06 {
  top: 3.24068021774292px;
  left: 12.502009391784668px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector07 {
  top: 3.24072265625px;
  left: 23.6162109375px;
  width: 9px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector08 {
  top: 3.24068021774292px;
  left: 33.33804702758789px;
  width: 10px;
  height: 16px;
  position: absolute;
}

.frame2loginnormal-vector09 {
  top: 3.24072265625px;
  left: 44.9150390625px;
  width: 7px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector10 {
  top: 3.24068021774292px;
  left: 52.32418441772461px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector11 {
  top: 3.24068021774292px;
  left: 62.9736328125px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector12 {
  top: 3.24068021774292px;
  left: 73.62411499023438px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector13 {
  top: 3.24072265625px;
  left: 85.2001953125px;
  width: 15px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector14 {
  top: 3.24068021774292px;
  left: 101.86904907226562px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-vector15 {
  top: 3.24068021774292px;
  left: 112.98342895507812px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.frame2loginnormal-group3 {
  top: 21.24936294555664px;
  left: 123.76150512695312px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-group4 {
  top: 1.22881281375885px;
  left: 1.5965594053268433px;
  width: 2.0437254905700684px;
  height: 2.4524710178375244px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.frame2loginnormal-group5 {
  top: 0px;
  left: 0px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-vector17 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 5px;
  position: absolute;
}

.frame2loginnormal-group6 {
  top: 0px;
  left: 0px;
  width: 66.92005920410156px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame2loginnormal-vector18 {
  top: 1.1788524389266968px;
  left: 0px;
  width: 24px;
  height: 53px;
  position: absolute;
}

.frame2loginnormal-vector19 {
  top: 0px;
  left: 21.206945419311523px;
  width: 46px;
  height: 67px;
  position: absolute;
}

.frame75persona-cotizarsegurode-desgravamen-text {
  top: 14px;
  left: 14px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-usosdebotones {
  top: 830px;
  /* left: 148px; */
  width: 220px;
  bottom: 31px;
  height: 23px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  margin-left: 0px;
}

.frame75persona-cotizarsegurode-desgravamen-usosdebotones1 {
  top: 830px;
  /* left: 1072px; */
  width: 250px;
  height: 23px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-left: 0px;
}

.frame75persona-cotizarsegurode-desgravamen-text02 {
  position: relative;
  top: 14px;
  left: 4px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-text06 {
  /* top: 385px; */
  /* left: 148px; */
  margin-left: 80px;
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  position: relative;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-group289170 {
  width: 1440px;
  height: 1250px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-frame22ingresar-sitiotuscuentasdigitales {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 1250px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}

.group289170-formtophome {
  top: 552px;
  left: -714px;
  width: 0px;
  height: 0px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-frame2loginnormal {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 1250px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}

.group289170-rectangulo-fondo-gradiente2 {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 324px;
  position: absolute;
}

.group289170-rectangulo-fondo-gradiente21 {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 324px;
  position: absolute;
}

.group289170-rectangle39 {
  top: 301px;
  left: 80px;
  width: 1286px;
  height: 849px;
  position: absolute;
  border-color: rgba(143, 150, 165, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
}

.group289170-rectangle40 {
  top: 195px;
  left: 75px;
  width: 1295px;
  height: 128px;
  position: absolute;
}

.group289170-group508 {
  top: 1089px;
  left: 154px;
  width: 1116px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-usosdebotones {
  top: 0px;
  left: 896px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}

.group289170-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}

.group289170-text {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-usosdebotones2 {
  top: 0px;
  left: 0px;
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
}

.group289170-text02 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-line20 {
  top: 1219px;
  left: 0px;
  width: 1440px;
  height: 0px;
  position: absolute;
}

.group289170-text04 {
  top: 248px;
  left: 148px;
  color: var(--dl-color-colores_neutrales-white);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-group495 {
  top: 68px;
  left: 148px;
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group498 {
  top: 0px;
  left: 0px;
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group {
  top: 12.904296875px;
  left: 90.333984375px;
  width: 128.66651916503906px;
  height: 41.209571838378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group1 {
  top: 0.00005661231989506632px;
  left: 37.96873474121094px;
  width: 47.22975158691406px;
  height: 17.132549285888672px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector {
  top: 2.778263807296753px;
  left: -0.00001415307997376658px;
  width: 9px;
  height: 14px;
  position: absolute;
}

.group289170-vector01 {
  top: 0.00005661231989506632px;
  left: 9.724743843078613px;
  width: 5px;
  height: 17px;
  position: absolute;
}

.group289170-vector02 {
  top: 5.5556640625px;
  left: 15.279296875px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector03 {
  top: 5.5559539794921875px;
  left: 26.393468856811523px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector04 {
  top: 5.5556640625px;
  left: 37.505859375px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-group2 {
  top: 22.2257137298584px;
  left: 0.00007784193439874798px;
  width: 123.17034912109375px;
  height: 18.98410415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector05 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 15px;
  position: absolute;
}

.group289170-vector06 {
  top: 3.24068021774292px;
  left: 12.502009391784668px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector07 {
  top: 3.24072265625px;
  left: 23.6162109375px;
  width: 9px;
  height: 12px;
  position: absolute;
}

.group289170-vector08 {
  top: 3.24068021774292px;
  left: 33.33804702758789px;
  width: 10px;
  height: 16px;
  position: absolute;
}

.group289170-vector09 {
  top: 3.24072265625px;
  left: 44.9150390625px;
  width: 7px;
  height: 12px;
  position: absolute;
}

.group289170-vector10 {
  top: 3.24068021774292px;
  left: 52.32418441772461px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector11 {
  top: 3.24068021774292px;
  left: 62.9736328125px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector12 {
  top: 3.24068021774292px;
  left: 73.62411499023438px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector13 {
  top: 3.24072265625px;
  left: 85.2001953125px;
  width: 15px;
  height: 12px;
  position: absolute;
}

.group289170-vector14 {
  top: 3.24068021774292px;
  left: 101.86904907226562px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-vector15 {
  top: 3.24068021774292px;
  left: 112.98342895507812px;
  width: 10px;
  height: 12px;
  position: absolute;
}

.group289170-group3 {
  top: 21.24936294555664px;
  left: 123.76150512695312px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group4 {
  top: 1.22881281375885px;
  left: 1.5965594053268433px;
  width: 2.0437254905700684px;
  height: 2.4524710178375244px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.group289170-group5 {
  top: 0px;
  left: 0px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector17 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 5px;
  position: absolute;
}

.group289170-group6 {
  top: 0px;
  left: 0px;
  width: 66.92005920410156px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector18 {
  top: 1.1788524389266968px;
  left: 0px;
  width: 24px;
  height: 53px;
  position: absolute;
}

.group289170-vector19 {
  top: 0px;
  left: 21.206945419311523px;
  width: 46px;
  height: 67px;
  position: absolute;
}

.group289170-alertbanner {
  /* top: 476px; */
  /* left: 148px; */
  margin-left: 80px;
  width: 728px;
  height: 54px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(214, 231, 254, 1);
}

.group289170-group506 {
  top: 14.000082015991211px;
  left: 38px;
  width: 652px;
  height: 26px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-text08 {
  left: 38px;
  color: var(--dl-color-colores_neutrales-dark);
  font-weight: bold;
  width: 614px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  line-height: 94.9999988079071%;
}

.group289170-akariconscirclealert {
  top: 0.9999179840087891px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-group7 {
  top: 2px;
  left: 2.005309820175171px;
  width: 20.053096771240234px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-vector20 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.group289170-vector21 {
  top: 5px;
  left: 10.026548385620117px;
  width: 0px;
  height: 10px;
  position: absolute;
}

.group289170-radiobtnaceptar {
  top: 689px;
  left: 1249px;
  width: 19px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-vector22 {
  top: 440px;
  left: 1211px;
  width: 81px;
  height: 104px;
  position: absolute;
}

.group289170-text10 {
  margin-left: 80px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: relative;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-text12 {
  /* top: 428px; */
  /* left: 148px; */
  margin-left: 80px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: relative;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.group289170-group289158 {
  top: 625px;
  left: 416px;
  width: 609px;
  height: 660.9998779296875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-rectangle2438 {
  top: 64.51316833496094px;
  left: 1.9967212677001953px;
  width: 605px;
  height: 63px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}

.group289170-rectangle2439 {
  top: 227.2802276611328px;
  left: 1.9967212677001953px;
  width: 605px;
  height: 62px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}

.group289170-line55 {
  top: 65.65694427490234px;
  left: 0px;
  width: 609px;
  height: 0px;
  position: absolute;
}

.group289170-group289137 {
  top: 0px;
  left: 315.4819641113281px;
  width: 0px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group289136 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group289135 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-line58 {
  top: 380px;
  left: 0px;
  width: 380px;
  height: 0px;
  position: absolute;
}

.group289170-group289166 {
  top: 0px;
  left: 130.78524780273438px;
  width: 0px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group2891361 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group2891351 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-line581 {
  top: 380px;
  left: 0px;
  width: 380px;
  height: 0px;
  position: absolute;
}

.group289170-group289138 {
  top: 1.9873043298721313px;
  left: 609px;
  width: 0px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group2891362 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-group2891352 {
  top: 0px;
  left: 0px;
  width: 0.000040214479668065906px;
  height: 380px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-line582 {
  top: 380px;
  left: 0px;
  width: 380px;
  height: 0px;
  position: absolute;
}

.group289170-radiobtnaceptar1 {
  top: 250.2021026611328px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 22.181177139282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-radiobtnaceptar2 {
  top: 245.9999542236328px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 24.999996185302734px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: var(--dl-radius-radius-round);
}

.group289170-ellipse289 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 25px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}

.group289170-radiobtnaceptar3 {
  top: 321.99993896484375px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 24.999996185302734px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: var(--dl-radius-radius-round);
}

.group289170-ellipse2891 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 25px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}

.group289170-radiobtnaceptar4 {
  top: 404.58441162109375px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 22.181177139282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-radiobtnaceptar5 {
  top: 562.5155029296875px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 22.181177139282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-radiobtnaceptar6 {
  top: 638.8187255859375px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 22.181177139282227px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-text14 {
  color: var(--dl-color-colores_neutrales-dark);

  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;

  font-stretch: normal;
  text-decoration: none;
}

.group289170-text16 {
  top: 175px;
  left: 162.726318359375px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 118px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text18 {
  top: 250px;
  left: 162.726318359375px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 118px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text20 {
  top: 326px;
  left: 158.73291015625px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 118px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text22 {
  top: 90.99998474121094px;
  left: 341.4393310546875px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 150px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text24 {
  top: 173.99996948242188px;
  left: 338.44427490234375px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 240px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text26 {
  top: 248.9999542236328px;
  left: 338.44427490234375px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 240px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text28 {
  top: 321.99993896484375px;
  left: 338.44427490234375px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 240px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-rectangle2433 {
  top: 0px;
  left: 2.995081901550293px;
  width: 604px;
  height: 380px;
  position: absolute;
}

.group289170-group289163 {
  /* top: 24.81249237060547px; */
  left: 12.99992847442627px;
  width: 565px;
  height: 18.187503814697266px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.group289170-text30 {
  color: var(--dl-color-colores_neutrales-dark);
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text32 {
  color: var(--dl-color-colores_neutrales-dark);
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-text34 {
  top: 1.187504529953003px;
  left: 349px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 216px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}

.group289170-radiobtnaceptar7 {
  top: 84.99998474121094px;
  left: 53.911476135253906px;
  width: 23.900754928588867px;
  height: 24.999996185302734px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.group289170-ellipse2892 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 25px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}

.group289170-ellipse2893 {
  top: 170.49996948242188px;
  left: 53.911476135253906px;
  width: 24px;
  height: 25px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}




.frame-activacindecuenta-text26 {
  top: 13px;
  /* left: 50px; */
  color: var(--dl-color-colores_neutrales-90);
  width: 347px;
  height: auto;
  position: relative;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 117.99999475479126%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-text27 {
  font-weight: 700;
}
.frame-activacindecuenta-text35 {
  /* top: 60px; */
  /* left: 881px; */
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: relative;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-text37 {
  top: 0px;
  /* left: 927px; */
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: relative;
  display:flex;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-text39 {
  top: 0px;
  /* left: 927px; */
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: relative;
  display:flex;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-text41 {
  top: 0px;
  /* left: 927px; */
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: relative;
  display:flex;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-ellipse291 {
  top: -8px;
  width: 35px;
  height: 35px;
  position: relative;
  display:flex;
  border-color: transparent;
}
.frame-activacindecuenta-ellipse292 {
  top: 322px;
  left: 881px;
  width: 35px;
  height: 35px;
  position: relative;
  display:flex;
  border-color: transparent;
}
.frame-activacindecuenta-ellipse293 {
  top: 365px;
  left: 881px;
  width: 35px;
  height: 35px;
  position: relative;
  display:flex;
  border-color: transparent;
}
.frame-activacindecuenta-navigationarrowdropdown24px {
  top: 603px;
  left: 703px;
  width: 24px;
  height: 24px;
  position: relative;
  display:flex;
}
.frame-activacindecuenta-navigationarrowdropdown24px1 {
  top: 675px;
  left: 703px;
  width: 24px;
  height: 24px;
  position: relative;
  display:flex;
}
.campotexto{
    border-radius: 10px;
}
.frame644persona-formulario-lneadesgravamen-text08 {
  top: 70px;
  left: 300px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;

  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-activacindecuenta-text47 {
  top: 1094px;
  left: 234px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 282px;
  height: auto;
  /* position: absolute; */
  font-size: 12px;
  align-self: auto;
  font-style: Extra Bold;
  text-align: justified;
  font-family: Inter;
  font-weight: 700;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-activacindecuenta-text48 {
  font-weight: 700;
}
.frame-activacindecuenta-text49 {
  font-weight: 400;
  font-size: 15px;
}
.cuadroPass{
    width: 50%;
    padding: 20px;
    border: 1px solid darkgray;
    border-radius: 20px;
    background-color: var(--dl-color-colores_paleta-tonos_azul-5);
}
</style>
