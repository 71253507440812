<template>
<v-app>
  <div class="bannerlogo">
  <Nav></Nav>
  <div> 

</div>
<div >
  <v-row >
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
       
      </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <v-row class="colcenter">
          <v-col cols="12" sm="10" md="8" lg="5" xl="4" class="colcenter formstyle">
            <div class="formulario-cotiza-persona-1">
                <v-row class="colcenter">
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="hola-nombre-test" >PREGUNTAS FRECUENTES</div>
                   
                  </v-col>
                           
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="line-1"></div>
                  </v-col>          
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"  v-for="(pregunta, i) in pfrecuente" 
                        :key="i">
                    <v-expansion-panels class="panell" 
                      >
                      <v-expansion-panel class="panell2">
                        <v-expansion-panel-header  class="panell3">
                          {{pregunta.titulo}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="panell4">
                          {{ pregunta.detalle }}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>     
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" >
                    
                    
                                  
                                    
                    </v-col>   
                  
                   
                  
                </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>   
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>   
        </v-row >
        
    </v-col>
</v-row >
</div>
<Footer  ></Footer>
<v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</div>
</v-app>
</template>

<script>
import Nav from "../layout/Nav.vue";
import Footer from "../components/Footer.vue";
import parametros from "../../../services/parametros";
import plan from "../../../services/plan";
import homedata from "../../../services/home";


export default {
  name: "navMueve",
  components: {
    Nav : Nav,
    Footer : Footer,    
  },
  data() {
    return {
      overlay: false,     
      pfrecuente:[],     
    };
  },
  computed:{
   
  },

  methods: {
        
    async traepFrcuente() {
      this.overlay=true;
      let res = false;
      res = await homedata.traepfrecuente();
      console.log(res);

      if (res.status == 200) {
        this.pfrecuente = [];
        for (let index = 0; index < res.data.preguntasfrecuentes.length; index++) {
          this.pfrecuente.push({
            titulo: res.data.preguntasfrecuentes[index].pfr_pregunta,
            detalle: res.data.preguntasfrecuentes[index].pfr_respuesta,
          });
        }
        this.overlay=false;
      }else{
        this.overlay=false;
      }

    },
   
  },
  mounted() {
    this.traepFrcuente();
  },
  created() {
    this.$root.$refs.Preguntas = this;
  },

  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>

<style scoped>
.fullh{
  /* height:100%; */
}

.fixfooter{
  bottom: 0;
  position: fixed;
}
.bannerlogo {
  background-image: url('/assets/cotizacion/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: 100% ;
  margin-left: 0px !important;
  height :100%;
}

.colcenter{
  display: flex;
  justify-content: center;
}



.banner-principal2 {
  background: var(--blanco, #f0f2f2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* width: 100%; */
  /* height: 200px; */
  position: relative;
  /* left: 0px;
  top: 966px; */
  overflow: hidden;
  padding: 20px;
}
.frame-4 {
  padding: 0px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 232px;
  position: relative;
}
.frame-29 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.una-empresa-de {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 160px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logotipo-grupo-insurex-blanco-1 {
  flex-shrink: 0;
  width: 200px;
  height: 75.25px;
  position: relative;
  object-fit: cover;
}
.frame-7 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 720px;
  position: relative;
  overflow: hidden;
}
.frame-2 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 720px;
  position: relative;
}
.respaldados-por {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-13 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.dise-o-sin-t-tulo-27-1 {
  flex-shrink: 0;
  width: 168.48px;
  height: 91.5px;
  position: relative;
  object-fit: cover;
}
.dise-o-sin-t-tulo-29-1 {
  flex-shrink: 0;
  width: 324.72px;
  height: 68.26px;
  position: relative;
  object-fit: cover;
}
.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 236px;
}
.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}
.contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}


.formulario-cotiza-persona-1 {
  background: var(--blanco, #ffffff);
  border-radius: 40px;
  padding: 20px 80px 20px 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-bottom: 20px;
}
.hola-nombre-test {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 30px);
  line-height: var(--headings-h2-line-height, 50px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}
.te-damos-las-bienvenida-selecciona-si-eres-persona-natural-o-empresa {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
}
.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}
.frame-32 {
  background: #d9d9d9;
  border-radius: 80px;
  padding: 9px 11px 9px 11px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.persona {
  flex-shrink: 0;
  width: 171px;
  height: 40px;
  position: relative;
}
.rectangle-33 {
  background: var(--azul-complementario, #04137f);
  border-radius: 80px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
}
.persona2 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 8.19%;
  left: 5.85%;
  width: 85.96%;
  bottom: 15%;
  top: 17.5%;
  height: 67.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empresa {
  flex-shrink: 0;
  width: 171px;
  height: 40px;
  position: relative;
}
.rectangle-34 {
  border-radius: 80px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
}
.empresa2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 7.02%;
  left: 7.02%;
  width: 85.96%;
  bottom: 15%;
  top: 17.5%;
  height: 67.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formulario-cotiza {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  padding: 12px 20px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.frame-12 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.group-25 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}
.group-20 {
  width: 20px;
  height: 20px;
  position: static;
}
.vector {
  width: 20%;
  height: 100%;
  position: absolute;
  right: 60%;
  left: 20%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}
._1 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 87.73%;
  left: 6.82%;
  width: 5.45%;
  bottom: 13.64%;
  top: 13.64%;
  height: 72.73%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-26 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}
.group-23 {
  width: 20px;
  height: 20px;
  position: static;
}
.vector2 {
  width: 20%;
  height: 100%;
  position: absolute;
  right: 20%;
  left: 60%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}
._2 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 46.25%;
  left: 46.25%;
  width: 7.5%;
  bottom: 21.88%;
  top: 18.75%;
  height: 59.38%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-27 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: static;
}
.group-24 {
  width: 20px;
  height: 20px;
  position: static;
}
.vector3 {
  width: 20%;
  height: 100%;
  position: absolute;
  right: -20%;
  left: 100%;
  bottom: 0%;
  top: 0%;
  transform: translate(-20px, 0px);
  overflow: visible;
}
._3 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: absolute;
  right: 7.73%;
  left: 86.82%;
  width: 5.45%;
  bottom: 13.64%;
  top: 13.64%;
  height: 72.73%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formstyle{
  padding: 20px;
}

.personaBtn{
  
  background-color: #04137f;
}
.personaBtn-s{
  
  color: white;
}
.personaBtn2{
  
  background-color: transparent;
}
.personaBtn2-s{
  
  color: #04137f;
}
.empresaBtn{
  
  background-color: #04137f;
}
.empresaBtn-s{
  
  color: white;
}
.empresaBtn2{
  
  background-color: transparent;
}
.empresaBtn2-s{
  
  color: #04137f;
}
.panell{
  border-radius: 25px 25px 0px 0px  !important;
}
.panell2{
  border-radius: 40px  !important;
  /* margin-bottom: 25px  !important; */
  background-color: #F0F2F2  !important;
}
.panell3{
  min-height: 83px;
  border: 1px solid  !important;
  border-radius: 40px  !important;
  background-color: white  !important;
  color: #04137F;
  font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
  font-size: var(--headings-h7-font-size, 18px);
  line-height: var(--headings-h7-line-height, 26px);
  font-weight: var(--headings-h7-font-weight, 450);
}
.panell4{
  margin-top: 10px;
  color: #04137F;
  font-family: var(--headings-h7-font-family, "CircularPro", sans-serif);
  font-size: var(--headings-h7-font-size, 14px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 450);
}
@media only screen and (max-width: 800px) {
  .footer {
    position : relative;
  }
  .formulario-cotiza-persona-1{
    padding: 20px 10px 20px 10px !important;
  }
  .row{
    margin : 0px !important;
  }
  
}
@media only screen and (max-width: 1270px) {

  .bannerlogo { 
  background-size: 100% 100% !important; 
  }
  
}




</style>