<template>
    <div>
     <v-row class="colcenter mt-3">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <v-row class="colcenter">
          <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="colcenter">
            <div class="quieres-portar-tu-seguro-de-desgravamen">
              ¿Quieres portar tu seguro de desgravamen?
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="colcenter">
            <div
              class="si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos">
              Si tienes un seguro de desgravamen asociado a un crédito de consumo,
              automotriz o comercial (PYME) y quieres portarte y recuperar dinero,
              déjanos tus datos.
            </div>
          </v-col>
          <v-col cols="9" sm="9" md="8" lg="8" xl="8" class="colcenter">
            <v-btn rounded outlined large color="rgba(4, 19, 127, 1)"  class="mr-1 txtbnt6" @click="calcula"><span
                class="home-text txtbnt7"><span>Calcula tu devolución</span></span></v-btn>
          </v-col>
          <v-col cols="8" sm="8" md="8" lg="8" xl="8" class="colcenter">
            <div class="excluye-cr-ditos-hipotecarios">
              *Excluye créditos hipotecarios.
            </div>
          </v-col>


        </v-row>
      </v-col>
    </v-row>
    <v-row class="franja-ayuda">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="franja-ayuda-iz">
        <div class="imagen-iz">
          <div class="frame-46">
            <div class="necesitas-ayuda">¿Necesitas ayuda?</div>
            <div class="comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl">
              Comunícate con nosotros en
              <br>
              servicioalcliente@mueveseguro.cl
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
        <div class="colcenter">
          <div class="imagen-dr colcenter">
            <img class="rectangle-23801185-jj-79-400-h-1" src="/assets/somos/rectangle-23801185-jj-79-400-h-10.png" />
          </div>
        </div>
      </v-col>
    </v-row>
</div>
</template>
<script>
export default {
  name: "PrivadoInicio",

  data() {
    return {
      datosContrato: {},
    };
  },
  props: {
    montoSaldos: {},
  },
  methods: {
    calcula(){
          location.href = "https://landing.mueveseguro.cl/calculadora";
    },
  }
}
    
</script>
<style scoped>
.colcenter {
  display: flex;
  justify-content: center;
}
.quieres-portar-tu-seguro-de-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 38px);
  line-height: var(--headings-h2-line-height, 50px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.excluye-cr-ditos-hipotecarios {
  color: rgba(4, 19, 127, 1) !important;
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  /* margin-top: 30px; */
  /* width: 400px; */
}
.franja-ayuda-iz {
  align-content: center;
}

.franja-ayuda {
  background-color: #ffffff !important;
  padding: 20px;
}
.frame-46 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

}

.necesitas-ayuda {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 40px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;

}

.comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 28px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  width: 100%;
  /* height: 81px; */
}
.rectangle-23801185-jj-79-400-h-1 {
  border-radius: 0px 40px 40px 0px;
  max-width: 520px;
  /* height: 494px; */
  position: relative;
  /* object-fit: cover; */
}
@media only screen and (max-width: 1000px) {
    .comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
    font-size: var(--headings-h4-font-size, 20px) !important;
  }
  .rectangle-23801185-jj-79-400-h-1 {
    max-width: 100% !important;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;

  }
}

</style>