<template>
  <div>
    <v-app>
    <div class="sitiom" style="display: none;">
   
  <div class="frame5-persona-formulario-lneadesgravamen-container">
    <div
      class="frame5-persona-formulario-lneadesgravamen-frame5-persona-formulario-lneadesgravamen"
    >
      <div class="frame5-persona-formulario-lneadesgravamen-background"></div>
      <div class="frame5-persona-formulario-lneadesgravamen-logo-v1"></div>
      <div
        class="frame5-persona-formulario-lneadesgravamen-imput-persona-formulario-lneadesgravamen"
      >
        <img
          alt="BackgroundI2232"
          src="/playground_assets/movil/linea/inicio/backgroundi2232-cidl.svg"
          class="frame5-persona-formulario-lneadesgravamen-background1"
        />
        <span class="frame5-persona-formulario-lneadesgravamen-text">
          <span>Progreso de encuesta</span>
        </span>
        <img v-if="estadoFormLinea<4"
          alt="Rectangle39I2232"
          src="/playground_assets/movil/linea/inicio/rectangle39i2232-7kfa-1000h.png"
          class="frame5-persona-formulario-lneadesgravamen-rectangle39"
        />
        <img v-if="estadoFormLinea>3"
        alt="Rectangle392232"
        src="/playground_assets/movil/linea/preaprobacion/rectangle392232-7cg8-1200w.png"
        class="frame6-persona-formulario-lneadesgravamen-rectangle39"
      />
      <img v-show="estadoFormLinea==5"
        alt="Rectangle24382232"
        src="/playground_assets/movil/linea/preaprobacion/rectangle24382232-6piq-1500w.png"
        class="frame6-persona-formulario-lneadesgravamen-rectangle2438"
      />
      <span v-show="estadoFormLinea==5" class="frame6-persona-formulario-lneadesgravamen-text52">
        <span class="frame6-persona-formulario-lneadesgravamen-text53">
          <span>Breve encuesta!</span>
          <br />
          <span></span>
        </span>
        <span class="frame6-persona-formulario-lneadesgravamen-text57">
          Hola!
        </span>
        <span class="frame6-persona-formulario-lneadesgravamen-text58">
          <span>
            Rellena todos los campos de nuestra encusta para lograr tu Cotización
            de nuestro.
          </span>
          <br />
          <span></span>
        </span>
        <span>
          <span>Seguro de Desgravamen On line.</span>
          <br />
          <span></span>
        </span>
        </span>
        
        <div v-if="estadoFormLinea==1 || (estadoFormLinea==2 && tipoPersonaEmpresa==`persona`)" class="frame5-persona-formulario-lneadesgravamen-group289058-2" >
          <span class="frame5-persona-formulario-lneadesgravamen-text02">
            <span>
              Es importante que tengas tu clave única vigente para poder continuar con el proceso.
            </span>
          </span>
          <div
            class="frame5-persona-formulario-lneadesgravamen-akariconscirclealert"
          >
            <div class="frame5-persona-formulario-lneadesgravamen-group">
             
              <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
            </div>
          </div>
        </div>
        <div v-if="estadoFormLinea==2 && tipoPersonaEmpresa==`empresa`" class="frame5-persona-formulario-lneadesgravamen-group289058-3">
          <span class="frame5-persona-formulario-lneadesgravamen-text02">
            <span>
              Es importante que tengas tu clave única vigente para poder continuar con el proceso.
            </span>
          </span>
          <div
            class="frame5-persona-formulario-lneadesgravamen-akariconscirclealert"
          >
            <div class="frame5-persona-formulario-lneadesgravamen-group">
             
              <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
            </div>
          </div>
        </div>
        <span v-if="(estadoFormLinea<3 && tipoPersonaEmpresa==`persona`) || (estadoFormLinea<3 && tipoPersonaEmpresa==`inicio`)" class="frame5-persona-formulario-lneadesgravamen-text04">
          <span>
            Te damos las bienvenida, selecciona si eres persona natural o empresa.
          </span>
        </span>
        <span v-if="estadoFormLinea==1 && tipoPersonaEmpresa==`empresa`" class="frame5-persona-formulario-lneadesgravamen-text04">
          <span>
            Te damos las bienvenida, selecciona si eres persona natural o empresa.
          </span>
        </span>
        <span v-if="(estadoFormLinea<3 && tipoPersonaEmpresa==`persona`) || (estadoFormLinea<3 && tipoPersonaEmpresa==`inicio`)" class="frame5-persona-formulario-lneadesgravamen-text06">
          <span class="frame5-persona-formulario-lneadesgravamen-text07">
            Hola!,
           
          </span>
        </span>
        <span v-if="estadoFormLinea==1 && tipoPersonaEmpresa==`empresa`" class="frame5-persona-formulario-lneadesgravamen-text06">
          <span class="frame5-persona-formulario-lneadesgravamen-text07">
            Hola!,
           
          </span>
        </span>
        <Form1Mo :paises="paises" :genero="genero" :ocupaciones="ocupacion" v-show="estadoFormLinea == 1 && (tipoPersonaEmpresa==`persona` || tipoPersonaEmpresa==`inicio`)"></Form1Mo>
        <FormE1Mo  v-show="estadoFormLinea == 1 && tipoPersonaEmpresa==`empresa`"></FormE1Mo>
        <Form2Mo :ocupaciones="ocupacion" v-show="estadoFormLinea == 2 && tipoPersonaEmpresa==`persona`"></Form2Mo>      
        <FormE2Mo :paises="paises" :genero="genero" :ocupaciones="ocupacion"  v-show="estadoFormLinea == 2 && tipoPersonaEmpresa==`empresa`"></FormE2Mo>
        <PreaprobacionLineaMo
          v-show="estadoFormLinea == 3 || estadoFormLinea == 4"
        ></PreaprobacionLineaMo> 
        <div v-show="(estadoFormLinea == 5)" v-for="(bpsitem, i) in bpsList" :key="i">
          <DpsLineaMo v-bind:bps="bpsitem" v-bind:id="(i+1)" v-show="((i+1)==estadoBps)"></DpsLineaMo>
        </div>
        <TerminosMo v-show="(estadoFormLinea ==  6)"></TerminosMo>
        <FinMo :clienteExiste="clienteEncontrado" v-show="(estadoFormLinea ==  7)"></FinMo>
     
        <img v-if="(estadoFormLinea<3 && tipoPersonaEmpresa==`persona`) || (estadoFormLinea==1 && tipoPersonaEmpresa==`empresa`)"
          alt="RectanguloFondoGradiente2I2232"
          src="/playground_assets/movil/linea/inicio/rectangulofondogradiente2i2232-21q.svg"
          class="frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2"
        />
        <img v-if="estadoFormLinea==2 && tipoPersonaEmpresa==`empresa`"
          alt="RectanguloFondoGradiente2I2232"
          src="/playground_assets/movil/linea/inicio/rectangulofondogradiente2i2232-21q.svg"
          class="frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2-2"
        />
       
        <span v-show="estadoFormLinea == 1"
          class="frame5-persona-formulario-lneadesgravamen-text08 Roboto_H6_16"
        >
          <span>Preguntas - 1/2</span>
        </span>
        <span v-show="(estadoFormLinea ==2 && tipoPersonaEmpresa==`persona`)"
          class="frame5-persona-formulario-lneadesgravamen-text08 Roboto_H6_16"
        >
          <span>Preguntas - 2/2</span>
        </span>
        <span v-show="(estadoFormLinea ==2 && tipoPersonaEmpresa==`empresa`)"
          class="frame5-persona-formulario-lneadesgravamen-text08-2 Roboto_H6_16"
        >
          <span>Preguntas - 2/2</span>
        </span>
        <img v-show="(estadoFormLinea < 3 && tipoPersonaEmpresa==`persona`) || (estadoFormLinea== 1 && tipoPersonaEmpresa==`empresa`) "
          alt="RectanguloFondoGradiente2I2232"
          src="/playground_assets/movil/linea/inicio/rectangulofondogradiente2i2232-rwlkj.svg"
          class="frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21"
        />
        <img v-show="estadoFormLinea == 2 && tipoPersonaEmpresa==`empresa`"
          alt="RectanguloFondoGradiente2I2232"
          src="/playground_assets/movil/linea/2.svg"
          class="frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21-2"
        />
      
        <div class="frame5-persona-formulario-lneadesgravamen-logotipos">
          <img
                  src="/playground_assets/logos/logo-blanco.png"
                  class="g-logo-blanco"
                />
        </div>
        <div v-show="estadoFormLinea==3" class="frame-persona-formulario-lneadesgravamen-group289066">
          <img
            src="/playground_assets/movil/linea/line50strokei2232-q49d.svg"
            alt="Line50StrokeI2232"
            class="frame-persona-formulario-lneadesgravamen-line5-stroke"
          />
          <img
            src="/playground_assets/movil/linea/line50strokei2232-e6ql.svg"
            alt="Line50StrokeI2232"
            class="frame-persona-formulario-lneadesgravamen-line5-stroke1"
          />
          <div class="frame-persona-formulario-lneadesgravamen-icon-btns">
            <img
              src="/playground_assets/movil/linea/ellipse4i2232-kuol-200h.png"
              alt="Ellipse4I2232"
              class="frame-persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              src="/playground_assets/movil/linea/iconoutlinechecki2232-w1g9.svg"
              alt="IconOutlinecheckI2232"
              class="frame-persona-formulario-lneadesgravamen-icon-outlinecheck"
            />
          </div>
          <div class="frame-persona-formulario-lneadesgravamen-icon-btns1">
            <img
              src="/playground_assets/movil/linea/ellipse4i2232-jz4-200h.png"
              alt="Ellipse4I2232"
              class="frame-persona-formulario-lneadesgravamen-ellipse41"
            />
            <img
              src="/playground_assets/movil/linea/ellipse5i2232-dwe5-200h.png"
              alt="Ellipse5I2232"
              class="frame-persona-formulario-lneadesgravamen-ellipse5"
            />
          </div>
          <div class="frame-persona-formulario-lneadesgravamen-icon-btns2">
            <img
              src="/playground_assets/movil/linea/ellipse4i2232-pyrq-200h.png"
              alt="Ellipse4I2232"
              class="frame-persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              src="/playground_assets/movil/linea/ellipse5i2232-j4o7-200h.png"
              alt="Ellipse5I2232"
              class="frame-persona-formulario-lneadesgravamen-ellipse51"
            />
          </div>
        </div>
        <div v-show="estadoFormLinea<3" class="frame5-persona-formulario-lneadesgravamen-group289066">
          <img
            alt="Line50StrokeI2232"
            src="/playground_assets/movil/linea/inicio/line50strokei2232-a5g.svg"
            class="frame5-persona-formulario-lneadesgravamen-line5-stroke"
          />
          <img
            alt="Line50StrokeI2232"
            src="/playground_assets/movil/linea/inicio/line50strokei2232-j56.svg"
            class="frame5-persona-formulario-lneadesgravamen-line5-stroke1"
          />
          <div class="frame5-persona-formulario-lneadesgravamen-icon-btns">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/inicio/ellipse4i2232-xhr8-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              alt="Ellipse5I2232"
              src="/playground_assets/movil/linea/inicio/ellipse5i2232-f2f8-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse5"
            />
          </div>
          <div class="frame5-persona-formulario-lneadesgravamen-icon-btns1">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/inicio/ellipse4i2232-zkmm-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse41"
            />
            <img
              alt="Ellipse5I2232"
              src="/playground_assets/movil/linea/inicio/ellipse5i2232-86dh-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse51"
            />
          </div>
          <div class="frame5-persona-formulario-lneadesgravamen-icon-btns2">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/inicio/ellipse4i2232-b84t-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              alt="Ellipse5I2232"
              src="/playground_assets/movil/linea/inicio/ellipse5i2232-j6hg-200h.png"
              class="frame5-persona-formulario-lneadesgravamen-ellipse52"
            />
          </div>
        </div> 

        <div v-show="estadoFormLinea>4 && estadoFormLinea<7" class="frame6-persona-formulario-lneadesgravamen-group289066">
          <img
            alt="Line50StrokeI2232"
            src="/playground_assets/movil/linea/preaprobacion/line50strokei2232-7io.svg"
            class="frame6-persona-formulario-lneadesgravamen-line5-stroke"
          />
          <img
            alt="Line50StrokeI2232"
            src="/playground_assets/movil/linea/preaprobacion/line50strokei2232-1fv.svg"
            class="frame6-persona-formulario-lneadesgravamen-line5-stroke1"
          />
          <div class="frame6-persona-formulario-lneadesgravamen-icon-btns">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/preaprobacion/ellipse4i2232-qyll-200h.png"
              class="frame6-persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              alt="IconOutlinecheckI2232"
              src="/playground_assets/movil/linea/preaprobacion/iconoutlinechecki2232-j9l.svg"
              class="frame6-persona-formulario-lneadesgravamen-icon-outlinecheck"
            />
          </div>
          <div class="frame6-persona-formulario-lneadesgravamen-icon-btns1">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/preaprobacion/ellipse4i2232-2y2q-200h.png"
              class="frame6-persona-formulario-lneadesgravamen-ellipse41"
            />
            <img
              alt="IconOutlinecheckI2232"
              src="/playground_assets/movil/linea/preaprobacion/iconoutlinechecki2232-czfm.svg"
              class="frame6-persona-formulario-lneadesgravamen-icon-outlinecheck1"
            />
          </div>
          <div class="frame6-persona-formulario-lneadesgravamen-icon-btns2">
            <img
              alt="Ellipse4I2232"
              src="/playground_assets/movil/linea/preaprobacion/ellipse4i2232-80p4-200h.png"
              class="frame6-persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              alt="Ellipse5I2232"
              src="/playground_assets/movil/linea/preaprobacion/ellipse5i2232-k74m-200h.png"
              class="frame6-persona-formulario-lneadesgravamen-ellipse5"
            />
          </div>
        </div>

        <div v-show="estadoFormLinea==7" class="frame7-persona-formulario-lneadesgravamen-group289066">
          <img
            src="/playground_assets/movil/linea/fin/line50strokei2257-hvb4.svg"
            alt="Line50StrokeI2257"
            class="frame7-persona-formulario-lneadesgravamen-line5-stroke"
          />
          <img
            src="/playground_assets/movil/linea/fin/line50strokei2257-xjjt.svg"
            alt="Line50StrokeI2257"
            class="frame7-persona-formulario-lneadesgravamen-line5-stroke1"
          />
          <div class="frame7-persona-formulario-lneadesgravamen-icon-btns">
            <img
              src="/playground_assets/movil/linea/fin/ellipse4i2257-u52b-200h.png"
              alt="Ellipse4I2257"
              class="frame7-persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              src="/playground_assets/movil/linea/fin/iconoutlinechecki2257-wv1.svg"
              alt="IconOutlinecheckI2257"
              class="frame7-persona-formulario-lneadesgravamen-icon-outlinecheck"
            />
          </div>
          <div class="frame7-persona-formulario-lneadesgravamen-icon-btns1">
            <img
              src="/playground_assets/movil/linea/fin/ellipse4i2257-gh6j-200h.png"
              alt="Ellipse4I2257"
              class="frame7-persona-formulario-lneadesgravamen-ellipse41"
            />
            <img
              src="/playground_assets/movil/linea/fin/iconoutlinechecki2257-w0t.svg"
              alt="IconOutlinecheckI2257"
              class="frame7-persona-formulario-lneadesgravamen-icon-outlinecheck1"
            />
          </div>
          <div class="frame7-persona-formulario-lneadesgravamen-icon-btns2">
            <img
              src="/playground_assets/movil/linea/fin/ellipse4i2257-ync-200h.png"
              alt="Ellipse4I2257"
              class="frame7-persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              src="/playground_assets/movil/linea/fin/iconoutlinechecki2257-za9f.svg"
              alt="IconOutlinecheckI2257"
              class="frame7-persona-formulario-lneadesgravamen-icon-outlinecheck2"
            />
          </div>
        </div>
        <div
        v-show="estadoFormLinea!=3"
        :class="{
          'frame21persona-formulario-lneadesgravamen-group288914-2':estadoFormLinea <'6' && estadoFormLinea!='3' ,
          'frame21persona-formulario-lneadesgravamen-group288914-3':estadoFormLinea >'4',
          'frame21persona-formulario-lneadesgravamen-group288914-4':estadoFormLinea =='2' && tipoPersonaEmpresa=='empresa',
          }"        
      
      >
         
            <v-btn
                class="mx-2 empresa"
                outlined
                x-large
                @click="seleccionTipoUsuario(`empresa`)"
                v-show="estadoFormLinea <6 && estadoFormLinea!=3"
                :class="{'btnTipo':tipoPersonaEmpresa=='empresa','btnTxt':tipoPersonaEmpresa=='empresa'}"
                style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(113, 48, 237);
                  width: 47px;
                  border-radius: 100px;
                  min-width: 260px;
                  height: 40px;
                  position: absolute;
                  text-transform: unset !important;
                  margin-top:10px;
                "
              >
                <span
                  style="width: 125px !important"
                  class="frame71persona-cotizarsegurode-desgravamen-text07 Roboto_H5_1824 tempresa"
                >
                  Empresa
                </span>

              </v-btn>
              <v-btn
                class="mx-2 persona"
                x-large
                outlined
                @click="seleccionTipoUsuario(`persona`)"
                v-show="estadoFormLinea <6 && estadoFormLinea!=3"
                :class="{'btnTipo':tipoPersonaEmpresa=='persona','btnTxt':tipoPersonaEmpresa=='persona'}"
                style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(113, 48, 237);
                  width: 47px;
                  border-radius: 100px;
                  min-width: 260px;
                  height: 40px;
                  position: absolute;
                  text-transform: unset !important;
                  margin-top:60px;
                "
              >
                <span
                  style="width: 125px !important"
                  class="frame71persona-cotizarsegurode-desgravamen-text06 Roboto_H5_1824 tpersona"
                >
                  Persona
                </span>
              
              </v-btn>
          
      </div>
      <div :class="{
        'frame71persona-cotizarsegurode-desgravamen-group288914-2':estadoFormLinea<'2',
        'frame71persona-cotizarsegurode-desgravamen-group288914-3':estadoFormLinea >'3'
        }"
      v-show="estadoFormLinea <6 && estadoFormLinea!=3">
      <div         
          class="frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar"
          style="top: 13px;left: 64px;"
          
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse288i1251-wvod-200h.png"
            alt="Ellipse288I1251 "
            class="frame71persona-cotizarsegurode-desgravamen-ellipse288 pempresa"
            style="top: 8px;left: -158px;"
            :class="{'puntoColor':tipoPersonaEmpresa=='empresa'}"
          />
      </div>
      <div
          class="frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar1"
          
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse288i1251-6dgr-200h.png"
            alt="Ellipse288I1251 "
            class="frame71persona-cotizarsegurode-desgravamen-ellipse2881 ppersona"
            style="top: 59px;left: -158px;"
            :class="{'puntoColor':tipoPersonaEmpresa=='persona'}"
          />
      </div>
      </div>
       
      </div>
    </div>
  </div>

</div> 
<div class="sitiod">
  
    <v-container full-width >
      <v-layout row wrap>
        <v-flex color="primary" sm1 >
          <h1 style="display:none !important ">1</h1>
        
          <img v-show="!isLogin"
            src="/playground_assets/rectangulofondogradiente29484-od1m.svg"
            alt="RectanguloFondoGradiente29484"
            class="frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2"
          />
          <img v-show="isLogin"
        src="/playground_assets/Seguros/privado/rectangulofondogradiente21384-07br.svg"
        alt="RectanguloFondoGradiente21251"
        class="frame71persona-cotizarsegurode-desgravamen-rectangulo-fondo-gradiente2p"
      />
         
        </v-flex>
        <v-flex  sm1>
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">3</h1>
          <div class="frame21persona-formulario-lneadesgravamen-group495">
        <Logo></Logo>
      </div>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">4</h1>
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">5</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">7</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">9</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">11</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">12</h1>
        </v-flex>
        <v-flex color="primary" sm12>
          <h1 style="display:none !important ;">2</h1>
          <img v-if="estadoFormLinea!=6"
            src="/playground_assets/rectangle399484-zd8-700h.png"
            alt="Rectangle399484"
            class="frame21persona-formulario-lneadesgravamen-rectangle39"
          />
        </v-flex>
      

        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">3</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">3</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">4</h1>
          <span
        class="frame71persona-cotizarsegurode-desgravamen-text02"
        v-show="estadoFormLinea < 3 && tipoPersonaEmpresa!='empresa'"
      >
        <span class="frame71persona-cotizarsegurode-desgravamen-text03"> Hola!, </span>
        <span class="frame71persona-cotizarsegurode-desgravamen-text05">
          Te damos las bienvenida, selecciona si eres persona natural o empresa
        </span>
      </span>
      <span
        class="frame71persona-cotizarsegurode-desgravamen-text02"
        v-show="estadoFormLinea < 3 && estadoFormLinea!=2 && tipoPersonaEmpresa=='empresa'"
      >
        <span class="frame71persona-cotizarsegurode-desgravamen-text03"> Hola!, </span>
        <span class="frame71persona-cotizarsegurode-desgravamen-text05">
          Te damos las bienvenida, selecciona si eres persona natural o empresa
        </span>
      </span>
      <div
        class="frame21persona-formulario-lneadesgravamen-alertbanner"
        v-show="(estadoFormLinea <3 && tipoPersonaEmpresa=='persona') || (estadoFormLinea <3 && tipoPersonaEmpresa=='inicio') || (estadoFormLinea!=2 && estadoFormLinea <3 && tipoPersonaEmpresa=='empresa')"
      >
        <div class="frame21persona-formulario-lneadesgravamen-group506">
          <div class="frame21persona-formulario-lneadesgravamen-akariconscirclealert">
            <div class="frame21persona-formulario-lneadesgravamen-group7">
              <img
                src="/playground_assets/vector1074-s8im.svg"
                alt="Vector1074"
                class="frame21persona-formulario-lneadesgravamen-vector20"
              />
              <img
                src="/playground_assets/vector1074-4pdo.svg"
                alt="Vector1074"
                class="frame21persona-formulario-lneadesgravamen-vector21"
              />
            </div>
          </div>
          <span class="frame21persona-formulario-lneadesgravamen-text10 Roboto_H6_16">
            ¡            Es importante que tengas tu cédula vigente para poder continuar con el proceso.
          </span>
        </div>
      </div>

      <div
        class="frame21persona-formulario-lneadesgravamen-alertbanner-2"
        v-show="estadoFormLinea==2 && tipoPersonaEmpresa=='empresa'"
      >
        <div class="frame21persona-formulario-lneadesgravamen-group506">
          <div class="frame21persona-formulario-lneadesgravamen-akariconscirclealert">
            <div class="frame21persona-formulario-lneadesgravamen-group7">
              <img
                src="/playground_assets/vector1074-s8im.svg"
                alt="Vector1074"
                class="frame21persona-formulario-lneadesgravamen-vector20"
              />
              <img
                src="/playground_assets/vector1074-4pdo.svg"
                alt="Vector1074"
                class="frame21persona-formulario-lneadesgravamen-vector21"
              />
            </div>
          </div>
          <span class="frame21persona-formulario-lneadesgravamen-text10 Roboto_H6_16">
            ¡            Es importante que tengas tu cédula vigente para poder continuar con el proceso.
          </span>
        </div>
      </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">5</h1>
          <div
        class="frame21persona-formulario-lneadesgravamen-group288914"
        v-show="estadoFormLinea <6 && estadoFormLinea!=3"
      >
         
            <v-btn
                class="mx-2 empresa"
                outlined
                x-large
                @click="seleccionTipoUsuario(`empresa`)"
                v-show="estadoFormLinea <6 && estadoFormLinea!=3"
                :class="{'btnTipo':tipoPersonaEmpresa=='empresa','btnTxt':tipoPersonaEmpresa=='empresa'}"
                style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(113, 48, 237);
                  width: 47px;
                  border-radius: 100px;
                  min-width: 260px;
                  height: 40px;
                  position: absolute;
                  text-transform: unset !important;
                  left: 230px;
                "
              >
                <span
                  style="width: 125px !important"
                  class="frame71persona-cotizarsegurode-desgravamen-text07 Roboto_H5_1824 tempresa"
                >
                  Empresa
                </span>

                <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
              </v-btn>
              <v-btn
                class="mx-2 persona"
                x-large
                outlined
                @click="seleccionTipoUsuario(`persona`)"
                v-show="estadoFormLinea <6 && estadoFormLinea!=3"
                :class="{'btnTipo':tipoPersonaEmpresa=='persona','btnTxt':tipoPersonaEmpresa=='persona'}"
                style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(113, 48, 237);
                  width: 47px;
                  border-radius: 100px;
                  min-width: 260px;
                  height: 40px;
                  position: absolute;
                  text-transform: unset !important;
                  left: 0px;
                "
              >
                <span
                  style="width: 125px !important"
                  class="frame71persona-cotizarsegurode-desgravamen-text06 Roboto_H5_1824 tpersona"
                >
                  Persona
                </span>
              
              </v-btn>
          
      </div>
      <div class="frame71persona-cotizarsegurode-desgravamen-group288914">
      <div
          class="frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar"
          v-show="estadoFormLinea <6 && estadoFormLinea!=3"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse288i1251-wvod-200h.png"
            alt="Ellipse288I1251 "
            class="frame71persona-cotizarsegurode-desgravamen-ellipse288 pempresa"
            :class="{'puntoColor':tipoPersonaEmpresa=='empresa'}"
          />
      </div>
      <div
          class="frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar1"
          v-show="estadoFormLinea <6 && estadoFormLinea!=3"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse288i1251-6dgr-200h.png"
            alt="Ellipse288I1251 "
            class="frame71persona-cotizarsegurode-desgravamen-ellipse2881 ppersona"
            :class="{'puntoColor':tipoPersonaEmpresa=='persona'}"
          />
      </div>
      </div>
     
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">6</h1>
       
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">7</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">9</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">10</h1>
        
      <div class="frame21persona-formulario-lneadesgravamen-group288877" :class="{'frame21persona-formulario-lneadesgravamen-group288877login':isLogin}">
        <span class="frame21persona-formulario-lneadesgravamen-text">
        <span>Progreso de encuesta</span>
      </span>
        <img
          src="/playground_assets/rectangle39484-dxkm-200h.png"
          alt="Rectangle39484"
          class="frame21persona-formulario-lneadesgravamen-rectangle3"
        />
        <img
          src="/playground_assets/rectangle49484-hzah-200h.png"
          alt="Rectangle49484"
          class="frame21persona-formulario-lneadesgravamen-rectangle4"
        />
        <div class="frame21persona-formulario-lneadesgravamen-group521">
          <div
            class="frame21persona-formulario-lneadesgravamen-icon-btns"
            v-show="estadoFormLinea < 4"
          >
            <img
              src="/playground_assets/ellipse4i948-pkoc-200h.png"
              alt="Ellipse4I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              src="/playground_assets/ellipse5i948-31bh-200h.png"
              alt="Ellipse5I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse5"
            />
          </div>
          <div
            class="frame21persona-formulario-lneadesgravamen-icon-btns"
            v-show="estadoFormLinea >= 4"
          >
            <img
              src="/playground_assets/seguros/cotizacion/ellipse4i1251-kcbj-200h.png"
              alt="Ellipse4I1251"
              class="frame75persona-cotizarsegurode-desgravamen-ellipse4"
            />
            <img
              src="/playground_assets/seguros/cotizacion/iconoutlinechecki1251-a0pyd.svg"
              alt="IconOutlinecheckI1251"
              class="frame75persona-cotizarsegurode-desgravamen-icon-outlinecheck"
            />
          </div>
          <div
            class="frame21persona-formulario-lneadesgravamen-icon-btns1"
            v-show="estadoFormLinea < 4"
          >
            <!-- <img
              src="/playground_assets/ellipse4i948-pkoc-200h.png"
              alt="Ellipse4I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse4"
            />
            <img
              src="/playground_assets/ellipse5i948-31bh-200h.png"
              alt="Ellipse5I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse5"
            /> -->
          </div>
          <div
            class="frame21persona-formulario-lneadesgravamen-icon-btns1"
            v-show="estadoFormLinea >= 4"
          >
          <img
              src="/playground_assets/seguros/cotizacion/ellipse4i1251-kcbj-200h.png"
              alt="Ellipse4I1251"
              class="frame75persona-cotizarsegurode-desgravamen-ellipse4"
            />
            <img
              src="/playground_assets/seguros/cotizacion/iconoutlinechecki1251-a0pyd.svg"
              alt="IconOutlinecheckI1251"
              class="frame75persona-cotizarsegurode-desgravamen-icon-outlinecheck"
            />
          </div>
          <div
            class="frame21persona-formulario-lneadesgravamen-icon-btns1"
            v-show="estadoFormLinea < 4"
          >
            <img
              src="/playground_assets/ellipse4i948-tm5-200h.png"
              alt="Ellipse4I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse41"
            />
            <img
              src="/playground_assets/ellipse5i948-tvqg-200h.png"
              alt="Ellipse5I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse51"
            />
          </div>
          <div class="frame21persona-formulario-lneadesgravamen-icon-btns2">
            <img
              src="/playground_assets/ellipse4i948-xtxs-200h.png"
              alt="Ellipse4I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              src="/playground_assets/ellipse5i948-ozmb-200h.png"
              alt="Ellipse5I948"
              class="frame21persona-formulario-lneadesgravamen-ellipse52"
            />
          </div>
          <div class="frame67persona-formulario-lneadesgravamen-icon-btns2" v-show="estadoFormLinea == 7">
            <img
              src="/playground_assets/seguros/linea/ellipse4i1251-7az-200h.png"
              alt="Ellipse4I1251"
              class="frame67persona-formulario-lneadesgravamen-ellipse42"
            />
            <img
              src="/playground_assets/seguros/linea/iconoutlinechecki1251-t42.svg"
              alt="IconOutlinecheckI1251"
              class="frame67persona-formulario-lneadesgravamen-icon-outlinecheck2"
            />
          </div>
        </div>
      </div>
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">11</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">12</h1>
       
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">4</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">3</h1>
         
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">5</h1>          
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">7</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">9</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">11</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">12</h1>
        </v-flex>
      

        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">5</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">3</h1>
          <img
            v-show="estadoFormLinea < 3"
            src="/playground_assets/rectangulofondogradiente29484-nlzh.svg"
            alt="RectanguloFondoGradiente29484"
            class="frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21"
          />
          <img
        v-show="estadoFormLinea == 1"
        src="/playground_assets/rectangulofondogradiente29484-01r.svg"
        alt="RectanguloFondoGradiente29484"
        class="frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente22"
      />
      <img
        v-show="estadoFormLinea == 2"
        src="/playground_assets/rectangulofondogradiente21251-2aaa.svg"
        alt="RectanguloFondoGradiente21251"
        class="frame64persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente22"
      />
         
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">5</h1>
          
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">6</h1>
         
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">7</h1>
          <span class="frame21persona-formulario-lneadesgravamen-text02 Roboto_H6_16">
        <span v-show="estadoFormLinea == 1">Preguntas - 1/2</span>
        <span v-show="estadoFormLinea == 2">Preguntas - 2/2</span>
        
      </span>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">9</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display:none !important ;">11</h1>
        </v-flex>
        <v-flex  sm1>
          <h1 style="display:none !important ;">12</h1>
        </v-flex>

     

<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">6</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">2</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">3</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">4</h1>
  
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
      <Form1 :paises="paises" :genero="genero" :ocupaciones="ocupacion" v-show="estadoFormLinea == 1 && (tipoPersonaEmpresa==`persona` || tipoPersonaEmpresa==`inicio`)"></Form1>
      <Form2 :ocupaciones="ocupacion" v-show="estadoFormLinea == 2 && tipoPersonaEmpresa==`persona`"></Form2>
      <FormE1 v-show="estadoFormLinea == 1 && tipoPersonaEmpresa==`empresa`"></FormE1>
      <FormE2 :paises="paises" :genero="genero" :ocupaciones="ocupacion" v-show="estadoFormLinea == 2 && tipoPersonaEmpresa==`empresa`"></FormE2>
      <PreaprobacionLinea
        v-show="estadoFormLinea == 3 || estadoFormLinea == 4"
      ></PreaprobacionLinea>
      <div v-show="(estadoFormLinea == 5)" v-for="(bpsitem, i) in bpsList" :key="i">
        <DpsLinea v-bind:bps="bpsitem" v-bind:id="(i+1)" v-show="((i+1)==estadoBps)"></DpsLinea>
      </div>
      <TerminosCondiciones v-show="(estadoFormLinea ==  6)"></TerminosCondiciones>
      <Fin :clienteExiste="clienteEncontrado" v-show="(estadoFormLinea ==  7)"></Fin>
      <div>
  <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">6</h1>
 
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">7</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">8</h1>
</v-flex> 
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">9</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">10</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">11</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">12</h1>
</v-flex>

<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">2</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">3</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">4</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">6</h1>
 
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">7</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">8</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">9</h1>
  <div class="frame76persona-cotizarsegurode-desgravamen-nombreultimoacceso" v-show="isLogin">
        <img
          src="/playground_assets/seguros/privado/rectangle2393i1647-8aru.svg"
          alt="Rectangle2393I1647"
          class="frame76persona-cotizarsegurode-desgravamen-rectangle2393"
        />
       
        <div class="frame76persona-cotizarsegurode-desgravamen-group288947">
          <img
            src="/playground_assets/seguros/privado/ellipse313i1647-k04h-200h.png"
            alt="Ellipse313I1647"
            class="frame76persona-cotizarsegurode-desgravamen-ellipse313"
          />
          <img
            src="/playground_assets/seguros/privado/iconbtnsi1647-d0w.svg"
            alt="IconBtnsI1647"
            class="frame76persona-cotizarsegurode-desgravamen-icon-btns3"
          />
        </div> 
        <v-list 
        style="    
        width: 424px;
    height: 61px;
    margin-left: 58px;
    
    background-color:transparent;">
  
  <v-list-group
    :value="false"
    color="white"
    style="    
    color=white
    width: 356px;
    margin-left: 3px;
    border-radius: 30px;
    background-color:white;
    border-width: 2px;
    border-color: #0000003d;
    border-style: solid;"
    
  >
  <!-- <template #prependIcon>
        <v-icon
        style="font-size: 73px;"
        >mdi-account-circle</v-icon>
      </template> -->
      <template #activator>
        
          <span style="color:white">
            {{ rutEmpresa }} / {{ nombrelogin }} <br />Último acceso 05 Nov 2022. 17:00 hrs.</span>
       
      </template>
      
      <v-list-item color="white" style="color: black;
  
" v-for="([title, icon], i) in admins"
        :key="i"
        link
      >
      <v-list-item-icon>
          <v-icon v-text="icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="title"></v-list-item-title>

       
      </v-list-item>

      <v-list-item  >
        <v-btn rounded outline color="juanito" @click="cerrar"
         style="margin-left: 105px;  width: 146px; top: -3px; text-transform: unset !important;">
          Cerrar sesión
        </v-btn>
        
        </v-list-item>

   
  </v-list-group>

  
</v-list>
      </div>

</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">10</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">11</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">12</h1>
</v-flex>


<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">2</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">3</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">4</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">6</h1>
 
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">7</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">8</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">9</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">10</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">11</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">12</h1>
</v-flex>




<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">2</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">3</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">4</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">5</h1>
  
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">6</h1>
 
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">7</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">8</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">9</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">10</h1>
</v-flex>
<v-flex color="primary" sm1>
  <h1 style="display:none !important ;">11</h1>
</v-flex>
<v-flex  sm1>
  <h1 style="display:none !important ;">12</h1>
  
</v-flex>

      </v-layout>
    </v-container>
 
</div>
</v-app>  
<v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import datausuario from "../../../../services/usuario-datos";
import parametros from "../../../../services/parametros";
import Logo from "./components/Logo.vue";
import Form1 from "./components/Form1.vue";
import Form2 from "./components/Form2.vue";
import FormE1 from "./components/FormE1.vue";
import FormE2 from "./components/FormE2.vue";
import PreaprobacionLinea from "../linea/components/PreaprobacionCotizacion.vue";
import DpsLinea from "./components/DpsLinea.vue";
import Terminos from "./components/Terminos.vue";
import TerminosCondiciones from "./components/TerminosCondiciones.vue";
import Fin from "./components/Fin.vue";

import LogoMo from "./components/mobile/Logo.vue";
import Form1Mo from "./components/mobile/Form1.vue";
import Form2Mo from "./components/mobile/Form2.vue";
import FormE1Mo from "./components/mobile/FormE1.vue";
import FormE2Mo from "./components/mobile/FormE2.vue";
import PreaprobacionLineaMo from "./components/mobile/PreaprobacionCotizacion.vue";
import DpsLineaMo from "./components/mobile/DpsLinea.vue";
import TerminosMo from "./components/mobile/Terminos.vue";
import FinMo from "./components/mobile/Fin.vue";

export default {
  name: "FormularioLinea",
  components: {
    Logo: Logo,
    Form1: Form1,
    Form2: Form2,
    FormE1: FormE1,
    FormE2: FormE2,
    PreaprobacionLinea: PreaprobacionLinea,
    DpsLinea: DpsLinea,
    Terminos: Terminos,
    TerminosCondiciones: TerminosCondiciones,
    Fin: Fin,
    LogoMo: LogoMo,
    Form1Mo: Form1Mo,
    Form2Mo: Form2Mo,
    FormE1Mo: FormE1Mo,
    FormE2Mo: FormE2Mo,
    PreaprobacionLineaMo: PreaprobacionLineaMo,
    DpsLineaMo: DpsLineaMo,
    TerminosMo: TerminosMo,
    FinMo: FinMo
  },
  data() {
    return {
      modalMobile: false,
      overlay: false,
      loginEstado:"",
      loginUsuario:"",
      nombrelogin:"",
      rutEmpresa: "",
      loginEstado: this.$store.state.loginEstado,
      loginUsuario: this.$store.state.loginUsuario,
      nombrelogin: this.$store.state.loginUsuario.acc_nombre_principal+" "+this.$store.state.loginUsuario.acc_ape_paterno,
      clienteEncontrado : this.$store.state.clienteEncontrado,
      formdisabled:true,
      tipoPersonaEmpresa: this.$store.state.tipoPersonaEmpresa,
      estadoFormLinea: this.$store.state.estadoFormLinea,
      estadoBps: this.$store.state.estadoBps,
      bpsList: this.$store.state.dps,
      overlay: false,
      validrut: true,
      validnombre1: true,
      validnombre2: true,
      validapaterno: true,
      validamaterno: true,
      isLogin: this.$store.state.islogin,
      cliente: this.$store.state.cliente,
      paises: {nombre : [],codigo : []},  
      genero: {nombre : [],codigo : []},  
      ocupacion : {nombre : [],codigo : []},  
      admins: [
        ['opcion 1', 'mdi-circle'],
        ['opcion 2', 'mdi-circle'],
        ['opcion 3', 'mdi-circle'],
        ['opcion 4', 'mdi-circle'],
      ],
      form1: {
        rut: this.$store.state.form1.rut,
        nombre1: this.$store.state.form1.nombre1,
        nombre2: this.$store.state.form1.nombre2,
        apaterno: this.$store.state.form1.apaterno,
        amaterno: this.$store.state.form1.amaterno,
      },
      antessalir: "",
      valid: true,
      nombre1Rules: [
        (v) => !!v || "el nombre es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      nombre2Rules: [
        (v) => !!v || "el nombre es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      apaternoRules: [
        (v) => !!v || "el apellido paterno es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      amaternoRules: [
        (v) => !!v || "el apellido materno es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      select: null,
      imgfondo1: "/playground_assets/rectangle3829484-upr6.svg",
      imgfondo2: "/playground_assets/rectangle3829484-upr6.svg",
    };
  },
  methods: {
    async traePaises() {
      let res = false;
      this.overlay=true;
      res = await parametros.traepaises();
      if(res.status==200 && res.data.estadoTransaccion.success){
        // console.log("entra");
        let paises={
          nombre : [],
          codigo : []
        };      
        res.data.tablaPaises.forEach(element => {        
            paises.nombre.push(element.pai_nombre);
            paises.codigo.push(element.pai_codigo);          
        });
        // console.log(paises);
        // this.$root.$refs.LineaForm1.selectNacionalidad("CHILE");
        // this.$root.$refs.LineaForm1.seleccionNacionalidad="CHILE";
        // this.$root.$refs.LineaForm1.validnacionalidad=false;
        this.paises=paises;
        this.overlay=false;
        return;
     } else{
      this.$store.commit('setclienteencontrado', false);   
      this.$alertas("En este momento no podemos atender su solicitud","warning");
     }    
    },
    async traeGenero() {
      let res = false;
      res = await parametros.traegenero();
      if(res.status==200 && res.data.estadoTransaccion.success){
        // console.log("entra");
        let genero={
          nombre : [],
          codigo : []
        };      
        res.data.tablaGenero.forEach(element => {        
            genero.nombre.push(element.sex_glosa);
            genero.codigo.push(element.sex_numero);          
        });
        // console.log(genero);
        this.genero=genero;
        return;
     } else{
      this.$store.commit('setclienteencontrado', false);   
      this.$alertas("En este momento no podemos atender su solicitud","warning");
     }    
    },
    async traeOcupacion() {
      let res = false;
      res = await parametros.traeocupaciones();
      if(res.status==200 && res.data.estadoTransaccion.success){
        // console.log("entra");
        let ocupacion={
          nombre : [],
          codigo : []
        };      
        res.data.tablaOcupaciones.forEach(element => {        
            ocupacion.nombre.push(element.ocu_glosa);
            ocupacion.codigo.push(element.ocu_numero);          
        });
        console.log(ocupacion);
        this.ocupacion=ocupacion;
        return;
     } else{
      this.$store.commit('setclienteencontrado', false);   
      this.$alertas("En este momento no podemos atender su solicitud","warning");
     }    
    },
    cerrar: function(){
        this.$store.commit("setislogin",false);
        localStorage.removeItem('loginEstado');
        localStorage.removeItem('loginCliente');
        this.$store.commit("setloginestado", false);
        this.$store.commit("setlogincliente", false);
        this.$router.push('/home');
      },
    cargaDps(){
      this.bpsList= this.$store.state.dps;
      this.$store.commit("setcontdps", this.bpsList.length);
      this.validrut = true;
    },
    buscar(e) {
      if (!this.validrut) {
        console.log(e.srcElement.value);
      }
    },
    vrut(v) {
      this.validrut = v;
      if (!v) {
        this.buscar(this.form1.rut);
      } else {
        this.limpiar();
      }
    },
    vnombre1(v) {
      this.validnombre1 = v;
    },
    vnombre2(v) {
      this.validnombre2 = v;
    },
    vapaterno(v) {
      this.validapaterno = v;
    },
    vamaterno(v) {
      this.validamaterno = v;
    },
    limpiar() {
      var form = {
        rut: "",
        nombre1: "",
        nombre2: "",
        apaterno: "",
        amaterno: "",
      };
      this.form1 = form;
      var form2 = {
        email: "",
        celular: "",
        date: "",
      };
      this.form2 = form2;
      this.$store.commit("setform1", form);
      this.$store.commit("setform2", form2);
      this.validrut = true;
      this.validnombre1 = true;
      this.validnombre2 = true;
      this.validapaterno = true;
      this.validamaterno = true;
      this.$refs.form.reset();
    },
    async buscar(data) {
      this.overlay = true;
      let res = false;
      console.log("hola");
      res = await datausuario.traeusuario(data);
      console.log(res);
      return;
      if (res.data.usuario) {
        var form = {
          rut: res.data.usuario.rut,
          nombre1: res.data.usuario.nombre1,
          nombre2: res.data.usuario.nombre2,
          apaterno: res.data.usuario.apaterno,
          amaterno: res.data.usuario.amaterno,
        };
        this.form1 = form;
        var form2 = {
          email: res.data.usuario.email,
          celular: res.data.usuario.celular,
          date: res.data.usuario.date,
        };
        this.form2 = form2;
        this.$store.commit("setform1", form);
        this.$store.commit("setform2", form2);
        console.log(res);
        this.$alertas(res);
        this.$store.commit("setclienteencontrado", true);
      } else {
        this.$store.commit("setclienteencontrado", false);
      }

      this.overlayBuscar();
    },
    validaRut: function (rutCompleto) {
      if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    btnleft: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-left");
      }, 150);
    },
    btnleftout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-left");
      }, 150);
    },
    btnright: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-right");
      }, 150);
    },
    btnrightout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-right");
      }, 150);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setform1", this.form1);
        console.log(this.$store);
        this.$router.push("formulario-linea2");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    volver() {
      this.$router.push("crear-degravamen");
    },
    hover: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg";
      }
    },
    hoverout: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg";
      }
    },

    overlayBuscar() {
      setTimeout(() => {
        this.overlay = false;
        if (this.$store.state.clienteEncontrado) {
          this.$router.push("formulario-linea4");
        }
      }, 1000);
    },
    seleccionTipoUsuario: function (tipo,formdisabled=false) {
      if(!formdisabled){
        this.$root.$refs.LineaForm1.tipodisabled=false;
        this.$root.$refs.LineaForm1Mo.tipodisabled=false;
      }
      if(this.estadoFormLinea>3){
        return;
      }
      var el = "";
      var txt = "";
      var pt = "";
      el = document.querySelector("." + tipo);
      txt = document.querySelector(".t" + tipo);
      pt = document.querySelector(".p" + tipo);
      el.classList.add("btnTipo");
      txt.classList.add("btnTxt");
      pt.classList.add("puntoColor");
      if (tipo == "persona") {
        el = document.querySelector(".empresa");
        el.classList.remove("btnTipo");
        txt = document.querySelector(".tempresa");
        txt.classList.remove("btnTxt");
        pt = document.querySelector(".pempresa");
        pt.classList.remove("puntoColor");
      }
      if (tipo == "empresa") {
        el = document.querySelector(".persona");
        el.classList.remove("btnTipo");
        txt = document.querySelector(".tpersona");
        txt.classList.remove("btnTxt");
        pt = document.querySelector(".ppersona");
        pt.classList.remove("puntoColor");
        console.log(tipo);
      }
      this.$store.commit("settipopersonaempresa", tipo);
      this.$store.commit("setestadoFormLinea", 1);
      this.tipoPersonaEmpresa=tipo;
      this.estadoFormLinea=1;
    },
  },
  computed: {
    rules() {
      const rules = [];
      let rule = (v) => !!v || "el rut es requerido";
      rules.push(rule);
      rule = (v) => this.validaRut(this.form1.rut) || `rut inválido`;
      rules.push(rule);
      (v) => (v && v.length <= 12) || "largo máximo 12 caracteres";
      rules.push(rule);
      return rules;
    },
  },
  mounted() {
    this.isLogin=false;
      this.$store.commit("setislogin",false);
      localStorage.removeItem('loginEstado');
      localStorage.removeItem('loginCliente');
      this.traePaises();
      this.traeGenero();
      this.traeOcupacion();
      if(this.$store.state.contratarLinea){
        if(this.$store.state.tipoPersonaEmpresa=="persona"){
          this.$root.$refs.LineaForm2.validate();
          this.$root.$refs.LineaForm2Mo.validate();
        }
        if(this.$store.state.tipoPersonaEmpresa=="empresa"){
          this.$root.$refs.LineaForme2.validate();
          this.$root.$refs.LineaForme2Mo.validate();
        }          
      }else{
  
    this.validrut = this.form1.rut.length ? false : true;
    this.validnombre1 = this.form1.nombre1.length ? false : true;
    this.validnombre2 = this.form1.nombre2.length ? false : true;
    this.validapaterno = this.form1.apaterno.length ? false : true;
    this.validamaterno = this.form1.amaterno.length ? false : true;
    this.$store.commit('setclienteencontrado', false);
    this.$store.commit('setrepresentanteencontrado', false);
    if(this.estadoFormLinea<=2){
    var tipo = this.$store.state.tipoPersonaEmpresa;
    if (tipo) {
      console.log(tipo);
      // this.seleccionTipoUsuario(tipo,true);
    }
    }
  }
  },
  created() {
   
    this.$root.$refs.FormularioLinea = this;
 
  },

  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>
<style scoped>
.frame5-persona-formulario-lneadesgravamen-container {
  min-height: 100vh;
}
.frame5-persona-formulario-lneadesgravamen-frame5-persona-formulario-lneadesgravamen {
  width: 100%;
  height: 1250px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame5-persona-formulario-lneadesgravamen-background {
  top: 0px;
  /* left: -150px; */
  width: 660px;
  height: 599px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-logo-v1 {
  top: 29px;
  left: 128px;
  width: 104px;
  height: 109px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-imput-persona-formulario-lneadesgravamen {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 1250px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame5-persona-formulario-lneadesgravamen-background1 {
  top: -530px;
  width: 110%;
  height: 1379px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-text {
  top: 44px;
  color: var(--dl-color-colores_neutrales-white);
  right: 15%;
  height: auto;
  position: absolute;
  font-size: 13px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 47.49999940395355%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-persona-formulario-lneadesgravamen-rectangle39 {
  top: 149px;
  width: 100%;
  height: 950px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-group289058 {
  top: 365px;
  width: 80%;
  height: 111px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(214, 231, 254, 1);
}
.frame5-persona-formulario-lneadesgravamen-group289058-2 {
  top: 365px;
  width: 80%;
  height: 50px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(214, 231, 254, 1);
}
.frame5-persona-formulario-lneadesgravamen-group289058-3 {
  top: 270px;
  width: 80%;
  height: 50px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(214, 231, 254, 1);
}
.frame5-persona-formulario-lneadesgravamen-text02 {
  top: 10px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 75%;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  text-decoration: none;
}
.frame5-persona-formulario-lneadesgravamen-text02-2 {
  top: 34px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 99%;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  text-decoration: none;
}
.frame5-persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 11px;
  width: 24px;
  height: 22px;
  left: 10px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-group {
  top: 1px;
  left: 1.63525390625px;
  width: 20.795804977416992px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector {
  top: 0px;
  left: 0px;
  width: 21px;
  height: 20px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector01 {
  top: 5px;
  left: 10.397902488708496px;
  width: 0px;
  height: 10px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-text04 {
  top: 202px;
  color: var(--dl-color-colores_neutrales-90);
  width: 50%;
  height: auto;
  position: absolute;
  font-size: 14px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 103.49999666213989%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-persona-formulario-lneadesgravamen-text06 {
  top: 171px;
  color: rgba(113, 48, 237, 1);
  height: auto;
  position: absolute;
  font-size: 23px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-persona-formulario-lneadesgravamen-text07 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
}
.frame5-persona-formulario-lneadesgravamen-imput-formulario-mobile {
  top: 500px;
  width: 245px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5-persona-formulario-lneadesgravamen--noleadingicona-inactive {
  width: 245px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-input-field {
  color: rgba(143, 150, 165, 1);
  width: 100%;
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}
.frame5-persona-formulario-lneadesgravamen-imput-formulario-mobile1 {
  top: 596px;
  width: 245px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5-persona-formulario-lneadesgravamen--noleadingicona-inactive1 {
  width: 245px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-input-field1 {
  color: rgba(143, 150, 165, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}
.frame5-persona-formulario-lneadesgravamen-imput-formulario-mobile2 {
  top: 692px;
  width: 245px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5-persona-formulario-lneadesgravamen--noleadingicona-inactive2 {
  width: 245px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-input-field2 {
  color: rgba(143, 150, 165, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}
.frame5-persona-formulario-lneadesgravamen-imput-formulario-mobile3 {
  top: 788px;
  width: 245px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5-persona-formulario-lneadesgravamen--noleadingicona-inactive3 {
  width: 245px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-input-field3 {
  color: rgba(143, 150, 165, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}
.frame5-persona-formulario-lneadesgravamen-imput-formulario-mobile4 {
  top: 884px;
  width: 245px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5-persona-formulario-lneadesgravamen--noleadingicona-inactive4 {
  width: 245px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.frame5-persona-formulario-lneadesgravamen-input-field4 {
  color: rgba(143, 150, 165, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}
.frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2 {
  top: 1143px;
  width: 312px;
  height: 15px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2-2 {
  top: 1183px;
  width: 312px;
  height: 15px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-text08 {
  top: 1177px;
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
}
.frame5-persona-formulario-lneadesgravamen-text08-2 {
  top: 1204px;
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
}
.frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21 {
  top: 1143px;
  width: 106px;
  height: 15px;
  position: absolute;
  margin-left: -205px;
}
.frame5-persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21-2 {
  top: 1183px;
    width: 329px;
    height: 15px;
    position: absolute;
    margin-left: 0px;
}
.frame5-persona-formulario-lneadesgravamen-logotipos {
  top: 27px;
  left: 26px;
  width: 90px;
  height: 94.04495239257812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-txt {
  top: 65.4912109375px;
  left: 0px;
  width: 90px;
  height: 28.554122924804688px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-group1 {
  top: 0px;
  left: 26.558950424194336px;
  width: 33.036964416503906px;
  height: 11.870681762695312px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector02 {
  top: 1.9248809814453125px;
  left: 0px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector03 {
  top: 0px;
  left: 6.801977157592773px;
  width: 4px;
  height: 12px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector04 {
  top: 3.8502960205078125px;
  left: 10.688322067260742px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector05 {
  top: 3.8502960205078125px;
  left: 18.4619197845459px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector06 {
  top: 3.8502960205078125px;
  left: 26.235246658325195px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-group2 {
  top: 15.400634765625px;
  left: 0px;
  width: 86.1550521850586px;
  height: 13.153488159179688px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector07 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector08 {
  top: 2.2457733154296875px;
  left: 8.74444580078125px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector09 {
  top: 2.2457733154296875px;
  left: 16.51831817626953px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector10 {
  top: 2.2457733154296875px;
  left: 23.319704055786133px;
  width: 7px;
  height: 11px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector11 {
  top: 2.2457733154296875px;
  left: 31.41728401184082px;
  width: 5px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector12 {
  top: 2.2457733154296875px;
  left: 36.59986114501953px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector13 {
  top: 2.2457733154296875px;
  left: 44.04920959472656px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector14 {
  top: 2.2457733154296875px;
  left: 51.49855422973633px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector15 {
  top: 2.2457733154296875px;
  left: 59.596134185791016px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector16 {
  top: 2.2457733154296875px;
  left: 71.25611877441406px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector17 {
  top: 2.2457733154296875px;
  left: 79.0294418334961px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-group3 {
  top: 14.723709106445312px;
  left: 86.56906127929688px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-group4 {
  top: 0.8503341674804688px;
  left: 1.1170196533203125px;
  width: 1.4295578002929688px;
  height: 1.6991806030273438px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector18 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 2px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector19 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-img {
  top: 0px;
  left: 15.21243953704834px;
  width: 59.16004943847656px;
  height: 58.59792709350586px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-vector20 {
  top: 1.0317881107330322px;
  left: 0px;
  width: 21px;
  height: 46px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-vector21 {
  top: 0px;
  left: 18.748096466064453px;
  width: 40px;
  height: 59px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame5-persona-formulario-lneadesgravamen-ellipse5 {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame5-persona-formulario-lneadesgravamen-ellipse51 {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame5-persona-formulario-lneadesgravamen-ellipse52 {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-btnpersonaempresaimput {
  top: 244px;
  width: 144px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-rectangle384 {
  top: 0px;
  left: 0px;
  width: 144px;
  height: 40px;
  position: relative;
}
.frame5-persona-formulario-lneadesgravamen-group289054 {
  top: 13px;
  left: 22px;
  width: 99.095703125px;
  height: 14px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-text10 {
  left: 28.095703125px;
  color: var(--dl-color-colores_paleta-tonos_morado-80);
  width: 71px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame5-persona-formulario-lneadesgravamen-radiobtnaceptar {
  top: 0px;
  left: 0px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-ellipse288 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame5-persona-formulario-lneadesgravamen-btnpersonaempresaimput1 {
  top: 298px;
  width: 144px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-rectangle3841 {
  top: 0px;
  left: 0px;
  width: 144px;
  height: 40px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-group2890541 {
  top: 13px;
  left: 22px;
  width: 99.095703125px;
  height: 14px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-text12 {
  left: 28.095703125px;
  color: var(--dl-color-colores_paleta-tonos_morado-80);
  width: 71px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame5-persona-formulario-lneadesgravamen-radiobtnaceptar1 {
  top: 0px;
  left: 0px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-ellipse2881 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame5-persona-formulario-lneadesgravamen-btnatrssiguiente {
  top: 990px;
  left: 30%;
  width: 50px;
  height: 49.999942779541016px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-icon-btns3 {
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  position: absolute;
}
.frame5-persona-formulario-lneadesgravamen-btnatrssiguiente1 {
  top: 990px;
  right: 30%;
  width: 50px;
  height: 49.99993896484375px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5-persona-formulario-lneadesgravamen-icon-btns4 {
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  position: absolute;
}
@media(max-width: 991px) {
  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }
  .frame5-persona-formulario-lneadesgravamen-background1 {
    top: -183px;
    right: 0px;
    height: 1238px;
    margin-bottom: 0px;
  }
  .frame5-persona-formulario-lneadesgravamen-group289058 {
    border-radius: var(--dl-radius-radius-radius8);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse4 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse41 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse42 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse288 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse2881 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-icon-btns3 {
    top: 0px;
    left: 0px;
  }
}
@media(max-width: 767px) {
  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }
  .frame5-persona-formulario-lneadesgravamen-background1 {
    top: -176px;
    left: -1px;
    height: 1053px;
  }
  .frame5-persona-formulario-lneadesgravamen-group289058 {
    border-radius: var(--dl-radius-radius-radius8);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse4 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse41 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse42 {
    right: -20px;
    bottom: -17px;
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse288 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse2881 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-icon-btns3 {
    top: 0px;
    right: 0px;
  }
}
@media(max-width: 479px) {
  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }
  .frame5-persona-formulario-lneadesgravamen-container {
    z-index: 100;
  }
  .frame5-persona-formulario-lneadesgravamen-logo-v1 {
    top: 28px;
    left: 121px;
  }
  .frame5-persona-formulario-lneadesgravamen-background1 {
    top: 0px;
    left: -1px;
    width: 479px;
    height: 724px;
  }
  .frame5-persona-formulario-lneadesgravamen-rectangle39 {
    top: 142px;
  }
  .frame5-persona-formulario-lneadesgravamen-group289058 {
    border-radius: var(--dl-radius-radius-radius8);
  }
  .frame5-persona-formulario-lneadesgravamen-text04 {
    top: 209px;
    width: 80%;
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse4 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse41 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse42 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse288 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-ellipse2881 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame5-persona-formulario-lneadesgravamen-icon-btns3 {
    top: 0px;
    left: 0px;
  }
}


*{
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}
.btnTipo {
  background-color: rgb(113, 48, 237) !important;
}
.btnTxt {
  color: rgb(255, 255, 255) !important;
}
.puntoColor {
  background-color: rgb(255, 184, 78) !important;
}
.container {
    width: 100%;
    max-width: 1920px;
    min-width: 1440px;
}
.v-text-field {
  color: rgba(169, 169, 169, 0.33);
}
.frame1home-logo-v1 {
    top: 34px;
    /* left: 148px; */
    width: 161px;
    height: 169.9999237060547px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
}
.frame71persona-cotizarsegurode-desgravamen-text02 {
  top: 320px;
  color: rgb(113, 48, 237);
  height: auto;
  position: absolute;
  font-size: x-large;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 95%;
  font-stretch: normal;
  margin-left: 102px;
  margin-bottom: 0;
  text-decoration: none;
  /* left: 350px; */
  width: 60%;
}

.frame71persona-cotizarsegurode-desgravamen-text03 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
}

.frame71persona-cotizarsegurode-desgravamen-text05 {
  color: #121212;
  font-size: 20px;
}

.mdi-check-circle::before {
  content: "\F39A";
  color: blue;
}

.w100 {
  width: 500px !important;
}

.w50 {
  width: 150px !important;
}

.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}

.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.boton-left {
  left: 180px !important;
}

.boton-right {
  left: 1200px !important;
}

.widthinput {
  width: 501px !important;
  text-align: center !important;
}

.frame21persona-formulario-lneadesgravamen-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.frame21persona-formulario-lneadesgravamen-frame21persona-formulario-lneadesgravamen {
  width: 1440px;
  height: 950px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}

.frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2 {
  top: -90px;
  /* left: 0px; */
  /* width: 100%; */
  min-width: 1440px;
  height: 375px;
  position: absolute;
}

.frame21persona-formulario-lneadesgravamen-rectangle39 {

  top: 195px;
    /* left: 0px; */
    width: 85%;
    max-width: 1568px;
    height: 630px;
    position: absolute;
    border-color: transparent;
    border-radius: 20px;
    margin-right: 100px;
    margin-left: 150px;
}

.frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente21 {
  top: 840px;
  /* left: 148px; */
  width: 1144px;
  height: 15px;
  position: absolute;
  margin-left: 31px;
}

.frame21persona-formulario-lneadesgravamen-text {
  top: -30px;
  margin-left: 28px;
  /* left: 1103px; */
  color: var(--dl-color-gray-white);
  width: 158px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-icon-outlinecheck {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame21persona-formulario-lneadesgravamen-group288877login {
  top: 88px;
  /* left: 1075px; */
  width: 214px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -587px;
}

.frame21persona-formulario-lneadesgravamen-group288877 {
  top: 88px;
  /* left: 1075px; */
  width: 214px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-rectangle3 {
  top: 18px;
  left: 125.72802734375px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame64persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente22 {
  top: 840px;
  /* left: 148px; */
  width: 1144px;
  height: 15px;
  position: absolute;
  margin-left: 32px;
}
.frame21persona-formulario-lneadesgravamen-rectangle4 {
  top: 18px;
  left: 38px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-group521 {
  top: 0px;
  left: 0px;
  width: 214px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: white;
}

.frame21persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}

.frame21persona-formulario-lneadesgravamen-ellipse5 {
  top: 12.547916412353516px;
  left: 12.546988487243652px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 88px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}

.frame21persona-formulario-lneadesgravamen-ellipse51 {
  top: 12.547916412353516px;
  left: 12.546988487243652px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 176px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}

.frame21persona-formulario-lneadesgravamen-ellipse52 {
  top: 12.547916412353516px;
  left: 12.546988487243652px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-text02 {
  top: 860px;
  /* left: 664px; */
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: -40px;
}

.frame21persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente22 {
  top: 840px;
  /* left: 148px; */
  width: 381px;
  height: 15px;
  position: absolute;
  margin-left: 32px;
}

.frame21persona-formulario-lneadesgravamen-icon-btns3 {
  top: 714px;
  left: 1189px;
  width: 60px;
  height: 60px;
  position: absolute;
}

.frame21persona-formulario-lneadesgravamen-icon-btns4 {
  top: 715px;
  left: 191px;
  width: 60px;
  height: 60px;
  position: absolute;
}

.frame21persona-formulario-lneadesgravamen-group495 {
  top: 30px;
  margin-left: -40px;
  /* left: 148px; */
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-group288914 {
  top: 241px;
  /* left: 474px; */
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 55px;
}
.frame21persona-formulario-lneadesgravamen-group288914-2 {
  top: 241px;
  /* left: 474px; */
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  /* align-items: flex-start; */
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  /* margin-left: 55px; */
}
.frame21persona-formulario-lneadesgravamen-group288914-4 {
  top: 160px !important;
  /* left: 474px; */
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  /* align-items: flex-start; */
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  /* margin-left: 55px; */
}

.frame21persona-formulario-lneadesgravamen-group288914-3 {
  top: 171px;
  /* left: 474px; */
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  /* align-items: flex-start; */
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  /* margin-left: 55px; */
}

.frame21persona-formulario-lneadesgravamen-rectangle382 {
  top: 0px;
  left: 215px;
  width: 243px;
  height: 40px;
  position: absolute;
  cursor: pointer;
}

.frame21persona-formulario-lneadesgravamen-rectangle383 {
  top: 0px;
  left: 0px;
  width: 243px;
  height: 40px;
  position: absolute;
  cursor: pointer;
}

.frame21persona-formulario-lneadesgravamen-text04 {
  top: 13px;
  left: 93px;
  color: white;
  width: 69px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  font-weight: 600;
  line-height: 94.9999988079071%;
  cursor: pointer;
}

.frame21persona-formulario-lneadesgravamen-text06 {
  top: 13px;
  left: 347.095703125px;
  color: var(--dl-color-colores_neutrales-50);
  width: 71px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  font-weight: 600;
  line-height: 94.9999988079071%;
  cursor: pointer;
}

.frame21persona-formulario-lneadesgravamen-radiobtnaceptar {
  top: 13px;
  left: 319px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-ellipse288 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}

.frame21persona-formulario-lneadesgravamen-radiobtnaceptar1 {
  top: 13px;
  left: 64px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-ellipse289 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  /* border-color: rgba(255, 184, 78, 1); */
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}

.frame21persona-formulario-lneadesgravamen-text08 {
  top: 320px;
  left: 520px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  width: 800px;
}



.frame21persona-formulario-lneadesgravamen-casusitica-btn-formulario {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}

.frame21persona-formulario-lneadesgravamen-a-noleadingicona-inactive {
  width: 526px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}

.frame21persona-formulario-lneadesgravamen-input-field {
  color: rgba(123, 131, 149, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}

.frame21persona-formulario-lneadesgravamen-casusitica-btn-formulario1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}

.frame21persona-formulario-lneadesgravamen-a-noleadingicona-inactive1 {
  width: 526px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}

.frame21persona-formulario-lneadesgravamen-input-field1 {
  color: rgba(123, 131, 149, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}

.frame21persona-formulario-lneadesgravamen-casusitica-btn-formulario2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 24px;
  flex-direction: column;
}

.frame21persona-formulario-lneadesgravamen-a-noleadingicona-inactive2 {
  width: 526px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}

.frame21persona-formulario-lneadesgravamen-input-field2 {
  color: rgba(123, 131, 149, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}

.frame21persona-formulario-lneadesgravamen-casusitica-btn-formulario3 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}

.frame21persona-formulario-lneadesgravamen-a-noleadingicona-inactive3 {
  width: 526px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}

.frame21persona-formulario-lneadesgravamen-input-field3 {
  color: rgba(123, 131, 149, 1);
  display: flex;
  padding: 16px 12px 14px 14px;
  position: relative;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto;
  font-weight: 400;
  border-color: transparent;
  background-color: var(--dl-color-colores_neutrales-5);
}

.frame21persona-formulario-lneadesgravamen-alertbanner {
  top: 356px;
  /* left: 356px; */
  width: 728px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(214, 231, 254, 1);
  margin-left: 94px;
}

.frame21persona-formulario-lneadesgravamen-alertbanner-2 {
  top: 287px;
  /* left: 356px; */
  width: 728px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(214, 231, 254, 1);
  margin-left: 94px;
}

.frame21persona-formulario-lneadesgravamen-group506 {
  top: 8px;
  left: 38px;
  width: 652px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  left: 0px;
  width: 24.063716888427734px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-group7 {
  top: 2px;
  left: 2.005309820175171px;
  width: 20.053096771240234px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame21persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  right: -1px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.frame21persona-formulario-lneadesgravamen-vector21 {
  top: 5px;
  left: 10.026548385620117px;
  width: 0px;
  border: 1px;
  height: 10px;
  position: absolute;
  background: blue;
}

.frame21persona-formulario-lneadesgravamen-text10 {
  top: 4px;
  left: 10px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 614px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  font-weight: 600;
  line-height: 94.9999988079071%;
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck2 {
  top: 9.962783813476562px;
  left: 9.962167739868164px;
  width: 28px;
  height: 28px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-icon-btns2 {
  top: -5px;
  left: 171px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame71persona-cotizarsegurode-desgravamen-group288914 {
  top: 241px;
  /* left: 473px; */
  /* width: 493px; */
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 50px;
}
.frame71persona-cotizarsegurode-desgravamen-group288914-3 {
  top: 171px;
  /* left: 473px; */
  /* width: 493px; */
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content:start;
  background-color: transparent;
  
}
.frame71persona-cotizarsegurode-desgravamen-group288914-2 {
  top: 241px;
  /* left: 473px; */
  /* width: 493px; */
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content:start;
  background-color: transparent;
  
}
.frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar {
  top: 13px;
  left: 319px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame71persona-cotizarsegurode-desgravamen-ellipse288 {
  top: 0px;
  left: -25px;
  width: 14px;
  height: 15px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame71persona-cotizarsegurode-desgravamen-radiobtnaceptar1 {
  top: 13px;
  left: 64px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame71persona-cotizarsegurode-desgravamen-ellipse2881 {
  top: 0px;
  left: -25px;
  width: 14px;
  height: 15px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame76persona-cotizarsegurode-desgravamen-nombreultimoacceso {
  top: 68px;
  /* left: 957px; */
  width: 424px;
  height: 61px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame76persona-cotizarsegurode-desgravamen-rectangle2393 {
  top: 0px;
  left: 0px;
  width: 424px;
  height: 61px;
  position: absolute;
}
.frame76persona-cotizarsegurode-desgravamen-text40 {
  top: 12px;
  left: 89px;
  color: var(--dl-color-gray-white);
  width: 258px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame76persona-cotizarsegurode-desgravamen-group288947 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame76persona-cotizarsegurode-desgravamen-ellipse313 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  position: absolute;
  border-color: transparent;
}
.frame76persona-cotizarsegurode-desgravamen-icon-btns3 {
  top: 7.988186836242676px;
  left: 7.988186836242676px;
  width: 45px;
  height: 45px;
  position: absolute;
}
.frame71persona-cotizarsegurode-desgravamen-rectangulo-fondo-gradiente2p {
  top: -115px;
    /* left: 0px; */
    width: 1906px;
    height: auto;
    position: absolute;
}
.frame-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame-persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame-persona-formulario-lneadesgravamen-ellipse5 {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: var(--dl-radius-radius-round);
}
.frame-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame-persona-formulario-lneadesgravamen-ellipse51 {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-rectangle39 {
  top: 149px;
  width: 100%;
  height: 1000px;
  display: flex;
  position: absolute;
  border-radius: 20px;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-rectangle2438 {
  top: 287px;
  width: 90%;
  height: 130px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-text52 {
  top: 302px;
  color: var(--dl-color-colores_neutrales-white);
  width: 250px;
  height: auto;
  position: absolute;
  font-size: 16;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-text53 {
  font-weight: 600;
}
.frame6-persona-formulario-lneadesgravamen-text57 {
  font-weight: 700;
}
.frame6-persona-formulario-lneadesgravamen-text58 {
  font-weight: 400;
}
.frame6-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-ellipse5 {
  top: 9.90625px;
  left: 9.905517578125px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.frame7-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck2 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.g-logo-blanco{
  width: 80px;
}
</style>
