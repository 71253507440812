import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

export default {
  contacto(id) {
    return this.callApi(ENDPOINT_PATH + "DatosCorreo/"+id,"post");
  },
  traecontactos() {
    return this.callApi(ENDPOINT_PATH + "DatosCorreo","post");
  },
  guardar(contacto) {
    return this.callApi(ENDPOINT_PATH + "Correos/Grabar", "post", contacto);
  },
  actualizar(id) {
    return this.callApi(ENDPOINT_PATH + "DatosCorreo/"+id, "put", contacto);
  },
  eliminar(id) {
    return this.callApi(ENDPOINT_PATH + "DatosCorreo/"+id, "delete", contacto);
  },

  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios.post(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios.post(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      } 
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
    } catch (error) {
      return false;
    }
  },
};
