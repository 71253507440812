<template>
 <v-row class="colcenter">
  <v-col cols="12" sm="12" md="9" lg="9" xl="9">
    <v-row class="imagenBienvenido">
      <v-col cols="12" sm="12" md="7" lg="7" xl="7">
        <div class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones">
          <input type="hidden">
          <span >
            <span
              class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span">
              Bienvenido a tu Mi Portal Seguro
              <br>
            </span>
            <span
              class="bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2">
              <br>
              Para continuar con tu PORTABILIDAD
              <br>
              accede a MIS SIMULACIONES
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="bannerBienvenido">
        <img class="happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1"
          src="/assets/privado/happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-10.png" />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" sm="12" md="12" lg="12" xl="12">

  </v-col>
  <v-col cols="12" sm="12" md="10" lg="8" xl="8" class="colcenter">
    <v-row class="colcenter">
      <v-col cols="12" sm="12" md="8" lg="4" xl="4" class="colcenter cuadros">
        <div class="rectangle-432">
          <div class="dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar">
            <span>
              <span class="dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span">
                Dinero Recuperado
                <br>
                Disponible
                <br>
                <br>
              </span>
              <span class="dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span2">
                {{ montoSaldos.csa_saldo_wallet }}
                <br>
              </span>
              <span class="dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span3">
                <br>
              </span>
              <span class="dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span4">
                Accede a Mi Dinero
                <br>
                para retirar
              </span>
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="4" xl="4" class="colcenter cuadros">
        <div class="rectangle-432">
          <div class="deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros">
            <span>
              <span class="deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span">
                Deuda Asegurada
                <br>
                <br>
              </span>
              <span class="deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span2">
                {{ montoSaldos.csa_deuda_asegurada }}
                <br>
              </span>
              <span class="deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span3">
                <br>
              </span>
              <span class="deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span4">
                Para revisar tus pólizas accede a Mis Seguros
              </span>
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="4" xl="4" class="colcenter cuadros">
        <div class="rectangle-432">
          <div
            class="si-tienes-otros-cr-ditos-de-consumo-automotriz-o-comercial-pyme-accede-a-contratar-seguro-para-portarlo-y-recuperar-dinero">
            Si tienes otros créditos de consumo, automotriz o comercial (pyme)
            accede a Contratar Seguro para Portarlo y recuperar dinero
          </div>
        </div>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
          <div
            class="mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8">
            Mueve Seguro actúa conforme a la&nbsp;Circular 2.114 de fecha 26-07-2013 de la
            CMF,&nbsp;Ley 19.496 Art.&nbsp;17D,&nbsp;Ley 20.448 Art. 8
          </div>
        </v-col>
</v-row>
</template>

<script>

export default {
  name: "PrivadoInicio",

  data() {
    return {
      datosContrato: {},
    };
  },
  props: {
    montoSaldos: {},
  },
  methods: {
    simular(){
        // this.$router.push("/formulario-cotizacion");
        this.$root.$refs.HomePrivado.cambiaMenu(4);
    },
    hover: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover");
    },
    hovertxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover-txt");
    },
    hoverouttxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover-txt");
    },
    formatNumberES(n, d = 0) {
      n = new Intl.NumberFormat("es-ES").format(parseFloat(n).toFixed(d));
      if (d > 0) {
        // Obtenemos la cantidad de decimales que tiene el numero
        const decimals =
          n.indexOf(",") > -1 ? n.length - 1 - n.indexOf(",") : 0;

        // añadimos los ceros necesios al numero
        n =
          decimals == 0
            ? n + "," + "0".repeat(d)
            : n + "0".repeat(d - decimals);
      }
      return n;
    },
  },
  mounted() {    
    window.scrollTo(0,0);      
  }
  
};
</script>
<style scoped>
.colcenter {
  display: flex;
  justify-content: center;
}
.imagenBienvenido {
  background-color: white;
  border-radius: 40px;
}

.bannerBienvenido {
  padding: 0px;
}

.rectangle-432 {
  background: var(--blanco, #f0f2f2);
  border-radius: 40px;
  flex-shrink: 0;
  width: 90%;
  height: 270px;
  position: relative;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cuadros {
  padding: 10px;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
    top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.dinero-recuperado-disponible-350-000-accede-a-mi-dinero-para-retirar-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 42px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 38px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.deuda-asegurada-45-000-000-para-revisar-tus-p-lizas-accede-a-mis-seguros-span4 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.si-tienes-otros-cr-ditos-de-consumo-automotriz-o-comercial-pyme-accede-a-contratar-seguro-para-portarlo-y-recuperar-dinero {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  /* left: 35px;
  top: 34px; */
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 1000%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

.txtbnt7 {
  font-size: 26px !important;
  font-weight: 500;
  padding: 28px;
  /* padding-top: 28px !important;
  padding-bottom: 28px !important; */
  /* background-color: rgb(3 75 178) !important;  */
  color: rgba(4, 19, 127, 1) !important;

}

.txtbnt6 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: white !important;
  /* color: white !important; */
  text-transform: unset !important;

}




  @media only screen and (max-width: 1000px) {
    .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
    font-size: 17px !important;
  }

  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
    font-size: 23px !important;
  }
  }
  
.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 35px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}
.happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1 {
  border-radius: 0px 40px 40px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* height: 220px; */
}
.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

</style>
