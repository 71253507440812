<template>
  <div class="overlayverdetalledeseguro-container">
    <div class="overlayverdetalledeseguro-overlayverdetalledeseguro">
      
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24191568-k2kq-1200h.png"
        alt="Rectangle24191568"
        class="overlayverdetalledeseguro-rectangle2419"
      /> -->
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24201586-axo-200h.png"
        alt="Rectangle24201586"
        class="overlayverdetalledeseguro-rectangle2420"
      /> -->
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24271586-uvdq-200h.png"
        alt="Rectangle24271586"
        class="overlayverdetalledeseguro-rectangle2427"
      /> -->
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24231586-z5lr-200h.png"
        alt="Rectangle24231586"
        class="overlayverdetalledeseguro-rectangle2423"
      />
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24241586-b2ca-200h.png"
        alt="Rectangle24241586"
        class="overlayverdetalledeseguro-rectangle2424"
      />
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24251586-rfn9-200h.png"
        alt="Rectangle24251586"
        class="overlayverdetalledeseguro-rectangle2425"
      />
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24261586-lusl-200h.png"
        alt="Rectangle24261586"
        class="overlayverdetalledeseguro-rectangle2426"
      /> 
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24211586-d56u-200h.png"
        alt="Rectangle24211586"
        class="overlayverdetalledeseguro-rectangle2421"
      /> -->
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24281586-2ufi-200h.png"
        alt="Rectangle24281586"
        class="overlayverdetalledeseguro-rectangle2428"
      />
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24291586-lzak-200h.png"
        alt="Rectangle24291586"
        class="overlayverdetalledeseguro-rectangle2429"
      />
      <img
        src="/playground_assets/seguros/privado/menumisseguros/rectangle24301586-urvp-200h.png"
        alt="Rectangle24301586"
        class="overlayverdetalledeseguro-rectangle2430"
      /> -->
      <!-- <div v-if="!simulacion" class="overlayverdetalledeseguro-group288920">
        <span class="overlayverdetalledeseguro-text">
          <span>Nº de Seguro</span>
        </span>
        <span class="overlayverdetalledeseguro-text02">
          <span>{{ Seguro.numero }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text04">
          <span>Nº de Póliza</span>
        </span>
        <span class="overlayverdetalledeseguro-text06">
          <span>{{ Seguro.poliza }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text08">
          <span>Nº de Transacción</span>
        </span>
        <span class="overlayverdetalledeseguro-text10">
          <span>{{ Seguro.transaccion }}</span>
        </span>
      </div> -->
      <!-- <div v-if="!simulacion" class="overlayverdetalledeseguro-group288949">
        <div class="overlayverdetalledeseguro-usosdebotones">
          <span class="overlayverdetalledeseguro-text12">
            <span>Descarga tu póliza</span>
          </span>
        </div>
        <div class="overlayverdetalledeseguro-group288925">
          <span class="overlayverdetalledeseguro-text14">
            <span>
              Si Quieres Descargar nuevamente tu comprobante de pago u obtener
              tu póliza. Lo puedes realizar en los siguientes enlaces
            </span>
          </span>
        </div>
      </div> -->
       <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/line451559-bvko.svg"
        alt="Line451559"
        class="overlayverdetalledeseguro-line45"
      /> -->
      <!-- <img
        src="/playground_assets/seguros/privado/menumisseguros/line461559-fgu8.svg"
        alt="Line461559"
        class="overlayverdetalledeseguro-line46"
      /> -->
      <div class="overlayverdetalledeseguro-group288921">
        <!-- <span class="overlayverdetalledeseguro-text16">
          <span>DESGRAVAMEN</span>
        </span> -->
        <v-icon  color="m100" @click="continuaOrigen"
          style="margin-left: 1000px !important;font-size: 47px;top: -36px;cursor:pointer">
          mdi-close-circle-outline
      </v-icon>
      </div>
      <!-- <div>
        <img
          src="/playground_assets/seguros/privado/menumisseguros/vector1559-cwjg.svg"
          alt="Vector1559"
          class="overlayverdetalledeseguro-vector"
        />
      </div> -->
      <div v-if="estado!=5" class="overlayverdetalledeseguro-group288970">
        <span class="overlayverdetalledeseguro-text18">
          <span>DATOS DE SIMULACION</span>
        </span>
        <img
          src="/playground_assets/seguros/privado/menumisseguros/vector1586-9eks.svg"
          alt="Vector1586"
          class="overlayverdetalledeseguro-vector1"
        />
      </div>
      <div v-if="estado==5" class="overlayverdetalledeseguro-group288970">
        <span class="overlayverdetalledeseguro-text18">
          <span>SEGURO DESGRAVAMEN</span>
        </span>
        <img
          src="/playground_assets/seguros/privado/menumisseguros/vector1586-9eks.svg"
          alt="Vector1586"
          class="overlayverdetalledeseguro-vector1"
        />
      </div>

      <!--
      <div class="overlayverdetalledeseguro-group288953">
        <span class="overlayverdetalledeseguro-text20">
          <span>{{ Seguro.inicio }}</span>
        </span>
        <span v-if="!simulacion" class="overlayverdetalledeseguro-text22">
          <span>Vigencia Fecha Inicio</span>
        </span>
      </div>
      <div class="overlayverdetalledeseguro-group288954">
        <span class="overlayverdetalledeseguro-text24">
          <span>{{ Seguro.termino }}</span>
        </span>
        <span v-if="!simulacion"  class="overlayverdetalledeseguro-text26">
          <span>Fecha Termino</span>
        </span>
      </div>
      <div class="overlayverdetalledeseguro-group288951">
        <span class="overlayverdetalledeseguro-text28">
          <span>{{ Seguro.celular }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text30"
          ><span>Celular</span></span
        >
        <span class="overlayverdetalledeseguro-text32">
          <span>{{ Seguro.email }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text34">
          <span>Correo Electrónico</span>
        </span>
      </div>
      <div class="overlayverdetalledeseguro-group288950">
        <span v-if="!simulacion" class="overlayverdetalledeseguro-text36">
          <span>{{ Seguro.direccion }}</span>
        </span>
        <span v-if="simulacion" class="overlayverdetalledeseguro-text36-2">
          <span>{{ Seguro.direccion }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text38">
          <span>Dirección</span>
        </span>
      </div>
      <div v-if="!simulacion" class="overlayverdetalledeseguro-group288955">
        <span class="overlayverdetalledeseguro-text40">
          <span>{{ Seguro.nombre }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text42">
          <span>Contratante</span>
        </span>
      </div>
      <div v-if="simulacion" class="overlayverdetalledeseguro-group288955-2">
        <span class="overlayverdetalledeseguro-text40">
          <span>{{ Seguro.nombre }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text42">
          <span>Contratante</span>
        </span>
      </div>
      <div class="overlayverdetalledeseguro-group288956">
        <span class="overlayverdetalledeseguro-text44">
          <span>{{ Seguro.rut }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text46"><span>Rut</span></span>
      </div>
      <div class="overlayverdetalledeseguro-group288969">
        <span class="overlayverdetalledeseguro-text48"><span>Plan</span></span>
        <span class="overlayverdetalledeseguro-text50">
          <span>Seg Desgrav.</span>
        </span>
        <span class="overlayverdetalledeseguro-text52">
          <span>{{ Seguro.montoPeso }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text54">
          <span>{{ Seguro.montoUf }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text56">
          <span> Prima Única </span>
        </span>
        <span class="overlayverdetalledeseguro-text58">
          <span>Prima en Pesos</span>
        </span>
        <span class="overlayverdetalledeseguro-text60">
          <span>{{ Seguro.montoAsegurado }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text62">
          <span>Monto Asegurado</span>
        </span>
      </div> -->
      <div class="overlayverdetalledeseguro-group288971">
        <span v-if="estado!=5" class="overlayverdetalledeseguro-text64">
          <span>{{ Seguro.tipoInstitucion }}</span>
        </span>
        <span v-if="estado==5" class="overlayverdetalledeseguro-text64">
          <span>{{ Seguro.nombreCompania }}</span>
        </span>
        <span v-if="estado!=5" class="overlayverdetalledeseguro-text66">
          <span>{{ Seguro.saldoInsoluto }}</span>
        </span>
        <span v-if="estado==5" class="overlayverdetalledeseguro-text66">
          <span>{{ Seguro.saldoInsoluto }}</span>
        </span>
        <span v-if="estado!=5" class="overlayverdetalledeseguro-text68">
          <span>{{ Seguro.nombreIntitucion }}</span>
        </span>
        <span v-if="estado==5" class="overlayverdetalledeseguro-text68">
          <span>{{ Seguro.poliza }}</span>
        </span>
        <span v-if="estado!=5" class="overlayverdetalledeseguro-text70">
          <span>{{ Seguro.mesesPendientePago }}</span>
        </span>
        <span v-if="estado==5" class="overlayverdetalledeseguro-text70">
          <span>{{ Seguro.plazoHasta }}</span>
        </span>
        <span class="overlayverdetalledeseguro-text72">
          <span>{{ Seguro.montoPeso }}</span>
        </span>
        <span v-if="estado!=5" class="overlayverdetalledeseguro-text74">
          <span>{{ Seguro.saldoFinalCliente }}</span>
        </span>
        <span v-if="estado==5" class="overlayverdetalledeseguro-text74">
          <span>{{ Seguro.nombreIntitucion }}</span>
        </span>
        <div class="overlayverdetalledeseguro-frame288930">
          <span v-if="estado!=5" class="overlayverdetalledeseguro-text78">
            <span>Tipo de institución</span>
          </span>
          <span v-if="estado==5" class="overlayverdetalledeseguro-text78">
            <span>Compañia</span>
          </span>
          <span v-if="estado!=5" class="overlayverdetalledeseguro-text80">
            <span>Nombre de institución</span>
          </span>
          <span v-if="estado==5" class="overlayverdetalledeseguro-text80">
            <span>Nro de Póliza</span>
          </span>
          <span v-if="estado!=5" class="overlayverdetalledeseguro-text82">
            <span>Saldo adeudado</span>
          </span>
          <span v-if="estado==5" class="overlayverdetalledeseguro-text82">
            <span>Monto Asegurado</span>
          </span>
          <span v-if="estado!=5" class="overlayverdetalledeseguro-text84">
            <span>Cantidad pendientes</span>
          </span>
          <span v-if="estado==5" class="overlayverdetalledeseguro-text84">
            <span>Plazo Hasta</span>
          </span>
          <span class="overlayverdetalledeseguro-text86">
            <span>Valor nuevo seguro</span>
          </span>
          <span v-if="estado!=5" class="overlayverdetalledeseguro-text88">
            <span>Saldo a favor cliente</span>
          </span>
          <span v-if="estado==5" class="overlayverdetalledeseguro-text88">
            <span>Beneficiario</span>
          </span>
          <!-- <span class="overlayverdetalledeseguro-text90">
            <span>Cantidad de cuotas</span>
          </span> -->
        </div>
      </div>
      <div class="">
        <span class="overlayverdetalledeseguro-text92">
          <span v-if="estado==4">Seguro Inactivo</span>
          <!-- <span v-if="simulacion">Pagar</span> -->
          <div v-if="estado==3 && mandato==2 && renuncia==2 " style="border-color: transparent" >
            <v-btn
              color="rgba(255, 184, 78)"
              rounded
              x-large
              @click="continuaContrato(nroseguro,nroplan)"
              style="       background-color: rgb(255, 184, 78);
            border-color: rgb(255, 184, 78);
            text-transform: unset !important;
            top: 57px;
            margin-left: 832px;
            width: 248px;
            font-size: x-large;"
              
              
            >
              <span >
                <span>Contratar</span>
              </span>
            </v-btn>
          </div>

          <div v-if="estado==5" style="border-color: transparent" >
            <v-btn
              color="rgba(255, 184, 78)"
              rounded
              x-large
              disabled
              @click="continuaContrato(nroseguro,nroplan)"
              style="       background-color: rgb(255, 184, 78);
            border-color: rgb(255, 184, 78);
            text-transform: unset !important;
            top: 57px;
            margin-left: 832px;
            width: 248px;
            font-size: x-large;"
              
              
            >
              <span >
                <span>Contratado</span>
              </span>
            </v-btn>
          </div>
          
        </span>
      </div>
      <div v-if="!simulacion" class="overlayverdetalledeseguro-usosdebotones2">
        <v-btn 
        color="azulsitio"
        v-if="Seguro.cse_usu_credito_nuevo"
        rounded
        x-large
        style="top: 0px; text-transform: unset !important"
        @click="descarga_documento(2,Seguro.cse_seg_numero)"
      >
        <span class="overlayverdetalledeseguro-text94">
          <span>Comprobante de pago</span>
        </span>
      </v-btn>
        <!-- <span class="overlayverdetalledeseguro-text94">
          <span>Comprobante de pago</span>
        </span> -->
      </div>
      <div v-if="!simulacion" class="overlayverdetalledeseguro-usosdebotones3">
        <v-btn 
        color="azulsitio"
        
        rounded
        x-large
        style="top: 0px; text-transform: unset !important"
        @click="descarga_documento(1,Seguro.cse_seg_numero)"
      >
        <span class="overlayverdetalledeseguro-text94">
          <span>Descargar Poliza</span>
        </span>
      </v-btn>
        <!-- <span class="overlayverdetalledeseguro-text94">
          <span>Comprobante de pago</span>
        </span> -->
      </div>
    </div>
    
    <v-overlay :value="Loading || overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </div>
</template>

<script>

import datausuario from "../../../../services/usuario-datos";


export default {
  name: "DetalleSeguroPage",
  data() {
    return {
      overlay: false,
    };
  },
  props: {
    Seguro: {},
    Loading: false,
    simulacion: false,
    cierraModaldet : "",
    nroseguro: "",
    estado: 0,
    mandato: 0,
    renuncia: 0,
    nroplan:0,
  },
  methods:{
    async descarga_documento(opt,nro) {
      this.overlay = true;
      var res = false;
      var documento = false;
      var ext="";
      var nombreDoc="";
      var seguro = {
        // seg_numero: this.$store.state.seguroSeleccion,
        seg_numero: nro,
      };
      if(opt==1){        
        res = await datausuario.traePoliza(seguro);
      }
      if(opt==2){       
        res = await datausuario.traeComprobante(seguro);
      }
      
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        // this.$alertas(res.data.estadoTransaccion.errors, "success");
        documento = res.data.cdo_docto;
        nombreDoc=res.data.cdo_nombre_docto;
        ext = res.data.cdo_extension.toLowerCase();
        this.extension = ext;
        this.documentoDescarga = documento;
        
        
        const binaryString = atob(documento);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });

          console.log(blob);
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = nombreDoc;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          this.overlay = false;
      } else {
        this.$alertas("En este momento no podemos atender su solicitud", "warning");
        this.overlay = false;
      }
    },
    pagar(seguro) {          
   
      this.$root.$refs.PrivadoCotizacion.cargaPlanes(seguro);
      this.continuaOrigen();
     
    },
    continuaContrato(nro,nrop){
      this.$store.commit("setseguroseleccion",nro);
      this.$store.commit("setplanseleccion",nrop);
      // console.log("contrato:" + nro);
      // console.log("seguro: ");
      // console.log(this.$root.$refs.HomePrivado.misCotizaciones);
      // this.$root.$refs.HomePrivado.misCotizaciones.forEach(element => {
      //   if(element.numero==nro){
      //     this.$store.commit("setseguroseleccion",element);
      //   }
      // });
      
      this.$store.commit("setestadoprivado", true);
      this.$root.$refs.HomePrivado.overlay=true;
      this.$store.commit("setestadoFormCotizacionPrivado", 21);
        this.$router.push("/formulario-cotizacion"); 
        this.continuaOrigen();
    },
    continuaOrigen(){
     
      this.$emit('cierraModaldet');
      this.cerrar();
    },
    cerrar(){
      this.$emit('cierraModal');
    }
  },
  metaInfo: {
    title: "exported project",
  },
  created(){
    
  }
};
</script>

<style scoped>
.overlayverdetalledeseguro-container {
  min-height: 83vh;
    overflow-y: scroll !important;
    background-color: white;
    border-radius: 21px;
    overflow: hidden;
}
.overlayverdetalledeseguro-overlayverdetalledeseguro {
  width: 100%;
  /* height: 1113px; */
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2419 {
  top: 0px;
  left: 0px;
  width: 1145px;
  height: 1113px;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2420 {
  top: 164.03857421875px;
  left: 50px;
  width: 452px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2427 {
  top: 738.55419921875px;
  left: 50px;
  width: 1045px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2423 {
  top: 164.03857421875px;
  left: 560px;
  width: 221px;
  height: 56px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2424 {
  top: 295.03857421875px;
  left: 560px;
  width: 221px;
  height: 56px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2425 {
  top: 423.03857421875px;
  left: 560px;
  width: 221px;
  height: 56px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2426 {
  top: 423.03857421875px;
  left: 50px;
  width: 452px;
  height: 56px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2421 {
  top: 272.03857421875px;
  left: 50px;
  width: 452px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2428 {
  top: 832.55419921875px;
  left: 50px;
  width: 1045px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2429 {
  top: 928.55419921875px;
  left: 50px;
  width: 1045px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-rectangle2430 {
  top: 1024.55419921875px;
  left: 50px;
  width: 1045px;
  height: 45px;
  opacity: 0.3;
  position: absolute;
  border-color: transparent;
}
.overlayverdetalledeseguro-group288920 {
  top: 154.53353881835938px;
  left: 840.7685546875px;
  width: 197.88821411132812px;
  height: 183.4023895263672px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text {
  color: var(--dl-color-colores_neutrales-90);
  width: 122px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text02 {
  top: 28.905811309814453px;
  left: 0.000027719022909877822px;
  color: var(--dl-color-colores_neutrales-90);
  width: 198px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text04 {
  top: 64.78888702392578px;
  left: 0.000027719022909877822px;
  color: var(--dl-color-colores_neutrales-90);
  width: 114px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text06 {
  top: 93.69469451904297px;
  left: 0.000027719022909877822px;
  color: var(--dl-color-colores_neutrales-90);
  width: 143px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text08 {
  top: 135.55828857421875px;
  left: 0.000027719022909877822px;
  color: var(--dl-color-colores_neutrales-90);
  width: 169px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text10 {
  top: 164.46409606933594px;
  left: 0.000027719022909877822px;
  color: var(--dl-color-colores_neutrales-90);
  width: 143px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288949 {
  top: 535.9998779296875px;
  left: 68px;
  width: 719px;
  height: 54px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-usosdebotones {
  top: 0px;
  left: 483px;
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-100);
}
.overlayverdetalledeseguro-text12 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288925 {
  top: 3px;
  left: 0px;
  width: 434px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text14 {
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 434px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 115.99999666213989%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-line45 {
  top: 486.453125px;
  left: 536.24609375px;
  width: 332px;
  height: 0px;
  position: absolute;
}
.overlayverdetalledeseguro-line46 {
  top: 481.46826171875px;
  left: 800.78125px;
  width: 332px;
  height: 0px;
  position: absolute;
}
.overlayverdetalledeseguro-group288921 {
  top: 47.84130859375px;
  left: 91.25390625px;
  width: 313.7502136230469px;
  height: 59.80512619018555px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text16 {
  top: 20.931791305541992px;
  left: 76.8995590209961px;
  color: var(--dl-color-colores_neutrales-90);
  width: 237px;
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-vector {
  top: 0px;
  left: 0px;
  width: 50px;
  height: 60px;
  position: absolute;
}
.overlayverdetalledeseguro-group288970 {
  top: 30px;
  left: 91.25390625px;
  width: 432.6882019042969px;
  height: 59.80512619018555px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text18 {
  top: 20.931808471679688px;
  left: 76.8995590209961px;
  color: var(--dl-color-colores_neutrales-90);
  width: 500px;
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-vector1 {
  top: 0px;
  left: 0px;
  width: 50px;
  height: 60px;
  position: absolute;
}
.overlayverdetalledeseguro-group288953 {
  top: 425.75732421875px;
  left: 91.25390625px;
  width: 195.83755493164062px;
  height: 48.73312759399414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text20 {
  top: 29.794818878173828px;
  left: 2.0506532192230225px;
  color: var(--dl-color-colores_neutrales-90);
  width: 108px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text22 {
  color: var(--dl-color-colores_neutrales-90);
  width: 196px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288954 {
  top: 425.75732421875px;
  left: 342.458984375px;
  width: 136.36856079101562px;
  height: 47.84410095214844px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text24 {
  top: 28.90576934814453px;
  left: -0.000002851860926966765px;
  color: var(--dl-color-colores_neutrales-90);
  width: 108px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text26 {
  color: var(--dl-color-colores_neutrales-90);
  width: 136px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288951 {
  top: 363.8515625px;
  left: 575.208984375px;
  width: 170.20436096191406px;
  height: 111.63622283935547px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text28 {
  top: 28.905778884887695px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 159px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text30 {
  color: var(--dl-color-colores_neutrales-90);
  width: 65px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text32 {
  top: 92.69790649414062px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 161px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text34 {
  top: 63.79209899902344px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 170px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288950 {
  top: 297.06884765625px;
  left: 575.208984375px;
  width: 192.76156616210938px;
  height: 47.84410095214844px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text36 {
  top: 28.90581703186035px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 193px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text36-2 {
  top: 28.90581703186035px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 500px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text38 {
  color: var(--dl-color-colores_neutrales-90);
  width: 87px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288955 {
    top: 170.48095703125px;
    left: 575.208984375px;
    width: 229px;
    height: 322px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    border-right: 4px solid darkgray;
    background-color: transparent;
}
.overlayverdetalledeseguro-group288955-2 {
  top: 170.48095703125px;
  left: 575.208984375px;
  width: 186.60960388183594px;
  height: 47.84410095214844px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text40 {
  top: 28.905805587768555px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 187px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text42 {
  color: var(--dl-color-colores_neutrales-90);
  width: 109px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288956 {
  top: 235.27001953125px;
  left: 575.208984375px;
  width: 118.93798828125px;
  height: 47.84410095214844px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text44 {
  top: 28.905805587768555px;
  left: -0.000006343391760310624px;
  color: var(--dl-color-colores_neutrales-90);
  width: 119px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text46 {
  color: var(--dl-color-colores_neutrales-90);
  width: 32px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288969 {
    top: 173.4716796875px;
    left: 91.25390625px;
    width: 442px;
    height: 322px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    border-right: 4px solid darkgrey;
    background-color: transparent;
}
.overlayverdetalledeseguro-text48 {
  top: -0.000007382754574791761px;
  left: 2.0506532192230225px;
  color: var(--dl-color-colores_neutrales-90);
  width: 49px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text50 {
  top: -0.000007382754574791761px;
  left: 253.25588989257812px;
  color: var(--dl-color-colores_neutrales-90);
  width: 145px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text52 {
  top: 111.63624572753906px;
  left: 253.25588989257812px;
  color: var(--dl-color-colores_neutrales-90);
  width: 200px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text54 {
  top: 56.81487274169922px;
  left: 253.25588989257812px;
  color: var(--dl-color-colores_neutrales-90);
  width: 200px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text56 {
  top: 56.814857482910156px;
  left: 2.0506532192230225px;
  color: var(--dl-color-colores_neutrales-90);
  width: 133px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text58 {
  top: 111.63622283935547px;
  left: 2.0506532192230225px;
  color: var(--dl-color-colores_neutrales-90);
  width: 172px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text60 {
  top: 168.4511260986328px;
  left: 251.20523071289062px;
  color: var(--dl-color-colores_neutrales-90);
  width: 156px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text62 {
  top: 166.45758056640625px;
  left: -0.00000331828687194502px;
  color: var(--dl-color-colores_neutrales-90);
  width: 196px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-group288971 {
  top: 150px;
  left: 89px;
  width: 711.7804565429688px;
  height: 312.8598937988281px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.overlayverdetalledeseguro-text64 {
  left: 555.9306640625px;
  color: var(--dl-color-colores_neutrales-90);
  width: 420px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text66 {
  top: 95.7568359375px;
  left: 557.9814453125px;
  color: var(--dl-color-colores_neutrales-90);
  width: 420px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text68 {
  top: 47.90087890625px;
  left: 557.9814453125px;
  color: var(--dl-color-colores_neutrales-90);
  width: 420px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text70 {
  top: 143.61279296875px;
  left: 555.9306640625px;
  color: var(--dl-color-colores_neutrales-90);
  width: 156px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text72 {
  top: 191.46875px;
  left: 555.9306640625px;
  color: var(--dl-color-colores_neutrales-90);
  width: 156px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text74 {
  top: 239.32470703125px;
  left: 555.9306640625px;
  color: var(--dl-color-colores_neutrales-90);
  width: 156px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-text76 {
  top: 287.1806640625px;
  left: 555.9306640625px;
  color: var(--dl-color-colores_neutrales-90);
  width: 156px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.overlayverdetalledeseguro-frame288930 {
  top: 1.8598804473876953px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.overlayverdetalledeseguro-text78 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text80 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text82 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text84 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text86 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text88 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 25px;
  text-decoration: none;
}
.overlayverdetalledeseguro-text90 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.overlayverdetalledeseguro-usosdebotones1 {
  top: 58px;
  left: 833px;
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}
.overlayverdetalledeseguro-text92 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.overlayverdetalledeseguro-usosdebotones2 {
  top: 420px;
  left: 800px;
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;

}
.overlayverdetalledeseguro-usosdebotones3 {
  top: 420px;
  left: 540px;
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;

}
.overlayverdetalledeseguro-text94 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-icon-btns {
  top: 20px;
  left: 650px;
  width: 52px;
  height: 52px;
  position: absolute;
}
</style>
