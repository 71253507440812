<template>

  <nav>
    <v-row class="valores" v-if="valores">
      <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
        <div class="valor-uf-37-232">Valor UF: {{ ufActual }}</div>
      </v-col>

      <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
        <div class="ltima-conexi-n-en-la-web-24-abril-2024-23-30-hrs">
          Última conexión en la web: {{ fechaAcceso }}.
        </div>
      </v-col>
    </v-row>
    <v-app-bar color="#f0f2f2" dark prominent class="navapp bannerlogo" :class="{ 'nofondo': !fondo }">
      <div class="menu-m ">
        <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" class="menu-m"></v-app-bar-nav-icon>
      </div>

      <!-- <v-toolbar-title>My files</v-toolbar-title> -->
      <v-toolbar-title class="toolsStyle">
        <img @click="home" src="/assets/login/logotipo-mueve-seguro-con-bajada-30.png"
          alt="LogotipoMueveSeguroconbajada31318" class="logotipo-mueve-seguro-con-bajada-3">
        <!-- <img class="logotipo-mueve-seguro-con-bajada-3" src="logotipo-mueve-seguro-con-bajada-30.png"> -->
      </v-toolbar-title>
      <!-- <v-spacer></v-spacer> -->
      <div class="bienvenido-a-mi-portal-seguro">
        {{ titulo }}
      </div>
      <div class="menu-t">
        <div class="d-flex flex-column flex-md-row justify-end ">


          <v-btn text class="mr-1 btnhelp">
            <div class="group-43">
              <img class="group-42" src="/assets/login/group-420.svg">
              <div class="centro-de-ayuda">Centro de ayuda</div>
            </div>
          </v-btn>
          <img class="icon-chile" src="/assets/login/icon-chile0.svg">
          <v-list-group class="userAction" :value="false" v-if="menuPrivadoCab">

            <template v-slot:activator>
              <v-icon>mdi-account-circle</v-icon>
            </template>

            <!-- <v-list-group
              :value="false"
              no-action
              sub-group
              class="userTool"
            > -->
            <div class="userTool" >
              <v-list-item class="listOpciones" v-for="item in admins" :key="item">
                <v-list-item-title @click="menuPrivado(item[2])" class="btnMenu">{{ item[0] }}</v-list-item-title>
              </v-list-item>
            </div>

          </v-list-group>
          <!-- </v-list-group> -->

        </div>
      </div>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
  
      <v-btn icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
  
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute class="indices">
      <v-list nav>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <v-list-item v-if="menuPrivadoCab">
            <v-list-item-title @click="menuPrivado(10)">Mis datos personales</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="menuPrivadoCab">
            <v-list-item-title @click="menuPrivado(11)">Cambia a perfil empresa</v-list-item-title>
          </v-list-item>
                    
          <v-list-item v-if="!menuPrivadoCab">
            <v-list-item-title>Beneficios</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!menuPrivadoCab">
            <v-list-item-title @click="como">¿Cómo funciona?</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!menuPrivadoCab">
            <v-list-item-title @click="preguntas">Preguntas frecuentes</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!menuPrivadoCab">
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="menuPrivadoCab">
            <v-list-item-title @click="menuPrivado(13)">Cerrar Sesion</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!menuPrivadoCab">
            <v-list-item-title style="display:flex;justify-content:center"><v-btn outlined rounded
                class="mr-1 btntop"><span
                  class="home-text txtbnt"><span>Ingresar</span></span></v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="display:flex;justify-content:center"><v-btn rounded class="mr-1 txtbnt2"><span
                  class="home-text txtbnt3"><span>Calcula tu devolución</span></span></v-btn></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
      admins: [
        ['Mis datos personales', 'mdi-circle', 10],
        ['Cambia a perfil empresa', 'mdi-circle', 11],
        ['Cerrar Sesion', 'mdi-circle', 13],
      ],
      //     admins: [
      //   ['Management', 'mdi-account-multiple-outline'],
      //   ['Settings', 'mdi-cog-outline'],
      // ],
    }
  },
  props: {
    titulo: "",
    fondo: true,
    valores: false,
    ufActual: "",
    fechaAcceso: "",
    menuPrivadoCab: false
  },
  methods: {
    cerrar: function(){
        this.$store.commit("setislogin", false);
      localStorage.removeItem("loginEstado");
      localStorage.removeItem("loginCliente");
      this.$router.push('/');
      // location.href = "https://w3.mueveseguro.cl";
      },
    menuPrivado(op) {
      if(op==13){
        this.cerrar();
      }else{
        this.$root.$refs.HomePrivado.cambiaMenu(op);
      }      
    },
    home() {
      this.$router.push("/");
    },
    como() {
      location.href = "#cfunciona";
    },
    somos() {
      location.href = "/nosotros";
    },
    preguntas() {
      this.$router.push("/preguntas");
    },
    login() {
      location.href = "/login";
    },
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<style scoped>
.btnMenu {
  cursor: pointer;
}

.colcenter {
  display: flex;
  justify-content: center;
}

.nofondo {
  background-image: none !important;
  background-color: transparent !important;
}

.navapp {
  box-shadow: none !important;
  height: 100px !important;

}

.bannerlogo {
  background-image: url('/assets/login/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 0px !important;
  height: 100%;
}

.navapp .v-toolbar__content {
  height: 100px !important;

}

.menu-m {
  display: none !important;

}

.menu-t {
  display: unset !important;
  margin-top: 20px;
  width: 100%;
}

.mdi-menu {
  margin-top: 45px !important;
  font-size: 53px !important;
  color: rgba(4, 19, 127, 1) !important;
}

.home-logotipo-mueve-seguroconbajada3 {
  width: 325px;
  cursor: pointer;
}

.HeadingsH6 {
  font-size: 15px;
  font-style: normal;
  font-family: Circular Pro;
  font-weight: 300px;
  font-stretch: normal;
  letter-spacing: 0em;
  margin-top: 21px !important;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

.btntop {
  margin-top: 3px;
}

.txtbnt2 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgba(4, 19, 127, 1) !important;
}

.txtbnt3 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgba(4, 19, 127, 1) !important; */
  color: white !important;
}

.tbarNav {
  /* 
    padding: 0px 40px 0px 40px; */
  display: flex;
  flex-direction: row;
  /* gap: 80px; */
  align-items: flex-start;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}


@media only screen and (max-width: 800px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 30px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bienvenido-a-mi-portal-seguro {
    display: none !important;
  }

  .bannerIz {
    /* padding: 50px; */
  }

  .valores {
    display: none !important;
  }

  .bannerCn {
    /* padding: 80px; */
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  .piggy-1 {
    flex-shrink: 0;
    width: 388px;
    height: 388px;
    /* position: relative; */
    object-fit: cover;
    align-content: center;
    margin-top: 0px !important;

  }



  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;


  }
}

@media only screen and (max-width: 1380px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 35px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bannerIz {
    padding: 20px;
    padding-top: 50px;
  }

  .menu-t {
    display: none !important;
  }

  .menu-m {
    display: unset !important;
    margin-top: -5px;
    color: white;
  }

  .mdi-menu {
    color: white !important;
  }

  .rotateimage {
    display: none !important;
  }

  .circuloiz {
    display: none !important;
  }

  .circulodr {
    display: none !important;
  }

  .indices {
    z-index: 1000;
  }


}

.vtoolNav {
  height: 100px !important;
}

.v-toolbar__content {
  height: 100px !important;
}

.logotipo-mueve-seguro-con-bajada-3 {
  flex-shrink: 0;
  width: 290px;
  height: 115px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.userTool {
  z-index: 1000;
  /* margin-top: 28px; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgb(4 112 203);

}

.icon-chile {
  flex-shrink: 0;
  width: 43px;
  height: 25px;
  position: relative;
  overflow: visible;
  margin-top: 12px;
}

.group-43 {
  flex-shrink: 0;
  width: 220px;
  height: 28px;
  position: static;
  margin-top: 10px;
}

.group-42 {

  /* width: 5.88%; */
  /* height: 71.43%; */
  position: absolute;
  right: 87.88%;
  /* left: 3.24%; */
  /* bottom: 14.29%; */
  top: 21.29%;

  overflow: visible;
}

.btnhelp {
  margin-top: 10px;
}

.btnhelp ::before {
  border-radius: 20px;
}

.userAction {
  border-radius: 20px !important;
}

.userAction ::before {
  border-radius: 20px !important;
}

.bienvenido-a-mi-portal-seguro {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 35px);
  line-height: var(--headings-h2-line-height, 48px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 22px;

}

.toolsStyle {
  width: 100%;
}

.LogotipoMueveSeguroconbajada31318 {
  cursor: pointer;
}

.valores {
  /* margin-top: 3px; */
  background-color: white;
}

.valor-uf-37-232 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 16px);
  font-weight: var(--text-font-weight, 400);
  position: relative;

  width: 100%;
  /* height: 40px; */
  display: flex;
  align-items: left;
  justify-content: left;
  margin-left: 20px;
  padding-top: 10px;
}

.teaser-ms-02-1 {
  width: 100%;
  height: 40px;
  position: relative;
  font-size: var(--text-font-size, 14px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);

}

.ltima-conexi-n-en-la-web-24-abril-2024-23-30-hrs {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 16px);
  font-weight: var(--text-font-weight, 400);
  position: relative;

  width: 100%;
  /* height: 40px; */
  display: flex;
  align-items: right;
  justify-content: right;
  margin-right: 20px;
  padding-top: 10px;
}

.listOpciones {
  z-index: 1000;
}
</style>