<template>
    <div style="height: 0px;">
    <div class="frame67persona-formulario-lneadesgravamen-frame515">
        <div class="frame67persona-formulario-lneadesgravamen-frame514" >
          <span class="frame67persona-formulario-lneadesgravamen-text02">
            <span v-if="!clienteExiste">Gracias por obtener tu línea de Desgravamen</span>
            <span v-if="clienteExiste">Según nuestros registros, Ya cuentas con una línea de desgravamen</span>
          </span>
        </div>
        <span
          class="frame67persona-formulario-lneadesgravamen-text04 Roboto_H4_3242"
        >
          <span v-if="!clienteExiste">Ahora te envíamos un correo para validar tu acceso.</span>
        </span>
      </div>
      <div class="frame67persona-formulario-lneadesgravamen-group512">
        <span class="frame67persona-formulario-lneadesgravamen-text06">
          <span>“Dale al botón siguiente”</span>
        </span>
        <img
          src="/playground_assets/seguros/linea/rectangle3951251-4vgb-700w.png"
          alt="Rectangle3951251"
          class="frame67persona-formulario-lneadesgravamen-rectangle395"
        />
        <img
          src="/playground_assets/seguros/linea/rectangle3981251-2t44.svg"
          alt="Rectangle3981251"
          class="frame67persona-formulario-lneadesgravamen-rectangle398"
        />
        <div class="frame67persona-formulario-lneadesgravamen-group288884">
          <span
            class="frame67persona-formulario-lneadesgravamen-text08 FontProductH4"
          >
            <span>
              <span>
                ¿Quieres cotizar tu
                <span v-html="rawp9xr"></span>
              </span>
              <br />
              <span>Seguro de Desgravamen?</span>
            </span>
          </span>
          <img
            src="/playground_assets/seguros/linea/iconbtns1251-egf7.svg"
            alt="IconBtns1251"
            class="frame67persona-formulario-lneadesgravamen-icon-btns3"
          />
        </div>
      </div>
      <!-- <div class="frame67persona-formulario-lneadesgravamen-usosdebotones">
        <div class="frame67persona-formulario-lneadesgravamen-usosdebotones1">
            
          <span class="frame67persona-formulario-lneadesgravamen-text13">
            <span>Volver al incio</span>
          </span>
        </div>
      </div> -->
      <v-app class="ctrans" style="height: 0px;">
      <v-btn color="juanito" class="rounded-pill " x-large
      style="top: 837px;width: 250px;text-transform: unset !important;margin-left: 710px;" 
      @click="inicio">
                    <span class="frame67persona-formulario-lneadesgravamen-text13">
                        <span>Volver al incio</span>
                    </span>
      </v-btn>
    </v-app>
      <span class="frame67persona-formulario-lneadesgravamen-text15">
        <span>Si no recibiste el correo, por favor haz clic aquí</span>
      </span>
      <!-- <img
        src="/playground_assets/seguros/linea/rectangle24321670-jlre6-200h.png"
        alt="Rectangle24321670"
        class="frame67persona-formulario-lneadesgravamen-rectangle2432"
      /> -->
    </div>
</template>
<script>
export default {
  name: 'Frame67PersonaFormularioLneadesgravamen',

  data() {
    return {
      rawp9xr: ' ',
      
    }
  },
  props :{
    clienteExiste: false
  },
  methods:{
    inicio: function() {
        this.$router.push('/home');
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame67persona-formulario-lneadesgravamen-container {
  min-height: 100vh;
}
.frame67persona-formulario-lneadesgravamen-frame67persona-formulario-lneadesgravamen {
  width: 100%;
  height: 950px;
  display: flex;
  overflow: hidden;
  /* position: relative; */
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame67persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2 {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 281px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-rectangle39 {
  top: 218px;
  left: 42px;
  width: 1357px;
  height: 630px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame67persona-formulario-lneadesgravamen-group495 {
  top: 62px;
  left: 148px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group {
  top: 15.025447845458984px;
  left: 105.18211364746094px;
  width: 149.81614685058594px;
  height: 47.98341751098633px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group1 {
  top: 0.00005686227814294398px;
  left: 44.20986557006836px;
  width: 54.99390411376953px;
  height: 19.948720932006836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector {
  top: 3.23506498336792px;
  left: -0.00009430493082618341px;
  width: 10px;
  height: 17px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector01 {
  top: 0.00005686227814294398px;
  left: 11.323002815246582px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector02 {
  top: 6.4695048332214355px;
  left: 17.79131317138672px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector03 {
  top: 6.4695048332214355px;
  left: 30.731914520263672px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector04 {
  top: 6.4695048332214355px;
  left: 43.67137908935547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group2 {
  top: 25.878984451293945px;
  left: -0.000016696129023330286px;
  width: 143.41653442382812px;
  height: 22.104625701904297px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector06 {
  top: 3.7734971046447754px;
  left: 14.556951522827148px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector07 {
  top: 3.7734971046447754px;
  left: 27.4975528717041px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector08 {
  top: 3.7734971046447754px;
  left: 38.817806243896484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector09 {
  top: 3.7734971046447754px;
  left: 52.29852294921875px;
  width: 9px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector10 {
  top: 3.7734971046447754px;
  left: 60.92502212524414px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector11 {
  top: 3.7734971046447754px;
  left: 73.32494354248047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector12 {
  top: 3.7734971046447754px;
  left: 85.72599792480469px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector13 {
  top: 3.7734971046447754px;
  left: 99.20443725585938px;
  width: 18px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector14 {
  top: 3.7734971046447754px;
  left: 118.6139144897461px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector15 {
  top: 3.7734971046447754px;
  left: 131.55508422851562px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group3 {
  top: 24.742467880249023px;
  left: 144.1051025390625px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group4 {
  top: 1.4308146238327026px;
  left: 1.859254240989685px;
  width: 2.3796639442443848px;
  height: 2.8555972576141357px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 77.92008209228516px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector18 {
  top: 1.3724621534347534px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector19 {
  top: -9.094947017729282e-13px;
  left: 24.692947387695312px;
  width: 53px;
  height: 78px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-text {
  top: 57px;
  left: 1103px;
  color: var(--dl-color-gray-white);
  width: 158px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-group288877 {
  top: 83px;
  left: 1075px;
  width: 219px;
  height: 48px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-rectangle3 {
  top: 23px;
  left: 125.72802734375px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-rectangle4 {
  top: 23px;
  left: 38px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group521 {
  top: 0px;
  left: 0px;
  width: 219px;
  height: 48px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-btns {
  top: 5px;
  left: 0px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-icon-btns1 {
  top: 5px;
  left: 88px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 171px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck2 {
  top: 9.962783813476562px;
  left: 9.962167739868164px;
  width: 28px;
  height: 28px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-frame515 {
  top: 307px;
  /* left: 256px; */
  width: 929px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  margin-left: -150px;
}
.frame67persona-formulario-lneadesgravamen-frame514 {
  top:-23px;
  display: flex;
  padding: 10px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}
.frame67persona-formulario-lneadesgravamen-text02 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 45px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-text04 {
  top:62px;
  color: var(--dl-color-colores_neutrales-90);
  width: 817px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group512 {
  top: 616px;
  /* left: 59px; */
  width: 1322px;
  height: 199px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -350px;
}
.frame67persona-formulario-lneadesgravamen-text06 {
  top: 115px;
  /* left: 765px; */
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 31px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-rectangle395 {
    top: 1px;
    left: 0px;
    margin-left: 16px;
    width: 649px;
    height: 184px;
    position: absolute;
    border-color: transparent;
    border-radius: 0 16px 0 20px;
}
.frame67persona-formulario-lneadesgravamen-rectangle398 {
    top: -15px;
    left: 640px;
    width: 666px;
    height: 216px;
    position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group288884 {
  top: 40px;
  left: 689px;
  width: 537px;
  height: 120px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-text08 {
  top: 18px;
  color: var(--dl-color-colores_paleta-tonos_azul-0);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 42px;
}
.frame67persona-formulario-lneadesgravamen-icon-btns3 {
  top: 0px;
  left: 417px;
  width: 120px;
  height: 120px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones {
  top: 863px;
  left: 1072px;
  width: 220px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}
.frame67persona-formulario-lneadesgravamen-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-text15 {
  top: 534px;
  /* left: 473px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 82px;
}
.frame67persona-formulario-lneadesgravamen-rectangle2432 {
  right: 7px;
  width: 98px;
  bottom: 20px;
  height: 75px;
  position: absolute;
  border-color: transparent;
}
</style>
