import { render, staticRenderFns } from "./PreaprobacionCotizacion.vue?vue&type=template&id=f1baff52&scoped=true&"
import script from "./PreaprobacionCotizacion.vue?vue&type=script&lang=js&"
export * from "./PreaprobacionCotizacion.vue?vue&type=script&lang=js&"
import style0 from "./PreaprobacionCotizacion.vue?vue&type=style&index=0&id=f1baff52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1baff52",
  null
  
)

export default component.exports