<template>
<div class="frame21persona-formulario-lneadesgravamen-imputformulariou2">
    <div>
      
        <v-form ref="form" v-model="valid" >
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
              <v-menu ref="menu" 
              v-model="menu" 
              :close-on-content-click="false" 
              transition="scale-transition"
              offset-y
              
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }" >
                  <v-text-field 
                  v-model="form2.date" 
                  v-bind="attrs" 
                  @blur="date = parseDate(form2.date)"
                  v-on="on" 
                  label="Fecha de nacimiento" 
                  append-icon="mdi-check-circle"
                    readonly   filled dense required 
                    :rules="dateRules" color="rgb(56,0,151)" @update:error="vdate">
                   
                    <template v-slot:append>
                    <v-btn
                    class="cal"
                    icon
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter="calendario"
                    >
                    <v-icon color="m100" v-if="!validdate" 
                        style="margin-bottom: 10px !important ;">
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-icon v-if="validdate"   style="margin-bottom: 10px !important ;">
                        mdi-calendar-cursor
                      </v-icon>
                    </v-btn>
                  </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" color="rgba(140, 87, 245, 1)" no-title  @change="save" required></v-date-picker>
              </v-menu>
               </v-col>
              <v-col cols="12" sm="12">
              <v-text-field v-model="form2.email" :rules="emailRules" label="Correo" required filled dense 
                append-icon="mdi-check-circle" class="w100" color="rgb(56,0,151)" @update:error="vemail" @keypress="vemailp()">
                    <template v-slot:append>
                      <div v-if="validemail==`no`"></div>
                      <v-icon  color="m100" v-else-if="!validemail" 
                        style="margin-bottom: 10px !important ;">
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-icon color="red" v-else="validemail"
                      style="margin-bottom: 10px !important ;">
                      mdi-close-circle
                      </v-icon>
                    </template>
              </v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="form2.celular" :rules="celularRules" label="Celular" required filled dense
                   append-icon="mdi-check-circle" class="w100"  color="rgb(56,0,151)" @update:error="vcelular" @keypress="vcelularp()">
                      <template v-slot:append>
                        <div v-if="validcelular==`no`"></div>
                        <v-icon color="m100" v-else-if="!validcelular" 
                          style="margin-bottom: 10px !important ;">
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon color="red"  v-else="validcelular" 
                        style="margin-bottom: 10px !important ;">
                        mdi-close-circle
                        </v-icon>
                      </template>
                  </v-text-field>
                </v-col>

            
                <!-- <v-dialog v-model="antessalir" width="740px" hide-overlay>
                    <template v-slot:activator="{ on }"> -->
                      <v-btn color="grey darken-1 " fab x-large style="left: -200px;"  @click="volver" >
                        <v-icon color="rgba(255, 255, 255)" size="70">
                          mdi-arrow-left
                        </v-icon>
                      </v-btn>
                    <!-- </template>
                    <AntesSalir style="overflow-y:hidden !important" v-bind:origen="`1`"></AntesSalir>
                  </v-dialog> -->
                  

                  <v-btn color="juanito" class="rounded-pill " x-large
                    style="left: 500px  ;top: 0px;width:250px;text-transform: unset !important;" @click="validate"
                    :disabled="!valid">
                    <span class="frame644persona-formulario-lneadesgravamen-text10">
                      <span>Buscar plan</span>
                    </span>
                    <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                  </v-btn>

                  <v-btn color="transparent"  text
                    style="left: 570px  ;top: -11px;width:250px;text-transform: unset !important;" 
                    :disabled="!valid">
                    <span class="frame644persona-formulario-lneadesgravamen-text08">
                      <span>¿Necesitas ayuda?</span>
                    </span>
                    <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                  </v-btn>
                  
                  <!-- <div class="frame644persona-formulario-lneadesgravamen-group288896">
                    <span class="frame644persona-formulario-lneadesgravamen-text08">
                      <span>¿Necesitas ayuda?</span>
                    </span>
                  </div> -->
                </v-row>
          </v-container>
        </v-form>
     
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import datausuario from "../../../../services/usuario-datos";
export default {
  name: "FormularioLinea2",
  components: {

  },
  data() {
    return {
      date: '',
      dateCompara: "",
      overlay: false,
      validdate: true,
      validemail: "no",
      validcelular:"no",
      form2: {
        email: this.$store.state.form2.email,
        celular: this.$store.state.form2.celular,
        date: this.$store.state.form2.date,
      },
      antessalir: "",
      valid: false,
      emailRules: [
        v => !!v || 'el campo Correo es requerido',
        (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
        v => /.+@.+\..+/.test(v) || 'Ingrese un correo válido',
        (v) => (this.validaMail(v)) || "largo mínimo de dominio 3 caracteres",
         
      ],
      celularRules: [
        (v) => (this.formatPhoneNumber(v)) || "formato inválido",
        (v) => !!v || "el campo Celular es requerido",
        (v) => (v && v.length == 16) || "número de celular máximo 9 caracteres",       
      ],
      dateRules: [
        (v) => !!v || "el campo Fecha de nacimiento es requerido",
        (v) => (this.calcularEdad(v)>=18) || "el usuario tiene que ser mayor de edad",
      ],
      select: null,
      activePicker: null,

      menu: false,
    };
  },
  methods: {
   
    calendario(){
      var cl = document.querySelector(".fecnac");
      var el = document.querySelector(".cal");
      if(!cl){
        
        // el.click();
      }
      console.log(el);
      el.click();
    },
    formatPhoneNumber(phone)  {
      if(phone && phone!=""){
      if(phone && phone.length>16){
        phone=phone.substring(0, phone.length - 1);
      } 
      let celular="(+56) 9 ";
      if(phone.length<=7){
        this.form2.celular=celular;
        return;
      }
      phone=phone.replace("(+56) 9 ","");
      let letra="";
      for (let index = 0; index < phone.length; index++) {
        letra=phone.charAt(index).replace(/[^0-9]/gi,"");
          celular=celular + letra;
      }
      this.form2.celular=celular;
      
    }
    return true;
  },

  validaMail(email){
      if(!email || email==""){
        return;
      }
      var revmail=email.split("@");
      if(revmail.length==2){
          if(revmail[1].length>=5){
            return true;
          }
          return false;
      }
    //   var revmail=email.split("@");
    //   if(revmail.length==2){
    //     var dominio=revmail[1].split(".");
    //     if(dominio.length==2){
    //       if(dominio[0].length>=3){
    //         return true;
    //       }
    //       return false;
    //   }
    //  }
    },
    calcularEdad(fecha_nacimiento) {
      if(!fecha_nacimiento){
        return;
      }
      fecha_nacimiento=this.parseDate(fecha_nacimiento);
      
        var hoy = new Date();
        var cumpleanos = new Date(fecha_nacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
      
        return edad;
    },
    async guardaUsuario() {
      console.log("guardar ususususususus");
      this.$root.$refs.PreaprobacionLinea.overlay =true;
      this.overlay=true;
      let res = false;
      var tipo=1
      if(this.$store.state.tipoPersonaEmpresa=="empresa"){
       tipo=2; 
      }
      
      var rutCompleto = this.$store.state.form1.rut.split(".").join("");
      rutCompleto = rutCompleto.replace(/\./g, "");
      rutCompleto=rutCompleto.split("-");
      var rut=parseInt(rutCompleto[0]);
      var dv=rutCompleto[1];
      var cel=this.$store.state.form2.celular;
      cel = cel.replace("(","");
      cel = cel.replace(")","");
      cel = cel.replace(" ","");
      cel = cel.replace(" ","");
      var usuario={
        per_tipo_cliente: tipo,
        personaJuridica: {

        },
        personaRepresentante: {
          per_rut:rut,
          per_dv:dv,
          per_tipo_cliente:tipo,
          per_nombre_principal:this.$store.state.form1.nombre1,
          per_nombre_segundo:this.$store.state.form1.nombre2,
          per_ape_paterno:this.$store.state.form1.apaterno,
          per_ape_materno:this.$store.state.form1.amaterno, 
          per_fec_nac:this.parseDate(this.$store.state.form2.date),
          per_celular:cel,
          per_fono:"",
          per_email:this.$store.state.form2.email,
          per_sexo:1,
          per_estado:1,
        }
      };
      // var formulario = Object.assign({}, this.$store.state.form1, this.$store.state.form2);
      res = await datausuario.guardarsimulacion(usuario);
      console.log(res);
      if(res.status==200 && res.data.estadoTransaccion.success){
          this.$alertas(res.data.estadoTransaccion.errors,"success");   
            this.$store.commit('setsimulacion', res.data.simulacion);
            var propuesta=this.$store.state.simulacion.sim_monto ; 
            console.log(propuesta);
            // this.$store.commit('setpropuesta', propuesta);
            this.$store.commit('setestadodps', res.data.simulacion.sim_con_dps);
            this.$store.commit('setplan', res.data.simulacion.sim_plan);
            this.$root.$refs.PreaprobacionLinea.propuesta=propuesta;
            this.$root.$refs.PreaprobacionLinea.traeDps();
            this.$root.$refs.PreaprobacionLinea.traeTerminos();
            this.$root.$refs.PreaprobacionLinea.overlay =false;
            this.overlay=false;
            this.$root.$refs.FormularioLinea.estadoFormLinea = 3;
      }else{
        this.$root.$refs.PreaprobacionLinea.overlay =false;
        this.overlay=false;
        this.$alertas(res.data.estadoTransaccion.errors,"info"); ;
      }
      console.log(this.$store.state.simulacion);
      
      
      // this.$store.commit("setform1", res.data.usuario);
      // this.$alertas(res);
    },
    vdate(v) {
      this.validdate = v;
    },
    vemail(v) {
      this.validemail = v;
    },
    vcelular(v) {
      this.validcelular = v;
    },
    vemailp(){
        if(this.validemail=="no"){ 
            this.validemail=true;
        }
    },
    vcelularp(){
        if(this.validcelular=="no"){ 
            this.validcelular=true;
        }
    },
    validaRut: function (rutCompleto) {
      if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    btnleft: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-left");
      }, 150);
    },
    btnleftout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-left");
      }, 150);
    },
    btnright: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-right");
      }, 150);
    },
    btnrightout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-right");
      }, 150);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.commit('setform2', this.form2);
        this.guardaUsuario();
        
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save(date) {
      // date=this.formatDate(date);
      this.$refs.menu.save(date);
    },
    formatDate  (date) {
      if (!date) return null

      var arr= date.split('-'),
               year = arr[0],
               month =  arr[1],
               day=  arr[2];
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      this.dateCompara=date;
      if (!date) return null

       var arr= date.split('/'),
                day = arr[0],
                month =  arr[1],
                year =  arr[2];
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    volver() {
      this.$root.$refs.FormularioLinea.estadoFormLinea = 1;
    },
    formatDate:function  (date) {
      if (!date) return null

      var arr= date.split('-'),
               year = arr[0],
               month =  arr[1],
               day=  arr[2];
      return `${day}/${month}/${year}`
    },
    parseDate:function  (date) {
      if (!date) return null

       var arr= date.split('/'),
                day = arr[0],
                month =  arr[1],
                year =  arr[2];
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  computed: {
     computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  watch: {
    date (val) {
      this.form2.date = this.formatDate(this.date)
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
      // this.validdate = this.form2.date.length ? false : true;
      // this.validemail = this.form2.email.length ? false : true;
      // this.validcelular = this.form2.celular.length ? false : true;
     
  },
  created() {
    this.$root.$refs.LineaForm2 = this;
  },
  // watch: {
  //   match: validateField,
  //   max: validateField,
  //   model: validateField,
  // },
  metaInfo: {
    title: 'Mueve Seguro',
  },
};
</script>
<style scoped>
.v-date-picker-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1;
    height: 24px;
    border: 0px;
}
.theme--light.v-card {
    background-color: transparent !important;
    color: rgba(0,0,0,.87);
}
.frame644persona-formulario-lneadesgravamen-text08 {
  top: 70px;
  left: 300px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;

  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame21persona-formulario-lneadesgravamen-imputformulariou2 {
  top: 410px;
  /* left: 457px; */
  width: 500px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  margin-left: 60px;
}
.frame644persona-formulario-lneadesgravamen-text10 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.v-menu__content {
    top: 477px !important;
}
</style>