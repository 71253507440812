<template>
  <div class="frame21persona-formulario-lneadesgravamen-imputformularioe2">

      <div>
         
              <v-form ref="form" v-model="valid" >
                 
                      <v-row dense>
                           <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-text-field v-model="form1.rut" :rules="rules" label="RUT REPRESENTANTE LEGAL" required class="rut"
                                    rounded dense outlined  @update:error="vrut" color="rgb(56,0,151)" @keypress="vrutp()"
                                    
                                    >
                                    <template v-slot:append>
                                        <div v-if="validrut==`no`"></div>
                                        <v-icon v-else-if="!validrut" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validrut" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                          </v-col>
                          <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                              <v-menu ref="menu" 
                              v-model="menu" 
                              :close-on-content-click="false" 
                              transition="scale-transition"
                              offset-y
                            
                                max-width="290px"
                                min-width="290px">
                                <template 
                                v-slot:activator="{ on, attrs }" 
                                >
                                  <v-text-field v-model="form1.date" append-icon="mdi-check-circle"
                                  id="cal-idecom"
                                  label="FECHA DE NACIMIENTO" 
                                  placeholder="dd/mm/yyyy"
                                  @keydown="formatoFecha(form1.date)"
                                  maxlength="10"
                                    v-bind="attrs" v-on="on" class="ctrans" rounded dense outlined required 
                                    :rules="dateRules" color="rgb(56,0,151)" @update:error="vdate">
                                    
                                    <template v-slot:append>
                                    <v-btn
                                    class="cale"
                                    icon
                                    color="indigo"
                                    v-bind="attrs"
                                    v-on="on"                              
                                    >
                                   
                                    <v-icon color="rgba(0, 23, 135, 1)" v-if="!validdate" 
                                        style="margin-bottom: 10px !important ;">
                                        mdi-checkbox-marked-circle
                                      </v-icon>
                                      <v-icon v-if="validdate"   style="margin-bottom: 10px !important ;">
                                        mdi-calendar-cursor
                                      </v-icon>
                                    </v-btn>
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="date" class="fecnace" color="rgba(140, 87, 245, 1)"  no-title @change="save" required></v-date-picker>
                              </v-menu>
                          </v-col>
                           <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-text-field v-model="form1.nombre1" :rules="nombre1Rules" label="PRIMER NOMBRE"
                                    required rounded dense outlined  color="rgb(56,0,151)" @update:error="vnombre1" @keypress="vnombre1p()"
                                    >
                                    <template v-slot:append>
                                        <div v-if="validnombre1==`no`"></div>
                                        <v-icon v-else-if="!validnombre1" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre1" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                             <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-text-field v-model="form1.nombre2" :rules="nombre2Rules" label="SEGUNDO NOMBRE"  maxlength ="50"
                                    rounded dense outlined  color="rgb(56,0,151)" @update:error="vnombre2" @keypress="vnombre2p()"
                                    >
                                    <template v-slot:append>
                                       
                                        <div v-if="validnombre2==`no`"></div>
                                        <v-icon v-else-if="!validnombre2" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre2" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                             <v-col  cols="12" sm="12" md="12" lg="6" xl="6">

                                <v-text-field v-model="form1.apaterno" :rules="apaternoRules" label="APELLIDO PATERNO"
                                    required rounded dense outlined  append-icon="mdi-check-circle" color="rgb(56,0,151)"
                                    class="w100" @update:error="vapaterno" @keypress="vapaternop()"
                                    >
                                    <template v-slot:append>
                                        
                                        <div v-if="validapaterno==`no`"></div>
                                        <v-icon v-else-if="!validapaterno" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validapaterno" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                             <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-text-field v-model="form1.amaterno" :rules="amaternoRules" label="APELLIDO MATERNO"
                                    required rounded dense outlined  append-icon="mdi-check-circle" color="rgb(56,0,151)"
                                    @update:error="vamaterno" @keypress="vamaternop()"
                                    >
                                    <template v-slot:append>
                                        
                                        <div v-if="validamaterno==`no`"></div>
                                        <v-icon v-else-if="!validamaterno" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validamaterno" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>

                                </v-text-field>
                            </v-col>
                             <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-select
                                v-model="seleccionNacionalidad"
                                :items="paises.nombre"
                                required
                                rounded dense
                                outlined
                                label="NACIONALIDAD"
                                color="rgb(56,0,151)"
                                :rules="nacionalidadRules"
                                v-on:change="selectNacionalidad"
                                
                                style="cursor: pointer"
                                >
                                <template v-slot:append>
                                  <div v-if="validnacionalidad==`no`"></div>
                                        <v-icon v-else-if="!validnacionalidad" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnacionalidad" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                </template>
                                </v-select>
                            </v-col>
                             <v-col  cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-select
                                v-model="seleccionGenero"
                                :items="genero.nombre"
                                required
                                rounded dense
                                outlined
                                label="GENERO"
                                color="rgb(56,0,151)"
                                
                                :rules="generoRules"
                                v-on:change="selectGenero"
                                style="cursor: pointer"
                                >
                                <template v-slot:append>
                                    <div v-if="validgenero==`no`"></div>
                                        <v-icon v-else-if="!validgenero" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validgenero" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                </template>
                                </v-select>
                            </v-col>

                            <v-col  cols="12" sm="12" md="12" lg="12" xl="12">
                              <v-select
                              v-model="seleccionOcupacion"
                              :items="ocupaciones.nombre"
                              required
                              rounded dense
                              outlined
                              label="OCUPACIÓN"
                              color="rgb(56,0,151)"
                              :rules="ocupacionRules"
                              v-on:change="selectOcupacion"
                              style="cursor: pointer"
                              >
                              <template v-slot:append>
                                  <div v-if="validocupacion==`no`"></div>
                                  <v-icon v-else-if="!validocupacion" color="m100" 
                                      style="margin-bottom: 10px !important ;">
                                      mdi-checkbox-marked-circle
                                  </v-icon>
                                  <v-icon v-else-if="validocupacion" color="red" 
                                      style="margin-bottom: 10px !important ;">
                                      mdi-close-circle
                                  </v-icon>
                              </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="line-1"></div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="frame-32-2">
                                <v-row class="colcenter">
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn  class="sm-size" id="btn-datos-empresa-2-r" outlined rounded x-large @click="volver"><span
                                        ><span>REGRESAR</span></span></v-btn>
                                    </v-col>      
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn class="sm-size" id="btn-datos-empresa-2" rounded x-large @click="validate" color="#1d2eff" :disabled="!valid"><span
                                        ><span style="color:white">CONTINUAR</span></span></v-btn>
                                    </v-col>                 
                                </v-row>                  
                                
                                </div>
                            </v-col> 

                       
                      </v-row>
                  
              </v-form>


          

      </div>
      <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
  </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";

export default {
  name: "CotizaForme2",
  components: {
     
  },
  data() {
      return {
          date: '',
          dateCompara: "",
          overlay: false,
          validrut: "no",
          validnombre1: "no",
          validnombre2: "no",
          validapaterno: "no",
          validamaterno: "no",
          validdate: true,
          form2: "",
          validnacionalidad : false,
          validgenero : "no",
          validocupacion : "no",
          form1: {
              rut: this.$store.state.forme2.rut,
              nombre1: this.$store.state.forme2.nombre1,
              nombre2: this.$store.state.forme2.nombre2,
              apaterno: this.$store.state.forme2.apaterno,
              amaterno: this.$store.state.forme2.amaterno,
              date: this.$store.state.forme2.date,
              nacionalidad : 9,
              genero : this.$store.state.forme2.genero,
              ocupacion : this.$store.state.forme2.ocupacion,
          },
          seleccionNacionalidad : this.$store.state.seleccionNacionalidad,
          seleccionGenero : this.$store.state.seleccionGenero,
          seleccionOcupacion : this.$store.state.seleccionOcupacion,
          antessalir: "",
          valid: false,
          nombre1Rules: [
              (v) => !!v || "el nombre es requerido",
              (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
              (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
              (v) => (v && this.validarLn1(v)) || "este campo solo acepta letras",
          ],
          nombre2Rules: [
              // (v) => !!v || "el nombre es requerido",
              // (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
              // (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
              (v) => (this.validarLn2(v)) || "este campo solo acepta letras",
          ],
          apaternoRules: [
              (v) => !!v || "el apellido paterno es requerido",
              (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
              (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
              (v) => (v && this.validarLa1(v)) || "este campo solo acepta letras",
          ],
          amaternoRules: [
              (v) => !!v || "el apellido materno es requerido",
              (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
              (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
              (v) => (v && this.validarLa2(v)) || "este campo solo acepta letras",
          ],
          nacionalidadRules: [
                (v) => !!v || "el campo nacionalidad es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
          generoRules: [
                (v) => !!v || "el campo genero es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
          ],
          ocupacionRules: [
                (v) => !!v || "el campo ocupación es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
          ],
          dateRules: [
        (v) => !!v || "el campo Fecha de nacimiento es requerido",
        (v) => (v && v.length == 10) || "largo fecha 10 caracteres", 
        (v) => (this.existeFecha(v)) || "ingrese una fecha real",
        (v) => (this.calcularEdad(v)>=18) || "el usuario tiene que ser mayor de edad",
      ],
          select: null,
          activePicker: null,
          menu: false,
          imgfondo1: "/playground_assets/rectangle3829484-upr6.svg",
          imgfondo2: "/playground_assets/rectangle3829484-upr6.svg"
      };
  },
  props: {
        paises : {},
        genero: {},
        ocupaciones : {}
    },
  methods: {
    existeFecha(fecha){
      if(fecha === undefined || fecha === null){
        return;
      }
      var fechaf = fecha.split("/");
      var day = fechaf[0];
      var month = fechaf[1];
      var year = fechaf[2];
      var date = new Date(year,month,'0');
      if(year<=1920 || day>31 || month>12 || (day-0)>(date.getDate()-0) ){
            return false;
      }  
     
      return true;
    },
    formatoFecha(fecha){
      if(fecha && fecha!=""){
        var v = fecha;
       v = v.replace(/\D/g,'').slice(0, 10);
      if (v.length >= 5) {
        v= v.slice(0,2)+"/"+v.slice(2,4)+"/"+v.slice(4);
      }
      else if (v.length >= 3) {
        v= v.slice(0,2)+"/"+v.slice(2);
      }
      if (v.length ==10) {
        this.calendario();
      }
      this.form1.date= v;        
      }
        
    },
    selectNacionalidad(e) {     
     this.validnacionalidad = false;
     this.$store.commit('setseleccionnacionalidad', e);
     let i=0;
     let idx=0;
     this.paises.nombre.forEach((element) => {
       if (element == e) {
        idx=i;
       }
       i++;
     });
     this.form1.nacionalidad=this.paises.codigo[idx];
   },
   selectGenero(e) {     
     this.validgenero= false;
     this.$store.commit('setselecciongenero', e);
     let i=0;
     let idx=0;
     this.genero.nombre.forEach((element) => {
       if (element == e) {
        idx=i;
       }
       i++;
     });
     this.form1.genero=this.genero.codigo[idx];
   },
   selectOcupacion(e) {     
     let i=0;
     let idx=0;
     this.$store.commit('setseleccionocupacion', e);
     this.ocupaciones.nombre.forEach((element) => {
       if (element == e) {
        idx=i;
        this.validocupacion = false;
       }
       i++;
     });
     this.form1.ocupacion=this.ocupaciones.codigo[idx];
   },
  calendario(){
      var cl = document.querySelector(".fecnace");
      var el = document.querySelector("#cal-idecom");
      if(!cl){
        
        // el.click();
      }
      // console.log(el);
      el.click();
    },
  calcularEdad(fecha_nacimiento) {
    if(fecha_nacimiento<=0 || fecha_nacimiento=="" || fecha_nacimiento === undefined ){
        return;
      }
      fecha_nacimiento=this.parseDate(fecha_nacimiento);
      
        var hoy = new Date();
        var cumpleanos = new Date(fecha_nacimiento);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
      
        return edad;
    },
    validarLn1(){
        this.form1.nombre1=this.form1.nombre1.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.nombre1=this.form1.nombre1.replace("  "," ");
        return true;
    },
    validarLn2(){
      if(this.form1.nombre2 && this.form1.nombre2!=""){
        this.form1.nombre2=this.form1.nombre2.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.nombre2=this.form1.nombre2.replace("  "," ");
      }
        return true;
    },
    validarLa1(){
        this.form1.apaterno=this.form1.apaterno.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.apaterno=this.form1.apaterno.replace("  "," ");
        return true;
    },
    validarLa2(){
        this.form1.amaterno=this.form1.amaterno.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.amaterno=this.form1.amaterno.replace("  "," ");
        return true;
    },
  vrut(v) {
    this.validrut = v;
    if(!v){
      if(this.form1.rut.length>=11 && this.form1.rut.length<=12){
                this.buscar(this.form1.rut)
        }
        
    }
  },
  vrutp(v) {
        if(this.validrut=="no"){ 
            this.validrut=true;
        }
    },
    vnombre1p(v){
        if(this.validnombre1=="no"){ 
            this.validnombre1=true;
        }
    },
    vnombre2p(v){
        if(this.validnombre2=="no"){ 
            this.validnombre2=true;
        }
        },
        vapaternop(v){
        if(this.validapaterno=="no"){ 
            this.validapaterno=true;
        }
        },
        vamaternop(v){
        if(this.validamaterno=="no"){ 
            this.validamaterno=true;
        }
        },
      vnombre1(v) {
          this.validnombre1 = v;

      },
      vnombre2(v) {
          this.validnombre2 = v;

      },
      vapaterno(v) {
          this.validapaterno = v;

      },
      vamaterno(v) {
          this.validamaterno = v;

      },
      vdate(v) {
      this.validdate = v;
      },
      limpiar(){
          var form = {
              rut : "",
              nombre1: "",
              nombre2: "",
              apaterno: "",
              amaterno: "",
          }
    this.form1=form;
    var form2 = {
      email: "",
      celular: "",
      date: "",
    }
    this.form2=form2;
    this.$store.commit('setforme2', form);
    this.$store.commit('setform2', form2);
    this.validrut=true;
    this.validnombre1=true;
    this.validnombre2=true;
    this.validapaterno=true;
    this.validamaterno=true;
    this.$refs.form.reset();
  },
  async buscar(data) {
    if(data.length>=11 && data.length<=12){
    document.activeElement.blur();
    this.overlay =true;
    let res = false;
    data = data.replace(/\./g, "");
    res = await datausuario.traeusuario(data);
    console.log(res);
    if(res.status==200 && res.data.estadoTransaccion.success){
    var form = {
      rut : res.data.datosPersona.per_rut+"-"+res.data.datosPersona.per_dv,
      nombre1: res.data.datosPersona.per_nombre_principal,
      nombre2: res.data.datosPersona.per_nombre_segundo,
      apaterno: res.data.datosPersona.per_ape_paterno,
      amaterno: res.data.datosPersona.per_ape_materno,
      date: this.$root.$refs.CotizaForm2.formatDate(res.data.datosPersona.per_fec_nac.split("T")[0]),
      nacionalidad: res.data.datosPersona.per_pais,
      genero :  res.data.datosPersona.per_genero,
      ocupacion :  res.data.datosPersona.per_ocupacion,
    }
    this.form1=form;
    var form2 = {
      email: res.data.datosPersona.per_email,
      celular: res.data.datosPersona.per_cel,
      date: res.data.datosPersona.per_fec_nac,
      ocupacion :  res.data.datosPersona.per_ocupacion,
    }
    let i=0;
     let idx=0;
     this.genero.codigo.forEach((element) => {
       if (element == res.data.datosPersona.per_genero) {
        idx=i;
       }
       i++;
     });
     this.seleccionGenero=this.genero.nombre[idx];
     this.$store.commit('setselecciongenero', this.seleccionGenero);
     this.validgenero= false;
     console.log(this.seleccionGenero);
      i=0;
      idx=0;
     this.paises.codigo.forEach((element) => {
       if (element == res.data.datosPersona.per_pais) {
        idx=i;
       }
       i++;
     });
     this.seleccionNacionalidad=this.paises.nombre[idx];
     this.$store.commit('setseleccionnacionalidad', this.seleccionNacionalidad);
     this.validnacionalidad= false;
     console.log(this.seleccionNacionalidad);
      i=0;
      idx=0;
     this.ocupaciones.codigo.forEach((element) => {
       if (element == res.data.datosPersona.per_ocupacion) {
        idx=i;
       }
       i++;
     });
     this.seleccionOcupacion=this.ocupaciones.nombre[idx];
     this.$store.commit('setseleccionocupacion', this.seleccionOcupacion);
     this.validocupacion= false;

    this.form2=form2;
      this.$store.commit('setforme2', form);
      if(res.data.datosPersona.per_tipo_cliente==1){
        this.$alertas("El representante existe en el sistema","info");
      }else{
        this.$alertas("rut representante existe como empresa","warning");
      }
      
     } else{
      this.selectNacionalidad("CHILE");
      this.$alertas("Representante nuevo, ¡ ingrese sus datos !","success");
     }
      
      this.overlayBuscar();
    }
  },
  formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.form1.rut=rutPuntos;
        }
        ,
        validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.form1.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
            if(rutCompleto=="44444444-4"){
                return true;
            }
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
      dv: function (T) {
          var M = 0,
              S = 1;
          for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
          return S ? S - 1 : "k";
      },
      btnleft: function (name) {
          var el = document.querySelector("." + name);
          setTimeout(() => {
              el.classList.add("boton-left");
          }, 150);
      },
      btnleftout: function (name) {
          var el = document.querySelector("." + name);
          setTimeout(() => {
              el.classList.remove("boton-left");
          }, 150);
      },
      btnright: function (name) {
          var el = document.querySelector("." + name);
          setTimeout(() => {
              el.classList.add("boton-right");
          }, 150);
      },
      btnrightout: function (name) {
          var el = document.querySelector("." + name);
          setTimeout(() => {
              el.classList.remove("boton-right");
          }, 150);
      },
      validate() {
          if (this.$refs.form.validate()) {
              this.$store.commit('setforme2', this.form1);
              this.guardaUsuario();
              this.$root.$refs.CotizaForm.estadoFormCotizacion = 3;
              
          }
      },
      async guardaUsuario() {
      this.overlay =true;
      let res = false;
      var tipo=1
      if(this.$store.state.tipoPersonaEmpresa=="empresa"){
       tipo=2; 
      }
      var rutCompletoe = this.$store.state.forme1.rut.split(".").join("");
      rutCompletoe=rutCompletoe.split("-");
      var rute=parseInt(rutCompletoe[0]);
      var dve=rutCompletoe[1];
      var rutCompleto = this.$store.state.forme2.rut.split(".").join("");
      rutCompleto=rutCompleto.split("-");
      var rut=parseInt(rutCompleto[0]);
      var dv=rutCompleto[1];
    //   var cel=this.$store.state.forme2.telefono;
    //     cel = cel.replace("(","");
    //     cel = cel.replace(")","");
    //     cel = cel.replace(" ","");
    //     cel = cel.replace(" ","");
      var usuario={
        per_tipo_cliente: 2,
        personaJuridica: {
          per_rut: rute,
          per_dv: dve,
          per_tipo_cliente: 2,
          per_nombre_principal: this.$store.state.forme1.nombre1,
          per_nombre_segundo: "",
          per_ape_paterno: "",
          per_ape_materno :"",
          per_fec_nac: "2003-09-01",
          per_celular: this.$store.state.forme1.telefono,
          per_fono: this.$store.state.forme1.telefono,    
          per_email: this.$store.state.forme1.email,
          per_estado: 1,
          per_pais : this.$store.state.forme2.nacionalidad,
          per_genero : this.$store.state.forme2.genero,
          per_ocupacion : this.$store.state.forme2.ocupacion,
        },
        personaRepresentante: {
        per_rut:rut,
        per_dv:dv,
        per_tipo_cliente:2,
        per_nombre_principal:this.$store.state.forme2.nombre1,
        per_nombre_segundo:this.$store.state.forme2.nombre2,
        per_ape_paterno:this.$store.state.forme2.apaterno,
        per_ape_materno:this.$store.state.forme2.amaterno, 
        per_fec_nac:this.parseDate(this.$store.state.forme2.date),
        per_celular: this.$store.state.forme1.telefono,
        per_fono:this.$store.state.forme1.telefono,
        per_email:"usuario@empresa.cl",
        per_estado:1,
        per_pais : this.$store.state.forme2.nacionalidad,
        per_genero : this.$store.state.forme2.genero,
        per_ocupacion : this.$store.state.forme2.ocupacion,
      }
    };
      // var formulario = Object.assign({}, this.$store.state.form1, this.$store.state.form2);
      res = await datausuario.guardarsimulacion(usuario);
      console.log(res);
      if(res.status==200){
        this.$alertas(res.data.estadoTransaccion.errors,"success");
            if(res.data.estadoTransaccion.success){  
                this.$store.commit('setsimulacion', res.data.simulacion);
                var propuesta=this.$store.state.simulacion.sim_monto + " " + this.$store.state.simulacion.sim_tipo_moneda; 
                console.log(propuesta);
                // this.$store.commit('setpropuesta', propuesta);
                this.$store.commit('setestadodps', this.$store.state.simulacion.sim_con_dps);
                this.$store.commit('setplan', res.data.simulacion.sim_plan);
                              
            }
      }else{
        this.$alertas("En este momento no podemos atender su solicitud","warning");
      }
      this.overlay =false;
      console.log(this.$store.state.simulacion);
      // this.$store.commit("setform1", res.data.usuario);
      // this.$alertas(res);
    },
      reset() {
          this.$refs.form.reset();
      },
      resetValidation() {
          this.$refs.form.resetValidation();
      },
      save(date) {
      this.$refs.menu.save(date);
      },
      volver() {
        this.$root.$refs.CotizaForm.estadoFormCotizacion = 1;
      },
      hover: function (name, img) {
          if (img == 1) {
              this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg"
          } else {
              this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg"
          }

      },
      hoverout: function (name, img) {
          if (img == 1) {
              this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg"
          } else {
              this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg"
          }

      },
      overlayBuscar() {
      setTimeout(() => {
        this.overlay = false
        if(this.$store.state.clienteEncontrado){
          this.$root.$refs.CotizaForm.estadoFormCotizacion = 3
          this.$store.commit('setclienteencontrado', false);
        };
      }, 1000)
    },
    formatDate:function  (date) {
      if (!date) return null

      var arr= date.split('-'),
               year = arr[0],
               month =  arr[1],
               day=  arr[2];
      return `${day}/${month}/${year}`
    },
    parseDateBuscar(date){
        if (!date) return null

        var arr= date.split('-'),
                year = arr[0],
                month =  arr[1],
                day =  arr[2];
                day=day.split('T');
                day=day[0];
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    parseDate:function  (date) {
      if (!date) return null

       var arr= date.split('/'),
                day = arr[0],
                month =  arr[1],
                year =  arr[2];
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.form1.rut=rut.replace(/[^kK0-9.-]/gi,"");
      
      return true;
    },
  },
  watch: {
    date (val) {
      this.form1.date = this.formatDate(this.date)
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
      rules() {
          const rules = [];
          let rule="";
          rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
          rules.push(rule);
          rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
          rules.push(rule);
          rule = (v) => !!v || "el rut es requerido";
          rules.push(rule);
          rule = (v) => this.validaRut(this.form1.rut) || `rut inválido`;
          if(this.form1.rut!=""){
            this.formateaRut(this.form1.rut);
            }
          rules.push(rule);
          return rules;
      },
  },
  mounted() {
    
  },
  created() {
      this.$root.$refs.LineaForme2 = this;
  },
  metaInfo: {
      title: 'Mueve Seguro',
  },
};
</script>
<style scoped >

.v-date-picker-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1;
    height: 24px;
    border: 0px;
}
.theme--light.v-card {
    background-color: transparent !important;
    color: rgba(0,0,0,.87);
}
.ctrans {
background-color: transparent !important;
height: 10% !important;
flex: none !important;
}
.frame644persona-formulario-lneadesgravamen-text08 {
  top: 70px;
  left: 300px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;

  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame644persona-formulario-lneadesgravamen-text10 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame21persona-formulario-lneadesgravamen-imputformularioe2 {
/* top: 320px; */
/* left: 457px; */
width: 100%;
display: flex;
position: relative;
align-items: flex-start;
border-color: transparent;
flex-direction: column;
/* margin-left: 20px; */
}
.v-menu__content {
    top: 404px !important;
}
.frame-32-2 {
  /* background: #d9d9d9; */
  border-radius: 80px;
  padding: 9px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}
.colcenter{
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .sm-size{
    font-size: small !important;
  }
  .frame-32-2 {
    width : 120% !important;
  }
  
}
</style>
