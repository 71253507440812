<template>
    
      <nav> 
        <v-app-bar
          color="#f0f2f2"
          class="navapp"
          prominent
          plain
        >
          <v-row>
    
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display:flex;justify-content:center">
            <img src="/external/logotipomueveseguroconbajada31318-kuw-200h.png" alt="LogotipoMueveSeguroconbajada31318"
                class="home-logotipo-mueve-seguroconbajada3">
          </v-col>
          </v-row>
            
        </v-app-bar>
    
      </nav>
    
    </template>
    
    <script>
    export default {
      data() {
        return {
          
        }
      },
      watch: {
        
        },
      computed: {
        
      }
    }
    </script>
    
    <style scoped>
    .navapp{
        box-shadow: none !important; 
    }
    .v-toolbar__content{
      padding: 0px !important;
    }
    </style>