<template>
  <v-app>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex sm1 border style="border: 1px">
          <h1 style="display: none !important">1</h1>
          <div data-v-fae5bece="" class="frame1home-backgroundtoppage1">
            <!-- <img
              data-v-fae5bece=""
              alt="Rectangle378I1185"
              src="/playground_assets/rectangle378i1185-rwdd.svg"
              class="frame1home-rectangle378"
            /> -->
            <!-- <img
              data-v-fae5bece=""
              alt="unsplashhSeGxLjozsI1185"
              src="/playground_assets/unsplashhsegxljozsi1185-nfal.svg"
              class="frame1home-unsplashh-se-gx-ljozs"
            /> -->
            <img
              style="top: -8px"
              data-v-fae5bece=""
              alt="unsplashhSeGxLjozsI1185"
              src="/playground_assets/contacto/fondomedio.png"
              class="frame1home-unsplashh-se-gx-ljozs"
            />
          </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
          <div data-v-fae5bece="" class="frame1home-logo-v1" @click="irHome">
            <div data-v-fae5bece="" class="frame1home-txt">
              <div data-v-fae5bece="" class="frame1home-group">
                <img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-wjhj.svg"
                  class="frame1home-vector009"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-atb9.svg"
                  class="frame1home-vector010"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-lbss.svg"
                  class="frame1home-vector011"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-te1.svg"
                  class="frame1home-vector012"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-mdjh.svg"
                  class="frame1home-vector013"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-group001">
                <img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-2xk.svg"
                  class="frame1home-vector014"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-5rgv.svg"
                  class="frame1home-vector015"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-cq2.svg"
                  class="frame1home-vector016"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-czp6.svg"
                  class="frame1home-vector017"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-90ff.svg"
                  class="frame1home-vector018"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-43vu.svg"
                  class="frame1home-vector019"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-3wq.svg"
                  class="frame1home-vector020"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-f5j.svg"
                  class="frame1home-vector021"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-b6up.svg"
                  class="frame1home-vector022"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-j0h.svg"
                  class="frame1home-vector023"
                /><img
                  data-v-fae5bece=""
                  alt="VectorI1185"
                  src="/playground_assets/vectori1185-ouoe.svg"
                  class="frame1home-vector024"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-group002">
                <div data-v-fae5bece="" class="frame1home-group003">
                  <img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-ok3u.svg"
                    class="frame1home-vector025"
                  />
                </div>
                <div data-v-fae5bece="" class="frame1home-group004">
                  <img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-681k.svg"
                    class="frame1home-vector026"
                  />
                </div>
              </div>
            </div>
            <div data-v-fae5bece="" class="frame1home-img">
              <img
                data-v-fae5bece=""
                alt="VectorI1185"
                src="/playground_assets/vectori1185-al3k.svg"
                class="frame1home-vector027"
              /><img
                data-v-fae5bece=""
                alt="VectorI1185"
                src="/playground_assets/vectori1185-4v2c.svg"
                class="frame1home-vector028"
              />
            </div>
          </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
          <div class="frame1home-menu-desktop">
            <span class="frame1home-text048"
              ><a
                href="/home#bienvenido"
                class="beneficio"
                @mouseover="hovertxt('beneficio')"
                @mouseout="hoverouttxt('beneficio')"
                style="color: white"
                >Beneficios</a
              ></span
            >
            <img
              alt="LineI1185"
              src="/playground_assets/linei1185-q71p.svg"
              class="frame1home-line"
            />
            <span class="frame1home-text050"
              ><a
                href="/home#cfunciona"
                class="funciona"
                @mouseover="hovertxt('funciona')"
                @mouseout="hoverouttxt('funciona')"
                style="color: white"
                >¿Cómo funciona?</a
              ></span
            >
            <img
              alt="LineI1185"
              src="/playground_assets/linei1185-58li.svg"
              class="frame1home-line1"
            />
            <span class="frame1home-text052"
              ><a
                href="/home#pfrecuente"
                class="pregunta"
                @mouseover="hovertxt('pregunta')"
                @mouseout="hoverouttxt('pregunta')"
                style="color: white"
                >Pregunta frecuente</a
              ></span
            >
            <img
              alt="LineI1185"
              src="/playground_assets/linei1185-ptlr.svg"
              class="frame1home-line2"
            />
            <span class="frame1home-text053"><span>Contacto</span></span>
          </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
          <div class="frame1home-frame493">
            <!-- <router-link to="/">
                            <div class="frame1home-usosdebotones cuenta" name="linea" @mouseover="hover('cuenta')"
                                @mouseout="hoverout('cuenta')">
                                <span class="frame1home-text044"><span>Crear cuenta</span></span>
                            </div>
                        </router-link> -->

            <router-link to="/login">
              <div
                class="frame1home-usosdebotones1 ingresar"
                name="linea"
                @mouseover="hover('ingresar')"
                @mouseout="hoverout('ingresar')"
               
              >
                <span class="frame1home-text046"><span>Ingresar</span></span>
              </div>
            </router-link>
            <!-- <img
              alt="IconBtns1185"
              src="/playground_assets/iconbtns1185-lu7s.svg"
              class="frame1home-icon-btns"
            />
            <img
              alt="IconBtns1185"
              src="/playground_assets/iconbtns1185-l9.svg"
              class="frame1home-icon-btns1"
            /> -->
          </div>
          <!-- <img
                data-v-fae5bece=""
                alt="ImagenHombreHome11954"
                src="/playground_assets/imagenhombrehome11954-pzb-600w.png"
                class="frame1home-imagen-hombre-home1"
              />
              <img
                data-v-fae5bece=""
                alt="Vector11954"
                src="/playground_assets/vector11954-6qzl.svg"
                class="frame1home-vector1"
              /> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
          <div class="frame5dasboarddelusuario-frame492">
            <div class="frame5dasboarddelusuario-textsbtns-siper-header">
              <img
                src="/playground_assets/seguros/privado/signoaccesibilidadi1228-m9qr.svg"
                alt="SignoAccesibilidadI1228"
                class="frame5dasboarddelusuario-signo-accesibilidad"
              />
              <span class="frame5dasboarddelusuario-text070">
                <span>Accesibilidad</span>
              </span>
            </div>
            <div class="frame5dasboarddelusuario-textsbtns-siper-header1">
              <span class="frame5dasboarddelusuario-text072">
                <span>Centro de ayuda</span>
              </span>
            </div>
            <div class="frame5dasboarddelusuario-textsbtns-siper-header2">
              <span class="frame5dasboarddelusuario-text074">
                <span>Idiomas</span>
              </span>
              <img
                src="/playground_assets/seguros/privado/polygon2i1228-5xkf.svg"
                alt="Polygon2I1228"
                class="frame5dasboarddelusuario-polygon2"
              />
              <img
                src="/playground_assets/seguros/privado/subwayworld1i1228-4zr.svg"
                alt="subwayworld1I1228"
                class="frame5dasboarddelusuario-subwayworld1"
              />
            </div>
          </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
          <div class="frame1home-frame493">
            <!-- <router-link to="/">
                                  <div class="frame1home-usosdebotones cuenta" name="linea" @mouseover="hover('cuenta')"
                                      @mouseout="hoverout('cuenta')">
                                      <span class="frame1home-text044"><span>Crear cuenta</span></span>
                                  </div>
                              </router-link> -->

            <!-- <router-link to="/login">
                  <div
                    class="frame1home-usosdebotones1 ingresar"
                    name="linea"
                    @mouseover="hover('ingresar')"
                    @mouseout="hoverout('ingresar')"
                  >
                    <span class="frame1home-text046"><span>Ingresar</span></span>
                  </div>
                </router-link> -->
            <!-- <img
                    alt="IconBtns1185"
                    src="/playground_assets/iconbtns1185-lu7s.svg"
                    class="frame1home-icon-btns"
                  />
                  <img
                    alt="IconBtns1185"
                    src="/playground_assets/iconbtns1185-l9.svg"
                    class="frame1home-icon-btns1"
                  /> -->
          </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>

        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div
            class="frame5dasboarddelusuario-menuinterno"
            v-if="estadoMenuPrivado != 10"
          >
            <img
              src="/playground_assets/seguros/privado/rectangle2385i1186-eag-200h.png"
              alt="Rectangle2385I1186"
              class="frame5dasboarddelusuario-rectangle2385"
            />
            <div class="frame5dasboarddelusuario-frame288925">
              
              <span
                class="frame5dasboarddelusuario-text056 Inicio"
                @click="cambiaMenu(1)"
                @mouseover="hover('Inicio')"
                @mouseout="hoverout('Inicio')"
              >
                <span>Inicio</span>
              </span>
              
              <img
                src="/playground_assets/seguros/privado/linei1186-bzgi.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line"
              />
              
              <span
                class="frame5dasboarddelusuario-text058 Simular"
                @click="cambiaMenu(4)"
                @mouseover="hover('Simular')"
                @mouseout="hoverout('Simular')"
              >
                <span>Simular seguro</span>
              </span>
              
              <img
                src="/playground_assets/seguros/privado/linei1186-c00a.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line1"
              />
              <span
                class="frame5dasboarddelusuario-text060 Cotizacion"
                @click="cambiaMenu(3)"
                @mouseover="hover('Cotizacion')"
                @mouseout="hoverout('Cotizacion')"
              >
                <span>Mis cotizaciones</span>
              </span>
              <img
                src="/playground_assets/seguros/privado/linei1186-tjk.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line2"
              />
              <span
                class="frame5dasboarddelusuario-text062 Seguros"
                @click="cambiaMenu(2)"
                @mouseover="hover('Seguros')"
                @mouseout="hoverout('Seguros')"
              >
                <span>Mis seguros</span>
              </span>
              <img
                src="/playground_assets/seguros/privado/linei1186-2goq.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line3"
              />
              <span class="frame5dasboarddelusuario-text064">
                <span> Endoso </span>
              </span>
              <img
                src="/playground_assets/seguros/privado/vectori1186-a77v.svg"
                alt="VectorI1186"
                class="frame5dasboarddelusuario-vector069"
              />
              <img
                src="/playground_assets/seguros/privado/linei1186-8xn2.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line4"
              />
              <span class="frame5dasboarddelusuario-text066">
                <span>Siniestro</span>
              </span>
              <img
                src="/playground_assets/seguros/privado/vectori1186-s10h.svg"
                alt="VectorI1186"
                class="frame5dasboarddelusuario-vector070"
              />
              <img
                src="/playground_assets/seguros/privado/linei1186-i4tl.svg"
                alt="LineI1186"
                class="frame5dasboarddelusuario-line5"
              />
              <span class="frame5dasboarddelusuario-text068">
                <span>Mis solicitudes</span>
              </span>
            </div>
          </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>

          <!-- <v-container>
                <v-row dense>
                    <v-col cols="12" sm="12">
                    </v-col>
                </v-row>
              </v-container> -->

          <!-- <div class="frame1home-text055">
                <div class="frame1home-frame496">
                  <div class="frame1home-textorecuperadinerodetucredito">
                    <span class="frame1home-text056">
                      <span>Recupera dinero en tu crédito de consumo.</span>
                    </span>
                    <span class="frame1home-text058">
                      <span>Accede a tu línea Desgravamen digital</span>
                    </span>
                  </div>
                  <div
                    class="frame1home-botones-cotizatu-seguro-cotizasinregistrarte"
                  >
                   
                    <router-link to="/crear-degravamen">
                      <div
                        class="frame1home-usosdebotones2 bnt1"
                        name="linea"
                        @mouseover="hover('bnt1')"
                        @mouseout="hoverout('bnt1')"
                      >
                        <span class="frame1home-text060"
                          ><span>Crea tu línea</span></span
                        >
                      </div>
                    </router-link>
                  
                    <router-link to="/crear-cotizacion">
                      <div
                        class="frame1home-usosdebotones3 bnt2"
                        name="linea"
                        @mouseover="hover('bnt2')"
                        @mouseout="hoverout('bnt2')"
                      >
                        <span class="frame1home-text062"
                          ><span>Cotiza tu seguro</span></span
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
              </div> -->
        </v-flex>
        <v-flex sm7>
          <h1 style="display: none !important">3</h1>
          <!-- <div
            :class="{ imagenMicuenta: estadoMenuPrivado == '10' }"
            class="frame5dasboarddelusuario-frame517"
          >
            <div class="frame5dasboarddelusuario-header">
              <div class="frame5dasboarddelusuario-header-subheadand-avatar-autolayout">
                <div
                  v-if="estadoMenuPrivado != 10"
                  class="frame5dasboarddelusuario-header-subhead-autolayout"
                >
                  <span class="frame5dasboarddelusuario-text">
                    <span>Estado de cuenta</span>
                  </span>
                  <span class="frame5dasboarddelusuario-text002">
                    <span> Aquí podrás ver + info. de tu cuenta </span>
                  </span>
                </div>
                <div
                  v-if="estadoMenuPrivado == 10"
                  class="frame5dasboarddelusuario-header-subhead-autolayout"
                >
                  <span class="frame5dasboarddelusuario-text">
                    <span>Datos personales</span>
                  </span>
                  <span class="frame5dasboarddelusuario-text002">
                    <span> Aquí podrás cambiar tus datos cuando quieras</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="frame5dasboarddelusuario-frame496">
              <div class="frame5dasboarddelusuario-textorecuperadinerodetucredito">
                <span
                  class="frame5dasboarddelusuario-text004"
                  v-if="estadoMenuPrivado != 10"
                >
                  <span>Bienvenido</span>
                </span>
                <span
                  class="frame5dasboarddelusuario-text004"
                  v-if="estadoMenuPrivado == 10"
                >
                  <span>Editar perfil</span>
                </span>
              </div>
            </div>
          </div>
          <div
            class="frame5dasboarddelusuario-maskgroup"
            :class="{ imagenMicuenta: estadoMenuPrivado == '10' }"
          >
            <img
              v-if="estadoMenuPrivado != 10"
              src="/playground_assets/seguros/privado/rectangle23811185-bm5h-300h.png"
              alt="Rectangle23811185"
              class="frame5dasboarddelusuario-rectangle2381"
            />
            <img
              v-if="estadoMenuPrivado == 10"
              style="width: 538px; margin-left: 676px"
              src="/playground_assets/seguros/privado/micuenta/Rectangle_der_micuenta.png"
              alt="Rectangle23811185"
              class="frame5dasboarddelusuario-rectangle2381"
            />
          </div> -->
        </v-flex>
        <!-- 
            <v-flex  sm1>
              <h1 style="display: none !important">4</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">5</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">6</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">7</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">8</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">9</h1>
            </v-flex>
            <v-flex  sm1>
              <h1 style="display: none !important">10</h1>
            </v-flex> -->
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>

        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>

          <!-- <Inicio v-if="estadoMenuPrivado == 1" :montoSaldos="montoSaldos"></Inicio>
          <Seguros v-if="estadoMenuPrivado == 2"></Seguros>
          <Cotizacion
            v-if="estadoMenuPrivado == 3"
            :cotizacionesEntrega="misCotizaciones"
          ></Cotizacion>
          <MiCuenta v-if="estadoMenuPrivado == 10"></MiCuenta> -->

          <div class="frame1-formulariocontacto-group288935">
            <div class="frame1-formulariocontacto-group288922">
              <img
                src="/playground_assets/contacto/rectangle391186-8jqo-900w.png"
                alt="Rectangle391186"
                class="frame1-formulariocontacto-rectangle39"
              />
              <span class="frame1-formulariocontacto-text">
                <span>!Contactanos!</span>
              </span>
              <span class="frame1-formulariocontacto-text02">
                <span>Presentas dudas, consultas y otros.</span>
              </span>
              <div class="">
                <v-btn color="rgb(56, 0, 135)" class="rounded-pill " x-large
                    style="margin-left: 305px; top: 651px;width:250px;text-transform: unset !important;" @click="validate"
                    :disabled="!valid"
                   >
                    <span class="frame644persona-formulario-lneadesgravamen-text10">
                      <span>Ingresar</span>
                    </span>
                    <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                  </v-btn>
              </div>
              <div class="frame1-formulariocontacto-formtophome">
                <!-- <img
              src="/playground_assets/contacto/rectangle71186-cp05-700w.png"
              alt="Rectangle71186"
              class="frame1-formulariocontacto-rectangle7"
            />
            <img
              src="/playground_assets/contacto/rectangle81186-z8mf-700w.png"
              alt="Rectangle81186"
              class="frame1-formulariocontacto-rectangle8"
            /> -->
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="nombre"
                        :rules="nombre1Rules"
                        label="Nombre:"
                        required
                        class="nombre1 campotexto"
                        single-line
                        outlined
                        @update:error="vnombre1"
                        color="rgb(56,0,151)"
                        maxlength="500"
                      >
                        <template v-slot:append>
                          <div v-if="validnombre1 == `no`"></div>
                          <v-icon
                            v-else-if="!validnombre1"
                            color="m100"
                            style="margin-bottom: 10px !important "
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon
                            v-else-if="validnombre1"
                            color="red"
                            style="margin-bottom: 10px !important "
                          >
                            mdi-close-circle
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="mail"
                        :rules="mailRules"
                        label="Correo:"
                        required
                        class="mail campotexto"
                        single-line
                        outlined
                        @update:error="vmail"
                        color="rgb(56,0,151)"
                        maxlength="100"
                      >
                        <template v-slot:append>
                          <div v-if="validmail == `no`"></div>
                          <v-icon
                            v-else-if="!validmail"
                            color="m100"
                            style="margin-bottom: 10px !important "
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon
                            v-else-if="validmail"
                            color="red"
                            style="margin-bottom: 10px !important "
                          >
                            mdi-close-circle
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="celular"
                          :rules="celularRules"
                          label="Teléfono:"
                          required
                          class="celular campotexto"
                          single-line
                          outlined
                          @update:error="vcelular"
                          color="rgb(56,0,151)"
                        >
                          <template v-slot:append>
                            <div v-if="validcelular == `no`"></div>
                            <v-icon
                              v-else-if="!validcelular"
                              color="m100"
                              style="margin-bottom: 10px !important "
                            >
                              mdi-checkbox-marked-circle
                            </v-icon>
                            <v-icon
                              v-else-if="validcelular"
                              color="red"
                              style="margin-bottom: 10px !important "
                            >
                              mdi-close-circle
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-textarea
                            v-model="comentarios"
                            label="Comentario"
                            :rules="comentariosRules"
                            auto-grow
                            outlined
                            rows="4"
                            row-height="40"
                            @update:error="vcomentarios"
                            class="comentario campotexto"
                            color="rgb(56,0,151)"
                            maxlength="500"
                        >
                        <template v-slot:append>
                            <div v-if="validcomentarios == `no`"></div>
                            <v-icon
                              v-else-if="!validcomentarios"
                              color="m100"
                              style="margin-bottom: 10px !important "
                            >
                              mdi-checkbox-marked-circle
                            </v-icon>
                            <v-icon
                              v-else-if="validcomentarios"
                              color="red"
                              style="margin-bottom: 10px !important "
                            >
                              mdi-close-circle
                            </v-icon>
                          </template>
                        </v-textarea>
                      </v-col>
                    
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
          <div class="frame1-formulariocontacto-group288934">
            <span class="frame1-formulariocontacto-text14">
              <span>¿Necesitas ayuda?</span>
            </span>
            <span class="frame1-formulariocontacto-text16">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum.
              </span>
            </span>
            <span class="frame1-formulariocontacto-text18">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
            </span>
            <img
              src="/playground_assets/contacto/ellipse2911186-f24c-200h.png"
              alt="Ellipse2911186"
              class="frame1-formulariocontacto-ellipse291"
            />
            <img
              src="/playground_assets/contacto/ellipse2921186-n7-200h.png"
              alt="Ellipse2921186"
              class="frame1-formulariocontacto-ellipse292"
            />
          </div>

          <div data-v-fae5bece="" class="frame1home-footer">
            <img
              data-v-fae5bece=""
              alt="Line23I1185"
              src="/playground_assets/line23i1185-ligo.svg"
              class="frame1home-line23"
            />
            <div style="position: absolute; left: 82px">
              <div data-v-fae5bece="" class="frame1home-cajamenfooter">
                <span data-v-fae5bece="" class="frame1home-text119"
                  ><span data-v-fae5bece="">Linea desgravamen</span></span
                ><span data-v-fae5bece="" class="frame1home-text121"
                  ><span data-v-fae5bece="">
                    Ingresa <span data-v-fae5bece=""> </span></span></span
                ><span data-v-fae5bece="" class="frame1home-text123"
                  ><span data-v-fae5bece="">Registrate</span></span
                ><span data-v-fae5bece="" class="frame1home-text125"
                  ><span data-v-fae5bece="">Recuperar tu clave.</span></span
                >
              </div>
              <div data-v-fae5bece="" class="frame1home-caja-quienessomosfooter">
                <span data-v-fae5bece="" class="frame1home-text127"
                  ><span data-v-fae5bece="">Sobre nosotros</span></span
                ><span data-v-fae5bece="" class="frame1home-text129"
                  ><span data-v-fae5bece="">Somos</span></span
                ><span data-v-fae5bece="" class="frame1home-text131"
                  ><span data-v-fae5bece="">Misión</span></span
                ><span data-v-fae5bece="" class="frame1home-text133"
                  ><span data-v-fae5bece="">Valores</span></span
                ><span data-v-fae5bece="" class="frame1home-text135"
                  ><span data-v-fae5bece="">Visión</span></span
                >
              </div>
              <div data-v-fae5bece="" class="frame1home-caja-segurofooter">
                <span data-v-fae5bece="" class="frame1home-text137"
                  ><span data-v-fae5bece="">Seguro</span></span
                ><span data-v-fae5bece="" class="frame1home-text139"
                  ><span data-v-fae5bece="">Cotiza</span></span
                ><span data-v-fae5bece="" class="frame1home-text141"
                  ><span data-v-fae5bece="">
                    Beneficios <span data-v-fae5bece=""> </span></span></span
                ><span data-v-fae5bece="" class="frame1home-text143"
                  ><span data-v-fae5bece="">¿Qué es?</span></span
                >
              </div>
              <div data-v-fae5bece="" class="frame1home-cajacontactofooter">
                <span data-v-fae5bece="" class="frame1home-text145"
                  ><span data-v-fae5bece="">Linea desgravamen</span></span
                ><span data-v-fae5bece="" class="frame1home-text147"
                  ><span data-v-fae5bece="">Fono: +569xxxxx</span></span
                ><span data-v-fae5bece="" class="frame1home-text149"
                  ><span data-v-fae5bece="">
                    Dirección: Av Los leones xx
                    <span data-v-fae5bece=""> </span></span></span
                ><span data-v-fae5bece="" class="frame1home-text151"
                  ><span data-v-fae5bece="">
                    Correo: contacto@segurodesgravamen.cl
                    <span data-v-fae5bece=""> </span></span></span
                ><span data-v-fae5bece="" class="frame1home-text153"
                  ><span data-v-fae5bece=""
                    >http://www.garantiasegura.cl/contacto/</span
                  ></span
                >
              </div>
            </div>
            <img
              data-v-fae5bece=""
              alt="Rectangle2374I1185"
              src="/playground_assets/rectangle2374i1185-ynk-200h.png"
              class="frame1home-rectangle2374"
            />
            <div data-v-fae5bece="" class="frame1home-group138">
              <img
                data-v-fae5bece=""
                alt="TwitterNegativeI1185"
                src="/playground_assets/hombreIzq.svg"
              />
            </div>
            <div data-v-fae5bece="" class="frame1home-group288892">
              <span data-v-fae5bece="" class="frame1home-text155"
                ><span data-v-fae5bece="" class="frame1home-text156">Grupo INSUREX</span
                ><span data-v-fae5bece="">
                  | La más amplia gama de seguros, reaseguros y asistencias del mercado
                  nacional e internacional.
                </span></span
              ><img
                data-v-fae5bece=""
                alt="GRUPOINSUREXSVG1I1185"
                src="/playground_assets/grupoinsurexsvg1i1185-vnpi-200h.png"
                class="frame1home-g-r-u-p-o-i-n-s-u-r-e-x-s-v-g1"
              />
            </div>
            <div data-v-fae5bece="" class="frame1home-logo-lineadesgravamen-footer1">
              <img
                data-v-fae5bece=""
                alt="TwitterNegativeI1185"
                src="/playground_assets/Logo_Linea desgravamen_Footer.svg"
              />
            </div>
            <div data-v-fae5bece="" class="frame1home-socialwrapper">
              <img
                data-v-fae5bece=""
                alt="FacebookNegativeI1185"
                src="/playground_assets/facebooknegativei1185-ftgp.svg"
                class="frame1home-facebook-negative"
              /><img
                data-v-fae5bece=""
                alt="TwitterNegativeI1185"
                src="/playground_assets/twitternegativei1185-b41.svg"
                class="frame1home-twitter-negative"
              />
              <div data-v-fae5bece="" class="frame1home-instagram-negative">
                <div data-v-fae5bece="" class="frame1home-group147">
                  <img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-f9s9.svg"
                    class="frame1home-vector251"
                  /><img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-ztl.svg"
                    class="frame1home-vector252"
                  /><img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-d9zg.svg"
                    class="frame1home-vector253"
                  />
                </div>
              </div>
              <div data-v-fae5bece="" class="frame1home-linked-in-negative">
                <div data-v-fae5bece="" class="frame1home-group148">
                  <img
                    data-v-fae5bece=""
                    alt="VectorI1185"
                    src="/playground_assets/vectori1185-4np9.svg"
                    class="frame1home-vector254"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 
               -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">13</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">14</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">15</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame288928">
                <div data-v-fae5bece="" class="frame1home-botonestopheader">
                  <div data-v-fae5bece="" class="frame1home-iconoahorrar">
                    <div data-v-fae5bece="" class="frame1home-group023">
                      <div data-v-fae5bece="" class="frame1home-group024">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-hi9a.svg"
                          class="frame1home-vector066"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group025">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-6g8t.svg"
                          class="frame1home-vector067"
                        /><img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-ixn.svg"
                          class="frame1home-vector068"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group026">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-vram.svg"
                          class="frame1home-vector069"
                        /><img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-4do8.svg"
                          class="frame1home-vector070"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group027">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-94mh.svg"
                          class="frame1home-vector071"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group028">
                        <div data-v-fae5bece="" class="frame1home-group029">
                          <img
                            data-v-fae5bece=""
                            alt="VectorI1185"
                            src="/playground_assets/vectori1185-axw.svg"
                            class="frame1home-vector072"
                          /><img
                            data-v-fae5bece=""
                            alt="VectorI1185"
                            src="/playground_assets/vectori1185-ohcr.svg"
                            class="frame1home-vector073"
                          />
                        </div>
                        <div data-v-fae5bece="" class="frame1home-group030">
                          <img
                            data-v-fae5bece=""
                            alt="VectorI1185"
                            src="/playground_assets/vectori1185-h3do.svg"
                            class="frame1home-vector074"
                          />
                        </div>
                        <div data-v-fae5bece="" class="frame1home-group031">
                          <img
                            data-v-fae5bece=""
                            alt="VectorI1185"
                            src="/playground_assets/vectori1185-ckbr.svg"
                            class="frame1home-vector075"
                          />
                        </div>
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group032">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-lh149.svg"
                          class="frame1home-vector076"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group033">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-atlu.svg"
                          class="frame1home-vector077"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group034">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-5t7p.svg"
                          class="frame1home-vector078"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group035">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-481j.svg"
                          class="frame1home-vector079"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group036">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-015.svg"
                          class="frame1home-vector080"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group037">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-nsnh.svg"
                          class="frame1home-vector081"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group038">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-3jdp.svg"
                          class="frame1home-vector082"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group039">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-g8s8.svg"
                          class="frame1home-vector083"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group040">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-ih3.svg"
                          class="frame1home-vector084"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group041">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-v60e.svg"
                          class="frame1home-vector085"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group042">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-e8ny.svg"
                          class="frame1home-vector086"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group043">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-8b3j.svg"
                          class="frame1home-vector087"
                        />
                      </div>
                    </div>
                  </div>
                  <span data-v-fae5bece="" class="frame1home-text112"
                    ><span data-v-fae5bece="">Ahorra</span></span
                  >
                </div>
                <div data-v-fae5bece="" class="frame1home-botonestopheader1">
                  <div data-v-fae5bece="" class="frame1home-icono-sininteres">
                    <div data-v-fae5bece="" class="frame1home-group044">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-umd5.svg"
                        class="frame1home-vector088"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-14ip.svg"
                        class="frame1home-vector089"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group045">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-y56q.svg"
                        class="frame1home-vector090"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group046">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ug89.svg"
                        class="frame1home-vector091"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group047">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-pkkc.svg"
                        class="frame1home-vector092"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group048">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ahip.svg"
                        class="frame1home-vector093"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-im9h.svg"
                        class="frame1home-vector094"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group049">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-2z7.svg"
                        class="frame1home-vector095"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group050">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-bp29.svg"
                        class="frame1home-vector096"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group051">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-nr8.svg"
                        class="frame1home-vector097"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group052">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3b2x.svg"
                        class="frame1home-vector098"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group053">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-b1jt.svg"
                        class="frame1home-vector099"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group054">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-5h8v.svg"
                        class="frame1home-vector100"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group055">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-2pgm.svg"
                        class="frame1home-vector101"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group056">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-rqx4.svg"
                        class="frame1home-vector102"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group057">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-7uxr.svg"
                        class="frame1home-vector103"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group058">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-apo.svg"
                        class="frame1home-vector104"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group059">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-v0zc.svg"
                        class="frame1home-vector105"
                      />
                    </div>
                  </div>
                  <span data-v-fae5bece="" class="frame1home-text114"
                    ><span data-v-fae5bece="">Sin interes</span></span
                  >
                </div>
                <div data-v-fae5bece="" class="frame1home-botonestopheader2">
                  <div data-v-fae5bece="" class="frame1home-group496">
                    <div data-v-fae5bece="" class="frame1home-group060">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-djjb.svg"
                        class="frame1home-vector106"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group061">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-qafi.svg"
                        class="frame1home-vector107"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group062">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-5p5d.svg"
                        class="frame1home-vector108"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group063">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-o67i.svg"
                        class="frame1home-vector109"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group064">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3oq.svg"
                        class="frame1home-vector110"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group065">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ehth.svg"
                        class="frame1home-vector111"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group066">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-l0sp.svg"
                        class="frame1home-vector112"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group067">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-5v6u.svg"
                        class="frame1home-vector113"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group068">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-hnj.svg"
                        class="frame1home-vector114"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group069">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-u18.svg"
                        class="frame1home-vector115"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group070">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-4ysq.svg"
                        class="frame1home-vector116"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group071">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3xsx.svg"
                        class="frame1home-vector117"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group072">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-wahgi.svg"
                        class="frame1home-vector118"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group073">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3eva.svg"
                        class="frame1home-vector119"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group074">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-qeg.svg"
                        class="frame1home-vector120"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group075">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-0c3.svg"
                        class="frame1home-vector121"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group076">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-mk4d.svg"
                        class="frame1home-vector122"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group077">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-26or.svg"
                        class="frame1home-vector123"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group078">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-25id.svg"
                        class="frame1home-vector124"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group079">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-eos.svg"
                        class="frame1home-vector125"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group080">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ckf.svg"
                        class="frame1home-vector126"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group081">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-6gzs.svg"
                        class="frame1home-vector127"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group082">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-rga.svg"
                        class="frame1home-vector128"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group083">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-yq5a.svg"
                        class="frame1home-vector129"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group084">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-zxa.svg"
                        class="frame1home-vector130"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group085">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-rtvl.svg"
                        class="frame1home-vector131"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group086">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-38da.svg"
                        class="frame1home-vector132"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group087">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-1u1r.svg"
                        class="frame1home-vector133"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group088">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-lygs.svg"
                        class="frame1home-vector134"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group089">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-joi.svg"
                        class="frame1home-vector135"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group090">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-gr2a.svg"
                        class="frame1home-vector136"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group091">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-6g39.svg"
                        class="frame1home-vector137"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group092">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-l61.svg"
                        class="frame1home-vector138"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group093">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-vzr.svg"
                        class="frame1home-vector139"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group094">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-7d2.svg"
                        class="frame1home-vector140"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group095">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-97mo.svg"
                        class="frame1home-vector141"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group096">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-wrm3.svg"
                        class="frame1home-vector142"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group097">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-hmv8.svg"
                        class="frame1home-vector143"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group098">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-oftb.svg"
                        class="frame1home-vector144"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group099">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-pnip.svg"
                        class="frame1home-vector145"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group100">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-pwr.svg"
                        class="frame1home-vector146"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group101">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-hbj.svg"
                        class="frame1home-vector147"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group102">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ek2pzg.svg"
                        class="frame1home-vector148"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group103">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-2whp.svg"
                        class="frame1home-vector149"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group104">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-9vdh.svg"
                        class="frame1home-vector150"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group105">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-z0kk.svg"
                        class="frame1home-vector151"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group106">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-09pm.svg"
                        class="frame1home-vector152"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group107">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-2heh.svg"
                        class="frame1home-vector153"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group108">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3uj.svg"
                        class="frame1home-vector154"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group109">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-0co8.svg"
                        class="frame1home-vector155"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group110">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-nbv8.svg"
                        class="frame1home-vector156"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group111">
                      <div data-v-fae5bece="" class="frame1home-group112">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-cqnc.svg"
                          class="frame1home-vector157"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group113">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-4s3.svg"
                          class="frame1home-vector158"
                        />
                      </div>
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group114">
                      <div data-v-fae5bece="" class="frame1home-group115">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-x0e.svg"
                          class="frame1home-vector159"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group116">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-u0sx.svg"
                          class="frame1home-vector160"
                        />
                      </div>
                    </div>
                  </div>
                  <span data-v-fae5bece="" class="frame1home-text116"
                    ><span data-v-fae5bece="">Precio justo</span></span
                  >
                </div>
                <div data-v-fae5bece="" class="frame1home-botonestopheader3">
                  <div data-v-fae5bece="" class="frame1home-icono-devolucion">
                    <div data-v-fae5bece="" class="frame1home-group117">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-4pln.svg"
                        class="frame1home-vector161"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group118">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-d8ys.svg"
                        class="frame1home-vector162"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-qdv9.svg"
                        class="frame1home-vector163"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group119">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-6rem.svg"
                        class="frame1home-vector164"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-fi76.svg"
                        class="frame1home-vector165"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group120">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-lyln.svg"
                        class="frame1home-vector166"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-3bnk.svg"
                        class="frame1home-vector167"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group121">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ance.svg"
                        class="frame1home-vector168"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group122">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-nbqj.svg"
                        class="frame1home-vector169"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group123">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-pvm.svg"
                        class="frame1home-vector170"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group124">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-uj76.svg"
                        class="frame1home-vector171"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group125">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-32q6.svg"
                        class="frame1home-vector172"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-33wg.svg"
                        class="frame1home-vector173"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group126">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-4ca8.svg"
                        class="frame1home-vector174"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group127">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-mrcn.svg"
                        class="frame1home-vector175"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group128">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-roli.svg"
                        class="frame1home-vector176"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group129">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-lald.svg"
                        class="frame1home-vector177"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group130">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-6vyj.svg"
                        class="frame1home-vector178"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group131">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-7mu.svg"
                        class="frame1home-vector179"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group132">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-irw4.svg"
                        class="frame1home-vector180"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group133">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-vo7p.svg"
                        class="frame1home-vector181"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group134">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ixo9v.svg"
                        class="frame1home-vector182"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group135">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-zmoi.svg"
                        class="frame1home-vector183"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group136">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ndmy.svg"
                        class="frame1home-vector184"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group137">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-iml.svg"
                        class="frame1home-vector185"
                      />
                    </div>
                  </div>
                  <span data-v-fae5bece="" class="frame1home-text118"
                    >Devolución 10%</span
                  >
                </div>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>

        <v-flex sm1>
          <h1 style="display: none !important">20</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">21</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm1>
          <h1 style="display: none !important">1</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame497">
                <span data-v-fae5bece="" id="bienvenido" class="frame1home-text064"
                  ><span data-v-fae5bece=""
                    ><span data-v-fae5bece=""
                      >Bienvenido a línea de Desgravamen.</span
                    ><br data-v-fae5bece="" /><span
                      data-v-fae5bece=""
                    ></span></span></span
                ><span data-v-fae5bece="" class="frame1home-text069"
                  ><span data-v-fae5bece=""
                    ><span data-v-fae5bece="">
                      Una nueva cuenta 100% digital y para TODOS, donde TÚ eliges lo
                      que necesitas. </span
                    ><br data-v-fae5bece="" /><span data-v-fae5bece=""></span
                    ><br data-v-fae5bece="" /><span data-v-fae5bece=""></span></span
                ></span>
                <div data-v-fae5bece="" class="frame1home-i-m-g-c-i-c-l-o">
                  <div data-v-fae5bece="" class="frame1home-group005">
                    <div data-v-fae5bece="" class="frame1home-group006">
                      <div data-v-fae5bece="" class="frame1home-group007">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-j1f8.svg"
                          class="frame1home-vector029"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group008">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-pmx9.svg"
                          class="frame1home-vector030"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group009">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-yu4l.svg"
                          class="frame1home-vector031"
                        />
                      </div>
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group010">
                      <div data-v-fae5bece="" class="frame1home-group011">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-wjfj.svg"
                          class="frame1home-vector032"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group012">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-i17.svg"
                          class="frame1home-vector033"
                        />
                      </div>
                      <div data-v-fae5bece="" class="frame1home-group013">
                        <img
                          data-v-fae5bece=""
                          alt="VectorI1185"
                          src="/playground_assets/vectori1185-e9eh.svg"
                          class="frame1home-vector034"
                        />
                      </div>
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group014">
                      <img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-ji0j.svg"
                        class="frame1home-vector035"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-seh.svg"
                        class="frame1home-vector036"
                      /><img
                        data-v-fae5bece=""
                        alt="VectorI1185"
                        src="/playground_assets/vectori1185-wfxj.svg"
                        class="frame1home-vector037"
                      />
                    </div>
                    <img
                      data-v-fae5bece=""
                      alt="VectorI1185"
                      src="/playground_assets/vectori1185-5mfr.svg"
                      class="frame1home-vector038"
                    /><img
                      data-v-fae5bece=""
                      alt="VectorI1185"
                      src="/playground_assets/vectori1185-yprt.svg"
                      class="frame1home-vector039"
                    />
                  </div>
                  <span data-v-fae5bece="" class="frame1home-text076"
                    ><span data-v-fae5bece="">Ahorra</span></span
                  ><span data-v-fae5bece="" class="frame1home-text078"
                    ><span data-v-fae5bece="">Sin interés</span></span
                  ><span data-v-fae5bece="" class="frame1home-text080"
                    ><span data-v-fae5bece=""
                      ><span data-v-fae5bece="">Precio</span
                      ><br data-v-fae5bece="" /><span data-v-fae5bece=""
                        >justo</span
                      ></span
                    ></span
                  >
                </div>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-formtophome">
                <span data-v-fae5bece="" class="frame1home-text"
                  ><span data-v-fae5bece="">Quieres más info?</span></span
                ><span data-v-fae5bece="" class="frame1home-text002"
                  ><span data-v-fae5bece="">Escribe tus datos</span></span
                >
                <v-app class="ctrans">
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    style="
                      top: 93px;
                      width: 100%;
                      border-radius: 100px !important;
                      position: absolute;
                    "
                  >
                    <v-text-field
                      v-model="formHome.nombre"
                      :rules="nombreRules"
                      label="Nombre*"
                      required
                      color="rgba(0, 23, 135, 1)"
                      outlined
                    >
                      <template v-slot:append> </template>
                    </v-text-field>
                    <v-text-field
                      v-model="formHome.correo"
                      :rules="correoRules"
                      label="Correo*"
                      required
                      color="rgba(0, 23, 135, 1)"
                      outlined
                    >
                      <template v-slot:append> </template>
                    </v-text-field>
                    <v-text-field
                      v-model="formHome.telefono"
                      :rules="telefonoRules"
                      label="Telefono*"
                      required
                      color="rgba(0, 23, 135, 1)"
                      outlined
                    >
                      <template v-slot:append> </template>
                    </v-text-field>
                    <v-btn
                      color="juanito"
                      class="frame1home-usosdebotones4 rounded-pill"
                      x-large
                      style="
                        text-transform: unset !important;
                        top: 260px;
                        width: 100%;
                        max-width: 374px;
                        text-transform: unset !important;
                      "
                      @click="validate"
                    >
                      <span class="frame1home-text110">
                        <span>Ingresar</span>
                      </span>
                    </v-btn>
                  </v-form>
                </v-app>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>

        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
          <!-- <div
                data-v-fae5bece=""
                class="frame1home-frame498centro"
                id="cfunciona"
              >
                <span data-v-fae5bece="" class="frame1home-text026centro"
                  ><span data-v-fae5bece="">¿Cómo funciona?</span></span
                ><span data-v-fae5bece="" class="frame1home-text028centro"
                  ><span data-v-fae5bece="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do..
                  </span></span
                >
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group459">
                <img
                  data-v-fae5bece=""
                  alt="Ellipse2791185"
                  src="/playground_assets/ellipse2791185-vgf3-200h.png"
                  class="frame1home-ellipse279"
                /><img
                  data-v-fae5bece=""
                  alt="Vector1185"
                  src="/playground_assets/vector1185-88a3.svg"
                  class="frame1home-vector"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-frame52">
                <div data-v-fae5bece="" class="frame1home-frame51">
                  <span data-v-fae5bece="" class="frame1home-text010"
                    ><span data-v-fae5bece="">Regístrate</span></span
                  >
                </div>
                <span data-v-fae5bece="" class="frame1home-text012"
                  ><span data-v-fae5bece=""
                    >Completa el registro para que podamos aprobar tu línea.</span
                  ></span
                >
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
          <!-- <img
                data-v-fae5bece=""
                alt="ESCUDO11185"
                src="/playground_assets/escudo11185-i5n.svg"
                class="frame1home-e-s-c-u-d-o1"
              /> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group461">
                <img
                  data-v-fae5bece=""
                  alt="Ellipse2811185"
                  src="/playground_assets/ellipse2811185-0s4sp-200h.png"
                  class="frame1home-ellipse281"
                /><img
                  data-v-fae5bece=""
                  alt="Vector1185"
                  src="/playground_assets/vector1185-t93r.svg"
                  class="frame1home-vector001"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-frame58">
                <div data-v-fae5bece="" class="frame1home-frame511">
                  <span data-v-fae5bece="" class="frame1home-text014"
                    ><span data-v-fae5bece="">Contrátalo</span></span
                  >
                </div>
                <span data-v-fae5bece="" class="frame1home-text016"
                  ><span data-v-fae5bece="">
                    El comparador de prima te dará los resultados y podrás contratar
                    el servicio.
                  </span></span
                >
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group462">
                <img
                  data-v-fae5bece=""
                  alt="Ellipse2801185"
                  src="/playground_assets/ellipse2801185-mtsd-200h.png"
                  class="frame1home-ellipse2801"
                /><img
                  data-v-fae5bece=""
                  alt="Vector1185"
                  src="/playground_assets/vector1185-7v4i.svg"
                  class="frame1home-vector003"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-frame59">
                <div data-v-fae5bece="" class="frame1home-frame513">
                  <span data-v-fae5bece="" class="frame1home-text022"
                    ><span data-v-fae5bece="">Cotiza</span></span
                  >
                </div>
                <span data-v-fae5bece="" class="frame1home-text024"
                  ><span data-v-fae5bece="">
                    Ingresa tus datos, el monto del crédito, cantidad de cuotas a
                    pagar, periodos de gracia y la institución donde se contrata el
                    crédito.
                  </span></span
                >
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group460">
                <img
                  data-v-fae5bece=""
                  alt="Ellipse2801185"
                  src="/playground_assets/ellipse2801185-61ag-200h.png"
                  class="frame1home-ellipse280"
                /><img
                  data-v-fae5bece=""
                  alt="Vector1185"
                  src="/playground_assets/vector1185-02p8.svg"
                  class="frame1home-vector002"
                />
              </div>
              <div data-v-fae5bece="" class="frame1home-frame59abajo">
                <div data-v-fae5bece="" class="frame1home-frame498">
                  <span data-v-fae5bece="" class="frame1home-text026"
                    ><span data-v-fae5bece="">Informate</span></span
                  ><span data-v-fae5bece="" class="frame1home-text028"
                    ><span data-v-fae5bece="">
                      Ingresa tus datos, el monto del crédito, cantidad de cuotas a
                      pagar, periodos de gracia y la institución donde se contrata
                      el crédito.
                    </span></span
                  >
                </div>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame499">
                <span data-v-fae5bece="" class="frame1home-text103"
                  ><span data-v-fae5bece="">Información legal</span></span
                ><span data-v-fae5bece="" class="frame1home-text105"
                  ><span data-v-fae5bece=""
                    ><span data-v-fae5bece="">
                      Si quieres conocer más acerca de las leyes en las que se
                      soporta nuestro seguro <span data-v-fae5bece=""> </span></span
                    ><br data-v-fae5bece="" /><span data-v-fae5bece=""
                      >Desgravamen, haz clic en los links a continuación.</span
                    ></span
                  ></span
                >
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>

          <!-- <div class="frame5dasboarddelusuario-maskgroup">
              <img
                src="/playground_assets/seguros/privado/rectangle23811185-bm5h-300h.png"
                alt="Rectangle23811185"
                class="frame5dasboarddelusuario-rectangle2381"
              />
            </div> -->

          <!-- <div data-v-fae5bece="" class="frame1home-cards1" v-if="legal">
                <v-app class="ctrans">
                  <v-skeleton-loader
                    class="mx-auto"
                    style="width: 335px; top: 80px"
                    type="card"
                  ></v-skeleton-loader>
                </v-app>
              </div>
              <div
                data-v-fae5bece=""
                class="frame1home-cards1 card1"
                v-if="!legal"
                @mouseover="hoverinfo('card1')"
                @mouseout="hoveroutinfo('card1')"
              >
                <div data-v-fae5bece="" class="frame1home-header">
                  <div
                    data-v-fae5bece=""
                    class="frame1home-header-subheadand-avatar-autolayout"
                  >
                    <div
                      data-v-fae5bece=""
                      class="frame1home-header-subhead-autolayout"
                    >
                      <span data-v-fae5bece="" class="frame1home-text085"
                        ><span data-v-fae5bece="">{{
                          infoLegal.info1.titulo
                        }}</span></span
                      ><span data-v-fae5bece="" class="frame1home-text087"
                        ><span data-v-fae5bece="">
                          {{ infoLegal.info1.detalle }}
                          <span data-v-fae5bece=""> </span></span
                      ></span>
                    </div>
                  </div>
                </div>
                <div data-v-fae5bece="" class="frame1home-frame-image">
                  <img
                    data-v-fae5bece=""
                    alt="IMAGEImage1185"
                    :src="`/playground_assets/` + infoLegal.info1.imagen"
                    class="frame1home-i-m-a-g-e-image"
                  />
                </div>
                <v-btn
                  @click="downloadDoc(infoLegal.info1.archivo)"
                  color="azulsitio"
                  class="frame1home-frame26 rounded-pill"
                  x-large
                  style="
                    text-transform: unset !important;
                    height: 40px;
                    margin-left: -8px;
                  "
                >
                  <span data-v-fae5bece="" class="frame1home-text089"
                    ><span data-v-fae5bece="">Descargar</span></span
                  >
                </v-btn>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>

          <!-- <div
                data-v-fae5bece=""
                class="frame1home-frame1imagetop-b-outlineda-resting"
                v-if="legal"
              >
                <v-app class="ctrans">
                  <v-skeleton-loader
                    class="mx-auto"
                    style="width: 335px; top: 80px"
                    type="card"
                  ></v-skeleton-loader>
                </v-app>
              </div>
              <div
                data-v-fae5bece=""
                class="frame1home-frame1imagetop-b-outlineda-resting card2"
                v-if="!legal"
                @mouseover="hoverinfo('card2')"
                @mouseout="hoveroutinfo('card2')"
              >
                <div data-v-fae5bece="" class="frame1home-header1">
                  <div
                    data-v-fae5bece=""
                    class="frame1home-header-subheadand-avatar-autolayout1"
                  >
                    <div
                      data-v-fae5bece=""
                      class="frame1home-header-subhead-autolayout1"
                    >
                      <span data-v-fae5bece="" class="frame1home-text091"
                        ><span data-v-fae5bece="">{{
                          infoLegal.info2.titulo
                        }}</span></span
                      ><span data-v-fae5bece="" class="frame1home-text093"
                        ><span data-v-fae5bece="">
                          {{ infoLegal.info2.titulo }}
                          <span data-v-fae5bece=""> </span></span
                      ></span>
                    </div>
                  </div>
                </div>
                <div data-v-fae5bece="" class="frame1home-frame-image1">
                  <img
                    data-v-fae5bece=""
                    alt="IMAGEImage1185"
                    :src="`/playground_assets/` + infoLegal.info2.imagen"
                    class="frame1home-i-m-a-g-e-image1"
                  />
                </div>
                <v-btn
                  @click="downloadDoc(infoLegal.info2.archivo)"
                  color="azulsitio"
                  class="frame1home-frame261 rounded-pill"
                  x-large
                  style="
                    text-transform: unset !important;
                    height: 40px;
                    margin-left: -8px;
                  "
                >
                  <span data-v-fae5bece="" class="frame1home-text095"
                    ><span data-v-fae5bece="">Descargar</span></span
                  >
                </v-btn>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
          <!-- <div
                data-v-fae5bece=""
                class="frame1home-frame1imagetop-b-outlineda-resting1"
                v-if="legal"
              >
                <v-app class="ctrans">
                  <v-skeleton-loader
                    class="mx-auto"
                    style="width: 335px; top: 80px"
                    type="card"
                  ></v-skeleton-loader>
                </v-app>
              </div>
              <div
                data-v-fae5bece=""
                class="frame1home-frame1imagetop-b-outlineda-resting1 card3"
                v-if="!legal"
                @mouseover="hoverinfo('card3')"
                @mouseout="hoveroutinfo('card3')"
              >
                <div data-v-fae5bece="" class="frame1home-header2">
                  <div
                    data-v-fae5bece=""
                    class="frame1home-header-subheadand-avatar-autolayout2"
                  >
                    <div
                      data-v-fae5bece=""
                      class="frame1home-header-subhead-autolayout2"
                    >
                      <span data-v-fae5bece="" class="frame1home-text097"
                        ><span data-v-fae5bece="">{{
                          infoLegal.info3.titulo
                        }}</span></span
                      ><span data-v-fae5bece="" class="frame1home-text099"
                        ><span data-v-fae5bece="">
                          {{ infoLegal.info3.titulo }}
                          <span data-v-fae5bece=""> </span></span
                      ></span>
                    </div>
                  </div>
                </div>
                <div data-v-fae5bece="" class="frame1home-frame-image2">
                  <img
                    data-v-fae5bece=""
                    alt="IMAGEImage1185"
                    :src="`/playground_assets/` + infoLegal.info3.imagen"
                    class="frame1home-i-m-a-g-e-image2"
                  />
                </div>
                <v-btn
                  @click="downloadDoc(infoLegal.info3.archivo)"
                  color="azulsitio"
                  class="frame1home-frame262 rounded-pill"
                  x-large
                  style="
                    text-transform: unset !important;
                    height: 40px;
                    margin-left: -8px;
                  "
                >
                  <span data-v-fae5bece="" class="frame1home-text101"
                    ><span data-v-fae5bece="">Descargar</span></span
                  >
                </v-btn>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm12>
          <h1 style="display: none !important">9</h1>
          <!-- <img
                data-v-fae5bece=""
                alt="unsplashhSeGxLjozs1185"
                id="pfrecuente"
                src="/playground_assets/unsplashhsegxljozs1185-t2sj.svg"
                class="frame1home-unsplashh-se-gx-ljozs1"
              /> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
          <!-- <span class="frame1home-text030 FontProductH4">
                <span>Preguntas frecuentes</span>
              </span>
              <span class="frame1home-text032">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                  vivamus enim, ipsum
                </span>
              </span> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
          <!-- <template>
                <v-row justify="center">
                  <v-expansion-panels
                    accordion
                    focusable
                    style="
                      top: 3600px;
                      position: absolute;
                      width: 75%;
                      max-width: 1300px;
                      justify-content: center;
                      align-items: center;
                      margin-left: 350px;
                      margin-right: 175px;
                    "
                  >
                    <v-expansion-panel
                      style="margin-bottom: 45px"
                      v-for="(item, i) in pfrecuente"
                      :key="i"
                    >
                      <v-expansion-panel-header>{{
                        item.titulo
                      }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ item.detalle }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </template> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
          <!-- <div
                data-v-fae5bece=""
                class="frame1home-logo-lineadesgravamen-footer"
              >
                <div data-v-fae5bece="" class="frame1home-txt1">
                  <div data-v-fae5bece="" class="frame1home-group018">
                    <img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-k5i.svg"
                      class="frame1home-vector046"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-3azb.svg"
                      class="frame1home-vector047"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-ortd.svg"
                      class="frame1home-vector048"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-nxwk.svg"
                      class="frame1home-vector049"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-m7i.svg"
                      class="frame1home-vector050"
                    />
                  </div>
                  <div data-v-fae5bece="" class="frame1home-group019">
                    <img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-tnsg.svg"
                      class="frame1home-vector051"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-ro2h.svg"
                      class="frame1home-vector052"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-uo0u.svg"
                      class="frame1home-vector053"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-idob.svg"
                      class="frame1home-vector054"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-kac.svg"
                      class="frame1home-vector055"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-nm4.svg"
                      class="frame1home-vector056"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-6lbk.svg"
                      class="frame1home-vector057"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-8b3.svg"
                      class="frame1home-vector058"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-j9x7.svg"
                      class="frame1home-vector059"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-9lvz8.svg"
                      class="frame1home-vector060"
                    /><img
                      data-v-fae5bece=""
                      alt="Vector1185"
                      src="/playground_assets/vector1185-4af7.svg"
                      class="frame1home-vector061"
                    />
                  </div>
                  <div data-v-fae5bece="" class="frame1home-group020">
                    <div data-v-fae5bece="" class="frame1home-group021">
                      <img
                        data-v-fae5bece=""
                        alt="Vector1185"
                        src="/playground_assets/vector1185-xtz.svg"
                        class="frame1home-vector062"
                      />
                    </div>
                    <div data-v-fae5bece="" class="frame1home-group022">
                      <img
                        data-v-fae5bece=""
                        alt="Vector1185"
                        src="/playground_assets/vector1185-3u1d.svg"
                        class="frame1home-vector063"
                      />
                    </div>
                  </div>
                </div>
                <div data-v-fae5bece="" class="frame1home-img1">
                  <img
                    data-v-fae5bece=""
                    alt="Vector1185"
                    src="/playground_assets/vector1185-7hs.svg"
                    class="frame1home-vector064"
                  /><img
                    data-v-fae5bece=""
                    alt="Vector1185"
                    src="/playground_assets/vector1185-21uq.svg"
                    class="frame1home-vector065"
                  />
                </div>
              </div> -->
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
          <img
            data-v-fae5bece=""
            alt="Minimi1185"
            src="/playground_assets/minimi1185-jwr5.svg"
            class="frame1home-minimi"
          />
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">13</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">14</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">15</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">16</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">17</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">18</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">19</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">20</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">21</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">8</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">10</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none !important">12</h1>
        </v-flex>
      </v-layout>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
import privado from "../../../services/privado";
import homedata from "../../../services/home";
import contacto from "../../../services/contacto";
// import Seguros from "./components/Seguros.vue";
// import Cotizacion from "./components/Cotizacion.vue";
// import MiCuenta from "./components/MiCuenta.vue";

const PUBLIC_DOWNLOAD = process.env.VUE_APP_PUBLIC_DOWNLOAD;

export default {
  name: "Contacto",
  components: {
    // Inicio: Inicio,
    // Seguros: Seguros,
    // Cotizacion: Cotizacion,
    // MiCuenta: MiCuenta,
  },
  data() {
    return {
      nombre: "",
      mail: "",
      celular: "",
      comentarios: "",
      valid: false,
      menuCab: false,
      selectMenu: this.$store.state.selectMenu,
      misSeguros: [],
      misCotizaciones: [],
      estadoMenuPrivado: 1,
      montoSaldos: this.$store.state.montoSaldos,
      datosContrato: {},
      loginEstado: "",
      loginUsuario: "",
      nombrelogin: "",
      rutEmpresa: "",
      overlay: false,
      isLogin: this.$store.state.islogin,
      cliente: this.$store.state.cliente,
      admins: [
        ["Mis datos personales", "mdi-circle", 10],
        ["Cambia a perfil empresa", "mdi-circle", 1],
        ["Contacto Emergencia", "mdi-circle", 1],
      ],
      validnombre1: "no",
      validnombre2: "no",
      validapellido1: "no",
      validapellido2: "no",
      validmail: "no",
      validcelular: "no",
      validdireccion: "no",
      validdepto: "no",
      validnumero: "no",
      validregion: "no",
      validciudad: "no",
      validcomuna: "no",
      validcomentarios: "no",
      show1: false,
      show2: false,
      overlay: false,
      prefijoCliente: "9",
      cargaRegiones: [],
      cargaCiudades: [],
      cargaComunas: [],
      comentariosRules: [
        (v) => !!v || "el campo comentario es requerido",
        (v) => (v && v.length >= 10) || "largo mínimo 10 caracteres",
        (v) => (v && v.length <= 500) || "largo máximo 500 caracteres",
      ],
      nombre1Rules: [
        (v) => !!v || "el campo Primer nombre es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarN1(v)) || "este campo solo acepta letras",
      ],
      nombre2Rules: [
        (v) => !!v || "el campo Segundo nombre es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarN2(v)) || "este campo solo acepta letras",
      ],
      apellido1Rules: [
        (v) => !!v || "el campo Apellido Paterno es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarA1(v)) || "este campo solo acepta letras",
      ],
      apellido2Rules: [
        (v) => !!v || "el campo Apellido Materno es requerido",
        (v) => (v && v.length >= 2) || "largo mínimo 2 caracteres",
        (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
        (v) => (v && this.validarA2(v)) || "este campo solo acepta letras",
      ],
      mailRules: [
        (v) => !!v || "el campo Mail es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un Mail válido",
        (v) => this.validaMail(v) || "largo mínimo de dominio 3 caracteres",
      ],
      celularRules: [
        (v) => this.formatPhoneNumber(v) || "formato inválido",
        (v) => !!v || "el campo Celular es requerido",
        (v) => (v && v.length == 16) || "número de celular máximo 9 caracteres",
      ],
      direccionRules: [(v) => !!v || "el campo Dirección es requerido"],
      deptoRules: [
        // (v) => !!v || "el campo Departamento es requerido",
      ],
      numeroRules: [(v) => !!v || "el campo Número es requerido"],
      regionRules: [(v) => !!v || "el campo Región es requerido"],
      ciudadRules: [(v) => !!v || "el campo Ciudad es requerido"],
      comunaRules: [(v) => !!v || "el campo Comuna es requerido"],
      validclave: "no",
      validclave2: "no",
      claveRules: [
        (v) => !!v || "el campo Clave es requerido",
        (v) => (v && v.length >= 6) || "largo mínimo 6 caracteres",
        (v) => (v && v.length <= 10) || "largo máximo 10 caracteres",
        (v) => (v && v.match(/[A-Z]/)) || "debe contener a lo menos una letra mayuscula",
        (v) => (v && v.match(/[1-9]/)) || "debe contener a lo menos un número",
        (v) =>
          v.includes(".") ||
          v.includes("-") ||
          v.includes("_") ||
          v.includes("*") ||
          v.includes(",") ||
          "debe contener a lo menos un caracter especial",
      ],
      clave2Rules: [
        (v) =>
          (v && this.passIgual(v)) ||
          "La segunda clave ingresada no es idéntica a la primera, corrígela y vuelve a intentar.",
      ],
      password: "",
      password2: "",
      contrato: {},
    };
  },
  methods: {
    async guardarContacto() {
      this.overlay = true;
      let res = false;
      var formulario = {
        datosCorreo: {
          dco_nombre: this.nombre,
          dco_correo: this.mail,
          dco_fono: this.celular,
          dco_texto: this.comentarios,
        }
      };
      res = await contacto.guardar(formulario);
      console.log(res);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
        this.$refs.form.reset();
        this.validnombre1="no";
        this.validcelular="no";
        this.validmail="no";
        this.validcomentarios="no";
      } else {
        this.$alertas(res.data.estadoTransaccion.errors, "error");
      }
      this.overlay = false;
    },
    irHome(){
      this.$router.push("/home");
    },
    validarN1() {
      this.nombre = this.nombre.replace(/[^a-zA-Z\s]/gi, "");
      this.nombre = this.nombre.replace("  ", " ");
      return true;
    },
    validarN2() {
      this.formCuenta.nombre2 = this.formCuenta.nombre2.replace(/[^a-zA-Z\s]/gi, "");
      this.formCuenta.nombre2 = this.formCuenta.nombre2.replace("  ", " ");
      return true;
    },
    validarA1() {
      this.formCuenta.apellido1 = this.formCuenta.apellido1.replace(/[^a-zA-Z\s]/gi, "");
      this.formCuenta.apellido1 = this.formCuenta.apellido1.replace("  ", " ");
      return true;
    },
    validarA2() {
      this.formCuenta.apellido2 = this.formCuenta.apellido2.replace(/[^a-zA-Z\s]/gi, "");
      this.formCuenta.apellido2 = this.formCuenta.apellido2.replace("  ", " ");
      return true;
    },
    formatPhoneNumber(phone) {
      if (phone && phone != "") {
        if (phone && phone.length > 16) {
          phone = phone.substring(0, phone.length - 1);
        }
        let celular = "(+56) " + this.prefijoCliente + " ";
        if (phone.length <= 7) {
          this.celular = celular;
          return;
        }
        phone = phone.replace("(+56) " + this.prefijoCliente + " ", "");
        let letra = "";
        for (let index = 0; index < phone.length; index++) {
          letra = phone.charAt(index).replace(/[^0-9]/gi, "");
          celular = celular + letra;
        }
        this.celular = celular;
      }
      return true;
    },

    validaMail(email) {
      if (!email || email == "") {
        return;
      }
      var revmail = email.split("@");
      if (revmail.length == 2) {
        if (revmail[1].length >= 5) {
          return true;
        }
        return false;
      }
    },
    vclave(v) {
      this.validclave = v;
    },
    vclave2(v) {
      this.validclave2 = v;
    },
    vnombre1(v) {
      this.validnombre1 = v;
    },
    vnombre2(v) {
      this.validnombre2 = v;
    },
    vapellido1(v) {
      this.validapellido1 = v;
    },
    vapellido2(v) {
      this.validapellido2 = v;
    },
    vmail(v) {
      this.validmail = v;
    },
    vcelular(v) {
      this.validcelular = v;
    },
    vdireccion(v) {
      this.validdireccion = v;
    },
    vdepto(v) {
      this.validdepto = v;
    },
    vnumero(v) {
      this.validnumero = v;
    },
    vcomentarios(v) {
      this.validcomentarios = v;
    },
    vregion(v) {
      this.validcelular = v;
    },
    vciudad(v) {
      this.validciudad = v;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.guardarContacto();
      }
    },
    async cambiaMenu(menu) {
      this.$store.commit("setselectmenu", menu);
      this.overlay = true;
      var res = false;
      if (menu == 2) {
        res = await privado.traeSeguros(this.loginUsuario.contrato.ctt_numero);
        if (res.status == 200 && res.data.estadoTransaccion.success) {
          this.misSeguros = [];
          var ini = "";
          var ter = "";
          res.data.contratoSeguro.forEach((element) => {
            this.misSeguros.push({
              glosaCompania: "Línea de desgravamen Isurex",
              formaPago: element.cse_forma_pago,
              plan: element.cse_pln_glosa,
              poliza: element.cse_pln_poliza_individual,
              total: element.cse_sim_monto_credito,
              numero: element.cse_numero,
              glosaPoliza: "Línea de desgravamen Isurex",
              fechaInicio: element.cse_pln_fecha_inicio,
              fechaTermino: element.cse_pln_fecha_termino,
              estado: element.cse_estado,
              activar: false,
            });
          });
          if (this.estadoMenuPrivado == 2) {
            this.$root.$refs.PrivadoSeguros.actualizaDatos();
          }
          this.overlay = false;
        } else {
          this.$alertas(res.data.estadoTransaccion.errors, "info");
          this.overlay = false;
        }
      }
      if (menu == 3) {
        res = await privado.traeCotizacionesRut(this.rutEmpresa);
        if (res.status == 200 && res.data.estadoTransaccion.success) {
          this.misCotizaciones = [];

          res.data.cotizacionesPlan.forEach((element) => {
            this.misCotizaciones.push({
              seleccion: false,
              numero: element.seg_numero,
              plan: element.seg_producto_glosa,
              prima:
                element.spl_sim_monto_prima_uf + " / " + element.spl_sim_monto_prima_cl,
              monto: element.seg_sim_valor_seguro,
              nombre: element.seg_glosa_institucion,
              numero_plan: element.spl_numero,
            });
          });
          if (this.estadoMenuPrivado == 3) {
            this.$root.$refs.PrivadoCotizacion.actualizaDatos();
          }
          this.overlay = false;
        } else {
          this.misCotizaciones = [];
          if (this.estadoMenuPrivado == 3) {
            this.$root.$refs.PrivadoCotizacion.actualizaDatos();
          }
          this.$alertas(res.data.estadoTransaccion.errors, "info");
          this.overlay = false;
        }
      }
      if (menu == 4) {
        this.$store.commit("setselectmenu", 0);
        this.$store.commit("setestadoFormCotizacionPrivado", 3);
        this.$router.push("/formulario-cotizacion");
      }
      this.estadoMenuPrivado = menu;
      this.overlay = false;
      console.log(this.estadoMenuPrivado);
    },
    formatNumberES(n, d = 0) {
      n = new Intl.NumberFormat("es-ES").format(parseFloat(n).toFixed(d));
      if (d > 0) {
        // Obtenemos la cantidad de decimales que tiene el numero
        const decimals = n.indexOf(",") > -1 ? n.length - 1 - n.indexOf(",") : 0;

        // añadimos los ceros necesios al numero
        n = decimals == 0 ? n + "," + "0".repeat(d) : n + "0".repeat(d - decimals);
      }
      return n;
    },
    async traeSaldos() {
      let res = false;
      this.datosContrato = JSON.parse(localStorage.getItem("datosContrato"));
      res = await privado.traeSaldos(this.loginUsuario.contrato.ctt_numero);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        this.$store.commit("setmontosaldos", res.data.montoSaldos);
        this.montoSaldos = res.data.montoSaldos;
      }
    },
    hover: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("selectMenu");
    },
    hoverouttxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover-txt");
    },
    hovertxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover-txt");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("selectMenu");
    },
    cerrar: function () {
      this.$store.commit("setislogin", false);
      localStorage.removeItem("loginEstado");
      localStorage.removeItem("loginCliente");
      this.$router.push("/home");
    },
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
  mounted() {
   
  },
  created() {
    this.$root.$refs.Contacto = this;
  },
};
</script>
<style scoped>
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}
/* .v-application a {
          color: #f5f5f5;
      } */

::-webkit-scrollbar {
  display: none;
}
.campotexto{
    border-radius: 10px;
}
.shadowsInfo {
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
}

.v-skeleton-loader__bone {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 325px;
  top: 34px;
}
.v-skeleton-loader__bone {
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  top: 24px;
  width: 100%;
  height: 293px;
}
.container {
  width: 100%;
  max-width: 1920px;
  min-width: 1440px;
}
.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.boton-hover-txt {
  color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame1home-rectangle378 {
  top: -179px;
  position: absolute;
  max-width: 1921px;
  min-width: 1287px;
  width: 100%;
  height: auto;
}

.frame1home-unsplashh-se-gx-ljozs {
  top: -177px;
  position: absolute;
  width: 100%;
  max-width: 1920px;
}

.frame1home-persona-background-banner {
  left: 8.21%;
  right: 0px;
  bottom: 0px;
  position: absolute;
  background-color: transparent;
}

.frame1home-businessmanusingmobilephoneapptextingintheblueremo {
  top: 176px;
  left: 901.2822265625px;
  width: 748px;
  height: 806px;
  position: absolute;
  border-color: transparent;
}

.frame1home-formtophome {
  top: 1105px;
  /* left: 842px; */
  width: 448px;
  height: 402px;
  position: absolute;
  border-style: none;
  background-color: var(--dl-color-colores_neutrales-white);
}

.frame1home-rectangle7 {
  top: 89px;
  left: 15px;
  width: 420px;
  height: 54px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.frame1home-rectangle8 {
  top: 167px;
  left: 15px;
  width: 420px;
  height: 54px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.frame1home-rectangle379 {
  top: 245px;
  left: 15px;
  width: 420px;
  height: 54px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.frame1home-text {
  top: 18px;
  left: 15px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-text002 {
  top: 56px;
  left: 15px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-text004 {
  top: 104px;
  left: 34px;
  color: var(--dl-color-colores_neutrales-80);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-text006 {
  top: 182px;
  left: 34px;
  color: var(--dl-color-colores_neutrales-80);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-text008 {
  top: 260px;
  left: 34px;
  color: var(--dl-color-colores_neutrales-80);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-frame-cmofunciona {
  top: 2364px;
  /* left: 99px; */
  width: 1241px;
  height: 872px;
  margin-left: 4px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group491 {
  top: 153px;
  left: 0px;
  width: 1241px;
  height: 719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-frame52 {
  top: 1962px;
  /* left: 0px; */
  width: 128px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame51 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}

.frame1home-text010 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text012 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 335px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame58 {
  top: 1962px;
  /* left: 925px; */
  width: 128px;
  height: 119px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame58abajo {
  top: 1000px;
  /* left: 925px; */
  width: 128px;
  height: 119px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame511 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}

.frame1home-text014 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text016 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 335px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame57 {
  top: 533px;
  left: 0px;
  width: 316px;
  height: 186px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame512 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}

.frame1home-text018 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text020 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 335px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame59 {
  top: 2364px;
  /* left: 920px; */
  width: 130px;
  height: 186px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame59abajo {
  /* top: 2364px; */
  /* left: 920px; */
  width: 130px;
  /* height: 186px; */
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame513 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}

.frame1home-text022 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text024 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 335px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-group459 {
  top: 1831px;
  /* left: 92px; */
  width: 130px;
  height: 130px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-ellipse279 {
  top: 0px;
  left: 0px;
  width: 130px;
  height: 130px;
  position: absolute;
  border-color: transparent;
}

.frame1home-vector {
  top: 28.36328125px;
  left: 27.181640625px;
  width: 89px;
  height: 74px;
  position: absolute;
}

.frame1home-group461 {
  top: 1832px;
  /* left: 1018px; */
  width: 130px;
  height: 130px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-ellipse281 {
  top: 0px;
  left: 0px;
  width: 130px;
  height: 130px;
  position: absolute;
  border-color: transparent;
}

.frame1home-vector001 {
  top: 34.7763671875px;
  left: 38.0888671875px;
  width: 60px;
  height: 60px;
  position: absolute;
}

.frame1home-group460 {
  top: 2229px;
  /* left: 92px; */
  width: 130px;
  height: 130px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-ellipse280 {
  top: 0px;
  left: 0px;
  width: 130px;
  height: 130px;
  position: absolute;
  border-color: transparent;
}

.frame1home-vector002 {
  top: 28.152433395385742px;
  left: 28.152433395385742px;
  width: 75px;
  height: 75px;
  position: absolute;
}

.frame1home-group462 {
  top: 2229px;
  /* left: 1018px; */
  width: 130px;
  height: 130px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-ellipse2801 {
  top: 0px;
  left: 0px;
  width: 130px;
  height: 130px;
  position: absolute;
  border-color: transparent;
}

.frame1home-vector003 {
  top: 28.152433395385742px;
  left: 28.152433395385742px;
  width: 75px;
  height: 75px;
  position: absolute;
}

.frame1home-e-s-c-u-d-o1 {
  top: 1951px;
  /* left: 426px; */
  margin-left: -41px;
  width: 392px;
  height: 456px;
  position: absolute;
}

.frame1home-frame498 {
  top: 2364px;
  /* left: 321px; */
  width: 621px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame498centro {
  top: 1678px;
  /* left: 321px; */
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  display: flex;
  position: absolute;
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  align-items: center;
  border-color: transparent;
  /* -webkit-box-orient: vertical; */
  /* -webkit-box-direction: normal; */
  -ms-flex-direction: column;
  flex-direction: column;
}

.frame1home-text026 {
  /* color: var(--dl-color-colores_neutrales-50);
            height: auto;
            font-size: 65px;
            align-self: auto;
            font-style: Bold;
            text-align: left;
            font-family: Roboto;
            font-weight: 700;
            line-height: 65px;
            font-stretch: normal;
            margin-right: 0;
            margin-bottom: 16px;
            text-decoration: none; */

  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text026centro {
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  font-size: 65px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 16px;
  text-decoration: none;
}

.frame1home-text028 {
  /* color: var(--dl-color-colores_neutrales-50);
            height: auto;
            font-size: 21px;
            align-self: auto;
            font-style: Medium;
            text-align: left;
            font-family: Roboto;
            font-weight: 500;
            line-height: 128.99999618530273%;
            font-stretch: normal;
            margin-right: 0;
            margin-bottom: 0;
            text-decoration: none; */

  color: var(--dl-color-colores_neutrales-dark);
  width: 335px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text028centro {
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 128.99999618530273%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 40px;
}

.frame1home-unsplashh-se-gx-ljozs1 {
  top: 3413px;
  /* left: 0px; */
  /* width: 100%; */
  max-width: 1906px;
  height: 880px;
  position: absolute;
}

.frame1home-text030 {
  top: 3447px;
  /* width: 646px; */
  /* left: 569px; */
  left: 10%;
  right: 10%;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  line-height: 42px;
  margin-right: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame1home-text032 {
  top: 3489px;

  /* left: 378px; */
  left: 10%;
  right: 10%;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-preguntasfrecuentes {
  top: 3554px;
  left: 151px;
  width: 1137px;
  height: 486px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group501 {
  top: 0px;
  left: 0px;
  width: 1136.782470703125px;
  border: 1px solid #7130ed;
  height: 67.51947784423828px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: var(--dl-color-gray-white);
  border-style: none;
  border-width: 0;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-text034 {
  top: 26px;
  left: 97px;
  color: var(--dl-color-colores_neutrales-90);
  width: 1016px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
}

.frame1home-vector004 {
  top: 15px;
  left: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
}

.frame1home-rectangle380 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 68px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group502 {
  top: 85.93438720703125px;
  left: 0px;
  width: 1136.782470703125px;
  border: 1px solid #7130ed;
  height: 67.51947784423828px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: var(--dl-color-gray-white);
  border-style: none;
  border-width: 0;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-text036 {
  top: 18.06561279296875px;
  left: 96px;
  color: var(--dl-color-colores_neutrales-90);
  width: 1016px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
}

.frame1home-vector005 {
  top: 15px;
  left: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
}

.frame1home-rectangle382 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 68px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group503 {
  top: 171.8681640625px;
  left: 0px;
  width: 1136.782470703125px;
  border: 1px solid #7130ed;
  height: 67.51947784423828px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: var(--dl-color-gray-white);
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-text038 {
  top: 20.1318359375px;
  left: 96px;
  color: var(--dl-color-colores_neutrales-90);
  width: 1016px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
}

.frame1home-vector006 {
  top: 15px;
  left: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
}

.frame1home-rectangle384 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 68px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group504 {
  top: 257.8017578125px;
  left: 0px;
  width: 1136.782470703125px;
  height: 67.52045440673828px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-rectangle385 {
  top: 0.0009765625px;
  left: 0px;
  width: 1140px;
  height: 78px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-radius: 0px;
}

.frame1home-text040 {
  top: 22px;
  left: 97px;
  color: var(--dl-color-colores_neutrales-90);
  width: 1016px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
}

.frame1home-vector007 {
  top: 15px;
  left: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
}

.frame1home-rectangle386 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 68px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group505 {
  top: 386px;
  left: 0px;
  width: 1137px;
  height: 100px;
  position: absolute;
  border-color: var(--dl-color-colores_paleta-tonos_morado-50);
  border-style: dotted;
  border-width: 2px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-vector008 {
  top: 30px;
  left: 30px;
  width: 54px;
  height: 40px;
  position: absolute;
}

.frame1home-text042 {
  top: 25px;
  left: 110px;
  color: var(--dl-color-colores_neutrales-90);
  width: 891px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
}

.frame1home-logo-v1 {
  top: 34px;
  /* left: 148px; */
  width: 161px;
  height: 169.9999237060547px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  cursor: pointer;
}

.frame1home-txt {
  top: 118.38489532470703px;
  /* left: 0px; */
  width: 161.1430206298828px;
  height: 51.61572265625px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}

.frame1home-group {
  top: 0px;
  /* left: 47.55321502685547px; */
  width: 59.151954650878906px;
  height: 21.457965850830078px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector009 {
  top: 3.4794921875px;
  left: 0px;
  width: 11px;
  height: 18px;
  position: absolute;
}

.frame1home-vector010 {
  top: 0px;
  left: 12.178791046142578px;
  width: 6px;
  height: 21px;
  position: absolute;
}

.frame1home-vector011 {
  top: 6.9599609375px;
  left: 19.13720703125px;
  width: 12px;
  height: 14px;
  position: absolute;
}

.frame1home-vector012 {
  top: 6.9599609375px;
  left: 33.0556640625px;
  width: 13px;
  height: 14px;
  position: absolute;
}

.frame1home-vector013 {
  top: 6.9599609375px;
  left: 46.9736328125px;
  width: 12px;
  height: 14px;
  position: absolute;
}

.frame1home-group001 {
  top: 27.8388671875px;
  left: 0px;
  width: 154.25872802734375px;
  height: 23.77685546875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector014 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 19px;
  position: absolute;
}

.frame1home-vector015 {
  top: 4.0595703125px;
  left: 15.65673828125px;
  width: 13px;
  height: 14px;
  position: absolute;
}

.frame1home-vector016 {
  top: 4.0595703125px;
  left: 29.57568359375px;
  width: 11px;
  height: 14px;
  position: absolute;
}

.frame1home-vector017 {
  top: 4.0595703125px;
  left: 41.75341796875px;
  width: 12px;
  height: 20px;
  position: absolute;
}

.frame1home-vector018 {
  top: 4.0595703125px;
  left: 56.251953125px;
  width: 9px;
  height: 14px;
  position: absolute;
}

.frame1home-vector019 {
  top: 4.0595703125px;
  left: 65.53125px;
  width: 12px;
  height: 14px;
  position: absolute;
}

.frame1home-vector020 {
  top: 4.0595703125px;
  left: 78.869140625px;
  width: 13px;
  height: 14px;
  position: absolute;
}

.frame1home-vector021 {
  top: 4.0595703125px;
  left: 92.20703125px;
  width: 12px;
  height: 14px;
  position: absolute;
}

.frame1home-vector022 {
  top: 4.0595703125px;
  left: 106.70556640625px;
  width: 19px;
  height: 14px;
  position: absolute;
}

.frame1home-vector023 {
  top: 4.0595703125px;
  left: 127.58251953125px;
  width: 13px;
  height: 14px;
  position: absolute;
}

.frame1home-vector024 {
  top: 4.0595703125px;
  left: 141.50048828125px;
  width: 13px;
  height: 14px;
  position: absolute;
}

.frame1home-group002 {
  top: 26.615234375px;
  left: 155px;
  width: 6.143017768859863px;
  height: 6.143017768859863px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group003 {
  top: 1.537109375px;
  left: 2px;
  width: 2.5595905780792236px;
  height: 3.0715088844299316px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector025 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}

.frame1home-group004 {
  top: 0px;
  left: 0px;
  width: 6.143017768859863px;
  height: 6.143017768859863px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector026 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}

.frame1home-img {
  top: 0px;
  left: 27.237537384033203px;
  width: 105.92477416992188px;
  height: 105.92427062988281px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector027 {
  top: 1.8651069402694702px;
  left: 0px;
  width: 38px;
  height: 84px;
  position: absolute;
}

.frame1home-vector028 {
  top: 0px;
  left: 33.568058013916016px;
  width: 72px;
  height: 106px;
  position: absolute;
}

.frame1home-frame493 {
  top: 93px;

  width: 594px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}

.frame1home-usosdebotones {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  margin-right: 30px;
  border-radius: 100px;
}

.frame1home-text044 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 21px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  margin-right: -160px;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}

.frame1home-text046 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 21px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-icon-btns {
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 30px;
}

.frame1home-icon-btns1 {
  width: 34px;
  height: 24px;
  position: relative;
}

.frame1home-menu-desktop {
  top: 181px;
  width: 710px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
}

.frame1home-text048 {
  color: var(--dl-color-gray-white);
  width: 99px;
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-line {
  flex: none;
  width: 35px;
  border: 1px solid #ffffff;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}

.frame1home-text050 {
  color: var(--dl-color-gray-white);
  width: 180px;
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-line1 {
  flex: none;
  width: 35px;
  border: 1px solid #ffffff;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}

.frame1home-text052 {
  color: var(--dl-color-gray-white);
  width: 190px;
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-line2 {
  flex: none;
  width: 35px;
  border: 1px solid #ffffff;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}

.frame1home-text053 {
  color: var(--dl-color-gray-white);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text055 {
  top: 313px;

  width: 564px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-frame496 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-textorecuperadinerodetucredito {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 34px;
  flex-direction: column;
  justify-content: center;
}

.frame1home-text056 {
  color: var(--dl-color-gray-white);
  width: 564px;
  height: auto;
  font-size: 68px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: 69px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 24px;
  text-decoration: none;
}

.frame1home-text058 {
  color: rgba(255, 255, 255, 1);
  width: 564px;
  height: auto;
  font-size: 30px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-botones-cotizatu-seguro-cotizasinregistrarte {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}

.frame1home-usosdebotones2 {
  width: 250px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  margin-right: 30px;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}

.frame1home-text060 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 21px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-usosdebotones3 {
  width: 250px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
}

.frame1home-text062 {
  color: var(--dl-color-gray-white);
  height: auto;
  flex-grow: 1;
  font-size: 21px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame497 {
  top: 1105px;
  /* left: 148px; */
  /* width: 566px; */
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-text064 {
  color: var(--dl-color-colores_neutrales-90);
  width: 566px;
  height: auto;
  font-size: 65px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 34px;
  text-decoration: none;
}

.frame1home-text069 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 566px;
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 128.99999618530273%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 34px;
  text-decoration: none;
}

.frame1home-i-m-g-c-i-c-l-o {
  width: 563.1045532226562px;
  height: 177.560546875px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-group005 {
  top: 0px;
  left: 0px;
  width: 563.1045532226562px;
  height: 177.560546875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group006 {
  top: 0.01171875px;
  left: 0px;
  width: 365.0081787109375px;
  height: 177.5474090576172px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group007 {
  top: 0px;
  left: 0px;
  width: 361.27960205078125px;
  height: 177.5474090576172px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector029 {
  top: 0px;
  left: 0px;
  width: 361px;
  height: 178px;
  position: absolute;
}

.frame1home-group008 {
  top: 17.47265625px;
  left: 139.8232421875px;
  width: 11.854421615600586px;
  height: 11.854413032531738px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector030 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  position: absolute;
}

.frame1home-group009 {
  top: 41.93265151977539px;
  left: 353.151123046875px;
  width: 11.85693073272705px;
  height: 11.851166725158691px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector031 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  position: absolute;
}

.frame1home-group010 {
  top: 0px;
  left: 195.21719360351562px;
  width: 367.8873596191406px;
  height: 177.560546875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group011 {
  top: 0px;
  left: 3.8221259117126465px;
  width: 364.0654296875px;
  height: 177.560546875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector032 {
  top: 0px;
  left: 0px;
  width: 364px;
  height: 178px;
  position: absolute;
}

.frame1home-group012 {
  top: 118.00874328613281px;
  left: 0.00003642353476607241px;
  width: 11.865373611450195px;
  height: 11.865361213684082px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector033 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  position: absolute;
}

.frame1home-group013 {
  top: 148.22146606445312px;
  left: 216.19635009765625px;
  width: 11.8544340133667px;
  height: 11.854413986206055px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector034 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  position: absolute;
}

.frame1home-group014 {
  top: 42.6444091796875px;
  left: 71.8812484741211px;
  width: 18.47997283935547px;
  height: 27.45688819885254px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector035 {
  top: 23.216938018798828px;
  left: 5.005810260772705px;
  width: 9px;
  height: 2px;
  position: absolute;
}

.frame1home-vector036 {
  top: 25.8681640625px;
  left: 6.41552734375px;
  width: 6px;
  height: 2px;
  position: absolute;
}

.frame1home-vector037 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 22px;
  position: absolute;
}

.frame1home-vector038 {
  top: 44.8544921875px;
  left: 464.468505859375px;
  width: 23px;
  height: 23px;
  position: absolute;
}

.frame1home-vector039 {
  top: 47.06640625px;
  left: 267.6220703125px;
  width: 28px;
  height: 19px;
  position: absolute;
}

.frame1home-text076 {
  top: 76.560546875px;
  left: 40.3701171875px;
  color: rgba(140, 87, 245, 1);
  width: 81px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 128.99999618530273%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text078 {
  top: 76.560546875px;
  left: 218.3701171875px;
  color: rgba(140, 87, 245, 1);
  width: 126px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 128.99999618530273%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-text080 {
  top: 76.560546875px;
  left: 437.3701171875px;
  color: rgba(140, 87, 245, 1);
  width: 77px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 87.99999356269836%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-group500 {
  top: 2691px;
  left: 148px;
  width: 1144px;
  height: 591px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-cards {
  top: 219px;
  left: 0px;
  width: 1144px;
  height: 372px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-cards1 {
  top: 2910px;
  /* left: 0px; */
  width: 335px;
  height: 372px;
  display: flex;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-header {
  top: 0px;
  left: 0px;
  width: 346px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-header-subheadand-avatar-autolayout {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  margin-right: 0px;
}

.frame1home-header-subhead-autolayout {
  width: 313.5726013183594px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-text085 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame1home-text087 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame-image {
  top: 105px;
  left: 0px;
  width: 335px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-image {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-states {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group10 {
  top: 51px;
  left: 117.2980728149414px;
  width: 101.77486419677734px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group015 {
  top: 0px;
  left: 0px;
  width: 101.77486419677734px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector040 {
  top: 32.8125px;
  left: 19.085172653198242px;
  width: 19px;
  height: 20px;
  position: absolute;
}

.frame1home-vector041 {
  top: 0px;
  left: 0px;
  width: 102px;
  height: 92px;
  position: absolute;
}

.frame1home-frame26 {
  top: 321px;
  left: 90px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
}

.frame1home-text089 {
  top: 0px;
  /* left: 35px; */
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-cards2 {
  top: 0px;
  left: 405px;
  width: 335px;
  height: 372px;
  display: flex;
  position: absolute;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-frame1imagetop-b-outlineda-resting {
  top: 2910px;
  /* left: 0px; */
  width: 335px;
  height: 372px;
  display: flex;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-header1 {
  top: 0px;
  left: 0px;
  width: 335px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-header-subheadand-avatar-autolayout1 {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  margin-right: 0px;
}

.frame1home-header-subhead-autolayout1 {
  width: 303px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-text091 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame1home-text093 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame-image1 {
  top: 104px;
  left: 0px;
  width: 335px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-image1 {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-states1 {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group101 {
  top: 51px;
  left: 115.7861328125px;
  width: 104.98687744140625px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group016 {
  top: 51px;
  left: 117.2980728149414px;
  width: 104.98687744140625px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector042 {
  top: 32.8125px;
  left: 19.6875px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.frame1home-vector043 {
  top: 0px;
  left: 0px;
  width: 105px;
  height: 92px;
  position: absolute;
}

.frame1home-frame261 {
  top: 321px;
  left: 90px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
}

.frame1home-text095 {
  top: 0px;
  /* left: 35px; */
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-cards21 {
  top: 0px;
  left: 810px;
  width: 334px;
  height: 372px;
  display: flex;
  position: absolute;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-frame1imagetop-b-outlineda-resting1 {
  top: 2910px;
  /* left: 0px; */
  width: 335px;
  height: 372px;
  display: flex;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame1home-header2 {
  top: 0px;
  left: 0px;
  width: 335px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-header-subheadand-avatar-autolayout2 {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  margin-right: 0px;
}

.frame1home-header-subhead-autolayout2 {
  width: 303px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-text097 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame1home-text099 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame-image2 {
  top: 104px;
  left: 0px;
  width: 335px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-image2 {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-i-m-a-g-e-states2 {
  top: 0px;
  left: 0px;
  width: 335px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group102 {
  top: 51px;
  left: 115.7861328125px;
  width: 104.98687744140625px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group017 {
  top: 51px;
  left: 117.2980728149414px;
  width: 104.98687744140625px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector044 {
  top: 32.8125px;
  left: 19.6875px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.frame1home-vector045 {
  top: 0px;
  left: 0px;
  width: 105px;
  height: 92px;
  position: absolute;
}

.frame1home-frame262 {
  top: 321px;
  left: 90px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
}

.frame1home-text101 {
  top: 0px;
  /* left: 35px; */
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-frame499 {
  top: 2691px;
  /* left: 191px; */
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  margin-left: -70px;
}

.frame1home-text103 {
  color: rgba(3, 138, 188, 1);
  height: auto;
  font-size: 65px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 24px;
  text-decoration: none;
}

.frame1home-text105 {
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-usosdebotones4 {
  top: 270px;
  /* left: 857px; */
  width: 397px;
  display: flex;
  padding: 14px;
  margin-left: 10px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}

.frame1home-text110 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  position: absolute;
  top: 12px;
}

.frame1home-logo-lineadesgravamen-footer {
  top: 4390px;
  /* left: 610px; */
  width: 220px;
  height: 232.489990234375px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-txt1 {
  top: 190.6018524169922px;
  left: 0px;
  width: 259px;
  height: 83.10227966308594px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group018 {
  top: 0.00001372624683426693px;
  left: 76.43074035644531px;
  width: 95.07303619384766px;
  height: 34.5477180480957px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector046 {
  top: 5.602019786834717px;
  left: 0.00001519215675216401px;
  width: 18px;
  height: 29px;
  position: absolute;
}

.frame1home-vector047 {
  top: 0.00001372624683426693px;
  left: 19.5745906829834px;
  width: 10px;
  height: 35px;
  position: absolute;
}

.frame1home-vector048 {
  top: 11.205682754516602px;
  left: 30.75865364074707px;
  width: 20px;
  height: 23px;
  position: absolute;
}

.frame1home-vector049 {
  top: 11.205682754516602px;
  left: 53.12933349609375px;
  width: 21px;
  height: 23px;
  position: absolute;
}

.frame1home-vector050 {
  top: 11.205682754516602px;
  left: 75.49925231933594px;
  width: 20px;
  height: 23px;
  position: absolute;
}

.frame1home-group019 {
  top: 44.8210334777832px;
  left: -1.1368683772161603e-13px;
  width: 247.9351043701172px;
  height: 38.281192779541016px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector051 {
  top: 0px;
  left: 0px;
  width: 23px;
  height: 30px;
  position: absolute;
}

.frame1home-vector052 {
  top: 6.535983085632324px;
  left: 25.16457176208496px;
  width: 21px;
  height: 23px;
  position: absolute;
}

.frame1home-vector053 {
  top: 6.535983085632324px;
  left: 47.53607940673828px;
  width: 18px;
  height: 23px;
  position: absolute;
}

.frame1home-vector054 {
  top: 6.535983085632324px;
  left: 67.10892486572266px;
  width: 20px;
  height: 32px;
  position: absolute;
}

.frame1home-vector055 {
  top: 6.535983085632324px;
  left: 90.41194152832031px;
  width: 15px;
  height: 23px;
  position: absolute;
}

.frame1home-vector056 {
  top: 6.535983085632324px;
  left: 105.32630157470703px;
  width: 20px;
  height: 23px;
  position: absolute;
}

.frame1home-vector057 {
  top: 6.535983085632324px;
  left: 126.7638168334961px;
  width: 21px;
  height: 23px;
  position: absolute;
}

.frame1home-vector058 {
  top: 6.535983085632324px;
  left: 148.20140075683594px;
  width: 20px;
  height: 23px;
  position: absolute;
}

.frame1home-vector059 {
  top: 6.535983085632324px;
  left: 171.50440979003906px;
  width: 31px;
  height: 23px;
  position: absolute;
}

.frame1home-vector060 {
  top: 6.535983085632324px;
  left: 205.05929565429688px;
  width: 21px;
  height: 23px;
  position: absolute;
}

.frame1home-vector061 {
  top: 6.535983085632324px;
  left: 227.42921447753906px;
  width: 21px;
  height: 23px;
  position: absolute;
}

.frame1home-group020 {
  top: 42.85102462768555px;
  left: 249.12649536132812px;
  width: 9.873473167419434px;
  height: 9.890368461608887px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group021 {
  top: 2.474712610244751px;
  left: 3.2145259380340576px;
  width: 4.113938808441162px;
  height: 4.945192813873291px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector062 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 5px;
  position: absolute;
}

.frame1home-group022 {
  top: 0px;
  left: 0px;
  width: 9.873473167419434px;
  height: 9.890368461608887px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector063 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.frame1home-img1 {
  top: 0px;
  left: 43.77802658081055px;
  width: 170.24949645996094px;
  height: 170.54005432128906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector064 {
  top: 3.002821922302246px;
  left: 0.00001332645297225099px;
  width: 61px;
  height: 135px;
  position: absolute;
}

.frame1home-vector065 {
  top: -0.000027852287530549802px;
  left: 53.9528694152832px;
  width: 116px;
  height: 171px;
  position: absolute;
}

.frame1home-frame288928 {
  top: 732px;
  /* left: 148px; */
  width: 563px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}

.frame1home-botonestopheader {
  width: 65px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 60px;
  flex-direction: column;
}

.frame1home-iconoahorrar {
  width: 49px;
  height: 48.00054931640625px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 25px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group023 {
  top: 0px;
  left: 0px;
  width: 49.26844024658203px;
  height: 48.00054931640625px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group024 {
  top: 9.600391387939453px;
  left: 23.37474822998047px;
  width: 1.798052430152893px;
  height: 29.399999618530273px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector066 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 29px;
  position: absolute;
}

.frame1home-group025 {
  top: 17.91115951538086px;
  left: 24.00677490234375px;
  width: 16.494823455810547px;
  height: 16.013540267944336px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector067 {
  top: 0.33267325162887573px;
  left: 0.3336014747619629px;
  width: 16px;
  height: 15px;
  position: absolute;
}

.frame1home-vector068 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}

.frame1home-group026 {
  top: 18.334157943725586px;
  left: 8.905693054199219px;
  width: 15.663468360900879px;
  height: 12.979180335998535px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector069 {
  top: 0.3335898816585541px;
  left: 0.33186203241348267px;
  width: 15px;
  height: 12px;
  position: absolute;
}

.frame1home-vector070 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 13px;
  position: absolute;
}

.frame1home-group027 {
  top: 23.488367080688477px;
  left: 13.766584396362305px;
  width: 10.798877716064453px;
  height: 7.8253679275512695px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector071 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 8px;
  position: absolute;
}

.frame1home-group028 {
  top: 9.094947017729282e-13px;
  left: 15.054950714111328px;
  width: 17.3762149810791px;
  height: 16.0606689453125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group029 {
  top: 0px;
  left: 0px;
  width: 17.3762149810791px;
  height: 16.0606689453125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector072 {
  top: 0.33341583609580994px;
  left: 0.33417725563049316px;
  width: 17px;
  height: 15px;
  position: absolute;
}

.frame1home-vector073 {
  top: 0px;
  left: 0px;
  width: 17px;
  height: 16px;
  position: absolute;
}

.frame1home-group030 {
  top: 3.7782177925109863px;
  left: 5.117840766906738px;
  width: 7.0351152420043945px;
  height: 8.143522262573242px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector074 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 8px;
  position: absolute;
}

.frame1home-group031 {
  top: 2.206930637359619px;
  left: 8.166850090026855px;
  width: 1.4152836799621582px;
  height: 11.210186004638672px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector075 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 11px;
  position: absolute;
}

.frame1home-group032 {
  top: 22.952970504760742px;
  left: 24.11064338684082px;
  width: 11.7529935836792px;
  height: 10.94767951965332px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector076 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 11px;
  position: absolute;
}

.frame1home-group033 {
  top: 38.68440628051758px;
  left: 9.666089057922363px;
  width: 27.939002990722656px;
  height: 9.3147611618042px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector077 {
  top: 0px;
  left: 0px;
  width: 28px;
  height: 9px;
  position: absolute;
}

.frame1home-group034 {
  top: 47.19024658203125px;
  left: 0px;
  width: 49.26844024658203px;
  height: 0.8103038668632507px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector078 {
  top: 0px;
  left: 0px;
  width: 49px;
  height: 1px;
  position: absolute;
}

.frame1home-group035 {
  top: 44.92167663574219px;
  left: 12.89018440246582px;
  width: 1.5947723388671875px;
  height: 1.3826637268066406px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector079 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 1px;
  position: absolute;
}

.frame1home-group036 {
  top: 43.636634826660156px;
  left: 14.648019790649414px;
  width: 3.445289373397827px;
  height: 2.962500810623169px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector080 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}

.frame1home-group037 {
  top: 42.392822265625px;
  left: 16.916399002075195px;
  width: 4.717475414276123px;
  height: 4.139494895935059px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector081 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 4px;
  position: absolute;
}

.frame1home-group038 {
  top: 41.153099060058594px;
  left: 19.572662353515625px;
  width: 6.4352827072143555px;
  height: 5.5528693199157715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector082 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}

.frame1home-group039 {
  top: 40.5582275390625px;
  left: 22.699047088623047px;
  width: 6.826028823852539px;
  height: 5.676619052886963px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector083 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 6px;
  position: absolute;
}

.frame1home-group040 {
  top: 40.61732482910156px;
  left: 26.095544815063477px;
  width: 6.9143829345703125px;
  height: 6.124067306518555px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector084 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 6px;
  position: absolute;
}

.frame1home-group041 {
  top: 42.29667663574219px;
  left: 31.00702667236328px;
  width: 4.162167072296143px;
  height: 3.8970658779144287px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector085 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  position: absolute;
}

.frame1home-group042 {
  top: 9.567326545715332px;
  left: 20.525495529174805px;
  width: 10.951854705810547px;
  height: 6.02808141708374px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector086 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 6px;
  position: absolute;
}

.frame1home-group043 {
  top: 21.861385345458984px;
  left: 26.269306182861328px;
  width: 12.60925006866455px;
  height: 10.888978004455566px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector087 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 11px;
  position: absolute;
}

.frame1home-text112 {
  color: rgba(255, 255, 255, 1);
  width: 63px;
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-botonestopheader1 {
  width: 95px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 60px;
  flex-direction: column;
}

.frame1home-icono-sininteres {
  width: 49px;
  height: 48px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 25px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group044 {
  top: 0.8985165357589722px;
  left: 24.092866897583008px;
  width: 15.467167854309082px;
  height: 11.32383918762207px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector088 {
  top: 0.3565153479576111px;
  left: 0.3557872176170349px;
  width: 15px;
  height: 11px;
  position: absolute;
}

.frame1home-vector089 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 11px;
  position: absolute;
}

.frame1home-group045 {
  top: 0px;
  left: 23.951034545898438px;
  width: 0.9930221438407898px;
  height: 24.79903221130371px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector090 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 25px;
  position: absolute;
}

.frame1home-group046 {
  top: 31.110206604003906px;
  left: 30.152284622192383px;
  width: 19.14377784729004px;
  height: 16.889793395996094px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector091 {
  top: 0px;
  left: 0px;
  width: 19px;
  height: 17px;
  position: absolute;
}

.frame1home-group047 {
  top: 36.326454162597656px;
  left: 0px;
  width: 16.49220085144043px;
  height: 11.64778995513916px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector092 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 12px;
  position: absolute;
}

.frame1home-group048 {
  top: 23.17949867248535px;
  left: 15.252503395080566px;
  width: 18.025259017944336px;
  height: 24.7663516998291px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector093 {
  top: 0.3562476933002472px;
  left: 0.35371407866477966px;
  width: 17px;
  height: 24px;
  position: absolute;
}

.frame1home-vector094 {
  top: 0px;
  left: 0px;
  width: 18px;
  height: 25px;
  position: absolute;
}

.frame1home-group049 {
  top: 43.966041564941406px;
  left: 34.070159912109375px;
  width: 4.33524751663208px;
  height: 2.5394864082336426px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector095 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 3px;
  position: absolute;
}

.frame1home-group050 {
  top: 41.21703338623047px;
  left: 33.81476593017578px;
  width: 10.735517501831055px;
  height: 5.642889499664307px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector096 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 6px;
  position: absolute;
}

.frame1home-group051 {
  top: 38.90977478027344px;
  left: 34.218505859375px;
  width: 13.20397663116455px;
  height: 6.948948383331299px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector097 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 7px;
  position: absolute;
}

.frame1home-group052 {
  top: 36.493621826171875px;
  left: 34.30987548828125px;
  width: 12.53678035736084px;
  height: 6.60861873626709px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector098 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 7px;
  position: absolute;
}

.frame1home-group053 {
  top: 33.7196159362793px;
  left: 34.40278625488281px;
  width: 12.79255485534668px;
  height: 7.140800952911377px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector099 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 7px;
  position: absolute;
}

.frame1home-group054 {
  top: 32.71900939941406px;
  left: 37.20521545410156px;
  width: 10.259542465209961px;
  height: 5.652597904205322px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector100 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.frame1home-group055 {
  top: 32.76837921142578px;
  left: 41.98894119262695px;
  width: 4.941341876983643px;
  height: 2.526776075363159px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector101 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 3px;
  position: absolute;
}

.frame1home-group056 {
  top: 1.286141037940979px;
  left: 24.298561096191406px;
  width: 1.7906899452209473px;
  height: 10.532106399536133px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector102 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 11px;
  position: absolute;
}

.frame1home-group057 {
  top: 23.275699615478516px;
  left: 30.968997955322266px;
  width: 2.4473142623901367px;
  height: 24.309911727905273px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector103 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 24px;
  position: absolute;
}

.frame1home-group058 {
  top: 31.25763702392578px;
  left: 46.84230041503906px;
  width: 1.9613792896270752px;
  height: 16.461084365844727px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector104 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 16px;
  position: absolute;
}

.frame1home-group059 {
  top: 36.1868896484375px;
  left: 13.909189224243164px;
  width: 1.8515185117721558px;
  height: 11.378807067871094px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector105 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 11px;
  position: absolute;
}

.frame1home-text114 {
  color: rgba(255, 255, 255, 1);
  width: var(--dl-size-size-large);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 96.00000381469727%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-botonestopheader2 {
  width: 112px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 60px;
  flex-direction: column;
}

.frame1home-group496 {
  width: 74px;
  height: 48px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 25px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group060 {
  top: 35.759681701660156px;
  left: 67.70472717285156px;
  width: 3.923840284347534px;
  height: 1.7000240087509155px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector106 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 2px;
  position: absolute;
}

.frame1home-group061 {
  top: 33.209747314453125px;
  left: 66.71292877197266px;
  width: 6.1940412521362305px;
  height: 2.783964157104492px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector107 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 3px;
  position: absolute;
}

.frame1home-group062 {
  top: 30.844554901123047px;
  left: 66.34159088134766px;
  width: 6.913417816162109px;
  height: 2.8649280071258545px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector108 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 3px;
  position: absolute;
}

.frame1home-group063 {
  top: 28.104290008544922px;
  left: 65.60730743408203px;
  width: 7.688398361206055px;
  height: 3.406919479370117px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector109 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 3px;
  position: absolute;
}

.frame1home-group064 {
  top: 25.565553665161133px;
  left: 64.92527770996094px;
  width: 7.755422592163086px;
  height: 3.4636409282684326px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector110 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 3px;
  position: absolute;
}

.frame1home-group065 {
  top: 22.611038208007812px;
  left: 64.077392578125px;
  width: 8.402615547180176px;
  height: 4.0420966148376465px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector111 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group066 {
  top: 19.537342071533203px;
  left: 63.25471115112305px;
  width: 8.476997375488281px;
  height: 4.132235527038574px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector112 {
  top: 8.305175214218252e-9px;
  left: -0.00022104960883950753px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group067 {
  top: 17.171531677246094px;
  left: 63.46690368652344px;
  width: 6.447768211364746px;
  height: 2.6139559745788574px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector113 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 3px;
  position: absolute;
}

.frame1home-group068 {
  top: 15.560224533081055px;
  left: 66.59262084960938px;
  width: 3.1834452152252197px;
  height: 1.5359121561050415px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector114 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 2px;
  position: absolute;
}

.frame1home-group069 {
  top: 12.252761840820312px;
  left: 1.0631096363067627px;
  width: 14.988913536071777px;
  height: 26.657527923583984px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector115 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 27px;
  position: absolute;
}

.frame1home-group070 {
  top: 22.435916900634766px;
  left: 43.150489807128906px;
  width: 19.953275680541992px;
  height: 21.002426147460938px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector116 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 21px;
  position: absolute;
}

.frame1home-group071 {
  top: 35.982032775878906px;
  left: 63.37178039550781px;
  width: 10.427177429199219px;
  height: 5.558683395385742px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector117 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 6px;
  position: absolute;
}

.frame1home-group072 {
  top: 18.16916275024414px;
  left: 14.822431564331055px;
  width: 5.676516056060791px;
  height: 17.686080932617188px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector118 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 18px;
  position: absolute;
}

.frame1home-group073 {
  top: 37.7308349609375px;
  left: 19.230735778808594px;
  width: 0.744148850440979px;
  height: 0.5394553542137146px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector119 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 1px;
  position: absolute;
}

.frame1home-group074 {
  top: 33.79008483886719px;
  left: 14.593526840209961px;
  width: 0.03632747381925583px;
  height: 0.2821446359157562px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector120 {
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  position: absolute;
}

.frame1home-group075 {
  top: 39.4824104309082px;
  left: 19.282161712646484px;
  width: 5.072632789611816px;
  height: 1.8681831359863281px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector121 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 2px;
  position: absolute;
}

.frame1home-group076 {
  top: 40.84368133544922px;
  left: 23.939775466918945px;
  width: 7.220190048217773px;
  height: 3.0812253952026367px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector122 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 3px;
  position: absolute;
}

.frame1home-group077 {
  top: 42.97562026977539px;
  left: 27.764904022216797px;
  width: 11.953587532043457px;
  height: 4.518550395965576px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector123 {
  top: 9.035190373651858e-9px;
  left: -0.00024047553951334066px;
  width: 12px;
  height: 5px;
  position: absolute;
}

.frame1home-group078 {
  top: 42.462921142578125px;
  left: 41.02806854248047px;
  width: 4.983090877532959px;
  height: 2.4810118675231934px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector124 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 2px;
  position: absolute;
}

.frame1home-group079 {
  top: 33.485511779785156px;
  left: 45.954200744628906px;
  width: 4.823142051696777px;
  height: 11.20527458190918px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector125 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 11px;
  position: absolute;
}

.frame1home-group080 {
  top: 14.749107360839844px;
  left: 8.143824577331543px;
  width: 8.246606826782227px;
  height: 24.498950958251953px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector126 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 24px;
  position: absolute;
}

.frame1home-group081 {
  top: 13.34066390991211px;
  left: 57.088890075683594px;
  width: 17.103944778442383px;
  height: 28.796541213989258px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector127 {
  top: 0px;
  left: 0px;
  width: 17px;
  height: 29px;
  position: absolute;
}

.frame1home-group082 {
  top: 16.22201919555664px;
  left: 61.09349822998047px;
  width: 7.451139450073242px;
  height: 24.1073055267334px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector128 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 24px;
  position: absolute;
}

.frame1home-group083 {
  top: 15.324359893798828px;
  left: 28.305286407470703px;
  width: 29.40070152282715px;
  height: 13.921491622924805px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector129 {
  top: 0px;
  left: 0px;
  width: 29px;
  height: 14px;
  position: absolute;
}

.frame1home-group084 {
  top: 38.617828369140625px;
  left: 55.0229377746582px;
  width: 8.061245918273926px;
  height: 4.004541873931885px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector130 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group085 {
  top: 22.389570236206055px;
  left: 42.004817962646484px;
  width: 13.665559768676758px;
  height: 21.47161865234375px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector131 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 21px;
  position: absolute;
}

.frame1home-group086 {
  top: 41.430545806884766px;
  left: 35.5567512512207px;
  width: 10.729280471801758px;
  height: 6.245994567871094px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector132 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 6px;
  position: absolute;
}

.frame1home-group087 {
  top: 37.55046844482422px;
  left: 43.54237365722656px;
  width: 7.310060977935791px;
  height: 7.325864315032959px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector133 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 7px;
  position: absolute;
}

.frame1home-group088 {
  top: 37.93080520629883px;
  left: 18.85390853881836px;
  width: 12.715596199035645px;
  height: 6.368969917297363px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector134 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 6px;
  position: absolute;
}

.frame1home-group089 {
  top: 40.48633575439453px;
  left: 27.191333770751953px;
  width: 8.382722854614258px;
  height: 6.3439040184021px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector135 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 6px;
  position: absolute;
}

.frame1home-group090 {
  top: 42.721839904785156px;
  left: 31.416959762573242px;
  width: 6.499551296234131px;
  height: 5.184023380279541px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector136 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 5px;
  position: absolute;
}

.frame1home-group091 {
  top: 33.6025505065918px;
  left: 13.967706680297852px;
  width: 10.871928215026855px;
  height: 6.558183193206787px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector137 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 7px;
  position: absolute;
}

.frame1home-group092 {
  top: 13.395984649658203px;
  left: 19.496685028076172px;
  width: 21.789670944213867px;
  height: 5.14161491394043px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector138 {
  top: 0px;
  left: 0px;
  width: 22px;
  height: 5px;
  position: absolute;
}

.frame1home-group093 {
  top: 35.759681701660156px;
  left: 67.70472717285156px;
  width: 3.923840284347534px;
  height: 1.7000240087509155px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector139 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 2px;
  position: absolute;
}

.frame1home-group094 {
  top: 33.209747314453125px;
  left: 66.71292877197266px;
  width: 6.1940412521362305px;
  height: 2.783964157104492px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector140 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 3px;
  position: absolute;
}

.frame1home-group095 {
  top: 30.844554901123047px;
  left: 66.34159088134766px;
  width: 6.913417816162109px;
  height: 2.8649280071258545px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector141 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 3px;
  position: absolute;
}

.frame1home-group096 {
  top: 28.104290008544922px;
  left: 65.60730743408203px;
  width: 7.688398361206055px;
  height: 3.406919479370117px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector142 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 3px;
  position: absolute;
}

.frame1home-group097 {
  top: 25.565553665161133px;
  left: 64.92527770996094px;
  width: 7.755422592163086px;
  height: 3.4636409282684326px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector143 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 3px;
  position: absolute;
}

.frame1home-group098 {
  top: 22.611038208007812px;
  left: 64.077392578125px;
  width: 8.402615547180176px;
  height: 4.0420966148376465px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector144 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group099 {
  top: 19.537342071533203px;
  left: 63.25471115112305px;
  width: 8.476997375488281px;
  height: 4.132235527038574px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector145 {
  top: 8.305175214218252e-9px;
  left: -0.00022104960883950753px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group100 {
  top: 17.171531677246094px;
  left: 63.46690368652344px;
  width: 6.447768211364746px;
  height: 2.6139559745788574px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector146 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 3px;
  position: absolute;
}

.frame1home-group101 {
  top: 15.560224533081055px;
  left: 66.59262084960938px;
  width: 3.1834452152252197px;
  height: 1.5359121561050415px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector147 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 2px;
  position: absolute;
}

.frame1home-group102 {
  top: 12.252761840820312px;
  left: 1.0631096363067627px;
  width: 14.988913536071777px;
  height: 26.657527923583984px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector148 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 27px;
  position: absolute;
}

.frame1home-group103 {
  top: 18.16916275024414px;
  left: 14.822431564331055px;
  width: 5.676516056060791px;
  height: 17.686080932617188px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector149 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 18px;
  position: absolute;
}

.frame1home-group104 {
  top: 37.7308349609375px;
  left: 19.230735778808594px;
  width: 0.744148850440979px;
  height: 0.5394553542137146px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector150 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 1px;
  position: absolute;
}

.frame1home-group105 {
  top: 33.79008483886719px;
  left: 14.593526840209961px;
  width: 0.03632747381925583px;
  height: 0.2821446359157562px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector151 {
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  position: absolute;
}

.frame1home-group106 {
  top: 11.60519790649414px;
  left: 0.4184577465057373px;
  width: 19.91738510131836px;
  height: 28.779722213745117px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector152 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 29px;
  position: absolute;
}

.frame1home-group107 {
  top: 14.749107360839844px;
  left: 8.143824577331543px;
  width: 8.246606826782227px;
  height: 24.498950958251953px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector153 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 24px;
  position: absolute;
}

.frame1home-group108 {
  top: 38.617828369140625px;
  left: 55.0229377746582px;
  width: 8.061245918273926px;
  height: 4.004541873931885px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector154 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group109 {
  top: 3.927365779876709px;
  left: 49.38695526123047px;
  width: 0.841306209564209px;
  height: 11.077889442443848px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector155 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 11px;
  position: absolute;
}

.frame1home-group110 {
  top: 3.3028743267059326px;
  left: 47.32673645019531px;
  width: 4.8863372802734375px;
  height: 3.9565656185150146px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector156 {
  top: 7.870511353758047e-9px;
  left: -0.00020948107722063014px;
  width: 5px;
  height: 4px;
  position: absolute;
}

.frame1home-group111 {
  top: 1.8189894035458565e-12px;
  left: 37.903282165527344px;
  width: 4.888311386108398px;
  height: 11.672098159790039px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group112 {
  top: 0.6241872803494406px;
  left: 2.061926622993333px;
  width: 0.841584324836731px;
  height: 11.077889442443848px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector157 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 11px;
  position: absolute;
}

.frame1home-group113 {
  top: 7.966323417444611e-9px;
  left: -0.00021203461294305725px;
  width: 4.888107776641846px;
  height: 3.956331491470337px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector158 {
  top: 7.638124652369058e-9px;
  left: -0.00020329063855228924px;
  width: 5px;
  height: 4px;
  position: absolute;
}

.frame1home-group114 {
  top: 1.2091878652572632px;
  left: 27.111093521118164px;
  width: 4.886736869812012px;
  height: 11.671649932861328px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group115 {
  top: 0.6238687695396585px;
  left: 2.0628261328536723px;
  width: 0.841584324836731px;
  height: 11.077889442443848px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector159 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 11px;
  position: absolute;
}

.frame1home-group116 {
  top: 7.966326043273853e-9px;
  left: -0.00021203267732516906px;
  width: 4.886533737182617px;
  height: 3.9563050270080566px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector160 {
  top: 7.63801621950222e-9px;
  left: -0.00020328896491150772px;
  width: 5px;
  height: 4px;
  position: absolute;
}

.frame1home-text116 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 96.00000381469727%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-botonestopheader3 {
  width: 111px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame1home-icono-devolucion {
  width: 55px;
  height: 48.00043487548828px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 25px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group117 {
  top: 6.367249488830566px;
  left: 10.175859451293945px;
  width: 1.173172116279602px;
  height: 23.140459060668945px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector161 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 23px;
  position: absolute;
}

.frame1home-group118 {
  top: 11.779212951660156px;
  left: 10.517709732055664px;
  width: 10.41014575958252px;
  height: 10.74570083618164px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector162 {
  top: 0.32729417085647583px;
  left: 0.3257647454738617px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.frame1home-vector163 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 11px;
  position: absolute;
}

.frame1home-group119 {
  top: 12.058649063110352px;
  left: 1.2059413194656372px;
  width: 9.895646095275879px;
  height: 8.749117851257324px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector164 {
  top: 0.32581961154937744px;
  left: 0.3233572840690613px;
  width: 9px;
  height: 8px;
  position: absolute;
}

.frame1home-vector165 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 9px;
  position: absolute;
}

.frame1home-group120 {
  top: 0px;
  left: 4.997927188873291px;
  width: 10.952994346618652px;
  height: 10.774527549743652px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector166 {
  top: 0.3257647454738617px;
  left: 0.3241155743598938px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.frame1home-vector167 {
  top: 0px;
  left: 0px;
  width: 11px;
  height: 11px;
  position: absolute;
}

.frame1home-group121 {
  top: 2.485790252685547px;
  left: 8.154863357543945px;
  width: 4.576113700866699px;
  height: 5.568251132965088px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector168 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 6px;
  position: absolute;
}

.frame1home-group122 {
  top: 1.4548399448394775px;
  left: 10.036113739013672px;
  width: 1.1089483499526978px;
  height: 7.583863258361816px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector169 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 8px;
  position: absolute;
}

.frame1home-group123 {
  top: 23.26541519165039px;
  left: 4.547473508864641e-13px;
  width: 44.5482292175293px;
  height: 21.537200927734375px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector170 {
  top: 0px;
  left: 1.0755920953897657e-7px;
  width: 45px;
  height: 22px;
  position: absolute;
}

.frame1home-group124 {
  top: 29.411026000976562px;
  left: 43.074256896972656px;
  width: 12.249835968017578px;
  height: 18.58940887451172px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector171 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 19px;
  position: absolute;
}

.frame1home-group125 {
  top: 33.08075714111328px;
  left: 47.25273895263672px;
  width: 3.8877248764038086px;
  height: 3.6402809619903564px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector172 {
  top: 0.37088239192962646px;
  left: 0.3701176941394806px;
  width: 3px;
  height: 3px;
  position: absolute;
}

.frame1home-vector173 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  position: absolute;
}

.frame1home-group126 {
  top: 44.88365173339844px;
  left: 45.25453567504883px;
  width: 3.4181275367736816px;
  height: 1.6737114191055298px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector174 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 2px;
  position: absolute;
}

.frame1home-group127 {
  top: 42.972652435302734px;
  left: 45.58565139770508px;
  width: 7.246945381164551px;
  height: 3.5845513343811035px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector175 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 4px;
  position: absolute;
}

.frame1home-group128 {
  top: 40.98930358886719px;
  left: 46.09412384033203px;
  width: 7.12089729309082px;
  height: 3.727858066558838px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector176 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 4px;
  position: absolute;
}

.frame1home-group129 {
  top: 38.784358978271484px;
  left: 45.49235916137695px;
  width: 7.754128932952881px;
  height: 4.176953315734863px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector177 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group130 {
  top: 36.65924072265625px;
  left: 45.72712326049805px;
  width: 7.828969478607178px;
  height: 4.130939483642578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector178 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame1home-group131 {
  top: 36.98839569091797px;
  left: 50.852073669433594px;
  width: 2.706294298171997px;
  height: 1.6954922676086426px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector179 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 2px;
  position: absolute;
}

.frame1home-group132 {
  top: 35.433414459228516px;
  left: 51.8463020324707px;
  width: 1.8069020509719849px;
  height: 1.0788228511810303px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector180 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 1px;
  position: absolute;
}

.frame1home-group133 {
  top: 30.917827606201172px;
  left: 49.159122467041016px;
  width: 4.653219699859619px;
  height: 3.0641918182373047px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector181 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 3px;
  position: absolute;
}

.frame1home-group134 {
  top: 31.22170639038086px;
  left: 45.48081970214844px;
  width: 2.1994948387145996px;
  height: 1.5525411367416382px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector182 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.frame1home-group135 {
  top: 29.670284271240234px;
  left: 52.20093536376953px;
  width: 2.5745248794555664px;
  height: 17.67410659790039px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector183 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 18px;
  position: absolute;
}

.frame1home-group136 {
  top: 4.772046089172363px;
  left: 6.805061340332031px;
  width: 8.925427436828613px;
  height: 5.873474597930908px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector184 {
  top: 0px;
  left: 0px;
  width: 9px;
  height: 6px;
  position: absolute;
}

.frame1home-group137 {
  top: 12.329607009887695px;
  left: 10.767827987670898px;
  width: 9.8497896194458px;
  height: 9.769856452941895px;
  display: flex;
  opacity: 0.13;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector185 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.frame1home-text118 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 96%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-footer {
  margin-left: -474px;
  width: 1906px;
  height: 362px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: absolute;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(247, 247, 247, 1);
  margin-top: 129px;
}

.frame1home-line23 {
  top: 185.181640625px;
  left: 240px;
  width: 1081px;
  height: 0px;
  position: absolute;
}

.frame1home-cajamenfooter {
  top: 42px;
  left: 417px;
  width: 140px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame1home-text119 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text121 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text123 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text125 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-caja-quienessomosfooter {
  top: 42px;
  left: 807px;
  width: 113px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame1home-text127 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text129 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text131 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text133 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text135 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-caja-segurofooter {
  top: 42px;
  left: 643px;
  width: 78px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame1home-text137 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text139 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text141 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text143 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-cajacontactofooter {
  top: 42px;
  left: 1006px;
  width: 285px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame1home-text145 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text147 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text149 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text151 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame1home-text153 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame1home-rectangle2374 {
  top: 322px;
  /* left: 0px; */
  width: 1906px;
  height: 40px;
  position: absolute;
  border-color: transparent;
}

.frame1home-group138 {
  top: 38px;
  left: 0px;
  width: 87.0000991821289px;
  height: 283.55206298828125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group139 {
  top: 183.783203125px;
  left: 8.86767578125px;
  width: 54.09845733642578px;
  height: 99.76887512207031px;
  display: flex;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector186 {
  top: 4.328125px;
  left: 1.89794921875px;
  width: 52px;
  height: 95px;
  opacity: 0.05;
  position: absolute;
}

.frame1home-vector187 {
  top: 8.615234375px;
  left: 3.81298828125px;
  width: 50px;
  height: 91px;
  opacity: 0.1;
  position: absolute;
}

.frame1home-vector188 {
  top: 13.005859375px;
  left: 5.68603515625px;
  width: 48px;
  height: 86px;
  opacity: 0.14;
  position: absolute;
}

.frame1home-vector189 {
  top: 17.314453125px;
  left: 7.58251953125px;
  width: 46px;
  height: 82px;
  opacity: 0.19;
  position: absolute;
}

.frame1home-vector190 {
  top: 21.64453125px;
  left: 9.47216796875px;
  width: 44px;
  height: 77px;
  opacity: 0.24;
  position: absolute;
}

.frame1home-vector191 {
  top: 25.9940128326416px;
  left: 11.377463340759277px;
  width: 42px;
  height: 73px;
  opacity: 0.29;
  position: absolute;
}

.frame1home-vector192 {
  top: 30.341796875px;
  left: 13.2802734375px;
  width: 40px;
  height: 68px;
  opacity: 0.33;
  position: absolute;
}

.frame1home-vector193 {
  top: 34.63066864013672px;
  left: 15.089670181274414px;
  width: 38px;
  height: 64px;
  opacity: 0.38;
  position: absolute;
}

.frame1home-vector194 {
  top: 39px;
  left: 16.95556640625px;
  width: 36px;
  height: 59px;
  opacity: 0.43;
  position: absolute;
}

.frame1home-vector195 {
  top: 43.34980010986328px;
  left: 18.815692901611328px;
  width: 34px;
  height: 55px;
  opacity: 0.48;
  position: absolute;
}

.frame1home-vector196 {
  top: 47.740440368652344px;
  left: 20.674196243286133px;
  width: 32px;
  height: 50px;
  opacity: 0.52;
  position: absolute;
}

.frame1home-vector197 {
  top: 51.986328125px;
  left: 22.498046875px;
  width: 30px;
  height: 46px;
  opacity: 0.57;
  position: absolute;
}

.frame1home-vector198 {
  top: 56.3359375px;
  left: 24.338134765625px;
  width: 28px;
  height: 41px;
  opacity: 0.62;
  position: absolute;
}

.frame1home-vector199 {
  top: 60.705078125px;
  left: 26.178955078125px;
  width: 26px;
  height: 37px;
  opacity: 0.67;
  position: absolute;
}

.frame1home-vector200 {
  top: 65.0546875px;
  left: 28.001220703125px;
  width: 24px;
  height: 32px;
  opacity: 0.71;
  position: absolute;
}

.frame1home-vector201 {
  top: 69.40608215332031px;
  left: 29.80400848388672px;
  width: 22px;
  height: 28px;
  opacity: 0.76;
  position: absolute;
}

.frame1home-vector202 {
  top: 73.671875px;
  left: 31.6181640625px;
  width: 20px;
  height: 23px;
  opacity: 0.81;
  position: absolute;
}

.frame1home-vector203 {
  top: 78.03515625px;
  left: 33.421142578125px;
  width: 19px;
  height: 19px;
  opacity: 0.86;
  position: absolute;
}

.frame1home-vector204 {
  top: 82.328125px;
  left: 35.244140625px;
  width: 17px;
  height: 15px;
  opacity: 0.9;
  position: absolute;
}

.frame1home-vector205 {
  top: 86.38858795166016px;
  left: 37.01726531982422px;
  width: 15px;
  height: 10px;
  opacity: 0.95;
  position: absolute;
}

.frame1home-vector206 {
  top: 89.765625px;
  left: 38.813720703125px;
  width: 13px;
  height: 7px;
  position: absolute;
}

.frame1home-vector207 {
  top: 260.162109375px;
  left: 51.68408203125px;
  width: 28px;
  height: 16px;
  position: absolute;
}

.frame1home-vector208 {
  top: 268.484375px;
  left: 51.746337890625px;
  width: 28px;
  height: 7px;
  position: absolute;
}

.frame1home-vector209 {
  top: 105.66619110107422px;
  left: 10.786811828613281px;
  width: 53px;
  height: 159px;
  position: absolute;
}

.frame1home-vector210 {
  top: 122.2109375px;
  left: 10.76611328125px;
  width: 49px;
  height: 143px;
  position: absolute;
}

.frame1home-vector211 {
  top: 254.7088165283203px;
  left: 36.814327239990234px;
  width: 24px;
  height: 27px;
  position: absolute;
}

.frame1home-group140 {
  top: 257.2265625px;
  left: 36.81396484375px;
  width: 23.727771759033203px;
  height: 24.127182006835938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector212 {
  top: 0px;
  left: 17.33056640625px;
  width: 0px;
  height: 0px;
  position: absolute;
}

.frame1home-vector213 {
  top: 2.14453125px;
  left: 0px;
  width: 24px;
  height: 22px;
  position: absolute;
}

.frame1home-vector214 {
  top: 111.1396484375px;
  left: 41.006103515625px;
  width: 44px;
  height: 149px;
  position: absolute;
}

.frame1home-vector215 {
  top: 31.6494140625px;
  left: 9.4072265625px;
  width: 56px;
  height: 97px;
  position: absolute;
}

.frame1home-vector216 {
  top: 47.066612243652344px;
  left: 9.428058624267578px;
  width: 20px;
  height: 81px;
  position: absolute;
}

.frame1home-vector217 {
  top: 34.3076171875px;
  left: 24.13427734375px;
  width: 25px;
  height: 21px;
  position: absolute;
}

.frame1home-vector218 {
  top: 17.49609375px;
  left: 26.92041015625px;
  width: 21px;
  height: 30px;
  position: absolute;
}

.frame1home-vector219 {
  top: 17.49609375px;
  left: 32.65478515625px;
  width: 15px;
  height: 27px;
  position: absolute;
}

.frame1home-vector220 {
  top: 2.9853515625px;
  left: 30.229736328125px;
  width: 26px;
  height: 38px;
  position: absolute;
}

.frame1home-vector221 {
  top: 0px;
  left: 27.21062660217285px;
  width: 34px;
  height: 29px;
  position: absolute;
}

.frame1home-vector222 {
  top: 89.7470703125px;
  left: 50.8291015625px;
  width: 12px;
  height: 6px;
  position: absolute;
}

.frame1home-group141 {
  top: 82.1923828125px;
  left: 43.31591796875px;
  width: 15.177910804748535px;
  height: 27.98879623413086px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector223 {
  top: 0.30078125px;
  left: 0.451904296875px;
  width: 15px;
  height: 28px;
  position: absolute;
}

.frame1home-vector224 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 28px;
  position: absolute;
}

.frame1home-vector225 {
  top: 91.7861557006836px;
  left: 43.08709716796875px;
  width: 23px;
  height: 14px;
  position: absolute;
}

.frame1home-vector226 {
  top: 37.0341796875px;
  left: 58.5244140625px;
  width: 28px;
  height: 63px;
  position: absolute;
}

.frame1home-vector227 {
  top: 130.951171875px;
  left: 14.9365234375px;
  width: 18px;
  height: 18px;
  position: absolute;
}

.frame1home-vector228 {
  top: 41.822181701660156px;
  left: 0px;
  width: 25px;
  height: 97px;
  position: absolute;
}

.frame1home-vector229 {
  top: 145.267578125px;
  left: 40.985107421875px;
  width: 32px;
  height: 114px;
  position: absolute;
}

.frame1home-vector230 {
  top: 61.007633209228516px;
  left: 58.9627799987793px;
  width: 18px;
  height: 32px;
  position: absolute;
}

.frame1home-group288892 {
  top: 212px;
  left: 262px;
  width: 657px;
  height: 81px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-text155 {
  top: 16px;
  left: 240px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 495px;
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1home-text156 {
  font-weight: 500;
}

.frame1home-g-r-u-p-o-i-n-s-u-r-e-x-s-v-g1 {
  top: 0px;
  left: 92px;
  width: 97px;
  height: 81px;
  position: absolute;
  border-color: transparent;
}

.frame1home-logo-lineadesgravamen-footer1 {
  top: 48.4041633605957px;
  left: 360px;
  width: 85.00013732910156px;
  height: 90.7261734008789px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-txt2 {
  top: 63.2845458984375px;
  left: 0px;
  width: 85.00013732910156px;
  height: 27.44162368774414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group142 {
  top: 0.0002319185296073556px;
  left: 25.083524703979492px;
  width: 31.201702117919922px;
  height: 11.408534049987793px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector231 {
  top: 1.8503669500350952px;
  left: 0.000029019884095760062px;
  width: 6px;
  height: 10px;
  position: absolute;
}

.frame1home-vector232 {
  top: 0.0002319185296073556px;
  left: 6.424156665802002px;
  width: 3px;
  height: 11px;
  position: absolute;
}

.frame1home-vector233 {
  top: 3.7005021572113037px;
  left: 10.094902992248535px;
  width: 6px;
  height: 8px;
  position: absolute;
}

.frame1home-vector234 {
  top: 3.7005021572113037px;
  left: 17.436397552490234px;
  width: 7px;
  height: 8px;
  position: absolute;
}

.frame1home-vector235 {
  top: 3.7005021572113037px;
  left: 24.777891159057617px;
  width: 6px;
  height: 8px;
  position: absolute;
}

.frame1home-group143 {
  top: 14.800132751464844px;
  left: -0.0001068115234375px;
  width: 81.3687515258789px;
  height: 12.6417236328125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector236 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame1home-vector237 {
  top: 2.15820574760437px;
  left: 8.258431434631348px;
  width: 7px;
  height: 8px;
  position: absolute;
}

.frame1home-vector238 {
  top: 2.15820574760437px;
  left: 15.600566864013672px;
  width: 6px;
  height: 8px;
  position: absolute;
}

.frame1home-vector239 {
  top: 2.15820574760437px;
  left: 22.024267196655273px;
  width: 6px;
  height: 10px;
  position: absolute;
}

.frame1home-vector240 {
  top: 2.15820574760437px;
  left: 29.671905517578125px;
  width: 5px;
  height: 8px;
  position: absolute;
}

.frame1home-vector241 {
  top: 2.15820574760437px;
  left: 34.566802978515625px;
  width: 6px;
  height: 8px;
  position: absolute;
}

.frame1home-vector242 {
  top: 2.15820574760437px;
  /* left: 41.6019401550293px; */
  width: 7px;
  height: 8px;
  position: absolute;
}

.frame1home-vector243 {
  top: 2.15820574760437px;
  left: 48.63771438598633px;
  width: 6px;
  height: 8px;
  position: absolute;
}

.frame1home-vector244 {
  top: 2.15820574760437px;
  /* left: 56.28514099121094px; */
  width: 10px;
  height: 8px;
  position: absolute;
}

.frame1home-vector245 {
  top: 2.15820574760437px;
  /* left: 68rem; */
  width: 7px;
  height: 8px;
  position: absolute;
}

/* .frame1home-vector246 {
            top: 2.15820574760437px;
            left: 68rem;
            width: 7px;
            height: 8px;
            position: absolute;
        } */

.frame1home-group144 {
  top: 14.14947509765625px;
  left: 81.75969696044922px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-group145 {
  top: 0.8171027898788452px;
  left: 1.0551077127456665px;
  width: 1.3501358032226562px;
  height: 1.633067011833191px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

/* .frame1home-vector247 {
            top: 0px;
            left: 68rem;
            width: 1px;
            height: 2px;
            position: absolute;
        } */

.frame1home-group146 {
  top: 0px;
  left: 0px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector248 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}

.frame1home-img2 {
  top: 0px;
  left: 14.36742877960205px;
  width: 55.87344741821289px;
  height: 56.31800079345703px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector249 {
  top: 0.9916815161705017px;
  left: -0.00005660363603965379px;
  width: 20px;
  height: 45px;
  position: absolute;
}

.frame1home-vector250 {
  top: -0.0001346481585642323px;
  left: 17.706514358520508px;
  width: 38px;
  height: 56px;
  position: absolute;
}

.frame1home-socialwrapper {
  top: 212px;
  left: 1095px;
  width: 144px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-facebook-negative {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}

.frame1home-twitter-negative {
  top: 0px;
  left: 42.666015625px;
  width: 16px;
  height: 16px;
  position: absolute;
}

.frame1home-instagram-negative {
  top: 0px;
  left: 85.333984375px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-group147 {
  top: 0px;
  left: 0px;
  width: 15.990626335144043px;
  height: 15.996874809265137px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector251 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}

.frame1home-vector252 {
  top: 3.890625px;
  left: 3.890625px;
  width: 8px;
  height: 8px;
  position: absolute;
}

.frame1home-vector253 {
  top: 2.76953125px;
  left: 11.3125px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.frame1home-linked-in-negative {
  top: 0px;
  left: 128px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-group148 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame1home-vector254 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}

.frame1home-minimi {
  margin-top: 71px;
  /* left: 1284px; */
  width: 73px;
  height: 125px;
  position: relative;
}
.frame1home-minimi2 {
  top: 4577px;
  left: 1284px;
  width: 73px;
  height: 125px;
  position: absolute;
}

.frame1home-textsbtns-siper-header {
  top: 39px;
  left: 1081px;
  width: 104px;
  height: 25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

/* .frame1home-text158 {
           
            color: var(--dl-color-gray-white);
            width: var(--dl-size-size-large);
            height: auto;
            position: absolute;
            font-size: 15px;
            align-self: auto;
            font-style: Regular;
            text-align: center;
            font-family: IBM Plex Sans Thai;
            font-weight: 400;
            line-height: normal;
            font-stretch: normal;
            margin-right: 0;
            margin-bottom: 0;
            text-decoration: underline;
        } */

.frame1home-textsbtns-siper-header1 {
  top: 39px;
  left: 1207px;
  width: 105px;
  height: 25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

/* .frame1home-text160 {
            left: 34px;
            color: var(--dl-color-gray-white);
            height: auto;
            position: absolute;
            font-size: 15px;
            align-self: auto;
            font-style: Regular;
            text-align: center;
            font-family: IBM Plex Sans Thai;
            font-weight: 400;
            line-height: normal;
            font-stretch: normal;
            margin-right: 0;
            margin-bottom: 0;
            text-decoration: underline;
        } */

.frame1home-polygon2 {
  top: 17px;
  left: 101px;
  width: 6px;
  height: 5px;
  position: absolute;
}

.frame1home-subwayworld1 {
  top: 2px;
  left: 6px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.frame1home-textsbtns-siper-header2 {
  top: 39px;
  left: 943px;
  width: 116px;
  height: 25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame1home-signo-accesibilidad {
  top: 2px;
  left: 0px;
  width: 22px;
  height: 22px;
  position: absolute;
}

/* .frame1home-text162 {
            left: 30px;
            color: var(--dl-color-gray-white);
            height: auto;
            position: absolute;
            font-size: 15px;
            align-self: auto;
            font-style: Regular;
            text-align: center;
            font-family: IBM Plex Sans Thai;
            font-weight: 400;
            line-height: normal;
            font-stretch: normal;
            margin-right: 0;
            margin-bottom: 0;
            text-decoration: underline;
        } */

.frame1home-imagen-hombre-home1 {
  top: 260px;
  width: 100%;
  max-width: 450px;
  height: auto;
  position: absolute;
  border-color: transparent;
}

.frame1home-vector1 {
  top: 845px;
  /* left: 863px; */
  width: 2088px;
  height: auto;
  position: absolute;
  margin-left: -1137px;
}

.frame1home-dropdownfinal {
  top: 3539px;
  /* left: 148px; */
  /* max-width: 1142px;
          width: auto; */

  margin-left: 100px;
  display: flex;
  padding: 24px 16px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.frame1home-text156 {
  color: var(--dl-color-colores_neutrales-60);
  /* width: 1063px; */
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 24px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame1home-vector244 {
  width: 25px;
  height: 12px;
  position: relative;
}
.frame1home-dropdownfinal1 {
  top: 3713px;
  /* left: 148px; */
  max-width: 1142px;
  width: auto;
  margin-left: 100px;
  display: flex;
  padding: 24px 16px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.frame1home-text158 {
  color: var(--dl-color-colores_neutrales-60);
  width: 1063px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 24px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame1home-vector245 {
  width: 25px;
  height: 12px;
  position: relative;
}
.frame1home-dropdownfinal2 {
  top: 3887px;
  /* left: 148px; */
  max-width: 1142px;
  width: auto;
  margin-left: 100px;
  display: flex;
  padding: 24px 16px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.frame1home-text160 {
  color: var(--dl-color-colores_neutrales-60);
  width: 1063px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 24px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame1home-vector246 {
  width: 25px;
  height: 12px;
  position: relative;
}
.frame1home-dropdownfinal3 {
  top: 4061px;
  /* left: 148px; */
  max-width: 1142px;
  width: auto;
  margin-left: 100px;
  display: flex;
  padding: 24px 16px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.frame1home-text162 {
  color: var(--dl-color-colores_neutrales-60);
  width: 1063px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 24px;
  margin-bottom: 0;
  text-decoration: none;
}
.frame1home-vector247 {
  width: 25px;
  height: 12px;
  position: relative;
}

/* @media(max-width: 1200px) {
          .frame1home-frame1home {
            flex-wrap: wrap;
          }
        } */
.frame5dasboarddelusuario-container {
  min-height: 100vh;
}
.frame5dasboarddelusuario-frame5dasboarddelusuario {
  width: 100%;
  height: 2300px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame5dasboarddelusuario-backgroundtoppage1 {
  top: 0px;
  left: -171px;
  width: 1788px;
  height: 1155px;
  position: absolute;
}
.imagenMicuenta {
  top: 224px !important;
}
.frame5dasboarddelusuario-frame517 {
  top: 316px;
  /* left: 148px; */
  width: 1144px;
  height: 256px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
  margin-left: 70px;
}
.frame5dasboarddelusuario-header {
  top: 85px;
  left: 51px;
  width: 420px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-header-subheadand-avatar-autolayout {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  margin-right: 0px;
}
.frame5dasboarddelusuario-header-subhead-autolayout {
  width: 388px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.frame5dasboarddelusuario-text {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 40px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}
.frame5dasboarddelusuario-text002 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 338px;
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-frame496 {
  top: 33px;
  left: 67px;
  width: 300px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.frame5dasboarddelusuario-textorecuperadinerodetucredito {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame5dasboarddelusuario-text004 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 50px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 69px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame5dasboarddelusuario-maskgroup {
  top: 316px;
  /* left: 515.8291015625px; */
  width: 778.1710205078125px;
  height: 356px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-rectangle2381 {
  width: 600px;
  top: 0px;
  /* left: 103.1708984375px; */
  width: 675px;
  height: 256px;
  position: absolute;
  border-color: transparent;
  border-radius: 0px 20px 0px 0px;
  margin-left: 540px;
}

.frame5dasboarddelusuario-footer {
  top: 1938px;
  left: 0px;
  width: 1440px;
  height: 362px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(247, 247, 247, 1);
}
.frame5dasboarddelusuario-line23 {
  top: 185.181640625px;
  left: 240px;
  width: 1081px;
  height: 0px;
  position: absolute;
}
.frame5dasboarddelusuario-cajamenfooter {
  top: 42px;
  left: 417px;
  width: 140px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame5dasboarddelusuario-text017 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text019 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text021 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text023 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-caja-quienessomosfooter {
  top: 42px;
  left: 807px;
  width: 113px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame5dasboarddelusuario-text025 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text027 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text029 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text031 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text033 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-caja-segurofooter {
  top: 42px;
  left: 643px;
  width: 78px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame5dasboarddelusuario-text035 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text037 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text039 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text041 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-cajacontactofooter {
  top: 42px;
  left: 1006px;
  width: 285px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}
.frame5dasboarddelusuario-text043 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text045 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text047 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text049 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text051 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-rectangle2374 {
  top: 322px;
  left: 0px;
  width: 1440px;
  height: 40px;
  position: absolute;
  border-color: transparent;
}
.frame5dasboarddelusuario-group {
  top: 38px;
  left: 0px;
  width: 87.0000991821289px;
  height: 283.55206298828125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-group01 {
  top: 183.783203125px;
  left: 8.86767578125px;
  width: 54.09845733642578px;
  height: 99.76887512207031px;
  display: flex;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector {
  top: 4.328125px;
  left: 1.89794921875px;
  width: 52px;
  height: 95px;
  opacity: 0.05;
  position: absolute;
}
.frame5dasboarddelusuario-vector001 {
  top: 8.615234375px;
  left: 3.81298828125px;
  width: 50px;
  height: 91px;
  opacity: 0.1;
  position: absolute;
}
.frame5dasboarddelusuario-vector002 {
  top: 13.005859375px;
  left: 5.68603515625px;
  width: 48px;
  height: 86px;
  opacity: 0.14;
  position: absolute;
}
.frame5dasboarddelusuario-vector003 {
  top: 17.314453125px;
  left: 7.58251953125px;
  width: 46px;
  height: 82px;
  opacity: 0.19;
  position: absolute;
}
.frame5dasboarddelusuario-vector004 {
  top: 21.64453125px;
  left: 9.47216796875px;
  width: 44px;
  height: 77px;
  opacity: 0.24;
  position: absolute;
}
.frame5dasboarddelusuario-vector005 {
  top: 25.9940128326416px;
  left: 11.377463340759277px;
  width: 42px;
  height: 73px;
  opacity: 0.29;
  position: absolute;
}
.frame5dasboarddelusuario-vector006 {
  top: 30.341796875px;
  left: 13.2802734375px;
  width: 40px;
  height: 68px;
  opacity: 0.33;
  position: absolute;
}
.frame5dasboarddelusuario-vector007 {
  top: 34.63066864013672px;
  left: 15.089670181274414px;
  width: 38px;
  height: 64px;
  opacity: 0.38;
  position: absolute;
}
.frame5dasboarddelusuario-vector008 {
  top: 39px;
  left: 16.95556640625px;
  width: 36px;
  height: 59px;
  opacity: 0.43;
  position: absolute;
}
.frame5dasboarddelusuario-vector009 {
  top: 43.34980010986328px;
  left: 18.815692901611328px;
  width: 34px;
  height: 55px;
  opacity: 0.48;
  position: absolute;
}
.frame5dasboarddelusuario-vector010 {
  top: 47.740440368652344px;
  left: 20.674196243286133px;
  width: 32px;
  height: 50px;
  opacity: 0.52;
  position: absolute;
}
.frame5dasboarddelusuario-vector011 {
  top: 51.986328125px;
  left: 22.498046875px;
  width: 30px;
  height: 46px;
  opacity: 0.57;
  position: absolute;
}
.frame5dasboarddelusuario-vector012 {
  top: 56.3359375px;
  left: 24.338134765625px;
  width: 28px;
  height: 41px;
  opacity: 0.62;
  position: absolute;
}
.frame5dasboarddelusuario-vector013 {
  top: 60.705078125px;
  left: 26.178955078125px;
  width: 26px;
  height: 37px;
  opacity: 0.67;
  position: absolute;
}
.frame5dasboarddelusuario-vector014 {
  top: 65.0546875px;
  left: 28.001220703125px;
  width: 24px;
  height: 32px;
  opacity: 0.71;
  position: absolute;
}
.frame5dasboarddelusuario-vector015 {
  top: 69.40608215332031px;
  left: 29.80400848388672px;
  width: 22px;
  height: 28px;
  opacity: 0.76;
  position: absolute;
}
.frame5dasboarddelusuario-vector016 {
  top: 73.671875px;
  left: 31.6181640625px;
  width: 20px;
  height: 23px;
  opacity: 0.81;
  position: absolute;
}
.frame5dasboarddelusuario-vector017 {
  top: 78.03515625px;
  left: 33.421142578125px;
  width: 19px;
  height: 19px;
  opacity: 0.86;
  position: absolute;
}
.frame5dasboarddelusuario-vector018 {
  top: 82.328125px;
  left: 35.244140625px;
  width: 17px;
  height: 15px;
  opacity: 0.9;
  position: absolute;
}
.frame5dasboarddelusuario-vector019 {
  top: 86.38858795166016px;
  left: 37.01726531982422px;
  width: 15px;
  height: 10px;
  opacity: 0.95;
  position: absolute;
}
.frame5dasboarddelusuario-vector020 {
  top: 89.765625px;
  left: 38.813720703125px;
  width: 13px;
  height: 7px;
  position: absolute;
}
.frame5dasboarddelusuario-vector021 {
  top: 260.162109375px;
  left: 51.68408203125px;
  width: 28px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-vector022 {
  top: 268.484375px;
  left: 51.746337890625px;
  width: 28px;
  height: 7px;
  position: absolute;
}
.frame5dasboarddelusuario-vector023 {
  top: 105.66619110107422px;
  left: 10.786811828613281px;
  width: 53px;
  height: 159px;
  position: absolute;
}
.frame5dasboarddelusuario-vector024 {
  top: 122.2109375px;
  left: 10.76611328125px;
  width: 49px;
  height: 143px;
  position: absolute;
}
.frame5dasboarddelusuario-vector025 {
  top: 254.7088165283203px;
  left: 36.814327239990234px;
  width: 24px;
  height: 27px;
  position: absolute;
}
.frame5dasboarddelusuario-group02 {
  top: 257.2265625px;
  left: 36.81396484375px;
  width: 23.727771759033203px;
  height: 24.127182006835938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector026 {
  top: 0px;
  left: 17.33056640625px;
  width: 0px;
  height: 0px;
  position: absolute;
}
.frame5dasboarddelusuario-vector027 {
  top: 2.14453125px;
  left: 0px;
  width: 24px;
  height: 22px;
  position: absolute;
}
.frame5dasboarddelusuario-vector028 {
  top: 111.1396484375px;
  left: 41.006103515625px;
  width: 44px;
  height: 149px;
  position: absolute;
}
.frame5dasboarddelusuario-vector029 {
  top: 31.6494140625px;
  left: 9.4072265625px;
  width: 56px;
  height: 97px;
  position: absolute;
}
.frame5dasboarddelusuario-vector030 {
  top: 47.066612243652344px;
  left: 9.428058624267578px;
  width: 20px;
  height: 81px;
  position: absolute;
}
.frame5dasboarddelusuario-vector031 {
  top: 34.3076171875px;
  left: 24.13427734375px;
  width: 25px;
  height: 21px;
  position: absolute;
}
.frame5dasboarddelusuario-vector032 {
  top: 17.49609375px;
  left: 26.92041015625px;
  width: 21px;
  height: 30px;
  position: absolute;
}
.frame5dasboarddelusuario-vector033 {
  top: 17.49609375px;
  left: 32.65478515625px;
  width: 15px;
  height: 27px;
  position: absolute;
}
.frame5dasboarddelusuario-vector034 {
  top: 2.9853515625px;
  left: 30.229736328125px;
  width: 26px;
  height: 38px;
  position: absolute;
}
.frame5dasboarddelusuario-vector035 {
  top: 0px;
  left: 27.21062660217285px;
  width: 34px;
  height: 29px;
  position: absolute;
}
.frame5dasboarddelusuario-vector036 {
  top: 89.7470703125px;
  left: 50.8291015625px;
  width: 12px;
  height: 6px;
  position: absolute;
}
.frame5dasboarddelusuario-group03 {
  top: 82.1923828125px;
  left: 43.31591796875px;
  width: 15.177910804748535px;
  height: 27.98879623413086px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector037 {
  top: 0.30078125px;
  left: 0.451904296875px;
  width: 15px;
  height: 28px;
  position: absolute;
}
.frame5dasboarddelusuario-vector038 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 28px;
  position: absolute;
}
.frame5dasboarddelusuario-vector039 {
  top: 91.7861557006836px;
  left: 43.08709716796875px;
  width: 23px;
  height: 14px;
  position: absolute;
}
.frame5dasboarddelusuario-vector040 {
  top: 37.0341796875px;
  left: 58.5244140625px;
  width: 28px;
  height: 63px;
  position: absolute;
}
.frame5dasboarddelusuario-vector041 {
  top: 130.951171875px;
  left: 14.9365234375px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame5dasboarddelusuario-vector042 {
  top: 41.822181701660156px;
  left: 0px;
  width: 25px;
  height: 97px;
  position: absolute;
}
.frame5dasboarddelusuario-vector043 {
  top: 145.267578125px;
  left: 40.985107421875px;
  width: 32px;
  height: 114px;
  position: absolute;
}
.frame5dasboarddelusuario-vector044 {
  top: 61.007633209228516px;
  left: 58.9627799987793px;
  width: 18px;
  height: 32px;
  position: absolute;
}
.frame5dasboarddelusuario-group288892 {
  top: 212px;
  left: 262px;
  width: 657px;
  height: 81px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-text053 {
  top: 16px;
  left: 162px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 495px;
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.frame5dasboarddelusuario-text054 {
  font-weight: 500;
}
.frame5dasboarddelusuario-g-r-u-p-o-i-n-s-u-r-e-x-s-v-g1 {
  top: 0px;
  left: 0px;
  width: 97px;
  height: 81px;
  position: absolute;
  border-color: transparent;
}
.frame5dasboarddelusuario-logo-lineadesgravamen-footer {
  top: 48.4041633605957px;
  left: 267.99993896484375px;
  width: 85.00013732910156px;
  height: 90.7261734008789px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-txt {
  top: 63.2845458984375px;
  left: 0px;
  width: 85.00013732910156px;
  height: 27.44162368774414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-group04 {
  top: 0.0002319185296073556px;
  left: 25.083524703979492px;
  width: 31.201702117919922px;
  height: 11.408534049987793px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector045 {
  top: 1.8503669500350952px;
  left: 0.000029019884095760062px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame5dasboarddelusuario-vector046 {
  top: 0.0002319185296073556px;
  left: 6.424156665802002px;
  width: 3px;
  height: 11px;
  position: absolute;
}
.frame5dasboarddelusuario-vector047 {
  top: 3.7005021572113037px;
  left: 10.094902992248535px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector048 {
  top: 3.7005021572113037px;
  left: 17.436397552490234px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector049 {
  top: 3.7005021572113037px;
  left: 24.777891159057617px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-group05 {
  top: 14.800132751464844px;
  left: -0.0001068115234375px;
  width: 81.3687515258789px;
  height: 12.6417236328125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector050 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}
.frame5dasboarddelusuario-vector051 {
  top: 2.15820574760437px;
  left: 8.258431434631348px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector052 {
  top: 2.15820574760437px;
  left: 15.600566864013672px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector053 {
  top: 2.15820574760437px;
  left: 22.024267196655273px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame5dasboarddelusuario-vector054 {
  top: 2.15820574760437px;
  left: 29.671905517578125px;
  width: 5px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector055 {
  top: 2.15820574760437px;
  left: 34.566802978515625px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector056 {
  top: 2.15820574760437px;
  left: 41.6019401550293px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector057 {
  top: 2.15820574760437px;
  left: 48.63771438598633px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector058 {
  top: 2.15820574760437px;
  left: 56.28514099121094px;
  width: 10px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector059 {
  top: 2.15820574760437px;
  left: 67.29759216308594px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector060 {
  top: 2.15820574760437px;
  left: 74.63908386230469px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-group06 {
  top: 14.14947509765625px;
  left: 81.75969696044922px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-group07 {
  top: 0.8171027898788452px;
  left: 1.0551077127456665px;
  width: 1.3501358032226562px;
  height: 1.633067011833191px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector061 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 2px;
  position: absolute;
}
.frame5dasboarddelusuario-group08 {
  top: 0px;
  left: 0px;
  width: 3.24033260345459px;
  height: 3.2661287784576416px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector062 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame5dasboarddelusuario-img {
  top: 0px;
  left: 14.36742877960205px;
  width: 55.87344741821289px;
  height: 56.31800079345703px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector063 {
  top: 0.9916815161705017px;
  left: -0.00005660363603965379px;
  width: 20px;
  height: 45px;
  position: absolute;
}
.frame5dasboarddelusuario-vector064 {
  top: -0.0001346481585642323px;
  left: 17.706514358520508px;
  width: 38px;
  height: 56px;
  position: absolute;
}
.frame5dasboarddelusuario-socialwrapper {
  top: 212px;
  left: 1010px;
  width: 144px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-facebook-negative {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-twitter-negative {
  top: 0px;
  left: 42.666015625px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-instagram-negative {
  top: 0px;
  left: 85.333984375px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-group09 {
  top: 0px;
  left: 0px;
  width: 15.990626335144043px;
  height: 15.996874809265137px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector065 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-vector066 {
  top: 3.890625px;
  left: 3.890625px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.frame5dasboarddelusuario-vector067 {
  top: 2.76953125px;
  left: 11.3125px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.frame5dasboarddelusuario-linked-in-negative {
  top: 0px;
  left: 128px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-group10 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector068 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-minimi {
  top: 1876px;
  left: 1284px;
  width: 73px;
  height: 125px;
  position: absolute;
}
.frame5dasboarddelusuario-menuinterno {
  top: 226px;
  /* left: 148px; */
  width: 1146px;
  height: 60px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  margin-left: 70px;
}
.frame5dasboarddelusuario-rectangle2385 {
  top: 0px;
  left: 0px;
  width: 1146px;
  height: 60px;
  position: absolute;
  border-color: transparent;
  border-radius: 100px;
}
.frame5dasboarddelusuario-frame288925 {
  top: -12px;
  left: 65px;
  width: 1225px;
  height: 88px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  cursor: pointer;
}
.frame5dasboarddelusuario-text056 {
  color: var(--dl-color-colores_paleta-tonos_morado-50);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-line {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.selectMenu {
  color: var(--dl-color-colores_paleta-tonos_morado-100) !important;
  font-style: ExtraBold !important;
}
.unselectMenu {
  color: var(--dl-color-colores_paleta-tonos_morado-40) !important;
  font-style: Medium !important;
  font-weight: 500 !important;
}
.frame5dasboarddelusuario-text058 {
  color: var(--dl-color-colores_paleta-tonos_morado-50);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-line1 {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.frame5dasboarddelusuario-text060 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-line2 {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.frame5dasboarddelusuario-text062 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
}
.frame5dasboarddelusuario-line3 {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.frame5dasboarddelusuario-text064 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-vector069 {
  width: 5px;
  height: 10px;
  position: relative;
  margin-right: 16px;
}
.frame5dasboarddelusuario-line4 {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.frame5dasboarddelusuario-text066 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-vector070 {
  width: 5px;
  height: 10px;
  position: relative;
  margin-right: 16px;
}
.frame5dasboarddelusuario-line5 {
  width: 35px;
  border: 1px solid #7130ed;
  height: 0px;
  position: relative;
  transform: rotate(90deg);
}
.frame5dasboarddelusuario-text068 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-logo-v1 {
  top: 34px;
  left: 148px;
  width: 147px;
  height: 155px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-txt1 {
  top: 107.93921661376953px;
  left: 0px;
  width: 147px;
  height: 47.06140899658203px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-group11 {
  top: 0px;
  left: 43.379615783691406px;
  width: 53.96037292480469px;
  height: 19.56462860107422px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector071 {
  top: 3.1724777221679688px;
  left: 0px;
  width: 10px;
  height: 16px;
  position: absolute;
}
.frame5dasboarddelusuario-vector072 {
  top: 0px;
  left: 11.10989761352539px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame5dasboarddelusuario-vector073 {
  top: 6.345848083496094px;
  left: 17.457595825195312px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector074 {
  top: 6.345848083496094px;
  left: 30.15447235107422px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector075 {
  top: 6.345848083496094px;
  left: 42.85090637207031px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-group12 {
  top: 25.38251495361328px;
  left: 0px;
  width: 140.7199249267578px;
  height: 21.67889404296875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector076 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame5dasboarddelusuario-vector077 {
  top: 3.7013702392578125px;
  left: 14.28259563446045px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector078 {
  top: 3.7013702392578125px;
  left: 26.979917526245117px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector079 {
  top: 3.7013702392578125px;
  left: 38.08885192871094px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame5dasboarddelusuario-vector080 {
  top: 3.7013702392578125px;
  left: 51.31489562988281px;
  width: 8px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector081 {
  top: 3.7013702392578125px;
  left: 59.77977752685547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector082 {
  top: 3.7013702392578125px;
  left: 71.9470443725586px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector083 {
  top: 3.7013702392578125px;
  left: 84.11431121826172px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector084 {
  top: 3.7013702392578125px;
  left: 97.3403549194336px;
  width: 17px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector085 {
  top: 3.7013702392578125px;
  left: 116.38500213623047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-vector086 {
  top: 3.7013702392578125px;
  left: 129.08143615722656px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame5dasboarddelusuario-group13 {
  top: 24.26683807373047px;
  left: 141.39613342285156px;
  width: 5.6038665771484375px;
  height: 5.6009979248046875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-group14 {
  top: 1.4014892578125px;
  left: 1.824462890625px;
  width: 2.3349456787109375px;
  height: 2.8004913330078125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector087 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame5dasboarddelusuario-group15 {
  top: 0px;
  left: 0px;
  width: 5.6038665771484375px;
  height: 5.6009979248046875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector088 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame5dasboarddelusuario-img1 {
  top: 0px;
  left: 24.846982955932617px;
  width: 96.62808227539062px;
  height: 96.57805633544922px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-vector089 {
  top: 1.700539469718933px;
  left: 0px;
  width: 35px;
  height: 77px;
  position: absolute;
}
.frame5dasboarddelusuario-vector090 {
  top: 0px;
  left: 30.621896743774414px;
  width: 66px;
  height: 97px;
  position: absolute;
}
.frame5dasboarddelusuario-frame492 {
  top: 34px;
  /* left: 908.142578125px; */
  width: 432px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame5dasboarddelusuario-textsbtns-siper-header {
  width: 116px;
  height: 25px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 30px;
}
.frame5dasboarddelusuario-signo-accesibilidad {
  top: 2px;
  left: 0px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame5dasboarddelusuario-text070 {
  left: 30px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-textsbtns-siper-header1 {
  width: 104px;
  height: 25px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 30px;
}
.frame5dasboarddelusuario-text072 {
  top: 0px;
  left: -8px;
  color: var(--dl-color-gray-white);
  width: 120px;
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-textsbtns-siper-header2 {
  top: -1px;
  right: 51px;
  width: 105px;
  height: 25px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-text074 {
  left: 34px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 15px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame5dasboarddelusuario-polygon2 {
  top: 11px;
  right: 3px;
  width: 6px;
  height: 5px;
  position: absolute;
}
.frame5dasboarddelusuario-subwayworld1 {
  top: 2px;
  left: 6px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.frame5dasboarddelusuario-group288941 {
  top: 602px;
  /* left: 162px; */
  width: 1115.3489990234375px;
  height: 417.27862548828125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -70px;
}

.frame5dasboarddelusuario-nombreultimoaccesovaloruf {
  top: 95px;
  /* left: 658px; */
  width: 535px;
  height: 61px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame5dasboarddelusuario-rectangle2393 {
  top: 0px;
  left: 0px;
  width: 424px;
  height: 61px;
  position: absolute;
}
.frame5dasboarddelusuario-text110 {
  top: 12px;
  left: 89px;
  color: var(--dl-color-gray-white);
  width: 258px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-text112 {
  top: 33.16015625px;
  left: 80px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-rectangle2392 {
  top: 0px;
  left: 411px;
  width: 124px;
  height: 61px;
  position: absolute;
}
.frame5dasboarddelusuario-text114 {
  top: 3px;
  left: 385px;
  color: var(--dl-color-gray-white);
  width: 69px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-text116 {
  top: 27px;
  left: 388px;
  color: var(--dl-color-gray-white);
  width: 65px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5dasboarddelusuario-vector103 {
  top: 26px;
  left: 375px;
  width: 8px;
  height: 17px;
  position: absolute;
}
.frame5dasboarddelusuario-group288947 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5dasboarddelusuario-ellipse313 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  position: absolute;
  border-color: transparent;
}
.frame5dasboarddelusuario-icon-btns1 {
  top: 7.988186836242676px;
  left: 7.988186836242676px;
  width: 45px;
  height: 45px;
  position: absolute;
}

.frame76persona-cotizarsegurode-desgravamen-nombreultimoacceso {
  top: 94px;
  /* left: 957px; */
  width: 424px;
  height: 61px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-left: -10px;
}
.frame76persona-cotizarsegurode-desgravamen-rectangle2393 {
  top: 0px;
  left: 0px;
  width: 424px;
  height: 61px;
  position: absolute;
}
.frame76persona-cotizarsegurode-desgravamen-group288947 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame76persona-cotizarsegurode-desgravamen-ellipse313 {
  top: 0px;
  left: 0px;
  width: 61px;
  height: 61px;
  position: absolute;
  border-color: transparent;
}

.frame76persona-cotizarsegurode-desgravamen-icon-btns3 {
  top: 7.988186836242676px;
  left: 7.988186836242676px;
  width: 45px;
  height: 45px;
  position: absolute;
}

.frame1-formulariocontacto-group288935 {
  top: 365px;
  /* left: 315px; */
  margin-left: 57px;
  width: 810px;
  height: 660px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame1-formulariocontacto-group288922 {
  top: 0px;
  left: 0px;
  width: 810px;
  height: 740px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame1-formulariocontacto-rectangle39 {
  right: -15px;
  width: 810px;
  bottom: -17px;
  height: 750px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame1-formulariocontacto-text {
  top: 49px;
  left: 304px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 36px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-text02 {
  top: 96px;
  left: 258px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-botn {
  left: 298px;
  width: 220px;
  bottom: 41px;
  height: 51px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  padding-bottom: 0px;
  justify-content: center;
  background-color: rgba(56, 0, 135, 1);
}
.frame1-formulariocontacto-text04 {
  top: 16px;
  left: 89px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame1-formulariocontacto-formtophome {
  top: 151px;
  left: 79px;
  width: 680px;
  height: 123px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame1-formulariocontacto-rectangle7 {
  top: 0px;
  left: 0px;
  width: 680px;
  height: 51px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}
.frame1-formulariocontacto-rectangle8 {
  top: 73px;
  left: 0px;
  width: 680px;
  height: 50px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}
.frame1-formulariocontacto-text06 {
  top: 16px;
  left: 30px;
  color: var(--dl-color-colores_neutrales-70);
  width: 232px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-text08 {
  top: 91px;
  left: 30px;
  color: var(--dl-color-colores_neutrales-70);
  width: 165px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-formtophome1 {
  top: 299px;
  left: 83px;
  width: 680px;
  height: 239px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame1-formulariocontacto-rectangle71 {
  top: 0px;
  left: 0px;
  width: 680px;
  height: 51px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}
.frame1-formulariocontacto-rectangle81 {
  top: 73px;
  left: 0px;
  width: 680px;
  height: 166px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
}
.frame1-formulariocontacto-text10 {
  top: 16px;
  left: 30px;
  color: var(--dl-color-colores_neutrales-70);
  width: 232px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-text12 {
  top: 91px;
  left: 30px;
  color: var(--dl-color-colores_neutrales-70);
  width: 165px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-group288934 {
  /* top: 1069px; */
  /* left: 404px; */
  margin-top: 541px;
  margin-left: 122px;
  width: 631px;
  height: 222px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-bottom: 211px;
}
.frame1-formulariocontacto-text14 {
  left: 1px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-text16 {
  top: 60px;
  left: 47px;
  color: var(--dl-color-colores_neutrales-40);
  width: 584px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 120.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-text18 {
  top: 199px;
  left: 46px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 120.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.frame1-formulariocontacto-ellipse291 {
  top: 60px;
  left: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  border-color: transparent;
}
.frame1-formulariocontacto-ellipse292 {
  top: 199px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  border-color: transparent;
}
.frame644persona-formulario-lneadesgravamen-text10 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
