<template>
    <v-row class="colcenter simulaPago">


        <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter frame-60 ">
            <v-row>
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <div class="resumen-de-seguro">Elige tu forma de pago</div>
                    <div class="este-es-el-resumen-del-seguro-que-estas-contratando">
                        Total a pagar: {{ Seguro.spl_sim_monto_prima_cl }}
                    </div>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                    <div class="colright">
                        <img class="vector6" src="assets/privado/simulacionpagar/vector5.svg">
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter mb-5 frame-61">
            <v-row class="cuadropago1">

                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-row class="colcenter cuadro">
                        <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                            <v-row class="colcenter">
                                <v-col cols="8" sm="8" md="8" lg="8" xl="8" class="colright cuadrodr">
                                    <v-row class="colcenter">
                                        <v-col cols="2" sm="2" md="2" lg="2" xl="2" class="colright align-self-center">
                                            <img class="vector7" src="assets/privado/pagar/vector6.svg">
                                        </v-col>
                                        <v-col cols="10" sm="10" md="10" lg="10" xl="10"
                                            class="colleft align-self-center">
                                            <div class="seguro-desgravamen">Seguro Desgravamen</div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-col>

                        <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colright cuadrodr">
                            <v-row class="colcenter">
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colleft">
                                    <div class="textodriz">Plan</div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colright">
                                    <div class="textodrdr">Plan 1</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <div class="line-2"></div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colleft">
                                    <div class="valor-total-uf">Valor Total UF</div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colright">
                                    <div class="textodrdr">{{ Seguro.spl_sim_monto_prima_uf }}</div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colleft">
                                    <div class="valor-total-clp">Valor Total CLP</div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colright">
                                    <div class="textodrdr">{{ Seguro.spl_sim_saldo_insoluto }}</div>
                                </v-col>
                            </v-row>
                        </v-col>


                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="line-1"></div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <v-row class="colcenter">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">

                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                            <div class="selecciona-tu-metodo-de-pago">
                                Selecciona tu metodo de pago
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">

                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <v-form ref="form" v-model="valid">
                                        <v-radio-group v-model="mediosPago" :rules="respRules" required
                                            @update:error="vresp" column>
                                            <v-row class="colcenter" v-for="(medios, i) in mediosPagos" :key="i">

                                                <v-col cols="2" sm="2" md="2" lg="2" xl="2" class="colcenter">
                                                    <v-radio :value="medios.mpa_numero"></v-radio>
                                                </v-col>
                                                <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
                                                    <img v-if="medios.mpa_numero == 1" class="image-3"
                                                        src="assets/privado/pagar/image-30.png">
                                                    <img v-if="medios.mpa_numero == 2" class="image-4"
                                                        src="assets/privado/pagar/image-40.png">
                                                </v-col>
                                            </v-row>
                                        </v-radio-group>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                    <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="volver"><span><span
                                style="color:white">VOLVER</span></span></v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                    <v-btn class="sm-size" rounded x-large @click="validate" :disabled="!valid"
                        color="#04137f"><span><span style="color:white;">PAGAR</span></span></v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-row>


</template>
<script>
import logContratar from "../../../../services/logContratar";
import datausuario from "../../../../services/usuario-datos";

export default {
    name: "MediosPago",
    components: {

    },
    data() {
        return {
            valid: false,
            medioPago: null,
            overlay: false,
            bitacora: {
                bitacoraPlan: {
                    spl_numero: 0,
                    spl_seguro_numero: 0,
                    spl_pln_numero: 0,
                    cse_numero: 0,
                    cse_ctt_numero: 0,
                },
                bitacoraPago: {
                    estado_pago: 0,
                    cce_transaccion: 0,
                },
            },
            mediosPago: false,
            mediosPagos: [
                {
                    "id": 1,
                    "nombre": "webpay",
                    "url": "assets/privado/pagar/image-30.png"
                },
                {
                    "id": 2,
                    "nombre": "kiphu",
                    "url": "assets/privado/pagar/image-40.png"
                },
            ],
            datosContrato: this.$store.state.datosContratoSeleccionado,
            valid: false,
            respRules: [(v) => !!v || ""],
            validresp: true,
        };
    },
    props: {
        Seguro: {},
    },
    methods: {
        volver() {
            this.$root.$refs.HomePrivado.cambiaMenu(14);
        },
        async seguroPagar() {
            this.overlay = true;

            let res2 = false;

            res2 = await datausuario.traeMediosPagos(this.Seguro.spl_pln_numero);
            if (res2.status == 200 && res2.data.estadoTransaccion.success) {
                console.log(res2.data);
                this.mediosPagos = res2.data.mediosPagos;
                this.overlay = false;
                //   this.$root.$refs.CotizaForm.estadoFormCotizacion = 8;
            } else {
                this.overlay = false;
                //   this.$root.$refs.CotizaForm.estadoFormCotizacion = 99;
            }

        },
        async pagar() {
            this.overlay = true;
            let res = false;

            var data = {
                pag_seguro: this.Seguro.spl_seg_numero,
                pag_medio_pago: this.mediosPago,

            };

            res = await datausuario.creaTransaccion(data);

            let token = "";
            let url = "";
            let url_completa = "";

            if (res.status == 200 && res.data.estadoTransaccion.success) {

                token = res.data.token;
                url = res.data.url;
                url_completa = res.data.url_completa;
                window.open(url_completa, "_self");
            } else {
                this.overlay = false;
                this.$alertas(res.data.estadoTransaccion.errors, "warning");
                // this.$root.$refs.CotizaForm.estadoFormCotizacion = 99;
            }
        },

        formatDate: function (date) {
            if (!date) return null

            var arr = date.split('-'),
                year = arr[0],
                month = arr[1],
                day = arr[2];
            return `${day}/${month}/${year}`
        },
        vresp(v) {
            this.validresp = v;
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.pagar();
            }
        },

    },
    mounted() {
        this.seguroPagar();
        window.scrollTo(0, 0);
    },
    created() {
        this.$root.$refs.Pagar = this;
    },
};
</script>
<style scoped>
.image-3 {
    width: 81%;
    /* height: 41.99%; */
    position: relative;
    object-fit: cover;
}

.image-4 {
    border-radius: 16px;
    width: 81%;
    /* height: 41.99%; */
    position: relative;
    object-fit: cover;
}

.selecciona-tu-metodo-de-pago {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1.2rem, 4vw, 2.6rem);
    line-height: clamp(1.2rem, 4vw, 3.3rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.cuadropago1 {
    padding: 20px 0px 20px 0px;
}

.cuadrodr {
    /* padding-left: 60px;
    padding-right: 60px; */
}

.sm-size {
    width: 50%;
}

.cuadrodatosiz {
    align-self: center;
}

.divide {
    display: inline-flex;
}

.simulaPago {
    background-color: white;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.colright {
    display: flex;
    justify-content: right;
}

.colleft {
    display: flex;
    justify-content: left;
}

.frame-60 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    padding: 20px 79px 20px 79px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    margin-top: -104px;
}

.frame-61 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    /* padding: 40px; */
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.resumen-de-seguro {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.este-es-el-resumen-del-seguro-que-estas-contratando {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.7rem, 4vw, 1.3rem);
    line-height: clamp(0.8rem, 4vw, 2.2rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vector6 {
    flex-shrink: 0;
    width: clamp(1.7rem, 4vw, 3.5rem);
    height: clamp(1.7rem, 4vw, 3.5rem);
    position: relative;
    overflow: visible;
}

.fecha-inicio {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    position: relative;
    font-size: clamp(0.5rem, 4vw, 0.8rem);
}

.fecha-inicio-span2 {
    color: var(--azul-complementario, #04137f);
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.7rem, 4vw, 1rem);
    line-height: clamp(0.8rem, 4vw, 1.2rem);
    font-weight: 900;
}

.fechadetalle {
    background: var(--azul-claro, #e8f3ff);
    border-radius: 100px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    width: 100%;
    /* height: 69px; */
    position: relative;
    padding: 3px 20px 3px 20px;

}

.textoiz {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
}

.textodriz {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.3rem);
    line-height: clamp(0.8rem, 4vw, 1.4rem);
    font-weight: 900;
    position: relative;
    width: 100%;
}

.textodrdr {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px); */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
}

.textodr {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.4rem);
    font-weight: 900;
    position: relative;
}

.cuadro {
    padding: 20px;
    margin-top: 5px;
}

.image-2 {
    margin-top: 5px;
    border-radius: 40px;
    flex-shrink: 0;
    width: 80%;
    /* height: 418px; */
    position: relative;
    object-fit: cover;
}

.datos-personales {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.seguro-desgravamen {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

.line-2 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}


.datosiz {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 24px;
    line-height: 28px; */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: 900;
    position: relative;
    width: 100%;
    /* height: 28px; */
}

.datosdr {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px); */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
}

.vector7 {
    width: clamp(2.5rem, 4vw, 5.9rem);
    height: clamp(2.5rem, 4vw, 5.9rem);
    position: relative;
    overflow: visible;
}

.valor-total-uf {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Bold", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: 700;
    position: relative;
    width: 100%;
}

.valor-total-clp {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: 900;
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .divide {
        display: none !important;
    }

    .sm-size {
        font-size: small !important;
        width: 100%;
    }

    .cuadro {
        padding: 0px !important;
    }

    .image-3 {
        width: 100%;
    }

    .image-4 {
        width: 100%;
    }
    .frame-60 {
        padding: 20px 40px 20px 40px !important;
    }
}
</style>