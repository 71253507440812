<template>
  <v-app>
    <v-container full-width>
      <v-layout row wrap>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important">1</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group495">
        <div class="frame6pgina-contexto-crear-lneade-desgravamen-group498">
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group">
            <div class="frame6pgina-contexto-crear-lneade-desgravamen-group1">
              <img
                src="/playground_assets/vector1251-v3a4.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector"
              />
              <img
                src="/playground_assets/vector1251-t0av.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector01"
              />
              <img
                src="/playground_assets/vector1251-sm4i.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector02"
              />
              <img
                src="/playground_assets/vector1251-e67b.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector03"
              />
              <img
                src="/playground_assets/vector1251-k1gd.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector04"
              />
            </div>
            <div class="frame6pgina-contexto-crear-lneade-desgravamen-group2">
              <img
                src="/playground_assets/vector1251-gmo.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector05"
              />
              <img
                src="/playground_assets/vector1251-cbrg.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector06"
              />
              <img
                src="/playground_assets/vector1251-9wxa.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector07"
              />
              <img
                src="/playground_assets/vector1251-rxax.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector08"
              />
              <img
                src="/playground_assets/vector1251-z8ws.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector09"
              />
              <img
                src="/playground_assets/vector1251-83zh.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector10"
              />
              <img
                src="/playground_assets/vector1251-ev4.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector11"
              />
              <img
                src="/playground_assets/vector1251-wm3p.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector12"
              />
              <img
                src="/playground_assets/vector1251-d63.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector13"
              />
              <img
                src="/playground_assets/vector1251-twlm.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector14"
              />
              <img
                src="/playground_assets/vector1251-71ml.svg"
                alt="Vector1251"
                class="frame6pgina-contexto-crear-lneade-desgravamen-vector15"
              />
            </div>
            <div class="frame6pgina-contexto-crear-lneade-desgravamen-group3">
              <div class="frame6pgina-contexto-crear-lneade-desgravamen-group4">
                <img
                  src="/playground_assets/vector1251-hxqr.svg"
                  alt="Vector1251"
                  class="frame6pgina-contexto-crear-lneade-desgravamen-vector16"
                />
              </div>
              <div class="frame6pgina-contexto-crear-lneade-desgravamen-group5">
                <img
                  src="/playground_assets/vector1251-31s.svg"
                  alt="Vector1251"
                  class="frame6pgina-contexto-crear-lneade-desgravamen-vector17"
                />
              </div>
            </div>
          </div>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group6">
            <img
              src="/playground_assets/vector1251-7byl.svg"
              alt="Vector1251"
              class="frame6pgina-contexto-crear-lneade-desgravamen-vector18"
            />
            <img
              src="/playground_assets/vector1251-e8et.svg"
              alt="Vector1251"
              class="frame6pgina-contexto-crear-lneade-desgravamen-vector19"
            />
          </div>
        </div>
      </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
         
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">6</h1>
        
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-maskgroup">
        <img
          src="/playground_assets/unsplashwhwybmtn302069-zbgi-1000h.png"
          alt="unsplashWHWYBmtn302069"
          class="frame6pgina-contexto-crear-lneade-desgravamen-unsplash-w-h-w-y-bmtn30"
        />
        <!-- <img
        src="/playground_assets/svg21366-im8j.svg"
        alt="SVG21366"
        class="frame6pgina-contexto-crear-lneade-desgravamen-s-v-g2"
      /> -->
      <img
        src="/playground_assets/svg21366-im8j.svg"
        alt="SVG21366"
        class="frame6pgina-contexto-crear-lneade-desgravamen-s-v-g21"
      />
      </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group15">
        <span
          class="frame6pgina-contexto-crear-lneade-desgravamen-text Roboto_H4_3242"
        >
          <span>
            <span>Desde donde estés</span>
            <br />
            <span>100% ONLINE</span>
          </span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text05">
          <span>Para contratar tu línea necesitaras los siguiente:</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text07">
          <span>Cédula de identidad</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text09">
          Completar el formulario con tus datos
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text10">
          <span>Tu teléfono móvil</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text12">
          <span>
            <span>ÚNETE a nuestra</span>
            <br />
            <span>
              Línea de Desgravamen
              <span v-html="rawdo78"></span>
            </span>
          </span>
        </span>
        <div class="frame6pgina-contexto-crear-lneade-desgravamen-group288879">
          <img
            src="/playground_assets/ellipse151251-vcy5-200h.png"
            alt="Ellipse151251"
            class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse15"
          />
        </div>
        <img
          src="/playground_assets/ellipse161251-aggb-200h.png"
          alt="Ellipse161251"
          class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse16"
        />
        <img
          src="/playground_assets/ellipse171251-55m5-200h.png"
          alt="Ellipse171251"
          class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse17"
        />
      </div>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones">
       
       <router-link to="/formulario-linea"><div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones1 conocenos"  @mouseover="hover('conocenos')" @mouseout="hoverout('conocenos')">
               <span class="frame6pgina-contexto-crear-lneade-desgravamen-text17"><span>Comencemos</span></span>                
       </div></router-link>
     </div>
     <img
       src="/playground_assets/line221251-lhmr.svg"
       alt="Line221251"
       class="frame6pgina-contexto-crear-lneade-desgravamen-line22"
     />
     <div class="frame6pgina-contexto-crear-lneade-desgravamen-group509">
      
       <router-link to="/"><div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones2 bvolver"  @mouseover="hover('bvolver')" @mouseout="hoverout('bvolver')">
               <span class="frame6pgina-contexto-crear-lneade-desgravamen-text19"><span>Volver</span></span>                
       </div></router-link>
     </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>


<script>
export default {
  name: "Frame6PginaContextoCrearLneadeDesgravamen",

  data() {
    return {
      rawdo78: " ",
    };
  },
  methods: {
    hover: function (name) {
      console.log(name);
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover");
    },
  },
  mounted() {
    var device = navigator.userAgent;
      
      if (device.match(/Iphone/i)|| device.match(/Ipod/i)|| device.match(/Android/i)|| device.match(/J2ME/i)|| device.match(/BlackBerry/i)|| device.match(/iPhone|iPad|iPod/i)|| device.match(/Opera Mini/i)|| device.match(/IEMobile/i)|| device.match(/Mobile/i)|| device.match(/Windows Phone/i)|| device.match(/windows mobile/i)|| device.match(/windows ce/i)|| device.match(/webOS/i)|| device.match(/palm/i)|| device.match(/bada/i)|| device.match(/series60/i)|| device.match(/nokia/i)|| device.match(/symbian/i)|| device.match(/HTC/i))
      {
        this.$router.push("/m/crear-degravamen");
      }

    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyform3", {});    
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyform5", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setestadoFormLinea", 1);
    this.$store.commit("setestadoFormCotizacion", 1);
    this.$store.commit("setestadoBps", 1);
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit("settipocotizacion", "inicio");
    this.$store.commit("settipopersonaempresa", "inicio");
    this.$store.commit("setiniciodisabled", true);
    if (this.$root.$refs.LineaForm1) {
      this.$root.$refs.LineaForm1.tipodisabled = true;
    }
    if (this.$root.$refs.CotizaForm1) {
      this.$root.$refs.CotizaForm1.tipodisabled = true;
    }
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 1920px;
  min-width: 1440px;
}
.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.frame6pgina-contexto-crear-lneade-desgravamen-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.frame6pgina-contexto-crear-lneade-desgravamen-frame6pgina-contexto-crear-lneade-desgravamen {
  top: -15px;
  width: 1468px;
  height: 950px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-maskgroup {
  top: 0px;
  /* left: 557px; */
  width: 911px;
  height: 950px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-unsplash-w-h-w-y-bmtn30 {
  top: -104px;
    /* left: 28.96848487854004px; */
    width: 994px;
    height: auto;
    position: absolute;
    background: #d9d9d9;
    border-color: transparent;
    margin-left: -49px;
}
.frame6pgina-contexto-crear-lneade-desgravamen-s-v-g2 {
  top: -503px;
  /* left: 200px; */
  width: 655px;
  height: 1912px;
  position: absolute;
  box-sizing: content-box;
}
.frame6pgina-contexto-crear-lneade-desgravamen-s-v-g21 {
  top: -157px;
    /* left: 347px; */
    width: 772px;
    /* height: 1912px; */
    position: absolute;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin-left: -431px;

}
.frame6pgina-contexto-crear-lneade-desgravamen-group15 {
  top: 220px;
  /* left: 148px; */
  width: 651px;
  height: 423px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-text {
  top: 138px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 330px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  font-weight: 700;
  line-height: 94.9999988079071%;
}
.frame6pgina-contexto-crear-lneade-desgravamen-text05 {
  top: 262px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 23px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text07 {
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  bottom: 30px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text09 {
  top: 309px;
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text10 {
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  bottom: -8px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text12 {
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-group288879 {
  top: 315px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse15 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse16 {
  top: 357px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse17 {
  top: 399px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones {
  top: 694px;
  /* left: 148px; */
  width: 361px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones1 {
  width: 361px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: rgba(113, 48, 237, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-text17 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-line22 {
  top: 477px;
  left: 148px;
  width: 572px;
  height: 0px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group509 {
  top: 790px;
  /* left: 148px; */
  width: 236px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones2 {
  top: 0px;
  /* left: 0px; */
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-text19 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-group495 {
  top: 62px;
  /* left: 148px; */
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group {
  top: 15.025447845458984px;
  left: 105.18211364746094px;
  width: 149.81614685058594px;
  height: 47.98341751098633px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group1 {
  top: 0.00005686227814294398px;
  left: 44.20986557006836px;
  width: 54.99390411376953px;
  height: 19.948720932006836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector {
  top: 3.23506498336792px;
  left: -0.00009430493082618341px;
  width: 10px;
  height: 17px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector01 {
  top: 0.00005686227814294398px;
  left: 11.323002815246582px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector02 {
  top: 6.4695048332214355px;
  left: 17.79131317138672px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector03 {
  top: 6.4695048332214355px;
  left: 30.731914520263672px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector04 {
  top: 6.4695048332214355px;
  left: 43.67137908935547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group2 {
  top: 25.878984451293945px;
  left: -0.000016696129023330286px;
  width: 143.41653442382812px;
  height: 22.104625701904297px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector06 {
  top: 3.7734971046447754px;
  left: 14.556951522827148px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector07 {
  top: 3.7734971046447754px;
  left: 27.4975528717041px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector08 {
  top: 3.7734971046447754px;
  left: 38.817806243896484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector09 {
  top: 3.7734971046447754px;
  left: 52.29852294921875px;
  width: 9px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector10 {
  top: 3.7734971046447754px;
  left: 60.92502212524414px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector11 {
  top: 3.7734971046447754px;
  left: 73.32494354248047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector12 {
  top: 3.7734971046447754px;
  left: 85.72599792480469px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector13 {
  top: 3.7734971046447754px;
  left: 99.20443725585938px;
  width: 18px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector14 {
  top: 3.7734971046447754px;
  left: 118.6139144897461px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector15 {
  top: 3.7734971046447754px;
  left: 131.55508422851562px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group3 {
  top: 24.742467880249023px;
  left: 144.1051025390625px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group4 {
  top: 1.4308146238327026px;
  left: 1.859254240989685px;
  width: 2.3796639442443848px;
  height: 2.8555972576141357px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 77.92008209228516px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector18 {
  top: 1.3724621534347534px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector19 {
  top: -9.094947017729282e-13px;
  left: 24.692947387695312px;
  width: 53px;
  height: 78px;
  position: absolute;
}
</style>
