import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/es5/locale/es'
import '@mdi/font/css/materialdesignicons.css'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import { mdiCheckCircle } from '@mdi/js';
Vue.use(Vuetify)

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'es',
	decimalLength: 0,
	autoDecimalMode: false,
	min: null,
  max: null,
	defaultValue: 0,
    valueAsInteger: true,
    allowNegative: false,
    prefix : "$"
})

const opts = {
    lang: {
        locales: { es },
        current: 'es'
      },
        iconfont: 'mdi',
        icons: {
          'product': 'mdi-dropbox',
          'support': 'mdi-lifebuoy',
          'steam': 'mdi-steam-box',
          'pc': 'mdi-desktop-classic',
          'xbox': 'mdi-xbox',
          'playstation': 'mdi-playstation',
          'switch': 'mdi-nintendo-switch',
          'check-pato' : mdiCheckCircle
        },
      theme: {
        themes: {
          light: {
            primary: "#14C6FF",
            secondary: "#424242",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
            lightblue: "#14c6FF",
            yellow: "#FFCF00",
            pink: "#FF1976",
            orange: "#FF8657",
            magenta: "#C33AFC",
            darkblue: "#1E2D56",
            gray: "#909090",
            neutralgray: "#9BA6C1",
            green: "#2ED47A",
            red: "#FF5c4E",
            darkblueshade: "#308DC2",
            lightgray: "#BDBDBD",
            lightpink: "#FFCFE3",
            white: "#FFFFFF",
            juanito: "#FFB84E",
            azulsitio: "7130ED",
            neutral: "D4D6DC",
            m100: "#001787",
            volver:"#5E6475",
            azulclaro: "rgba(214, 231, 254)"
          }
        }
      }
}

export default new Vuetify(opts)