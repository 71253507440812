<template>
  <div class="frame21persona-formulario-lneadesgravamen-imputformulariot2">
    <div>
     
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row dense>
       
              <v-col  cols="12" sm="12" md="12" lg="12" xl="12"
                v-if="tipo == `vigente`"
                @mouseover="selectSaldoInsoluto"
              >
                <v-text-field
                  v-if="saldoinsoluto"
                  :rules="saldoInsolutoRules"
                  label="SALDO ADEUDADO A LA FECHA (CLP)"
                  outlined
                  rounded
                  dense
                  style="cursor: pointer"
                  color="rgb(56,0,151)"
                  
                >
                </v-text-field>
                <v-currency-field
                  ref="saldoinsoluto"
                  v-show="!saldoinsoluto"
                  v-model="form5.saldoInsolutoCredito"
                  :rules="saldoInsolutoRules"
                  required
                  outlined
                  rounded
                  dense
                  label="SALDO ADEUDADO A LA FECHA (CLP)"
                  color="rgb(56,0,151)"
                  :prefix="prefix"
                  @update:error="vsaldoinsoluto"
                  
                >
                <template v-slot:append>
                    <v-icon
                      color="rgba(0, 23, 135, 1)"
                      v-if="!validsaldoinsoluto"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else-if="validsaldoinsoluto"
                      color="red"
                      style="margin-bottom: 10px !important "
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-currency-field>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="tipo != `inicio`">
              
                <v-text-field
                  v-if="tipo == `vigente`"
                  v-model="form5.mesesPendientePago"
                  :rules="mesPendientePagoRules"
                  label="CUOTAS PENDIENTES"
                  rounded
                  dense
                  outlined
                  min="1"
                  max="9999"
                  
                  append-icon="mdi-check-circle"
                  color="rgb(56,0,151)"
                  type="number"
                  class="w100"
                  @update:error="vmespendientepago"
                >
                  <template v-slot:append>
                    <div v-if="validmespendientepago == `no`"></div>
                    <v-icon
                      v-else-if="!validmespendientepago"
                      color="m100"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else-if="validmespendientepago"
                      color="red"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                v-if="tipo == `vigente`"
                  v-model="form5.numeroCredito"
                  :rules="numeroCreditoRules"
                  label="INGRESE NRO. DE CRÉDITO (OPCIONAL)"
                  rounded
                  dense
                  outlined
                  maxlength="30"
                  
                  append-icon="mdi-check-circle"
                  color="rgb(56,0,151)"
                  type="text"
                  class="w100"
                  @update:error="vnumerocredito"
                >
                  <template v-slot:append>
                    <div v-if="validnumerocredito == `no`"></div>
                    <v-icon
                      v-else-if="!validnumerocredito"
                      color="m100"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else-if="validnumerocredito"
                      color="red"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-text-field>
        
              </v-col>

              


              <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                <v-text-field
                v-if="tipo == `nuevo`"
                  v-model="form5.numeroCredito"
                  :rules="numeroCreditoRules"
                  label="INGRESE NRO. DE CRÉDITO (OPCIONAL)"
                  rounded
                  dense
                  outlined
                  maxlength="30"
                  
                  append-icon="mdi-check-circle"
                  color="rgb(56,0,151)"
                  type="text"
                  class="w100"
                  @update:error="vnumerocredito"
                >
                  <template v-slot:append>
                    <div v-if="validnumerocredito == `no`"></div>
                    <v-icon
                      v-else-if="!validnumerocredito"
                      color="m100"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else-if="validnumerocredito"
                      color="red"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-text-field>

              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="line-1"></div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="frame-32-2">
                    <v-row class="colcenter">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" id="btn-credito-2-r" outlined rounded x-large @click="volver"><span
                            ><span>REGRESAR</span></span></v-btn>
                        </v-col>      
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" id="btn-credito-2" rounded x-large @click="validate" color="#1d2eff" :disabled="!valid"><span
                            ><span style="color:white">CONTINUAR</span></span></v-btn>
                        </v-col>                 
                    </v-row>                  
                    
                    </div>
            </v-col> 
             
            </v-row>
          </v-container>
        </v-form>
      
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";

export default {
  name: "CotizaForm4",
  components: {},
  data() {
    return {
      overlay: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: "",
      errors: {},
      prefix: "$",
      menu1: false,
      menu2: false,
      cuotaini: this.$store.state.form5.valorCuota ? false : true,
      mesesPago: this.$store.state.mPago,
      mesesPagoPendiente: this.$store.state.mPendientePago,
      saldoinsoluto: this.$store.state.form5.saldoinsoluto ? false : true,
      validsaldoinsoluto: "no",
      validnumerocredito: "no",
      validmespendientepago: "no",
      validmesgracia: "no",
      validfechacuota: true,
      tipoCotizacion: this.$store.state.tipoCotizacion,
      validcuotacredito: true,
     
      form5: {
        saldoInsolutoCredito: 0,
        mesesPendientePago: "",
        numeroCredito: "",
        fechaCuota: 0,
        valorCuota: 0,
      },
      antessalir: "",
      valid: true,
      mesPagoRules: [
        (v) => !!v || "meses total credito es requerido",
        (v) => (v && this.validarMaxTotal(v)) || "valor máximo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_maximo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
        (v) => (v && this.validarMinTotal(v)) || "valor mínimo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_minimo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
      ],
    
      mesPendientePagoRules: [
        (v) => !!v || "meses total pendiente de pago es requerido",
        (v) => (v <= this.$store.state.form4.mesesPago) || "Meses pendiente de pago, mayor a meses originales",
        (v) => (v && this.validarMaxPendiente(v)) || "valor máximo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_pendientes_maximo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
        (v) => (v && this.validarMinPendiente(v)) || "valor mínimo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_pendientes_minimo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
        (v) => (v && this.validarMesesPendiente(v)) || "Meses pendientes, mayor a meses total credito",
      ],
      numeroCreditoRules: [
        (v) => (v.length <= 30) ||
          "el campo meses de gracia debe ser menor o igual a 22 caracteres alfanumericos",
      ],
      fechaCuotaRules: [
        (v) => !!v || "fecha primera cuota es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => (v && v != "0") || "el saldo debe ser mayor a 0",
      ],
      valorCuotaRules: [
        (v) => !!v || "el campo monto cuota es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => (v && v != "0") || "el saldo debe ser mayor a 0",
      ],
      saldoInsolutoRules: [
        (v) => !!v || "el campo saldo insoluto credito es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => (v && v != "0") || "el saldo debe ser mayor a 0",
        (v) => (v && this.validarMaxInsoluto(v)) || "monto máximo para este credito es de $ "+this.$root.$refs.CotizaForm.restricciones[0].tmn_insoluto_maximo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
        (v) => (v && this.validarMinInsoluto(v)) || "monto mínimo para este credito es de $ "+this.$root.$refs.CotizaForm.restricciones[0].tmn_insoluto_minimo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
        (v) => (v && this.validarSaldo(v)) || "Saldo credito, mayor a saldo original",
      ],
    };
  },
  props: {
    tipo: "",
  },
  methods: {
    validarPeriodoGracia(v){
      
      this.form5.mesesGracia=parseInt(v);
      return parseInt(v);
      
    },
    validarMesesPendiente(v){
      if(v && v!="0" && v!=""){     
      var vlocal=this.$store.state.form4.mesesPago;
      if(v>vlocal){
        return false;
      }
      return true;
      }
    },
    validarSaldo(v){
      if(v && v!="0" && v!=""){
        v=v.replace(/[^0-9]/g, "");       
     var vlocal=this.$store.state.form4.saldoCredito;
      if(v>vlocal){
        return false;
      }
      return true;
      }
    },
    validarMinInsoluto(v){
      v=v.replace(/[^0-9]/g, "");
      if(v<this.$root.$refs.CotizaForm.restricciones[0].tmn_insoluto_minimo){
        return false;
      }
      return true;
    },
    validarMaxInsoluto(v){
      v=v.replace(/[^0-9]/g, "");
      if(v>this.$root.$refs.CotizaForm.restricciones[0].tmn_insoluto_maximo){
        return false;
      }
      return true;
    },
    validarMinTotal(v){
      if(v<this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_minimo){
        return false;
      }
      return true;
    },
    validarMaxTotal(v){
      if(v>this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_maximo){
        return false;
      }
      return true;
    },
    validarMinPendiente(v){
      this.form5.mesesPendientePago=parseInt(v);
      if(parseInt(v)<parseInt(this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_pendientes_minimo)){
        return false;
      }
      return true;
    },
    validarMaxPendiente(v){
      this.form5.mesesPendientePago=parseInt(v);
      if(parseInt(v)>parseInt(this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_pendientes_maximo)){
        return false;
      }
      return true;
    },
    formatAsCurrency(value, dec) {
      dec = dec || 0;
      if (value === null) {
        return 0;
      }

      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day,month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    selectSaldoIni() {
      this.saldoini = false;
      this.$refs["saldo"].$refs.textfield.focus();
    },
    selectValorCuota() {
      this.cuotaini = false;
      this.$refs["cuota"].$refs.textfield.focus();
    },
    formatAsCurrency(value, dec) {
      dec = dec || 0;
      if (value === null) {
        return 0;
      }

      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    selectMesPago(e) {
      this.validmespago = true;
      this.form5.mesesPago = e;
    },
    selectMesPendientePago(e) {
      this.validmespendientepago = true;
      this.form5.mesesPendientePago = e;
    },
    selectMesGracia(e) {
      this.validmesgracia = true;
      this.form5.mesesGracia = e;
    },
    selectSaldoInsoluto() {
      if(this.tipo=="vigente"){
      this.saldoinsoluto = false;
      this.$refs["saldoinsoluto"].$refs.textfield.focus();
      }else{
        this.selectSaldoIni();
      }
    },
    vfechacuota(v) {
      this.validfechacuota = v;
    },
    vmespago(v) {
      this.validmespago = v;
      
    },
    vmespendientepago(v) {
      this.validmespendientepago = v;
      
    },
    vnumerocredito(v) {
      this.vnumerocredito = v;
      
    },
    vvalorcuota(v) {
      this.validcuotacredito = v;
    },
    vsaldoinsoluto(v) {
      this.validsaldoinsoluto = v;
    },
    async guardaUsuario(data) {
      let actualiza = "refresh";
      let res = false;
      res = await datausuario.actualizar(data);
      //crear objeto tipo form1
      this.$store.commit("setform1", res.data.usuario);
      this.$alertas(res);
    },

    btnleft: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-left");
      }, 150);
    },
    btnleftout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-left");
      }, 150);
    },
    btnright: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-right");
      }, 150);
    },
    btnrightout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-right");
      }, 150);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setform5", this.form5);
        this.guardaUsuario();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValida
    },
    hover: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg";
      }
    },
    hoverout: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg";
      }
    },
    async guardaUsuario(data) {
      this.overlay = true;
      
      let res = false;
      var tipo = 1;
      if (this.$store.state.tipoPersonaEmpresa == "empresa") {
        tipo = 2;
      }
      // var intitucion=this.$store.state.form4.tipoInstitucion.parseInt();
      var nombreinstitucion=this.$store.state.form4.nombreInstitucion;
      if(tipo==1){
      var rutCompleto = this.$store.state.form1.rut.split(".").join("");
      rutCompleto = rutCompleto.replace(/\./g, "");
      rutCompleto = rutCompleto.split("-");
      var rut = parseInt(rutCompleto[0]);
      var dv = rutCompleto[1];
      var cel = "+569"+this.$store.state.form2.celular;
      if(this.$root.$refs.CotizaForm.isLogin){
        cel = this.$store.state.form2.celular;
      }  
      cel = cel.replace("(", "");
      cel = cel.replace(")", "");
      cel = cel.replace(" ", "");
      cel = cel.replace(" ", "");
      }else{
      var rutCompleto = this.$store.state.forme2.rut.split(".").join("");
      rutCompleto = rutCompleto.replace(/\./g, "");
      rutCompleto = rutCompleto.split("-");
      var rut = parseInt(rutCompleto[0]);
      var dv = rutCompleto[1];
      var cel = "+569"+this.$store.state.forme1.telefono;
      if(this.$root.$refs.CotizaForm.isLogin){
        cel = this.$store.state.forme1.telefono;
      }      
      cel = cel.replace("(", "");
      cel = cel.replace(")", "");
      cel = cel.replace(" ", "");
      cel = cel.replace(" ", "");

      var rutCompletoe = this.$store.state.forme1.rut.split(".").join("");
      rutCompletoe = rutCompletoe.replace(/\./g, "");
      rutCompletoe = rutCompletoe.split("-");
      var rute = parseInt(rutCompletoe[0]);
      var dve = rutCompletoe[1];
  
      }
      let tipoCredito = this.$root.$refs.CotizaForm.tipoCotizacion;
      var montoCredito = this.$store.state.form4.saldoCredito;
      var montoInsolutoCredito = this.form5.saldoInsolutoCredito;
      var mesespago = this.$store.state.form4.mesesPago;
      var mesespendientepago = this.form5.mesesPendientePago;
      var periodoGracia = 0;
     
      if(periodoGracia=== undefined){
         periodoGracia = 0;
      } 
      if(mesespago=== undefined){
        mesespago = 0;
      } 
      if(mesespendientepago=== undefined){
        mesespendientepago = 0;
      }     
      
      var valorcuota= this.form5.valorCuota;
      
      var mpago = parseInt(mesespago);
      var mpendiente = parseInt(mesespendientepago);
      var mgracia = 0;      
      var codInst= this.$store.state.codInst;
      var codNInst= this.$store.state.codNInst;
      if (tipoCredito == "nuevo") {
        var mpendiente = 0;
        tipoCredito = 1;
        var numeroCredito = this.form5.numeroCredito;
      }
      if (tipoCredito == "vigente") {
        tipoCredito = 0;
        var numeroCredito = this.form5.numeroCredito;
      }
      var sim=this.$store.state.simulacion.sim_numero;
      if(sim==""){
        sim = 0;
      }
      if(tipo==1){
      var usuario = {
        seg_tipo_cliente: tipo,
        seg_simulacion_numero:sim,
        ctt_numero:0,
        seg_nro_credito_original: numeroCredito,
        detalleCredito: {
          seg_usu_credito_nuevo: tipoCredito,
          seg_usu_tipo_institucion: codInst,
          seg_usu_institucion: codNInst,
          seg_usu_valor_prestamo: montoCredito,
          seg_sim_valor_cuota_seguro: valorcuota,
          seg_usu_meses_originales: mpago,         
          seg_usu_periodo_gracia: mgracia,
          seg_usu_saldo_insoluto: montoInsolutoCredito,
          seg_usu_cuotas_pendientes: mpendiente,

        },
        personaJuridica: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.form1.nombre1,
          per_nombre_segundo: this.$store.state.form1.nombre2,
          per_ape_paterno: this.$store.state.form1.apaterno,
          per_ape_materno: this.$store.state.form1.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.form2.date),
          per_celular: cel,
          per_fono: "",
          per_email: this.$store.state.form2.email,
          per_estado: 1,
          per_pais : this.$store.state.form1.nacionalidad,
          per_genero : this.$store.state.form1.genero,
          per_ocupacion : this.$store.state.form2.ocupacion,
        },
        personaRepresentante: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.form1.nombre1,
          per_nombre_segundo: this.$store.state.form1.nombre2,
          per_ape_paterno: this.$store.state.form1.apaterno,
          per_ape_materno: this.$store.state.form1.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.form2.date),
          per_celular: cel,
          per_fono: "",
          per_email: this.$store.state.form2.email,
          per_estado: 1,
          per_pais : this.$store.state.form1.nacionalidad,
          per_genero : this.$store.state.form1.genero,
          per_ocupacion : this.$store.state.form2.ocupacion,
        },
      }
    }
    else{
      var usuario = {
        seg_tipo_cliente: tipo,
        seg_simulacion_numero:sim,
        ctt_numero:0,
        seg_nro_credito_original: numeroCredito,
        detalleCredito: {
          seg_usu_credito_nuevo: tipoCredito,
          seg_usu_tipo_institucion: codInst,
          seg_usu_institucion: codNInst,
          seg_usu_valor_prestamo: montoCredito,
          seg_sim_valor_cuota_seguro: valorcuota,
          seg_usu_meses_originales: mpago,         
          seg_usu_periodo_gracia: mgracia,
          seg_usu_saldo_insoluto: montoInsolutoCredito,
          seg_usu_cuotas_pendientes: mpendiente,

        },
        personaJuridica: {
          per_rut: rute,
          per_dv: dve,
          per_tipo_cliente: 2,
          per_nombre_principal: this.$store.state.forme1.nombre1,
          per_nombre_segundo: "",
          per_ape_paterno: "",
          per_ape_materno :"",
          per_fec_nac: "2003-09-01",
          per_celular: cel,
          per_fono: cel,    
          per_email: this.$store.state.forme1.email,
          per_estado: 1,
          per_pais : this.$store.state.forme2.nacionalidad,
          per_genero : this.$store.state.forme2.genero,
          per_ocupacion : this.$store.state.forme2.ocupacion,
        },
        personaRepresentante: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.forme2.nombre1,
          per_nombre_segundo: this.$store.state.forme2.nombre2,
          per_ape_paterno: this.$store.state.forme2.apaterno,
          per_ape_materno: this.$store.state.forme2.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.forme2.date),
          per_celular: cel,
          per_fono: cel,
          per_email: this.$store.state.forme1.email,
          per_estado: 1,
          per_pais : this.$store.state.forme2.nacionalidad,
          per_genero : this.$store.state.forme2.genero,
          per_ocupacion : this.$store.state.forme2.ocupacion,
        },

      }
    }
    
      if(tipoCredito){
       
        res = await datausuario.simulacionCreditoNuevo(usuario);
      }else{
        res = await datausuario.simulacionCreditoVigente(usuario);
      }
      
      console.log(res);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
        
        if (res.data.estadoTransaccion.success) {
          this.$store.commit("setestadofea", res.data.fea);
          if(tipoCredito){       

              this.$store.commit("setseguroseleccion", res.data.simulaSeguro.spl_seg_numero);
              this.$root.$refs.Identidad.seguro = res.data.simulaSeguro.spl_seg_numero;
              var propuestaCreditoNuevo = res.data.simulaSeguro;
              this.$store.commit("setpropuestacreditonuevo", propuestaCreditoNuevo);
              
              
              this.overlay = false;
              this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;
          }else{
                  var propuestaCreditoVigente = res.data.parrillaSeguro;
                  this.$store.commit("setseguroseleccion", res.data.parrillaSeguro.seg_numero);
                  this.$root.$refs.Identidad.seguro = res.data.parrillaSeguro.seg_numero;

                  if(res.data.dps){
                        this.overlay = false;
                        this.$root.$refs.CotizaForm.estadoFormCotizacion = 14;
                      }
                  this.$store.commit("setpropuestacreditovigente", propuestaCreditoVigente);
                  var documentos=false;
                  var res2 =false;
                  var seguro = {
                                seg_numero: res.data.parrillaSeguro.seg_numero
                                }
                  res2 = await datausuario.traeDocumentos(seguro);
                  if (res2.status == 200) {
                    this.$alertas(res2.data.estadoTransaccion.errors, "success");
                    documentos=res2.data.doctoRespuesta;
                    this.$store.commit("setdocumentos", documentos);
                    this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;
                    this.overlay = false;
                  } else {
                    this.$alertas(
                      "En este momento no podemos atender su solicitud",
                      "warning"
                    );
                    this.overlay = false;
                  }
                 
           }
         
        }else{
          this.overlay = false;
          this.$root.$refs.CotizaForm.estadoFormCotizacion = 99;
        }
      } else {
        this.$alertas(
          "En este momento no podemos atender su solicitud",
          "warning"
        );
      }
      console.log(this.$store.state.simulacion);
      this.overlay = false;
      
    },
    volver() {
      this.$root.$refs.CotizaForm.estadoFormCotizacion = 3;
    },
    round(num, decimalPlaces = 0) {
    if (num < 0)
        return - this.round(-num, decimalPlaces);
    var p = Math.pow(10, decimalPlaces);
    var n = num * p;
    var f = n - Math.floor(n);
    var e = Number.EPSILON * n;

    // Determine whether this fraction is a midpoint value.
    return (f >= .5 - e) ? Math.ceil(n) / p : Math.floor(n) / p;  
    },
  },
  computed: {
    initialBalanceFormatted: {
      get: function () {
        return this.formatAsCurrency(this.initialBalance, 0);
      },
      set: function (newValue) {
        console.log(newValue);

        this.initialBalance = Number(newValue.replace(/[^0-9\.]/g, ""));
        this.form5.saldoCredito = this.initialBalance;
        this.form5.valorCuota = this.initialBalance;
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.form5.fechaCuota = this.formatDate(this.date);
    },
  },
  mounted() {
    // this.validtipoinstitucion = this.form4.tipoInstitucion.length ? false : true;
    // this.validnombreinstitucion = this.form4.nombreInstitucion.length ? false : true;
    // this.validfechacuota = this.form5.fechaCuota.length ? false : true;
    console.log(this.$store.state.form4);
  },
  created() {
    this.$root.$refs.CotizaForm4 = this;
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>
<style scoped>

.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame21persona-formulario-lneadesgravamen-imputformulariot2 {
  /* top: 410px; */
  /* left: 457px; */
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  /* margin-left: 20px; */
}
.frame-32-2 {
  /* background: #d9d9d9; */
  border-radius: 80px;
  padding: 9px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}
.colcenter{
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .sm-size{
    font-size: small !important;
 }
   
}
</style>
