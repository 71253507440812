<template>
  <div class="o-v-e-r-l-a-y-notevayas-container">
    <div class="o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas">
      <div class="o-v-e-r-l-a-y-notevayas-group288922">
        <span class="o-v-e-r-l-a-y-notevayas-text Roboto_H4_3242">
          <span>Ingresa el número de tu Crédito</span>
        </span>
        <v-form ref="form" v-model="valid" >
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="12">
                            <div style="position: absolute; align-content: center;top: 116px;margin-left: 158px;width: 394px;text-align: right;">
                                <v-text-field v-model="numeroIngreso" 
                                :rules="numeroRules" 
                                label="Numero" 
                                required 
                                class="nn"
                                maxlength="30"
                                type="text"    
                                @update:error="vnumero" 
                                color="rgb(56,0,151)" 
                                    >
                                    <template v-slot:append>                                       
                                        <v-icon v-if="!validnumero" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnumero" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                              </div>
                          </v-col>
                    </v-row>
                  </v-container>
          </v-form>
        <img
          src="/playground_assets/line331251-btmo.svg"
          alt="Line331251"
          class="o-v-e-r-l-a-y-notevayas-line33"
        />
        <div class="o-v-e-r-l-a-y-notevayas-group288921">
          <!-- <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
              <span class="o-v-e-r-l-a-y-notevayas-text4">
                <span>Salir</span>
              </span>
            </div>
          </div> -->

          <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
             
                <v-btn
                  color="neutral"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  @click="continuaOrigen"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text4">
                    <span>Salir</span>
                  </span>
                </v-btn>
            
            </div>
          </div>

          <div class="o-v-e-r-l-a-y-notevayas-usosdebotones2">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones3">
            
                <v-btn
                  color="juanito"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  @click="validate(ident)"
                  :disabled="!valid"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text6">
                    <span>Cambiar</span>
                  </span>
                </v-btn>
            
            </div>
          </div>
         
          <!-- <span class="o-v-e-r-l-a-y-notevayas-text6">
                <span>Continuar</span>
              </span> -->
        </div>
      </div>
      
                <v-btn
                  color="transparent"
                  class="rounded-pill o-v-e-r-l-a-y-notevayas-icon-btns"
                  x-large
                  width="10px"
                  text
                  @click="continuaOrigen"

                >
                <img
            src="/playground_assets/iconbtns1251-loup.svg"
            alt="IconBtns1251"
            
          />
                </v-btn>
    
      
    </div>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </div>
</template>

<script>
import privado from "../../../../services/privado";
import logContratar from "../../../../services/logContratar";
export default {
  name: "ActivaSeguro",
  data() {
    return {
      overlay: false,
      numeroIngreso: "",
      valid: false,
      SeguroDetalle : {},
      numeroRules: [
      (v) => !!v || "número de credito es requerido",
      (v) => (v.length <= 30) ||
          "el campo meses de gracia debe ser menor o igual a 22 caracteres alfanumericos",
      ],
      validnumero : false,
      bitacora: { 
        bitacoraPlan: {
          spl_numero: 0,
          spl_seguro_numero: 0,
          spl_pln_numero: 0,
          cse_numero: 0,
          cse_ctt_numero: 0,
        },
        bitacoraPago: {
          estado_pago: 0,
          cce_transaccion: 0,
        },
      },
    }
  },
  props: {
    Seguro : {},
    cierraModal : "",
    ident: 0,
  },
  methods: {
    vnumero(v) {        
            this.validnumero = v;
        },
    validate(seguroSelect) {
            if (this.$refs.form.validate()) {
                this.Activar(seguroSelect);
                this.$root.$refs.PrivadoDetalleSimulacion.activa=true;
            }else{
              this.$root.$refs.PrivadoDetalleSimulacion.activa=false;
            }
    },
  async  Activar(seguroSelect){
           console.log(seguroSelect);
        var res = false;
        var res2 = false;
        var res3 = false;
        let numSeg=0;
        numSeg=this.numeroIngreso;
          this.overlay=true;
          var seguroPago={
                    cse_numero: seguroSelect,
                    cse_credito: numSeg
                    };
          console.log(seguroPago);
          res = await privado.activarSeguro(seguroPago);          
          if (res.status == 200 && res.data.estadoTransaccion.success) {

              res2 = await privado.traeDetalleSeguro(seguroSelect);
                if (res.status == 200) {
                    this.bitacora.cse_estado=0;
                    this.bitacora.bitacoraPlan.spl_numero = res2.data.miSeguro.cse_numero;
                    this.bitacora.bitacoraPlan.spl_seguro_numero = res2.data.miSeguro.spl_seguro_numero;
                    this.bitacora.bitacoraPlan.spl_pln_numero = res2.data.miSeguro.spl_pln_numero;
                    this.bitacora.bitacoraPlan.cse_numero =res2.data.miSeguro.cse_numero;
                    this.bitacora.bitacoraPlan.cse_ctt_numero = res2.data.miSeguro.cse_ctt_numero;
                    this.bitacora.bitacoraPago.cce_transaccion = 3533675;
                    this.bitacora.bitacoraPago.estado_pago = 1; 
                    res3 = await logContratar.guardarSimulacionPagoProcesar(this.bitacora);
                      if (res.status == 200 && res.data.estadoTransaccion.success) {
                          this.$alertas(res3.data.estadoTransaccion.errors,"success");
                          this.overlay = false;
                          this.$root.$refs.HomePrivado.cambiaMenu(2);
                          this.$root.$refs.PrivadoDetalleSimulacion.activa=true;
                          this.$emit('cierraModal');

                      }else{
                          this.$alertas(res3.data.estadoTransaccion.errors,"error");
                          this.overlay = false;
                          this.$root.$refs.HomePrivado.cambiaMenu(2);
                          this.$root.$refs.PrivadoDetalleSimulacion.activa=true;
                          this.$emit('cierraModal');
                      }
              }else{
                  this.$alertas(res2.data.estadoTransaccion.errors,"error");
                  this.overlay = false;
                  this.$root.$refs.HomePrivado.cambiaMenu(2);
                  this.$root.$refs.PrivadoDetalleSimulacion.activa=true;
                  this.$emit('cierraModal');
              }
        }else{
            this.$alertas(res.data.estadoTransaccion.errors,"error");
            this.overlay = false;
            this.$root.$refs.PrivadoDetalleSimulacion.activa=false;                       
        }
          
    },
    continuaOrigen(){
      this.$emit('cierraModal');
    }

  },
  metaInfo: {
    title: 'Mueve Seguro',
  },
  created() {
        
  },
};
</script>

<style scoped>
.o-v-e-r-l-a-y-notevayas-container {
  min-height: 315px;
}
.o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas {
  width: 100%;
  height: 315px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.o-v-e-r-l-a-y-notevayas-group288922 {
  top: 0px;
  left: 0px;
  width: 744px;
  height: 313px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(241, 241, 243, 1);
}
.o-v-e-r-l-a-y-notevayas-text {
  width: 662px;
  top: 49px;
  left: 133px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.o-v-e-r-l-a-y-notevayas-text2 {
  top: 184px;
  left: 92px;
  color: var(--dl-color-colores_neutrales-90);
  width: 560px;
  height: auto;
  position: absolute;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 146.00000381469727%;
  font-stretch: normal;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-line33 {
  top: 135px;
  left: 101px;
  width: 542px;
  height: 0px;
  position: absolute;
}
.o-v-e-r-l-a-y-notevayas-group288921 {
  top: 210px;
  left: 83px;
  width: 525px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones {
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones1 {
  top: -15px;
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  /* background-color: var(--dl-color-colores_neutrales-10); */
}
.o-v-e-r-l-a-y-notevayas-text4 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones2 {
  top: 0px;
  left: 305px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones3 {
  top: -15px;
  width: 400px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.o-v-e-r-l-a-y-notevayas-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-icon-btns {
  top: 20px;
  left: 650px;
  width: 52px;
  height: 52px;
  position: absolute;
}
</style>
