<template>
  <v-container fluid style="padding: 0">
    <v-row>
      <v-col cols="12" xs="12">
        <div class="frame-home-page-frame-home-page">
          <img
            alt="BackgroundHome1822"
            src="/playground_assets/movil/home/backgroundhome1822-yre.svg"
            class="frame-home-page-background-home"
          />
          <img
            alt="IconBtns1822"
            src="/playground_assets/movil/home/iconbtns1822-sr5e.svg"
            class="frame-home-page-icon-btns"
          />
          <!-- <div class="frame-home-page-usosdebotones">
        <span class="frame-home-page-text">
            <span>Cotiza tu Seguro</span>
        </span>
      </div> -->

          <router-link to="/crear-cotizacion">
            <div
              class="frame-home-page-usosdebotones bnt2"
              name="linea"
              @mouseover="hover('bnt2')"
              @mouseout="hoverout('bnt2')"
            >
              <span class="frame-home-page-text"><span>Cotiza tu seguro</span></span>
            </div>
          </router-link>

          <!-- <div class="frame-home-page-usosdebotones1">
        <span class="frame-home-page-text002">
          <span>
            Crea tu Línea
            <span ></span>
          </span>
        </span>
      </div> -->

          <router-link to="/crear-degravamen">
            <div
              class="frame-home-page-usosdebotones1 bnt1"
              name="linea"
              @mouseover="hover('bnt1')"
              @mouseout="hoverout('bnt1')"
            >
              <span class="frame-home-page-text002"><span>Crea tu línea</span></span>
            </div>
          </router-link>

          <div class="frame-home-page-logo-v1">
            <div class="frame-home-page-txt">
              <div class="frame-home-page-group">
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-swh.svg"
                  class="frame-home-page-vector"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-ipws.svg"
                  class="frame-home-page-vector001"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-64h.svg"
                  class="frame-home-page-vector002"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-11k9.svg"
                  class="frame-home-page-vector003"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-i3rc.svg"
                  class="frame-home-page-vector004"
                />
              </div>
              <div class="frame-home-page-group01">
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-l5yk.svg"
                  class="frame-home-page-vector005"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-2kum.svg"
                  class="frame-home-page-vector006"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-mtqj.svg"
                  class="frame-home-page-vector007"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-lvjh.svg"
                  class="frame-home-page-vector008"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-a9ma.svg"
                  class="frame-home-page-vector009"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-oko.svg"
                  class="frame-home-page-vector010"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-4p24.svg"
                  class="frame-home-page-vector011"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-3kiq.svg"
                  class="frame-home-page-vector012"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-jlm2.svg"
                  class="frame-home-page-vector013"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-tu9.svg"
                  class="frame-home-page-vector014"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-4jce.svg"
                  class="frame-home-page-vector015"
                />
              </div>
              <div class="frame-home-page-group02">
                <div class="frame-home-page-group03">
                  <img
                    alt="VectorI1822"
                    src="/playground_assets/movil/home/vectori1822-kug.svg"
                    class="frame-home-page-vector016"
                  />
                </div>
                <div class="frame-home-page-group04">
                  <img
                    alt="VectorI1822"
                    src="/playground_assets/movil/home/vectori1822-uytr.svg"
                    class="frame-home-page-vector017"
                  />
                </div>
              </div>
            </div>
            <div class="frame-home-page-img">
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-pnv2h.svg"
                class="frame-home-page-vector018"
              />
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-2tfs.svg"
                class="frame-home-page-vector019"
              />
            </div>
          </div>
          <span class="frame-home-page-text004">
            <span>Recupera dinero de tu crédito de consumo</span>
          </span>
          <span class="frame-home-page-text006">
            <span>
              <span>Accede a tu</span>
              <br />
              <span>línea desgravamen digital</span>
            </span>
          </span>
          <img
            alt="IconBtns1822"
            src="/playground_assets/movil/home/iconbtns1822-5x6b.svg"
            class="frame-home-page-icon-btns1"
          />
          <span class="frame-home-page-text011"><span>¡Bienvenido!</span></span>
          <span class="frame-home-page-text013"><span>Línea desgravamen</span></span>
          <div class="frame-home-page-cardquieresmasinfo">
            <div class="frame-home-page-group288895">
              <div class="frame-home-page-formtophome">
                <!-- <img
              alt="Rectangle7I1822"
              src="/playground_assets/movil/home/rectangle7i1822-jq7-300w.png"
              class="frame-home-page-rectangle7"
            />
            <img
              alt="Rectangle8I1822"
              src="/playground_assets/movil/home/rectangle8i1822-vczc-300w.png"
              class="frame-home-page-rectangle8"
            />
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-spkd-300w.png"
              class="frame-home-page-rectangle379"
            /> -->
                <span class="frame-home-page-text015">
                  <span>¿Quieres más información?</span>
                </span>
                <span class="frame-home-page-text017">
                  <span>Escribe tus datos</span>
                </span>
                <!-- <span class="frame-home-page-text019"><span>Nombre *</span></span>
            <span class="frame-home-page-text021"><span>Correo*</span></span>
            <span class="frame-home-page-text023"><span>Teléfono*</span></span> -->
              </div>
            </div>
            <!-- <div class="frame-home-page-usosdebotones2">
          <div class="frame-home-page-usosdebotones3">
            <span class="frame-home-page-text025"><span>Ingresar</span></span>
          </div>
        </div>  -->
            <v-app class="ctrans">
              <v-form
                dense
                ref="form"
                v-model="valid"
                lazy-validation
                style="
                  top: 139px;
                  width: 332px;
                  border-radius: 100px !important;
                  position: absolute;
                "
              >
                <v-text-field
                  v-model="formHome.nombre"
                  :rules="nombreRules"
                  label="Nombre*"
                  required
                  rounded
                  dense
                  color="rgba(0, 23, 135, 1)"
                  outlined
                >
                  <template v-slot:append> </template>
                </v-text-field>
                <v-text-field
                  v-model="formHome.correo"
                  :rules="correoRules"
                  label="Correo*"
                  required
                  rounded
                  dense
                  color="rgba(0, 23, 135, 1)"
                  outlined
                >
                  <template v-slot:append> </template>
                </v-text-field>
                <v-text-field
                  v-model="formHome.telefono"
                  :rules="telefonoRules"
                  rounded
                  dense
                  label="Telefono*"
                  required
                  color="rgba(0, 23, 135, 1)"
                  outlined
                >
                  <template v-slot:append> </template>
                </v-text-field>
                <v-btn
                  color="juanito"
                  class="frame-home-page-usosdebotones3 rounded-pill"
                  x-large
                  style="
                    text-transform: unset !important;
                    top: 0px;
                    width: 100%;
                    max-width: 286px;
                    text-transform: unset !important;
                  "
                  @click="validate"
                >
                  <span class="frame-home-page-text025">
                    <span>Ingresar</span>
                  </span>
                </v-btn>
              </v-form>
            </v-app>
          </div>
          <div class="frame-home-page-frame520">
            <div class="frame-home-page-iconpasoapaso">
              <div class="frame-home-page-frame52">
                <div class="frame-home-page-frame51">
                  <span class="frame-home-page-text027"><span>Regístrate</span></span>
                </div>
                <span class="frame-home-page-text029">
                  <span>Completa el registro para que podamos aprobar tu línea.</span>
                </span>
              </div>
              <div class="frame-home-page-group459">
                <img
                  alt="Ellipse279I1822"
                  src="/playground_assets/movil/home/ellipse279i1822-2ocv-200h.png"
                  class="frame-home-page-ellipse279"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-u08.svg"
                  class="frame-home-page-vector020"
                />
              </div>
            </div>
            <div class="frame-home-page-iconpasoapaso1">
              <div class="frame-home-page-frame521">
                <div class="frame-home-page-frame511">
                  <span class="frame-home-page-text031"><span>Cotiza</span></span>
                </div>
                <span class="frame-home-page-text033">
                  <span>
                    Ingresa tus datos, el monto del crédito, cantidad de cuotas a pagar
                  </span>
                </span>
              </div>
              <div class="frame-home-page-group460">
                <img
                  alt="Ellipse280I1822"
                  src="/playground_assets/movil/home/ellipse280i1822-g2y6-200h.png"
                  class="frame-home-page-ellipse280"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-w5k7.svg"
                  class="frame-home-page-vector021"
                />
              </div>
            </div>
            <div class="frame-home-page-iconpasoapaso2">
              <div class="frame-home-page-frame522">
                <div class="frame-home-page-frame512">
                  <span class="frame-home-page-text035"><span>Contrátalo</span></span>
                </div>
                <span class="frame-home-page-text037">
                  <span>
                    El comparador de prima te dará los resultados y podrás contratar el
                    servicio.
                  </span>
                </span>
              </div>
              <div class="frame-home-page-group461">
                <img
                  alt="Ellipse281I1822"
                  src="/playground_assets/movil/home/ellipse281i1822-cp7-200h.png"
                  class="frame-home-page-ellipse281"
                />
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-9ph.svg"
                  class="frame-home-page-vector022"
                />
              </div>
            </div>
            <div class="frame-home-page-iconpasoapaso3">
              <div class="frame-home-page-frame523">
                <div class="frame-home-page-frame513">
                  <span class="frame-home-page-text039">
                    <span>
                      Infórmate
                      <span></span>
                    </span>
                  </span>
                </div>
                <span class="frame-home-page-text041">
                  <span> Información constante y directa, para aclarar sus dudas. </span>
                </span>
              </div>
              <div class="frame-home-page-icon-informate">
                <div class="frame-home-page-group462">
                  <img
                    alt="Ellipse280I1822"
                    src="/playground_assets/movil/home/ellipse280i1822-7zt-200h.png"
                    class="frame-home-page-ellipse2801"
                  />
                </div>
                <img
                  alt="VectorI1822"
                  src="/playground_assets/movil/home/vectori1822-ve4e.svg"
                  class="frame-home-page-vector023"
                />
              </div>
            </div>
          </div>
          <div class="frame-home-page-group288921">
            <img
              alt="Vector1822"
              src="/playground_assets/movil/home/vector1822-jqsv.svg"
              class="frame-home-page-vector024"
            />
          </div>
          
           
           
          </div>
          
          <span class="frame-home-page-text063">
            <span>
              <span>Información</span>
              <br />
              <span>legal</span>
            </span>
          </span>
          <span class="frame-home-page-text068">
            <span>
              <span>Preguntas</span>
              <br />
              <span>frecuentes</span>
            </span>
          </span>
          <span class="frame-home-page-text073"><span>Línea desgravamen</span></span>
          <span class="frame-home-page-text075">
            <span>
              <span>¿Tienes dudas?</span>
              <br />
              <span>Te guiamos con las siguientes</span>
              <br />
              <span>preguntas</span>
            </span>
          </span>
          <div data-v-fae5bece="" class="frame-home-page-cards1" v-if="legal">
            <v-app class="ctrans">
              <v-skeleton-loader
                class="mx-auto"
                style="width: 335px; top: 80px"
                type="card"
              ></v-skeleton-loader>
            </v-app>
          </div>
          <div
            data-v-fae5bece=""
            class="frame-home-page-cards1 card1"
            v-if="!legal"
            @mouseover="hoverinfo('card1')"
            @mouseout="hoveroutinfo('card1')"
          >
            <div data-v-fae5bece="" class="frame-home-page-header">
              <div
                data-v-fae5bece=""
                class="frame-home-page-header-subheadand-avatar-autolayout"
              >
                <div data-v-fae5bece="" class="frame-home-page-header-subhead-autolayout">
                  <span data-v-fae5bece="" class="frame-home-page-text082"
                    ><span data-v-fae5bece="">{{ infoLegal.info1.titulo }}</span></span
                  ><span data-v-fae5bece="" class="frame-home-page-text084"
                    ><span data-v-fae5bece="">
                      {{ infoLegal.info1.detalle }}
                      <span data-v-fae5bece=""> </span></span
                  ></span>
                </div>
              </div>
            </div>
            <div data-v-fae5bece="" class="frame-home-page-frame-image">
              <img
                data-v-fae5bece=""
                alt="IMAGEImage1185"
                :src="`/playground_assets/` + infoLegal.info1.imagen"
                class="frame-home-page-imag-image"
              />
            </div>
            <v-btn
              @click="downloadDoc(infoLegal.info1.archivo)"
              color="azulsitio"
              class="frame-home-page-botndescargarcard1 rounded-pill"
              x-large
              style="text-transform: unset !important; height: 40px; margin-left: -18px"
            >
              <span data-v-fae5bece="" class="frame-home-page-text086"
                ><span data-v-fae5bece="">Descargar</span></span
              >
            </v-btn>
          </div>
         
          <div data-v-fae5bece="" class="frame-home-page-cards11" v-if="legal">
            <v-app class="ctrans">
              <v-skeleton-loader
                class="mx-auto"
                style="width: 335px; top: 80px"
                type="card"
              ></v-skeleton-loader>
            </v-app>
          </div>
          <div
            data-v-fae5bece=""
            class="frame-home-page-cards11 card2"
            v-if="!legal"
            @mouseover="hoverinfo('card2')"
            @mouseout="hoveroutinfo('card2')"
          >
            <div data-v-fae5bece="" class="frame-home-page-header1">
              <div
                data-v-fae5bece=""
                class="frame-home-page-header-subheadand-avatar-autolayout1"
              >
                <div
                  data-v-fae5bece=""
                  class="frame-home-page-header-subhead-autolayout1"
                >
                  <span data-v-fae5bece="" class="frame-home-page-text088"
                    ><span data-v-fae5bece="">{{ infoLegal.info2.titulo }}</span></span
                  ><span data-v-fae5bece="" class="frame-home-page-text090"
                    ><span data-v-fae5bece="">
                      {{ infoLegal.info2.titulo }}
                      <span data-v-fae5bece=""> </span></span
                  ></span>
                </div>
              </div>
            </div>
            <div data-v-fae5bece="" class="frame-home-page-frame-image1">
              <img
                data-v-fae5bece=""
                alt="IMAGEImage1185"
                :src="`/playground_assets/` + infoLegal.info2.imagen"
                class="frame-home-page-imag-image1"
              />
            </div>
            <v-btn
              @click="downloadDoc(infoLegal.info2.archivo)"
              color="azulsitio"
              class="frame-home-page-botndescargarcard11 rounded-pill"
              x-large
              style="text-transform: unset !important; height: 40px; margin-left: -18px"
            >
              <span data-v-fae5bece="" class="frame-home-page-text092"
                ><span data-v-fae5bece="">Descargar</span></span
              >
            </v-btn>
          </div>
         
          <div data-v-fae5bece="" class="frame-home-page-cards12" v-if="legal">
            <v-app class="ctrans">
              <v-skeleton-loader
                class="mx-auto"
                style="width: 335px; top: 80px"
                type="card"
              ></v-skeleton-loader>
            </v-app>
          </div>
          <div
            data-v-fae5bece=""
            class="frame-home-page-cards12 card3"
            v-if="!legal"
            @mouseover="hoverinfo('card3')"
            @mouseout="hoveroutinfo('card3')"
          >
            <div data-v-fae5bece="" class="frame-home-page-header2">
              <div
                data-v-fae5bece=""
                class="frame-home-page-header-subheadand-avatar-autolayout2"
              >
                <div
                  data-v-fae5bece=""
                  class="frame-home-page-header-subhead-autolayout2"
                >
                  <span data-v-fae5bece="" class="frame-home-page-text094"
                    ><span data-v-fae5bece="">{{ infoLegal.info3.titulo }}</span></span
                  ><span data-v-fae5bece="" class="frame-home-page-text096"
                    ><span data-v-fae5bece="">
                      {{ infoLegal.info3.titulo }}
                      <span data-v-fae5bece=""> </span></span
                  ></span>
                </div>
              </div>
            </div>
            <div data-v-fae5bece="" class="frame-home-page-frame-image2">
              <img
                data-v-fae5bece=""
                alt="IMAGEImage1185"
                :src="`/playground_assets/` + infoLegal.info3.imagen"
                class="frame-home-page-imag-image2"
              />
            </div>
            <v-btn
              @click="downloadDoc(infoLegal.info3.archivo)"
              color="azulsitio"
              class="frame-home-page-botndescargarcard12 rounded-pill"
              x-large
              style="text-transform: unset !important; height: 40px; margin-left: -18px"
            >
              <span data-v-fae5bece="" class="frame-home-page-text098"
                ><span data-v-fae5bece="">Descargar</span></span
              >
            </v-btn>
          </div>
         
          <template>
            <v-row justify="center"
            style="
            top: 3969px;
            position: absolute;
            width: 100%;
            max-width: 1300px;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            margin-right: 175px;
                "
            >
            <v-col cols="12" xs="12">
            
            
              <v-expansion-panels
                accordion
                focusable
               
              >
                <v-expansion-panel
                  style="margin-bottom: 45px"
                  v-for="(item, i) in pfrecuente"
                  :key="i"
                >
                  <v-expansion-panel-header>{{ item.titulo }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.detalle }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
        <v-col cols="12" xs="12">
            <div class="frame-home-page-cards-preguntas-frecuentes-icono">
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-nu2-200h.png"
              class="frame-home-page-rectangle3795"
            />
            <span class="frame-home-page-text108 FontProductp">
              <span>
                Lorem ipsum dolor sit amet, asaadaconsectetur adipiscing Lorem
                <span></span>
              </span>
            </span>
            <img
              alt="VectorI1822"
              src="/playground_assets/movil/home/vectori1822-0b4k.svg"
              class="frame-home-page-vector125"
            />
          </div>
        </v-col>
        <v-col cols="12">
            
            <img
            data-v-fae5bece=""
            alt="logo2"
            src="/playground_assets/movil/home/Logo_Linea desgravamen_Footer.png"
            style="
                width: 31%;
                margin-left: 34%;
                margin-top: 26px;
                margin-bottom: -13px;
            "      
          />
        </v-col>
        <v-col cols="12">
        <div style="position: relative;">
            
          
          <div class="frame-home-page-rectangulofooter">
            <img
            data-v-fae5bece=""
            alt="Minimi1185"
            src="/playground_assets/minimi1185-jwr5.svg"
            class="frame1home-minimi"
            style="width: 12%;
                margin-left: 86%;"      
          />
            <div class="frame-home-page-caja-quienessomosfooter">
              <span class="frame-home-page-text043"><span>Sobre nosotros</span></span>
              <span class="frame-home-page-text045 FontProductp">
                <span>Somos</span>
              </span>
              <span class="frame-home-page-text047 FontProductp">
                <span>Misión</span>
              </span>
              <span class="frame-home-page-text049 FontProductp">
                <span>Valores</span>
              </span>
              <span class="frame-home-page-text051 FontProductp">
                <span>Visión</span>
              </span>
            </div>
            <div class="frame-home-page-cajacontactofooter">
              <span class="frame-home-page-text053">
                <span>Linea desgravamen</span>
              </span>
              <span class="frame-home-page-text055 FontProductp">
                <span>Fono: +569xxxxx</span>
              </span>
              <span class="frame-home-page-text057 FontProductp">
                <span>
                  Dirección: Av Los leones xx
                  <span></span>
                </span>
              </span>
              <span class="frame-home-page-text059 FontProductp">
                <span>
                  Correo: contacto@segurodesgravamen.cl
                  <span></span>
                </span>
              </span>
              <span class="frame-home-page-text061 FontProductp">
                <span>http://www.garantiasegura.cl/contacto/</span>
              </span>
            </div>
            <img
              alt="GRUPOINSUREXSVG11822"
              src="/playground_assets/movil/home/grupoinsurexsvg11822-596e-200h.png"
              class="frame-home-page-grupoinsurexsvg1"
            />
            <img
              alt="Rectangle23761822"
              src="/playground_assets/movil/home/rectangle23761822-645-200h.png"
              class="frame-home-page-rectangle2376"
            />
          </div>
          <img
            alt="Rectangle23741822"
            src="/playground_assets/movil/home/rectangle23741822-66x-200h.png"
            class="frame-home-page-rectangle2374"
          />
          
        </div>
        </v-col>
        <v-col cols="12">
            
        </v-col>
            </v-row>
          </template>
        
          <!-- <img
            alt="Line11822"
            src="/playground_assets/movil/home/line11822-seeq.svg"
            class="frame-home-page-line1"
          /> -->
          <!-- <div class="frame-home-page-cards-preguntas-frecuentes">
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-k9un-200h.png"
              class="frame-home-page-rectangle3791"
            />
            <div class="frame-home-page-group288956">
              <span class="frame-home-page-text100">
                <span>¿Qué es una línea de desgravamen?</span>
              </span>
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-88z.svg"
                class="frame-home-page-vector121"
              />
            </div>
          </div> -->
          <!-- <div class="frame-home-page-cards-preguntas-frecuentes1">
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-14y-200h.png"
              class="frame-home-page-rectangle3792"
            />
            <div class="frame-home-page-group2889561">
              <span class="frame-home-page-text102">
                <span>¿Qué es una línea de desgravamen?</span>
              </span>
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-7xb.svg"
                class="frame-home-page-vector122"
              />
            </div>
          </div> -->
          <!-- <div class="frame-home-page-cards-preguntas-frecuentes2">
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-uujl-200h.png"
              class="frame-home-page-rectangle3793"
            />
            <div class="frame-home-page-group2889562">
              <span class="frame-home-page-text104">
                <span>¿Qué es una línea de desgravamen?</span>
              </span>
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-ffgm.svg"
                class="frame-home-page-vector123"
              />
            </div>
          </div> -->
          <!-- <div class="frame-home-page-cards-preguntas-frecuentes3">
            <img
              alt="Rectangle379I1822"
              src="/playground_assets/movil/home/rectangle379i1822-m6rs-200h.png"
              class="frame-home-page-rectangle3794"
            />
            <div class="frame-home-page-group2889563">
              <span class="frame-home-page-text106">
                <span>¿Qué es una línea de desgravamen?</span>
              </span>
              <img
                alt="VectorI1822"
                src="/playground_assets/movil/home/vectori1822-8wk.svg"
                class="frame-home-page-vector124"
              />
            </div>
          </div> -->
         
       
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contacto from "../../services/contacto";
import homedata from "../../services/home";

const PUBLIC_DOWNLOAD = process.env.VUE_APP_PUBLIC_DOWNLOAD;

export default {
  name: "Frame1Home",

  data() {
    return {
      //   raw8q7o: ' ',
      //   rawh7on: ' ',
      //   raweenr: ' ',
      //   raw6pkx: ' ',
      //   rawwovy: ' ',
      //   rawmqoq: ' ',
      //   rawq94t: ' ',
      //   rawyvv7: ' ',
      //   raw6cku: ' ',
      legal: true,
      overlay: false,
      formHome: this.$store.state.formHome,
      infoLegal: this.$store.state.infoLegal,
      pfrecuente: this.$store.state.pfrecuente,
      valid: true,
      nombreRules: [
        (v) => !!v || "el nombre es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
      ],
      correoRules: [
        (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
        (v) => !!v || "el campo Correo es requerido",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un correo válido",
        (v) => this.validaMail(v) || "largo mínimo de dominio 3 caracteres",
      ],
      telefonoRules: [
        (v) => this.formatPhoneNumber(v) || "formato inválido",
        (v) => this.largoPhoneNumber(v) || "tamaño invalido",
        (v) => !!v || "el campo Telefono es requerido",
        (v) => (v && v.length == 16) || "largo máximo 9 caracteres",
      ],
      metadata: {
        titulo: "Recupera dinero de tu crédito de consumo.",
        descripcion: "Accede a tu línea Desgravamen digital",
      },
    };
  },
  methods: {
    validaNumeros(e) {
      var code = e.which ? e.which : e.keyCode;
      console.log(code);
      if (code == 8) {
        // backspace.
        return true;
      } else if (code >= 48 && code <= 57) {
        // is a number.
        return true;
      } else {
        // other keys.
        return false;
      }
    },
    validaMail(email) {
      if (!email || email == "") {
        return;
      }
      var revmail = email.split("@");
      if (revmail.length == 2) {
        if (revmail[1].length >= 5) {
          return true;
        }
        return false;
      }
    },
    largoPhoneNumber(phone) {
      if (phone && phone.length > 16) {
        this.formHome.telefono = phone.substring(0, phone.length - 1);
      }
      return true;
    },

    formatPhoneNumber(phone) {
      if (phone && phone != "") {
        let celular = "(+56) 9 ";
        if (!phone.includes(celular)) {
          this.formHome.telefono = celular;
          return;
        }
        if (phone.length <= 7) {
          this.formHome.telefono = celular;
          return;
        }
        phone = phone.replace("(+56) 9 ", "");
        var letra = "";
        for (let index = 0; index < phone.length; index++) {
          celular = celular + phone.charAt(index).replace(/[^0-9]/gi, "");
        }
        this.formHome.telefono = celular;
      }
      return true;
    },
    downloadDoc(doc) {
      // console.log(PUBLIC_DOWNLOAD);
      window.open(PUBLIC_DOWNLOAD + doc, "_blank", "noreferrer");
    },
    async guardarContacto() {
      this.overlay = true;
      let res = false;
      var formulario = {
        datosCorreo: {
          dco_nombre: this.formHome.nombre,
          dco_correo: this.formHome.correo,
          dco_fono: this.formHome.telefono,
        },
      };
      res = await contacto.guardar(formulario);
      console.log(res);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
      } else {
        this.$alertas("Ups, en este momento no es posible enviar la información", "info");
      }
      this.overlay = false;
    },
    async traeInfoLegal() {
      let res = false;
      res = await homedata.traeinfo();
      console.log(res);
      if (res.status == 200) {
        this.infoLegal.info1.titulo = res.data.informacionlegal[0].ile_codigo;
        this.infoLegal.info1.detalle = res.data.informacionlegal[0].ile_titulo;
        this.infoLegal.info1.imagen = res.data.informacionlegal[0].ile_nombre_imagen;
        this.infoLegal.info1.archivo = res.data.informacionlegal[0].ile_nombre_pdf;

        this.infoLegal.info2.titulo = res.data.informacionlegal[1].ile_codigo;
        this.infoLegal.info2.detalle = res.data.informacionlegal[1].ile_titulo;
        this.infoLegal.info2.imagen = res.data.informacionlegal[1].ile_nombre_imagen;
        this.infoLegal.info2.archivo = res.data.informacionlegal[1].ile_nombre_pdf;

        this.infoLegal.info3.titulo = res.data.informacionlegal[2].ile_codigo;
        this.infoLegal.info3.detalle = res.data.informacionlegal[2].ile_titulo;
        this.infoLegal.info3.imagen = res.data.informacionlegal[2].ile_nombre_imagen;
        this.infoLegal.info3.archivo = res.data.informacionlegal[2].ile_nombre_pdf;
        this.legal = false;
      } else {
      }
      //   this.overlay = false;
    },
    async traepFrcuente() {
      let res = false;
      res = await homedata.traepfrecuente();
      console.log(res);

      if (res.status == 200) {
        this.pfrecuente = [];
        for (let index = 0; index < res.data.preguntasfrecuentes.length; index++) {
          this.pfrecuente.push({
            titulo: res.data.preguntasfrecuentes[index].pfr_pregunta,
            detalle: res.data.preguntasfrecuentes[index].pfr_respuesta,
          });
        }
      }

      console.log(this.pfrecuente);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.commit("setformhome", this.formHome);
        this.guardarContacto();
        this.formHome.nombre = "";
        this.formHome.correo = "";
        this.formHome.telefono = "";
        this.reset();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    hover: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover");
    },
    hovertxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover-txt");
    },
    hoverouttxt: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover-txt");
    },

    hoverinfo: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("shadowsInfo");
    },
    hoveroutinfo: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("shadowsInfo");
    },
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
  mounted() {
    var device = navigator.userAgent;
    if (
      device.match(/Iphone/i) ||
      device.match(/Ipod/i) ||
      device.match(/Android/i) ||
      device.match(/J2ME/i) ||
      device.match(/BlackBerry/i) ||
      device.match(/iPhone|iPad|iPod/i) ||
      device.match(/Opera Mini/i) ||
      device.match(/IEMobile/i) ||
      device.match(/Mobile/i) ||
      device.match(/Windows Phone/i) ||
      device.match(/windows mobile/i) ||
      device.match(/windows ce/i) ||
      device.match(/webOS/i) ||
      device.match(/palm/i) ||
      device.match(/bada/i) ||
      device.match(/series60/i) ||
      device.match(/nokia/i) ||
      device.match(/symbian/i) ||
      device.match(/HTC/i)
    ) {
    } else {
      this.$router.push("/home");
    }
    this.traeInfoLegal();
    this.traepFrcuente();
    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit("setislogin", false);
    localStorage.removeItem("loginEstado");
    localStorage.removeItem("loginCliente");

    // this.$store.commit('settipocotizacion', false);
    // this.$store.commit('settipopersonaempresa', false);
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>
<style scoped>
.frame-home-page-container {
    display: grid;
    align-content: center;
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
  min-height: 100vh;
}

.frame-home-page-frame-home-page {
  width: 103%;
  height: 5350px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}

.frame-home-page-background-home {
    top: -0.8%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    /* right: 0px; */
    width: 120%;
    margin-left: -74px;
    margin-top: -85px;
}

.frame-home-page-icon-btns {
  top: 81px;
  left: 302px;
  width: 34px;
  height: 24px;
  position: absolute;
}

.frame-home-page-usosdebotones {
  top: 512px;
  left: 9%;
  width: 290px;
  height: 51px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}

.frame-home-page-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-usosdebotones1 {
  top: 435px;
  left: 9%;
  width: 290px;
  height: 51px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(242, 247, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
}

.frame-home-page-text002 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-logo-v1 {
  top: 29px;
  left: 24px;
  width: 104px;
  height: 109px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-txt {
  top: 75.9056396484375px;
  left: 0px;
  width: 104px;
  height: 33.09480285644531px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group {
  top: 0px;
  left: 30.69034194946289px;
  width: 38.17604446411133px;
  height: 13.758354187011719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector {
  top: 2.2309722900390625px;
  left: 0px;
  width: 7px;
  height: 12px;
  position: absolute;
}

.frame-home-page-vector001 {
  top: 0px;
  left: 7.8600616455078125px;
  width: 4px;
  height: 14px;
  position: absolute;
}

.frame-home-page-vector002 {
  top: 4.4625701904296875px;
  left: 12.3509521484375px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector003 {
  top: 4.4625701904296875px;
  left: 21.33377456665039px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector004 {
  top: 4.4625701904296875px;
  left: 30.3162841796875px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-group01 {
  top: 17.849639892578125px;
  left: 0px;
  width: 99.55695343017578px;
  height: 15.245162963867188px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector005 {
  top: 0px;
  left: 0px;
  width: 9px;
  height: 12px;
  position: absolute;
}

.frame-home-page-vector006 {
  top: 2.6028976440429688px;
  left: 10.104693412780762px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector007 {
  top: 2.6028976440429688px;
  left: 19.087833404541016px;
  width: 7px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector008 {
  top: 2.6028976440429688px;
  left: 26.947214126586914px;
  width: 8px;
  height: 13px;
  position: absolute;
}

.frame-home-page-vector009 {
  top: 2.6028976440429688px;
  left: 36.30441665649414px;
  width: 6px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector010 {
  top: 2.6028976440429688px;
  left: 42.293174743652344px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector011 {
  top: 2.6028976440429688px;
  left: 50.901309967041016px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector012 {
  top: 2.6028976440429688px;
  left: 59.50944137573242px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector013 {
  top: 2.6028976440429688px;
  left: 68.86664581298828px;
  width: 12px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector014 {
  top: 2.6028976440429688px;
  left: 82.34040832519531px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector015 {
  top: 2.6028976440429688px;
  left: 91.32291412353516px;
  width: 8px;
  height: 9px;
  position: absolute;
}

.frame-home-page-group02 {
  top: 17.06507110595703px;
  left: 100.03536224365234px;
  width: 3.9646377563476562px;
  height: 3.9387588500976562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group03 {
  top: 0.9855575561523438px;
  left: 1.2907791137695312px;
  width: 1.6519317626953125px;
  height: 1.9693832397460938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector016 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.frame-home-page-group04 {
  top: 0px;
  left: 0px;
  width: 3.9646377563476562px;
  height: 3.9387588500976562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector017 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  position: absolute;
}

.frame-home-page-img {
  top: 0px;
  left: 17.578819274902344px;
  width: 68.36272430419922px;
  height: 67.91618347167969px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector018 {
  top: 1.1958632469177246px;
  left: 0px;
  width: 25px;
  height: 54px;
  position: absolute;
}

.frame-home-page-vector019 {
  top: 0px;
  left: 21.664466857910156px;
  width: 47px;
  height: 68px;
  position: absolute;
}

.frame-home-page-text004 {
  top: 203px;
  left: 9%;
  color: var(--dl-color-colores_neutrales-white);
  width: 312px;
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 40px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text006 {
  top: 351px;
  left: 18%;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-icon-btns1 {
  top: 29px;
  left: 306px;
  width: 25px;
  height: 25px;
  position: absolute;
}

.frame-home-page-text011 {
  top: 615px;
  left: 66px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text013 {
  top: 676px;
  left: 73px;
  color: var(--dl-color-colores_paleta-tonos_morado-30);
  width: 214px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-cardquieresmasinfo {
  top: 730px;
  left: 24px;
  width: 312px;
  height: 430px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-group288895 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 424px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-formtophome {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 424px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}

.frame-home-page-rectangle7 {
  top: 146px;
  left: 10.446563720703125px;
  width: 281px;
  height: 42px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
}

.frame-home-page-rectangle8 {
  top: 210.87078857421875px;
  left: 10.446563720703125px;
  width: 281px;
  height: 42px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
}

.frame-home-page-rectangle379 {
  top: 276px;
  left: 10.446563720703125px;
  width: 281px;
  height: 42px;
  position: absolute;
  border-color: rgba(83, 89, 103, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
}

.frame-home-page-text015 {
  top: 69px;
  left: 8px;
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-text017 {
  top: 99.443359375px;
  left: 79px;
  color: var(--dl-color-colores_neutrales-50);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-text019 {
  top: 157.517578125px;
  left: 23.493988037109375px;
  color: var(--dl-color-colores_neutrales-80);
  width: 194px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-text021 {
  top: 222.59327697753906px;
  left: 23.493988037109375px;
  color: var(--dl-color-colores_neutrales-80);
  width: 143px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-text023 {
  top: 287.927001953125px;
  left: 23.493988037109375px;
  color: var(--dl-color-colores_neutrales-80);
  width: 143px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Light;
  text-align: left;
  font-family: Roboto;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-usosdebotones2 {
  top: 346px;
  left: 45.999969482421875px;
  width: 220px;
  height: 51px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-usosdebotones3 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}

.frame-home-page-text025 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-top: 12px;
}

.frame-home-page-frame520 {
  top: 1212px;
  left: 24px;
  width: 312px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-iconpasoapaso {
  width: 312px;
  height: 223px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 40px;
}

.frame-home-page-frame52 {
  top: 79px;
  left: 14px;
  width: 283px;
  height: 144px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-frame51 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
  justify-content: center;
}

.frame-home-page-text027 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 25px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text029 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 210px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-group459 {
  top: 0px;
  left: 116px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-ellipse279 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-vector020 {
  top: 17.454261779785156px;
  left: 16.727155685424805px;
  width: 55px;
  height: 46px;
  position: absolute;
}

.frame-home-page-iconpasoapaso1 {
  width: 312px;
  height: 223px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 40px;
}

.frame-home-page-frame521 {
  top: 89px;
  left: 14px;
  width: 283px;
  height: 144px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-frame511 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
  justify-content: center;
}

.frame-home-page-text031 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 25px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text033 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 227px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-group460 {
  top: 9px;
  left: 116px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-ellipse280 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-vector021 {
  top: 17.324604034423828px;
  left: 17.32468032836914px;
  width: 46px;
  height: 46px;
  position: absolute;
}

.frame-home-page-iconpasoapaso2 {
  width: 312px;
  height: 223px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 40px;
}

.frame-home-page-frame522 {
  top: 85px;
  left: 14px;
  width: 283px;
  height: 144px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-frame512 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
  justify-content: center;
}

.frame-home-page-text035 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 25px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text037 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 239px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-group461 {
  top: 5px;
  left: 116px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-ellipse281 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-vector022 {
  top: 21.400800704956055px;
  left: 23.439437866210938px;
  width: 37px;
  height: 37px;
  position: absolute;
}

.frame-home-page-iconpasoapaso3 {
  width: 312px;
  height: 223px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-frame523 {
  top: 97px;
  left: 14px;
  width: 283px;
  height: 144px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-frame513 {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
  justify-content: center;
}

.frame-home-page-text039 {
  color: rgba(83, 89, 103, 1);
  height: auto;
  font-size: 25px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text041 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 233px;
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-icon-informate {
  top: 9px;
  left: 116px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group462 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-ellipse2801 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-vector023 {
  top: 18.902673721313477px;
  left: 20.792940139770508px;
  width: 38px;
  height: 42px;
  position: absolute;
}

.frame-home-page-group288921 {
  top: 721px;
  left: 160px;
  width: 40px;
  height: 48.87673568725586px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector024 {
  top: 5px;
  left: 0px;
  width: 40px;
  height: 49px;
  position: absolute;
}

.frame-home-page-logo-lineadesgravamen-footer {
  top: 4566px;
  left: 125px;
  width: 110.0482406616211px;
  height: 116.29592895507812px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-txt1 {
  top: 80.98604583740234px;
  left: 0px;
  width: 110.0482406616211px;
  height: 35.30988311767578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group05 {
  top: 0.000022539203200722113px;
  left: 32.47490310668945px;
  width: 40.39622116088867px;
  height: 14.679211616516113px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector025 {
  top: 2.3803224563598633px;
  left: -0.00019488214456941932px;
  width: 8px;
  height: 12px;
  position: absolute;
}

.frame-home-page-vector026 {
  top: 0.000022539203200722113px;
  left: 8.317182540893555px;
  width: 4px;
  height: 15px;
  position: absolute;
}

.frame-home-page-vector027 {
  top: 4.7612385749816895px;
  left: 13.06930923461914px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector028 {
  top: 4.7612385749816895px;
  left: 22.574485778808594px;
  width: 9px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector029 {
  top: 4.7612385749816895px;
  left: 32.0793571472168px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame-home-page-group06 {
  top: 19.04427146911621px;
  left: 0.00003458409628365189px;
  width: 105.34680938720703px;
  height: 16.265552520751953px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector030 {
  top: 0px;
  left: 0px;
  width: 10px;
  height: 13px;
  position: absolute;
}

.frame-home-page-vector031 {
  top: 2.7771172523498535px;
  left: 10.69208812713623px;
  width: 9px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector032 {
  top: 2.7771172523498535px;
  left: 20.1978816986084px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector033 {
  top: 2.7771172523498535px;
  left: 28.51433563232422px;
  width: 8px;
  height: 13px;
  position: absolute;
}

.frame-home-page-vector034 {
  top: 2.7771172523498535px;
  left: 38.41575622558594px;
  width: 6px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector035 {
  top: 2.7771172523498535px;
  left: 44.752540588378906px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector036 {
  top: 2.7771172523498535px;
  left: 53.86147689819336px;
  width: 9px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector037 {
  top: 2.7771172523498535px;
  left: 62.9701042175293px;
  width: 8px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector038 {
  top: 2.7771172523498535px;
  left: 72.87152099609375px;
  width: 13px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector039 {
  top: 2.7771172523498535px;
  left: 87.12882995605469px;
  width: 9px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector040 {
  top: 2.7771172523498535px;
  left: 96.63369750976562px;
  width: 9px;
  height: 10px;
  position: absolute;
}

.frame-home-page-group07 {
  top: 18.207263946533203px;
  left: 105.85293579101562px;
  width: 4.195206165313721px;
  height: 4.2023844718933105px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group08 {
  top: 1.0515238046646118px;
  left: 1.365497350692749px;
  width: 1.7479989528656006px;
  height: 2.101196050643921px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector041 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}

.frame-home-page-group09 {
  top: 0px;
  left: 0px;
  width: 4.195206165313721px;
  height: 4.2023844718933105px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector042 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  position: absolute;
}

.frame-home-page-img1 {
  top: 0px;
  left: 18.601173400878906px;
  width: 72.33844757080078px;
  height: 72.46190643310547px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector043 {
  top: 1.2758617401123047px;
  left: 0.00013300433056429029px;
  width: 26px;
  height: 57px;
  position: absolute;
}

.frame-home-page-vector044 {
  top: -0.00006103515625px;
  left: 22.924530029296875px;
  width: 49px;
  height: 72px;
  position: absolute;
}

.frame-home-page-rectangulofooter {
  /* top: 4751px; */
  left: 0px;
  width: 361px;
  height: 599px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(247, 247, 247, 1);
}

.frame-home-page-caja-quienessomosfooter {
  top: 38px;
  left: 128px;
  width: 105px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame-home-page-text043 {
  width: 105px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame-home-page-text045 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text047 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text049 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text051 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}

.frame-home-page-cajacontactofooter {
  top: 255px;
  left: 41px;
  width: 278px;
  display: flex;
  padding: 4px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
}

.frame-home-page-text053 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 15px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 4px;
  text-decoration: none;
}

.frame-home-page-text055 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text057 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text059 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 4px;
}

.frame-home-page-text061 {
  color: var(--dl-color-colores_paleta-tonos_morado-40);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}

.frame-home-page-grupoinsurexsvg1 {
  top: 441px;
  left: 125px;
  width: 110px;
  height: 92px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-rectangle2376 {
  top: 208px;
  left: 101px;
  width: 158px;
  height: 3px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-personasentada-footer {
  top: 4703px;
  left: 301px;
  width: 48.33339309692383px;
  height: 82.99999237060547px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector045 {
  top: 37.08468246459961px;
  left: 9.501557350158691px;
  width: 36px;
  height: 17px;
  position: absolute;
}

.frame-home-page-group10 {
  top: 50.595062255859375px;
  left: 4.163679599761963px;
  width: 12.087895393371582px;
  height: 24.375173568725586px;
  display: flex;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector046 {
  top: 1.0436787605285645px;
  left: 0.033752091228961945px;
  width: 12px;
  height: 23px;
  opacity: 0.05;
  position: absolute;
}

.frame-home-page-vector047 {
  top: 2.0672216415405273px;
  left: 0.06737447530031204px;
  width: 11px;
  height: 22px;
  opacity: 0.1;
  position: absolute;
}

.frame-home-page-vector048 {
  top: 3.097069025039673px;
  left: 0.10099685937166214px;
  width: 11px;
  height: 21px;
  opacity: 0.14;
  position: absolute;
}

.frame-home-page-vector049 {
  top: 4.147283554077148px;
  left: 0.13461925089359283px;
  width: 10px;
  height: 20px;
  opacity: 0.19;
  position: absolute;
}

.frame-home-page-vector050 {
  top: 5.1911940574646px;
  left: 0.17470747232437134px;
  width: 10px;
  height: 19px;
  opacity: 0.24;
  position: absolute;
}

.frame-home-page-vector051 {
  top: 6.200835227966309px;
  left: 0.20832985639572144px;
  width: 10px;
  height: 18px;
  opacity: 0.29;
  position: absolute;
}

.frame-home-page-vector052 {
  top: 7.237471580505371px;
  left: 0.23451653122901917px;
  width: 9px;
  height: 17px;
  opacity: 0.33;
  position: absolute;
}

.frame-home-page-vector053 {
  top: 8.287686347961426px;
  left: 0.2749280333518982px;
  width: 9px;
  height: 16px;
  opacity: 0.38;
  position: absolute;
}

.frame-home-page-vector054 {
  top: 9.324322700500488px;
  left: 0.3085504174232483px;
  width: 8px;
  height: 15px;
  opacity: 0.43;
  position: absolute;
}

.frame-home-page-vector055 {
  top: 10.34786605834961px;
  left: 0.3405563533306122px;
  width: 8px;
  height: 14px;
  opacity: 0.48;
  position: absolute;
}

.frame-home-page-vector056 {
  top: 11.377874374389648px;
  left: 0.36706632375717163px;
  width: 8px;
  height: 13px;
  opacity: 0.52;
  position: absolute;
}

.frame-home-page-vector057 {
  top: 12.421299934387207px;
  left: 0.4068312644958496px;
  width: 7px;
  height: 11px;
  opacity: 0.57;
  position: absolute;
}

.frame-home-page-vector058 {
  top: 13.458420753479004px;
  left: 0.431078165769577px;
  width: 7px;
  height: 10px;
  opacity: 0.62;
  position: absolute;
}

.frame-home-page-vector059 {
  top: 14.488268852233887px;
  left: 0.470843106508255px;
  width: 7px;
  height: 9px;
  opacity: 0.67;
  position: absolute;
}

.frame-home-page-vector060 {
  top: 15.511811256408691px;
  left: 0.5225698351860046px;
  width: 6px;
  height: 8px;
  opacity: 0.71;
  position: absolute;
}

.frame-home-page-vector061 {
  top: 16.56202507019043px;
  left: 0.5377645492553711px;
  width: 6px;
  height: 7px;
  opacity: 0.76;
  position: absolute;
}

.frame-home-page-vector062 {
  top: 17.591711044311523px;
  left: 0.5733267068862915px;
  width: 5px;
  height: 6px;
  opacity: 0.81;
  position: absolute;
}

.frame-home-page-vector063 {
  top: 18.640148162841797px;
  left: 0.5943406820297241px;
  width: 5px;
  height: 5px;
  opacity: 0.86;
  position: absolute;
}

.frame-home-page-vector064 {
  top: 19.639606475830078px;
  left: 0.6185876131057739px;
  width: 5px;
  height: 4px;
  opacity: 0.9;
  position: absolute;
}

.frame-home-page-vector065 {
  top: 20.620311737060547px;
  left: 0.6088888645172119px;
  width: 4px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}

.frame-home-page-vector066 {
  top: 21.386192321777344px;
  left: 0.5914310812950134px;
  width: 4px;
  height: 2px;
  position: absolute;
}

.frame-home-page-vector067 {
  top: 57.3036994934082px;
  left: 17.69377899169922px;
  width: 12px;
  height: 26px;
  opacity: 0.05;
  position: absolute;
}

.frame-home-page-vector068 {
  top: 58.44184875488281px;
  left: 17.734514236450195px;
  width: 12px;
  height: 24px;
  opacity: 0.1;
  position: absolute;
}

.frame-home-page-vector069 {
  top: 59.573856353759766px;
  left: 17.767812728881836px;
  width: 11px;
  height: 23px;
  opacity: 0.14;
  position: absolute;
}

.frame-home-page-vector070 {
  top: 60.719120025634766px;
  left: 17.80790138244629px;
  width: 11px;
  height: 22px;
  opacity: 0.19;
  position: absolute;
}

.frame-home-page-vector071 {
  top: 61.87747573852539px;
  left: 17.841846466064453px;
  width: 11px;
  height: 21px;
  opacity: 0.24;
  position: absolute;
}

.frame-home-page-vector072 {
  top: 63.01594543457031px;
  left: 17.87579345703125px;
  width: 10px;
  height: 20px;
  opacity: 0.29;
  position: absolute;
}

.frame-home-page-vector073 {
  top: 64.15409851074219px;
  left: 17.921701431274414px;
  width: 10px;
  height: 18px;
  opacity: 0.33;
  position: absolute;
}

.frame-home-page-vector074 {
  top: 65.29984283447266px;
  left: 17.94853401184082px;
  width: 9px;
  height: 17px;
  opacity: 0.38;
  position: absolute;
}

.frame-home-page-vector075 {
  top: 66.45157623291016px;
  left: 17.99573516845703px;
  width: 9px;
  height: 16px;
  opacity: 0.43;
  position: absolute;
}

.frame-home-page-vector076 {
  top: 67.59716033935547px;
  left: 18.02806282043457px;
  width: 9px;
  height: 15px;
  opacity: 0.48;
  position: absolute;
}

.frame-home-page-vector077 {
  top: 68.72868347167969px;
  left: 18.05457305908203px;
  width: 8px;
  height: 14px;
  opacity: 0.52;
  position: absolute;
}

.frame-home-page-vector078 {
  top: 69.87410736083984px;
  left: 18.106300354003906px;
  width: 8px;
  height: 13px;
  opacity: 0.57;
  position: absolute;
}

.frame-home-page-vector079 {
  top: 71.00579071044922px;
  left: 18.12537384033203px;
  width: 7px;
  height: 11px;
  opacity: 0.62;
  position: absolute;
}

.frame-home-page-vector080 {
  top: 72.16365814208984px;
  left: 18.171281814575195px;
  width: 7px;
  height: 10px;
  opacity: 0.67;
  position: absolute;
}

.frame-home-page-vector081 {
  top: 73.68523406982422px;
  left: 17.768783569335938px;
  width: 7px;
  height: 9px;
  opacity: 0.71;
  position: absolute;
}

.frame-home-page-vector082 {
  top: 74.83033752441406px;
  left: 17.793354034423828px;
  width: 6px;
  height: 8px;
  opacity: 0.76;
  position: absolute;
}

.frame-home-page-vector083 {
  top: 75.96913146972656px;
  left: 17.836029052734375px;
  width: 6px;
  height: 7px;
  opacity: 0.81;
  position: absolute;
}

.frame-home-page-vector084 {
  top: 77.11196899414062px;
  left: 17.865123748779297px;
  width: 5px;
  height: 5px;
  opacity: 0.86;
  position: absolute;
}

.frame-home-page-vector085 {
  top: 78.24899291992188px;
  left: 17.887432098388672px;
  width: 5px;
  height: 4px;
  opacity: 0.9;
  position: absolute;
}

.frame-home-page-vector086 {
  top: 79.33460235595703px;
  left: 17.89066505432129px;
  width: 5px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}

.frame-home-page-vector087 {
  top: 80.17257690429688px;
  left: 17.866416931152344px;
  width: 4px;
  height: 2px;
  position: absolute;
}

.frame-home-page-vector088 {
  top: 11.044468879699707px;
  left: 14.088103294372559px;
  width: 13px;
  height: 27px;
  position: absolute;
}

.frame-home-page-vector089 {
  top: 15.238537788391113px;
  left: 17.29742431640625px;
  width: 10px;
  height: 21px;
  position: absolute;
}

.frame-home-page-vector090 {
  top: 76.0394515991211px;
  left: 17.344301223754883px;
  width: 8px;
  height: 7px;
  position: absolute;
}

.frame-home-page-vector091 {
  top: 76.17975616455078px;
  left: 17.33783531188965px;
  width: 8px;
  height: 7px;
  position: absolute;
}

.frame-home-page-vector092 {
  top: 67.0556411743164px;
  left: 4.154627323150635px;
  width: 8px;
  height: 7px;
  position: absolute;
}

.frame-home-page-vector093 {
  top: 67.0556411743164px;
  left: 4.150748252868652px;
  width: 8px;
  height: 7px;
  position: absolute;
}

.frame-home-page-vector094 {
  top: 34.577552795410156px;
  left: 0px;
  width: 34px;
  height: 35px;
  position: absolute;
}

.frame-home-page-vector095 {
  top: 38.4535026550293px;
  left: 6.948194980621338px;
  width: 23px;
  height: 31px;
  position: absolute;
}

.frame-home-page-vector096 {
  top: 34.570762634277344px;
  left: 19.433414459228516px;
  width: 22px;
  height: 43px;
  position: absolute;
}

.frame-home-page-vector097 {
  top: 10.037090301513672px;
  left: 22.1749324798584px;
  width: 19px;
  height: 30px;
  position: absolute;
}

.frame-home-page-vector098 {
  top: 11.023615837097168px;
  left: 27.78922462463379px;
  width: 8px;
  height: 7px;
  position: absolute;
}

.frame-home-page-vector099 {
  top: 35.68886947631836px;
  left: 13.294421195983887px;
  width: 4px;
  height: 6px;
  position: absolute;
}

.frame-home-page-vector100 {
  top: 5.62318229675293px;
  left: 28.20303726196289px;
  width: 7px;
  height: 10px;
  position: absolute;
}

.frame-home-page-vector101 {
  top: 5.617039680480957px;
  left: 28.202390670776367px;
  width: 5px;
  height: 9px;
  position: absolute;
}

.frame-home-page-vector102 {
  top: 0.952903687953949px;
  left: 25.620256423950195px;
  width: 8px;
  height: 12px;
  position: absolute;
}

.frame-home-page-vector103 {
  top: 37.04410934448242px;
  left: 25.30601692199707px;
  width: 16px;
  height: 40px;
  position: absolute;
}

.frame-home-page-vector104 {
  top: 0px;
  left: 23.91909408569336px;
  width: 11px;
  height: 9px;
  position: absolute;
}

.frame-home-page-group11 {
  top: 33.49484634399414px;
  left: 7.1715898513793945px;
  width: 29.873981475830078px;
  height: 18.12832260131836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector105 {
  top: 0.8064257502555847px;
  left: 0.0021672705188393593px;
  width: 30px;
  height: 17px;
  position: absolute;
}

.frame-home-page-vector106 {
  top: -6.85486156726256e-9px;
  left: -3.0659236927021993e-8px;
  width: 30px;
  height: 17px;
  position: absolute;
}

.frame-home-page-vector107 {
  top: 0.3237505555152893px;
  left: 0.33968427777290344px;
  width: 29px;
  height: 17px;
  position: absolute;
}

.frame-home-page-vector108 {
  top: 3.1532034873962402px;
  left: 4.2398810386657715px;
  width: 20px;
  height: 12px;
  position: absolute;
}

.frame-home-page-vector109 {
  top: 3.6025795936584473px;
  left: 5.028067111968994px;
  width: 19px;
  height: 11px;
  position: absolute;
}

.frame-home-page-group12 {
  top: 24.178213119506836px;
  left: 4.895936489105225px;
  width: 20.830480575561523px;
  height: 26.470151901245117px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector110 {
  top: 0.18313521146774292px;
  left: -0.000035413333534961566px;
  width: 21px;
  height: 26px;
  position: absolute;
}

.frame-home-page-vector111 {
  top: -0.000009797327948035672px;
  left: 0.29997971653938293px;
  width: 21px;
  height: 26px;
  position: absolute;
}

.frame-home-page-vector112 {
  top: 40.24243927001953px;
  left: 27.043066024780273px;
  width: 8px;
  height: 4px;
  position: absolute;
}

.frame-home-page-vector113 {
  top: 15.997466087341309px;
  left: 31.338972091674805px;
  width: 9px;
  height: 24px;
  position: absolute;
}

.frame-home-page-vector114 {
  top: 14.256699562072754px;
  left: 31.993314743041992px;
  width: 16px;
  height: 29px;
  position: absolute;
}

.frame-home-page-rectangle2374 {
    left: -23px;
    width: 393px;
    height: 22px;
    position: absolute;
  border-color: transparent;
}

.frame-home-page-text063 {
  top: 2312px;
  left: 68px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 41.5px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text068 {
  top: 3765px;
  left: 81px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 41.5px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text073 {
  top: 2401px;
  left: 73px;
  color: var(--dl-color-colores_paleta-tonos_morado-30);
  width: 214px;
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 65px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-text075 {
  top: 3876px;
  left: 24px;
  color: var(--dl-color-colores_paleta-tonos_morado-30);
  width: 312px;
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-cards1 {
  top: 2471px;
  left: 24px;
  width: 312px;
  height: 372px;
  display: flex;
  position: absolute;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame-home-page-header {
  top: 0px;
  left: 0px;
  width: 312px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-header-subheadand-avatar-autolayout {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
}

.frame-home-page-header-subhead-autolayout {
  width: 280px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-text082 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame-home-page-text084 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-frame-image {
  top: 105px;
  left: 0px;
  width: 312px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame-home-page-imag-image {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-imag-states {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-group10 {
  top: 51px;
  left: 109.2447738647461px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group13 {
  top: 0px;
  left: 0px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector115 {
  top: 32.8125px;
  left: 17.77484703063965px;
  width: 18px;
  height: 20px;
  position: absolute;
}

.frame-home-page-vector116 {
  top: 0px;
  left: 0px;
  width: 95px;
  height: 92px;
  position: absolute;
}

.frame-home-page-botndescargarcard1 {
  top: 321px;
  left: 94px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40) !important;
}

.frame-home-page-text086 {
  top: -1px;
  left: 15px;
  color: var(--dl-color-colores_neutrales-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-cards11 {
  top: 2889px;
  left: 24px;
  width: 312px;
  height: 372px;
  display: flex;
  position: absolute;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame-home-page-header1 {
  top: 0px;
  left: 0px;
  width: 312px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-header-subheadand-avatar-autolayout1 {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
}

.frame-home-page-header-subhead-autolayout1 {
  width: 280px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-text088 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame-home-page-text090 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-frame-image1 {
  top: 105px;
  left: 0px;
  width: 312px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame-home-page-imag-image1 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-imag-states1 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-group101 {
  top: 51px;
  left: 109.2447738647461px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group14 {
  top: 0px;
  left: 0px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector117 {
  top: 32.8125px;
  left: 17.77484703063965px;
  width: 18px;
  height: 20px;
  position: absolute;
}

.frame-home-page-vector118 {
  top: 0px;
  left: 0px;
  width: 95px;
  height: 92px;
  position: absolute;
}

.frame-home-page-botndescargarcard11 {
  top: 321px;
  left: 94px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40) !important;
}

.frame-home-page-text092 {
  top: -1px;
  left: 15px;
  color: var(--dl-color-colores_neutrales-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-cards12 {
  top: 3307px;
  left: 24px;
  width: 312px;
  height: 372px;
  display: flex;
  position: absolute;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.20000000298023224);
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(0, 0, 0, 0.11999999731779099);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.frame-home-page-header2 {
  top: 0px;
  left: 0px;
  width: 312px;
  display: flex;
  padding: 14px 16px 12px 16px;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-header-subheadand-avatar-autolayout2 {
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
}

.frame-home-page-header-subhead-autolayout2 {
  width: 280px;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}

.frame-home-page-text094 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 22px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.frame-home-page-text096 {
  color: var(--dl-color-colores_neutrales-dark);
  width: 303px;
  height: auto;
  font-size: 14px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-frame-image2 {
  top: 105px;
  left: 0px;
  width: 312px;
  height: 194px;
  display: flex;
  position: absolute;
  align-self: stretch;
  align-items: flex-start;
  border-color: transparent;
}

.frame-home-page-imag-image2 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-imag-states2 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 194px;
  position: absolute;
  border-color: transparent;
}

.frame-home-page-group102 {
  top: 51px;
  left: 109.2447738647461px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-group15 {
  top: 0px;
  left: 0px;
  width: 94.78733825683594px;
  height: 91.875px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-vector119 {
  top: 32.8125px;
  left: 17.77484703063965px;
  width: 18px;
  height: 20px;
  position: absolute;
}

.frame-home-page-vector120 {
  top: 0px;
  left: 0px;
  width: 95px;
  height: 92px;
  position: absolute;
}

.frame-home-page-botndescargarcard12 {
  top: 321px;
  left: 94px;
  width: 123px;
  height: 27px;
  display: flex;
  padding: 8px 20px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
}

.frame-home-page-text098 {
  top: -1px;
  left: 15px;
  color: var(--dl-color-colores_neutrales-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: IBM Plex Sans Thai;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.frame-home-page-line1 {
  top: 2280px;
  left: 55px;
  width: 249px;
  height: 0px;
  position: absolute;
}

.frame-home-page-cards-preguntas-frecuentes {
  top: 3975px;
  left: 25px;
  width: 312px;
  height: 85px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-rectangle3791 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 85px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.frame-home-page-group288956 {
  top: 27px;
  left: 8.929740905761719px;
  width: 291.57025146484375px;
  height: 30.110910415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-text100 {
  top: 4px;
  left: 37.57025909423828px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-vector121 {
  top: 0px;
  left: 29.999794006347656px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.frame-home-page-cards-preguntas-frecuentes1 {
  top: 4084px;
  left: 25px;
  width: 312px;
  height: 85px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-rectangle3792 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 85px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.frame-home-page-group2889561 {
  top: 27px;
  left: 8.929740905761719px;
  width: 291.57025146484375px;
  height: 30.110910415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-text102 {
  top: 4px;
  left: 37.57025909423828px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-vector122 {
  top: 0px;
  left: 29.999794006347656px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.frame-home-page-cards-preguntas-frecuentes2 {
  top: 4193px;
  left: 25px;
  width: 312px;
  height: 85px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-rectangle3793 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 85px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.frame-home-page-group2889562 {
  top: 27px;
  left: 8.929740905761719px;
  width: 291.57025146484375px;
  height: 30.110910415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-text104 {
  top: 4px;
  left: 37.57025909423828px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-vector123 {
  top: 0px;
  left: 29.999794006347656px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.frame-home-page-cards-preguntas-frecuentes3 {
  top: 4302px;
  left: 25px;
  width: 312px;
  height: 85px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-rectangle3794 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 85px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.frame-home-page-group2889563 {
  top: 27px;
  left: 8.929740905761719px;
  width: 291.57025146484375px;
  height: 30.110910415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}

.frame-home-page-text106 {
  top: 4px;
  left: 37.57025909423828px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame-home-page-vector124 {
  top: 0px;
  left: 29.999794006347656px;
  width: 30px;
  height: 30px;
  position: absolute;
}

.frame-home-page-cards-preguntas-frecuentes-icono {
  /* top: 4411px; */
  left: 21px;
  width: 312px;
  height: 85px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}

.frame-home-page-rectangle3795 {
  top: 0px;
  left: 0px;
  width: 312px;
  height: 85px;
  position: absolute;
  border-color: rgba(113, 48, 237, 1);
  border-style: solid;
  border-width: 0.5px;
  border-radius: 4px;
}

.frame-home-page-text108 {
  top: 18px;
  left: 49px;
  color: var(--dl-color-colores_neutrales-90);
  width: 249px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}

.frame-home-page-vector125 {
  top: 31px;
  left: 9px;
  width: 30px;
  height: 22px;
  position: absolute;
}

@media (max-width: 767px) {
  .frame-home-page-background-home {
    top: 0px;
    left: -68px;
    width: 488px;
    height: 680px;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: 0px;
  }
}

@media (max-width: 479px) {
  .frame-home-page-frame-home-page {
    position: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  /* .frame-home-page-background-home {
    top: 0px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    left: -83px;
    right: -4px;
    width: 478px;
    height: 607px;
    margin-right: var(--dl-space-space-unit);
  } */

  .frame-home-page-usosdebotones {
    height: 33px;
  }

  .frame-home-page-usosdebotones1 {
    height: 30px;
  }

  .frame-home-page-formtophome {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
</style>
