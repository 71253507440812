<template>
  <div class="overlay-dasboarddelusuario-container">
    <div class="overlay-dasboarddelusuario-overlay-dasboarddelusuario">
      <img
        src="/playground_assets/movil/linea/rectangle24182171-d5ia.svg"
        alt="Rectangle24182171"
        class="overlay-dasboarddelusuario-rectangle2418"
      />
      <img
        src="/playground_assets/movil/linea/rectanguloarribarellenalasiguienteencuesta2171-xe0c.svg"
        alt="RectanguloarribaRellenalasiguienteencuesta2171"
        class="overlay-dasboarddelusuario-rectanguloarriba-rellenalasiguienteencuesta"
      />
      <img
        src="/playground_assets/movil/linea/rectanguloarribarellenalasiguienteencuesta2171-8tra-200h.png"
        alt="RectanguloarribaRellenalasiguienteencuesta2171"
        class="overlay-dasboarddelusuario-rectanguloarriba-rellenalasiguienteencuesta1"
      />
      <div class="overlay-dasboarddelusuario-group288878">
        <span class="overlay-dasboarddelusuario-text">
          <span class="overlay-dasboarddelusuario-text01">
            <span>Condiciones y</span>
            <br />
            <span></span>
          </span>
          <span>
            BENEFICIOS
            
          </span>
        </span>
      </div>
      <span class="overlay-dasboarddelusuario-text06">
        <span class="overlay-dasboarddelusuario-text07">
          Info!
          
        </span>
        <span class="overlay-dasboarddelusuario-text08">
          <span>
            Lorem Ipsum is simply dummy text of the printing and
            
          </span>
          <br />
          <span></span>
        </span>
        <span>Seguro de Desgravamen On line.</span>
      </span>
      <img @click="closeDialog"
        src="/playground_assets/movil/linea/iconbtns2171-0cx9.svg"
        alt="IconBtns2171"
        class="overlay-dasboarddelusuario-icon-btns"
      />
      <div class="overlay-dasboarddelusuario-group289073">
        <div class="overlay-dasboarddelusuario-group289069">
          <div class="overlay-dasboarddelusuario-group460">
            <img
              src="/playground_assets/movil/linea/ellipse2802171-zhk-200h.png"
              alt="Ellipse2802171"
              class="overlay-dasboarddelusuario-ellipse280"
            />
            <img
              src="/playground_assets/movil/linea/vector2171-j96w.svg"
              alt="Vector2171"
              class="overlay-dasboarddelusuario-vector"
            />
          </div>
          <div class="overlay-dasboarddelusuario-group288902">
            <span class="overlay-dasboarddelusuario-text13">
              <span>{{ detallePlan[0].ben_titulo }}</span>
            </span>
            <span class="overlay-dasboarddelusuario-text15">
              <span>
                <span>{{ detallePlan[0].ben_texto }}</span>
                <!-- <br />
                <span>text of the printing and</span> -->
              </span>
            </span>
          </div>
        </div>
        <div class="overlay-dasboarddelusuario-group289070">
          <div class="overlay-dasboarddelusuario-group4601">
            <img
              src="/playground_assets/movil/linea/ellipse2802171-qj1i-200h.png"
              alt="Ellipse2802171"
              class="overlay-dasboarddelusuario-ellipse2801"
            />
            <img
              src="/playground_assets/movil/linea/vector2171-3f1a.svg"
              alt="Vector2171"
              class="overlay-dasboarddelusuario-vector1"
            />
          </div>
          <div class="overlay-dasboarddelusuario-group2889021">
            <span class="overlay-dasboarddelusuario-text20">
              <span>{{ detallePlan[1].ben_titulo }}</span>
            </span>
            <span class="overlay-dasboarddelusuario-text22">
              <span>
                <span>{{ detallePlan[1].ben_texto }}</span>
                <!-- <br />
                <span>text of the printing and</span> -->
              </span>
            </span>
          </div>
        </div>
        <div class="overlay-dasboarddelusuario-group289071">
          <div class="overlay-dasboarddelusuario-group4602">
            <img
              src="/playground_assets/movil/linea/ellipse2802171-str8-200h.png"
              alt="Ellipse2802171"
              class="overlay-dasboarddelusuario-ellipse2802"
            />
            <img
              src="/playground_assets/movil/linea/vector2171-qtcb.svg"
              alt="Vector2171"
              class="overlay-dasboarddelusuario-vector2"
            />
          </div>
          <div class="overlay-dasboarddelusuario-group2889022">
            <span class="overlay-dasboarddelusuario-text27">
              <span>{{ detallePlan[2].ben_titulo }}</span>
            </span>
            <span class="overlay-dasboarddelusuario-text29">
              <span>
                <span>{{ detallePlan[2].ben_texto }}</span>
                <!-- <br />
                <span>text of the printing and</span> -->
              </span>
            </span>
          </div>
        </div>
        <div class="overlay-dasboarddelusuario-group289072">
          <div class="overlay-dasboarddelusuario-group4603">
            <img
              src="/playground_assets/movil/linea/ellipse2802171-lhl8-200h.png"
              alt="Ellipse2802171"
              class="overlay-dasboarddelusuario-ellipse2803"
            />
            <img
              src="/playground_assets/movil/linea/vector2171-s0nl.svg"
              alt="Vector2171"
              class="overlay-dasboarddelusuario-vector3"
            />
          </div>
          <div class="overlay-dasboarddelusuario-group2889023">
            <span class="overlay-dasboarddelusuario-text34">
              <span>{{ detallePlan[3].ben_titulo }}</span>
            </span>
            <span class="overlay-dasboarddelusuario-text36">
              <span>
                <span>{{ detallePlan[3].ben_texto }}</span>
                <!-- <br />
                <span>text of the printing and</span> -->
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
import detalle from "../../../../../../services/plan";
export default {
  name: 'MobilCondicionesPersonas',

  data() {
    return {
      raw51i8: ' ',
      raw4ntd: ' ',
      // detalle: {
      //   beneficios: "",
      //   cubre:"",
      //   nocubre:"",
      //   restricciones:""
      // }
      detallePlan: [
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        }
      ], 
    }
  },
  methods: {
    closeDialog(){
      this.$root.$refs.PreaprobacionLineaMo.closeDialog();
    },
    async traeDetallePlan(){
      var res=false;
      var plan= this.$store.state.plan;
      res = await detalle.consultabeneficios(plan);
      if(res.status==200){
        if(res.data.estadoTransaccion.success){
          this.detallePlan=res.data.beneficios;
        }        
      }      
    }
  },
  mounted() {
    this.traeDetallePlan();
  },
  metaInfo: {
    title: 'Mueve Seguro',
  },
}
</script>

<style scoped>
.overlay-dasboarddelusuario-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.overlay-dasboarddelusuario-overlay-dasboarddelusuario {
  width: 100%;
  height: 517px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}
.overlay-dasboarddelusuario-rectangle2418 {
  top: 187px;
  width: 312px;
  height: 330px;
  display: flex;
  position: absolute;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.20000000298023224) ;
  justify-content: center;
  background-color: white;
  border-radius: 0 0 20px 20px;
}
.overlay-dasboarddelusuario-rectanguloarriba-rellenalasiguienteencuesta {
  top: 2px;
  width: 312px;
  height: 77px;
  display: flex;
  position: absolute;
  justify-content: center;
}
.overlay-dasboarddelusuario-rectanguloarriba-rellenalasiguienteencuesta1 {
  top: 77px;
  width: 312px;
  height: 110px;
  position: absolute;
}
.overlay-dasboarddelusuario-group288878 {
  top: 20px;
  width: 106px;
  height: 37px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  margin-left: -100px;
}
.overlay-dasboarddelusuario-text {
  color: var(--dl-color-colores_neutrales-white);
  width: 106px;
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text01 {
  font-weight: 500;
}
.overlay-dasboarddelusuario-text06 {
  top: 95px;
  color: var(--dl-color-colores_neutrales-white);
  width: 234px;
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: ExtraBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text07 {
  font-weight: 800;
}
.overlay-dasboarddelusuario-text08 {
  font-weight: 400;
}
.overlay-dasboarddelusuario-icon-btns {
  top: 12px;
  width: 28px;
  height: 28px;
  position: absolute;
  margin-left: 200px;
}
.overlay-dasboarddelusuario-group289073 {
  top: 228px;
  width: 254px;
  height: 261px;
  display: flex;
  position: absolute;
  flex-shrink: 1;
  justify-content: center;
}
.overlay-dasboarddelusuario-group289069 {
  top: 0px;
  width: 254px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-group460 {
  top: 11px;
  left: 0px;
  width: 35px;
  height: 35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-ellipse280 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  position: absolute;
}
.overlay-dasboarddelusuario-vector {
  top: 7.579345703125px;
  left: 7.5802001953125px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.overlay-dasboarddelusuario-group288902 {
  top: 0px;
  left: 49px;
  width: 205px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-text13 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text15 {
  top: 22px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 104.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-group289070 {
  top: 69px;
  width: 254px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-group4601 {
  top: 11px;
  left: 0px;
  width: 35px;
  height: 35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-ellipse2801 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  position: absolute;
}
.overlay-dasboarddelusuario-vector1 {
  top: 7.579345703125px;
  left: 7.5802001953125px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.overlay-dasboarddelusuario-group2889021 {
  top: 0px;
  left: 49px;
  width: 205px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-text20 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text22 {
  top: 22px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 104.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-group289071 {
  top: 138px;
  width: 254px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-group4602 {
  top: 11px;
  left: 0px;
  width: 35px;
  height: 35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-ellipse2802 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  position: absolute;
}
.overlay-dasboarddelusuario-vector2 {
  top: 7.579345703125px;
  left: 7.5802001953125px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.overlay-dasboarddelusuario-group2889022 {
  top: 0px;
  left: 49px;
  width: 205px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-text27 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text29 {
  top: 22px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 104.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-group289072 {
  top: 207px;
  width: 254px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-group4603 {
  top: 11px;
  left: 0px;
  width: 35px;
  height: 35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-ellipse2803 {
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  position: absolute;
}
.overlay-dasboarddelusuario-vector3 {
  top: 7.579345703125px;
  left: 7.5802001953125px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.overlay-dasboarddelusuario-group2889023 {
  top: 0px;
  left: 49px;
  width: 205px;
  height: 54px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.overlay-dasboarddelusuario-text34 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.overlay-dasboarddelusuario-text36 {
  top: 22px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 104.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 479px) {
  .overlay-dasboarddelusuario-container {
    display: flex;
    justify-content: center;
  }
}
</style>
