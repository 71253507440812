<template>

    <div style="margin-top: -68px;
    margin-left: 210px;">
           

    <v-dialog
      v-model="activar"
      width="740px"
      height="316px"
      border="0px"
      content-class="permanenteEliminar"
      style="background-color: transparent"
      :class="`hola`"
      
    >
    <template v-slot:activator="{ on }">
      <v-btn
        
        
        color="rgba(255, 184, 78)"
        v-on="on"
        x-large
        
        style="background-color: transparent;
              border-color: transparent;
              text-transform: unset !important;
              box-shadow: none;
              width: 195px;
             
              "
      >
      <span class="frame2loginnormal-text17"
            ><span>Recuperar Clave</span></span
          >
        </v-btn>
    </template>
      <RecuperaPass @cierraModal="cerrarModal" :ident="ident"
        style="overflow-y: hidden !important"
      ></RecuperaPass>
    </v-dialog>
    </div>


  
</template>
<script>
import privado from "../../../services/privado";
import RecuperaPass from "./RecuperaPass.vue";

export default {
  name: "LoginRecuperacionActiva",
  components: {
    RecuperaPass: RecuperaPass,
  },
  data() {
    return {
      
      overlay: false,
      valid: true,
      fpoco: "",
      activar: "",
      activa: false,
      
     
    };
  },
  
  props: {
    ident: "",
  },

  methods: {
   cerrarModal(){
    this.activar=false;
   },
    async  traeDetalle(numero){
        
      var res = false;
        this.overlay=true;
        res = await privado.traeDetalleSimulacion(numero);
        if (res.status == 200 && res.data.estadoTransaccion.success) { 
          this.overlay=false;
        }else{
          this.$alertas(res.data.estadoTransaccion.errors,"info");
          this.overlay=false;
        }
       
    }
  },
  created() {
    this.$root.$refs.ActivaRecuperacion = this;
  },
};
</script>
<style scoped >

.frame2loginnormal-text17 {
  /* top: 571px; */
  /* left: 344px; */
  /* margin-left: 250px; */
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.permanenteEliminar{
    border-radius: 40px !important;
}
.frame52missegurosactivos-text140 {
 
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  
}
.frame75persona-cotizarsegurode-desgravamen-usosdebotones1 {
  top: 863px;
  left: 1072px;
  width: 250px;
  height: 23px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-text02 {
  position: relative;
  top: 14px;
  left: 4px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
