import { render, staticRenderFns } from "./PreguntasMo.vue?vue&type=template&id=c7431126&scoped=true&"
import script from "./PreguntasMo.vue?vue&type=script&lang=js&"
export * from "./PreguntasMo.vue?vue&type=script&lang=js&"
import style0 from "./PreguntasMo.vue?vue&type=style&index=0&id=c7431126&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7431126",
  null
  
)

export default component.exports