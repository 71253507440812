<template>
  <div>
    <!-- <div class="frame66persona-formulario-lneadesgravamen-usosdebotones">
        <span class="frame66persona-formulario-lneadesgravamen-text02">
          <span>Volver</span>
        </span>
      </div> -->
      <div
      class="frame66persona-formulario-lneadesgravamen-usosdebotones"
      style="border-color: transparent"
    >
      <v-btn
        color="rgba(255, 255, 255, 1)"
        class="frame66persona-formulario-lneadesgravamen-usosdebotones"
        rounded
        x-large
        style="left: 0px; top: 0px; text-transform: unset !important; border: thin solid rgb(44, 49, 49);"
        @click="volver"
      >
        <span class="frame66persona-formulario-lneadesgravamen-text02">
          <span>Volver</span>
        </span>
      </v-btn>
    </div>
    <!-- <div class="frame66persona-formulario-lneadesgravamen-usosdebotones1">
        <div class="frame66persona-formulario-lneadesgravamen-usosdebotones2">
          <span class="frame66persona-formulario-lneadesgravamen-text04">
            <span>Continuar</span>
          </span>
        </div>
      </div> -->
    <div
      class="frame66persona-formulario-lneadesgravamen-usosdebotones1"
      style="border-color: transparent"
    >
      <v-btn
        color="rgba(255, 184, 78)"
        class="frame66persona-formulario-lneadesgravamen-usosdebotones1"
        rounded
        x-large
        style="left: 0px; top: 0px; text-transform: unset !important"
        @click="validate"
        :disabled="!valid"
      >
        <span class="frame66persona-formulario-lneadesgravamen-text04">
          <span>Continuar</span>
        </span>
      </v-btn>
    </div>

    

    <div class="frame67persona-formulario-lneadesgravamen-group288940">
        <div class="frame67persona-formulario-lneadesgravamen-alertbanner">
          <div class="frame67persona-formulario-lneadesgravamen-group506">
            <span class="frame67persona-formulario-lneadesgravamen-text02">
              <span>Acepto términos y condiciones</span>
            </span>
            <div
              class="frame67persona-formulario-lneadesgravamen-akariconscirclealert"
            >
              <div class="frame67persona-formulario-lneadesgravamen-group7">
                <!-- <img
                  alt="Vector1647"
                  src="/playground_assets/seguros/linea/vector1647-qfvl.svg"
                  class="frame67persona-formulario-lneadesgravamen-vector20"
                /> -->
                <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
                <!-- <img
                  alt="Vector1647"
                  src="/playground_assets/vector1647-uagm.svg"
                  class="frame67persona-formulario-lneadesgravamen-vector21"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="frame67persona-formulario-lneadesgravamen-icon-switch-o-n-o-f-f"
        >
          <div class="frame67persona-formulario-lneadesgravamen-group8">
           
            
                         
            
              <v-form ref="form" v-model="valid" >
                        <v-switch color="azulsitio" v-model="lectura" style="top: -22px;" :rules="rulesLectura"
                        class="frame67persona-formulario-lneadesgravamen-oval1"  ></v-switch>
             </v-form>
            
          </div>
        </div>
      </div>
      <span
        class="frame67persona-formulario-lneadesgravamen-text04 Roboto_H4_3242"
      >
        <span>{{ titulo }}</span>
      </span>
      <span class="frame67persona-formulario-lneadesgravamen-text06">
        <span>{{ sub_titulo }}</span>
      </span>
      <div class="frame67persona-formulario-lneadesgravamen-contenedor-scroll">
        <img
          alt="LineaScroll1647"
          src="/playground_assets/seguros/linea/lineascroll1647-rops.svg"
          class="frame67persona-formulario-lneadesgravamen-linea-scroll"
        />
        <!-- <img
          alt="RectanguloScroll1647"
          src="/playground_assets/seguros/linea/rectanguloscroll1647-apf-200w.png"
          class="frame67persona-formulario-lneadesgravamen-rectangulo-scroll"
        /> -->
        <div class="frame67persona-formulario-lneadesgravamen-frame288928 contenedor"  @scroll="handleScroll" style="overflow-y: scroll !important;">
          <div class="frame67persona-formulario-lneadesgravamen-group288930">
            <span class="frame67persona-formulario-lneadesgravamen-text08">
              <span>
                  <span>
                   {{ texto }}
                  </span>
              </span>
                <!-- <span>
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the indusLorem.Lorem Ipsum is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply duorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the
                    indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy teply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy text of and typesetting industry.
                    Lorem Ipsum has been the iLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy ummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the orem.
                  </span>
                </span>
              </span>
              <span class="frame67persona-formulario-lneadesgravamen-text19">
                <span>
                  <span>
                    Lorem Ipsum is simply dummy teply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has berem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the indusLorem.Lorem Ipsum is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply duorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the
                    indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy teply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the indusLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy text of and typesetting industry.
                    Lorem Ipsum has been the iLorem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy ummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the orem.
                  </span>
                  <br />
                  <span>
                    Lorem Ipsum is simply dummy teply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has berem.
                  </span>
                  <br />
                  <span></span>
                </span>-->
            </span> 
          </div>
        </div>
        
      </div>
      <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
  </div>
</template>
<script>

import plan from "../../../../services/plan";

export default {
  name: "Terminos",
  components: {
  
  },
  data() {
    return {
      overlay: false,
      normalClass: "frame66persona-formulario-lneadesgravamen-radiobtn-si",
      captchaClass: "frame66persona-formulario-lneadesgravamen-radiobtn-si2",
      rulesLectura: [(v) => !!v || ""],
      validresp: true,
      valid: true,
      lectura: false,
      titulo: "vvvvvvvvvvvvvvvv",
      sub_titulo: "AAAAAAAAAAAAAAAA",
      texto: ["XXXXXX","LLLLLLLLLLL","OOOOOOOOOOOOOOO"],
    };
  },
  props: {
    robot: false,
    id: 0,
    bps: {
      respuesta: "",
      titulo: "",

      texto_c1f1: "",
      texto_c1f2: "",
      texto_c1f3: "",
      texto_c1f4: "",
      texto_c1f5: "",

      texto_c2f1: "",
      texto_c2f2: "",
      texto_c2f3: "",
      texto_c2f4: "",
      texto_c2f5: "",

      texto_c3f1: "",
      texto_c3f2: "",
      texto_c3f3: "",
      texto_c3f4: "",
      texto_c3f5: "",
    },
  },
  methods: {
    async aprobar(){
      this.overlay =true;
      let res = false;      
      var simulacion=this.$store.state.simulacion;
      var planSimulacion=simulacion.sim_ldp_numero;
      var estadoDps=this.$store.state.estadodps;
      var dps=[];
      if(estadoDps){
        dps=this.$store.state.dps;
        var dpsrespuesta=[];
        var resp=0;
        dps.forEach(element => {
          if(element.respuesta=="SI"){
            resp=1;
          }else{
            resp=2;
          }
          dpsrespuesta.push({
            dre_plan_dps:planSimulacion,
            dre_pregunta:element.prg_numero,
            dre_respuesta:resp})
        });
        dps=dpsrespuesta;
      }

       var preparaSimulacion={
        ctt_simulacion: simulacion.sim_numero,
        dpsRespuestas: dps
        }

      res = await plan.aprobar(preparaSimulacion);
      console.log(res);
        if(res.status==200){
          this.$alertas(res.data.estadoTransaccion.errors,"success");
          if(res.data.estadoTransaccion.success){
            this.$store.commit('setcontrato', res.data.contrato);
          }
          this.$root.$refs.FormularioLinea.estadoFormLinea = 7;
        }else{
          this.$alertas("En este momento no podemos atender su solicitud","warning");
        }       
      
      this.overlay =false;
    },
    cargaTerminos(){
      this.titulo= this.$store.state.terminos.tco_titulo;
      this.texto= this.$store.state.terminos.tco_texto;
      this.sub_titulo= this.$store.state.terminos.tco_sub_titulo;
    },
    handleScroll: function(e) {
      this.lectura=false;
    if (e.srcElement.offsetHeight + e.srcElement.scrollTop >= e.srcElement.scrollHeight) {
    this.lectura=true;
  }
  
},

    submit: function () {
      if (this.form.robot) {
      }
    },
    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.aprobar();
      }
    },
    vresp(v) {
      this.validresp = v;
    },
    volver() {
      if(this.$store.state.estadodps){
        this.$root.$refs.FormularioLinea.estadoFormLinea = 5;
      }else{
        this.$root.$refs.FormularioLinea.estadoFormLinea = 3;
      }
        
    },
  },
  mounted() {
    var styleElement = document.createElement("style");
styleElement.appendChild(document.createTextNode("div ::-webkit-scrollbar {-webkit-appearance: none;width: 20px;}div ::-webkit-scrollbar-thumb {border-radius: 10px;background-color: rgba(113, 48, 237, 1);-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);}"));
document.getElementsByTagName("head")[0].appendChild(styleElement);
  },
  created() {
    this.$root.$refs.Terminos = this;
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>
<style scoped>

.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.v-messages__message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  top: 14px;
  position: relative;
  font-size: large;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones {
  top: 863px;
  /* left: 148px; */
  width: 220px;
  height: 48px !important;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 4px;
  border-radius: 100px;
  margin-left: -130px;
}
.frame66persona-formulario-lneadesgravamen-text02 {
  top: 14px;
  left: 14px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame664personaformulariolneadesgravamenalertbanner {
  top: 695px;
  left: 484px;
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 163, 163, 1);
}
.frame664personaformulariolneadesgravamenalertbanner2 {
  top: 600px;
  left: 484px;
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 163, 163, 1);
}
.frame664persona-formulario-lneadesgravamen-group506 {
  top: 15px;
  left: 77px;
  width: 665.4117431640625px;
  height: 27px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame664persona-formulario-lneadesgravamen-text06 {
  left: 35.37058639526367px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 630px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.frame664persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  left: 0px;
  width: 26.528043746948242px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.frame664persona-formulario-lneadesgravamen-group7 {
  top: 2px;
  left: 2.210670232772827px;
  width: 22.10670280456543px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame664persona-formulario-lneadesgravamen-text35 {
  top: 445px;
  left: 495px;
  color: var(--dl-color-colores_neutrales-90);
  width: 796px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame664persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  left: 0px;
  width: 22px;
  height: 20px;
  position: absolute;
}
.frame664persona-formulario-lneadesgravamen-vector21 {
  top: 5px;
  left: 11.053351402282715px;
  width: 0px;
  height: 10px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones1 {
  top: 863px;
  /* left: 1072px; */
  width: 255px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-left: 21em;
}
.frame66persona-formulario-lneadesgravamen-usosdebotones2 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}
.frame66persona-formulario-lneadesgravamen-text04 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  position: relative;
  top: 13px;
}
.frame66persona-formulario-lneadesgravamen-group288991 {
  top: 368px;
  left: 148px;
  width: 268px;
  height: 388px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta {
  top: 0px;
  left: 0px;
  width: 268px;
  height: 141px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta1 {
  left: 0px;
  width: 268px;
  bottom: 2px;
  height: 247px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-group288878 {
  top: 52.210693359375px;
  left: 47px;
  width: 173.2490234375px;
  height: 65px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-text06 {
  top: 28.17718505859375px;
  color: var(--dl-color-gray-white);
  width: 173px;
  height: auto;
  position: absolute;
  font-size: 34px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text08 {
  top: -4px;
  left: 51px;
  color: var(--dl-color-gray-white);
  width: 73px;
  height: auto;
  position: absolute;
  font-size: 28px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  margin-bottom: 5px;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text10 {
  top: 174px;
  left: 9px;
  color: var(--dl-color-gray-white);
  width: 250px;
  height: auto;
  position: absolute;
  font-size: medium;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text11 {
  font-weight: 700;
}
.frame66persona-formulario-lneadesgravamen-text15 {
  font-weight: 400;
}
.frame66persona-formulario-lneadesgravamen-group288914 {
  top: 241px;
  left: 474px;
  width: 493px;
  height: 40px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-rectangle382 {
  top: 0px;
  left: 215px;
  width: 278px;
  height: 40px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-rectangle383 {
  top: 0px;
  left: 0px;
  width: 259px;
  height: 40px;
  position: absolute;
}
.frame66persona-formulario-lneadesgravamen-text20 {
  top: 13px;
  left: 93px;
  color: var(--dl-color-gray-white);
  width: 69px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame66persona-formulario-lneadesgravamen-text22 {
  top: 13px;
  left: 347.095703125px;
  color: var(--dl-color-colores_neutrales-50);
  width: 71px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame66persona-formulario-lneadesgravamen-radiobtnaceptar {
  top: 13px;
  left: 319px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse288 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-radiobtnaceptar1 {
  top: 13px;
  left: 64px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse289 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text24 {
  top: 368px;
  left: 474px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-rectangle31 {
  top: 444.84814453125px;
  left: 1063.728515625px;
  width: 50px;
  height: 3px;
  position: absolute;
  border-color: transparent;
}
.frame66personaformulariolneadesgravamenradiobtnsi {
  top: 600px;
  left: 772px;
  width: 150px;
  height: 10px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66personaformulariolneadesgravamenradiobtnsi2 {
  top: 525px;
  left: 772px;
  width: 150px;
  height: 10px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse2881 {
  top: 22px;
  left: 0px;
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text26 {
  left: 1px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-radiobtn-no {
  top: 706px;
  left: 872px;
  width: 26px;
  height: 39px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame66persona-formulario-lneadesgravamen-ellipse2882 {
  top: 22px;
  left: 4px;
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame66persona-formulario-lneadesgravamen-text28 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text30 {
  top: 405px;
  left: 474px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text32 {
  top: 445px;
  left: 500px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text45 {
  top: 445px;
  left: 765px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame66persona-formulario-lneadesgravamen-text58 {
  top: 445px;
  left: 1029px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-group288940 {
  top: 708px;
  /* left: 316px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -112px;
 
}
.frame67persona-formulario-lneadesgravamen-alertbanner {
  top: 0px;
  left: 0px;
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 100px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(246, 242, 255, 1);
}
.frame67persona-formulario-lneadesgravamen-group506 {
  top: 15px;
  left: 77px;
  width: 665.4117431640625px;
  height: 27px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-text02 {
  left: 35.37058639526367px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 630px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  left: 0px;
  width: 26.528043746948242px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.frame67persona-formulario-lneadesgravamen-group7 {
  top: 2px;
  left: 2.210670232772827px;
  width: 22.10670280456543px;
  height: 20px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  left: 0px;
  width: 22px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector21 {
  top: 2px;
  left: 12px;
  width: 0px;
  height: 10px;
  background-color: var(--dl-color-gray-black);
}
.frame67persona-formulario-lneadesgravamen-icon-switch-o-n-o-f-f {
  top: 19px;
  left: 742px;
  width: 34px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group8 {
  top: 0px;
  left: 0px;
  width: 34px;
  height: 20px;
  display: flex;
  opacity: 0.38;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-rectangle {
  top: 3px;
  left: 0px;
  width: 34px;
  height: 14px;
  position: absolute;
  border-color: rgba(150, 150, 150, 1);
  border-radius: 7px;
}
.frame67persona-formulario-lneadesgravamen-oval {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14000000059604645) ;
}
.frame67persona-formulario-lneadesgravamen-oval1 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-text04 {
  top: 222px;
  /* left: 372px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  font-weight: 700;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
}
.frame67persona-formulario-lneadesgravamen-text06 {
  top: 268px;
  /* left: 550px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-contenedor-scroll {
  top: 310px;
  /* left: 165px; */
  width: 1110px;
  height: 396px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
  margin-left: -250px;
}
.frame67persona-formulario-lneadesgravamen-linea-scroll {
  top: 162px;
  left: 1047px;
  width: 202px;
  height: 0px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-rectangulo-scroll {
  top: 14px;
  left: 1040px;
  width: 16px;
  height: 150px;
  position: absolute;
  border-color: transparent;
  border-radius: 100px;
}
.frame67persona-formulario-lneadesgravamen-frame288928 {
  top: 14px;
  left: 36px;
  width: 1017px;
  height: 326px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-color: #8080802e;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
.frame67persona-formulario-lneadesgravamen-group288930 {
  top: 0px;
  left: 0px;
  width: 947px;
  height: 701px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  padding: 40px;
}
.frame67persona-formulario-lneadesgravamen-text08 {
  color: var(--dl-color-colores_neutrales-90);
  width: 947px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-text19 {
  top: 306px;
  color: var(--dl-color-colores_neutrales-90);
  width: 947px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones {
  top: 863px;
  left: 148px;
  width: 220px;
  height: 51px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
}
.frame67persona-formulario-lneadesgravamen-text36 {
  top: 14px;
  left: 14px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones1 {
  top: 863px;
  left: 1072px;
  width: 220px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones2 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}
.frame67persona-formulario-lneadesgravamen-text38 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
