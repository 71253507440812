<template>
    <div>
      <v-dialog v-model="dialogRecupera" class="modalSize" max-width="100%" display="flex" justify-center="center">
        <template>
          <div class="modalDialog">
          <div class="dialogStyle colcenter">
          <v-row class="colcenter marcoDialog">
            <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="colcenter">
              <div class="ingrese-su-rut">Ingrese su Rut</div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="colcenter">
              <div class="">
                  <v-form ref="form" v-model="valid">

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <div>

                          <v-text-field v-model="rut" :rules="rules" label="Rut" required @update:error="vrut"
                            color="rgba(0, 23, 135, 1)" outlined rounded>
                            <template v-slot:append>
                              <div v-if="validrut == `no`"></div>
                              <v-icon v-else-if="!validrut" color="m100" style="margin-bottom: 10px !important ;">
                                mdi-checkbox-marked-circle
                              </v-icon>
                              <v-icon v-else-if="validrut" color="red" style="margin-bottom: 10px !important ;">
                                mdi-close-circle
                              </v-icon>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <div>
                          <span>
                            <span class="env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a">
                              Envíe su rut y enseguida le enviaremos un correo con las intrucciones para recuperar su
                              contraseña</span>
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" class="colcenter">
                      <div class="line-1"></div>
                    </v-col>
                    <v-col cols="12" sm="12" class="colcenter"></v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                      <v-btn rounded x-large @click="cierra" width="180" color="white"><span><span
                        style="color:#1d2eff;width: 180px;" class="submit">SALIR</span></span></v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                      <v-btn rounded x-large @click="validate" width="180" :disabled="!valid" color="#1d2eff"><span><span
                        style="color:white;width: 180px;" class="submit">ENVIAR</span></span></v-btn>
                    </v-col>
                    </v-row>

                  </v-form>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
        </template>
      </v-dialog>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
     </div>
</template>
<script>
import privado from "../../../../services/privado";
import logContratar from "../../../../services/logContratar";
export default {
  name: "LoginRecuperacion",
  data() {
    return {
      dialogRecupera: false,
      rut: "",
      validrut: "no",
      overlay: false,
      numeroIngreso: "",
      valid: false,
      SeguroDetalle : {},
      numeroRules: [
                (v) =>  !!v  || "número requerido",
                (v) => (v >= 999) || "número inválido",
                (v) => (v <= 999999) || "número inválido",
            ],
      validnumero : false,
      bitacora: {
        bitacoraPlan: {
          spl_numero: 0,
          spl_seguro_numero: 0,
          spl_pln_numero: 0,
          cse_numero: 0,
          cse_ctt_numero: 0,
        },
        bitacoraPago: {
          estado_pago: 0,
          cce_transaccion: 0,
        },
      },
    }
  },
  props: {
    Seguro : {},
    cierraModal : "",
    ident: 0,
    
  },
  methods: {
    cierra(){
        this.dialogRecupera = false;
    },
    validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
            if(rutCompleto=="44444444-4"){
                return true;
            }
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        
      formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.rut=rutPuntos;
        },
        largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.rut=rut.replace(/[^kK0-9.-]/gi,"");
      
      return true;
    },
    vnumero(v) {        
            this.validnumero = v;
        },
    vrut(v) {
        this.validrut = v;
        },
    validate() {
            if (this.$refs.form.validate()) {
                this.Recuperar();               
            }
    },
  async  Recuperar(){   
          let res="";      
          this.overlay=true;
          this.dialogRecupera=false;
          var rut=this.rut.split(".").join("");
          var cuenta={
                    acc_rut: rut,
                    };
          console.log(cuenta);
          res = await privado.recuperarPass(cuenta);          
          if (res.status == 200 && res.data.estadoTransaccion.success) {
              
                          this.$alertas(res.data.estadoTransaccion.errors,"success");
                          this.overlay = false;
                          this.$emit('cierraModal');

                      }else{
                          this.$alertas(res.data.estadoTransaccion.errors,"error");
                          this.overlay = false;
                          this.$emit('cierraModal');
                      }
                   
          
    },
    continuaOrigen(){
      this.$emit('cierraModal');
    }

  },
  
  computed : {
    rules() {
            const rules = [];
            let rule="";
            rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.rut) || `rut inválido`;
            if(this.rut!=""){
            this.formateaRut(this.rut);
            }
            rules.push(rule);
            
            return rules;
        },
   
  },
  created() {
        this.$root.$refs.RecuperaPassword = this;
    },
  metaInfo: {
    title: 'Mueve Seguro',
  },

};
</script>
<style scoped>
.colcenter {
  display: flex;
  justify-content: center;
}
.modalDialog{
  display: flex;
  justify-content: center;
}
.modalDialog{
  display: flex;
  justify-content: center;
}
.dialogStyle{
  /* background: var(--blanco, #f0f2f2); */
  background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 80px 20px 80px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 50%;
}
.marcoDialog{
  background: var(--blanco, #f0f2f2);
  
}
.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ingrese-su-rut {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .dialogStyle{
  width: 100% !important;
}
}
</style>