
import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

export default {
    GuardaIdentidad(documento) {
        return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoGrabar", "post", documento);
    },
    GuardaFirmado(documento) {
        return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoGrabarFirmado", "post", documento);
    },

    async callApi(ruta, method = false, data = false) {
        try {
            if (method == "post") {
                let formData = new FormData();

                formData.append('File', data.file);
                formData.append('seg_numero', data.seg_numero);
                formData.append('cdo_tipo_docto', data.cdo_tipo_docto);

                let config = { headers: { 'Content-Type': 'multipart/form-data' } };
                return axios.post(ruta, formData, config).then(response => {
                    return response;
                }).catch(error => {
                    return false;
                })
            };

        } catch (error) {
            return false;
        }

    },

};
