<template>
    <div>
        <v-dialog v-model="dialogmovimiento" persistent class="modalSize" max-width="70%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                <div class="mi-simulaci-n">
                                    <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">
                                    DETALLE MOVIMIENTO
                                </div>

                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">

                                <v-row dense class="colcenter">
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                        <div class="descripcion">
                                            Fecha:
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                        <div class="descripcion2">
                                            {{ Movimiento.fecha }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                        <div class="descripcion">
                                            N° Operación:
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                        <div class="descripcion2">
                                            {{ Movimiento.operacion }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                        <div class="descripcion">
                                            Origen:
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                        <div class="descripcion2">
                                            {{ Movimiento.origen }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                                        <div class="descripcion">
                                            Monto:
                                        </div>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                        <div class="descripcion2">
                                            {{ Movimiento.monto }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <div class="line-1"></div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                            <v-row class="colcenter">
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <v-btn class="sm-size"  rounded x-large  color="#1d2eff"
                                                        @click="continuaOrigen"><span><span style="color:white;">REGRESAR</span></span></v-btn>
                                                </v-col>
                                            </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import privado from "../../../../../services/privado";
export default {
    name: "MovimientoSeguro",
    data() {
        return {
            overlay: false,

        }
    },
    props: {
        numeroSeguro: false,
        dialogmovimiento: false,
        Movimiento: {},
        cierraModal: "",
        ident: "",
    },
    methods: {

        continuaOrigen() {
            this.$root.$refs.WalletMo.dialogmovimiento = false;
        }

    },

    created() {
        this.$root.$refs.ModalMovimientoSeguro = this;
    },
};
</script>
<style scoped>
.sm-size {
    width: 40%;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 40px 20px 40px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 70%;
}

.marcoDialog {
    background: var(--blanco, #f0f2f2);

}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 33px;
    height: 33px;
    position: relative;
    overflow: visible;
}

.el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 4vw, 1.7rem);
    line-height: clamp(1rem, 4vw, 1.7rem);
    position: relative;
    width: 100%;
}

.descripcion {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 600);
    font-size: clamp(0.8rem, 4vw, 1.7rem);
    line-height: clamp(1rem, 4vw, 1.7rem);
    position: relative;
}
.descripcion2 {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 600);
    font-size: clamp(0.8rem, 4vw, 1.7rem);
    line-height: clamp(1rem, 4vw, 1.7rem);
    position: relative;
    width: 100%;
}

.valores {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 36px;
    line-height: 36px; */
    font-size: clamp(0.8rem, 4vw, 1.7rem);
    line-height: clamp(1rem, 4vw, 1.6rem);
    font-weight: 900;
    position: relative;

}

.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem, 4vw, 2.2rem);
    line-height: clamp(1.3rem, 4vw, 2.6rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .dialogStyle {
        width: 100% !important;
        padding: 20px 10px 20px 10px !important;
    }

    .sm-size {
        width: 100%;
    }
    .vector {
        width: 20px !important;
        height: 20px !important;
    }
}
</style>