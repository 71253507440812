<template>
  <div style=" justify-content: center;
    display: flex;
    position: relative;
    top: 444px;">
    <v-form ref="form" v-model="valid" >
      <v-row>
        <v-col cols="12" class="classcenter">
          <span class="frame6-persona-formulario-lneadesgravamen-text10">
            <span>Declaración Personal de salud</span>
          </span>
        </v-col>
        <v-col cols="12" class="classcenter">
          <span class="frame6-persona-formulario-lneadesgravamen-text12">
          <span>
            <span>{{ bps.prg_titulo }}</span>          
          </span>
        </span>
        </v-col>
     
        <v-col cols="12" class="classcenter">
          <span class="frame6-persona-formulario-lneadesgravamen-text17">
            <span>
              <span>                   
              </span>
            </span>
          </span>
        </v-col>
        <v-col cols="12" class="classcenter">
          <div class="frame6-persona-formulario-lneadesgravamen-group289074">
        
       
          <v-radio-group
            v-model="bps.respuesta"
            :rules="respRules"
            required
            @update:error="vresp"
          >
            <v-layout row wrap>
              <v-flex column>
                <p style="position: relative; left: 4px; margin-bottom: 0px">SI</p>
                <v-radio key="1" value="si" @click="acepta" color="juanito"> </v-radio>
              </v-flex>
              <v-flex column>
                <span style="color: transparent">___________</span>
              </v-flex>
              <v-flex column>
                <p style="position: relative; margin-bottom: 0px">NO</p>
                <v-radio key="2" value="no" @click="rechaza"  color="juanito"> </v-radio>
              </v-flex>
              <v-flex column>
              </v-flex>
            </v-layout>
          </v-radio-group>
       
      </div>
        </v-col>
        <v-col  cols="12" class="classcenter">
          <div class="frame6-persona-formulario-lneadesgravamen-group289077">
        <div class="frame6-persona-formulario-lneadesgravamen-group289076">
          <span class="frame6-persona-formulario-lneadesgravamen-text36">
            <span>
              Acepto que toda la información que estoy otrogando es real y
              fidedigna.
            </span>
          </span>
          <div
            class="frame6-persona-formulario-lneadesgravamen-akariconscirclealert"
          >
           
            <v-icon  
                   style="margin-bottom: 10px !important ;top: -2px;">
                   mdi-alert-circle-outline
                  </v-icon>
          </div>
        </div>
        <div class="frame6-persona-formulario-lneadesgravamen-icon-switch-onoff">
         
          <v-switch color="azulsitio"  class="frame6-persona-formulario-lneadesgravamen-oval"
                         ></v-switch>
        </div>
      </div>
        </v-col>
        <v-col  cols="12" class="classcenter">
          <vue-recaptcha ref="recaptcha"  @verify="onVerify"  class="sizecaptcha"
          sitekey="6Lf95eAjAAAAAEfbjyFShlUNjq5DqdowUYBirAih" 
          style="     top: 20px;
          position: relative;
          transform: scale(0.77);
          transform-origin: 154px 26px;
          "
          
          >
          </vue-recaptcha>
        </v-col>
        <v-col  cols="12" class="classcenter">
          <v-btn color="juanito" class="rounded-pill " x-large
                    style="text-transform: unset !important;width:250px;top:20px" @click="validate"
                    :disabled="!valid">
                    <span class="frame66persona-formulario-lneadesgravamen-text04">
                    <span>Continuar</span>
                  </span>
          </v-btn>
        </v-col>
        <v-col  cols="12" class="classcenter">
          <span class="frame-persona-formulario-lneadesgravamen-text12" @click="volver">
          <span>Volver</span>
        </span>
        </v-col>
        
      </v-row>
      
    </v-form>
     
  </div>
 
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Frame66PersonaFormularioLneadesgravamenMo",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      info: false,
      normalClass : "frame66persona-formulario-lneadesgravamen-radiobtn-si",
      captchaClass : "frame66persona-formulario-lneadesgravamen-radiobtn-si2",
      respRules: [(v) => !!v || ""],
      validresp: true,
      valid: true,
      bdisabled: true,
      contDps: 0,
    };
  },
  props: {
    robot: false,
    id: 0,
    bps: {
      prg_id: "",
      pdp_numero: "",
      prg_numero: "",
      prg_titulo: "",
      prg_tipo_respuesta: "",
      prg_ultima_pregunta: "",
      prg_con_opcion: "",
      pdp_ldp_numero: ""
    },
  },
  methods: {
   acepta(){
    this.info=true;
    this.contDps=this.$store.state.contdps;
   },
   rechaza(){
    this.info=false;
    this.contDps=this.$store.state.contdps;
   },
    submit: function () {
      if (this.form.robot) {
      }
    },
    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        console.log("id:"+ this.id );
        console.log("dps:"+ this.contDps );
        var Cdps=this.$store.state.contdps;
        if(this.id>=Cdps){
          this.$store.commit("setestadoFormLinea",6);
          this.$root.$refs.FormularioLinea.estadoFormLinea = 6;     
        }else{
          this.$store.commit("setestadoBps", this.id + 1);
          this.$root.$refs.FormularioLinea.estadoBps = this.id + 1;
        }
        
        console.log(this.bps);
      }
    },
    vresp(v) {
      this.bdisabled=false;
      this.validresp = v;
    },
    volver() {
      if (this.id == 1) {
        this.$root.$refs.FormularioLinea.estadoFormLinea = 3;
      } else {
        this.$store.commit("setestadoBps", this.id - 1);
        this.$root.$refs.FormularioLinea.estadoBps = this.id - 1;
      }
    },
  },
  mounted() {
    

  },
  created() {
    this.$root.$refs.BpsLineaMo = this;
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>
<style >
iframe {
    width: 312px !important;
    height: 110px !important;
}
.classcenter{
  display: flex;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-frame6-persona-formulario-lneadesgravamen {
  width: 100%;
  height: 1250px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame6-persona-formulario-lneadesgravamen-back-persona-formulario-lneadesgravamen {
  top: 0px;
  width: 100%;
  height: 620px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame6-persona-formulario-lneadesgravamen-background {
  top: 0px;
  width: 100%;
  height: auto;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-text {
  top: 44px;
  color: var(--dl-color-colores_neutrales-white);
  right: 11.1%;
  height: auto;
  position: absolute;
  font-size: 13px;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 47.49999940395355%;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-logotipos {
  top: 27px;
  left: 26px;
  width: 90px;
  height: 94.04495239257812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-txt {
  top: 65.4912109375px;
  left: 0px;
  width: 90px;
  height: 28.554122924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-group {
  top: 0px;
  left: 26.558950424194336px;
  width: 33.036964416503906px;
  height: 11.870681762695312px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector {
  top: 1.9248809814453125px;
  left: 0px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector01 {
  top: 0px;
  left: 6.801977157592773px;
  width: 4px;
  height: 12px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector02 {
  top: 3.8502960205078125px;
  left: 10.688322067260742px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector03 {
  top: 3.8502960205078125px;
  left: 18.4619197845459px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector04 {
  top: 3.8502960205078125px;
  left: 26.235246658325195px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group1 {
  top: 15.400634765625px;
  left: 0px;
  width: 86.1550521850586px;
  height: 13.153488159179688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector06 {
  top: 2.2457733154296875px;
  left: 8.74444580078125px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector07 {
  top: 2.2457733154296875px;
  left: 16.51831817626953px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector08 {
  top: 2.2457733154296875px;
  left: 23.319704055786133px;
  width: 7px;
  height: 11px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector09 {
  top: 2.2457733154296875px;
  left: 31.41728401184082px;
  width: 5px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector10 {
  top: 2.2457733154296875px;
  left: 36.59986114501953px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector11 {
  top: 2.2457733154296875px;
  left: 44.04920959472656px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector12 {
  top: 2.2457733154296875px;
  left: 51.49855422973633px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector13 {
  top: 2.2457733154296875px;
  left: 59.596134185791016px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector14 {
  top: 2.2457733154296875px;
  left: 71.25611877441406px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector15 {
  top: 2.2457733154296875px;
  left: 79.0294418334961px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group2 {
  top: 14.723709106445312px;
  left: 86.56906127929688px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-group3 {
  top: 0.8503341674804688px;
  left: 1.1170196533203125px;
  width: 1.4295578002929688px;
  height: 1.6991806030273438px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 2px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group4 {
  top: 0px;
  left: 0px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-img {
  top: 0px;
  left: 15.21243953704834px;
  width: 59.16004943847656px;
  height: 58.59792709350586px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector18 {
  top: 1.0317881107330322px;
  left: 0px;
  width: 21px;
  height: 46px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector19 {
  top: 0px;
  left: 18.748096466064453px;
  width: 40px;
  height: 59px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-ellipse5 {
  top: 9.90625px;
  left: 9.905517578125px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-rectangle39 {
  top: 149px;
  width: 80%;
  height: 1000px;
  display: flex;
  position: absolute;
  border-radius: 20px;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-unsplash-whw-bmtn30 {
  top: 417px;
  width: 310px;
  height: 310px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-usosdebotones {
  top: 1050px;
  width: 220px;
  display: flex;
  position: absolute;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.15000000596046448) ;
  align-items: flex-start;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}
.frame6-persona-formulario-lneadesgravamen-text02 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-text04 {
  top: 1187px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 58px;
  height: auto;
  position: absolute;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.frame6-persona-formulario-lneadesgravamen-group289056 {
  top: 223px;
  width: 144px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-rectangle384 {
  top: 0px;
  left: 0px;
  width: 144px;
  height: 40px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group289054 {
  top: 13px;
  left: 22px;
  width: 99.095703125px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-text06 {
  left: 28.095703125px;
  color: var(--dl-color-colores_paleta-tonos_morado-80);
  width: 71px;
  height: auto;
  position: absolute;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame6-persona-formulario-lneadesgravamen-radiobtnaceptar {
  top: 0px;
  left: 0px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse288 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-group289057 {
  top: 174px;
  width: 144px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-rectangle383 {
  top: 0px;
  width: 144px;
  height: 40px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-group289055 {
  top: 13px;
  left: 23px;
  width: 98px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-text08 {
  left: 29px;
  color: var(--dl-color-colores_neutrales-white);
  width: 69px;
  height: auto;
  position: absolute;
  text-align: left;
  line-height: 94.9999988079071%;
}
.frame6-persona-formulario-lneadesgravamen-radiobtnaceptar1 {
  top: 0px;
  left: 0px;
  width: 14.577934265136719px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse289 {
  top: 0px;
  left: 0px;
  width: 15px;
  height: 14px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-text10 {
  /* top: 463px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: relative;
  font-size: 19px;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-text12 {
  /* top: 491px; */
  color: var(--dl-color-colores_neutrales-90);
  width: 100%;
  height: auto;
  position: relative;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-text17 {
  /* top: 559px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: relative;
  font-size: 18px;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-group289074 {
  /* top: 968px; */
  width: 126px;
  height: 39px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-radiobtn-si {
  top: 0px;
  left: 0px;
  width: 17px;
  height: 39px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse2881 {
  top: 22px;
  left: 0px;
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-text48 {
  left: 1px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-radiobtn-no {
  top: 0px;
  left: 100px;
  width: 26px;
  height: 39px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-ellipse290 {
  top: 22px;
  left: 4px;
  width: 17px;
  height: 17px;
  position: absolute;
  border-color: rgba(255, 184, 78, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame6-persona-formulario-lneadesgravamen-text50 {
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-rectangle2438 {
  top: 287px;
  width: 71.9%;
  height: 130px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-text52 {
  top: 302px;
  color: var(--dl-color-colores_neutrales-white);
  width: 250px;
  height: auto;
  position: absolute;
  font-size: 16;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-text53 {
  font-weight: 600;
}
.frame6-persona-formulario-lneadesgravamen-text57 {
  font-weight: 700;
}
.frame6-persona-formulario-lneadesgravamen-text58 {
  font-weight: 400;
}
@media(max-width: 479px) {
  .frame6-persona-formulario-lneadesgravamen-background {
    width: 479px;
  }
  .frame6-persona-formulario-lneadesgravamen-usosdebotones {
    width: 276px;
  }
}
.frame-persona-formulario-lneadesgravamen-text12 {
  top: 30px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 58px;
  height: auto;
  position: relative;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
.frame67persona-formulario-lneadesgravamen-group288940 {
  top: 0px;
  /* left: 465px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-alertbanner {
  top: 0px;
  /* left: 0px; */
  width: 808px;
  height: 57px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 100px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(246, 242, 255, 1);
}
.frame67persona-formulario-lneadesgravamen-group506 {
  top: 15px;
  left: 77px;
  width: 665.4117431640625px;
  height: 27px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-text02 {
  /* left: 35.37058639526367px; */
  color: var(--dl-color-colores_neutrales-dark);
  width: 630px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 0px;
  /* left: 0px; */
  width: 26.528043746948242px;
  height: 24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}

.frame6-persona-formulario-lneadesgravamen-group289077 {
  top: 20px;
  width: 89.8%;
  height: 145px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-group289076 {
  top: 0px;
  width: 100%;
  height: 145px;
  display: flex;
  position: absolute;
  flex-shrink: 1;
  justify-content: center;
  background-color: rgba(209, 187, 253, 1);
}
.frame6-persona-formulario-lneadesgravamen-text36 {
  top: 47px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 218px;
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  text-decoration: none;
}
.frame6-persona-formulario-lneadesgravamen-akariconscirclealert {
  top: 11px;
  width: 24px;
  height: 22px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-group5 {
  top: 1px;
  left: 1.634765625px;
  width: 20.795804977416992px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-vector20 {
  top: 0px;
  width: 21px;
  height: 20px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-vector21 {
  top: 5px;
  left: 10.3974609375px;
  width: 0px;
  height: 10px;
  position: absolute;
}
.frame6-persona-formulario-lneadesgravamen-icon-switch-onoff {
  top: 88px;
  width: 34px;
  height: 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame6-persona-formulario-lneadesgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 34px;
  height: 20px;
  display: flex;
  opacity: 0.38;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame6-persona-formulario-lneadesgravamen-rectangle {
  top: 3px;
  width: 34px;
  height: 14px;
  position: absolute;
  border-radius: 7px;
  justify-content: center;
}
.frame6-persona-formulario-lneadesgravamen-oval {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14000000059604645) ;
}
.frame6-persona-formulario-lneadesgravamen-oval1 {
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
}
</style>
