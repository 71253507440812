<template>
  <div class="frame75persona-cotizarsegurode-desgravamen-usosdebotones1">
    <!-- <div class="frame75persona-cotizarsegurode-desgravamen-usosdebotones2">
          <span class="frame75persona-cotizarsegurode-desgravamen-text02">
            <span>Continuar</span>
          </span>
        </div> -->
    
      <v-dialog
        v-model="fpoco"
        width="740px"
        hide-overlay
        style="background-color: transparent; border-radius: 15px !important"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="rgba(255, 184, 78)"
            v-on="on"
            class="frame75persona-cotizarsegurode-desgravamen-usosdebotones1"
            rounded
            x-large
            style="left: 0px; top: 0px; text-transform: unset !important"
          >
            <span class="frame75persona-cotizarsegurode-desgravamen-text02">
              <span>Continuar</span>
            </span>
          </v-btn>
        </template>
        <ContratarLoginVigente
          :tipo="tipo"
          style="overflow-y: hidden !important"
          v-bind:origen="`0`"
        ></ContratarLoginVigente>
      </v-dialog>
    
  </div>
</template>
<script>
import ContratarLoginVigente from "./ContratarLoginVigente";
export default {
  name: "BotonContratarLogin",
  components: {
    ContratarLoginVigente: ContratarLoginVigente,
  },
  data() {
    return {
      valid: true,
      fpoco: "",
      tipo: ""
    };
  },
  methods: {
    habilitar(value){
        this.valid=value;
    },
  },
  created() {
    this.$root.$refs.ContratarLoginVigente = this;
    this.$parent.$on('habilitar',this.habilitar);
  },
};
</script>
<style scoped>
.frame75persona-cotizarsegurode-desgravamen-usosdebotones1 {
  /* top: 863px; */
  left: 890px;
  width: 250px;
  height: 23px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-text02 {
  position: relative;
  top: 14px;
  left: 4px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
