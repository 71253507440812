import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

console.log("api: " + ENDPOINT_PATH);
export default {

  traepaises() {
    return this.callApi(ENDPOINT_PATH + "Parametros/PaisesListar", "post");
  },
  
  traeocupaciones() {
    return this.callApi(ENDPOINT_PATH + "Parametros/OcupacionesListar", "post");
  },

  traegenero() {
    return this.callApi(ENDPOINT_PATH + "Parametros/GeneroListar", "post");
  },

  traerestricciones() {
    return this.callApi(ENDPOINT_PATH + "Parametros/RestriccionesLeer", "post");
  },


  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios
          .post(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            let erdis = "";
            if (error.response.data.errors) {
              const er = error.response.data.errors;
              if (er.usuario) {
                erdis + " error usuario : ";
                er.usuario.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.nombre) {
                erdis + " error nombre : ";
                er.nombre.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.email) {
                erdis + " error email : ";
                er.email.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.password) {
                erdis = erdis + " error password : ";
                er.password.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              return { data: { success: false, message: erdis }, status: 200 };
            }
            return {
              data: { success: false, message: "Error no capturado" },
              status: 200,
            };
          });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios
          .put(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    } catch (error) {}
  },
};
