import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

console.log("api: " + ENDPOINT_PATH);
export default {

  traeinstituciones() {
    return this.callApi(ENDPOINT_PATH + "Institucion/Listar", "post");
  },  

  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios
          .post(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {

          });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios
          .put(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    } catch (error) {}
  },
};
