<template>
  <div>


    <v-row v-if="tipo == `vigente`">

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="textopropuesta3">
          Al portar tu seguro de desgravamen con nosotros, adquieres un nuevo seguro y recuperas
        </div>

      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="hola-nombre-test">{{ p1_valor_saldo_favor }}</div>

      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="textopropuesta3">
          Este dinero será depositado en la cuenta personal que nos indiques.
        </div>

      </v-col>


      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="line-1"></div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="frame-32-2">
          <v-row class="colcenter">
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
              <v-btn class="sm-size" id="btn-propuesta-vigente-r" outlined rounded x-large
                @click="volver"><span><span>REGRESAR</span></span></v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
              <v-btn class="sm-size" id="btn-propuesta-vigente" rounded x-large @click="continuar"
                color="#1d2eff"><span><span style="color:white">CONTINUAR</span></span></v-btn>
            </v-col>
          </v-row>

        </div>
      </v-col>

    </v-row>
    <v-row v-if="tipo == `nuevo`">

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="textopropuesta3">
          Este es el resumen del seguro que estas contratando
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="hola-nombre-test2">Valor Prima</div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="hola-nombre-test">{{ propuestaCreditoNuevo.spl_sim_monto_prima_cl }}</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="">
        <div class="textopropuesta">
          Compañia
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="">
        <div class="textopropuesta">{{ propuestaCreditoNuevo.spl_compania }}</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="">
        <div class="textopropuesta">
          Vigencia
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="">
        <div class="textopropuesta">{{ propuestaCreditoNuevo.spl_pln_fecha_termino }}</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="">
        <div class="textopropuesta">
          Beneficiario
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="">
        <div class="textopropuesta">{{ propuestaCreditoNuevo.spl_nombre }}</div>
      </v-col>
      <!-- <v-col cols="12" sm="12" class="textopropuesta">Valor Prestamo</v-col>
                  <v-col cols="12" sm="12" class="textopropuesta2">{{propuestaCreditoNuevo.spl_sim_monto_credito}}</v-col>
                  <v-col cols="12" sm="12" class="textopropuesta">Valor Cuota Seguro</v-col>
                  <v-col cols="12" sm="12" class="textopropuesta2">{{propuestaCreditoNuevo.spl_valor_cuota}}</v-col>
                  <v-col cols="12" sm="12" class="textopropuesta">Valor Final Seguro</v-col>
                  <v-col cols="12" sm="12" class="textopropuesta2">{{propuestaCreditoNuevo.spl_sim_monto_prima_cl}}</v-col> -->
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="line-1"></div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
        <div class="frame-32-2">
          <v-row class="colcenter">
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
              <v-btn class="sm-size" id="btn-propuesta-nuevo-r" outlined rounded x-large
                @click="volver"><span><span>REGRESAR</span></span></v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
              <v-btn class="sm-size" id="btn-propuesta-nuevo" rounded x-large @click="continuarNuevo"
                color="#1d2eff"><span><span style="color:white">CONTINUAR</span></span></v-btn>
            </v-col>
          </v-row>

        </div>
      </v-col>
    </v-row>


  </div>



</template>
<script>
import BotonContratarLogin from "./BotonContratarLoginVigente.vue";
import BotonContratarFaltaLinea from "./BotonContratarFaltaLineaVigente.vue";

export default {
  name: "PropuestaSeguro",
  components: {
    BotonContratarLogin: BotonContratarLogin,
    BotonContratarFaltaLinea: BotonContratarFaltaLinea,
  },
  data() {
    return {

      p1_glosa_valor_nuevo_seguro: this.$store.state.propuestaCreditoVigente.p1_glosa_valor_nuevo_seguro,
      p1_valor_nuevo_seguro: this.$store.state.propuestaCreditoVigente.p1_valor_nuevo_seguro,
      p1_glosa_seguro_se_paga: this.$store.state.propuestaCreditoVigente.p1_glosa_seguro_se_paga,
      p1_glosa_saldo_favor: this.$store.state.propuestaCreditoVigente.p1_glosa_saldo_favor,
      p1_devolucion: this.$store.state.propuestaCreditoVigente.p1_devolucion,
      p1_glosa_devolucion: this.$store.state.propuestaCreditoVigente.p1_glosa_devolucion,
      p1_valor_saldo_favor: this.$store.state.propuestaCreditoVigente.p1_valor_saldo_favor,
      propuestaCreditoNuevo: this.$store.state.propuestaCreditoNuevo,
      isLogin: this.$store.state.islogin,
      tieneLinea: this.$store.state.estadoCliente.tiene_linea_desgravamen,
    }
  },
  props: {
    credito: 0,
    tipo: ""
  },
  methods: {
    volver() {
      this.$root.$refs.CotizaForm.estadoFormCotizacion = 4;
    },
    continuar() {
      if (this.isLogin) {
        this.$root.$refs.CotizaForm.traeDocumentos(1);
        this.$root.$refs.CotizaForm.traeDocumentos(2);
        this.$root.$refs.CotizaForm.estadoFormCotizacion = 17;
      } else {
        this.$root.$refs.CotizaForm.aprobar();
      }

    },
    continuarNuevo() {
      this.$store.commit("setselectmenu", 3)
      this.$router.push("/mi-cuenta");
    },

  },
  created() {
    console.log(this.$store.state.estadoCliente.tiene_linea_desgravamen);
    this.$store.commit("setestadoprivado", false);
    console.log(this.$store.state.islogin);
    this.$root.$refs.Propuesta = this;

  }

}
</script>

<style scoped>
.textopropuesta {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px); */
  font-size: clamp(0.8rem, 4vw, 1.3rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  ;
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
}

.textopropuesta2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: clamp(0.8rem, 4vw, 1.3rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  ;
  /* font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px); */
  font-weight: var(--headings-h5-font-weight, 700);
  position: relative;
  width: 100%;
}

.textopropuesta3 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  /* font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px); */
  font-size: clamp(0.8rem, 4vw, 1.3rem);
  line-height: clamp(0.8rem, 4vw, 1.5rem);
  ;
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
}

.frame-32-2 {
  /* background: #d9d9d9; */
  border-radius: 80px;
  padding: 9px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

.colcenter {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .sm-size {
    font-size: small;
  }

  .frame-32-2 {
    width: 144% !important;
  }

}

.hola-nombre-test {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}
.hola-nombre-test2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 35px);
  line-height: var(--headings-h2-line-height, 10px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
}
</style>