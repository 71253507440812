<template>
<div>
  <div
      class="frame-persona-formulario-lneadesgravamen-frame-persona-formulario-lneadesgravamen"
    >
  <img
        src="/playground_assets/movil/linea/unsplashwhwybmtn302171-aj5c-400h.png"
        alt="unsplashWHWYBmtn302171"
        class="frame-persona-formulario-lneadesgravamen-unsplash-whw-bmtn30"
      />
      <!-- <img
        src="/playground_assets/movil/linea/rectangle392162-g5j-1500w.png"
        alt="Rectangle392162"
        class="frame-persona-formulario-lneadesgravamen-rectangle39"
      /> -->
      <span class="frame-persona-formulario-lneadesgravamen-text02">
        <span class="frame-persona-formulario-lneadesgravamen-text03">
          <span>De acuerdo a los datos ingresados, te comentamos que tienes una linea</span>
          <br />
          <span></span>
        </span>
        <span>Pre aprobada de {{ propuesta }} con las siguientes</span>
      </span>
      
      
          <v-dialog  
          content-class="customd xxx" 
          style="overflow: unset !important;"
          v-model="dialogx" 
           >
        <template v-slot:activator="{ on }" style="z-index: 9988;" >
          <span  v-on="on" class="frame-persona-formulario-lneadesgravamen-text08">
        <span>Condiciones y beneficios</span>
      </span>
        </template>
        <CondicionesPersonasVue
          style="overflow: unset !important"
        ></CondicionesPersonasVue>
      </v-dialog>
      <img
        src="/playground_assets/movil/linea/unsplashwbgavagjzfg2171-a6m-1100w.png"
        alt="unsplashwBgAVAGjzFg2171"
        class="frame-persona-formulario-lneadesgravamen-unsplashw-bg-av-gjz-fg"
      />
      
      
      <v-btn
        :disabled="clienteExiste"
        color="azulsitio"
        class="rounded-pill"
        x-large
        style="top: 685px; width: 250px; text-transform: unset !important"
        @click="CreaLinea"
      >
        <span class="frame65persona-formulario-lneadesgravamen-text09">
         Crea tu Línea
        </span>
      </v-btn>
      <span class="frame-persona-formulario-lneadesgravamen-text12-2" @click="volver">
        <span>Volver</span>
      </span>
      
      </div>
   
  <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</div>
</template>
<script>
import CondicionesPersonasVue from "./CondicionesPersonasMobile.vue";
import plan from "../../../../../../services/plan";

export default {
  name: "FormularioLinea4",
  components: {
    CondicionesPersonasVue: CondicionesPersonasVue,
  },
  data() {
    return {
      overlay: false,
      dialogx :false ,
      clienteExiste: false,
      propuesta: this.$store.state.propuesta,
      rawacd6: " ",
      antessalir: "",
    };
  },
  methods: {
    volver() {
      this.$router.push("crear-degravamen");
    },
    CreaLinea() {
      if(this.$store.state.estadoCliente.tiene_linea_desgravamen){
        this.$root.$refs.FormularioLinea.estadoFormLinea=7;
      }else{
      if(this.$store.state.estadodps){
        this.$root.$refs.FormularioLinea.estadoFormLinea=5;
      }else{
        this.$root.$refs.FormularioLinea.estadoFormLinea=6;
      }    
    }  
    },
    closeDialog() {
      this.dialogx = false;
    },
    async traeDps(){
      this.overlay =true;
      let res = false;      
      var plancliente=this.$store.state.plan;
      var estadoDps=this.$store.state.estadodps;
      if(estadoDps){
      res = await plan.traedps(plancliente);
      console.log(res);
        if(res.status==200 && res.data.estadoTransaccion.success){
          // this.$alertas(res.data.estadoTransaccion.errors,"success");
          let preg= res.data.preguntas.length;
          if(!preg){
            this.$store.commit('setestadodps', false);
            return;
          }
          this.$store.commit('setdps', res.data.preguntas);
          var dps = this.$store.state.dps;
          this.$root.$refs.FormularioLinea.cargaDps();
          var contDps=this.$store.state.dps.length;
          // this.$root.$refs.BpsLinea.contDps=contDps;
        }else{
          // this.$alertas("En este momento no podemos atender su solicitud","warning");
          this.$store.commit('setestadodps', false);
        }
       
      }
      this.overlay =false;
    },
    async traeTerminos(){
      this.overlay =true;
      let res = false;      
      var plancliente=this.$store.state.plan;
      res = await plan.traeterminos(plancliente);
      console.log(res);
      if(res.status==200){       
        this.$store.commit('setterminos', res.data.terminoscondicion[0]);     
        console.log(res.data.terminoscondicion[0]);
        this.$root.$refs.TerminosMo.cargaTerminos();    
      }else{
        this.$alertas("En este momento no podemos atender su solicitud","warning");
      }
      this.overlay =false;
      
    }
  },
  mounted() {
  
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
  created() {
    // console.log(this.$store.state.clienteEncontrado);
    this.$root.$refs.PreaprobacionLineaMo = this;
  },
};
</script>
<style >
.v-dialog__content{
  top: 30px !important;
}
.v-dialog{
 overflow: unset !important;
 overflow-y: unset !important;
 box-shadow:none !important;
 /* width: 315px !important;
 height: 360px !important;  */
}
.customd{
 overflow: unset !important;
 overflow-y: unset !important;
}
.xxx{
  overflow: unset !important;
 overflow-y: unset !important;
}
.frame65persona-formulario-lneadesgravamen-text02 {
  top: -20px;
  left: 10px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 30px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-rectangle2362 {
  top: 293px;
  /* left: 162px; */
  width: 1173px;
  height: 440px;
  position: absolute;
  border-color: transparent;
  margin-left: -270px;
}
.frame65persona-formulario-lneadesgravamen-unsplashw-bg-a-v-a-gjz-fg {
  top: 293px;
  /* left: 159px; */
  width: 301px;
  height: 412px;
  position: absolute;
  border-color: transparent;
  margin-left: -270px;
}
.frame65persona-formulario-lneadesgravamen-group288916 {
  top: 411px;
  /* left: 540px; */
  width: 686px;
  height: 279px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  /* margin-right: 0; */
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 117px;
}
.frame65persona-formulario-lneadesgravamen-text03 {
  top: 36px;
  color: var(--dl-color-colores_neutrales-90);
  right: -3px;
  width: 686px;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: none;
  width: 800px;
  left: -55px;
}
.frame65persona-formulario-lneadesgravamen-text06 {
  color: var(--dl-color-colores_neutrales-90);
  width: 686px;
  height: auto;
  position: absolute;
  font-size: 25px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-text11 {
  top: 80px;
  /* left: 170px; */
  color: rgb(80, 154, 255);
  height: auto;
  position: absolute;
  font-size: 25px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: underline;
  margin-left: 120px;
}
.ctrans {
  background-color: transparent !important;
  height: 0px !important;
  flex: none !important;
}
.frame-persona-formulario-lneadesgravamen-unsplash-whw-bmtn30 {
  top: 599px;
  left: 24px;
  width: 310px;
  height: 310px;
  position: absolute;
}
.frame-persona-formulario-lneadesgravamen-rectangle39 {
  top: 149px;
  width: 100%;
  height: 727px;
  display: flex;
  position: absolute;
  border-radius: 20px;
  justify-content: center;
}
.frame-persona-formulario-lneadesgravamen-text02 {
  top: 187px;
  color: var(--dl-color-colores_neutrales-90);
  width: 50%;
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 103.49999666213989%;
  font-stretch: normal;
  text-decoration: none;
}
.frame-persona-formulario-lneadesgravamen-text03 {
  font-weight: 600;
}
.frame-persona-formulario-lneadesgravamen-text08 {
  top: 268px;
  color: rgba(81, 155, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 103.49999666213989%;
  font-stretch: normal;
  text-decoration: underline;
}
.frame-persona-formulario-lneadesgravamen-unsplashw-bg-av-gjz-fg {
  top: 322px;
  width: 100%;
  max-width: 347px;
  height: auto;
  display: flex;
  position: absolute;
  justify-content: center;
}
.frame-persona-formulario-lneadesgravamen-usosdebotones {
  top: 700px;
  /* left: 70px; */
  width: 220px;
  display: flex;
  position: absolute;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.15000000596046448) ;
  align-items:center;
  justify-content: center;
}
.frame-persona-formulario-lneadesgravamen-usosdebotones1 {
  gap: 10px;
  width: 220px;
  display: flex;
  padding: 14px;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
}
.frame-persona-formulario-lneadesgravamen-text10 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame-persona-formulario-lneadesgravamen-text12-2 {
  top: 797px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 58px;
  height: auto;
  position: absolute;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: underline;
}
@media(max-width: 767px) {
  .frame-persona-formulario-lneadesgravamen-background {
    top: -150px;
    width: 100%;
    height: 1000px;
  }
  .frame-persona-formulario-lneadesgravamen-ellipse41 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame-persona-formulario-lneadesgravamen-ellipse42 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame-persona-formulario-lneadesgravamen-rectangle39 {
    width: 80%;
  }
  .frame-persona-formulario-lneadesgravamen-unsplashw-bg-av-gjz-fg {
    top: 372px;
  }
}
@media(max-width: 479px) {
  .frame-persona-formulario-lneadesgravamen-background {
    top: -81px;
    width: 479px;
    height: 674px;
  }
  .frame-persona-formulario-lneadesgravamen-ellipse41 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame-persona-formulario-lneadesgravamen-ellipse42 {
    border-radius: var(--dl-radius-radius-round);
  }
  .frame-persona-formulario-lneadesgravamen-rectangle39 {
    top: 142px;
  }
  .frame-persona-formulario-lneadesgravamen-unsplashw-bg-av-gjz-fg {
    top: 360px;
    width: 75%;
  }
  .frame-persona-formulario-lneadesgravamen-usosdebotones {
    width: 245px;
  }

}
.frame-persona-formulario-lneadesgravamen-frame-persona-formulario-lneadesgravamen {
  width: 100%;
  height: 940px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
</style>