<template>
    <div>
        <a class="click-button" @click="open()">
            <img src="/assets/Click2.png" alt="WhatsApp" class="whatsapp-icon" width="30" height="30" />
        </a>
        <v-dialog v-model="dialogAsistencia" class="modalSize" max-width="100%" display="flex" justify-center="center">
            <template>
                <div class="modalDialog">
                    <div class="dialogStyle colcenter">
                        <v-row class="colcenter marcoDialog">
                            <v-col cols="12" sm="12" md="12" lg="11" xl="11" class="colcenter">
                                <img class="vector" src="/assets/privado/modalsimulacion/vector0.svg">
                                <div class="mi-simulaci-n">ASISTENCIA TELEFÓNICA</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter scrollh" v-if="!enviado">
                                <div class="">
                                    <v-form ref="form" v-model="valid">
                                        <v-row dense class="colcenter">
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                                    Por favor ingrese los siguientes datos
                                                </div>
                                                <br>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <v-text-field dense rounded outlined v-model="nombre" ref="fnombre"
                                                    :rules="nombreRules" label="NOMBRE" required color="rgb(56,0,151)"
                                                    @update:error="vnombre" @keypress="vnombre1p()">
                                                    <template v-slot:append>
                                                        <div v-if="validnombre == `no`"></div>
                                                        <v-icon v-else-if="!validnombre" color="m100"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon v-else-if="validnombre" color="red"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" dense>
                                                <v-text-field dense rounded outlined v-model="rut" :rules="rules"
                                                    label="RUT" required class="rut" @update:error="vrut"
                                                    color="rgb(56,0,151)" @keypress="vrutp()">
                                                    <template v-slot:append>
                                                        <div v-if="validrut == `no`"></div>
                                                        <v-icon v-else-if="!validrut" color="m100"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon v-else-if="validrut" color="red"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                <v-text-field prefix="(+56) 9" v-model="celular" :rules="celularRules"
                                                    label="TELEFONO" required rounded outlined dense
                                                    append-icon="mdi-check-circle" class="w100" color="rgb(56,0,151)"
                                                    @update:error="vcelular" @keypress="vcelularp()">
                                                    <template v-slot:append>
                                                        <div v-if="validcelular == `no`"></div>
                                                        <v-icon color="m100" v-else-if="!validcelular"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon color="red" v-else="validcelular"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <v-text-field v-model="email" :rules="emailRules" label="E-MAIL"
                                                    required rounded outlined dense append-icon="mdi-check-circle"
                                                    class="w100" color="rgb(56,0,151)" @update:error="vemail"
                                                    @keypress="vemailp()">
                                                    <template v-slot:append>
                                                        <div v-if="validemail == `no`"></div>
                                                        <v-icon color="m100" v-else-if="!validemail"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon color="red" v-else="validemail"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                <v-select v-model="seleccionTipoInstitucion" :items="instituciones"
                                                    rounded outlined dense label="TIPO DE INSTITUCIÓN"
                                                    color="rgb(56,0,151)" :rules="tipoInstitucionRules"
                                                    v-on:change="selectTipoInstitucion" style="cursor: pointer">
                                                    <template v-slot:append>
                                                        <v-icon color="rgba(0, 23, 135, 1)" v-if="validtipoinstitucion"
                                                            style="margin-bottom: 10px !important">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                                <v-select v-model="seleccionNombreInstitucion"
                                                    :items="nombreintituciones" rounded outlined dense
                                                    label="NOMBRE DE INSTITUCIÓN" color="rgb(56,0,151)"
                                                    :rules="nombreInstitucionRules"
                                                    v-on:change="selectNombreInstituciones">
                                                    <template v-slot:append>
                                                        <v-icon color="rgba(0, 23, 135, 1)"
                                                            v-if="validnombreinstitucion"
                                                            style="margin-bottom: 10px !important">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <v-textarea v-model="caso" no-resize rows="1" :rules="casoRules"
                                                    label="CASO" required rounded outlined dense
                                                    append-icon="mdi-check-circle" class="w100" color="rgb(56,0,151)"
                                                    @update:error="vcaso" @keypress="vcasop()">
                                                    <template v-slot:append>
                                                        <div v-if="validcaso == `no`"></div>
                                                        <v-icon color="m100" v-else-if="!validcaso"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-checkbox-marked-circle
                                                        </v-icon>
                                                        <v-icon color="red" v-else="validcaso"
                                                            style="margin-bottom: 10px !important ;">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="line-1"></div>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                <div class="frame-32-2">
                                                    <v-row class="colcenter">
                                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                                            <v-btn class="sm-size" outlined rounded x-large block
                                                                @click="close"><span><span>REGRESAR</span></span></v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                                                            <v-btn class="sm-size" rounded x-large @click="validate"
                                                                block :disabled="!valid" color="#1d2eff"><span><span
                                                                        style="color:white">ENVIAR</span></span></v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-form>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="colcenter" v-if="enviado">
                                <v-row dense class="colcenter">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <div class="devoluci-n-aproximada-de-prima-no-consumida">
                                            Muchas gracias, seras contactado por un agente
                                        </div>
                                        <br>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <div class="frame-32-2">
                                            <v-row class="colcenter">
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                                    <v-btn class="sm-size" outlined rounded x-large
                                                        @click="close"><span><span>REGRESAR</span></span></v-btn>
                                                </v-col>
                                            </v-row>

                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-dialog>
        <v-overlay :value="overlay" style="z-index: 1000;">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>

</template>
<script>
import datausuario from "../../../services/usuario-datos";
import institucion from "../../../services/intitucion";

export default {
    name: "ModalAsistencia",
    data() {
        return {
            overlay: false,
            enviado: false,
            nombre: "",
            celular: "",
            email: "",
            rut: "",
            caso: "",
            vinicio: true,
            validnombre: "no",
            validemail: "no",
            validcelular: "no",
            validrut: "no",
            validcaso: "no",
            valid: false,
            instituciones: [],
            nistituciones: [],
            nombreintituciones: [],
            seleccionTipoInstitucion: "",
            seleccionNombreInstitucion: "",
            codInst: 0,
            codNInst: 0,
            ncod: [],
            validtipoinstitucion: false,
            validnombreinstitucion: false,
            tipoInstitucionRules: [
                (v) => !!v || "el tipo institución es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
            nombreInstitucionRules: [
                (v) => !!v || "el nombre institución es requerido",
                (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
            ],
            nombreRules: [
                (v) => !!v || "el nombre es requerido",
                (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (v && v.length >= 10) || "largo mínimo 10 caracteres",
                (v) => (v && this.validarLn1(v)) || "este campo solo acepta letras",
            ],
            emailRules: [
                v => !!v || 'el campo Correo es requerido',
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                v => /.+@.+\..+/.test(v) || 'Ingrese un correo válido',
                (v) => (this.validaMail(v)) || "largo mínimo de dominio 3 caracteres",

            ],
            celularRules: [
                (v) => (this.formatPhoneNumber(v)) || "formato inválido",
                (v) => !!v || "el campo Celular es requerido",
                (v) => (v && v.length == 8) || "número de celular máximo 8 caracteres",
            ],
            casoRules: [
                (v) => !!v || "el caso es requerido",
                (v) => (v && v.length <= 500) || "largo máximo 500 caracteres",
                (v) => (v && v.length >= 20) || "largo mínimo 20 caracteres",
            ],
            dialogAsistencia: false,
        }
    },
    methods: {
        async enviar() {
            this.overlay = true;
            let res2 = false;
            let cliente = {
                nombre: this.nombre,
                telefono: "+569" + this.celular,
                email: this.email,
                rut: this.rut,
                banco: this.seleccionNombreInstitucion,
                caso: this.caso
            }
            res2 = await datausuario.asistencia(cliente);
            if (res2.status == 200 && res2.data.estadoTransaccion.success) {
                console.log(res2.data);
                this.overlay = false;
                this.enviado = true;
            } else {
                this.$alertas(res2.data.estadoTransaccion.errors,"error");
                this.overlay = false;
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.enviar();
            }
        },
        validaRut: function (rutCompleto) {
            if (rutCompleto && rutCompleto != "") {
                rutCompleto = rutCompleto.replace(/\./g, '');
            }
            if (!rutCompleto) {
                this.rut = "";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev = rut + "" + digv;
            let sw = true;
            let mem = false;
            for (let index = 0; index < rev.length; index++) {
                if (!index) {
                    mem = rev.substring(index, 1);
                    console.log("mem:" + mem);
                } else {
                    if (mem != rev.substring(index, index + 1)) {
                        sw = false;
                        break;
                    } else {
                        console.log("err:" + rev.substring(index, 1));
                    }
                }
            }
            if (rutCompleto == "44444444-4") {
                return true;
            }
            if (sw) { return false; }
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        formateaRut(rut) {
            if (!rut || rut == "") {
                return;
            }
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 0) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return this.rut = rutPuntos;
        },
        largorut(rut, largo) {
            if (rut && rut.length > largo) {
                rut = rut.substring(0, rut.length - 1);
            }
            this.rut = rut.replace(/[^kK0-9.-]/gi, "");

            return true;
        },
        vrutp(v) {
            if (this.validrut == "no") {
                this.validrut = true;
            }
        },
        vrut(v) {
            this.validrut = v;
            console.log(v);
            if (!v) {
                if (this.rut.length >= 11 && this.rut.length <= 12) {
                    // this.buscar(this.rut)
                }

            }
        },
        vcasop(v) {
            if (this.validcaso == "no") {
                this.validcaso = true;
            }
        },
        vcaso(v) {
            this.validcaso = v;
        },
        vemail(v) {
            this.validemail = v;
        },
        vcelular(v) {
            this.validcelular = v;
        },
        vcelularp() {
            if (this.validcelular == "no") {
                this.validcelular = true;
            }
        },
        validarLn1() {
            this.nombre = this.nombre.replace(/[^a-zA-Z\s]/gi, "");
            this.nombre = this.nombre.replace("  ", " ");
            return true;
        },
        vnombre(v) {
            this.validnombre = v;
        },
        vnombre1p(v) {
            if (this.validnombre == "no") {
                this.validnombre = true;
            }
        },
        validaMail(email) {
            if (!email || email == "") {
                return;
            }
            var revmail = email.split("@");
            if (revmail.length == 2) {
                if (revmail[1].length >= 5) {
                    return true;
                }
                return false;
            }

        },
        vemailp() {
            if (this.validemail == "no") {
                this.validemail = true;
            }
        },
        formatPhoneNumber(phone) {
            if (phone && phone != "") {
                let celular = "";
                phone = phone.replace("(+56) 9 ", "");
                phone = phone.replace("+569", "");
                if (phone && phone.length > 8) {
                    phone = phone.substring(0, phone.length - 1);
                }
                let letra = "";
                for (let index = 0; index < phone.length; index++) {
                    letra = phone.charAt(index).replace(/[^0-9]/gi, "");
                    celular = celular + letra;
                }
                this.celular = celular;

            }
            return true;
        },
        open() {
            this.dialogAsistencia = true;
            window.scrollTo(0,0);  
            let sw=false;
            if (localStorage.getItem("loginEstado")) {
                this.rut=this.$store.state.loginUsuario.contrato.per_rut;
                this.celular =this.$store.state.loginUsuario.contrato.per_celular;
                this.nombre = this.$store.state.loginUsuario.contrato.per_nombre;
                this.email = this.$store.state.loginUsuario.contrato.per_email;
                sw=true;
            }
            console.log(this.$store.state.form1);
            if(this.$store.state.form1.rut!=""){
                this.rut=this.$store.state.form1.rut;
                this.nombre = this.$store.state.form1.nombre1 + " " + this.$store.state.form1.nombre2 + " " + this.$store.state.form1.apaterno + " " + this.$store.state.form1.amaterno;
                sw=true;
            }
            if(this.$store.state.form2.email!=""){
                this.email = this.$store.state.form2.email;
                this.celular = this.$store.state.form2.celular;
                sw=true;
            }
            if(!sw){
                this.enviado = false;
                // this.nombre = "";
                // this.celular = "";
                // this.email = "";
                // this.rut = "";
                // this.email = "";
                this.banco = "",
                this.caso = "";
                this.seleccionTipoInstitucion = "";
                this.seleccionNombreInstitucion = "";
                this.validnombre = "no";
                this.validemail = "no";
                this.validcelular = "no";
                this.validrut = "no";
                this.validcaso = "no";
                this.validtipoinstitucion = false;
                this.validnombreinstitucion = false; 
                try {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();                
                } catch (error) {
                    console.log("nuevo");
                }  
            }
            
            
        },
        close() {
            this.dialogAsistencia = false;
        },
        async traeIntituciones() {
            let res = false;
            res = await institucion.traeinstituciones();
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // console.log("entra");
                let inst = [];
                let ninst = [];
                let ncod = [];
                let nombreinst = [];
                res.data.instituciones.forEach((element) => {
                    // console.log(element);
                    ninst = [];
                    inst.push(element.tin_glosa);
                    if (element.institucion) {
                        element.institucion.forEach((i) => {
                            // console.log(i);
                            ninst.push(i.int_nombre);
                            ncod.push({ nombre: i.int_nombre, cod: i.int_numero });
                        });
                    }
                    nombreinst.push({
                        tipo: element.tin_glosa,
                        cod: element.tin_numero,
                        nombre: ninst,
                        ncod: ncod,
                    });
                });

                this.instituciones = inst;
                this.nistituciones = nombreinst;
                this.$store.commit("setinstituciones", inst);
                this.$store.commit("setnombreinst", nombreinst);

                return;
            } else {
                this.$store.commit("setclienteencontrado", false);

                this.$alertas("En este momento no podemos atender su solicitud", "warning");
            }
        },
        selectTipoInstitucion(e) {
            this.validtipoinstitucion = true;
            this.tipoInstitucion = e;
            let nombresInst = [];
            let ncod = [];

            this.nistituciones.forEach((element) => {
                if (element.tipo == e) {
                    nombresInst = element.nombre;
                    this.codInst = element.cod;
                    ncod = element.ncod;
                }
            });
            this.nombreintituciones = nombresInst;
            this.ncod = ncod;
            this.$store.commit("setnombreinst", nombresInst);
        },
        selectNombreInstituciones(e) {
            this.validnombreinstitucion = true;
            this.nombreInstitucion = e;
            this.ncod.forEach((element) => {
                if (element.nombre == e) {
                    this.codNInst = element.cod;                   
                }
            });
            this.$store.commit("setcodinst", this.codInst);
            this.$store.commit("setcodninst", this.codNInst);
        },
    },
    computed: {
        rules() {
            const rules = [];
            let rule = "";
            rule = (v) => (v && v.length >= 11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule = (v) => (v && this.largorut(v, 12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.rut) || `rut inválido`;
            if (this.rut != "") {
                this.formateaRut(this.rut);
            }
            rules.push(rule);

            return rules;
        },
    },
    mounted() {
        window.scrollTo(0,0);  
        this.traeIntituciones();
    },
    created() {
        this.$root.$refs.ModalAsistencia = this;
    },
}
</script>
<style scoped>
.colcenter {
    display: flex;
    justify-content: center;
}

.modalDialog {
    display: flex;
    justify-content: center;
}

.dialogStyle {
    /* background: var(--blanco, #f0f2f2); */
    background: var(--blanco, #f0f2f2);
    border-radius: 40px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    width: 60%;
}

.marcoDialog {
    background: var(--blanco, #f0f2f2);

}

.env-e-su-rut-y-enseguida-le-enviaremos-un-correo-con-las-instrucciones-para-recuperar-su-contrase-a {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 33px;
    height: 33px;
    position: relative;
    overflow: visible;
}

.el-saldo-a-favor-ser-depositado-en-billetera-electr-nica-solo-una-vez-que-recibamos-de-parte-de-la-compa-a-aseguradora-proveedora-del-seguro-a-reemplazar-la-devoluci-n-conforme-de-la-prima-pagada-no-devengada {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.7rem);
    position: relative;
    width: 100%;
}

.devoluci-n-aproximada-de-prima-no-consumida {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h4-font-size, 36px);
    line-height: var(--headings-h4-line-height, 36px); */
    font-weight: var(--headings-h4-font-weight, 400);
    font-size: clamp(0.8rem, 5vw, 1.5rem);
    line-height: clamp(1rem, 5vw, 1.5rem);
    position: relative;
}

.valores {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 36px;
    line-height: 36px; */
    font-size: clamp(0.8rem, 5vw, 1.7rem);
    line-height: clamp(1rem, 5vw, 1.6rem);
    font-weight: 900;
    position: relative;

}

.mi-simulaci-n {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
    /* font-size: var(--headings-h3-font-size, 40px);
    line-height: var(--headings-h3-line-height, 48px); */
    font-size: clamp(1rem, 5vw, 2rem);
    line-height: clamp(1.3rem, 5vw, 2.4rem);
    font-weight: var(--headings-h3-font-weight, 500);
    position: relative;
    margin-left: 20px;
}

.scrollh{
    height: auto;
}

.click-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 83px;
    right: 15px;
    background-color: rgb(6 17 100);
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    transition: background-color 0.3s;
}

.click-button:hover {
    background-color: #c2cdd0;
}

.whatsapp-icon {
    margin-top: 15px;
}

@media only screen and (max-width: 800px) {
    .click-button {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 100px !important;
        right: 30px !important;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 1000;
        transition: background-color 0.3s;
    }
    .dialogStyle {
        width: 100% !important;
    }
    .scrollh{
        height: 400px !important;
        overflow-y: auto !important;;
    }
    .vector {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
}
</style>