<template>
<div class="footer">
    <v-row class="banner-principal2 colcenter">
      <v-col cols="12" sm="12" md="10" lg="6" xl="6" class="colcenter">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                <div class="frame-29">
                  <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                        <div class="una-empresa-de">Una empresa de</div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                        <img class="logotipo-grupo-insurex-blanco-1" src="/assets/cotizacion/logotipo-grupo-insurex-blanco-10.png">
                      </v-col>
                    </v-row>
                </div>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="colcenter">
                <div class="frame-7">
                  <div class="frame-2">
                    <div class="respaldados-por">Respaldados por</div>
                  </div>
                  <div class="frame-13">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                        <img class="dise-o-sin-t-tulo-27-1" src="/assets/cotizacion/dise-o-sin-t-tulo-27-10.png">
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                        <img class="dise-o-sin-t-tulo-29-1" src="/assets/cotizacion/dise-o-sin-t-tulo-29-10.png">
                      </v-col>
                    </v-row>
                  </div>
                </div>
          </v-col> -->

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="colcenter">
                <div class="contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes">
                  <span>
                    <span class="contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span">
                      Consultas de la portabilidad:                      
                      <br>            
                      servicioalcliente@mueveseguro.cl
                    </span>
                    <span class="contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span2">
                      <br>
                      Consulta de tu nuevo Seguro:
                      <br>
                      contacto@grupoinsurex.cl
                    </span>
                   
                  </span>
                </div>
              </v-col>
        </v-row>
      </v-col>
      <BotonClick></BotonClick>
      <BotonWsp></BotonWsp>
    </v-row>
</div>

  </template>
  
  <script>
  import BotonWsp from "./BotonWsp.vue";
  import BotonClick from "./BotonClick.vue";
    
  export default {
    name: "FooterMueve",
    components: {
      BotonWsp: BotonWsp,
      BotonClick: BotonClick,
    },
    data() {
      return {
        
      }
    },
    watch: {
      
      },
    computed: {
      
    }
  }
  </script>
  
  <style scoped>
  .bannerlogo {
    background-image: url('/assets/cotizacion/teaser-ms-02-10.png');
    background-repeat: no-repeat;
    background-size: 100% ;
    margin-left: 0px !important;
    height :100%;
  }
  
  .colcenter{
    display: flex;
    justify-content: center;
  }
  
  
  .footer {
    text-align: center;
          font-family: sans-serif;
          color: whitesmoke;
          width: 100%;
          bottom: 0;
          /* position:fixed; */
  
  }
  .banner-principal2 {
    background: var(--blanco, #f0f2f2);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* width: 100%; */
    /* height: 200px; */
    position: relative;
    /* left: 0px;
    top: 966px; */
    overflow: hidden;
    padding: 20px;
  }
  .frame-4 {
    padding: 0px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    gap: 60px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 232px;
    position: relative;
  }
  .frame-29 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }
  .una-empresa-de {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h6-font-size, 20px);
    line-height: var(--headings-h6-line-height, 24px);
    font-weight: var(--headings-h6-font-weight, 400);
    position: relative;
    width: 160px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .logotipo-grupo-insurex-blanco-1 {
    flex-shrink: 0;
    width: 200px;
    height: 75.25px;
    position: relative;
    object-fit: cover;
  }
  .frame-7 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 720px;
    position: relative;
    overflow: hidden;
  }
  .frame-2 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    width: 720px;
    position: relative;
  }
  .respaldados-por {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h6-font-size, 20px);
    line-height: var(--headings-h6-line-height, 24px);
    font-weight: var(--headings-h6-font-weight, 400);
    position: relative;
    width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .frame-13 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }
  .dise-o-sin-t-tulo-27-1 {
    flex-shrink: 0;
    width: 168.48px;
    height: 91.5px;
    position: relative;
    object-fit: cover;
  }
  .dise-o-sin-t-tulo-29-1 {
    flex-shrink: 0;
    width: 324.72px;
    height: 68.26px;
    position: relative;
    object-fit: cover;
  }
  .contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    position: relative;
    width: 236px;
  }
  .contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span {
    color: var(--azul-complementario, #04137f);
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
  }
  .contacto-fono-56226339090-direcci-n-av-vitacura-2771-of-904-las-condes-span2 {
    color: var(--azul-complementario, #04137f);
    font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h6-font-size, 20px);
    line-height: var(--headings-h6-line-height, 24px);
    font-weight: var(--headings-h6-font-weight, 400);
  }
  
  
  
  @media only screen and (max-width: 800px) {
    .footer {
      position : relative;
    }
  }
  
  </style>