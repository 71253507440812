
import Vue from 'vue'
import vue from 'vue'
window.Vue = vue;

import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from "./store";
import axios from "axios";


import "../helpers/h-toast";
// import "../helpers/h-mask";
// import "./helpers/h-formulate";
// import "./helpers/h-sweetalert2";
// import "./helpers/h-loading-overlay";
// import "./helpers/h-tables";
// import "./helpers/h-toggle-button";
// import "./helpers/h-apexcharts";

Vue.config.productionTip = false

// if (localStorage.getItem("token") && localStorage.getItem("token") != "undefined") {
//   let token = JSON.parse(localStorage.getItem("token"));
//   if (token) {
//     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//   }
// }

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')