import { render, staticRenderFns } from "./Terminos.vue?vue&type=template&id=24ffc572&scoped=true&"
import script from "./Terminos.vue?vue&type=script&lang=js&"
export * from "./Terminos.vue?vue&type=script&lang=js&"
import style0 from "./Terminos.vue?vue&type=style&index=0&id=24ffc572&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ffc572",
  null
  
)

export default component.exports