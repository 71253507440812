<template>
    <div>
       

    <v-dialog
      v-model="fpoco"
      width="1169px"
      border="0px"
      content-class="permanente"
      style="background-color: transparent"
      :class="`hola`"
      
    >
      <template v-slot:activator="{ on }">
        <v-btn
        class="frame52missegurosactivos-text140"
          color="rgba(255, 184, 78)"
          v-on="on"
          x-large
          @click="traeDetalle(ident)"
          style="background-color: transparent;
                border-color: transparent;
                left: 0px;
                text-transform: unset !important;
                box-shadow: none;
                position: absolute;
                width: 226px;
                height: 30px;
                margin-top: 182px;
                margin-left: 805px;"
        >
          <span class="frame52missegurosactivos-text140">
            <span>Ver detalle Seguro</span>
          </span>
        </v-btn>
      </template>
      <DetalleSeguroPage :nroseguro="identnumero" :Seguro="SeguroDetalle" @cierraModaldet="cerrarModaldet" :Loading="overlay" :simulacion="false"  
      
        v-bind:origen="`0`"
      ></DetalleSeguroPage>
    </v-dialog>
    
  
  </div>
  
</template>
<script>
import privado from "../../../../services/privado";
import DetalleSeguroPage from "./DetalleSeguroPage.vue";
export default {
  name: "DetalleSeguro",
  components: {
    DetalleSeguroPage: DetalleSeguroPage,
  },
  data() {
    return {
      overlay: false,
      valid: true,
      fpoco: "",
      SeguroDetalle : {
        numero: "",
        poliza: "",
        transaccion: "",
        inicio: "",
        termino: "",
        celular: "",
        email: "",
        direccion: "",
        nombre: "",
        rut: "",
        montoPeso: "",
        montoUf: "",
        montoAsegurado: "",
        tipoInstitucion: "",
        nombreIntitucion: "",
        saldoInsoluto: "",
        mesesPendientePago: 0,
        periodoGracia: 0,
        plazoCredito: 0,
        cantidadCuotas: 0,
        spl_numero: 0,
        spl_seguro_numero: 0,
        spl_pln_numero: 0,
        cse_numero: 0,
        cse_ctt_numero: 0,
    }
    };
  },
  props: {
    ident: "",
    identnumero: ""
  },
  methods: {
    cerrarModaldet(){
    this.fpoco=false;
   },
    async  traeDetalle(numero){
        
      var res = false;
        this.overlay=true;
        res = await privado.traeDetalleSeguro(numero);
       
        if (res.status == 200 && res.data.estadoTransaccion.success) {
                
          this.SeguroDetalle.numero= res.data.miSeguro.cse_numero;
          this.SeguroDetalle.poliza= res.data.miSeguro.cse_poliza;
          this.SeguroDetalle.transaccion= res.data.miSeguro.cse_transaccion;
          this.SeguroDetalle.inicio= res.data.miSeguro.cse_pln_fecha_inicio;
          this.SeguroDetalle.termino= res.data.miSeguro.cse_pln_fecha_termino;
          this.SeguroDetalle.celular= res.data.miSeguro.cse_celular;
          this.SeguroDetalle.email= res.data.miSeguro.cse_email;
          this.SeguroDetalle.direccion= res.data.miSeguro.cse_calle +" #"+
          res.data.miSeguro.cse_nro +", "+ res.data.miSeguro.cse_depto +", "+
          res.data.miSeguro.cse_comuna +" " + res.data.miSeguro.cse_ciudad ;
          this.SeguroDetalle.nombre= res.data.miSeguro.cse_nombre;
          this.SeguroDetalle.rut= res.data.miSeguro.cse_rut;
          this.SeguroDetalle.montoPeso= res.data.miSeguro.cse_sim_monto_prima_cl;
          this.SeguroDetalle.montoUf= res.data.miSeguro.cse_sim_monto_prima_uf;
          this.SeguroDetalle.montoAsegurado= res.data.miSeguro.cse_saldo_insoluto;
          this.SeguroDetalle.tipoInstitucion= res.data.miSeguro.cse_glosa_tipo_institucion;
          this.SeguroDetalle.nombreIntitucion= res.data.miSeguro.cse_glosa_institucion;
          this.SeguroDetalle.saldoInsoluto= res.data.miSeguro.cse_sim_monto_credito;
          this.SeguroDetalle.mesesPendientePago=res.data.miSeguro.cse_meses_pendiente;
          this.SeguroDetalle.periodoGracia=res.data.miSeguro.cse_periodo_gracia;
          this.SeguroDetalle.plazoCredito=res.data.miSeguro.cse_plazo_meses;
          this.SeguroDetalle.cantidadCuotas=res.data.miSeguro.cse_meses_pendiente;
          this.SeguroDetalle.spl_numero=res.data.miSeguro.spl_numero;
          this.SeguroDetalle.spl_seguro_numero=res.data.miSeguro.spl_seguro_numero;
          this.SeguroDetalle.spl_pln_numero=res.data.miSeguro.spl_pln_numero;
          this.SeguroDetalle.cse_numero=res.data.miSeguro.cse_numero;
          this.SeguroDetalle.cse_ctt_numero=res.data.miSeguro.cse_ctt_numero;
          this.SeguroDetalle.cse_seg_numero=res.data.miSeguro.cse_seg_numero;
          this.SeguroDetalle.cse_usu_credito_nuevo=res.data.miSeguro.cse_usu_credito_nuevo;
          this.overlay=false;
        }else{
          this.$alertas(res.data.estadoTransaccion.errors,"info");
          this.overlay=false;
        }
       
    }
  },
  created() {
    this.$root.$refs.DetalleSeguro = this;
  },
};
</script>
<style scoped>

.permanente{
    border-radius: 0px ;
}
.frame52missegurosactivos-text140 {
 
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 22px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame75persona-cotizarsegurode-desgravamen-usosdebotones1 {
  top: 863px;
  left: 1072px;
  width: 250px;
  height: 23px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-text02 {
  position: relative;
  top: 14px;
  left: 4px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
