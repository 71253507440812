<template>
  <v-app>
    <div style="display: flex;justify-content: center;">
     
      <div class="frame7-persona-formulario-lneadesgravamen-iconocorreo">
        <img
          src="/playground_assets/movil/linea/fin/icons8mail12257-xs0a.svg"
          alt="icons8mail12257"
          class="frame7-persona-formulario-lneadesgravamen-icons8mail1"
        />
      </div>
      <span class="frame7-persona-formulario-lneadesgravamen-text02">
        <span>Ahora te envíamos un correo para validar tu acceso.</span>
        
      </span>
      <span class="frame7-persona-formulario-lneadesgravamen-text04">
        <span>
          <span v-if="!clienteExiste">Gracias por obtener tu</span>
          <br v-if="!clienteExiste"/>
          <span v-if="!clienteExiste">línea de Desgravamen</span>

          <span v-if="clienteExiste">Según nuestros registros,</span>
          <br v-if="clienteExiste"/>
          <span v-if="clienteExiste">Ya cuentas con una línea de desgravamen</span>
          
        </span>
      </span>
      <div class="frame7-persona-formulario-lneadesgravamen-usosdebotones">
        <div class="frame7-persona-formulario-lneadesgravamen-usosdebotones1">
          
          <v-btn color="juanito" class="rounded-pill " x-large
      style="text-transform: unset !important;" 
      @click="inicio">
                    <span class="frame7-persona-formulario-lneadesgravamen-text09">
                        <span>Volver al incio</span>
                    </span>
      </v-btn>
        </div>
      </div>
      <div class="frame7-persona-formulario-lneadesgravamen-group289079">
        <img
          src="/playground_assets/movil/linea/fin/rectangle24392257-2ms9-200h.png"
          alt="Rectangle24392257"
          class="frame7-persona-formulario-lneadesgravamen-rectangle2439"
        />
        <img
          src="/playground_assets/movil/linea/fin/rectangle3952257-fpn-200h.png"
          alt="Rectangle3952257"
          class="frame7-persona-formulario-lneadesgravamen-rectangle395"
        />
        <img
          src="/playground_assets/movil/linea/fin/iconbtns2257-h2ud.svg"
          alt="IconBtns2257"
          class="frame7-persona-formulario-lneadesgravamen-icon-btns3"
        />
        <span class="frame7-persona-formulario-lneadesgravamen-text11">
          <span>
            <span>
              ¿Quieres cotizar tu
             
            </span>
            <br />
            <span>Seguro de Desgravamen?</span>
          </span>
        </span>
      </div>
      <span class="frame7-persona-formulario-lneadesgravamen-text16">
        <span class="frame7-persona-formulario-lneadesgravamen-text17">
          <span>Si no recibiste el correo, por favor</span>
          <br />
          <span></span>
        </span>
        <span>Haz clic aquí</span>
      </span>
      <div class="frame7-persona-formulario-lneadesgravamen-icon-btns4">
        <img
          src="/playground_assets/movil/linea/fin/ellipse4i2257-p1ib-200h.png"
          alt="Ellipse4I2257"
          class="frame7-persona-formulario-lneadesgravamen-ellipse43"
        />
        <img
          src="/playground_assets/movil/linea/fin/iconoutlinechecki2257-tdid.svg"
          alt="IconOutlinecheckI2257"
          class="frame7-persona-formulario-lneadesgravamen-icon-outlinecheck3"
        />
      </div>
    </div>
  </v-app>
</template>
<script>
export default {
  name: 'Frame67PersonaFormularioLneadesgravamen',

  data() {
    return {
      rawp9xr: ' ',
      
    }
  },
  props :{
    clienteExiste: false
  },
  methods:{
    inicio: function() {
        this.$router.push('/home');
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame67persona-formulario-lneadesgravamen-container {
  min-height: 100vh;
}
.frame67persona-formulario-lneadesgravamen-frame67persona-formulario-lneadesgravamen {
  width: 100%;
  height: 950px;
  display: flex;
  overflow: hidden;
  /* position: relative; */
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame67persona-formulario-lneadesgravamen-rectangulo-fondo-gradiente2 {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 281px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-rectangle39 {
  top: 218px;
  left: 42px;
  width: 1357px;
  height: 630px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame67persona-formulario-lneadesgravamen-group495 {
  top: 62px;
  left: 148px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group {
  top: 15.025447845458984px;
  left: 105.18211364746094px;
  width: 149.81614685058594px;
  height: 47.98341751098633px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group1 {
  top: 0.00005686227814294398px;
  left: 44.20986557006836px;
  width: 54.99390411376953px;
  height: 19.948720932006836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector {
  top: 3.23506498336792px;
  left: -0.00009430493082618341px;
  width: 10px;
  height: 17px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector01 {
  top: 0.00005686227814294398px;
  left: 11.323002815246582px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector02 {
  top: 6.4695048332214355px;
  left: 17.79131317138672px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector03 {
  top: 6.4695048332214355px;
  left: 30.731914520263672px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector04 {
  top: 6.4695048332214355px;
  left: 43.67137908935547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group2 {
  top: 25.878984451293945px;
  left: -0.000016696129023330286px;
  width: 143.41653442382812px;
  height: 22.104625701904297px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector06 {
  top: 3.7734971046447754px;
  left: 14.556951522827148px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector07 {
  top: 3.7734971046447754px;
  left: 27.4975528717041px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector08 {
  top: 3.7734971046447754px;
  left: 38.817806243896484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector09 {
  top: 3.7734971046447754px;
  left: 52.29852294921875px;
  width: 9px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector10 {
  top: 3.7734971046447754px;
  left: 60.92502212524414px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector11 {
  top: 3.7734971046447754px;
  left: 73.32494354248047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector12 {
  top: 3.7734971046447754px;
  left: 85.72599792480469px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector13 {
  top: 3.7734971046447754px;
  left: 99.20443725585938px;
  width: 18px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector14 {
  top: 3.7734971046447754px;
  left: 118.6139144897461px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector15 {
  top: 3.7734971046447754px;
  left: 131.55508422851562px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group3 {
  top: 24.742467880249023px;
  left: 144.1051025390625px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group4 {
  top: 1.4308146238327026px;
  left: 1.859254240989685px;
  width: 2.3796639442443848px;
  height: 2.8555972576141357px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 77.92008209228516px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-vector18 {
  top: 1.3724621534347534px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-vector19 {
  top: -9.094947017729282e-13px;
  left: 24.692947387695312px;
  width: 53px;
  height: 78px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-text {
  top: 57px;
  left: 1103px;
  color: var(--dl-color-gray-white);
  width: 158px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-group288877 {
  top: 83px;
  left: 1075px;
  width: 219px;
  height: 48px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-rectangle3 {
  top: 23px;
  left: 125.72802734375px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-rectangle4 {
  top: 23px;
  left: 38px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-group521 {
  top: 0px;
  left: 0px;
  width: 219px;
  height: 48px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-btns {
  top: 5px;
  left: 0px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-icon-btns1 {
  top: 5px;
  left: 88px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 171px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
}
.frame67persona-formulario-lneadesgravamen-icon-outlinecheck2 {
  top: 9.962783813476562px;
  left: 9.962167739868164px;
  width: 28px;
  height: 28px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-frame515 {
  top: 307px;
  /* left: 256px; */
  width: 929px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  margin-left: -150px;
}
.frame67persona-formulario-lneadesgravamen-frame514 {
  top:-23px;
  display: flex;
  padding: 10px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 0px;
}
.frame67persona-formulario-lneadesgravamen-text02 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  font-size: 45px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-text04 {
  top:62px;
  color: var(--dl-color-colores_neutrales-90);
  width: 817px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group512 {
  top: 616px;
  /* left: 59px; */
  width: 1322px;
  height: 199px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: -350px;
}
.frame67persona-formulario-lneadesgravamen-text06 {
  top: 115px;
  /* left: 765px; */
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 31px;
  font-stretch: normal;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-rectangle395 {
    top: 1px;
    left: 0px;
    margin-left: 16px;
    width: 649px;
    height: 184px;
    position: absolute;
    border-color: transparent;
    border-radius: 0 16px 0 20px;
}
.frame67persona-formulario-lneadesgravamen-rectangle398 {
    top: -15px;
    left: 640px;
    width: 666px;
    height: 216px;
    position: absolute;
}
.frame67persona-formulario-lneadesgravamen-group288884 {
  top: 40px;
  left: 689px;
  width: 537px;
  height: 120px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-text08 {
  top: 18px;
  color: var(--dl-color-colores_paleta-tonos_azul-0);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 42px;
}
.frame67persona-formulario-lneadesgravamen-icon-btns3 {
  top: 0px;
  left: 417px;
  width: 120px;
  height: 120px;
  position: absolute;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones {
  top: 863px;
  left: 1072px;
  width: 220px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame67persona-formulario-lneadesgravamen-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}
.frame67persona-formulario-lneadesgravamen-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame67persona-formulario-lneadesgravamen-text15 {
  top: 534px;
  /* left: 473px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 114.99999761581421%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 82px;
}
.frame67persona-formulario-lneadesgravamen-rectangle2432 {
  right: 7px;
  width: 98px;
  bottom: 20px;
  height: 75px;
  position: absolute;
  border-color: transparent;
}

.frame7-persona-formulario-lneadesgravamen-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.frame7-persona-formulario-lneadesgravamen-frame7-persona-formulario-lneadesgravamen {
  width: 100%;
  height: 1050px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame7-persona-formulario-lneadesgravamen-back-persona-formulario-lneadesgravamen {
  top: 0px;
  width: 100%;
  height: 620px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
.frame7-persona-formulario-lneadesgravamen-background {
  top: 0px;
  width: 100%;
  height: auto;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-text {
  top: 44px;
  left: 188px;
  color: var(--dl-color-colores_neutrales-white);
  right: 11%;
  height: auto;
  position: absolute;
  font-size: 13px;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 47.49999940395355%;
  font-stretch: normal;
  text-decoration: none;
}
.frame7-persona-formulario-lneadesgravamen-logotipos {
  top: 27px;
  left: 26px;
  width: 90px;
  height: 94.04495239257812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-txt {
  top: 65.4912109375px;
  left: 0px;
  width: 90px;
  height: 28.554122924804688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-group {
  top: 0px;
  left: 26.558950424194336px;
  width: 33.036964416503906px;
  height: 11.870681762695312px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-vector {
  top: 1.9248809814453125px;
  left: 0px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector01 {
  top: 0px;
  left: 6.801977157592773px;
  width: 4px;
  height: 12px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector02 {
  top: 3.8502960205078125px;
  left: 10.688322067260742px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector03 {
  top: 3.8502960205078125px;
  left: 18.4619197845459px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector04 {
  top: 3.8502960205078125px;
  left: 26.235246658325195px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-group1 {
  top: 15.400634765625px;
  left: 0px;
  width: 86.1550521850586px;
  height: 13.153488159179688px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 8px;
  height: 10px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector06 {
  top: 2.2457733154296875px;
  left: 8.74444580078125px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector07 {
  top: 2.2457733154296875px;
  left: 16.51831817626953px;
  width: 6px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector08 {
  top: 2.2457733154296875px;
  left: 23.319704055786133px;
  width: 7px;
  height: 11px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector09 {
  top: 2.2457733154296875px;
  left: 31.41728401184082px;
  width: 5px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector10 {
  top: 2.2457733154296875px;
  left: 36.59986114501953px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector11 {
  top: 2.2457733154296875px;
  left: 44.04920959472656px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector12 {
  top: 2.2457733154296875px;
  left: 51.49855422973633px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector13 {
  top: 2.2457733154296875px;
  left: 59.596134185791016px;
  width: 11px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector14 {
  top: 2.2457733154296875px;
  left: 71.25611877441406px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector15 {
  top: 2.2457733154296875px;
  left: 79.0294418334961px;
  width: 7px;
  height: 8px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-group2 {
  top: 14.723709106445312px;
  left: 86.56906127929688px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-group3 {
  top: 0.8503341674804688px;
  left: 1.1170196533203125px;
  width: 1.4295578002929688px;
  height: 1.6991806030273438px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 1px;
  height: 2px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-group4 {
  top: 0px;
  left: 0px;
  width: 3.430938720703125px;
  height: 3.3983535766601562px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-img {
  top: 0px;
  left: 15.21243953704834px;
  width: 59.16004943847656px;
  height: 58.59792709350586px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-vector18 {
  top: 1.0317881107330322px;
  left: 0px;
  width: 21px;
  height: 46px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-vector19 {
  top: 0px;
  left: 18.748096466064453px;
  width: 40px;
  height: 59px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-group289066 {
  top: 64px;
  right: 10%;
  width: 146px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-line5-stroke {
  top: 14px;
  left: 29px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-line5-stroke1 {
  top: 14px;
  left: 87px;
  width: 30px;
  height: 2px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns1 {
  top: 0px;
  left: 58px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck1 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns2 {
  top: 0px;
  left: 116px;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck2 {
  top: 6.226739883422852px;
  left: 6.226355075836182px;
  width: 18px;
  height: 18px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-rectangle39 {
  top: 149px;
  width: 100%;
  height: 840px;
  position: absolute;
  border-radius: 20px;
}
.frame7-persona-formulario-lneadesgravamen-iconocorreo {
  top: 425px;
  width: 160px;
  height: 160px;
  display: flex;
  opacity: 0.90;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-icons8mail1 {
  top: 0px;
  left: 0px;
  width: 160px;
  height: 160px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-text02 {
  top: 390px;
  color: var(--dl-color-colores_neutrales-90);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 103.49999666213989%;
  font-stretch: normal;
  text-decoration: none;
}
.frame7-persona-formulario-lneadesgravamen-text04 {
  top: 245px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 26px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 104%;
  font-stretch: normal;
  text-decoration: none;
  width: 300px;
}
.frame7-persona-formulario-lneadesgravamen-usosdebotones {
  top: 890px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
}
.frame7-persona-formulario-lneadesgravamen-usosdebotones1 {
  gap: 10px;
  width: 220px;
  display: flex;
  padding: 14px;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 100px;
  justify-content: center;
  /* background-color: var(--dl-color-colores-elnaranjodejuanito); */
}
.frame7-persona-formulario-lneadesgravamen-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame7-persona-formulario-lneadesgravamen-group289079 {
  top: 624px;
  width: 264px;
  height: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.frame7-persona-formulario-lneadesgravamen-rectangle2439 {
  top: 105px;
  left: 0px;
  width: 100%;
  height: auto;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-rectangle395 {
  top: 0px;
  width: 100%;
  height: 105px;
  position: absolute;
  border-radius: 10px 10px 0 0;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns3 {
  top: 167px;
  left: 112px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-text11 {
  top: 114px;
  left: 29px;
  color: var(--dl-color-colores_neutrales-white);
  height: auto;
  position: absolute;
  font-size: 18px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 19px;
  font-stretch: normal;
  text-decoration: none;
}
.frame7-persona-formulario-lneadesgravamen-text16 {
  top: 590px;
  color: var(--dl-color-colores_neutrales-90);
  width: 228px;
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: 80.0000011920929%;
  font-stretch: normal;
  text-decoration: none;
}
.frame7-persona-formulario-lneadesgravamen-text17 {
  font-weight: 600;
  text-decoration: NONE;
}
.frame7-persona-formulario-lneadesgravamen-icon-btns4 {
  top: 185px;
  width: 42px;
  height: 42px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame7-persona-formulario-lneadesgravamen-ellipse43 {
  top: 0px;
  left: 0px;
  width: 42px;
  height: 42px;
  position: absolute;
}
.frame7-persona-formulario-lneadesgravamen-icon-outlinecheck3 {
  top: 8.717435836791992px;
  left: 8.716897010803223px;
  width: 25px;
  height: 25px;
  position: absolute;
}
</style>
