<template>
    <v-row class="colcenter ">

        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="cuadros">
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div
                    class="su-portabilidad-ya-ha-sido-registrada-revisaremos-los-antecedentes-que-adjunt-y-nos-contactaremos-v-a-ws-desde-mueve-seguro-nro-9999999-para-informar-los-avances-de-su-portabilidad"
                    >
                    Su portabilidad ya ha sido registrada
                    <br />
                    <br />
                    Revisaremos los antecedentes que adjuntó y nos contactaremos vía WS desde
                    MueveSeguro al número
                    <br />
                    {{ celular }}, para informar los avances
                    <br />
                    de su portabilidad
                    </div>
                </v-col>
                
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="line-1"></div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
            <div class="frame-32-2">
                <v-row class="colcenter">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                        <v-btn class="sm-size" id="btn-finalizar" rounded x-large @click="finalizar" color="#1d2eff"
                        ><span><span style="color:white">CONTINUAR</span></span></v-btn>
                    </v-col>
                </v-row>

            </div>
        </v-col>

    </v-row>

</template>
<script>

export default {
    name: "Felicitaciones",
    components: {

    },
    data() {
        return {

        }
    },
    props : {
        celular : ""
    },
    methods: {
        finalizar() {
            this.$store.commit("setselectmenu", 3);
            this.$router.push("/mi-cuenta");
        },
    }
}
</script>
<style scoped>
.colcenter {
    display: flex;
    justify-content: center;
}

.frame-32-2 {
    /* background: #d9d9d9; */
    border-radius: 80px;
    padding: 9px 20px 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}
.su-portabilidad-ya-ha-sido-registrada-revisaremos-los-antecedentes-que-adjunt-y-nos-contactaremos-v-a-ws-desde-mueve-seguro-nro-9999999-para-informar-los-avances-de-su-portabilidad {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 28px);
  line-height: var(--headings-h4-line-height, 34px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
    .sm-size {
        font-size: small !important;
    }

    .base-image-input {
        height: 150px !important;
    }


}
</style>