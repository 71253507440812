<template>
    <div class="frame21persona-formulario-lneadesgravamen-imputformularioe1">

        <div>
            
                <v-form ref="form" v-model="valid" >
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="forme1.rut" :rules="rules" label="Rut empresa" required
                                    filled dense  @update:error="vrut" @keypress="vrutp()" color="rgb(56,0,151)">
                                    <template v-slot:append>
                                        <div v-if="validrut==`no`"></div>
                                        <v-icon v-else-if="!validrut" color="rgba(0, 23, 135, 1)"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validrut" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="forme1.nombre1" :rules="nombre1Rules" label="Nombre de la empresa"
                                    required filled dense  color="rgb(56,0,151)" @update:error="vnombre1" @keypress="vnombre1p()">
                                    <template v-slot:append>
                                        <div v-if="validnombre1==`no`"></div>
                                        <v-icon v-else-if="!validnombre1" color="rgba(0, 23, 135, 1)" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre1" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                           
                            <v-col cols="12" sm="12">
                            <v-text-field v-model="forme1.telefono"  :rules="telefonoRules" label="Telefono" required filled dense
                                 append-icon="mdi-check-circle" class="w100"  color="rgb(56,0,151)" @update:error="vtelefono" @keypress="vtelefonop()">
                                    <template v-slot:append>
                                     <div v-if="validtelefono==`no`"></div>
                                        <v-icon v-else-if="!validtelefono" color="rgba(0, 23, 135, 1)" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validtelefono" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                            <v-text-field v-model="forme1.email" :rules="emailRules" label="Correo" required filled dense 
                                append-icon="mdi-check-circle" class="w100" color="rgb(56,0,151)" @update:error="vemail" @keypress="vemailp()">
                                    <template v-slot:append>
                                    <div v-if="validemail==`no`"></div>
                                    <v-icon  color="rgba(0, 23, 135, 1)" v-else-if="!validemail" 
                                        style="margin-bottom: 10px !important ;">
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    <v-icon color="red" v-else="validemail"
                                    style="margin-bottom: 10px !important ;">
                                    mdi-close-circle
                                    </v-icon>
                                    </template>
                            </v-text-field>
                            </v-col>

                            <!-- <v-dialog v-model="antessalir" width="740px" hide-overlay>
            <template v-slot:activator="{ on }"> -->
                            <v-btn color="grey darken-1 " fab x-large style="left: -200px;top:-41px" @click="volver">
                                <v-icon color="rgba(255, 255, 255)" size="70">
                                    mdi-arrow-left
                                </v-icon>
                            </v-btn>
                            <!-- </template>
            <AntesSalir style="overflow-y:hidden !important" v-bind:origen="`1`"></AntesSalir>
          </v-dialog> -->


                            <v-btn class="mx-2" color="rgba(113, 48, 237, 1)" fab x-large style="left: 714px;top: -41px;"
                                @click="validate" :disabled="!valid">
                                <v-icon color="rgba(255, 255, 255)" size="70">
                                    mdi-arrow-right
                                </v-icon>
                                <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>


           

        </div>
        <v-overlay v-if="overlay" :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </div>
</template>
<script>
import datausuario from "../../../../services/usuario-datos";

export default {
    name: "FormularioLinea",
    components: {
       
    },
    data() {
        return {
            overlay: false,
            validrut: "no",
            validnombre1: "no",
            validemail: "no",
            validtelefono:"no",
            forme1: {
                rut: this.$store.state.forme1.rut,
                nombre1: this.$store.state.forme1.nombre1,
                telefono: this.$store.state.forme1.telefono,
                email: this.$store.state.forme1.email,
            },
            antessalir: "",
            valid: false,
            nombre1Rules: [
                (v) => !!v || "el nombre es requerido",
                (v) => (v && v.length <= 200) || "largo máximo 200 caracteres",
                (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
                (v) => (v && this.validarLn1(v)) || "este campo solo acepta letras",
            ],
            emailRules: [
                v => !!v || 'el campo Correo es requerido',
                v => /.+@.+\..+/.test(v) || 'Ingrese un correo válido',
                (v) => (this.validaMail(v)) || "largo mínimo de dominio 3 caracteres",
            ],
            telefonoRules: [
                (v) => (this.formatPhoneNumber(v)) || "formato inválido",
                (v) => !!v || "el campo Telefono es requerido",
                (v) => (v && v.length == 15) || "largo máximo 9 caracteres",
            ],
            select: null,
            imgfondo1: "/playground_assets/rectangle3829484-upr6.svg",
            imgfondo2: "/playground_assets/rectangle3829484-upr6.svg"
        };
    },
    methods: {
        validarLn1(){
        this.forme1.nombre1=this.forme1.nombre1.replace(/[^a-zA-Z\s]/gi,"");
        this.forme1.nombre1=this.forme1.nombre1.replace("  "," ");
        return true;
    },
    formatPhoneNumber(phone)  {
      if(phone && phone!=""){
        if(phone && phone.length>15){
        phone=phone.substring(0, phone.length - 1);
      } 
      let celular="(+56) ";
      if(phone.length<=5){
        this.forme1.telefono=celular;
        return;
      }
      phone=phone.replace("(+56) ","");      
      let letra="";
      for (let index = 0; index < phone.length; index++) {
        letra=phone.charAt(index).replace(/[^0-9]/gi,"");
          celular=celular + letra;
      }
      this.forme1.telefono=celular;
      
    }
    return true;
  },
    validaMail(email){
      if(!email || email==""){
        return;
      }
      var revmail=email.split("@");
      if(revmail.length==2){
          if(revmail[1].length>=5){
            return true;
          }
          return false;
      }
    //   var revmail=email.split("@");
    //   if(revmail.length==2){
    //     var dominio=revmail[1].split(".");
    //     if(dominio.length==2){
    //       if(dominio[0].length>=3){
    //         return true;
    //       }
    //       return false;
    //   }
    //  }
    },
    vrutp(v) {
        if(this.validrut=="no"){ 
            this.validrut=true;
        }
    },
    vnombre1p(v){
        if(this.validnombre1=="no"){ 
            this.validnombre1=true;
        }
    },
    vtelefonop(v){
        if(this.validtelefono=="no"){ 
            this.validtelefono=true;
        }
    },
    vemailp(v){
        if(this.validemail=="no"){ 
            this.validemail=true;
        }
    },
    vrut(v) {
      this.validrut = v;
      console.log(v);
      if(!v){
        if(this.forme1.rut.length>=11 && this.forme1.rut.length<=12){
                this.buscar(this.forme1.rut)
        }
        
      }
    },
        vnombre1(v) {
            this.validnombre1 = v;

        },
        vtelefono(v) {
            this.validtelefono = v;

        },
        vemail(v) {
            this.validemail = v;

        },
       
        limpiar(){
            var form = {
                rut : "",
                nombre1: "",
                telefono: "",
                email: "",
            }
      this.form1=form;
      var form2 = {
        email: "",
        celular: "",
        date: "",
      }
      this.form2=form2;
      this.$store.commit('setforme1', form);
      this.$store.commit('setforme2', form2);
      this.validrut=true;
      this.validnombre1=true;
      this.validtelefono=true;
      this.validemail=true;
      this.$refs.form.reset();
    },
    async buscar(data) {
    if(data.length>=11 && data.length<=12){
      document.activeElement.blur();
      this.overlay =true;
      let res = false;
      data = data.replace(/\./g, "");
      res = await datausuario.traeusuario(data);
      console.log(res);
      if(res.status==200 && res.data.estadoTransaccion.success){
      var form = {
        rut : res.data.datosPersona.per_rut+"-"+res.data.datosPersona.per_dv,
        nombre1: res.data.datosPersona.per_nombre_principal,
        telefono: res.data.datosPersona.per_fono,
        email: res.data.datosPersona.per_email,
      }
      this.forme1=form;
      var form2 = {
        email: res.data.datosPersona.per_email,
        celular: res.data.datosPersona.per_cel,
        date: res.data.datosPersona.per_fec_nac,
      }
      this.form2=form2;
      this.$store.commit('setforme1', form);
      this.$store.commit('setforme2', form2);
      this.$store.commit('setestadocliente', res.data.estadoCliente);
      this.$root.$refs.FormularioLinea.clienteEncontrado=res.data.estadoCliente.tiene_linea_desgravamen;
      if(res.data.datosPersona.per_tipo_cliente==1){
        this.$alertas("El usuario existe en el sistema","info");
      }else{
        this.$alertas("Empresa existe en el sistema","info");
      }
      this.$store.commit('setclienteencontrado', true);
      this.$root.$refs.FormularioLinea.clienteEncontrado=true;
    //   this.$root.$refs.PreaprobacionLinea.clienteExiste =true;
     } else{
       this.limpiar();
    //    this.$root.$refs.LineaForm1.limpiar(false);
      this.$store.commit('setclienteencontrado', false);
      this.$root.$refs.FormularioLinea.clienteEncontrado=false;
    //   this.$root.$refs.PreaprobacionLinea.clienteExiste =false;
      this.$alertas("Usuario nuevo, ¡ ingrese sus datos !","success");
     }
      
      this.overlayBuscar();
    }
    },
    limpiar(){
 
        var form = {
        rut : this.forme1.rut,
        nombre1: "",
        telefono: "",
        email: "",
      }
    //   this.forme1=form;
      var form2 = {
        email: "",
        celular: "",
        date: "",
      }
    //   this.form2=form2;
      this.$store.commit('setforme1', form);
      this.$store.commit('setforme2', form2);
      this.$store.commit('setestadocliente', false);
      this.$store.commit('setclienteencontrado', false);
      this.$root.$refs.FormularioLinea.clienteEncontrado=0;
      
    },
    formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.forme1.rut=rutPuntos;
        }
        ,
        validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.forme1.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
          
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        btnleft: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.add("boton-left");
            }, 150);
        },
        btnleftout: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.remove("boton-left");
            }, 150);
        },
        btnright: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.add("boton-right");
            }, 150);
        },
        btnrightout: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.remove("boton-right");
            }, 150);
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$store.commit('setforme1', this.forme1);
                this.$root.$refs.FormularioLinea.estadoFormLinea = 2;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        volver() {
            this.$router.push('crear-degravamen');
        },
        hover: function (name, img) {
            if (img == 1) {
                this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg"
            } else {
                this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg"
            }

        },
        hoverout: function (name, img) {
            if (img == 1) {
                this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg"
            } else {
                this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg"
            }

        },
        overlayBuscar() {
        setTimeout(() => {
          this.overlay = false
        //   if(this.$store.state.clienteEncontrado){
        //     this.$root.$refs.FormularioLinea.estadoFormLinea = 7;
        // };
        }, 1000)
      },
      largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.forme1.rut=rut.replace(/[^kK0-9.-]/gi,"");
      return true;
    },
    },
    computed: {
        rules() {
            const rules = [];
            let rule="";
            rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut empresa es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.forme1.rut) || `rut inválido`;
            if(this.forme1.rut!=""){
            this.formateaRut(this.forme1.rut);
            }
            rules.push(rule);
            return rules;
        },
    },
    mounted() {
        // this.validrut = this.forme1.rut.length ? false : true;
        // this.validnombre1 = this.forme1.nombre1.length ? false : true;
        // this.validtelefono =  true;
        // this.validemail = true;
    },
    created() {
        this.$root.$refs.LineaForme1 = this;
    },
    metaInfo: {
        title: 'Mueve Seguro',
    },
};
</script>
<style scoped>
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}
.frame21persona-formulario-lneadesgravamen-imputformularioe1 {
  top: 410px;
  /* left: 457px; */
  width: 700px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  margin-left: -31px;
}
</style>
