import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true
};
import alertas from "./alertas";
alertas(Vue);
Vue.use(Toast, options);