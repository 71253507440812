<template>
    <div class="frame75persona-cotizarsegurode-desgravamen-card-active-inactive " >
        <div >
        <img src="/playground_assets/seguros/cotizacion/rectangle400i1251-2325-500h.png" alt="Rectangle400I1251"
            class="frame75persona-cotizarsegurode-desgravamen-rectangle400" />
        <div class="frame75persona-cotizarsegurode-desgravamen-logo-v1"></div>
        <!-- <img
            src="/playground_assets/seguros/cotizacion/rectangle2371i1251-718.svg"
            alt="Rectangle2371I1251"
            class="frame75persona-cotizarsegurode-desgravamen-rectangle2371"
          /> -->
        <div class="frame75persona-cotizarsegurode-desgravamen-rectangle2371" ref="cab"></div>
        <div class="frame75persona-cotizarsegurode-desgravamen-group288884">
            <span class="frame75persona-cotizarsegurode-desgravamen-text06">
                <span>
                    <span>
                        Tu ahorro es de
                    </span>
                    <br />
                    <span>{{segurosLamina.spl_sim_compara_ahorro}}</span>
                </span>
            </span>
            <img
            style="margin-left: 153px;"
            src="/playground_assets/seguros/cotizacion/pig.svg"
            alt="Rectangle2371I1251"
          />
        </div>
        <span class="frame75persona-cotizarsegurode-desgravamen-text11">
            <span>{{segurosLamina.spl_sim_monto_prima_cl}}</span>
        </span>
        <span class="frame75persona-cotizarsegurode-desgravamen-text13">
            <span>{{segurosLamina.spl_sim_monto_prima_uf}}</span>
        </span>
        <span class="frame75persona-cotizarsegurode-desgravamen-text15">
            <span>Conoce nuestros beneficios</span>
        </span>
        <span class="frame75persona-cotizarsegurode-desgravamen-text17">
            <span>
                <span>{{segurosLamina.beneficios[0].pbe_glosa}}</span>
                <br />
                <span>{{segurosLamina.beneficios[1].pbe_glosa}}</span>
                <br />
                <span>{{segurosLamina.beneficios[2].pbe_glosa}}</span>
            </span>
        </span>
       </div>
       <span class="frame75persona-cotizarsegurode-desgravamen-text24-2">
            <span style="margin-left: 40px;">Personaliza</span>
            <br>
            <span>Asistencia y Coberturas</span>
        </span>
        <span @click="terminos(segurosLamina.spl_pln_numero)"
        @mouseover="hover(`bnt2`+segurosLamina.spl_numero)"
        @mouseout="hoverout((`bnt2`+segurosLamina.spl_numero))"
         :class="`frame75persona-cotizarsegurode-desgravamen-text24 bnt2`+segurosLamina.spl_numero">
            <span>Términos y condiciones</span>
        </span>
        <div class="frame75persona-cotizarsegurode-desgravamen-group288885">
            <span class="frame75persona-cotizarsegurode-desgravamen-text26">
                <span>Comparar</span>
            </span>
            <div class="frame75persona-cotizarsegurode-desgravamen-icon-switch-o-n-o-f-f">
                <div class="frame75persona-cotizarsegurode-desgravamen-group7">
                    <!-- <v-app class="ctrans"> -->
                        <v-switch color="rgba(113, 48, 237, 1)" style="top:-23px;position:relative" v-model="segurosLamina.comparar" @click="selectComparar"></v-switch>
                    <!-- </v-app> -->
                </div>
            </div>
        </div>
        <div @click="selectSeguro">
            <img src="/playground_assets/seguros/cotizacion/line10i1251-aqj8.svg" alt="Line10I1251"
                class="frame75persona-cotizarsegurode-desgravamen-line10" />
            <img ref="botonselect" src="/playground_assets/seguros/cotizacion/rectangle2389i1251-15eb-300w.png"
                alt="Rectangle2389I1251" class="frame75persona-cotizarsegurode-desgravamen-rectangle2389" />
            <span class="frame75persona-cotizarsegurode-desgravamen-text28">
                <span>Seleccionar</span>
            </span>
            <div class="frame75persona-cotizarsegurode-desgravamen-radiobtnaceptar">
                <img ref="circulo" src="/playground_assets/seguros/cotizacion/ellipse288i1251-fbk4l-200h.png"
                    alt="Ellipse288I1251" class="frame75persona-cotizarsegurode-desgravamen-ellipse288" />

            </div>
            <img src="/playground_assets/seguros/cotizacion/vector1549-1bqi.svg" alt="Vector1549"
                class="frame75persona-cotizarsegurode-desgravamen-vector31" />
        </div>
       
    </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
export default {
    name: 'Frame75PersonaCotizarsegurodeDesgravamen',
    components: {

    },
    data() {
        return {
            selectLaminaSeguro: false,
            switch1: true,
        }
    },
    props : {
        id : 0,
        segurosLamina : 
      {
        ahorro: "",
        monto : "",
        uf : "",
        glosa1 : "",
        glosa2 : "",
        glosa3 : "",
        seleccion : false,
        comparar : false,
        terminos : ""
      },
    },
    methods: {
    hover: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover-txt");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover-txt");
    },
      async  terminos(plan){
            
            this.$root.$refs.ListarPropuesta.overlay=true;
            var res=false
            res = await datausuario.traeplanSimulacion(plan);
            
            if(res.status==200 && res.data.estadoTransaccion.success){
            console.log("entraaaa");
            this.$root.$refs.TerminosSeguro.titulo=res.data.terminosPlan.tco_titulo;
            this.$root.$refs.TerminosSeguro.sub_titulo=res.data.terminosPlan.tco_sub_titulo;
            this.$root.$refs.TerminosSeguro.texto=res.data.terminosPlan.tco_texto;
            this.$root.$refs.TerminosSeguro.privado=true;
            this.$store.commit("setestadoFormCotizacion",6);
            this.$root.$refs.CotizaForm.estadoFormCotizacion = 6;
            }
            this.$root.$refs.ListarPropuesta.overlay=false;
        },
        selectComparar (){
            
            this.segurosLamina.comparar = this.switch1;
            
            console.log(this.segurosLamina);           
        },        
        selectSeguro() {
            if (this.segurosLamina.seleccion == false) {
               
                this.segurosLamina.seleccion = true;
                this.$root.$refs.ListarPropuesta.seleccionaSeguro(this.id,true);
                this.$refs.cab.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2371');
                this.$refs.cab.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2371-sel');
                this.$refs.circulo.classList.remove('frame75persona-cotizarsegurode-desgravamen-ellipse288');
                this.$refs.circulo.classList.add('frame75persona-cotizarsegurode-desgravamen-ellipse288-sel');
                this.$refs.botonselect.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2389');
                this.$refs.botonselect.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2389-sel');
            } else {
                this.segurosLamina.seleccion = false;
                this.$root.$refs.ListarPropuesta.seleccionaSeguro(this.id,false);
                this.$refs.cab.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2371');
                this.$refs.cab.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2371-sel');
                this.$refs.circulo.classList.add('frame75persona-cotizarsegurode-desgravamen-ellipse288');
                this.$refs.circulo.classList.remove('frame75persona-cotizarsegurode-desgravamen-ellipse288-sel');
                this.$refs.botonselect.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2389');
                this.$refs.botonselect.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2389-sel');
            }
            
        },
        selectSeguro2(seg) {
            if (seg == true) {              
                this.segurosLamina.seleccion = true;
                this.$refs.cab.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2371');
                this.$refs.cab.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2371-sel');
                this.$refs.circulo.classList.remove('frame75persona-cotizarsegurode-desgravamen-ellipse288');
                this.$refs.circulo.classList.add('frame75persona-cotizarsegurode-desgravamen-ellipse288-sel');
                this.$refs.botonselect.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2389');
                this.$refs.botonselect.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2389-sel');
            } else {            
                this.segurosLamina.seleccion = false;
                this.$refs.cab.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2371');
                this.$refs.cab.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2371-sel');
                this.$refs.circulo.classList.add('frame75persona-cotizarsegurode-desgravamen-ellipse288');
                this.$refs.circulo.classList.remove('frame75persona-cotizarsegurode-desgravamen-ellipse288-sel');
                this.$refs.botonselect.classList.add('frame75persona-cotizarsegurode-desgravamen-rectangle2389');
                this.$refs.botonselect.classList.remove('frame75persona-cotizarsegurode-desgravamen-rectangle2389-sel');
            }
            
        },
        
    },
    computed : {
       
    },
    created() {
        this.$parent.$on('update'+this.id, this.selectSeguro2);
  },
    metaInfo: {
        title: 'exported project',
    },
}
</script>
<style scoped>
.ctrans {
    background-color: transparent !important;
    height: 10% !important;
    flex: none !important;
}
.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.boton-hover-txt {
  color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.frame75persona-cotizarsegurode-desgravamen-card-active-inactive {
    top: 0px;
    /* left: 300px; */
    width: 255px;
    height: 420px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    cursor: pointer;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle400 {
    top: 0px;
    left: -16px;
    width: 286px;
    height: 470px;
    position: absolute;
    border-color: transparent;
    border-radius: 20px;
}

.frame75persona-cotizarsegurode-desgravamen-logo-v1 {
    top: 14px;
    left: 104px;
    width: 47px;
    height: 49px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle2371 {
    top: 0px;
    left: 0px;
    width: 255px;
    height: 77px;
    position: absolute;
    background-color: rgba(113, 48, 237, 1);
    border-radius: 20px 20px 0 0;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle2371-sel {
    top: 0px;
    left: 0px;
    width: 255px;
    height: 77px;
    position: absolute;
    background-color: rgba(255, 184, 78, 1);
    border-radius: 20px 20px 0 0;
}

.frame75persona-cotizarsegurode-desgravamen-group288884 {
    top: 20px;
    left: 25px;
    width: 144px;
    height: 42px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-text06 {
    color: var(--dl-color-gray-white);
    width: 144px;
    height: auto;
    position: absolute;
    font-size: 18px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: Roboto;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-text11 {
    top: 110px;
    /* left: 21px; */
    color: var(--dl-color-colores_neutrales-dark);
    height: auto;
    position: absolute;
    font-size: 50px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: Roboto;
    font-weight: 700;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    font-size: 29px;
    margin-left: 16%;
    margin-right: 8%;
}

.frame75persona-cotizarsegurode-desgravamen-text13 {
    top: 157px;
    /* left: 70px; */
    color: var(--dl-color-colores_neutrales-dark);
    height: auto;
    position: absolute;
    font-size: 32px;
    align-self: auto;
    font-style: Medium;
    text-align: left;
    font-family: Roboto;
    font-weight: 500;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    margin-left: 20%;
}

.frame75persona-cotizarsegurode-desgravamen-text15 {
    top: 205px;
    left: 21px;
    color: var(--dl-color-colores_neutrales-80);
    height: auto;
    position: absolute;
    font-size: 17px;
    align-self: auto;
    font-style: Medium;
    text-align: left;
    font-family: Roboto;
    font-weight: 500;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-text17 {
    top: 230px;
    left: 56px;
    color: var(--dl-color-colores_neutrales-80);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Roboto;
    font-weight: 400;
    line-height: var(--dl-size-size-xsmall);
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-text24 {
    top: 403px;
    left: 48px;
    color: var(--dl-color-colores_neutrales-80);
    height: auto;
    position: absolute;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Roboto;
    font-weight: 400;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    border-top: 2px solid darkgrey;
    padding-top: 10px;
}
.frame75persona-cotizarsegurode-desgravamen-text24-2 {
    top: 355px;
    left: 48px;
    color: var(--dl-color-colores_paleta-tonos_azul-40);
    height: auto;
    position: absolute;
    font-size: 15px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Roboto;
    font-weight: 400;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: underline;
    
}


.frame75persona-cotizarsegurode-desgravamen-group288885 {
    top: -32px;
    left: 110px;
    width: 135px;
    height: 20px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-text26 {
    top: 2px;
    color: var(--dl-color-colores_neutrales-80);
    height: auto;
    position: absolute;
    font-size: 17px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: Roboto;
    font-weight: 700;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-icon-switch-o-n-o-f-f {
    top: 0px;
    left: 101px;
    width: 34px;
    height: 20px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-group7 {
    top: 0px;
    left: 0px;
    width: 34px;
    height: 20px;
    display: flex;
    opacity: 0.38;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle {
    top: 3px;
    left: 0px;
    width: 34px;
    height: 14px;
    position: absolute;
    border-color: rgba(150, 150, 150, 1);
    border-radius: 7px;
}

.frame75persona-cotizarsegurode-desgravamen-oval {
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    position: absolute;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14000000059604645);
}

.frame75persona-cotizarsegurode-desgravamen-oval1 {
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    position: absolute;
}

.frame75persona-cotizarsegurode-desgravamen-line10 {
    top: 380px;
    left: 12px;
    width: 230px;
    height: 0px;
    position: absolute;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle2389 {
    top: 292px;
    left: 27px;
    width: 201px;
    height: 32px;
    position: absolute;
    border-color: rgba(113, 48, 237, 1);
    border-style: solid;
    border-width: 2.5px;
    border-radius: 100px;
}

.frame75persona-cotizarsegurode-desgravamen-rectangle2389-sel {
    top: 292px;
    left: 27px;
    width: 201px;
    height: 32px;
    position: absolute;
    border-color: rgba(255, 184, 78, 1);
    border-style: solid;
    border-width: 2.5px;
    border-radius: 100px;
}

.frame75persona-cotizarsegurode-desgravamen-text28 {
    top: 299px;
    left: 63px;
    color: var(--dl-color-colores_neutrales-dark);
    height: auto;
    position: absolute;
    font-size: 19px;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: Roboto;
    font-weight: 700;
    line-height: 94.9999988079071%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame75persona-cotizarsegurode-desgravamen-radiobtnaceptar {
    top: 300px;
    left: 195px;
    width: 17px;
    height: 17px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
}

.frame75persona-cotizarsegurode-desgravamen-ellipse288 {
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    position: absolute;
    border-color: rgba(255, 184, 78, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-round);
}

.frame75persona-cotizarsegurode-desgravamen-ellipse288-sel {
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    position: absolute;
    border-color: rgba(255, 184, 78, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-round);
    background-color: rgba(255, 184, 78, 1);
    ;
}
</style>