import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

export default {
  info(id) {
    return this.callApi(ENDPOINT_PATH + "InformacionLegal/"+id,"post");
  },
  traeinfo() {
    return this.callApi(ENDPOINT_PATH + "InformacionLegal/Listar","post");
  },
  traepfrecuente() {
    return this.callApi(ENDPOINT_PATH + "PreguntaFrecuente/Listar","post");
  },
  // traeAdjuntoPFrecuente(id) {   
  //   return this.callApi(ENDPOINT_PATH + "InformacionLegal/GetPdf/"+id,"download");
  // },
  guardar(info) {
    return this.callApi(ENDPOINT_PATH + "InformacionLegal", "post", info);
  },
  actualizar(id) {
    return this.callApi(ENDPOINT_PATH + "InformacionLegal/"+id, "put", info);
  },
  eliminar(id) {
    return this.callApi(ENDPOINT_PATH + "InformacionLegal/"+id, "delete", info);
  },

  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios.post(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios.post(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      } 
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      // if (method == "download"){
      //   const url = `http://52.201.11.248/Insurex.ldesgravamen.Ejecutivos/upload/Pdf/catalogo%20color%20y%20sabor%20PARA%20EMPRESAS.pdf`;
      //   return await axios({
      //     url: url,
      //     method: "GET",
      //     responseType: "blob", // importante
      //     onDownloadProgress: (progressEvent) => {
      //       var percentCompleted = Math.round((progressEvent.loaded * 100) /  progressEvent.total);
      //       console.log(percentCompleted)
      //     },
      //   }).then((response) => {
      //     const url = window.URL.createObjectURL(new  Blob([response.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "fileprueba.pdf");
      //     document.body.appendChild(link);
      //     link.click();
      //   });
      // }
    } catch (error) {
      return false;
    }
  },
  
};
