import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_API_DATOS;

console.log("api: " + ENDPOINT_PATH);
export default {


  traeSaldos(id) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SaldosLeer/"+id, "post");
  },
  traeContrato(rut) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/ContratosListar/"+rut, "post");
  },
  traeTerminos(plan) {
    return this.callApi(ENDPOINT_PATH + "PlanSeguro/ListarTerminos/"+plan, "post");
  },
  
  guardarsimulacion(usuario) {
    return this.callApi(ENDPOINT_PATH + "LineaDesgravamen/CrearPropuesta", "post",usuario);
  },
  traeSeguros(contrato) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/ListarPorContrato/"+contrato, "post");
  },
  traeSegurosRut(rut) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/ListarPorRut/"+rut, "post");
  },
  traeCotizaciones(contrato) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/ListarPorContrato/"+contrato, "post");
  },
  traeCotizacionesRut(rut) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/ListarPorRut/"+rut, "post");
  },
  traeDetalleSeguro(seguro) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/SeguroLeer/"+seguro, "post");
  },
  traeDetalleSimulacion(simulacion) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/PlanLeer/"+simulacion, "post");
  },
  traePlanSimulacion(simulacion) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/PlanesSimulacionListar/"+simulacion, "post");
  },
  eliminarPlanSimulacion(simulacion) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/SimulacionEliminar", "post",simulacion);
  },
  activarSeguro(seguro) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/SeguroActivar", "post",seguro);
  },
  activaCuenta(token) {
    return this.callApi(ENDPOINT_PATH + "Contrato/TokenLeer/"+token, "post");
  },
  editarPerfil(cuenta) {
    return this.callApi(ENDPOINT_PATH + "Contrato/PerfilGrabar","post",cuenta);
  },  
  editarPerfilPublico(cuenta) {
    return this.callApi(ENDPOINT_PATH + "Contrato/PerfilPublicoGrabar","post",cuenta);
  },  
  activarContrato(contrato) {
    return this.callApi(ENDPOINT_PATH + "Contrato/Activar","post",contrato);
  },
  traePerfil(contrato) {
    return this.callApi(ENDPOINT_PATH + "Contrato/PerfilLeer/"+contrato,"post");
  },
  recuperarPass(cuenta) {
    return this.callApi(ENDPOINT_PATH + "Login/ClaveRecuperar", "post",cuenta);
  },

  walletBancosListar() {
    return this.callApi(ENDPOINT_PATH + "Wallet/BancosListar","post");
  },
  walletTipoCuentasListar() {
    return this.callApi(ENDPOINT_PATH + "Wallet/TipoCuentasListar","post");
  },
  walletCuentaBancariaListar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/CuentaBancariaListar","post",contrato);
  },
  walletTransaccionesListar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/TransaccionesListar","post",contrato);
  },
  walletCuentaBancariaRegistrar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/CuentaBancariaRegistrar", "post",contrato);
  },
  walletCuentaBancariaEliminar(cuenta) {
    return this.callApi(ENDPOINT_PATH + "Wallet/CuentaBancariaEliminar", "post",cuenta);
  },
  walletCodigoGenerar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/CodigoGenerar", "post",contrato);
  },
  walletRetiroGrabar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/RetiroGrabar", "post",contrato);
  },
  walletRetiroAplicar(contrato) {
    return this.callApi(ENDPOINT_PATH + "Wallet/RetiroAplicar", "post",contrato);
  },
  walletPreguntasListar() {
    return this.callApi(ENDPOINT_PATH + "Wallet/PreguntasListar", "post");
  },
  confirmarTransaccion(data){
    return this.callApi(ENDPOINT_PATH + "Pagos/TransaccionConfirmar", "post",data);
  },
  confirmarTransaccion2(data){
    return this.callApi(ENDPOINT_PATH + "Pagos/KhipuVerificar", "post",data);
  },  
  cancelarTransaccion2(data){
    return this.callApi(ENDPOINT_PATH + "Pagos/KhipuCancel", "post",data);
  },  
  ingresaScrapping(data){
    return this.callApi(ENDPOINT_PATH + "Scrapping/Ingreso", "post",data);
  },
  traeDps(seguro){
    return this.callApi(ENDPOINT_PATH + "DPS/DpsSimple/"+seguro, "post");
  },
  guardarDps(data){
    return this.callApi(ENDPOINT_PATH + "DPS/DpsSimpleGrabar", "post",data);
  },
  aceptarSeguro(data){
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SeguroAceptar", "post",data);
  },
  preIncripcion(documento) {
    return this.callApi(ENDPOINT_PATH + "FirmaEcert/PreInscripcion", "post",documento);
  },
  Identificar(documento) {
    return this.callApi(ENDPOINT_PATH + "FirmaEcert/Identificar", "post",documento);
  },
  
  async callApi(ruta, method = false, data = false) {
    try {
      if (method == "post") {
        return await axios
          .post(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
                     
            if (error.response.statusText) {
             if(error.response.status == "401"){
              location.href="/";
              this.$router.push("/logout");
             }
              return { data: { success: false, message: error.response.statusText }, status: error.response.status };
            }
            return {
              data: { success: false, message: "Error no capturado" },
              status: 200,
            };
          });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios
          .put(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    } catch (error) {}
  },
};
