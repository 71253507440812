<template>
  <v-app>
    <v-container fluid style="background-color: white;">
      <v-layout row wrap>
        <v-flex color="primary" sm1 border style="border: 1px">
          <h1 style="display: none !important ">1</h1>
          <div class="frame2loginnormal-frame2loginnormal">
            <img
              src="/playground_assets/login/rectangulofondogradiente21186-mk8.svg"
              alt="RectanguloFondoGradiente21186"
              class="frame2loginnormal-rectangulo-fondo-gradiente2"
            />
            <img
              src="/playground_assets/login/rectangulofondogradiente21186-dh4d.svg"
              alt="RectanguloFondoGradiente21186"
              class="frame2loginnormal-rectangulo-fondo-gradiente21"
            />
            <!-- <img
              src="/playground_assets/login/line161186-64vo.svg"
              alt="Line161186"
              class="frame2loginnormal-line16"
            /> -->
          </div>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
          <div class="frame2loginnormal-group495">
            <div class="frame2loginnormal-group498">
              <div class="frame2loginnormal-group">
                <div class="frame2loginnormal-group1">
                  <img
                    src="/playground_assets/login/vector1865-ex43.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector"
                  />
                  <img
                    src="/playground_assets/login/vector1865-7if.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector01"
                  />
                  <img
                    src="/playground_assets/login/vector1865-36j9.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector02"
                  />
                  <img
                    src="/playground_assets/login/vector1865-t058.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector03"
                  />
                  <img
                    src="/playground_assets/login/vector1865-8lgd.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector04"
                  />
                </div>
                <div class="frame2loginnormal-group2">
                  <img
                    src="/playground_assets/login/vector1865-cy17.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector05"
                  />
                  <img
                    src="/playground_assets/login/vector1865-sw1h.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector06"
                  />
                  <img
                    src="/playground_assets/login/vector1865-6oij.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector07"
                  />
                  <img
                    src="/playground_assets/login/vector1865-pind.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector08"
                  />
                  <img
                    src="/playground_assets/login/vector1865-qus8.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector09"
                  />
                  <img
                    src="/playground_assets/login/vector1865-bo1ox.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector10"
                  />
                  <img
                    src="/playground_assets/login/vector1865-h1xb.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector11"
                  />
                  <img
                    src="/playground_assets/login/vector1865-w12l.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector12"
                  />
                  <img
                    src="/playground_assets/login/vector1865-z268.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector13"
                  />
                  <img
                    src="/playground_assets/login/vector1865-qb5.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector14"
                  />
                  <img
                    src="/playground_assets/login/vector1865-2tes.svg"
                    alt="Vector1865"
                    class="frame2loginnormal-vector15"
                  />
                </div>
                <div class="frame2loginnormal-group3">
                  <div class="frame2loginnormal-group4">
                    <img
                      src="/playground_assets/login/vector1865-e1nn.svg"
                      alt="Vector1865"
                      class="frame2loginnormal-vector16"
                    />
                  </div>
                  <div class="frame2loginnormal-group5">
                    <img
                      src="/playground_assets/login/vector1865-mvu.svg"
                      alt="Vector1865"
                      class="frame2loginnormal-vector17"
                    />
                  </div>
                </div>
              </div>
              <div class="frame2loginnormal-group6">
                <img
                  src="/playground_assets/login/vector1865-ivwo.svg"
                  alt="Vector1865"
                  class="frame2loginnormal-vector18"
                />
                <img
                  src="/playground_assets/login/vector1865-cx9v.svg"
                  alt="Vector1865"
                  class="frame2loginnormal-vector19"
                />
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <img
        src="/playground_assets/login/rectangle401186-wat5.svg"
        alt="Rectangle401186"
        class="frame2loginnormal-rectangle40"
      /> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex sm10>
          <h1 style="display: none !important ">2</h1>
          <div class="frame2loginnormal-rectangle40"></div>
          <span class="frame2loginnormal-text12">
            <span>Recuperación de contraseña</span>
          </span>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <img
                    src="/playground_assets/login/rectangle391186-j99t-700h.png"
                    alt="Rectangle391186"
                    class="frame2loginnormal-rectangle39" -->
          <!-- <div class="frame2loginnormal-rectangle39"></div> -->
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex sm10>
          <h1 style="display: none !important ">2</h1>
          <div class="frame2loginnormal-rectangle39"></div>
          <span class="frame2loginnormal-text14">
            <span class="frame2loginnormal-text15"
              >Por favor escriba su Rut,</span
            >
            <span>
              para recuperar contraseña.
             
            </span>
          </span>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <img
                    src="/playground_assets/login/rectangle391186-j99t-700h.png"
                    alt="Rectangle391186"
                    class="frame2loginnormal-rectangle39" -->
          <!-- <div class="frame2loginnormal-rectangle39"></div> -->
          
        </v-flex>
        <!-- <v-flex secondary sm1>
                    <h1 style="display:none !important ;">4</h1>
                    
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">5</h1>
                  
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">6</h1>
                  
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">7</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">8</h1>
                    
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">9</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">10</h1>

                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">11</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">12</h1>
                </v-flex> -->

        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
          <div class="frame2loginnormal-formtophome">
            <!-- <img
              src="/playground_assets/login/rectangle71186-a35-700w.png"
              alt="Rectangle71186"
              class="frame2loginnormal-rectangle7"
            /> -->
            <!-- <img
              src="/playground_assets/login/rectangle81186-6xh-700w.png"
              alt="Rectangle81186"
              class="frame2loginnormal-rectangle8"
            /> -->
          
              <v-form
                ref="form"
                v-model="valid"
                style="top: -18px;
                position: absolute;"    
              >
              
                <v-text-field v-model="rut" :rules="rules" label="Rut" required 
                                    @update:error="vrut" color="rgba(0, 23, 135, 1)" 
                                    outlined
                                    style="width: 680px;
                                    border-radius: 10px;"
                                    >
                                    <template v-slot:append>
                                        <div v-if="validrut==`no`"></div>
                                        <v-icon v-else-if="!validrut" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validrut" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                <!-- <v-text-field
                v-model="clave" 
                :rules="claveRules"
                @update:error="vclave"
                 label="Clave"
                 required
                 color="rgba(0, 23, 135, 1)"
                 outlined
                 style="width: 680px;border-radius: 10px;">
                       <template v-slot:append>
                                        <div v-if="validclave==`no`"></div>
                                        <v-icon v-else-if="!validclave" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validclave" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                        </template>
               </v-text-field> -->
               <v-text-field
               outlined
               required
               color="rgba(0, 23, 135, 1)"
               style="width: 680px;border-radius: 10px;"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="claveRules"
            @update:error="vclave"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Clave"
            @click:append="show1 = !show1"
          ></v-text-field>
            </v-form>
            
            <!-- <span class="frame2loginnormal-text08"><span>Rut:</span></span> -->
            <!-- <span class="frame2loginnormal-text10"><span>Clave</span></span> -->
          </div>
          <span class="frame2loginnormal-text17"
            ><span>Recuperar Clave</span></span
          >
          <span class="frame2loginnormal-text06"
            ><span>Crear Cuenta</span></span
          >
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
          <div class="frame2loginnormal-group289010">
            <img
              src="/playground_assets/login/rectangle3981186-eca.svg"
              alt="Rectangle3981186"
              class="frame2loginnormal-rectangle398"
            />
            <img
              src="/playground_assets/login/rectangle3991186-ur1q.svg"
              alt="Rectangle3991186"
              class="frame2loginnormal-rectangle399"
            />
            <img
              src="/playground_assets/login/rectangle4001186-gku8.svg"
              alt="Rectangle4001186"
              class="frame2loginnormal-rectangle400"
            />
            <div class="frame2loginnormal-group288893">
              <img
                src="/playground_assets/login/ellipse2941186-ljke-200w.png"
                alt="Ellipse2941186"
                class="frame2loginnormal-ellipse294"
              />
              <img
                src="/playground_assets/login/ellipse2951186-64g-200w.png"
                alt="Ellipse2951186"
                class="frame2loginnormal-ellipse295"
              />
              <img
                src="/playground_assets/login/ellipse2961186-p1va-200w.png"
                alt="Ellipse2961186"
                class="frame2loginnormal-ellipse296"
              />
              <img
                src="/playground_assets/login/ellipse2971186-bgfd-200w.png"
                alt="Ellipse2971186"
                class="frame2loginnormal-ellipse297"
              />
            </div>
            <span class="frame2loginnormal-text27">
              <span>
                <span>¡QUIERES COTIZAR TU</span>
                <br />
                <span>SEGURO DESGRAVAMEN?</span>
              </span>
            </span>
          </div>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
          <span class="frame2loginnormal-text19"
            ><span>¿Necesitas ayuda?</span></span
          >
          <span class="frame2loginnormal-text21">
            <span>Lorem Ipsum is simply</span>
          </span>
          <span class="frame2loginnormal-text23">
            <span>Lorem Ipsum is simply</span>
          </span>
          <span class="frame2loginnormal-text25">
            <span>Lorem Ipsum is simply</span>
          </span>
          <img
            src="/playground_assets/login/ellipse2911186-qs4r-200h.png"
            alt="Ellipse2911186"
            class="frame2loginnormal-ellipse291"
          />
          <img
            src="/playground_assets/login/ellipse2921186-behr-200h.png"
            alt="Ellipse2921186"
            class="frame2loginnormal-ellipse292"
          />
          <img
            src="/playground_assets/login/ellipse2931186-bjkk-200h.png"
            alt="Ellipse2931186"
            class="frame2loginnormal-ellipse293"
          />
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <div class="frame2loginnormal-group508"> -->
            
          
    <div class="frame75persona-cotizarsegurode-desgravamen-usosdebotones1">
      <v-btn
        color="rgba(255, 184, 78)"
        @click="validate"
        :disabled="!valid"
        class="frame75persona-cotizarsegurode-desgravamen-usosdebotones1"
        rounded
        x-large
        style="left: 0px; top: 0px; text-transform: unset !important"
      >
        <span class="frame75persona-cotizarsegurode-desgravamen-text02">
          <span>Ingresar</span>
        </span>
      </v-btn>
    </div>
       
      <!-- </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
          
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
          
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
          <!-- <span class="frame2loginnormal-text"><span>¿Necesitas ayuda?</span></span>
          <div class="frame2loginnormal-usosdebotones2">
          <span class="frame2loginnormal-text04"><span>Volver</span></span>
        </div> -->
        <div
      class="frame75persona-cotizarsegurode-desgravamen-usosdebotones"
      style="border-color: transparent"
    >
      <v-btn
        color="grey darken-1 "
        class="frame75persona-cotizarsegurode-desgravamen-usosdebotones"
        rounded
        x-large
        style="left: 0px; top: 0px; text-transform: unset !important"
        @click="volver"
      >
        <span class="frame75persona-cotizarsegurode-desgravamen-text">
          <span>Volver</span>
        </span>
      </v-btn>
    </div>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
    
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">13</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">14</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">15</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <!-- <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">16</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">2</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">3</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">4</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">5</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">6</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">7</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">8</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">9</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">10</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">11</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">12</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">17</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">2</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">3</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">4</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">5</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">6</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">7</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">8</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">9</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">10</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">11</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">12</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">18</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">2</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">3</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">4</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">5</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">6</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">7</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">8</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">9</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">10</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">11</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">12</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">19</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">2</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">3</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">4</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">5</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">6</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">7</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">8</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">9</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">10</h1>
                </v-flex>
                <v-flex color="primary" sm1>
                    <h1 style="display:none !important ;">11</h1>
                </v-flex>
                <v-flex secondary sm1>
                    <h1 style="display:none !important ;">12</h1>
                </v-flex> -->
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">20</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">21</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">1</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame498centro" id="cfunciona"><span data-v-fae5bece=""
                            class="frame1home-text026centro"><span data-v-fae5bece="">¿Cómo funciona?</span></span><span
                            data-v-fae5bece="" class="frame1home-text028centro"><span data-v-fae5bece=""> Lorem ipsum
                                dolor
                                sit amet, consectetur adipiscing elit, sed do.. </span></span></div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group459"><img data-v-fae5bece="" alt="Ellipse2791185"
                            src="/playground_assets/login/ellipse2791185-vgf3-200h.png" class="frame1home-ellipse279"><img
                            data-v-fae5bece="" alt="Vector1185" src="/playground_assets/login/vector1185-88a3.svg"
                            class="frame1home-vector"></div>
                    <div data-v-fae5bece="" class="frame1home-frame52">
                        <div data-v-fae5bece="" class="frame1home-frame51"><span data-v-fae5bece=""
                                class="frame1home-text010"><span data-v-fae5bece="">Regístrate</span></span>
                        </div><span data-v-fae5bece="" class="frame1home-text012"><span data-v-fae5bece="">Completa el
                                registro para que podamos aprobar tu
                                línea.</span></span>
                    </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
          <!-- <img data-v-fae5bece="" alt="ESCUDO11185" src="/playground_assets/login/escudo11185-i5n.svg"
                        class="frame1home-e-s-c-u-d-o1"> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group461"><img data-v-fae5bece="" alt="Ellipse2811185"
                            src="/playground_assets/login/ellipse2811185-0s4sp-200h.png" class="frame1home-ellipse281"><img
                            data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-t93r.svg"
                            class="frame1home-vector001"></div>
                    <div data-v-fae5bece="" class="frame1home-frame58">
                        <div data-v-fae5bece="" class="frame1home-frame511"><span data-v-fae5bece=""
                                class="frame1home-text014"><span data-v-fae5bece="">Contrátalo</span></span>
                        </div><span data-v-fae5bece="" class="frame1home-text016"><span data-v-fae5bece=""> El
                                comparador de prima te dará los resultados y podrás contratar el servicio.
                            </span></span>
                    </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group462"><img data-v-fae5bece="" alt="Ellipse2801185"
                            src="/playground_assets/ellipse2801185-mtsd-200h.png" class="frame1home-ellipse2801"><img
                            data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-7v4i.svg"
                            class="frame1home-vector003"></div>
                    <div data-v-fae5bece="" class="frame1home-frame59">
                        <div data-v-fae5bece="" class="frame1home-frame513"><span data-v-fae5bece=""
                                class="frame1home-text022"><span data-v-fae5bece="">Cotiza</span></span>
                        </div><span data-v-fae5bece="" class="frame1home-text024"><span data-v-fae5bece="">
                                Ingresa tus datos, el monto del crédito, cantidad de cuotas a pagar, periodos de
                                gracia y la institución donde se contrata el crédito. </span></span>
                    </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-group460"><img data-v-fae5bece="" alt="Ellipse2801185"
                            src="/playground_assets/ellipse2801185-61ag-200h.png" class="frame1home-ellipse280"><img
                            data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-02p8.svg"
                            class="frame1home-vector002"></div>
                    <div data-v-fae5bece="" class="frame1home-frame59abajo">
                        <div data-v-fae5bece="" class="frame1home-frame498"><span data-v-fae5bece=""
                                class="frame1home-text026"><span data-v-fae5bece="">Informate</span></span><span
                                data-v-fae5bece="" class="frame1home-text028"><span data-v-fae5bece="">
                                    Ingresa tus datos, el monto del crédito, cantidad de cuotas a pagar, periodos de
                                    gracia y la institución donde se contrata el crédito. </span></span>
                        </div>

                    </div> -->
          <!-- <div data-v-fae5bece="" class="frame1home-frame58">
                                <div data-v-fae5bece="" class="frame1home-frame511"><span data-v-fae5bece=""
                                        class="frame1home-text014"><span data-v-fae5bece="">Contrátalo</span></span>
                                </div><span data-v-fae5bece="" class="frame1home-text016"><span data-v-fae5bece=""> El
                                        comparador de prima te dará los resultados y podrás contratar el servicio.
                                    </span></span>
                            </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame499"><span data-v-fae5bece=""
                            class="frame1home-text103"><span data-v-fae5bece="">Información legal</span></span><span
                            data-v-fae5bece="" class="frame1home-text105"><span data-v-fae5bece=""><span
                                    data-v-fae5bece=""> Si quieres conocer más acerca de las leyes en las que se soporta
                                    nuestro seguro <span data-v-fae5bece=""> </span></span><br data-v-fae5bece=""><span
                                    data-v-fae5bece="">Desgravamen, haz clic en los links a
                                    continuación.</span></span></span></div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-cards1">
                        <div data-v-fae5bece="" class="frame1home-header">
                            <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout">
                                <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout"><span
                                        data-v-fae5bece="" class="frame1home-text085"><span data-v-fae5bece="">Circular
                                            2114 CMF</span></span><span data-v-fae5bece=""
                                        class="frame1home-text087"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                            tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                </div>
                            </div>
                        </div>
                        <div data-v-fae5bece="" class="frame1home-frame-image"><img data-v-fae5bece=""
                                alt="IMAGEImage1185" src="/playground_assets/imageimage1185-glt-400w.png"
                                class="frame1home-i-m-a-g-e-image"></div>
                        <div data-v-fae5bece="" class="frame1home-frame26"><span data-v-fae5bece=""
                                class="frame1home-text089"><span data-v-fae5bece="">Descargar</span></span></div>
                    </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame1imagetop-b-outlineda-resting">
                        <div data-v-fae5bece="" class="frame1home-header1">
                            <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout1">
                                <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout1"><span
                                        data-v-fae5bece="" class="frame1home-text091"><span data-v-fae5bece="">Circular
                                            114 CMF</span></span><span data-v-fae5bece=""
                                        class="frame1home-text093"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                            tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                </div>
                            </div>
                        </div>
                        <div data-v-fae5bece="" class="frame1home-frame-image1"><img data-v-fae5bece=""
                                alt="IMAGEImage1185" src="/playground_assets/tim-gouw-bwki71ap-y8-unsplash.png"
                                class="frame1home-i-m-a-g-e-image1"></div>
                        <div data-v-fae5bece="" class="frame1home-frame261"><span data-v-fae5bece=""
                                class="frame1home-text095"><span data-v-fae5bece="">Descargar</span></span></div>
                    </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-frame1imagetop-b-outlineda-resting1">
                        <div data-v-fae5bece="" class="frame1home-header2">
                            <div data-v-fae5bece="" class="frame1home-header-subheadand-avatar-autolayout2">
                                <div data-v-fae5bece="" class="frame1home-header-subhead-autolayout2"><span
                                        data-v-fae5bece="" class="frame1home-text097"><span data-v-fae5bece="">Ley
                                            24.302</span></span><span data-v-fae5bece=""
                                        class="frame1home-text099"><span data-v-fae5bece=""> Aquí podrás ver + info. de
                                            tu línea de desgravamen <span data-v-fae5bece=""> </span></span></span>
                                </div>
                            </div>
                        </div>
                        <div data-v-fae5bece="" class="frame1home-frame-image2"><img data-v-fae5bece=""
                                alt="IMAGEImage1185" src="/playground_assets/scott-graham-5fNmWej4tAA-unsplash.png"
                                class="frame1home-i-m-a-g-e-image2"></div>
                        <div data-v-fae5bece="" class="frame1home-frame262"><span data-v-fae5bece=""
                                class="frame1home-text101"><span data-v-fae5bece="">Descargar</span></span></div>
                    </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm12>
          <h1 style="display: none !important ">9</h1>
          <!-- <img data-v-fae5bece="" alt="unsplashhSeGxLjozs1185"  id="pfrecuente"
                        src="/playground_assets/unsplashhsegxljozs1185-t2sj.svg"
                        class="frame1home-unsplashh-se-gx-ljozs1" /> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
          <!-- <div class="frame1home-dropdownfinal">
                        <span class="frame1home-text156">
                            <span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                vivamus enim, ipsum iaculis ut aliquet tellus.
                            </span>
                        </span>
                        <img src="/playground_assets/vectori2771-hweo.svg" alt="VectorI2771"
                            class="frame1home-vector244" />
                    </div>
                    <div class="frame1home-dropdownfinal1">
                        <span class="frame1home-text158">
                            <span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                vivamus enim, ipsum iaculis ut aliquet tellus.
                            </span>
                        </span>
                        <img src="/playground_assets/vectori2771-buzu.svg" alt="VectorI2771"
                            class="frame1home-vector245" />
                    </div>
                    <div class="frame1home-dropdownfinal2">
                        <span class="frame1home-text160">
                            <span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                vivamus enim, ipsum iaculis ut aliquet tellus.
                            </span>
                        </span>
                        <img src="/playground_assets/vectori2771-nk7x.svg" alt="VectorI2771"
                            class="frame1home-vector246" />
                    </div>
                    <div class="frame1home-dropdownfinal3">
                        <span class="frame1home-text162">
                            <span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                                vivamus enim, ipsum iaculis ut aliquet tellus.
                            </span>
                        </span>
                        <img src="/playground_assets/vectori2771-hhg8.svg" alt="VectorI2771"
                            class="frame1home-vector247" />
                    </div> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
          <!-- <span class="frame1home-text030 FontProductH4">
        <span>Preguntas frecuentes</span>
      </span>
      <span class="frame1home-text032">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vivamus
          enim, ipsum
        </span>
      </span> -->
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
          <!-- <div data-v-fae5bece="" class="frame1home-logo-lineadesgravamen-footer">
                        <div data-v-fae5bece="" class="frame1home-txt1">
                            <div data-v-fae5bece="" class="frame1home-group018"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-k5i.svg" class="frame1home-vector046"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-3azb.svg"
                                    class="frame1home-vector047"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-ortd.svg" class="frame1home-vector048"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-nxwk.svg"
                                    class="frame1home-vector049"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-m7i.svg" class="frame1home-vector050"></div>
                            <div data-v-fae5bece="" class="frame1home-group019"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-tnsg.svg" class="frame1home-vector051"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-ro2h.svg"
                                    class="frame1home-vector052"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-uo0u.svg" class="frame1home-vector053"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-idob.svg"
                                    class="frame1home-vector054"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-kac.svg" class="frame1home-vector055"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-nm4.svg"
                                    class="frame1home-vector056"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-6lbk.svg" class="frame1home-vector057"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-8b3.svg"
                                    class="frame1home-vector058"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-j9x7.svg" class="frame1home-vector059"><img
                                    data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-9lvz8.svg"
                                    class="frame1home-vector060"><img data-v-fae5bece="" alt="Vector1185"
                                    src="/playground_assets/vector1185-4af7.svg" class="frame1home-vector061"></div>
                            <div data-v-fae5bece="" class="frame1home-group020">
                                <div data-v-fae5bece="" class="frame1home-group021"><img data-v-fae5bece=""
                                        alt="Vector1185" src="/playground_assets/vector1185-xtz.svg"
                                        class="frame1home-vector062"></div>
                                <div data-v-fae5bece="" class="frame1home-group022"><img data-v-fae5bece=""
                                        alt="Vector1185" src="/playground_assets/vector1185-3u1d.svg"
                                        class="frame1home-vector063"></div>
                            </div>
                        </div>
                        <div data-v-fae5bece="" class="frame1home-img1"><img data-v-fae5bece="" alt="Vector1185"
                                src="/playground_assets/vector1185-7hs.svg" class="frame1home-vector064"><img
                                data-v-fae5bece="" alt="Vector1185" src="/playground_assets/vector1185-21uq.svg"
                                class="frame1home-vector065"></div>
                    </div> -->
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">13</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">14</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">15</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">16</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">17</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">18</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">19</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">20</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">21</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">3</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">5</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">7</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">9</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none !important ">11</h1>
        </v-flex>
        <v-flex secondary sm1>
          <h1 style="display: none !important ">12</h1>
        </v-flex>
      </v-layout>
    </v-container>
    <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
  </v-app>
  
  
</template>
<script>
import datausuario from "../../../services/usuario-datos";
import privado from "../../../services/privado";

export default {
  name: "Frame2Loginnormal",

  data() {
    return {
      datosContrato: [],
      overlay: false,
      valid: false,
      rut: "",
      clave: "",
      validrut: "no",
      validclave: "no",
      claveRules: [
        v => !!v || 'el campo Clave es requerido',
        (v) => (v && v.length >= 4) || "largo mínimo 8 caracteres",
         
      ],
      show1: false,
      password: '',
        rulesPass: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
    };
  },
  
  methods :{

    validate() {
      this.overlay = true;
        if (this.$refs.form.validate()) {

              this.login();
          return;
              setTimeout(() => {
              this.overlay = false;
              this.$store.commit("setislogin",true);
              console.log(this.$store.state.tipoPersonaEmpresa);
              if(this.$store.state.tipoPersonaEmpresa=="inicio"){
                this.$router.push("/home");
              }else{
              this.$router.push("/formulario-cotizacion");
              this.$store.commit("setestadoFormCotizacion",6);
              }
            }, 1000);
        }
      
    },
    
   async login(){
    let res = false;
    let res2 = false;
    var vrut = this.rut.replace(/\./g, "");
    console.log(vrut);
    var  data={
	    acc_rut: vrut,
	    acc_clave: this.password
	    };
      res = await datausuario.login(data);
      if(res.status==200 && res.data.estadoTransaccion.success){
        this.$store.commit("setloginestado",res.data.loginEstado);
        this.$store.commit("setloginusuario",res.data.loginUsuario);

        localStorage.setItem('loginEstado', JSON.stringify(res.data.loginEstado));
        localStorage.setItem('loginUsuario', JSON.stringify(res.data.loginUsuario));


        res2 = await privado.traeContrato(vrut);
        
        if(res2.status==200){
          this.$store.commit("setdatoscontrato",res2.data.datosContrato);
          this.datosContrato=res2.data.datosContrato;
          localStorage.setItem('datosContrato', JSON.stringify(res2.data.datosContrato));
          
          if(this.datosContrato.length>1){
            this.$router.push("/login2");
            return;
          }
          
          if(this.datosContrato.length==1){
            if(this.datosContrato[0].ctt_tipo_cliente==2){
              this.datosContrato[0].per_fec_nac=this.parseDate(this.datosContrato[0].per_fec_nac);
              this.$router.push("/login2");
            return;
            }
          }
        }
        var loginUsuario=this.$store.state.loginUsuario;
        loginUsuario.contrato=this.datosContrato[0];
        this.$store.commit("setloginusuario",loginUsuario);
        localStorage.setItem('loginUsuario', JSON.stringify(loginUsuario));
        this.$store.commit("setislogin",true);
        if(this.$store.state.estadoFormCotizacion==5){
          this.$store.commit("setselectmenu",3);
        }
        this.$router.push("/mi-cuenta");
        // if(this.$store.state.tipoPersonaEmpresa=="inicio"){
        //         this.$router.push("/mi-cuenta");
        //       }else{
        //       this.$router.push("/formulario-cotizacion");
        //       this.$store.commit("setestadoFormCotizacion",6);
        //       }
      }else{
        this.$alertas("Usuario o clave inválida!","error");
        this.overlay = false;
        return;
      }
      console.log(res);
    },
         
      vrut(v) {
      this.validrut = v;

    },
      vclave(v) {
      this.validclave = v;
      },
    volver() {
      this.$router.push("/home");
      // this.$router.go(-1)
    },
    parseDate : function  (date) {
      this.dateCompara=date;
      if (!date) return null

       var arr= date.split('/'),
                day = arr[0],
                month =  arr[1],
                year =  arr[2];
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
            if(rutCompleto=="44444444-4"){
                return true;
            }
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        
      formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.rut=rutPuntos;
        },
        largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.rut=rut.replace(/[^kK0-9.-]/gi,"");
      
      return true;
    },
  },
  computed : {
    rules() {
            const rules = [];
            let rule="";
            rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.rut) || `rut inválido`;
            if(this.rut!=""){
            this.formateaRut(this.rut);
            }
            rules.push(rule);
            
            return rules;
        },
  },
  mounted() {
    if(!localStorage.getItem('loginEstado')){
      this.isLogin=false;
      this.$store.commit("setislogin",false);
     
    }else{
      // this.$router.push("/mi-cuenta");
    }
  },
  metaInfo: {
    title: "exported project",
  },
};
</script>

<style scoped>
*{
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}
html,body{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.container {
  width: 100%;
  max-width: 1923px;
  min-width: 1440px;
}

.frame2loginnormal-container {
  min-height: 100vh;
}
.frame2loginnormal-frame2loginnormall {
  width: 100%;
  height: 1025px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame2loginnormal-rectangulo-fondo-gradiente2 {
  top: -117px;
  /* left: 0px; */
  width: 1930px;
  height: 435px;
  position: absolute;
}
.frame2loginnormal-rectangulo-fondo-gradiente21 {
  top: -117px;
  /* left: 0px; */
  width: 1930px;
  height: 435px;
  position: absolute;
}
.frame2loginnormal-line16 {
  top: 998px;
  left: 0px;
  width: 1440px;
  height: 0px;
  position: absolute;
}
.frame2loginnormal-text {
  top: 899px;
  /* left: 1093px; */
  margin-left: 60px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-group508 {
  top: 868px;
  /* left: 162px; */
  width: 1116px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-usosdebotones {
    top: 836px;
  /* left: 875px; */
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame2loginnormal-usosdebotones1 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}
.frame2loginnormal-text02 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-usosdebotones2 {
  top: 836px;
  /* left: 0px; */
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
}
.frame2loginnormal-text04 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-line20 {
  top: 998px;
  left: 0px;
  width: 1440px;
  height: 0px;
  position: absolute;
}
.frame2loginnormal-rectangle39 {
  top: 196px;
  /* left: 150px; */
  /* width: 1144px; */
  background-color: white;
  height: 500px;
  position: relative;
  border-color: rgba(143, 150, 165, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 20px 20px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.frame2loginnormal-text06 {
  top: 571px;
  /* left: 570px; */
  margin-left: 430px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame2loginnormal-googlelogo {
  top: 566px;
  left: 367px;
  width: 22px;
  height: 22px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame2loginnormal-rectangle40 {
  top: 197px;
  /* left: 150px; */
  /* width: 1458px; */
  height: 122px;
  position: relative;
  border-color: rgba(143, 150, 165, 1);
  border-radius: 20px 20px 0 0;
  background-color: var(--dl-color-colores_paleta-tonos_morado-40);
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
}
.frame2loginnormal-formtophome {
  top: 432px;
  /* left: 198px; */
  margin-left: 80px;
  width: 680px;
  height: 123px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-rectangle7 {
  top: 0px;
  left: 0px;
  width: 680px;
  height: 51px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}
.frame2loginnormal-rectangle8 {
  top: 73px;
  left: 0px;
  width: 680px;
  height: 50px;
  position: absolute;
  border-color: rgba(94, 101, 117, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}
.frame2loginnormal-text08 {
  top: 16px;
  left: 29.9571533203125px;
  color: var(--dl-color-colores_neutrales-70);
  width: 232px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame2loginnormal-text10 {
  top: 91px;
  left: 29.9571533203125px;
  color: var(--dl-color-colores_neutrales-70);
  width: 165px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame2loginnormal-text12 {
  top: 248px;
  /* left: 198px; */
  margin-left: 80px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-text14 {
  top: 372px;
  /* left: 198px; */
  margin-left: 80px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-text15 {
  font-weight: 700;
}
.frame2loginnormal-text17 {
  top: 571px;
  /* left: 344px; */
  margin-left: 250px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
}
.frame2loginnormal-text19 {
  top: 394px;
  /* left: 1007px; */
  margin-left: 55px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 600;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-text21 {
  top: 440px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-text23 {
  top: 485px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-text25 {
  top: 530px;
  /* left: 1053px; */
  margin-left: 100px;
  color: var(--dl-color-colores_neutrales-40);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Regular;
  text-align: justified;
  font-family: Roboto;
  font-weight: 400;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame2loginnormal-ellipse291 {
  top: 434px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-ellipse292 {
  top: 477px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-ellipse293 {
  top: 520px;
  /* left: 1007px; */
  margin-left: 50px;
  width: 35px;
  height: 35px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-group289010 {
  top: 630.5px;
  /* left: 415px; */
  width: 610px;
  height: 155px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-rectangle398 {
  top: 0px;
  left: 177px;
  width: 433px;
  height: 155px;
  position: absolute;
}
.frame2loginnormal-rectangle399 {
  top: 0px;
  left: 0px;
  width: 177px;
  height: 155px;
  position: absolute;
}
.frame2loginnormal-rectangle400 {
  top: 0px;
  left: 0px;
  width: 177px;
  height: 155px;
  position: absolute;
}
.frame2loginnormal-group288893 {
  top: 124px;
  left: 327px;
  width: 133px;
  height: 13px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-ellipse294 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-ellipse295 {
  top: 0px;
  left: 40px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-ellipse296 {
  top: 0px;
  left: 80px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-ellipse297 {
  top: 0px;
  left: 120px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}
.frame2loginnormal-text27 {
  top: 35px;
  left: 244px;
  color: var(--dl-color-colores_paleta-tonos_azul-0);
  height: auto;
  position: absolute;
  font-size: 26px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  text-decoration: none;
}
.frame2loginnormal-group495 {
  top: 68px;
  /* left: 148px; */
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-group498 {
  top: 0px;
  left: 0px;
  width: 219.00050354003906px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-group {
  top: 12.904296875px;
  left: 90.333984375px;
  width: 128.66651916503906px;
  height: 41.209571838378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-group1 {
  top: 0.00005661231989506632px;
  left: 37.96873474121094px;
  width: 47.22975158691406px;
  height: 17.132549285888672px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-vector {
  top: 2.778263807296753px;
  left: -0.00001415307997376658px;
  width: 9px;
  height: 14px;
  position: absolute;
}
.frame2loginnormal-vector01 {
  top: 0.00005661231989506632px;
  left: 9.724743843078613px;
  width: 5px;
  height: 17px;
  position: absolute;
}
.frame2loginnormal-vector02 {
  top: 5.555908203125px;
  left: 15.279296875px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector03 {
  top: 5.5559539794921875px;
  left: 26.393468856811523px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector04 {
  top: 5.555908203125px;
  left: 37.505859375px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-group2 {
  top: 22.2257137298584px;
  left: 0.00007784193439874798px;
  width: 123.17034912109375px;
  height: 18.98410415649414px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-vector05 {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 15px;
  position: absolute;
}
.frame2loginnormal-vector06 {
  top: 3.24068021774292px;
  left: 12.502009391784668px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector07 {
  top: 3.24072265625px;
  left: 23.6162109375px;
  width: 9px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector08 {
  top: 3.24068021774292px;
  left: 33.33804702758789px;
  width: 10px;
  height: 16px;
  position: absolute;
}
.frame2loginnormal-vector09 {
  top: 3.24072265625px;
  left: 44.9150390625px;
  width: 7px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector10 {
  top: 3.24068021774292px;
  left: 52.32418441772461px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector11 {
  top: 3.24068021774292px;
  left: 62.9736328125px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector12 {
  top: 3.24068021774292px;
  left: 73.62411499023438px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector13 {
  top: 3.24072265625px;
  left: 85.2001953125px;
  width: 15px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector14 {
  top: 3.24068021774292px;
  left: 101.86904907226562px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-vector15 {
  top: 3.24068021774292px;
  left: 112.98342895507812px;
  width: 10px;
  height: 12px;
  position: absolute;
}
.frame2loginnormal-group3 {
  top: 21.24936294555664px;
  left: 123.76150512695312px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-group4 {
  top: 1.22881281375885px;
  left: 1.5965594053268433px;
  width: 2.0437254905700684px;
  height: 2.4524710178375244px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.frame2loginnormal-group5 {
  top: 0px;
  left: 0px;
  width: 4.904942035675049px;
  height: 4.904942035675049px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-vector17 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 5px;
  position: absolute;
}
.frame2loginnormal-group6 {
  top: 0px;
  left: 0px;
  width: 66.92005920410156px;
  height: 66.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame2loginnormal-vector18 {
  top: 1.1788524389266968px;
  left: 0px;
  width: 24px;
  height: 53px;
  position: absolute;
}
.frame2loginnormal-vector19 {
  top: 0px;
  left: 21.206945419311523px;
  width: 46px;
  height: 67px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-text {
  top: 14px;
  left: 14px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame75persona-cotizarsegurode-desgravamen-usosdebotones {
  top: 830px;
  /* left: 148px; */
  width: 220px;
  bottom: 31px;
  height: 23px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  margin-left: 0px;
}
.frame75persona-cotizarsegurode-desgravamen-usosdebotones1 {
  top: 830px;
  /* left: 1072px; */
  width: 250px;
  height: 23px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  margin-left: 0px;
}
.frame75persona-cotizarsegurode-desgravamen-text02 {
  position: relative;
  top: 14px;
  left: 4px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
