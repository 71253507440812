<template>
    <v-row class="colcenter simulaPago">

        <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter frame-60 ">
            <v-row>
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <div class="resumen-de-seguro">Resumen de Seguro</div>
                    <div class="este-es-el-resumen-del-seguro-que-estas-contratando">
                        Este es el resumen del seguro que estas contratando
                    </div>
                </v-col>
                <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                    <div class="colright">
                        <img class="vector6" src="assets/privado/simulacionpagar/vector5.svg">
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="11" sm="11" md="11" lg="8" xl="8" class="colcenter mb-5 frame-61">
            <v-row class="">
                <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="">
                    <v-row class="colcenter cuadro">
                        <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="colcenter">
                            <div class="fechadetalle fecha-inicio colcenter">
                                <span>
                                    <span class="fecha-inicio">
                                        Fecha inicio
                                        <br>
                                    </span>
                                    <span class="fecha-inicio-span2">{{ Seguro.spl_pln_fecha_inicio }}</span>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                            <div class="textoiz">Plan</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                            <div class="textoiz">Prima Única</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                            <div class="textoiz">Prima en Pesos</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colleft">
                            <div class="textoiz">Monto Asegurado</div>
                        </v-col>

                    </v-row>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="3" xl="3">
                    <v-row class="colcenter cuadro">
                        <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="colcenter">
                            <div class="fechadetalle fecha-inicio colcenter">
                                <span>
                                    <span class="fecha-inicio">
                                        Fecha Termino
                                        <br>
                                    </span>
                                    <span class="fecha-inicio-span2">{{ Seguro.spl_pln_fecha_termino }}</span>
                                </span>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colright">
                            <div class="textodr">Seguro Desgravamen</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colright">
                            <div class="textodr">{{ Seguro.spl_sim_monto_prima_uf }}</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colright">
                            <div class="textodr">{{ Seguro.spl_sim_monto_prima_cl }}</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colright">
                            <div class="textodr">{{ Seguro.spl_sim_saldo_insoluto }}</div>
                        </v-col>


                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">
                    <img class="image-2" src="assets/privado/simulacionpagar/image-20.png">
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="line-1"></div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <v-row class="colcenter" v-if="pagado == 1">
                        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter cuadrodatosiz">
                            <div class="datos-personales2">¡FELICIDADES!</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <div class="datosce">
                                        Tu seguro fue pagado exitosamente
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="colcenter" v-if="pagado == 2">
                        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter cuadrodatosiz">
                            <div class="datos-personales3">TRANSACCION RECHAZADA</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
                            <v-row class="colcenter">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                    <div class="datosce">
                                        pago rechazado
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="colcenter" v-if="!pagado">
                        <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="colcenter cuadrodatosiz">
                            <div class="datos-personales">DATOS PERSONALES</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="7" xl="7" class="">
                            <v-row class="colcenter">
                                <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                                    <div class="datosiz">Contratante:</div>
                                </v-col>
                                <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colcenter">
                                    <div class="datosdr">{{ Seguro.spl_nombre }}</div>
                                </v-col>
                                <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                                    <div class="datosiz">Rut:</div>
                                </v-col>
                                <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colcenter">
                                    <div class="datosdr">{{ Seguro.spl_rut }}</div>
                                </v-col>
                                <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                                    <div class="datosiz">Dirección:</div>
                                </v-col>
                                <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colcenter">
                                    <div class="datosdr">{{ Seguro.spl_calle + ` ` + Seguro.spl_nro + ` ` + (Seguro.spl_depto ? Seguro.spl_depto : '')
                                        + ` ` + Seguro.spl_region + ` ` + Seguro.spl_ciudad +` `+ Seguro.spl_comuna }}</div>
                                </v-col>
                                <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                                    <div class="datosiz">Celular:</div>
                                </v-col>
                                <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colcenter">
                                    <div class="datosdr">{{ Seguro.spl_celular }}</div>
                                </v-col>
                                <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="colcenter">
                                    <div class="datosiz">Correo Electrónico:</div>
                                </v-col>
                                <v-col cols="7" sm="7" md="7" lg="7" xl="7" class="colcenter" style="overflow-x: auto;">
                                    <div class="datosdr">{{ Seguro.spl_email }}</div>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="!pagado">
                    <div class="line-1"></div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter" v-if="!pagado">
                    <v-form ref="form" v-model="valid">
                    <v-checkbox v-model="acepta" :rules="respRules" required @update:error="vresp">
                        <template v-slot:label>
                            <div>
                                Acepto
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a class="color-termininos" target="_blank" @click="leerTerminos(Seguro.spl_seg_numero)"
                                            v-on="on">
                                            términos y condiciones
                                        </a>
                                    </template>
                                    abrir en nueva ventana
                                </v-tooltip>
                                de MueveSeguro
                            </div>
                        </template>
                    </v-checkbox>
                    </v-form>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter">

            <v-row class="colcenter">
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter" v-if="!pagado">
                    <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="volver"><span><span
                                style="color:white">VOLVER</span></span></v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter" v-if="pagado">
                    <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="volver1"><span><span
                                style="color:white">VOLVER</span></span></v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="colcenter" v-if="!pagado">
                    <v-btn class="sm-size" rounded x-large @click="pagar" :disabled="pagado != 0 || !valid"
                        color="#04137f"><span><span style="color:white;">PAGAR</span></span></v-btn>
                </v-col>
            </v-row>
        </v-col>

    </v-row>


</template>
<script>
import datausuario from "../../../../services/usuario-datos";
export default {
    name: "SimulacionSeguroPagar",
    components: {

    },
    data() {
        return {
            acepta: false,
            valid: false,
            respRules: [(v) => !!v || ""],
            validresp: true,

        };
    },
    props: {
        Seguro: {},
        pagado: 0
    },
    methods: {
        vresp(v) {
            this.validresp = v;
        },
        pagar() {
            this.$root.$refs.HomePrivado.cambiaMenu(15);
        },
        volver() {
            this.$root.$refs.HomePrivado.cambiaMenu(3);
        },
        volver1() {
            location.href = "/mi-cuenta";
        },
        async leerTerminos(seg_numero) {
            this.overlay = true;
            var res = false;
            var documento = false;
            var ext = "";
            var nombreDoc = "";
                      
            var seguro = {
                seg_numero: seg_numero
            }
            res = await datausuario.leerTerminos(seguro);

            if (res.status == 200 && res.data.estadoTransaccion.success) {
                // this.$alertas(res.data.estadoTransaccion.errors, "success");
                documento = res.data.cdo_docto;
                nombreDoc = res.data.cdo_nombre_docto;
                ext = res.data.cdo_extension.toLowerCase();

                const binaryString = atob(documento);
                const uint8Array = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: "application/pdf" });

                console.log(blob);
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = nombreDoc;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                this.overlay = false;
            } else {
                this.$alertas("En este momento no podemos atender su solicitud", "warning");
                this.overlay = false;
            }
        },


    },
    mounted () {
        window.scrollTo(0,0);  
    }
    // this.SeguroDetalle.numero = res.data.miSeguro.cse_numero;
    //             this.SeguroDetalle.poliza = res.data.miSeguro.cse_poliza;
    //             this.SeguroDetalle.transaccion = res.data.miSeguro.cse_transaccion;
    //             this.SeguroDetalle.inicio = res.data.miSeguro.cse_pln_fecha_inicio;
    //             this.SeguroDetalle.termino = res.data.miSeguro.cse_pln_fecha_termino;
    //             this.SeguroDetalle.celular = res.data.miSeguro.cse_celular;
    //             this.SeguroDetalle.email = res.data.miSeguro.cse_email;
    //             this.SeguroDetalle.direccion = res.data.miSeguro.cse_calle + " #" +
    //                 res.data.miSeguro.cse_nro + ", " + res.data.miSeguro.cse_depto + ", " +
    //                 res.data.miSeguro.cse_comuna + " " + res.data.miSeguro.cse_ciudad;
    //             this.SeguroDetalle.nombre = res.data.miSeguro.cse_nombre;
    //             this.SeguroDetalle.rut = res.data.miSeguro.cse_rut;
    //             this.SeguroDetalle.montoPeso = res.data.miSeguro.cse_sim_monto_prima_cl;
    //             this.SeguroDetalle.montoUf = res.data.miSeguro.cse_sim_monto_prima_uf;
    //             this.SeguroDetalle.montoAsegurado = res.data.miSeguro.cse_saldo_insoluto;
    //             this.SeguroDetalle.tipoInstitucion = res.data.miSeguro.cse_glosa_tipo_institucion;
    //             this.SeguroDetalle.nombreIntitucion = res.data.miSeguro.cse_glosa_institucion;
    //             this.SeguroDetalle.saldoInsoluto = res.data.miSeguro.cse_sim_monto_credito;
    //             this.SeguroDetalle.mesesPendientePago = res.data.miSeguro.cse_meses_pendiente;
    //             this.SeguroDetalle.periodoGracia = res.data.miSeguro.cse_periodo_gracia;
    //             this.SeguroDetalle.plazoCredito = res.data.miSeguro.cse_plazo_meses;
    //             this.SeguroDetalle.cantidadCuotas = res.data.miSeguro.cse_meses_pendiente;
    //             this.SeguroDetalle.spl_numero = res.data.miSeguro.spl_numero;
    //             this.SeguroDetalle.spl_seguro_numero = res.data.miSeguro.spl_seguro_numero;
    //             this.SeguroDetalle.spl_pln_numero = res.data.miSeguro.spl_pln_numero;
    //             this.SeguroDetalle.cse_numero = res.data.miSeguro.cse_numero;
    //             this.SeguroDetalle.cse_ctt_numero = res.data.miSeguro.cse_ctt_numero;
    //             this.SeguroDetalle.cse_seg_numero = res.data.miSeguro.cse_seg_numero;
    //             this.SeguroDetalle.cse_usu_credito_nuevo = res.data.miSeguro.cse_usu_credito_nuevo;
    //             this.overlay = false;

}
</script>
<style scoped>
.sm-size {
    width: 50%;
}

.cuadrodatosiz {
    align-self: center;
}

.divide {
    display: inline-flex;
}

.simulaPago {
    background-color: white;
}

.colcenter {
    display: flex;
    justify-content: center;
}

.colright {
    display: flex;
    justify-content: right;
}

.colleft {
    display: flex;
    justify-content: left;
}

.frame-60 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    padding: 20px 79px 20px 79px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    margin-top: -104px;
}

.frame-61 {
    background: var(--blanco, #f0f2f2);
    border-radius: 0px 0px 40px 40px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    /* padding: 40px; */
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.resumen-de-seguro {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1.5rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.este-es-el-resumen-del-seguro-que-estas-contratando {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.7rem, 4vw, 1.3rem);
    line-height: clamp(0.8rem, 4vw, 1.5rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vector6 {
    flex-shrink: 0;
    width: clamp(1.7rem, 4vw, 3.5rem);
    height: clamp(1.7rem, 4vw, 3.5rem);
    position: relative;
    overflow: visible;
}

.fecha-inicio {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    position: relative;
    font-size: clamp(0.5rem, 2vw, 0.8rem);
}

.fecha-inicio-span2 {
    color: var(--azul-complementario, #04137f);
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.7rem, 2vw, 1rem);
    line-height: clamp(0.8rem, 2vw, 1.2rem);
    font-weight: 900;
}

.fechadetalle {
    background: var(--azul-claro, #e8f3ff);
    border-radius: 100px;
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px;
    width: 100%;
    /* height: 69px; */
    position: relative;
    padding: 3px 20px 3px 20px;

}

.textoiz {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
    font-size: clamp(0.8rem, 4vw, 1rem);
    line-height: clamp(0.8rem, 4vw, 1rem);
    font-weight: var(--headings-h4-font-weight, 400);
    position: relative;
}

.textodr {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    font-size: clamp(0.8rem, 4vw, 1rem);
    line-height: clamp(0.8rem, 4vw, 1rem);
    font-weight: 900;
    position: relative;
}

.cuadro {
    padding: 20px;
    margin-top: 5px;
}

.image-2 {
    margin-top: 5px;
    border-radius: 40px;
    flex-shrink: 0;
    width: 80%;
    /* height: 418px; */
    position: relative;
    object-fit: cover;
}

.datos-personales {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.datos-personales2 {
    color: var(--azul-complementario, #15711f);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.datos-personales3 {
    color: var(--azul-complementario, #9a2020);
    text-align: center;
    font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
    /* font-size: var(--headings-h2-font-size, 48px);
    line-height: var(--headings-h2-line-height, 60px); */
    font-size: clamp(1rem, 4vw, 2.1rem);
    line-height: clamp(1rem, 4vw, 2.1rem);
    font-weight: var(--headings-h2-font-weight, 700);
    position: relative;
    width: 100%;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}

.datosiz {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 24px;
    line-height: 28px; */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: 900;
    position: relative;
    width: 100%;
    /* height: 28px; */
}

.datosce {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: "CircularPro-Black", sans-serif;
    /* font-size: 24px;
    line-height: 28px; */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: 900;
    position: relative;
    width: 100%;
    /* height: 28px; */
}

.datosdr {
    color: var(--azul-complementario, #04137f);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px); */
    font-size: clamp(0.8rem, 4vw, 1.1rem);
    line-height: clamp(0.8rem, 4vw, 1.3rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .divide {
        display: none !important;
    }

    .sm-size {
        font-size: small !important;
        width: 100%;
    }

    .cuadro {
        padding: 0px !important;
    }
}
</style>