<template>
  <div class="frame750persona-cotizarsegurode-desgravamen-container">
   
      <div class="frame750persona-cotizarsegurode-desgravamen-group288926">
        <div class="frame750persona-cotizarsegurode-desgravamen-card-active-inactive">
          <img
            src="/playground_assets/seguros/cotizacion/rectangle400i1294-i9hd-500h.png"
            alt="Rectangle400I1294"
            class="frame750persona-cotizarsegurode-desgravamen-rectangle400"
          />
          <div class="frame750persona-cotizarsegurode-desgravamen-logo-v1"></div>
          <img
            src="/playground_assets/seguros/cotizacion/rectangle2371i1294-phn8.svg"
            alt="Rectangle2371I1294"
            class="frame750persona-cotizarsegurode-desgravamen-rectangle2371"
          />
          <div class="frame750persona-cotizarsegurode-desgravamen-group288885"></div>
          <img
            src="/playground_assets/seguros/cotizacion/line10i1294-ybv.svg"
            alt="Line10I1294"
            class="frame750persona-cotizarsegurode-desgravamen-line10"
          />
          <span class="frame750persona-cotizarsegurode-desgravamen-text6">
            <span></span>
          </span>
        </div>
        <div class="frame750persona-cotizarsegurode-desgravamen-card-costoactual">
          <img
            src="/playground_assets/seguros/cotizacion/boundsi1294-hf3p-500h.png"
            alt="BoundsI1294"
            class="frame750persona-cotizarsegurode-desgravamen-bounds"
          />
        </div>
      </div>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle23951294-3dmu-200h.png"
        alt="Rectangle23951294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2395"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2395"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24001294-fpn8-200h.png"
        alt="Rectangle24001294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2400"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2400"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24011294-zktj-200w.png"
        alt="Rectangle24011294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2401"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2401"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle23961294-i1vj-200h.png"
        alt="Rectangle23961294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2396"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2396"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24021294-322b-200h.png"
        alt="Rectangle24021294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2402"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2402"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle23971294-czqn-200h.png"
        alt="Rectangle23971294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2397"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2397"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24031294-4mtq-200h.png"
        alt="Rectangle24031294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2403"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2403"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24061294-tabb-200h.png"
        alt="Rectangle24061294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2406"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2406"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24081294-emtn-200h.png"
        alt="Rectangle24081294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2408"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2408"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24071294-1vx3-200h.png"
        alt="Rectangle24071294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2407"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2407"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle23981294-90qc-200h.png"
        alt="Rectangle23981294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2398"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2398"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24041294-70i-200h.png"
        alt="Rectangle24041294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2404"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2404"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle24051294-mi8p-200h.png"
        alt="Rectangle24051294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2405"
      />
      <v-skeleton-loader
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2405-2"
        type="card"
      ></v-skeleton-loader>
      <img
        src="/playground_assets/seguros/cotizacion/rectangle23991294-f5oh-200h.png"
        alt="Rectangle23991294"
        class="frame750persona-cotizarsegurode-desgravamen-rectangle2399"
      />
      <v-skeleton-loader
        class="mx-auto frame750persona-cotizarsegurode-desgravamen-rectangle2399"
        type="card"
      ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "Frame750PersonaCotizarsegurodeDesgravamen",
  metaInfo: {
    title: "exported project",
  },
};
</script>

<style scoped>


.frame750persona-cotizarsegurode-desgravamen-rectangulo-fondo-gradiente2 {
  top: 0px;
  left: 0px;
  width: 792px;
  height: 281px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle395 {
  top: 0px;
  left: 726px;
  width: 714px;
  height: 281px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle39 {
  top: 184px;
  left: 65px;
  width: 1322px;
  height: 630px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame750persona-cotizarsegurode-desgravamen-group495 {
  top: 62px;
  left: 148px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group {
  top: 15.025447845458984px;
  left: 105.18211364746094px;
  width: 149.81614685058594px;
  height: 47.98341751098633px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group1 {
  top: 0.00005686227814294398px;
  left: 44.20986557006836px;
  width: 54.99390411376953px;
  height: 19.948720932006836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-vector {
  top: 3.23506498336792px;
  left: -0.00009430493082618341px;
  width: 10px;
  height: 17px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector01 {
  top: 0.00005686227814294398px;
  left: 11.323002815246582px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector02 {
  top: 6.4695048332214355px;
  left: 17.79131317138672px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector03 {
  top: 6.4695048332214355px;
  left: 30.731914520263672px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector04 {
  top: 6.4695048332214355px;
  left: 43.67137908935547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-group2 {
  top: 25.878984451293945px;
  left: -0.000016696129023330286px;
  width: 143.41653442382812px;
  height: 22.104625701904297px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector06 {
  top: 3.7734971046447754px;
  left: 14.556951522827148px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector07 {
  top: 3.7734971046447754px;
  left: 27.4975528717041px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector08 {
  top: 3.7734971046447754px;
  left: 38.817806243896484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector09 {
  top: 3.7734971046447754px;
  left: 52.29852294921875px;
  width: 9px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector10 {
  top: 3.7734971046447754px;
  left: 60.92502212524414px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector11 {
  top: 3.7734971046447754px;
  left: 73.32494354248047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector12 {
  top: 3.7734971046447754px;
  left: 85.72599792480469px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector13 {
  top: 3.7734971046447754px;
  left: 99.20443725585938px;
  width: 18px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector14 {
  top: 3.7734971046447754px;
  left: 118.6139144897461px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector15 {
  top: 3.7734971046447754px;
  left: 131.55508422851562px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-group3 {
  top: 24.742467880249023px;
  left: 144.1051025390625px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group4 {
  top: 1.4308146238327026px;
  left: 1.859254240989685px;
  width: 2.3796639442443848px;
  height: 2.8555972576141357px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 77.92008209228516px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-vector18 {
  top: 1.3724621534347534px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-vector19 {
  top: -9.094947017729282e-13px;
  left: 24.692947387695312px;
  width: 53px;
  height: 78px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-usosdebotones {
  top: 863px;
  left: 148px;
  width: 220px;
  height: 13px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
}
.frame750persona-cotizarsegurode-desgravamen-text {
  top: 14px;
  left: 14px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame750persona-cotizarsegurode-desgravamen-usosdebotones1 {
  top: 863px;
  left: 1072px;
  width: 220px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-usosdebotones2 {
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores_neutrales-10);
}
.frame750persona-cotizarsegurode-desgravamen-text2 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame750persona-cotizarsegurode-desgravamen-group288924 {
  top: 53px;
  left: 574px;
  width: 291px;
  height: 99px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangulo-fondo-gradiente21 {
  top: 0px;
  left: 0px;
  width: 291px;
  height: 99px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-group288918 {
  top: 12px;
  left: 38px;
  width: 214px;
  height: 69px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-text4 {
  left: 28px;
  color: var(--dl-color-gray-white);
  width: 158px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  text-decoration: none;
}
.frame750persona-cotizarsegurode-desgravamen-group288877 {
  top: 31px;
  left: 0px;
  width: 214px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle3 {
  top: 18px;
  left: 125.72802734375px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle4 {
  top: 18px;
  left: 38px;
  width: 50px;
  height: 1px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group521 {
  top: 0px;
  left: 0px;
  width: 214px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-icon-btns {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-ellipse4 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-icon-outlinecheck {
  top: 7.887203693389893px;
  left: 7.886716365814209px;
  width: 22px;
  height: 22px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-icon-btns1 {
  top: 0px;
  left: 88px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-ellipse41 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: rgba(56, 0, 135, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame750persona-cotizarsegurode-desgravamen-ellipse5 {
  top: 12.547916412353516px;
  left: 12.546988487243652px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
  border-radius: var(--dl-radius-radius-round);
}
.frame750persona-cotizarsegurode-desgravamen-icon-btns2 {
  top: 0px;
  left: 176px;
  width: 38px;
  height: 38px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-ellipse42 {
  top: 0px;
  left: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 6px;
  border-radius: var(--dl-radius-radius-round);
}
.frame750persona-cotizarsegurode-desgravamen-ellipse51 {
  top: 12.547916412353516px;
  left: 12.546988487243652px;
  width: 13px;
  height: 13px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-group288926 {
  top: 286px;
  left: 443px;
  width: 555px;
  height: 420px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-card-active-inactive {
  top: 0px;
  left: 300px;
  width: 255px;
  height: 420px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle400 {
  top: -13px;
  left: -16px;
  width: 287px;
  height: 450px;
  position: absolute;
  border-color: transparent;
  border-radius: 20px;
}
.frame750persona-cotizarsegurode-desgravamen-logo-v1 {
  top: 14px;
  left: 104px;
  width: 47px;
  height: 49px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2371 {
  top: -2px;
  width: 255px;
  height: 77px;
  position: absolute;
  left : 0px;
}
.frame750persona-cotizarsegurode-desgravamen-group288885 {
  top: 339px;
  left: 60px;
  width: 0px;
  height: 0px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-line10 {
  top: 380px;
  left: 12px;
  width: 230px;
  height: 0px;
  position: absolute;
}
.frame750persona-cotizarsegurode-desgravamen-text6 {
  top: 301px;
  left: 88px;
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame750persona-cotizarsegurode-desgravamen-card-costoactual {
  top: 0px;
  left: 0px;
  width: 255px;
  height: 420px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-bounds {
  top: 0px;
  left: 0px;
  width: 255px;
  height: 420px;
  position: absolute;
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2.5px;
  border-radius: 20px;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2395 {
  top: 306px;
  left: 489px;
  width: 166px;
  height: 50px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2400 {
  top: 306px;
  left: 765px;
  width: 126px;
  height: 45px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2401 {
  top: 300px;
  left: 925px;
  width: 46px;
  height: 51px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2396 {
  top: 390px;
  left: 464px;
  width: 211px;
  height: 48px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2402 {
  top: 390px;
  left: 765px;
  width: 211px;
  height: 48px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2397 {
  top: 445px;
  left: 512px;
  width: 115px;
  height: 29px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2403 {
  top: 449px;
  left: 815px;
  width: 115px;
  height: 29px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2406 {
  top: 627px;
  left: 800px;
  width: 75px;
  height: 17px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2408 {
  top: 663px;
  left: 780px;
  width: 179px;
  height: 21px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2407 {
  top: 624px;
  left: 913px;
  width: 35px;
  height: 20px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2398 {
  top: 504px;
  left: 467px;
  width: 204px;
  height: 103px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2404 {
  top: 491px;
  left: 765px;
  width: 212px;
  height: 80px;
  position: absolute;
  border-color: transparent;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2405 {
  top: 580px;
  left: 800px;
  width: 139px;
  height: 33px;
  position: absolute;
  border-color: transparent;
  border-radius: 100px;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2405-2 {
  top: 580px;
  left: 800px;
  width: 139px;
  height: 33px;
  position: absolute;
  border-color: transparent;
  border-radius: 13px;
}
.frame750persona-cotizarsegurode-desgravamen-rectangle2399 {
  top: 629px;
  left: 539px;
  width: 64px;
  height: 51px;
  position: absolute;
  border-color: transparent;
}
</style>
