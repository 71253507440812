<template>
  <div>
    <div>
     
      
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import datausuario from "../../../../../services/usuario-datos";
import logContratar from "../../../../../services/logContratar";
import BotonContratarLogin from "./BotonContratarLogin.vue";
import BotonContratarFaltaLinea from "./BotonContratarFaltaLinea.vue";
import LaminaPro from "./LaminaPro.vue";
import LaminaSeg from "./LaminaSeg.vue";
import SkeletonLaminaPro from "./SkeletonLaminaPro.vue";
import Propuesta from "./PropuestaMo.vue";
export default {
  name: "FinalCotizacion",
  components: {    
  },
  data() {
    return {
        overlay: false,
      
    };
  },
  methods: {
   
  },
  mounted() {
    
  },
  created() {
    this.$root.$refs.ListarPropuesta = this;
  },

};
</script>

<style scoped>

</style>
