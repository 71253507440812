export default (Vue) => {
  Vue.prototype.$alertas = function (resp, clase = false) {
    const config = {
      position: "top-right",
      timeout: 2504,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    };
   
    if (clase) {
      if (clase == "success") {
        this.$toast.success(resp, config);
      }
      if (clase == "error") {
        this.$toast.error(resp, config);
      }
      if (clase == "info") {
        this.$toast.info(resp, config);
      }
      if (clase == "warning") {
        this.$toast.warning(resp, config);
      }
      
      return;
    }

    if (resp) {
      if (resp.data.success) {
        this.$toast.success(resp.data.message, config);
      }
      if (!resp.data.success) {
        if(resp.status==200){
        this.$toast.error(resp.data.message, config);
        }
        else{
            this.$toast.error(resp.status + " error al procesar los datos", config); 
        }
      }
    }
        
  };
};
