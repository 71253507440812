<template>
    <div class="frame21persona-formulario-lneadesgravamen-imputformulariou">

        <div>
           
                <v-form ref="form" v-model="valid" >
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="form1.rut" :rules="rules" label="Rut" required class="rut"
                                    filled dense  @update:error="vrut" color="rgb(56,0,151)" @keypress="vrutp()"
                                    :disabled="tipodisabled"
                                    >
                                    <template v-slot:append>
                                        <div v-if="validrut==`no`"></div>
                                        <v-icon v-else-if="!validrut" color="m100"  
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validrut" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="form1.nombre1" :rules="nombre1Rules" label="Primer Nombre"
                                    required filled dense  color="rgb(56,0,151)" @update:error="vnombre1" @keypress="vnombre1p()"
                                    :disabled="tipodisabled">
                                    <template v-slot:append>
                                        <div v-if="validnombre1==`no`"></div>
                                        <v-icon v-else-if="!validnombre1" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre1" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="form1.nombre2" :rules="nombre2Rules" label="Segundo Nombre"
                                    required filled dense  color="rgb(56,0,151)" @update:error="vnombre2" @keypress="vnombre2p()"
                                    :disabled="tipodisabled">
                                    <template v-slot:append>
                                       
                                        <div v-if="validnombre2==`no`"></div>
                                        <v-icon v-else-if="!validnombre2" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validnombre2" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">

                                <v-text-field v-model="form1.apaterno" :rules="apaternoRules" label="Apellido Paterno"
                                    required filled dense  append-icon="mdi-check-circle" color="rgb(56,0,151)"
                                    class="w100" @update:error="vapaterno" @keypress="vapaternop()"
                                    :disabled="tipodisabled">
                                    <template v-slot:append>
                                        
                                        <div v-if="validapaterno==`no`"></div>
                                        <v-icon v-else-if="!validapaterno" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validapaterno" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <v-text-field v-model="form1.amaterno" :rules="amaternoRules" label="Apellido Materno"
                                    required filled dense  append-icon="mdi-check-circle" color="rgb(56,0,151)"
                                    @update:error="vamaterno" @keypress="vamaternop()"
                                    :disabled="tipodisabled">
                                    <template v-slot:append>
                                        
                                        <div v-if="validamaterno==`no`"></div>
                                        <v-icon v-else-if="!validamaterno" color="m100" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon v-else-if="validamaterno" color="red" 
                                            style="margin-bottom: 10px !important ;">
                                            mdi-close-circle
                                        </v-icon>
                                    </template>

                                </v-text-field>
                            </v-col>

                            <!-- <v-dialog v-model="antessalir" width="740px" hide-overlay>
            <template v-slot:activator="{ on }"> -->
                            <v-btn color="grey darken-1 " fab x-large style="margin-left: -147px;
    position: absolute;
    top: 255px;" @click="volver">
                                <v-icon color="rgba(255, 255, 255)" size="70">
                                    mdi-arrow-left
                                </v-icon>
                            </v-btn>
                            <!-- </template>
            <AntesSalir style="overflow-y:hidden !important" v-bind:origen="`1`"></AntesSalir>
          </v-dialog> -->


                            <v-btn class="mx-2" color="rgba(113, 48, 237, 1)" fab x-large style="    margin-left: 108% !important;
    position: absolute;
    top: 255px;"
                                @click="validate" :disabled="!valid">
                                <v-icon color="rgba(255, 255, 255)" size="70">
                                    mdi-arrow-right
                                </v-icon>
                                <!-- <v-img src="playground_assets/iconbtns9484-rk3.svg"></v-img> -->
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>


           

        </div>
        <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
       
</template>
<script>
import datausuario from "../../../../services/usuario-datos";

export default {
    name: "FormularioLinea",
    components: {
       
    },
    data() {
        return {
            tipodisabled: this.$store.state.inicioDisabled,
            overlay: false,
            vinicio: true,
            validrut: "no",
            validnombre1: "no",
            validnombre2: "no",
            validapaterno: "no",
            validamaterno: "no",
            form1: {
                rut: this.$store.state.form1.rut,
                nombre1: this.$store.state.form1.nombre1,
                nombre2: this.$store.state.form1.nombre2,
                apaterno: this.$store.state.form1.apaterno,
                amaterno: this.$store.state.form1.amaterno
            },
            antessalir: "",
            valid: false,
            nombre1Rules: [
                (v) =>  !!v  || "el nombre es requerido",
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
                (v) => (v && this.validarLn1(v)) || "este campo solo acepta letras",
            ],
            nombre2Rules: [
                (v) => !!v || "el nombre es requerido",
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
                (v) => (v && this.validarLn2(v)) || "este campo solo acepta letras",
            ],
            apaternoRules: [
                (v) => !!v || "el apellido paterno es requerido",
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
                (v) => (v && this.validarLa1(v)) || "este campo solo acepta letras",
            ],
            amaternoRules: [
                (v) => !!v || "el apellido materno es requerido",
                (v) => (v && v.length <= 50) || "largo máximo 50 caracteres",
                (v) => (v && v.length >= 3) || "largo mínimo 3 caracteres",
                (v) => (v && this.validarLa2(v)) || "este campo solo acepta letras",
            ],
            select: null,
            imgfondo1: "/playground_assets/rectangle3829484-upr6.svg",
            imgfondo2: "/playground_assets/rectangle3829484-upr6.svg"
        };
    },
    methods: {
    
    validarLn1(){
        this.form1.nombre1=this.form1.nombre1.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.nombre1=this.form1.nombre1.replace("  "," ");
        return true;
    },
    validarLn2(){
        this.form1.nombre2=this.form1.nombre2.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.nombre2=this.form1.nombre2.replace("  "," ");
        return true;
    },
    validarLa1(){
        this.form1.apaterno=this.form1.apaterno.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.apaterno=this.form1.apaterno.replace("  "," ");
        return true;
    },
    validarLa2(){
        this.form1.amaterno=this.form1.amaterno.replace(/[^a-zA-Z\s]/gi,"");
        this.form1.amaterno=this.form1.amaterno.replace("  "," ");
        return true;
    },
    largorut(rut,largo){
      if(rut && rut.length>largo){
        rut=rut.substring(0, rut.length - 1);
      }      
      this.form1.rut=rut.replace(/[^kK0-9.-]/gi,"");
      
      return true;
    },
    vrutp(v) {
        if(this.validrut=="no"){ 
            this.validrut=true;
        }
    },
    vrut(v) {
      this.validrut = v;
      console.log(v);
      if(!v){
        if(this.form1.rut.length>=11 && this.form1.rut.length<=12){
                this.buscar(this.form1.rut)
        }
        
      }
    },
    vnombre1p(v){
        if(this.validnombre1=="no"){ 
            this.validnombre1=true;
        }
    },

        vnombre1(v) {        
            this.validnombre1 = v;
        },
        vnombre2p(v){
        if(this.validnombre2=="no"){ 
            this.validnombre2=true;
        }
        },
        vnombre2(v) {
            this.validnombre2 = v;
        },
        vapaternop(v){
        if(this.validapaterno=="no"){ 
            this.validapaterno=true;
        }
        },
        vapaterno(v) {
            this.validapaterno = v;
        },
        vamaternop(v){
        if(this.validamaterno=="no"){ 
            this.validamaterno=true;
        }
        },
        vamaterno(v) {
            this.validamaterno = v;
        },
        limpiar(){
            var form = {
                rut: this.form1.rut,
                nombre1: "",
                nombre2: "",
                apaterno: "",
                amaterno: "",
            }
        this.form1=form;
        var form2 = {
            email: "",
            celular: "",
            date: "",
        }
      this.form2=form2;
      this.$store.commit('setform1', form);
      this.$store.commit('setform2', form2);
      this.$root.$refs.LineaForm2.form2=form2;
      this.$store.commit('setestadocliente', false);
      this.$root.$refs.FormularioLinea.clienteEncontrado=0;
      this.$store.commit('setclienteencontrado', 0);
      
      
    //   this.$refs.form.reset();
    },
    async buscar(data) {
    if(data.length>=11 && data.length<=12){
        document.activeElement.blur();
      this.overlay =true;
      let res = false;
      data = this.form1.rut.replace(/\./g, "");
      res = await datausuario.traeusuario(data);
      
      if(res.status==200 && res.data.estadoTransaccion.success){
      var form = {
        rut : res.data.datosPersona.per_rut+"-"+res.data.datosPersona.per_dv,
        nombre1: res.data.datosPersona.per_nombre_principal,
        nombre2: res.data.datosPersona.per_nombre_segundo,
        apaterno: res.data.datosPersona.per_ape_paterno,
        amaterno: res.data.datosPersona.per_ape_materno,
      }
      this.form1=form;
      var form2 = {
        email: res.data.datosPersona.per_email,
        celular: res.data.datosPersona.per_celular,
        date: this.$root.$refs.LineaForm2.formatDate(res.data.datosPersona.per_fec_nac.split("T")[0]),
      }
      this.form2=form2;
      this.$store.commit('setform1', form);
      this.$store.commit('setform2', form2);
      this.$root.$refs.LineaForm2.form2=form2;
      this.$store.commit('setestadocliente', res.data.estadoCliente);
      this.$root.$refs.FormularioLinea.clienteEncontrado=res.data.estadoCliente.tiene_linea_desgravamen;
      if(res.data.datosPersona.per_tipo_cliente==1){
        this.$alertas("El usuario existe en el sistema","info");
      }else{
        this.$alertas("Empresa existe en el sistema","info");
      }
      
      this.$store.commit('setclienteencontrado', true);
      this.$root.$refs.FormularioLinea.clienteEncontrado=true;
    //   this.$root.$refs.PreaprobacionLinea.clienteExiste =true;
     } else{
      
      this.$store.commit('setclienteencontrado', false);
      this.$root.$refs.FormularioLinea.clienteEncontrado=0;
      this.$root.$refs.FormularioLinea.clienteEncontrado=false;
      this.limpiar();
    //   this.$root.$refs.LineaForme1.limpiar(false);
    //   this.$root.$refs.PreaprobacionLinea.clienteExiste =false;
      this.$alertas("Usuario nuevo, ¡ ingrese sus datos !","success");
     }
      
      this.overlayBuscar();
    }
    },
   
        validaRut: function (rutCompleto) {
            if(rutCompleto && rutCompleto!=""){
                rutCompleto=rutCompleto.replace(/\./g, '');
            }           
            if(!rutCompleto){
                this.form1.rut="";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev=rut+""+digv;
            let sw=true;
            let mem=false;
            for (let index = 0; index < rev.length; index++) {
                if(!index){
                    mem = rev.substring(index,1);
                    console.log("mem:"+mem);
                }else{
                    if(mem!=rev.substring(index,index+1)){                      
                        sw=false;
                        break;
                    }else{
                        console.log("err:"+rev.substring(index,1));
                    }
                }                                
            }
          
            if(sw){return false;}
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
        btnleft: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.add("boton-left");
            }, 150);
        },
        btnleftout: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.remove("boton-left");
            }, 150);
        },
        btnright: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.add("boton-right");
            }, 150);
        },
        btnrightout: function (name) {
            var el = document.querySelector("." + name);
            setTimeout(() => {
                el.classList.remove("boton-right");
            }, 150);
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$store.commit('setform1', this.form1);
                this.$root.$refs.FormularioLinea.estadoFormLinea = 2;
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        volver() {
            this.$router.push('crear-degravamen');
        },
        hover: function (name, img) {
            if (img == 1) {
                this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg"
            } else {
                this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg"
            }

        },
        hoverout: function (name, img) {
            if (img == 1) {
                this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg"
            } else {
                this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg"
            }

        },
        overlayBuscar() {
        setTimeout(() => {
          this.overlay = false
        //   if(this.$store.state.clienteEncontrado){
        //     this.$root.$refs.FormularioLinea.estadoFormLinea = 7;
        // };
        }, 1000)
      },
      
      formateaRut(rut) {
        if(!rut || rut==""){
            return;
        }
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 0) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return this.form1.rut=rutPuntos;
        }

    },
    computed: {
     
        tipoUsuario(){
            if(!this.$store.state.tipoPersonaEmpresa){
                return true;
            }
            return false;
        },
        rules() {
            const rules = [];
            let rule="";
            rule =(v) => (v && v.length>=11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule =(v) => (v && this.largorut(v,12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.form1.rut) || `rut inválido`;
            if(this.form1.rut!=""){
            this.formateaRut(this.form1.rut);
            }
            rules.push(rule);
            
            return rules;
        },
    },
    mounted() {
        // this.tipodisabled = true;
        // this.validrut = this.form1.rut.length ? false : true;
        // this.validnombre1 = this.form1.nombre1.length ? false : true;
        // this.validnombre2 = this.form1.nombre2.length ? false : true;
        // this.validapaterno = this.form1.apaterno.length ? false : true;
        // this.validamaterno = this.form1.amaterno.length ? false : true;
    },
    created() {
        this.$root.$refs.LineaForm1 = this;
    },
    metaInfo: {
        title: 'Mueve Seguro',
    },
};
</script>
<style scoped>
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}
.frame21persona-formulario-lneadesgravamen-imputformulariou {
  top: 410px;
  /* left: 457px; */
  width: 600px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
  margin-left: 20px;
}
</style>
