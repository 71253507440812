<template>
  <div class="o-v-e-r-l-a-y-notevayas-container">
    <div class="o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas">
      <div class="o-v-e-r-l-a-y-notevayas-group288922">
        <span class="o-v-e-r-l-a-y-notevayas-text Roboto_H4_3242">
          <span color="rgba(113, 48, 237, 1)" style="color: #7130ed;font-weight: 500;">¡Ups! </span> 
          <span>te falta un poco todavía</span>
        </span>
        <img data-v-2db79d28="" alt="Line221251" src="/playground_assets/seguros/cotizacion/line221251-vkqw.svg" class="frame7pgina-contexto-cotizarsegurode-desgravamen-line22">
        <span class="o-v-e-r-l-a-y-notevayas-text2">
          <span>
            Para poder contratar necesitas ingresar a tú cuenta privada.
          </span>
        </span>
        <img
          src="/playground_assets/line331251-btmo.svg"
          alt="Line331251"
          class="o-v-e-r-l-a-y-notevayas-line33"
        />
        <div class="o-v-e-r-l-a-y-notevayas-group288921">
          <!-- <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
              <span class="o-v-e-r-l-a-y-notevayas-text4">
                <span>Salir</span>
              </span>
            </div>
          </div> -->

          <!-- <div class="o-v-e-r-l-a-y-notevayas-usosdebotones">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones1">
              <v-app style="height: 0px">
                <v-btn
                  color="neutral"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  @click="continuaOrigen"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text4">
                    <span>Salir</span>
                  </span>
                </v-btn>
              </v-app>
            </div>
          </div> -->

          <div class="o-v-e-r-l-a-y-notevayas-usosdebotones2">
            <div class="o-v-e-r-l-a-y-notevayas-usosdebotones3">
              <v-app style="height: 0px">
                <v-btn
                  color="juanito"
                  class="rounded-pill"
                  x-large
                  width="200px"
                  style="left:-180px"
                  @click="continuaOrigen"
                >
                  <span class="o-v-e-r-l-a-y-notevayas-text6">
                    <span>Ingresar</span>
                  </span>
                </v-btn>
              </v-app>
            </div>
          </div>
         
          <!-- <span class="o-v-e-r-l-a-y-notevayas-text6">
                <span>Continuar</span>
              </span> -->
        </div>
      </div>
      <v-app style="height: 0px">
                <v-btn
                  color="transparent"
                  class="rounded-pill o-v-e-r-l-a-y-notevayas-icon-btns"
                  x-large
                  width="10px"
                  text
                  @click="cancelaOrigen"

                >
                <img
            src="/playground_assets/iconbtns1251-loup.svg"
            alt="IconBtns1251"
            
          />
                </v-btn>
      </v-app>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "OVERLAYFaltaPoco",
  props: {
    origen : ""
  },
  methods: {
    continuaOrigen(){
      // this.$root.$refs.ListarPropuesta.guardarPlanSeleccionado(2);
      this.$router.push('login');
    },
    cancelaOrigen(){
      
        this.$root.$refs.ContratarLogin.fpoco=false;
    
    }

  },
  metaInfo: {
    title: 'Mueve Seguro',
  },
};
</script>

<style scoped>
.o-v-e-r-l-a-y-notevayas-container {
  min-height: 390px;
}
.o-v-e-r-l-a-y-notevayas-o-v-e-r-l-a-y-notevayas {
  width: 100%;
  height: 392px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
}
.o-v-e-r-l-a-y-notevayas-group288922 {
  top: 0px;
  left: 0px;
  width: 744px;
  height: 392px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(241, 241, 243, 1);
}
.o-v-e-r-l-a-y-notevayas-text {
  top: 75px;
  left: 160px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 94.9999988079071%;
}
.o-v-e-r-l-a-y-notevayas-text2 {
  top: 164px;
  left: 92px;
  color: var(--dl-color-colores_neutrales-90);
  width: 580px;
  height: auto;
  position: absolute;
  font-size: 21px;
  align-self: auto;
  font-style: Medium;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 146.00000381469727%;
  font-stretch: normal;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-line33 {
  top: 135px;
  left: 101px;
  width: 542px;
  height: 0px;
  position: absolute;
}
.o-v-e-r-l-a-y-notevayas-group288921 {
  top: 277px;
  left: 110px;
  width: 525px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones {
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones1 {
  top: -15px;
  width: 220px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  /* background-color: var(--dl-color-colores_neutrales-10); */
}
.o-v-e-r-l-a-y-notevayas-text4 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones2 {
  top: 0px;
  left: 305px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.o-v-e-r-l-a-y-notevayas-usosdebotones3 {
  top: -15px;
  width: 400px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
}
.o-v-e-r-l-a-y-notevayas-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.o-v-e-r-l-a-y-notevayas-icon-btns {
  top: 20px;
  left: 650px;
  width: 52px;
  height: 52px;
  position: absolute;
}
.frame7pgina-contexto-cotizarsegurode-desgravamen-line22 {
  top: 135px;
  left: 90px;
  width: 572px;
  border: 1px solid #494e5b;
  height: 0px;
  position: absolute;
}
</style>
