<template>
   <div>
  <div class="sitiom" style="display: none;">
  <div class="frame-pgina-contexto-cotizarsegurode-desgravamen-container">
    <div
      class="frame-pgina-contexto-cotizarsegurode-desgravamen-frame-pgina-contexto-cotizarsegurode-desgravamen"
    >
      <img
        alt="BackgroundHome2265"
        src="/playground_assets/movil/cotizacion/backgroundhome2265-pt39.svg"
        class="frame-pgina-contexto-cotizarsegurode-desgravamen-background-home"
      />
      <div class="frame-pgina-contexto-cotizarsegurode-desgravamen-logo-v1">
        <img
                  src="/playground_assets/logos/logo-blanco.png"
                  class="g-logo-blanco"
                />
      </div>
      <span
        class="frame-pgina-contexto-cotizarsegurode-desgravamen-text Roboto_H4_3242"
      >
        <span>Desde donde estés</span>
        <br />
        <span>100% ONLINE</span>
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text04">
        <span>Para contratar tu línea necesitaras los siguiente:</span>
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text06">
        <span>Cédula de identidad</span>
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text08">
        <span>Completar el formulario con tus datos</span>
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text10">
        <span>Tu teléfono móvil</span>
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text12">
        <span>COTIZA</span>
        <br />
      </span>
      <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text15">
        tu seguro de Desgravamen
      </span>
      <div class="frame-pgina-contexto-cotizarsegurode-desgravamen-group288879">
        <img
          alt="Ellipse152265"
          src="/playground_assets/movil/cotizacion/ellipse152265-1308-200h.png"
          class="frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse15"
        />
      </div>
      <img
        alt="Ellipse162265"
        src="/playground_assets/movil/cotizacion/ellipse162265-t2ro-200h.png"
        class="frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse16"
      />
      <img
        alt="Ellipse172265"
        src="/playground_assets/movil/cotizacion/ellipse172265-134-200h.png"
        class="frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse17"
      />
      <img
        alt="unsplashWHWYBmtn302265"
        src="/playground_assets/movil/cotizacion/unsplashwhwybmtn302265-elqu-600w.png"
        class="frame-pgina-contexto-cotizarsegurode-desgravamen-unsplash-whw-bmtn30"
      />
      <div class="frame-pgina-contexto-cotizarsegurode-desgravamen-usosdebotones">
        <div
          class="frame-pgina-contexto-cotizarsegurode-desgravamen-usosdebotones1"
        >
        <v-btn rounded width="100%" height="50px" style="height: 46px;width: 200px;background-color: transparent;padding: 10px;" 
            @click="nuevacotizacion"
            >
            <span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text16">
            <span>Comencemos</span>
          </span>
            </v-btn>
        </div>
        
      </div>
      <router-link to="/"><span class="frame-pgina-contexto-cotizarsegurode-desgravamen-text18 bvolver">
        <span>Volver</span>
      </span>
    </router-link>
      
    </div>
  </div>
  </div>
  <div class="sitiod">
  <div>
    <v-app>
    <v-container full-width>
      <v-layout row wrap>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important">1</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group495">
            <img
                  src="/playground_assets/logos/p-logo-morado.png"
                  class="g-logo-morado"
                />
          </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
         
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">6</h1>
        
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-maskgroup">
        <img
        src="/playground_assets/seguros/cotizacion/Rectangle 363.svg"
          alt="unsplashWHWYBmtn302069"
          class="frame6pgina-contexto-crear-lneade-desgravamen-unsplash-w-h-w-y-bmtn30"
        />
        <!-- <img
        src="/playground_assets/svg21366-im8j.svg"
        alt="SVG21366"
        class="frame6pgina-contexto-crear-lneade-desgravamen-s-v-g2"
      /> -->
      <img
        src="/playground_assets/svg21366-im8j.svg"
        alt="SVG21366"
        class="frame6pgina-contexto-crear-lneade-desgravamen-s-v-g21"
      />
      </div>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-group15">
        <span
          class="frame6pgina-contexto-crear-lneade-desgravamen-text Roboto_H4_3242"
        >
          <span>
            <span>Desde donde estés</span>
            <br />
            <span>100% ONLINE</span>
          </span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text05">
          <span>Para contratar tu línea necesitaras los siguiente:</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text07">
          <span>Cédula de identidad</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text09">
          Completar el formulario con tus datos
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text10">
          <span>Tu teléfono móvil</span>
        </span>
        <span class="frame6pgina-contexto-crear-lneade-desgravamen-text12">
          <span>
            <span>COTIZA tu seguro de</span>
            <br />
            <span>
              Desgravamen
             
            </span>
          </span>
        </span>
        <div class="frame6pgina-contexto-crear-lneade-desgravamen-group288879">
          <img
            src="/playground_assets/ellipse151251-vcy5-200h.png"
            alt="Ellipse151251"
            class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse15"
          />
        </div>
        <img
          src="/playground_assets/ellipse161251-aggb-200h.png"
          alt="Ellipse161251"
          class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse16"
        />
        <img
          src="/playground_assets/ellipse171251-55m5-200h.png"
          alt="Ellipse171251"
          class="frame6pgina-contexto-crear-lneade-desgravamen-ellipse17"
        />
      </div>
          <div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones">
       
       <router-link to="/formulario-cotizacion"><div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones1 conocenos"  @mouseover="hover('conocenos')" @mouseout="hoverout('conocenos')">
               <span class="frame6pgina-contexto-crear-lneade-desgravamen-text17"><span>Comencemos</span></span>                
       </div></router-link>
     </div>
     <img
       src="/playground_assets/line221251-lhmr.svg"
       alt="Line221251"
       class="frame6pgina-contexto-crear-lneade-desgravamen-line22"
     />
     <div class="frame6pgina-contexto-crear-lneade-desgravamen-group509">
      
       <router-link to="/"><div class="frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones2 bvolver"  @mouseover="hover('bvolver')" @mouseout="hoverout('bvolver')">
               <span class="frame6pgina-contexto-crear-lneade-desgravamen-text19"><span>Volver</span></span>                
       </div></router-link>
     </div>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>

        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">2</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">3</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">4</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">5</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">6</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">7</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">8</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">9</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">10</h1>
        </v-flex>
        <v-flex color="primary" sm1>
          <h1 style="display: none   !important ">11</h1>
        </v-flex>
        <v-flex sm1>
          <h1 style="display: none   !important ">12</h1>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
  </div>
  </div>
  </div>
</template>


<script>
export default {
  name: "Frame6PginaContextoCrearLneadeDesgravamenmo",

  data() {
    return {
      rawdo78: " ",
    };
  },
  methods: {
    nuevacotizacion(){
      this.$router.push("/formulario-cotizacion");
    },
    hover: function (name) {
      console.log(name);
      var el = document.querySelector("." + name);
      el.classList.add("boton-hover");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("boton-hover");
    },
  },
  mounted() {
    var device = navigator.userAgent;
    // if (
    //   device.match(/Iphone/i) ||
    //   device.match(/Ipod/i) ||
    //   device.match(/Android/i) ||
    //   device.match(/J2ME/i) ||
    //   device.match(/BlackBerry/i) ||
    //   device.match(/iPhone|iPad|iPod/i) ||
    //   device.match(/Opera Mini/i) ||
    //   device.match(/IEMobile/i) ||
    //   device.match(/Mobile/i) ||
    //   device.match(/Windows Phone/i) ||
    //   device.match(/windows mobile/i) ||
    //   device.match(/windows ce/i) ||
    //   device.match(/webOS/i) ||
    //   device.match(/palm/i) ||
    //   device.match(/bada/i) ||
    //   device.match(/series60/i) ||
    //   device.match(/nokia/i) ||
    //   device.match(/symbian/i) ||
    //   device.match(/HTC/i)
    // ) {
    // } else {
    //   this.$router.push("/crear-cotizacion");
    // }

    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setemptyform3", {});    
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyform5", {});
    this.$store.commit("setestadoFormLinea", 1);
    this.$store.commit("setestadoFormCotizacion", 1);
    this.$store.commit("setestadoBps", 1);
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit('setrepresentanteencontrado', false);
    this.$store.commit("settipocotizacion", "inicio");
    this.$store.commit("settipopersonaempresa", "inicio");
    this.$store.commit("setiniciodisabled", true);
    this.isLogin=false;
      this.$store.commit("setislogin",false);
      localStorage.removeItem('loginEstado');
      localStorage.removeItem('loginCliente');
    
    this.$store.commit('setclienteencontrado', false);
    this.$store.commit('setrepresentanteencontrado', false);
    if (this.$root.$refs.LineaForm1) {
      this.$root.$refs.LineaForm1.tipodisabled = true;
    }
    if (this.$root.$refs.CotizaForm1) {
      this.$root.$refs.CotizaForm1.tipodisabled = true;
    }
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>

<style scoped>
.frame-pgina-contexto-cotizarsegurode-desgravamen-container {
  min-height: 100vh;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-frame-pgina-contexto-cotizarsegurode-desgravamen {
  width: 100%;
  height: 1120px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-background-home {
  top: -425px;
  width: 100%;
  height: 1000px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-logo-v1 {
  top: 29px;
  width: 104px;
  height: 109px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-txt {
  top: 75.9056396484375px;
  left: 0px;
  width: 104px;
  height: 33.09480285644531px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group {
  top: 0px;
  left: 30.69034194946289px;
  width: 38.17604446411133px;
  height: 13.758354187011719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector {
  top: 2.2309722900390625px;
  left: 0px;
  width: 7px;
  height: 12px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector01 {
  top: 0px;
  left: 7.8600616455078125px;
  width: 4px;
  height: 14px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector02 {
  top: 4.4625701904296875px;
  left: 12.3509521484375px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector03 {
  top: 4.4625701904296875px;
  left: 21.33377456665039px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector04 {
  top: 4.4625701904296875px;
  left: 30.3162841796875px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group1 {
  top: 17.849639892578125px;
  left: 0px;
  width: 99.55695343017578px;
  height: 15.245162963867188px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 9px;
  height: 12px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector06 {
  top: 2.6028976440429688px;
  left: 10.104693412780762px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector07 {
  top: 2.6028976440429688px;
  left: 19.087833404541016px;
  width: 7px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector08 {
  top: 2.6028976440429688px;
  left: 26.947214126586914px;
  width: 8px;
  height: 13px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector09 {
  top: 2.6028976440429688px;
  left: 36.30441665649414px;
  width: 6px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector10 {
  top: 2.6028976440429688px;
  left: 42.293174743652344px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector11 {
  top: 2.6028976440429688px;
  left: 50.901309967041016px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector12 {
  top: 2.6028976440429688px;
  left: 59.50944137573242px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector13 {
  top: 2.6028976440429688px;
  left: 68.86664581298828px;
  width: 12px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector14 {
  top: 2.6028976440429688px;
  left: 82.34040832519531px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector15 {
  top: 2.6028976440429688px;
  left: 91.32291412353516px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group2 {
  top: 17.06507110595703px;
  left: 100.03536224365234px;
  width: 3.9646377563476562px;
  height: 3.9387588500976562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group3 {
  top: 0.9855575561523438px;
  left: 1.2907791137695312px;
  width: 1.6519317626953125px;
  height: 1.9693832397460938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 2px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group4 {
  top: 0px;
  left: 0px;
  width: 3.9646377563476562px;
  height: 3.9387588500976562px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 4px;
  height: 4px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-img {
  top: 0px;
  left: 17.578819274902344px;
  width: 68.36272430419922px;
  height: 67.91618347167969px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector18 {
  top: 1.1958632469177246px;
  left: 0px;
  width: 25px;
  height: 54px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-vector19 {
  top: 0px;
  left: 21.664466857910156px;
  width: 47px;
  height: 68px;
  position: absolute;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text {
  top: 353px;
  left: 24px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 312px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  line-height: 94.9999988079071%;
  margin-right: 0;
  margin-bottom: 0;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text04 {
  top: 452px;
  left: 24px;
  color: var(--dl-color-colores_neutrales-90);
  width: 312px;
  height: auto;
  position: absolute;
  font-size: 23px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text06 {
  top: 592px;
  left: 61px;
  color: var(--dl-color-colores_neutrales-90);
  width: 162px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text08 {
  top: 550px;
  left: 61px;
  color: var(--dl-color-colores_neutrales-90);
  width: 275px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text10 {
  top: 634px;
  left: 61px;
  color: var(--dl-color-colores_neutrales-90);
  width: 142px;
  height: auto;
  position: absolute;
  font-size: 16px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text12 {
  top: 250px;
  left: 24px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 312px;
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text15 {
  top: 250px;
  left: 24px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 312px;
  height: auto;
  position: absolute;
  font-size: 25px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-group288879 {
  top: 546px;
  left: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse15 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse16 {
  top: 588px;
  left: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse17 {
  top: 630px;
  left: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-unsplash-whw-bmtn30 {
  top: 681px;
  width: 360px;
  height: 355px;
  position: absolute;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-usosdebotones {
  top: 833px;
  width: 220px;
  display: flex;
  position: absolute;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.15000000596046448) ;
  align-items: flex-start;
  border-color: transparent;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-usosdebotones1 {
  width: 220px;
  display: flex;
  /* padding: 14px; */
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: var(--dl-color-colores-elnaranjodejuanito);
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text16 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame-pgina-contexto-cotizarsegurode-desgravamen-text18 {
  top: 1061px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 58px;
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: underline;
  margin-left: -28px;
}
@media(max-width: 991px) {

  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }  .frame-pgina-contexto-cotizarsegurode-desgravamen-background-home {
    top: -550px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text {
    top: 353px;
    left: 158px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text04 {
    top: 450px;
    left: 158px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text06 {
    top: 582px;
    left: 223px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text08 {
    top: 538px;
    left: 225px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text10 {
    top: 622px;
    left: 219px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text12 {
    top: 240px;
    left: 158px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text15 {
    top: 294px;
    left: 162px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse15 {
    left: 150px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse16 {
    top: 588px;
    left: 174px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse17 {
    top: 630px;
    left: 174px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-unsplash-whw-bmtn30 {
    width: 470px;
  }
}
@media(max-width: 767px) {
  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-background-home {
    top: -466px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text {
    top: 361px;
    left: 83px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text04 {
    top: 450px;
    left: 86px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text06 {
    top: 582px;
    left: 150px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text08 {
    top: 540px;
    left: 150px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text10 {
    top: 622px;
    left: 150px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text12 {
    top: 266px;
    left: 80px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text15 {
    top: 309px;
    left: 85px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse15 {
    left: 75px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse16 {
    left: 100px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse17 {
    top: 632px;
    left: 100px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-unsplash-whw-bmtn30 {
    width: 557px;
  }
}
@media(max-width: 479px) {
  .sitiod {
    display: none !important;
  }
  .sitiom {
    display: unset !important;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-background-home {
    top: -378px;
    height: 893px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text {
    top: 331px;
    left: 20px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text04 {
    top: 429px;
    left: 24px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text06 {
    top: 578px;
    left: 61px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text08 {
    top: 535px;
    left: 61px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text10 {
    top: 619px;
    left: 61px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text12 {
    top: 238px;
    left: 20px;
    color: var(--dl-color-colores_paleta-tonos_morado-100);
    font-size: 40px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 95%;
    text-decoration: none;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-text15 {
    top: 276px;
    left: 24px;
    color: var(--dl-color-colores_paleta-tonos_morado-100);
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse15 {
    top: 0;
    left: 0;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse16 {
    top: 588px;
    left: 24px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-ellipse17 {
    top: 630px;
    left: 24px;
  }
  .frame-pgina-contexto-cotizarsegurode-desgravamen-unsplash-whw-bmtn30 {
    width: 100%;
  }
}
.container {
  width: 100%;
  max-width: 1920px;
  min-width: 1440px;
}
.boton-hover {
  background-color: var(--dl-color-colores-elnaranjodejuanito) !important;
}
.frame6pgina-contexto-crear-lneade-desgravamen-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.frame6pgina-contexto-crear-lneade-desgravamen-frame6pgina-contexto-crear-lneade-desgravamen {
  top: -15px;
  width: 1468px;
  height: 950px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-maskgroup {
  top: 0px;
  /* left: 557px; */
  width: 911px;
  height: 950px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-unsplash-w-h-w-y-bmtn30 {
  top: -156px;
    /* left: 28.96848487854004px; */
    width: 994px;
    height: auto;
    position: absolute;
    background: #d9d9d9;
    border-color: transparent;
    margin-left: -49px;
}
.frame6pgina-contexto-crear-lneade-desgravamen-s-v-g2 {
  top: -503px;
  /* left: 200px; */
  width: 655px;
  height: 1912px;
  position: absolute;
  box-sizing: content-box;
}
.frame6pgina-contexto-crear-lneade-desgravamen-s-v-g21 {
  top: -425px;
    /* left: 347px; */
    width: 952px;
    /* height: 1912px; */
    position: absolute;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin-left: -419px;

}
.frame6pgina-contexto-crear-lneade-desgravamen-group15 {
  top: 220px;
  /* left: 148px; */
  width: 651px;
  height: 423px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-text {
  top: 138px;
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  width: 330px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: justified;
  font-weight: 700;
  line-height: 94.9999988079071%;
}
.frame6pgina-contexto-crear-lneade-desgravamen-text05 {
  top: 262px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 23px;
  align-self: auto;
  font-style: Bold;
  text-align: justified;
  font-family: Roboto;
  font-weight: 700;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text07 {
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  bottom: 30px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text09 {
  top: 309px;
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text10 {
  color: var(--dl-color-colores_neutrales-90);
  right: -37px;
  width: 100%;
  bottom: -8px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: Medium;
  text-align: justified;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-text12 {
  color: var(--dl-color-colores_paleta-tonos_morado-100);
  height: auto;
  position: absolute;
  font-size: 40px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 94.9999988079071%;
  font-stretch: normal;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-group288879 {
  top: 315px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse15 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse16 {
  top: 357px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-ellipse17 {
  top: 399px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones {
  top: 694px;
  /* left: 148px; */
  width: 361px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones1 {
  width: 361px;
  display: flex;
  padding: 14px;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 100px;
  background-color: rgba(113, 48, 237, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-text17 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-line22 {
  top: 477px;
  left: 148px;
  width: 572px;
  height: 0px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group509 {
  top: 790px;
  /* left: 148px; */
  width: 236px;
  height: 51px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-usosdebotones2 {
  top: 0px;
  /* left: 0px; */
  width: 236px;
  display: flex;
  padding: 14px;
  position: absolute;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(39, 41, 48, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
}
.frame6pgina-contexto-crear-lneade-desgravamen-text19 {
  color: var(--dl-color-colores_neutrales-dark);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  
}
.frame6pgina-contexto-crear-lneade-desgravamen-group495 {
  top: 62px;
  /* left: 148px; */
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group498 {
  top: 0px;
  left: 0px;
  width: 254.9982452392578px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group {
  top: 15.025447845458984px;
  left: 105.18211364746094px;
  width: 149.81614685058594px;
  height: 47.98341751098633px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group1 {
  top: 0.00005686227814294398px;
  left: 44.20986557006836px;
  width: 54.99390411376953px;
  height: 19.948720932006836px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector {
  top: 3.23506498336792px;
  left: -0.00009430493082618341px;
  width: 10px;
  height: 17px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector01 {
  top: 0.00005686227814294398px;
  left: 11.323002815246582px;
  width: 6px;
  height: 20px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector02 {
  top: 6.4695048332214355px;
  left: 17.79131317138672px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector03 {
  top: 6.4695048332214355px;
  left: 30.731914520263672px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector04 {
  top: 6.4695048332214355px;
  left: 43.67137908935547px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group2 {
  top: 25.878984451293945px;
  left: -0.000016696129023330286px;
  width: 143.41653442382812px;
  height: 22.104625701904297px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector05 {
  top: 0px;
  left: 0px;
  width: 13px;
  height: 17px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector06 {
  top: 3.7734971046447754px;
  left: 14.556951522827148px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector07 {
  top: 3.7734971046447754px;
  left: 27.4975528717041px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector08 {
  top: 3.7734971046447754px;
  left: 38.817806243896484px;
  width: 11px;
  height: 18px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector09 {
  top: 3.7734971046447754px;
  left: 52.29852294921875px;
  width: 9px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector10 {
  top: 3.7734971046447754px;
  left: 60.92502212524414px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector11 {
  top: 3.7734971046447754px;
  left: 73.32494354248047px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector12 {
  top: 3.7734971046447754px;
  left: 85.72599792480469px;
  width: 11px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector13 {
  top: 3.7734971046447754px;
  left: 99.20443725585938px;
  width: 18px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector14 {
  top: 3.7734971046447754px;
  left: 118.6139144897461px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector15 {
  top: 3.7734971046447754px;
  left: 131.55508422851562px;
  width: 12px;
  height: 13px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group3 {
  top: 24.742467880249023px;
  left: 144.1051025390625px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group4 {
  top: 1.4308146238327026px;
  left: 1.859254240989685px;
  width: 2.3796639442443848px;
  height: 2.8555972576141357px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector16 {
  top: 0px;
  left: 0px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group5 {
  top: 0px;
  left: 0px;
  width: 5.7111945152282715px;
  height: 5.7111945152282715px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector17 {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-group6 {
  top: 0px;
  left: 0px;
  width: 77.92008209228516px;
  height: 77.91993713378906px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector18 {
  top: 1.3724621534347534px;
  left: 0px;
  width: 28px;
  height: 62px;
  position: absolute;
}
.frame6pgina-contexto-crear-lneade-desgravamen-vector19 {
  top: -9.094947017729282e-13px;
  left: 24.692947387695312px;
  width: 53px;
  height: 78px;
  position: absolute;
}
.g-logo-morado{
  width: 120px;
}
.g-logo-blanco{
  width: 100px;
}
</style>

