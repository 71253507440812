<template>
  <div class="frame75persona-cotizarsegurode-desgravamen-card-costoactual">
    <img
      src="/playground_assets/seguros/cotizacion/boundsi1251-ajk9-500h.png"
      alt="BoundsI1251"
      class="frame75persona-cotizarsegurode-desgravamen-bounds"
    />
    <span class="frame75persona-cotizarsegurode-desgravamen-text30">
      <span>{{propuestaLamina.seg_sim_valor_seguro}}</span>
    </span>
    <span class="frame75persona-cotizarsegurode-desgravamen-text32">
      <span>{{propuestaLamina.seg_sim_valor_seguro}}</span>
    </span>
    <div class="frame75persona-cotizarsegurode-desgravamen-group288914">
      <div class="frame75persona-cotizarsegurode-desgravamen-group288953">
        <span class="frame75persona-cotizarsegurode-desgravamen-text34">
          <span>
            Proyectamos que este es el valor de tu seguro de Desgravamen con tu entidad
            financiera:
          </span>
        </span>
      </div>
    </div>
    <div
      class="frame75persona-cotizarsegurode-desgravamen-capturade-pantalla20221111alas141201011"
    >
      <div class="frame75persona-cotizarsegurode-desgravamen-group8">
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-2l4.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector20"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-ksmf.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector21"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-ux5j.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector22"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-tueq.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector23"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-x2y.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector24"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-nsgs.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector25"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-cfcl.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector26"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-p0qm.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector27"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-ltug.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector28"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-j14.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector29"
        />
        <img
          src="/playground_assets/seguros/cotizacion/vectori1251-bkzg.svg"
          alt="VectorI1251"
          class="frame75persona-cotizarsegurode-desgravamen-vector30"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Frame71PersonaCotizarsegurodeDesgravamen',
  components : {
       
  },
  data() {
    return {
      rawcyg3: ' ',
      estadoFormCotizacion: this.$store.state.estadoFormCotizacion,
      propuesta : [
        {

        }
      ]
    }
  },
  props : {
    propuestaLamina : {
      monto : "550.000",
      uf : "14,68"
    }
  }
}
</script>
<style scoped >
.frame75persona-cotizarsegurode-desgravamen-card-costoactual {
  top: 0px;
  left: 0px;
  width: 255px;
  height: 420px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-bounds {
  top: 0px;
  left: 0px;
  width: 255px;
  height: 451px;
  position: absolute;
  border-color: rgba(123, 131, 149, 1);
  border-style: solid;
  border-width: 2.5px;
  border-radius: 20px;
}
.frame75persona-cotizarsegurode-desgravamen-text30 {
  top: 172px;
  /* left: 20px; */
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 50px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
  font-size: 36px;
  margin-left: 8%;
  width: 243px;
}
.frame75persona-cotizarsegurode-desgravamen-text32 {
  top: 226px;
  left: 67px;
  color: var(--dl-color-colores_neutrales-90);
  height: auto;
  position: absolute;
  font-size: 32px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Roboto;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame75persona-cotizarsegurode-desgravamen-group288914 {
  top: 52px;
  left: 26px;
  width: 203px;
  height: 99px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-group288953 {
  top: 0px;
  left: 0px;
  width: 203px;
  height: 99px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-text34 {
  color: var(--dl-color-colores_neutrales-70);
  width: 203px;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame75persona-cotizarsegurode-desgravamen-capturade-pantalla20221111alas141201011 {
  top: 297px;
  left: 72px;
  width: 111px;
  height: 111px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-group8 {
  top: 3.656879186630249px;
  left: 12.46051025390625px;
  width: 86.92200469970703px;
  height: 103.50279235839844px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame75persona-cotizarsegurode-desgravamen-vector20 {
  top: 0px;
  left: 0px;
  width: 71px;
  height: 86px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector21 {
  top: 68.63763427734375px;
  left: 54.823204040527344px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector22 {
  top: 78.79243469238281px;
  left: 71.02151489257812px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector23 {
  top: 87.6129379272461px;
  left: 53.85353469848633px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector24 {
  top: 31.531068801879883px;
  left: 3.500051498413086px;
  width: 64px;
  height: 51px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector25 {
  top: 3.1901943683624268px;
  left: 18.38292121887207px;
  width: 37px;
  height: 20px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector26 {
  top: 26.190034866333008px;
  left: 23.499174118041992px;
  width: 27px;
  height: 2px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector27 {
  top: 71.92605590820312px;
  left: 58.10920715332031px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector28 {
  top: 82.07135009765625px;
  left: 74.2144775390625px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector29 {
  top: 90.90082550048828px;
  left: 57.05131530761719px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector30 {
  top: 36.69210433959961px;
  left: 30.317081451416016px;
  width: 14px;
  height: 26px;
  position: absolute;
}
.frame75persona-cotizarsegurode-desgravamen-vector31 {
  top: 15px;
  right: 22px;
  width: 64px;
  height: 47px;
  position: absolute;
  box-sizing: content-box;
}
</style>
