<template>
<div>
 
    <v-btn
      class="rounded-pill btn-volver"
      x-large
      style="top: 560px;
    width: 250px;
    text-transform: unset !important;
    margin-left: -260px;"
      @click="volver"
    >
      <span class="frame65persona-formulario-lneadesgravamen-text02">
        <span>Volver</span>
      </span>
    </v-btn>
  
  <img
    src="/playground_assets/rectangle23621647-gt8-500h.png"
    alt="Rectangle23621647"
    class="frame65persona-formulario-lneadesgravamen-rectangle2362"
  />
  <img
    src="/playground_assets/unsplashwbgavagjzfg1647-9rmq-400w.png"
    alt="unsplashwBgAVAGjzFg1647"
    class="frame65persona-formulario-lneadesgravamen-unsplashw-bg-a-v-a-gjz-fg"
  />
  <div class="frame65persona-formulario-lneadesgravamen-group288916">
    <span class="frame65persona-formulario-lneadesgravamen-text03" v-if="!clienteExiste">
      <span>Pre aprobado de {{ propuesta }} con las siguientes</span>
      <br />
    </span>
    <span class="frame65persona-formulario-lneadesgravamen-text06" v-if="!clienteExiste">
      <span>De acuerdo a los datos ingresados, te comentamos tienes un</span>
      <br />
    </span>
    <span class="frame65persona-formulario-lneadesgravamen-text06" v-if="clienteExiste">
      <span>De acuerdo a los datos ingresados, Ud. ya posee una Línea Contratada</span>
      <br />
    </span>
    
      <v-btn
        :disabled="clienteExiste"
        color="azulsitio"
        class="rounded-pill"
        x-large
        style="left: 220px; top: 200px; width: 250px; text-transform: unset !important"
        @click="CreaLinea"
      >
        <span class="frame65persona-formulario-lneadesgravamen-text09">
         Crea tu Línea
        </span>
      </v-btn>
   
      <v-dialog v-model="dialog" width="1440px" hide-overlay>
        <template v-slot:activator="{ on }">
          <v-btn
            color=""
            text
            height="0px"
            v-on="on"
            style="text-transform: unset !important"
          >
            <span class="frame65persona-formulario-lneadesgravamen-text11">
              Condiciones y beneficios
            </span>
          </v-btn>
        </template>
        <CondicionesPersonasVue
          style="overflow-y: hidden !important"
        ></CondicionesPersonasVue>
      </v-dialog>
    
  </div>
  <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</div>
</template>
<script>
import CondicionesPersonasVue from "../components/CondicionesPersonas.vue";
import plan from "../../../../services/plan";

export default {
  name: "FormularioLinea4",
  components: {
    CondicionesPersonasVue: CondicionesPersonasVue,
  },
  data() {
    return {
      overlay: false,
      dialog :false ,
      clienteExiste: false,
      propuesta: this.$store.state.propuesta,
      rawacd6: " ",
      antessalir: "",
    };
  },
  methods: {
    volver() {
      this.$router.push("crear-degravamen");
    },
    CreaLinea() {
      if(this.$store.state.estadoCliente.tiene_linea_desgravamen){
        this.$root.$refs.FormularioLinea.estadoFormLinea=7;
      }else{
      if(this.$store.state.estadodps){
        this.$root.$refs.FormularioLinea.estadoFormLinea=5;
      }else{
        this.$root.$refs.FormularioLinea.estadoFormLinea=6;
      }    
    }  
    },
    closeDialog() {
      this.dialog = false;
    },
    async traeDps(){
      this.overlay =true;
      let res = false;      
      var plancliente=this.$store.state.plan;
      var estadoDps=this.$store.state.estadodps;
      if(estadoDps){
      res = await plan.traedps(plancliente);
      console.log(res);
        if(res.status==200 && res.data.estadoTransaccion.success){
          // this.$alertas(res.data.estadoTransaccion.errors,"success");
          let preg= res.data.preguntas.length;
          if(!preg){
            this.$store.commit('setestadodps', false);
            return;
          }
          this.$store.commit('setdps', res.data.preguntas);
          var dps = this.$store.state.dps;
          this.$root.$refs.FormularioLinea.cargaDps();
          var contDps=this.$store.state.dps.length;
          // this.$root.$refs.BpsLinea.contDps=contDps;
        }else{
          // this.$alertas("En este momento no podemos atender su solicitud","warning");
          this.$store.commit('setestadodps', false);
        }
       
      }
      this.overlay =false;
    },
    async traeTerminos(){
      this.overlay =true;
      let res = false;      
      var plancliente=this.$store.state.plan;
      res = await plan.traeterminos(plancliente);
      console.log(res);
      if(res.status==200){       
        this.$store.commit('setterminos', res.data.terminoscondicion[0]);     
        console.log(res.data.terminoscondicion[0]);
        this.$root.$refs.Terminos.cargaTerminos();    
      }else{
        this.$alertas("En este momento no podemos atender su solicitud","warning");
      }
      this.overlay =false;
      
    }
  },
  mounted() {
  
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
  created() {
    // console.log(this.$store.state.clienteEncontrado);
    this.$root.$refs.PreaprobacionLinea = this;
  },
};
</script>
<style scoped>
.frame65persona-formulario-lneadesgravamen-text02 {
  top: -20px;
  left: 10px;
  color: var(--dl-color-colores_neutrales-dark);
  width: 192px;
  height: auto;
  position: absolute;
  flex-grow: 1;
  font-size: 30px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-rectangle2362 {
  top: 293px;
  /* left: 162px; */
  width: 1173px;
  height: 440px;
  position: absolute;
  border-color: transparent;
  margin-left: -270px;
}
.frame65persona-formulario-lneadesgravamen-unsplashw-bg-a-v-a-gjz-fg {
  top: 293px;
  /* left: 159px; */
  width: 301px;
  height: 412px;
  position: absolute;
  border-color: transparent;
  margin-left: -270px;
}
.frame65persona-formulario-lneadesgravamen-group288916 {
  top: 411px;
  /* left: 540px; */
  width: 686px;
  height: 279px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  /* margin-right: 0; */
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  margin-left: 117px;
}
.frame65persona-formulario-lneadesgravamen-text03 {
  top: 36px;
  color: var(--dl-color-colores_neutrales-90);
  right: -3px;
  width: 686px;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: none;
  width: 800px;
  left: -55px;
}
.frame65persona-formulario-lneadesgravamen-text06 {
  color: var(--dl-color-colores_neutrales-90);
  width: 686px;
  height: auto;
  position: absolute;
  font-size: 25px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Roboto;
  font-weight: 500;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame65persona-formulario-lneadesgravamen-text11 {
  top: 80px;
  /* left: 170px; */
  color: rgb(80, 154, 255);
  height: auto;
  position: absolute;
  font-size: 25px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 95%;
  font-stretch: normal;
  text-decoration: underline;
  margin-left: 120px;
}
.ctrans {
  background-color: transparent !important;
  height: 0px !important;
  flex: none !important;
}
</style>