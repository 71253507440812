<template>
  <div class="frame21persona-formulario-lneadesgravamen-imputformulariot1">
    <div>
      <v-form ref="form" v-model="valid">
        
          <v-row dense >
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-select
                v-model="seleccionTipoInstitucion"
                :items="instituciones"
                rounded
                outlined
                dense
                label="TIPO DE INSTITUCIÓN"
                color="rgb(56,0,151)"
                :rules="tipoInstitucionRules"
                v-on:change="selectTipoInstitucion"
                :disabled="tipodisabled"
                style="cursor: pointer"
              >
                <template v-slot:append>
                  <v-icon
                    color="rgba(0, 23, 135, 1)"
                    v-if="validtipoinstitucion"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-select
                v-model="seleccionNombreInstitucion"
                :items="nombreintituciones"
                rounded
                outlined
                dense
                label="NOMBRE DE INSTITUCIÓN"
                color="rgb(56,0,151)"
                :rules="nombreInstitucionRules"
                v-on:change="selectNombreInstituciones"
                :disabled="tipodisabled"
              >
                <template v-slot:append>
                  <v-icon
                    color="rgba(0, 23, 135, 1)"
                    v-if="validnombreinstitucion"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </template>
              </v-select>
            </v-col>
            <!-- <v-col
                v-if="tipo == `vigente`"
                cols="12"
                sm="12"
                @click="selectSaldoInsoluto"
              >
                <v-text-field
                  v-if="saldoinsoluto"
                  :rules="saldoInsolutoRules"
                  label="Saldo insoluto del crédito"
                  required
                  filled
                  dense
                  style="cursor: pointer"
                  color="rgb(56,0,151)"
                  :disabled="tipodisabled"
                >
                </v-text-field>
                <v-currency-field
                  ref="saldoinsoluto"
                  v-show="!saldoinsoluto"
                  v-model="form4.saldoInsolutoCredito"
                  :rules="saldoInsolutoRules"
                  required
                  label="Saldo insoluto del crédito"
                  dense
                  filled
                  color="rgb(56,0,151)"
                  :prefix="prefix"
                  @update:error="vsaldoinsoluto"
                  :disabled="tipodisabled"
                >
                <template v-slot:append>
                    <v-icon
                      color="rgba(0, 23, 135, 1)"
                      v-if="!validsaldoinsoluto"
                      style="margin-bottom: 10px !important"
                    >
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon
                      v-else-if="validsaldoinsoluto"
                      color="red"
                      style="margin-bottom: 10px !important "
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-currency-field>
              </v-col> -->
            <v-col v-if="tipo != `inicio`" cols="12" sm="12" md="12" lg="12" xl="12"  @mouseover="selectSaldoIni">
              <v-text-field
                v-if="saldoini"
                :rules="saldoCreditoRules"
                label="NRO. CUOTAS ORIGINALES"
                
                rounded
                outlined
                dense
                style="cursor: pointer"
                color="rgb(56,0,151)"
                :disabled="tipodisabled"
              >
              </v-text-field>
              <v-currency-field
                ref="saldo"
                v-show="!saldoini"
                v-model="form4.saldoCredito"
                :rules="saldoCreditoRules"
                required
                rounded
                outlined
                dense
                
                label="MONTO ORIGINAL DEL CRÉDITO (CPL)"
                color="rgb(56,0,151)"
                :prefix="prefix"
                @update:error="vsaldocredito"
                :disabled="tipodisabled"
              >
                <template v-slot:append>
                  <v-icon
                    color="rgba(0, 23, 135, 1)"
                    v-if="!validsaldocredito"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon
                    v-else-if="validsaldocredito"
                    color="red"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
              </v-currency-field>
            </v-col>
            <v-col v-if="tipo != `inicio`" cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="form4.mesesPago"
                ref="mespago"
                :rules="mesPagoRules"
                :disabled="tipodisabled"
                label="NRO. CUOTAS ORIGINALES"
                required
                rounded
                outlined
                dense
                min="1"
                max="9999"
                append-icon="mdi-check-circle"
                color="rgb(56,0,151)"
                type="number"
                class="w100"
                @update:error="vmespago"
              >
                <template v-slot:append>
                  <div v-if="validmespago == `no`"></div>
                  <v-icon
                    v-else-if="!validmespago"
                    color="m100"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon
                    v-else-if="validmespago"
                    color="red"
                    style="margin-bottom: 10px !important"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
              <div class="line-1"></div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                    <div class="frame-32-2">
                    <v-row class="colcenter">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" id="btn-credito-1-r" outlined rounded x-large @click="volver"><span
                            ><span>REGRESAR</span></span></v-btn>
                        </v-col>      
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                        <v-btn class="sm-size" id="btn-credito-1" rounded x-large @click="validate" color="#1d2eff" :disabled="!valid"><span
                            ><span style="color:white">CONTINUAR</span></span></v-btn>
                        </v-col>                 
                    </v-row>                  
                    
                    </div>
            </v-col> 

           
            
          </v-row>
       
      </v-form>
    </div>
  </div>
</template>
<script>
import datausuario from "../../../../../services/usuario-datos";
import institucion from "../../../../../services/intitucion";

export default {
  name: "CotizaForm3",
  components: {},
  data() {
    return {
      tipodisabled: false,
      saldoini: this.$store.state.form4.saldoCredito ? false : true,
      
      errors: {},
      prefix: "$",
      instituciones: [],
      nistituciones: [],
      nombreintituciones: [],
      seleccionTipoInstitucion: "",
      seleccionNombreInstitucion: "",
      codInst: 0,
      codNInst: 0,
      ncod: [],
      validtipoinstitucion: this.$store.state.form4.tipoInstitucion ? true : false,
      validnombreinstitucion: this.$store.state.form4.nombreInstitucion ? true : false,
      validsaldocredito: true,
      validmespago: "no",
    
      form4: {
        tipoInstitucion: this.$store.state.form4.tipoInstitucion,
        nombreInstitucion: this.$store.state.form4.nombreInstitucion,
        saldoCredito: this.$store.state.form4.saldoCredito,
        mesesPago: this.$store.state.form4.mesesPago,
       
      },
      antessalir: "",
      valid: true,
      tipoInstitucionRules: [
        (v) => !!v || "el tipo institución es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      nombreInstitucionRules: [
        (v) => !!v || "el nombre institución es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
      ],
      saldoCreditoRules: [
        (v) => !!v || "el campo saldo credito es requerido",
        (v) => (v && v.length <= 100) || "largo máximo 100 caracteres",
        (v) => (v && v != "0") || "el saldo debe ser mayor a 0",
        (v) =>
          (v && this.validarMaxCredito(v)) ||
          "monto máximo para este credito es de $ " +
            this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_maximo
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
        (v) =>
          (v && this.validarMinCredito(v)) ||
          "monto mínimo para este credito es de $ " +
            this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_minimo
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."),
      ],
      mesPagoRules: [
        (v) => !!v || "meses total credito es requerido",
        (v) => (v && this.validarMaxTotal(v)) || "valor máximo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_maximo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
        (v) => (v && this.validarMinTotal(v)) || "valor mínimo para este campo es de "+this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_minimo.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.")+" meses",
      ],
     
    };
  },
  props: {
    tipo: "",
  },
  methods: {
    validarMinTotal(v) {
      this.form4.mesesPago=parseInt(v);
      if (parseInt(v) < parseInt(this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_minimo)) {
        return false;
      }
      return true;
    },
    validarMaxTotal(v) {
      this.form4.mesesPago=parseInt(v);
      if (parseInt(v) > parseInt(this.$root.$refs.CotizaForm.restricciones[0].tmn_cuotas_original_maximo)) {
        return false;
      }
      return true;
    },
   
    validarMinCredito(v) {
      v = v.replace(/[^0-9]/g, "");
      if (v < this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_minimo) {
        return false;
      }
      return true;
    },
    validarMaxCredito(v) {
      v = v.replace(/[^0-9]/g, "");
      if (v > this.$root.$refs.CotizaForm.restricciones[0].tmn_valor_credito_maximo) {
        return false;
      }
      return true;
    },
    
    selectSaldoIni() {
      this.saldoini = false;
      this.$refs["saldo"].$refs.textfield.focus();
      this.$refs["saldo"].$refs.textfield.focus();
    },
    selectMesPago(e) {
      this.validmespago = true;
      this.$refs["mespago"].$refs.textfield.focus();
    },
    selectValorCuota() {
      this.cuotaini = false;
      this.$refs["cuota"].$refs.textfield.focus();
    },
    formatAsCurrency(value, dec) {
      dec = dec || 0;
      if (value === null) {
        return 0;
      }

      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    selectTipoInstitucion(e) {
      this.validtipoinstitucion = true;
      this.form4.tipoInstitucion = e;
      let nombresInst = [];
      let ncod = [];
     
      this.nistituciones.forEach((element) => {
        if (element.tipo == e) {
          nombresInst = element.nombre;
          this.codInst = element.cod;
          ncod = element.ncod;
        }
      });
      this.nombreintituciones = nombresInst;
      this.ncod = ncod;
      this.$store.commit("setnombreinst", nombresInst);
    },
    selectNombreInstituciones(e) {
      this.validnombreinstitucion = true;
      this.form4.nombreInstitucion = e;
      this.ncod.forEach((element) => {
        if (element.nombre == e) {
          this.codNInst = element.cod;
          this.selectSaldoIni();
        }
      });
      this.$store.commit("setcodinst", this.codInst);
      this.$store.commit("setcodninst", this.codNInst);
    },
    vsaldocredito(v) {
     
      this.validsaldocredito = v;
    },
    vmespago(v) {
      this.validmespago = v;
    },
   

    async guardaUsuario(data) {
      let actualiza = "refresh";
      let res = false;
      res = await datausuario.actualizar(data);
      
      this.$store.commit("setform1", res.data.usuario);
      this.$alertas(res);
    },

    btnleft: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-left");
      }, 150);
    },
    btnleftout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-left");
      }, 150);
    },
    btnright: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.add("boton-right");
      }, 150);
    },
    btnrightout: function (name) {
      var el = document.querySelector("." + name);
      setTimeout(() => {
        el.classList.remove("boton-right");
      }, 150);
    },
    validate() {
      if (this.$refs.form.validate()) {
        
          this.$store.commit("setform4", this.form4);
          this.$root.$refs.CotizaForm.estadoFormCotizacion = 4;
         
        
      }
    },

    async guardaUsuario(data) {
      this.overlay = true;
      let res = false;
      var tipo = 1;
      if (this.$store.state.tipoPersonaEmpresa == "empresa") {
        tipo = 2;
      }
      
      var nombreinstitucion=this.$store.state.form4.nombreInstitucion;
      if(tipo==1){
      var rutCompleto = this.$store.state.form1.rut.split(".").join("");
      rutCompleto = rutCompleto.replace(/\./g, "");
      rutCompleto = rutCompleto.split("-");
      var rut = parseInt(rutCompleto[0]);
      var dv = rutCompleto[1];
      var cel = this.$store.state.form2.celular;
      cel = cel.replace("(", "");
      cel = cel.replace(")", "");
      cel = cel.replace(" ", "");
      cel = cel.replace(" ", "");
      }else{
      var rutCompleto = this.$store.state.forme2.rut.split(".").join("");
      rutCompleto = rutCompleto.replace(/\./g, "");
      rutCompleto = rutCompleto.split("-");
      var rut = parseInt(rutCompleto[0]);
      var dv = rutCompleto[1];
      var cel = this.$store.state.forme1.telefono;
      cel = cel.replace("(", "");
      cel = cel.replace(")", "");
      cel = cel.replace(" ", "");
      cel = cel.replace(" ", "");

      var rutCompletoe = this.$store.state.forme1.rut.split(".").join("");
      rutCompletoe = rutCompletoe.replace(/\./g, "");
      rutCompletoe = rutCompletoe.split("-");
      var rute = parseInt(rutCompletoe[0]);
      var dve = rutCompletoe[1];
  
      }
    
      let tipoCredito = this.$root.$refs.CotizaForm.tipoCotizacion;
      var montoCredito = this.form4.saldoCredito;
      var montoInsolutoCredito = 0;
      var mesespago = this.form4.mesesPago;
      var mesespendientepago = 0;
      var periodoGracia = 0;
     
      if(periodoGracia=== undefined){
         periodoGracia = 0;
      } 
      if(mesespago=== undefined){
        mesespago = 0;
      } 
      if(mesespendientepago=== undefined){
        mesespendientepago = 0;
      }     
      
      var valorcuota= 0;
      
      var mpago = parseInt(mesespago);
      var mpendiente = parseInt(mesespendientepago);
      var mgracia = parseInt(periodoGracia);      
      var codInst= this.$store.state.codInst;
      var codNInst= this.$store.state.codNInst;
      if (this.tipo == "nuevo") {
        tipoCredito = 1;
      }
      if (this.tipo == "vigente") {
        tipoCredito = 0;
      }
      if(tipo==1){
      var usuario = {
        seg_tipo_cliente: tipo,
        seg_simulacion_numero:0,
        ctt_numero:0,
        detalleCredito: {
          seg_usu_credito_nuevo: tipoCredito,
          seg_usu_tipo_institucion: codInst,
          seg_usu_institucion: codNInst,
          seg_usu_valor_prestamo: montoCredito,
          seg_sim_valor_cuota_seguro: valorcuota,
          seg_usu_meses_originales: mpago,         
          seg_usu_periodo_gracia: mgracia,
          seg_usu_saldo_insoluto: montoInsolutoCredito,
          seg_usu_cuotas_pendientes: mpendiente,

        },
        personaJuridica: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.form1.nombre1,
          per_nombre_segundo: this.$store.state.form1.nombre2,
          per_ape_paterno: this.$store.state.form1.apaterno,
          per_ape_materno: this.$store.state.form1.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.form2.date),
          per_celular: cel,
          per_fono: "",
          per_email: this.$store.state.form2.email,
          per_estado: 1,
          per_pais : this.$store.state.form1.nacionalidad,
          per_genero : this.$store.state.form1.genero,
          per_ocupacion : this.$store.state.form2.ocupacion,
        },
        personaRepresentante: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.form1.nombre1,
          per_nombre_segundo: this.$store.state.form1.nombre2,
          per_ape_paterno: this.$store.state.form1.apaterno,
          per_ape_materno: this.$store.state.form1.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.form2.date),
          per_celular: cel,
          per_fono: "",
          per_email: this.$store.state.form2.email,
          per_estado: 1,
          per_pais : this.$store.state.form1.nacionalidad,
          per_genero : this.$store.state.form1.genero,
          per_ocupacion : this.$store.state.form2.ocupacion,
        },
      }
    }
    else{
      var usuario = {
        seg_tipo_cliente: tipo,
        seg_simulacion_numero:0,
        ctt_numero:0,
        detalleCredito: {
          seg_usu_credito_nuevo: tipoCredito,
          seg_usu_tipo_institucion: codInst,
          seg_usu_institucion: codNInst,
          seg_usu_valor_prestamo: montoCredito,
          seg_sim_valor_cuota_seguro: valorcuota,
          seg_usu_meses_originales: mpago,         
          seg_usu_periodo_gracia: mgracia,
          seg_usu_saldo_insoluto: montoInsolutoCredito,
          seg_usu_cuotas_pendientes: mpendiente,

        },
        personaJuridica: {
          per_rut: rute,
          per_dv: dve,
          per_tipo_cliente: 2,
          per_nombre_principal: this.$store.state.forme1.nombre1,
          per_nombre_segundo: "",
          per_ape_paterno: "",
          per_ape_materno :"",
          per_fec_nac: "2003-09-01",
          per_celular: cel,
          per_fono: cel,    
          per_email: this.$store.state.forme1.email,
          per_estado: 1,
          per_pais : this.$store.state.forme2.nacionalidad,
          per_genero : this.$store.state.forme2.genero,
          per_ocupacion : this.$store.state.forme2.ocupacion,
        },
        personaRepresentante: {
          per_rut: rut,
          per_dv: dv,
          per_tipo_cliente: tipo,
          per_nombre_principal: this.$store.state.forme2.nombre1,
          per_nombre_segundo: this.$store.state.forme2.nombre2,
          per_ape_paterno: this.$store.state.forme2.apaterno,
          per_ape_materno: this.$store.state.forme2.amaterno,
          per_fec_nac: this.parseDate(this.$store.state.forme2.date),
          per_celular: cel,
          per_fono: cel,
          per_email: this.$store.state.forme1.email,
          per_estado: 1,
          per_pais : this.$store.state.forme2.nacionalidad,
          per_genero : this.$store.state.forme2.genero,
          per_ocupacion : this.$store.state.forme2.ocupacion,
        },

      }
    }
      
      
      if(tipoCredito){
        
        res = await datausuario.simulacionCreditoNuevo(usuario);
      }else{
        res = await datausuario.simulacionCreditoVigente(usuario);
      }
      
      console.log(res);
      if (res.status == 200) {
        this.$alertas(res.data.estadoTransaccion.errors, "success");
        
        if (res.data.estadoTransaccion.success) {
          if(tipoCredito){
          
          this.overlay = false;
          this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;
          }else{
          var propuestaCreditoVigente = res.data.parrillaSeguro;
          this.$store.commit("setseguroseleccion", res.data.parrillaSeguro.seg_numero);
    
       this.$store.commit("setpropuestacreditovigente", propuestaCreditoVigente);
       var documentos=false;
       var res2 =false;
       var seguro = {
                     seg_numero: res.data.parrillaSeguro.seg_numero
                    }
       res2 = await datausuario.traeDocumentos(seguro);
       if (res2.status == 200) {
        this.$alertas(res2.data.estadoTransaccion.errors, "success");
        documentos=res2.data.doctoRespuesta;
        this.$store.commit("setdocumentos", documentos);
      } else {
        this.$alertas(
          "En este momento no podemos atender su solicitud",
          "warning"
        );
      }

       this.overlay = false;
       this.$root.$refs.CotizaForm.estadoFormCotizacion = 15;
          }

         
         
        }else{
          this.overlay = false;
          this.$root.$refs.CotizaForm.estadoFormCotizacion = 99;
        }
      } else {
        this.$alertas(
          "En este momento no podemos atender su solicitud",
          "warning"
        );
      }
      console.log(this.$store.state.simulacion);
      this.overlay = false;
      
    },
    parseDate(date) {
      if (!date) return null;

      const [day,month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    volver() {
      if (this.$root.$refs.CotizaForm.isLogin) {
        this.$router.push("/mi-cuenta");
      } else {
        this.$root.$refs.CotizaForm.estadoFormCotizacion = 2;
      }
    },
    hover: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3839484-hdtp.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3839484-hdtp.svg";
      }
    },
    hoverout: function (name, img) {
      if (img == 1) {
        this.imgfondo1 = "/playground_assets/rectangle3829484-upr6.svg";
      } else {
        this.imgfondo2 = "/playground_assets/rectangle3829484-upr6.svg";
      }
    },
    async traeIntituciones() {
      let res = false;
      res = await institucion.traeinstituciones();
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        // console.log("entra");
        let inst = [];
        let ninst = [];
        let ncod = [];
        let nombreinst = [];
        res.data.instituciones.forEach((element) => {
          // console.log(element);
          ninst = [];
          inst.push(element.tin_glosa);
          if (element.institucion) {
            element.institucion.forEach((i) => {
              // console.log(i);
              ninst.push(i.int_nombre);
              ncod.push({ nombre: i.int_nombre, cod: i.int_numero });
            });
          }
          nombreinst.push({
            tipo: element.tin_glosa,
            cod: element.tin_numero,
            nombre: ninst,
            ncod: ncod,
          });
        });

        this.instituciones = inst;
        this.nistituciones = nombreinst;
        this.$store.commit("setinstituciones", inst);
        this.$store.commit("setnombreinst", nombreinst);
        
        return;
      } else {
        this.$store.commit("setclienteencontrado", false);

        this.$alertas("En este momento no podemos atender su solicitud", "warning");
      }
    },
  },
  computed: {
    initialBalanceFormatted: {
      get: function () {
        return this.formatAsCurrency(this.initialBalance, 0);
      },
      set: function (newValue) {
        console.log(newValue);

        this.initialBalance = Number(newValue.replace(/[^0-9\.]/g, ""));
        this.form4.saldoCredito = this.initialBalance;
        this.form4.valorCuota = this.initialBalance;
      },
    },
  },
  mounted() {
    this.traeIntituciones();
    console.log(this.$store.state.form4);
  },
  created() {
    this.$root.$refs.CotizaForm3 = this;
  },
  metaInfo: {
    title: "Mueve Seguro",
  },
};
</script>
<style scoped>
.colcenter{
  display: flex;
  justify-content: center;
}
.ctrans {
  background-color: transparent !important;
  height: 10% !important;
  flex: none !important;
}

.frame21persona-formulario-lneadesgravamen-imputformulariot1 {
  /* top: 410px; */
  /* left: 457px; */
  width: 100%;
  display: flex;
  position: relative;
  /*align-items: flex-start;*/
  border-color: transparent;
  flex-direction: column;
  /* margin-left: 20px; */
}

.frame-32-2 {
  /* background: #d9d9d9; */
  border-radius: 80px;
  padding: 9px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.line-1 {
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 100%;
  height: 0px;
  position: relative;
}
@media only screen and (max-width: 800px) {
  .sm-size{
    font-size: small !important;
 }
    
}
</style>
