import { render, staticRenderFns } from "./Homepp.vue?vue&type=template&id=1edf916c&scoped=true&"
import script from "./Homepp.vue?vue&type=script&lang=js&"
export * from "./Homepp.vue?vue&type=script&lang=js&"
import style0 from "./Homepp.vue?vue&type=style&index=0&id=1edf916c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1edf916c",
  null
  
)

export default component.exports