<template>
    <div>
        <v-row>
           
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="cuadro1">
                        <v-row class="colcenter botoniz">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter rectangle-49">
                                <div class="conexi-n-directa ">Conexión directa</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter rectangle-50">

                                <v-row class="colcenter botoniz">

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <div class="conexi-n-en-l-nea-con-la-p-gina-de-tu-banco">
                                            Conexión en línea con
                                            <br>
                                            la página de tu banco
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <v-row class="colcenter">
                                            <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
                                                <v-btn rounded large block @click="seleccionaMedio(1)"
                                                    color="#04137f"><span><span
                                                            style="color:white">Seleccionar</span></span></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="cuadro1">
                        <v-row class="colcenter botoniz">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter rectangle-49">
                                <div class="conexi-n-directa ">Carga de documento</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter rectangle-50">

                                <v-row class="colcenter botoniz">

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <div class="conexi-n-en-l-nea-con-la-p-gina-de-tu-banco">
                                            Selecciona documentos
                                            <br />
                                            desde tu computador
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter"></v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                                        <v-row class="colcenter">
                                            <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="colcenter">
                                                <v-btn rounded large block @click="seleccionaMedio(2)"
                                                    color="#04137f"><span><span
                                                            style="color:white">Seleccionar</span></span></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                        <div class="line-1"></div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="colcenter">
                        <div class="frame-32-2">
                            <v-row class="colcenter">
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn class="sm-size" outlined rounded x-large
                                        @click="volver"><span><span>REGRESAR</span></span></v-btn>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="colcenter">
                                    <v-btn class="sm-size" rounded x-large color="#1d2eff" @click="continuar"
                                        :disabled="externo"><span><span
                                                style="color:white">CONTINUAR</span></span></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
           
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-form ref="form" v-model="valid" v-if="dialog">
                <v-card class="colordialog">
                    <v-card-title>
                        <span class="text-h5">Ingrese a su cuenta de banco</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">

                                </v-col>
                                <v-col cols="12" sm="6" md="4">

                                </v-col>
                                <v-col cols="12" sm="6" md="4">

                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="rut" :rules="rules" label="Rut" required @update:error="vrut"
                                        color="rgba(0, 23, 135, 1)" outlined style="border-radius: 10px;">
                                        <template v-slot:append>
                                            <div v-if="validrut == `no`"></div>
                                            <v-icon v-else-if="!validrut" color="m100"
                                                style="margin-bottom: 10px !important ;">
                                                mdi-checkbox-marked-circle
                                            </v-icon>
                                            <v-icon v-else-if="validrut" color="red"
                                                style="margin-bottom: 10px !important ;">
                                                mdi-close-circle
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined required color="rgba(0, 23, 135, 1)"
                                        style="border-radius: 10px;" v-model="password"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="claveRules"
                                        @update:error="vclave" :type="show1 ? 'text' : 'password'" name="input-10-1"
                                        label="Clave" @click:append="show1 = !show1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">

                                </v-col>
                                <v-col cols="12" sm="6">

                                </v-col>
                            </v-row>
                        </v-container>
                        <small>Datos de privacidad</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false">
                            Salir
                        </v-btn>
                        <v-btn color="rgba(255, 184, 78)" rounded @click="validate" :disabled="!valid">
                            Buscar datos
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import privado from "../../../../../services/privado";

export default {
    name: "DevolucionSeguro",
    components: {

    },
    data() {
        return {
            documentos: this.$store.state.documentos,
            externo: false,
            colorext1: "",
            colorext2: "juanito",
            dialog: false,
            datosContrato: [],
            overlay: false,
            valid: false,
            rut: "",
            clave: "",
            validrut: "no",
            validclave: "no",
            claveRules: [
                v => !!v || 'el campo Clave es requerido',
                (v) => (v && v.length >= 4) || "largo mínimo 8 caracteres",

            ],
            show1: false,
            password: '',
            rulesPass: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 4 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },

        }
    },
    props: {
        renuncia: 0,
    },
    methods: {
        async validate() {

            if (this.$refs.form.validate()) {
                this.dialog = false;
                this.consultaScrapping();
            }
        },
        espera() {
            setTimeout(function () {
                return "ok";
            }, 4000);
        },
        vrut(v) {
            this.validrut = v;

        },
        vclave(v) {
            this.validclave = v;
        },
        validaRut: function (rutCompleto) {
            if (rutCompleto && rutCompleto != "") {
                rutCompleto = rutCompleto.replace(/\./g, '');
            }
            if (!rutCompleto) {
                this.rut = "";
            }
            if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto)) return false;
            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            let rev = rut + "" + digv;
            let sw = true;
            let mem = false;
            for (let index = 0; index < rev.length; index++) {
                if (!index) {
                    mem = rev.substring(index, 1);
                    console.log("mem:" + mem);
                } else {
                    if (mem != rev.substring(index, index + 1)) {
                        sw = false;
                        break;
                    } else {
                        console.log("err:" + rev.substring(index, 1));
                    }
                }
            }

            if (sw) { return false; }
            if (digv == "K") digv = "k";
            return this.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },

        formateaRut(rut) {
            if (!rut || rut == "") {
                return;
            }
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 0) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return this.rut = rutPuntos;
        },
        largorut(rut, largo) {
            if (rut && rut.length > largo) {
                rut = rut.substring(0, rut.length - 1);
            }
            this.rut = rut.replace(/[^kK0-9.-]/gi, "");

            return true;
        },
        seleccionaMedio(op) {
            this.colorext1 = "";
            this.colorext2 = "";
            if (op == 1) {
                this.externo = true;
                this.colorext1 = "juanito";
                this.rut = "";
                this.clave = "";
                this.validrut = "no";
                this.validclave = "no";
                this.dialog = true;
                // this.$refs.form.reset();
            }
            if (op == 2) {
                this.externo = false;
                this.colorext2 = "juanito";
            }
        },
        async consultaScrapping() {
            let res = false;
            this.overlay = true;
            let data = {
                ctt_rut: this.rut,
                ctt_clave: this.clave,
                ctt_seguro: this.$store.state.propuestaCreditoNuevo.spl_seg_numero,
            }
            this.$root.$refs.CotizaForm.traeDocumentos(1);
            this.$root.$refs.CotizaForm.traeDocumentos(2);
            res = await privado.ingresaScrapping(data);
            if (res.status == 200 && res.data.estadoTransaccion.success) {
                if (res.data.respuesta) {
                    this.overlay = false;
                    this.$root.$refs.CotizaForm.estadoFormCotizacion = 20;
                    this.$alertas("Datos extraidos exitosamente", "success");
                } else {
                    this.$alertas("Suba los documentos manualmente", "warning");
                    this.seleccionaMedio(2);
                    this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
                }
            } else {
                this.$alertas("No se pudieron obtener los datos", "info");
            }
            this.overlay = false;
        },
        volver() {
            if (this.$store.state.estadoPrivado) {
                window.history.back();
            } else {
                this.$root.$refs.CotizaForm.estadoFormCotizacion = 17;
            }

        },
        continuar() {
            this.$root.$refs.CotizaForm.traeDocumentos(1);
            this.$root.$refs.CotizaForm.traeDocumentos(2);
            this.$root.$refs.CotizaForm.estadoFormCotizacion = 19;
        },
    },
    computed: {
        rules() {
            const rules = [];
            let rule = "";
            rule = (v) => (v && v.length >= 11) || "largo mínimo 11 caracteres";
            rules.push(rule);
            rule = (v) => (v && this.largorut(v, 12)) || "largo máximo 12 caracteres";
            rules.push(rule);
            rule = (v) => !!v || "el rut es requerido";
            rules.push(rule);
            rule = (v) => this.validaRut(this.rut) || `rut inválido`;
            if (this.rut != "") {
                this.formateaRut(this.rut);
            }
            rules.push(rule);

            return rules;
        },
    },
}
</script>
<style scoped>
.cuadro1 {
    padding: clamp(0.5rem,5vw,2.7rem);
}

.colcenter {
    display: flex;
    justify-content: center;
}

.frame-58 {
    flex-shrink: 0;
    width: 100%;
    height: 302px;
    position: relative;
}

.rectangle-49 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    width: 100%;
    height: 104px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    /* left: 0px;
  top: 0px; */
}

.rectangle-50 {
    background: #d9d9d9;
    border-radius: 0px 0px 40px 40px;
    width: 100%;
    /* height: 198px; */
    position: relative;
    /* left: 0px;
  top: 104px; */
}

.conexi-n-directa {
    color: var(--blanco, #f0f2f2);
    /* text-align: left; */
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 22px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    /* left: 79px;
  top: 38px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.conexi-n-en-l-nea-con-la-p-gina-de-tu-banco {
    color: var(--azul-complementario, #04137f);
    text-align: center;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    /* font-size: var(--headings-h5-font-size, 22px);
    line-height: var(--headings-h5-line-height, 28px); */
    font-size:clamp(0.8rem,5vw,1.2rem) ;
    line-height: clamp(1rem,5vw,1.5rem);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    /* left: 44px;
  top: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.rectangle-53 {
    background: var(--azul-complementario, #04137f);
    border-radius: 52px;
    width: 100%;
    height: 62px;
    position: relative;
    /* left: 34px;
  top: 224px; */
}

.seleccionar {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
    font-size: var(--headings-h5-font-size, 24px);
    line-height: var(--headings-h5-line-height, 28px);
    font-weight: var(--headings-h5-font-weight, 400);
    position: relative;
    /* left: 103px;
  top: 241px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.rectangle-49 {
    background: var(--azul-complementario, #04137f);
    border-radius: 40px 40px 0px 0px;
    width: 100%;
    height: 104px;
    position: relative;
    /* left: 0px;
  top: 0px; */
}

.botoniz {
    display: flex;
    /* align-self: baseline; */
    height: 100%;
    /* margin-top: 50px; */
}

.frame-32-2 {
    border-radius: 80px;
    padding: 9px 20px 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.line-1 {
    border-style: solid;
    border-color: var(--azul-complementario, #04137f);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 100%;
    height: 0px;
    position: relative;
}
@media only screen and (max-width: 800px) {
  .sm-size{
    font-size: small ;
 }
  .frame-32-2 {
    /* width : 120% !important; */
  }
  
}
</style>