import { render, staticRenderFns } from "./Fin.vue?vue&type=template&id=89c10368&scoped=true&"
import script from "./Fin.vue?vue&type=script&lang=js&"
export * from "./Fin.vue?vue&type=script&lang=js&"
import style0 from "./Fin.vue?vue&type=style&index=0&id=89c10368&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c10368",
  null
  
)

export default component.exports