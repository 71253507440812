<template>
  <div class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-container">
    <div
      class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen"
    >
      <img
        src="/playground_assets/CondicionesPersonas/rectanguloarribarellenalasiguienteencuesta1251-m5ka-200h.png"
        alt="RectanguloarribaRellenalasiguienteencuesta1251"
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta"
      />
      <div
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288878"
      >
        <span
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text"
        >
          <span>BENEFICIOS</span>
        </span>
        <span
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text02"
        >
          <span>
            Condiciones y
            
          </span>
        </span>
      </div>
      <img
        src="/playground_assets/CondicionesPersonas/rectanguloarribarellenalasiguienteencuesta1251-zfa5-400h.png"
        alt="RectanguloarribaRellenalasiguienteencuesta1251"
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta1"
      />
      <span
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text04"
      >
        <span
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text05"
        >
          <span>Info!</span>
          <br />
          <span></span>
        </span>
        <span
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text09"
        >
          <span>
            Lorem Ipsum is simply dummy text of the printing and
            
          </span>
          <br />
          <span></span>
        </span>
        <span>Seguro de Desgravamen On line.</span>
      </span>
      <div
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288890"
      >
        <img
          src="/playground_assets/CondicionesPersonas/rectangle23611251-wzap-200h.png"
          alt="Rectangle23611251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectangle2361"
        />
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group"
        >
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group01"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group02"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-5lf3.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ab3a.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector001"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-rmr8.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector002"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-krph.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector003"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-oqp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector004"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-s39p.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector005"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-60ie.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector006"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-obrl.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector007"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-y11.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector008"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-1bou.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector009"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-quom.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector010"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-75i9bp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector011"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-8spf.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector012"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-b7rwq.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector013"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-g3jt.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector014"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-5iha.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector015"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-7i5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector016"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-hvw.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector017"
            />
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group03"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-r6p7.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector018"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-80an.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector019"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-c93m.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector020"
              />
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group04"
              >
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-3ci.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector021"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-ppck.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector022"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-e8f.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector023"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-op2h.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector024"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-zxb.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector025"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-xxd9.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector026"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-x79q.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector027"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-4g2.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector028"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-xox.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector029"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-abjn.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector030"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-txz7.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector031"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-f90h.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector032"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-rd0s.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector033"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-lds5s.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector034"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-hmoi.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector035"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-c1po.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector036"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-yk1y.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector037"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-602t.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector038"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-orqr.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector039"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-8rt.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector040"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-l27l.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector041"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-tg5.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector042"
                />
              </div>
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-1qjl.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector043"
              />
            </div>
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group05"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group06"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-e1q.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector044"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-pbz.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector045"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-7gw.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector046"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-z43n.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector047"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-t69r.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector048"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-51bh.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector049"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-j62b.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector050"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-0ye.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector051"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-p9bs.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector052"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-cclr.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector053"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-0y28.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector054"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-f4om.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector055"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-1wno.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector056"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-hogm.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector057"
            />
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group07"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group08"
            >
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group09"
              >
                <div
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group10"
                >
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-2b1.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector058"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-mbwu.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector059"
                  />
                </div>
              </div>
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-l8x8.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector060"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-y6k9.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector061"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ahdy.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector062"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-lk.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector063"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-egeq.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector064"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-u03g.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector065"
              />
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group11"
              >
                <div
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group12"
                >
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-5vbm.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector066"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-f339.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector067"
                  />
                </div>
              </div>
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group13"
              >
                <div
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group14"
                >
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-8dk.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector068"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-4vfg.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector069"
                  />
                </div>
              </div>
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-dw8x.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector070"
              />
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group15"
              >
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-q6vr.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector071"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-nhy.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector072"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-2ok.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector073"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-v7mi.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector074"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-fdsy.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector075"
                />
              </div>
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-0imxj.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector076"
              />
            </div>
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group16"
            >
              <div
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group17"
              >
                <div
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group18"
                >
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-ib3.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector077"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-w1m.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector078"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-gata.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector079"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-dpw4.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector080"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-04at.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector081"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-oghg.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector082"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-mz6e.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector083"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-58s7.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector084"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-s08t.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector085"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-1f2e.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector086"
                  />
                  <img
                    src="/playground_assets/CondicionesPersonas/vector1251-opls.svg"
                    alt="Vector1251"
                    class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector087"
                  />
                </div>
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-kb8k.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector088"
                />
                <img
                  src="/playground_assets/CondicionesPersonas/vector1251-9f0g.svg"
                  alt="Vector1251"
                  class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector089"
                />
              </div>
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-glac.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector090"
            />
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group19"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-74vl.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector091"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-7zeh.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector092"
              />
            </div>
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group20"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group21"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-lkgp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector093"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-jq1.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector094"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-vvhp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector095"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-gfb.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector096"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-m8ja.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector097"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-k6f.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector098"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-hi3w.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector099"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-unta.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector100"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-7xu.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector101"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-38k.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector102"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-55e.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector103"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-fecj.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector104"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-y7pp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector105"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-wi6l.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector106"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-bh5d.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector107"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-yer.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector108"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-lihi.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector109"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-o9f6.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector110"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-0yfq.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector111"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-3ypf.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector112"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-54pl.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector113"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-ajh.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector114"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-yok.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector115"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-ri8c.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector116"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-r88v.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector117"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-1yq5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector118"
            />
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group22"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-6er.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector119"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-8fce.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector120"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-rbqw.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector121"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-6f3s.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector122"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-grz5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector123"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-anz5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector124"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-y56j.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector125"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-b5g.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector126"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-spc.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector127"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-br6w.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector128"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-kee.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector129"
            />
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group23"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-9pk.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector130"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-bfbs.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector131"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-g22t.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector132"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-colo.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector133"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-hb9jl.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector134"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-m69x.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector135"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-eqqp.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector136"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-lpo.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector137"
            />
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group24"
          >
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-gbw.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector138"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-65s.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector139"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-yin.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector140"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-3om.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector141"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-lufi.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector142"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-9jgw.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector143"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-b5h5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector144"
            />
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group25"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-x47.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector145"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-w3og6.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector146"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-vrio.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector147"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-dcf.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector148"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-rcq.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector149"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-30rt.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector150"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-j4elh.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector151"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-fpfs.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector152"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-mzis.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector153"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-otvb.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector154"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-9m5i.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector155"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-2bp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector156"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-phox.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector157"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-jgit.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector158"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-mlp.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector159"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-62oxg.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector160"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-9sal.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector161"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-gc5.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector162"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-d8pd.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector163"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-mz97.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector164"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ekt.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector165"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-fj59.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector166"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-lkk4.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector167"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-zies.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector168"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-qr77.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector169"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-0u44.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector170"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-slt.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector171"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-inim.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector172"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-guik.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector173"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-fitp.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector174"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-e5e5.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector175"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-4g9.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector176"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-t3xd.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector177"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-ejx9.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector178"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-dh9.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector179"
            />
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group26"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group27"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-s7p.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector180"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-u2l.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector181"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-n3zc.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector182"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-b22l.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector183"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-6bme.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector184"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ry9a.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector185"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ukyq.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector186"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-6od4.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector187"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ai38.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector188"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-zs9h.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector189"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-ocy.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector190"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-hh45.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector191"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-oe7n.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector192"
            />
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group28"
          >
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-0ugm.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector193"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-ajof.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector194"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-l0f.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector195"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-vanh.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector196"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-m8z.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector197"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-acyr.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector198"
            />
          </div>
          <div
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group29"
          >
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group30"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-jrmr.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector199"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-evg.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector200"
              />
            </div>
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group31"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-qse.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector201"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-uptc.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector202"
              />
            </div>
            <div
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group32"
            >
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-xbys.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector203"
              />
              <img
                src="/playground_assets/CondicionesPersonas/vector1251-yp3.svg"
                alt="Vector1251"
                class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector204"
              />
            </div>
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-plmq.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector205"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-n0ws.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector206"
            />
            <img
              src="/playground_assets/CondicionesPersonas/vector1251-pr8w.svg"
              alt="Vector1251"
              class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector207"
            />
          </div>
        </div>
      </div>
      
      <v-btn 
      class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-icon-btns"
            color="transparent"
            text
            @click="closeDialog"
          >
          <img
        src="/playground_assets/CondicionesPersonas/iconbtns1251-m2fu.svg"
        alt="IconBtns1251"
        
      />
          </v-btn>
      <div
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288909"
      >
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group460"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse2801251-doah-200h.png"
            alt="Ellipse2801251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse280"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-1lwg.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector208"
          />
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288903"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse2801251-elin-200w.png"
            alt="Ellipse2801251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2801"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-65k8.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector209"
          />
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288905"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse2801251-rav-200w.png"
            alt="Ellipse2801251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2802"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-fegk.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector210"
          />
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288907"
        >
          <img
            src="/playground_assets/CondicionesPersonas/ellipse2801251-6uyq-200w.png"
            alt="Ellipse2801251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2803"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-d6bj.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector211"
          />
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288902"
        >
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text14"
          >
            <span>{{ detallePlan[0].ben_titulo }}</span>
          </span>
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text16"
          >
            <span>{{ detallePlan[0].ben_texto }}</span>
          </span>
        </div>
       
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288904"
        >
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text18"
          >
            <span>{{ detallePlan[1].ben_titulo }}</span>
          </span>
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text20"
          >
            <span>{{ detallePlan[1].ben_texto }}</span>
          </span>
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288906"
        >
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text22"
          >
            <span>{{ detallePlan[2].ben_titulo }}</span>
          </span>
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text24"
          >
            <span>{{ detallePlan[2].ben_texto }}</span>
          </span>
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288908"
        >
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text26"
          >
            <span>{{ detallePlan[3].ben_titulo }}</span>
          </span>
          <span
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text28"
          >
            <span>{{ detallePlan[3].ben_texto }}</span>
          </span>
        </div>
      </div>
      <div
        class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-personasentada-footer"
      >
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-rv5q.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector212"
        />
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group33"
        >
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-xt26.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector213"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-3yxw.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector214"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-wkph.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector215"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-mfd.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector216"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-rv18.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector217"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-pyic.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector218"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-6ov9.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector219"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-0yki.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector220"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-305m.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector221"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-yzmq.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector222"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-ac1.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector223"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-km9b.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector224"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-uqzs.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector225"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-czr3.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector226"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-dsie.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector227"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-1nt3.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector228"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-t954.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector229"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-px2r.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector230"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-u3z9.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector231"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-2qtr.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector232"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-kdwq.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector233"
          />
        </div>
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-pcrj.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector234"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-k38o.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector235"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-ctq.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector236"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-q33s.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector237"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-nr5j.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector238"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-j73.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector239"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-991k.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector240"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-thw4.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector241"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-6htq.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector242"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-iyxr.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector243"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-vh8k.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector244"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-fcb.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector245"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-mfl1.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector246"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-ys7f.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector247"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-j69g.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector248"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-dw9n.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector249"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-7625.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector250"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-wio.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector251"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-7snh.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector252"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-dm3f.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector253"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-cov.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector254"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-epy.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector255"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-r6ca.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector256"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-e2mo.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector257"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-130a.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector258"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-r5.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector259"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-llvh.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector260"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-mtd2.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector261"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-yryl.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector262"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-u6nr.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector263"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-9s6s.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector264"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-pqfu.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector265"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-dxyp.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector266"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-b1v5.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector267"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-wx2l.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector268"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-xxie.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector269"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-vdw.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector270"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-gppc.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector271"
        />
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group34"
        >
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-qr5q.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector272"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-2kc5.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector273"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-r2h.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector274"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-u2sy.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector275"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-2hgd.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector276"
          />
        </div>
        <div
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group35"
        >
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-yv1a.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector277"
          />
          <img
            src="/playground_assets/CondicionesPersonas/vector1251-cckd.svg"
            alt="Vector1251"
            class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector278"
          />
        </div>
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-zz9y.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector279"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-c04.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector280"
        />
        <img
          src="/playground_assets/CondicionesPersonas/vector1251-30ql.svg"
          alt="Vector1251"
          class="frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector281"
        />
      </div>
    </div>
  </div>
</template>

<script>
import detalle from "../../../../../services/plan";
export default {
  name: 'CondicionesPersonas',

  data() {
    return {
    
      detallePlan: [
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        },
        {
          ben_titulo: "",
          ben_texto: ""
        }
      ], 
    }
  },
  methods: {
    closeDialog(){
      this.$root.$refs.PreaprobacionLinea.closeDialog();
    },
    async traeDetallePlan(){
      var res=false;
      var plan= this.$store.state.plan;
      res = await detalle.consultabeneficios(plan);
      if(res.status==200){
        if(res.data.estadoTransaccion.success){
          this.detallePlan=res.data.beneficios;
        }        
      }      
    }
  },
  mounted() {
    this.traeDetallePlan();
  },
  metaInfo: {
    title: 'Mueve Seguro',
  },
}
</script>

<style scoped>
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-container {
  min-height: 700px;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: hidden;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta {
  top: -1px;
  left: 92px;
  width: 297px;
  height: 144px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288878 {
  top: 41px;
  left: 146px;
  width: 190px;
  height: 72px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text {
  top: 34px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 34px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  text-decoration: none;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text02 {
  left: 6px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  font-size: 28px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 38px;
  font-stretch: normal;
  text-decoration: none;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectanguloarriba-rellenalasiguienteencuesta1 {
  top: 507px;
  left: 92px;
  width: 297px;
  height: 364px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text04 {
  top: 171px;
  left: 125px;
  color: var(--dl-color-gray-white);
  width: 234px;
  height: auto;
  position: absolute;
  font-size: 19px;
  align-self: auto;
  font-style: ExtraBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text05 {
  font-weight: 800;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text09 {
  font-weight: 400;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288890 {
  top: 308.53253173828125px;
  left: 77px;
  width: 315.6685791015625px;
  height: 311.5331115722656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-rectangle2361 {
  top: 143.46746826171875px;
  left: 48.17529296875px;
  width: 235px;
  height: 14px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group {
  top: 0px;
  left: 0px;
  width: 315.6685791015625px;
  height: 311.5331115722656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group01 {
  top: 0px;
  left: 0px;
  width: 277.4153137207031px;
  height: 311.5331115722656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group02 {
  top: 144.117431640625px;
  left: -0.0000020770819446624955px;
  width: 277.4153137207031px;
  height: 167.41571044921875px;
  display: flex;
  opacity: 0.20;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector {
  top: 0px;
  left: 0px;
  width: 277px;
  height: 167px;
  opacity: 0.19;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector001 {
  top: 1.0277587175369263px;
  left: 0.7502304315567017px;
  width: 276px;
  height: 166px;
  opacity: 0.26;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector002 {
  top: 2.0465002059936523px;
  left: 1.4451050758361816px;
  width: 274px;
  height: 164px;
  opacity: 0.32;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector003 {
  top: 3.060556650161743px;
  left: 2.086125612258911px;
  width: 272px;
  height: 163px;
  opacity: 0.39;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector004 {
  top: 4.071409225463867px;
  left: 2.6730258464813232px;
  width: 270px;
  height: 161px;
  opacity: 0.45;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector005 {
  top: 5.077483654022217px;
  left: 3.2143514156341553px;
  width: 269px;
  height: 160px;
  opacity: 0.52;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector006 {
  top: 6.081024169921875px;
  left: 3.7054569721221924px;
  width: 267px;
  height: 159px;
  opacity: 0.58;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector007 {
  top: 7.0814080238342285px;
  left: 4.163393020629883px;
  width: 266px;
  height: 157px;
  opacity: 0.64;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector008 {
  top: 8.076922416687012px;
  left: 4.58078145980835px;
  width: 265px;
  height: 156px;
  opacity: 0.71;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector009 {
  top: 9.070572853088379px;
  left: 4.968929767608643px;
  width: 264px;
  height: 154px;
  opacity: 0.77;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector010 {
  top: 10.059741020202637px;
  left: 5.333216190338135px;
  width: 262px;
  height: 153px;
  opacity: 0.84;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector011 {
  top: 11.053438186645508px;
  left: 5.676597595214844px;
  width: 261px;
  height: 151px;
  opacity: 0.90;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector012 {
  top: 155.03085327148438px;
  left: 5.675991058349609px;
  width: 261px;
  height: 154px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector013 {
  top: 147.90476989746094px;
  left: 5.6765947341918945px;
  width: 261px;
  height: 151px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector014 {
  top: 205.8618927001953px;
  left: 5.677916526794434px;
  width: 261px;
  height: 93px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector015 {
  top: 164.41940307617188px;
  left: 61.47364807128906px;
  width: 177px;
  height: 102px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector016 {
  top: 169.4187774658203px;
  left: 68.37997436523438px;
  width: 163px;
  height: 94px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector017 {
  top: 168.31863403320312px;
  left: 68.37997436523438px;
  width: 163px;
  height: 94px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group03 {
  top: 0px;
  left: 103.22998046875px;
  width: 173.37210083007812px;
  height: 235.75161743164062px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector018 {
  top: 2.5055699348449707px;
  left: 0.000008283946954179555px;
  width: 169px;
  height: 233px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector019 {
  top: 8.550003051757812px;
  left: 5.252045631408691px;
  width: 159px;
  height: 221px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector020 {
  top: 8.550679206848145px;
  left: 5.247061729431152px;
  width: 159px;
  height: 221px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group04 {
  top: 0px;
  left: 3.5263094902038574px;
  width: 169.8458251953125px;
  height: 235.5125274658203px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector021 {
  top: 0px;
  left: 0px;
  width: 170px;
  height: 236px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector022 {
  top: 0.04995068162679672px;
  left: -4.186443902653991e-8px;
  width: 170px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector023 {
  top: 0.10016728937625885px;
  left: -2.3018833417154383e-7px;
  width: 170px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector024 {
  top: 0.155258446931839px;
  left: -2.301883910149627e-7px;
  width: 170px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector025 {
  top: 0.20503142476081848px;
  left: -2.3018833417154383e-7px;
  width: 170px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector026 {
  top: 0.2600402235984802px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector027 {
  top: 0.3100471496582031px;
  left: -2.301883910149627e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector028 {
  top: 0.3650558888912201px;
  left: -2.3018833417154383e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector029 {
  top: 0.415063738822937px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector030 {
  top: 0.46507155895233154px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector031 {
  top: 0.5200794339179993px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector032 {
  top: 0.5700873732566833px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector033 {
  top: 0.6250959634780884px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector034 {
  top: 0.6751038432121277px;
  left: -2.3018833417154383e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector035 {
  top: 0.7251117825508118px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector036 {
  top: 0.7801195979118347px;
  left: -2.3018833417154383e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector037 {
  top: 0.8301274180412292px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector038 {
  top: 0.885136067867279px;
  left: -2.3018827732812497e-7px;
  width: 169px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector039 {
  top: 0.9351431727409363px;
  left: -2.3018833417154383e-7px;
  width: 168px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector040 {
  top: 0.9901520609855652px;
  left: -2.3018827732812497e-7px;
  width: 168px;
  height: 235px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector041 {
  top: 1.0401597023010254px;
  left: -2.301883910149627e-7px;
  width: 168px;
  height: 234px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector042 {
  top: 1.0901676416397095px;
  left: -2.3018827732812497e-7px;
  width: 168px;
  height: 234px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector043 {
  top: 2.291510820388794px;
  left: 4.164732933044434px;
  width: 165px;
  height: 233px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group05 {
  top: 3.117408037185669px;
  left: 123.3955078125px;
  width: 136.53805541992188px;
  height: 218.00180053710938px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group06 {
  top: 1.221934199333191px;
  left: 0.45888257026672363px;
  width: 136.07919311523438px;
  height: 206.5413818359375px;
  display: flex;
  opacity: 0.30;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector044 {
  top: 4.2792067527771px;
  left: 1.5910744667053223px;
  width: 134px;
  height: 202px;
  opacity: 0.09;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector045 {
  top: 3.846086025238037px;
  left: 1.4314645528793335px;
  width: 133px;
  height: 202px;
  opacity: 0.18;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector046 {
  top: 3.4210712909698486px;
  left: 1.2768474817276px;
  width: 133px;
  height: 202px;
  opacity: 0.27;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector047 {
  top: 2.992950439453125px;
  left: 1.1122604608535767px;
  width: 132px;
  height: 202px;
  opacity: 0.36;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector048 {
  top: 2.5648295879364014px;
  left: 0.9526509046554565px;
  width: 131px;
  height: 203px;
  opacity: 0.45;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector049 {
  top: 2.136585235595703px;
  left: 0.7980336546897888px;
  width: 131px;
  height: 203px;
  opacity: 0.55;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector050 {
  top: 1.7093727588653564px;
  left: 0.6334312558174133px;
  width: 130px;
  height: 203px;
  opacity: 0.64;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector051 {
  top: 1.2834500074386597px;
  left: 0.47881412506103516px;
  width: 130px;
  height: 203px;
  opacity: 0.73;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector052 {
  top: 0.8553311824798584px;
  left: 0.3192194700241089px;
  width: 129px;
  height: 203px;
  opacity: 0.82;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector053 {
  top: 0.42721253633499146px;
  left: 0.1645870804786682px;
  width: 129px;
  height: 203px;
  opacity: 0.91;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector054 {
  top: 0px;
  left: 0px;
  width: 128px;
  height: 204px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector055 {
  top: 0px;
  left: 0px;
  width: 128px;
  height: 103px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector056 {
  top: 17.9910945892334px;
  left: 0.00000832258228911087px;
  width: 128px;
  height: 200px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector057 {
  top: 0px;
  left: 0px;
  width: 128px;
  height: 85px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group07 {
  top: 37.726043701171875px;
  left: 86.0625px;
  width: 128.14373779296875px;
  height: 149.62948608398438px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group08 {
  top: -0.0000016146344705703086px;
  left: 49.46791076660156px;
  width: 69.68303680419922px;
  height: 104.38603210449219px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group09 {
  top: 53.32319259643555px;
  left: 0.000005160340606380487px;
  width: 15.815964698791504px;
  height: 27.254180908203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group10 {
  top: 0px;
  left: 0px;
  width: 15.815964698791504px;
  height: 27.254180908203125px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector058 {
  top: 0.8051234483718872px;
  left: 0.4538816511631012px;
  width: 15px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector059 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 27px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector060 {
  top: 62.14323806762695px;
  left: 3.232855796813965px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector061 {
  top: 46.4271240234375px;
  left: 20.479459762573242px;
  width: 39px;
  height: 25px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector062 {
  top: 40.14616394042969px;
  left: 20.683958053588867px;
  width: 36px;
  height: 24px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector063 {
  top: 66.96527862548828px;
  left: 20.683958053588867px;
  width: 15px;
  height: 12px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector064 {
  top: 19.91805648803711px;
  left: 20.479459762573242px;
  width: 34px;
  height: 23px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector065 {
  top: 13.642096519470215px;
  left: 20.683958053588867px;
  width: 26px;
  height: 18px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group11 {
  top: 26.58408546447754px;
  left: 0.000005160340606380487px;
  width: 15.815964698791504px;
  height: 27.254188537597656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group12 {
  top: 0px;
  left: 0px;
  width: 15.815964698791504px;
  height: 27.254188537597656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector066 {
  top: 0.8001170754432678px;
  left: 0.4538816511631012px;
  width: 15px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector067 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 27px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group13 {
  top: 0px;
  left: 0px;
  width: 15.815964698791504px;
  height: 27.254188537597656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group14 {
  top: 0px;
  left: 0px;
  width: 15.815964698791504px;
  height: 27.254188537597656px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector068 {
  top: 0.8001208305358887px;
  left: 0.45388689637184143px;
  width: 15px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector069 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 27px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector070 {
  top: 8.815052032470703px;
  left: 3.232855796813965px;
  width: 10px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group15 {
  top: 73.24625396728516px;
  left: 20.479459762573242px;
  width: 49.20356369018555px;
  height: 31.139787673950195px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector071 {
  top: 4.270648956298828px;
  left: 0.00000218309105548542px;
  width: 23px;
  height: 16px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector072 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 11px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector073 {
  top: 9.266427993774414px;
  left: 15.97557544708252px;
  width: 23px;
  height: 16px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector074 {
  top: 24.123703002929688px;
  left: 41.06864929199219px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector075 {
  top: 18.657867431640625px;
  left: 24.4746036529541px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector076 {
  top: 34.65852355957031px;
  left: 3.922818899154663px;
  width: 8px;
  height: 12px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group16 {
  top: 55.817073822021484px;
  left: -0.0000043661757445079274px;
  width: 128.14373779296875px;
  height: 93.81242370605469px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group17 {
  top: 0px;
  left: 0px;
  width: 128.14373779296875px;
  height: 93.81242370605469px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group18 {
  top: 3.441324234008789px;
  left: 13.69620132446289px;
  width: 114.44755554199219px;
  height: 90.37108612060547px;
  display: flex;
  opacity: 0.50;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector077 {
  top: 0.5459210276603699px;
  left: 0.8229665160179138px;
  width: 113px;
  height: 89px;
  opacity: 0.09;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector078 {
  top: 1.0949480533599854px;
  left: 0.7381767630577087px;
  width: 112px;
  height: 88px;
  opacity: 0.18;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector079 {
  top: 1.63677179813385px;
  left: 0.6583718061447144px;
  width: 112px;
  height: 87px;
  opacity: 0.27;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector080 {
  top: 2.1827003955841064px;
  left: 0.5735820531845093px;
  width: 111px;
  height: 86px;
  opacity: 0.36;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector081 {
  top: 2.7317276000976562px;
  left: 0.49377721548080444px;
  width: 110px;
  height: 85px;
  opacity: 0.45;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector082 {
  top: 3.2785563468933105px;
  left: 0.4139799475669861px;
  width: 110px;
  height: 84px;
  opacity: 0.55;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector083 {
  top: 3.819472312927246px;
  left: 0.32919007539749146px;
  width: 109px;
  height: 83px;
  opacity: 0.64;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector084 {
  top: 4.368620872497559px;
  left: 0.24938520789146423px;
  width: 108px;
  height: 82px;
  opacity: 0.73;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector085 {
  top: 4.9154582023620605px;
  left: 0.1645953506231308px;
  width: 108px;
  height: 81px;
  opacity: 0.82;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector086 {
  top: 5.456381797790527px;
  left: 0.08479051291942596px;
  width: 107px;
  height: 80px;
  opacity: 0.91;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector087 {
  top: 6.005401611328125px;
  left: 6.789309168198088e-7px;
  width: 106px;
  height: 78px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector088 {
  top: 0px;
  left: 0px;
  width: 122px;
  height: 93px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector089 {
  top: 0px;
  left: 0px;
  width: 122px;
  height: 80px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector090 {
  top: 78.76209259033203px;
  left: 30.449848175048828px;
  width: 64px;
  height: 41px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group19 {
  top: 64.89495849609375px;
  left: 9.466638565063477px;
  width: 14.393893241882324px;
  height: 24.77322006225586px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector091 {
  top: 0.004541776608675718px;
  left: -1.2056034393026493e-7px;
  width: 14px;
  height: 25px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector092 {
  top: -0.0000011122313026135089px;
  left: 0.004528363235294819px;
  width: 14px;
  height: 25px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group20 {
  top: 186.78053283691406px;
  left: 261.48193359375px;
  width: 27.72165298461914px;
  height: 90.78372955322266px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group21 {
  top: 58.841064453125px;
  left: 2.825684070587158px;
  width: 17.23790740966797px;
  height: 31.942638397216797px;
  display: flex;
  opacity: 0.50;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector093 {
  top: 1.3860161304473877px;
  left: 0.604733407497406px;
  width: 17px;
  height: 30px;
  opacity: 0.05;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector094 {
  top: 2.758481502532959px;
  left: 1.2149112224578857px;
  width: 16px;
  height: 29px;
  opacity: 0.10;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector095 {
  top: 4.164229869842529px;
  left: 1.811734914779663px;
  width: 15px;
  height: 28px;
  opacity: 0.14;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector096 {
  top: 5.54328727722168px;
  left: 2.416006088256836px;
  width: 15px;
  height: 26px;
  opacity: 0.19;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector097 {
  top: 6.929514408111572px;
  left: 3.0182228088378906px;
  width: 14px;
  height: 25px;
  opacity: 0.24;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector098 {
  top: 8.322332382202148px;
  left: 3.625318765640259px;
  width: 13px;
  height: 23px;
  opacity: 0.29;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector099 {
  top: 9.714703559875488px;
  left: 4.231644630432129px;
  width: 13px;
  height: 22px;
  opacity: 0.33;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector100 {
  top: 11.087554931640625px;
  left: 4.808180332183838px;
  width: 12px;
  height: 20px;
  opacity: 0.38;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector101 {
  top: 12.486708641052246px;
  left: 5.4026923179626465px;
  width: 11px;
  height: 19px;
  opacity: 0.43;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector102 {
  top: 13.879080772399902px;
  left: 5.9954071044921875px;
  width: 11px;
  height: 17px;
  opacity: 0.48;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector103 {
  top: 15.28476619720459px;
  left: 6.587608337402344px;
  width: 10px;
  height: 16px;
  opacity: 0.52;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector104 {
  top: 16.644363403320312px;
  left: 7.168766975402832px;
  width: 10px;
  height: 15px;
  opacity: 0.57;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector105 {
  top: 18.036733627319336px;
  left: 7.755061626434326px;
  width: 9px;
  height: 13px;
  opacity: 0.62;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector106 {
  top: 19.43576431274414px;
  left: 8.34161376953125px;
  width: 8px;
  height: 12px;
  opacity: 0.67;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector107 {
  top: 20.82807159423828px;
  left: 8.922258377075195px;
  width: 8px;
  height: 10px;
  opacity: 0.71;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector108 {
  top: 22.221464157104492px;
  left: 9.49673843383789px;
  width: 7px;
  height: 9px;
  opacity: 0.76;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector109 {
  top: 23.587081909179688px;
  left: 10.074816703796387px;
  width: 7px;
  height: 7px;
  opacity: 0.81;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector110 {
  top: 24.9841251373291px;
  left: 10.649296760559082px;
  width: 6px;
  height: 6px;
  opacity: 0.86;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector111 {
  top: 26.358448028564453px;
  left: 11.230198860168457px;
  width: 5px;
  height: 5px;
  opacity: 0.90;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector112 {
  top: 27.658649444580078px;
  left: 11.79517936706543px;
  width: 5px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector113 {
  top: 28.740020751953125px;
  left: 12.367606163024902px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector114 {
  top: 83.2947998046875px;
  left: 16.46865463256836px;
  width: 9px;
  height: 5px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector115 {
  top: 85.95985412597656px;
  left: 16.488426208496094px;
  width: 9px;
  height: 2px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector116 {
  top: 33.83074951171875px;
  left: 3.4371461868286133px;
  width: 17px;
  height: 51px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector117 {
  top: 39.12763595581055px;
  left: 3.430469036102295px;
  width: 16px;
  height: 46px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector118 {
  top: 81.5491714477539px;
  left: 11.730530738830566px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group22 {
  top: 82.3553237915039px;
  left: 11.730530738830566px;
  width: 7.560605525970459px;
  height: 7.7243218421936035px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector119 {
  top: 0.00003127134186797775px;
  left: 5.5220842361450195px;
  width: 0px;
  height: 0px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector120 {
  top: 0.6862978935241699px;
  left: -0.00007523404929088429px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector121 {
  top: 35.58302307128906px;
  left: 13.066191673278809px;
  width: 14px;
  height: 48px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector122 {
  top: 10.133047103881836px;
  left: 2.9974892139434814px;
  width: 18px;
  height: 31px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector123 {
  top: 15.069134712219238px;
  left: 3.0041661262512207px;
  width: 6px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector124 {
  top: 10.984253883361816px;
  left: 7.690157413482666px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector125 {
  top: 5.601536750793457px;
  left: 8.5779447555542px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector126 {
  top: 5.601536750793457px;
  left: 10.405139923095703px;
  width: 5px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector127 {
  top: 0.9558412432670593px;
  left: 9.632402420043945px;
  width: 8px;
  height: 12px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector128 {
  top: -0.000017671798559604213px;
  left: 8.670395851135254px;
  width: 11px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector129 {
  top: 28.73375129699707px;
  left: 16.196178436279297px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group23 {
  top: 26.315053939819336px;
  left: 13.802205085754395px;
  width: 4.836314678192139px;
  height: 8.961170196533203px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector130 {
  top: 0.09654499590396881px;
  left: 0.14399318397045135px;
  width: 5px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector131 {
  top: 0px;
  left: 0px;
  width: 5px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector132 {
  top: 29.38673210144043px;
  left: 13.729272842407227px;
  width: 7px;
  height: 5px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector133 {
  top: 11.857094764709473px;
  left: 18.648189544677734px;
  width: 9px;
  height: 20px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector134 {
  top: 41.92594528198242px;
  left: 4.759454250335693px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector135 {
  top: 13.390021324157715px;
  left: 0.000016607411453151144px;
  width: 8px;
  height: 31px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector136 {
  top: 46.50987243652344px;
  left: 13.059515953063965px;
  width: 10px;
  height: 36px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector137 {
  top: 19.532543182373047px;
  left: 18.78789520263672px;
  width: 6px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group24 {
  top: 109.6673355102539px;
  left: 32.17529296875px;
  width: 61.307594299316406px;
  height: 94.23250579833984px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector138 {
  top: 5.635012149810791px;
  left: 41.30476760864258px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector139 {
  top: 0px;
  left: 42.761070251464844px;
  width: 19px;
  height: 18px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector140 {
  top: 9.597395896911621px;
  left: 54.011356353759766px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector141 {
  top: 8.816746711730957px;
  left: 53.262203216552734px;
  width: 6px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector142 {
  top: 5.713417053222656px;
  left: 42.68239974975586px;
  width: 2px;
  height: 3px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector143 {
  top: 5.432504177093506px;
  left: 41.491981506347656px;
  width: 3px;
  height: 5px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector144 {
  top: 9.171781539916992px;
  left: 24.55341148376465px;
  width: 19px;
  height: 19px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group25 {
  top: 77.96063995361328px;
  left: 13.994016647338867px;
  width: 24.96025276184082px;
  height: 16.271862030029297px;
  display: flex;
  opacity: 0.20;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector145 {
  top: 0.545210063457489px;
  left: 0.14490701258182526px;
  width: 24px;
  height: 16px;
  opacity: 0.05;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector146 {
  top: 1.1085121631622314px;
  left: 0.29603859782218933px;
  width: 23px;
  height: 15px;
  opacity: 0.10;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector147 {
  top: 1.665612816810608px;
  left: 0.4471701979637146px;
  width: 22px;
  height: 14px;
  opacity: 0.14;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector148 {
  top: 2.2168071269989014px;
  left: 0.6039211750030518px;
  width: 21px;
  height: 14px;
  opacity: 0.19;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector149 {
  top: 2.7675585746765137px;
  left: 0.7370116114616394px;
  width: 20px;
  height: 13px;
  opacity: 0.24;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector150 {
  top: 3.318826913833618px;
  left: 0.8937625885009766px;
  width: 19px;
  height: 12px;
  opacity: 0.29;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector151 {
  top: 3.8631556034088135px;
  left: 1.014726996421814px;
  width: 18px;
  height: 12px;
  opacity: 0.33;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector152 {
  top: 4.425276279449463px;
  left: 1.1711821556091309px;
  width: 17px;
  height: 11px;
  opacity: 0.38;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector153 {
  top: 4.988357067108154px;
  left: 1.3282289505004883px;
  width: 16px;
  height: 10px;
  opacity: 0.43;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector154 {
  top: 5.531947135925293px;
  left: 1.4728538990020752px;
  width: 15px;
  height: 10px;
  opacity: 0.48;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector155 {
  top: 6.076570987701416px;
  left: 1.6236896514892578px;
  width: 14px;
  height: 9px;
  opacity: 0.52;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector156 {
  top: 6.655229568481445px;
  left: 1.7623995542526245px;
  width: 13px;
  height: 8px;
  opacity: 0.57;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector157 {
  top: 7.203396797180176px;
  left: 1.906728744506836px;
  width: 12px;
  height: 8px;
  opacity: 0.62;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector158 {
  top: 7.740933418273926px;
  left: 2.0634796619415283px;
  width: 11px;
  height: 7px;
  opacity: 0.67;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector159 {
  top: 8.29013442993164px;
  left: 2.194795608520508px;
  width: 10px;
  height: 6px;
  opacity: 0.71;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector160 {
  top: 8.847086906433105px;
  left: 2.3512508869171143px;
  width: 9px;
  height: 6px;
  opacity: 0.76;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector161 {
  top: 9.401603698730469px;
  left: 2.4941012859344482px;
  width: 8px;
  height: 5px;
  opacity: 0.81;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector162 {
  top: 9.939288139343262px;
  left: 2.6499648094177246px;
  width: 7px;
  height: 4px;
  opacity: 0.86;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector163 {
  top: 10.488414764404297px;
  left: 2.7732954025268555px;
  width: 7px;
  height: 4px;
  opacity: 0.90;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector164 {
  top: 11.010374069213867px;
  left: 2.9398062229156494px;
  width: 6px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector165 {
  top: 11.526796340942383px;
  left: 3.073192596435547px;
  width: 5px;
  height: 3px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector166 {
  top: 86.67816925048828px;
  left: 15.548808097839355px;
  width: 6px;
  height: 5px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector167 {
  top: 88.32599639892578px;
  left: 15.287063598632812px;
  width: 6px;
  height: 4px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector168 {
  top: 46.48925018310547px;
  left: 2.8221089839935303px;
  width: 28px;
  height: 29px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector169 {
  top: 47.6341667175293px;
  left: 3.686013698577881px;
  width: 27px;
  height: 28px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector170 {
  top: 45.574459075927734px;
  left: 16.70107650756836px;
  width: 18px;
  height: 43px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector171 {
  top: 73.47164916992188px;
  left: 1.8585340976715088px;
  width: 3px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector172 {
  top: 73.35411834716797px;
  left: 0px;
  width: 4px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector173 {
  top: 47.19194030761719px;
  left: 18.42888641357422px;
  width: 17px;
  height: 42px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector174 {
  top: 12.721766471862793px;
  left: 17.613189697265625px;
  width: 38px;
  height: 40px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector175 {
  top: 23.244884490966797px;
  left: 28.37990951538086px;
  width: 7px;
  height: 4px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector176 {
  top: 23.182573318481445px;
  left: 28.881513595581055px;
  width: 6px;
  height: 3px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector177 {
  top: 12.68463134765625px;
  left: 27.342100143432617px;
  width: 10px;
  height: 13px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector178 {
  top: 11.372572898864746px;
  left: 27.14630889892578px;
  width: 9px;
  height: 11px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector179 {
  top: 30.320341110229492px;
  left: 31.643287658691406px;
  width: 10px;
  height: 23px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group26 {
  top: 96.46907043457031px;
  left: 203.21337890625px;
  width: 108.16803741455078px;
  height: 90.62162780761719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group27 {
  top: 0px;
  left: 0px;
  width: 75.82787322998047px;
  height: 70.6328353881836px;
  display: flex;
  opacity: 0.50;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector180 {
  top: 0.7708423137664795px;
  left: 0.3292105793952942px;
  width: 75px;
  height: 69px;
  opacity: 0.09;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector181 {
  top: 1.5449022054672241px;
  left: 0.6583999395370483px;
  width: 74px;
  height: 68px;
  opacity: 0.18;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector182 {
  top: 2.318962574005127px;
  left: 0.9875895380973816px;
  width: 74px;
  height: 67px;
  opacity: 0.27;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector183 {
  top: 3.0847890377044678px;
  left: 1.321771502494812px;
  width: 73px;
  height: 66px;
  opacity: 0.36;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector184 {
  top: 3.858848810195923px;
  left: 1.6509608030319214px;
  width: 72px;
  height: 65px;
  opacity: 0.45;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector185 {
  top: 4.630589485168457px;
  left: 1.9801348447799683px;
  width: 71px;
  height: 64px;
  opacity: 0.55;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector186 {
  top: 5.404648303985596px;
  left: 2.314316749572754px;
  width: 71px;
  height: 62px;
  opacity: 0.64;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector187 {
  top: 6.177679061889648px;
  left: 2.6435062885284424px;
  width: 70px;
  height: 61px;
  opacity: 0.73;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector188 {
  top: 6.949542045593262px;
  left: 2.972695827484131px;
  width: 69px;
  height: 60px;
  opacity: 0.82;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector189 {
  top: 7.723479270935059px;
  left: 3.3068621158599854px;
  width: 69px;
  height: 59px;
  opacity: 0.91;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector190 {
  top: 8.489434242248535px;
  left: 3.636052131652832px;
  width: 68px;
  height: 58px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector191 {
  top: 6.485395908355713px;
  left: 0.778114914894104px;
  width: 107px;
  height: 84px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector192 {
  top: 6.4882731437683105px;
  left: 0.778114914894104px;
  width: 107px;
  height: 72px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group28 {
  top: 119.65482330322266px;
  left: 217.09423828125px;
  width: 83.99768829345703px;
  height: 54.30597686767578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector193 {
  top: 0px;
  left: 0px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector194 {
  top: 10.8916654586792px;
  left: 18.85844612121582px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector195 {
  top: 22.128393173217773px;
  left: 38.170772552490234px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector196 {
  top: 33.48513412475586px;
  left: 57.4781379699707px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector197 {
  top: 35.06420135498047px;
  left: 58.695125579833984px;
  width: 5px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector198 {
  top: 44.7868766784668px;
  left: 76.67575073242188px;
  width: 7px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group29 {
  top: 14.594829559326172px;
  left: 83.42822265625px;
  width: 232.24034118652344px;
  height: 188.02159118652344px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group30 {
  top: 154.5164031982422px;
  left: 130.78326416015625px;
  width: 26.21030616760254px;
  height: 33.505149841308594px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector199 {
  top: 0.45222383737564087px;
  left: 0.448883056640625px;
  width: 25px;
  height: 33px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector200 {
  top: 0px;
  left: 0px;
  width: 26px;
  height: 34px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group31 {
  top: 0px;
  left: 0px;
  width: 35.78689956665039px;
  height: 41.300010681152344px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector201 {
  top: 10.423654556274414px;
  left: 0.000004808703579328721px;
  width: 21px;
  height: 36px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector202 {
  top: 3.8932831287384033px;
  left: 4.789195537567139px;
  width: 26px;
  height: 34px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group32 {
  top: 97.39759063720703px;
  left: 196.45347595214844px;
  width: 35.78689193725586px;
  height: 41.29999923706055px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector203 {
  top: 10.423683166503906px;
  left: -0.000012300772141315974px;
  width: 21px;
  height: 36px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector204 {
  top: 3.9006612300872803px;
  left: 4.78587532043457px;
  width: 26px;
  height: 34px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector205 {
  top: 11.444440841674805px;
  left: 14.026402473449707px;
  width: 5px;
  height: 19px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector206 {
  top: 108.5262451171875px;
  left: 210.82571411132812px;
  width: 7px;
  height: 21px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector207 {
  top: 161.40550231933594px;
  left: 140.20994567871094px;
  width: 7px;
  height: 20px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-icon-btns {
  top: 46px;
  left: 1218px;
  width: 38px;
  height: 38px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288909 {
  top: 123px;
  left: 537px;
  width: 553px;
  height: 453.3131408691406px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group460 {
  top: 0px;
  left: 0px;
  width: 85px;
  height: 84.87140655517578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse280 {
  top: 0px;
  left: 0px;
  width: 85px;
  height: 85px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector208 {
  top: 18.379453659057617px;
  left: 18.40730094909668px;
  width: 49px;
  height: 49px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288903 {
  top: 122.81391906738281px;
  left: 0px;
  width: 85px;
  height: 84.87140655517578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2801 {
  top: 0px;
  left: 0px;
  width: 85px;
  height: 85px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector209 {
  top: 18.37945556640625px;
  left: 18.40730094909668px;
  width: 49px;
  height: 49px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288905 {
  top: 245.62783813476562px;
  left: 0px;
  width: 85px;
  height: 84.87140655517578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2802 {
  top: 0px;
  left: 0px;
  width: 85px;
  height: 85px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector210 {
  top: 18.379446029663086px;
  left: 18.40730094909668px;
  width: 49px;
  height: 49px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288907 {
  top: 368.4417419433594px;
  left: 0px;
  width: 85px;
  height: 84.87140655517578px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-ellipse2803 {
  top: 0px;
  left: 0px;
  width: 85px;
  height: 85px;
  position: absolute;
  border-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector211 {
  top: 18.37946128845215px;
  left: 18.40730094909668px;
  width: 49px;
  height: 49px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288902 {
  top: 8.986384391784668px;
  left: 113px;
  width: 100%;
  height: 59.90923309326172px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text14 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text16 {
  top: 41.93646240234375px;
  color: var(--dl-color-colores_neutrales-80);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288904 {
  top: 131.80029296875px;
  left: 113px;
  width: 100%;
  height: 59.90922927856445px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text18 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text20 {
  top: 41.93646240234375px;
  color: var(--dl-color-colores_neutrales-80);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288906 {
  top: 254.6142120361328px;
  left: 113px;
  width: 100%;
  height: 59.90922546386719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text22 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text24 {
  top: 41.936458587646484px;
  left: 8px;
  color: var(--dl-color-colores_neutrales-80);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group288908 {
  top: 377.4281311035156px;
  left: 113px;
  width: 100%;
  height: 59.90922546386719px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text26 {
  color: var(--dl-color-colores_paleta-tonos_morado-90);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 30px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-text28 {
  top: 41.936458587646484px;
  left: 10px;
  color: var(--dl-color-colores_neutrales-80);
  width: 100%;
  height: auto;
  position: absolute;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-personasentada-footer {
  top: 554px;
  left: 213px;
  width: 47.215576171875px;
  height: 80.57166290283203px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector212 {
  top: 35.99970626831055px;
  left: 9.281811714172363px;
  width: 35px;
  height: 16px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group33 {
  top: 49.11480712890625px;
  left: 4.067385196685791px;
  width: 11.808335304260254px;
  height: 23.66203498840332px;
  display: flex;
  opacity: 0.50;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector213 {
  top: 1.013037919998169px;
  left: 0.0329318568110466px;
  width: 11px;
  height: 23px;
  opacity: 0.05;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector214 {
  top: 2.006671905517578px;
  left: 0.06577664613723755px;
  width: 11px;
  height: 22px;
  opacity: 0.10;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector215 {
  top: 3.0064642429351807px;
  left: 0.0986214354634285px;
  width: 11px;
  height: 21px;
  opacity: 0.14;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector216 {
  top: 4.025994777679443px;
  left: 0.13146622478961945px;
  width: 10px;
  height: 19px;
  opacity: 0.19;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector217 {
  top: 5.039367198944092px;
  left: 0.17062732577323914px;
  width: 10px;
  height: 18px;
  opacity: 0.24;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector218 {
  top: 6.019341945648193px;
  left: 0.20347212255001068px;
  width: 9px;
  height: 17px;
  opacity: 0.29;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector219 {
  top: 7.0256876945495605px;
  left: 0.22905315458774567px;
  width: 9px;
  height: 16px;
  opacity: 0.33;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector220 {
  top: 8.045218467712402px;
  left: 0.26853007078170776px;
  width: 9px;
  height: 15px;
  opacity: 0.38;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector221 {
  top: 9.05156421661377px;
  left: 0.3013748526573181px;
  width: 8px;
  height: 14px;
  opacity: 0.43;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector222 {
  top: 10.045197486877441px;
  left: 0.3326405882835388px;
  width: 8px;
  height: 13px;
  opacity: 0.48;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector223 {
  top: 11.04491138458252px;
  left: 0.3585374355316162px;
  width: 8px;
  height: 12px;
  opacity: 0.52;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector224 {
  top: 12.057888984680176px;
  left: 0.3973827064037323px;
  width: 7px;
  height: 11px;
  opacity: 0.57;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector225 {
  top: 13.064628601074219px;
  left: 0.42106887698173523px;
  width: 7px;
  height: 10px;
  opacity: 0.62;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector226 {
  top: 14.064421653747559px;
  left: 0.4599141478538513px;
  width: 6px;
  height: 9px;
  opacity: 0.67;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector227 {
  top: 15.057975769042969px;
  left: 0.5104445815086365px;
  width: 6px;
  height: 8px;
  opacity: 0.71;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector228 {
  top: 16.077428817749023px;
  left: 0.525287926197052px;
  width: 6px;
  height: 7px;
  opacity: 0.76;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector229 {
  top: 17.076982498168945px;
  left: 0.5600275993347168px;
  width: 5px;
  height: 6px;
  opacity: 0.81;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector230 {
  top: 18.09485626220703px;
  left: 0.5805556178092957px;
  width: 5px;
  height: 5px;
  opacity: 0.86;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector231 {
  top: 19.06496238708496px;
  left: 0.6042417287826538px;
  width: 5px;
  height: 4px;
  opacity: 0.90;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector232 {
  top: 20.017065048217773px;
  left: 0.5947672724723816px;
  width: 4px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector233 {
  top: 20.76041603088379px;
  left: 0.5777132511138916px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector234 {
  top: 55.6270751953125px;
  left: 17.28457260131836px;
  width: 12px;
  height: 25px;
  opacity: 0.05;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector235 {
  top: 56.73203659057617px;
  left: 17.324365615844727px;
  width: 12px;
  height: 24px;
  opacity: 0.10;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector236 {
  top: 57.83091354370117px;
  left: 17.35689353942871px;
  width: 11px;
  height: 23px;
  opacity: 0.14;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector237 {
  top: 58.9425048828125px;
  left: 17.396055221557617px;
  width: 11px;
  height: 21px;
  opacity: 0.19;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector238 {
  top: 60.06712341308594px;
  left: 17.429216384887695px;
  width: 10px;
  height: 20px;
  opacity: 0.24;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector239 {
  top: 61.17216110229492px;
  left: 17.46237564086914px;
  width: 10px;
  height: 19px;
  opacity: 0.29;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector240 {
  top: 62.27711868286133px;
  left: 17.507221221923828px;
  width: 10px;
  height: 18px;
  opacity: 0.33;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector241 {
  top: 63.38926315307617px;
  left: 17.53343391418457px;
  width: 9px;
  height: 17px;
  opacity: 0.38;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector242 {
  top: 64.50740814208984px;
  left: 17.579544067382812px;
  width: 9px;
  height: 16px;
  opacity: 0.43;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector243 {
  top: 65.61939239501953px;
  left: 17.611125946044922px;
  width: 8px;
  height: 14px;
  opacity: 0.48;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector244 {
  top: 66.7177963256836px;
  left: 17.637022018432617px;
  width: 8px;
  height: 13px;
  opacity: 0.52;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector245 {
  top: 67.82978820800781px;
  left: 17.68755340576172px;
  width: 8px;
  height: 12px;
  opacity: 0.57;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector246 {
  top: 68.9283447265625px;
  left: 17.706186294555664px;
  width: 7px;
  height: 11px;
  opacity: 0.62;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector247 {
  top: 70.05241394042969px;
  left: 17.75103187561035px;
  width: 7px;
  height: 10px;
  opacity: 0.67;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector248 {
  top: 71.52940368652344px;
  left: 17.35784149169922px;
  width: 6px;
  height: 9px;
  opacity: 0.71;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector249 {
  top: 72.64091491699219px;
  left: 17.38184356689453px;
  width: 6px;
  height: 8px;
  opacity: 0.76;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector250 {
  top: 73.74650573730469px;
  left: 17.42353057861328px;
  width: 6px;
  height: 6px;
  opacity: 0.81;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector251 {
  top: 74.85580444335938px;
  left: 17.451953887939453px;
  width: 5px;
  height: 5px;
  opacity: 0.86;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector252 {
  top: 75.95957946777344px;
  left: 17.473745346069336px;
  width: 5px;
  height: 4px;
  opacity: 0.90;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector253 {
  top: 77.01337432861328px;
  left: 17.476903915405273px;
  width: 4px;
  height: 3px;
  opacity: 0.95;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector254 {
  top: 77.82683563232422px;
  left: 17.453218460083008px;
  width: 4px;
  height: 2px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector255 {
  top: 10.721376419067383px;
  left: 13.762283325195312px;
  width: 13px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector256 {
  top: 14.792631149291992px;
  left: 16.897382736206055px;
  width: 10px;
  height: 20px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector257 {
  top: 73.8147201538086px;
  left: 16.94317626953125px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector258 {
  top: 73.95075988769531px;
  left: 16.93654441833496px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector259 {
  top: 65.09371948242188px;
  left: 4.058542728424072px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector260 {
  top: 65.09371948242188px;
  left: 4.054752826690674px;
  width: 8px;
  height: 7px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector261 {
  top: 33.56587600708008px;
  left: 0px;
  width: 33px;
  height: 34px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector262 {
  top: 37.32857894897461px;
  left: 6.787502765655518px;
  width: 22px;
  height: 30px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector263 {
  top: 33.559322357177734px;
  left: 18.98397445678711px;
  width: 21px;
  height: 42px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector264 {
  top: 9.743375778198242px;
  left: 21.66208839416504px;
  width: 18px;
  height: 29px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector265 {
  top: 10.701086044311523px;
  left: 27.146535873413086px;
  width: 8px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector266 {
  top: 34.64470291137695px;
  left: 12.986956596374512px;
  width: 4px;
  height: 6px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector267 {
  top: 5.458630561828613px;
  left: 27.55078125px;
  width: 7px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector268 {
  top: 5.452551364898682px;
  left: 27.550148010253906px;
  width: 5px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector269 {
  top: 0.9249440431594849px;
  left: 25.02773094177246px;
  width: 8px;
  height: 12px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector270 {
  top: 35.96023178100586px;
  left: 24.72075843811035px;
  width: 15px;
  height: 39px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector271 {
  top: 0px;
  left: 23.36591148376465px;
  width: 11px;
  height: 9px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group34 {
  top: 32.51484298706055px;
  left: 7.005731105804443px;
  width: 29.18307876586914px;
  height: 17.597949981689453px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector272 {
  top: 0.7826991677284241px;
  left: 0.0018509780056774616px;
  width: 29px;
  height: 17px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector273 {
  top: -6.855088940938003e-9px;
  left: -3.0659236927021993e-8px;
  width: 29px;
  height: 17px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector274 {
  top: 0.3142661452293396px;
  left: 0.3315621614456177px;
  width: 29px;
  height: 16px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector275 {
  top: 3.0609118938446045px;
  left: 4.1415581703186035px;
  width: 20px;
  height: 11px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector276 {
  top: 3.497131824493408px;
  left: 4.911515712738037px;
  width: 18px;
  height: 10px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-group35 {
  top: 23.470840454101562px;
  left: 4.782707214355469px;
  width: 20.34872817993164px;
  height: 25.695724487304688px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector277 {
  top: 0.17774055898189545px;
  left: 0.00007669531623832881px;
  width: 20px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector278 {
  top: 0.000015491697922698222px;
  left: 0.2931532859802246px;
  width: 20px;
  height: 26px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector279 {
  top: 39.06501007080078px;
  left: 26.417634963989258px;
  width: 7px;
  height: 4px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector280 {
  top: 15.529428482055664px;
  left: 30.61418914794922px;
  width: 9px;
  height: 23px;
  position: absolute;
}
.frame651o-v-e-r-l-a-y-persona-formulario-lneadesgravamen-vector281 {
  top: 13.839579582214355px;
  left: 31.253398895263672px;
  width: 16px;
  height: 28px;
  position: absolute;
}
</style>
